import React, { useEffect, useRef, useState } from "react";
import styles from "./SurveyDesign.module.scss";
import { SurveyLogo } from "./SurveyLogo";
import { PageStyle } from "./PageStyle";
import { PageNavigation } from "./PageNavigation";
import { QuestionStyle } from "./QuestionStyle";
import { FinishedSettings } from "./FinishedSettings";

export function SurveyDesign({
  settings,
  setSettings,
  activeTab,
  setActiveTab,
  numPages,
  setGetAll,
  currPage,
  setCurrPage,
  allQuestions,
  onDeletePage,
  addPage,
  switchPages,
  status,
}) {
  const ref = useRef(null);
  const handleClickOutside = (event) => {
    // debugger;
    // if (ref.current && !ref.current.contains(event.target) ) {
    //   setActiveQuestionId("");
    // }
    if (ref.current && !ref.current.contains(event.target)) {
      setActiveTab("");
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  function changeSettingsField(setting, value) {
    let tempSettings = { ...settings };
    tempSettings[setting] = value;
    setSettings(tempSettings);
  }

  return (
    <div className={styles.designContainer}>
      {/* <SurveyLogo
        active={activeTab === "SurveyLogo"}
        setActive={setActiveTab}
        settings={settings}
        setSetting={changeSettingsField}
        setImage={setImage}
        setFile={setFile}
        currImage={currImage}
      ></SurveyLogo> */}
      <PageStyle
        active={activeTab === "PageStyle"}
        setActive={setActiveTab}
        settings={settings}
        changeSetting={changeSettingsField}
      ></PageStyle>
      {/* <QuestionStyle
        active={activeTab === "QuestionStyle"}
        setActive={setActiveTab}
        settings={settings}
        changeSetting={changeSettingsField}
      ></QuestionStyle> */}
      <PageNavigation
        active={activeTab === "PageNavigation"}
        setActive={setActiveTab}
        settings={settings}
        setSettings={setSettings}
        changeSetting={changeSettingsField}
        numPages={numPages}
        setGetAll={setGetAll}
        currPage={currPage}
        setCurrPage={setCurrPage}
        allQuestions={allQuestions}
        onDeletePage={onDeletePage}
        addPage={addPage}
        switchPages={switchPages}
        status={status}
      ></PageNavigation>
      <FinishedSettings
        active={activeTab === "Finished"}
        setActive={setActiveTab}
        settings={settings}
        changeSetting={changeSettingsField}
      ></FinishedSettings>
    </div>
  );
}
