import {
  useCreateVisualization,
  useGetViz,
} from "api/resources/projects/visualizations";
import styles from "../WidgetSettings.module.scss";
import { Label } from "components/layouts/Label/Label";
import { useState } from "react";
import { DndContext, DragOverlay, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  restrictToVerticalAxis,
  restrictToParentElement,
} from "@dnd-kit/modifiers";
import {
  useFetchAnOrgsFields,
  useFetchCurrOrgsFields,
} from "api/resources/organization/organization";
import { useGetCurrentUser } from "api/resources/organization/users";
import Visualization from "pages/results/Charts/Visualization";
import AddChartToDash from "pages/dashboard/AddChartToDash";

export default function ChartSettings({
  widget,
  changeSettings,
  visible,
  setVisible,
  addToDeleted,
}) {
  const createViz = useCreateVisualization();
  const fetchCustomFields = useFetchCurrOrgsFields();
  const getCurrUser = useGetCurrentUser();

  const [addChart, setAddChart] = useState();
  const [activeId, setActiveId] = useState();

  function handleNewChart(viz) {
    setAddChart(false);

    let data = {
      title: viz.title,
      type: viz.type,
      questionIds: viz.question ? viz.question.map((q) => q.id) : null,
      designSettings: JSON.stringify(viz.designSettings),
      pivotString: viz.pivotString,
      filters: viz.filters ? viz.filters : "{}",
      analysisId: null,
      projectIdsArray: JSON.stringify(viz.projectIdsArray),
      tagIdsArray: JSON.stringify(viz.tagIdsArray),
      programIds: viz.programIds ? JSON.stringify(viz.programIds) : null,
      questionTags: viz.questionTags ? JSON.stringify(viz.questionTags) : null,
      comparison: newViz.comparison ? JSON.stringify(newViz.comparison) : null,
      dataMethod: viz.dataMethod,
    };

    createViz.mutate(
      {
        data: data,
      },
      {
        onSuccess: (data) => {
          // console.log("Added Chart", data);
          finishAddingChart(data.createVisualization.id);
        },
        onError: (data) => {},
      }
    );
  }

  function finishAddingChart(id) {
    let charts = [...widget.settings.charts];
    charts.push({
      id: id,
      orgId: getCurrUser.data.me.organizationId,
    });
    changeSettings("charts", charts);
  }

  function handleNevermind() {
    setAddChart(false);
  }

  function removeChart(id) {
    let charts = [...widget.settings.charts];
    let index = charts.findIndex((c) => c.id === id);
    charts.splice(index, 1);
    changeSettings("charts", charts);
    addToDeleted(id);
  }

  function handleDragEnd({ active, over }) {
    if (active.id !== over?.id) {
      let oldList = [...widget.settings.charts];
      let indexOfActive = oldList.findIndex((c) => c.id === active.id);
      let indexOfOver = oldList.findIndex((c) => c.id === over.id);

      let newList = [...oldList];
      const down = indexOfActive < indexOfOver;
      const up = indexOfActive > indexOfOver;

      if (down) {
        newList.splice(indexOfOver + 1, 0, oldList[indexOfActive]);
        newList.splice(indexOfActive, 1);
      }
      if (up) {
        newList.splice(indexOfOver, 0, oldList[indexOfActive]);
        newList.splice(indexOfActive + 1, 1);
      }

      changeSettings("charts", newList);
    }
    setActiveId(null);
  }

  function handleStart({ active }) {
    setActiveId(active.id);
  }

  return (
    <>
      <div
        className={`${styles.header} ${visible ? styles.headervisible : ""}`}
        onClick={setVisible}
      >
        {"Charts"}
        <span className={styles.accordionicon}>
          <i className="bi bi-caret-left-fill"></i>
        </span>
      </div>
      {visible && (
        <>
          {!addChart && (
            <div className={styles.body}>
              <Label style={{ fontWeight: "600", color: "#8dabb2" }}>
                Charts
              </Label>
              <div
                className={styles.col}
                style={{ gap: "15px", alignItems: "center" }}
              >
                <DndContext
                  // sensors={sensors}
                  collisionDetection={closestCenter}
                  // onDragOver={handleDragOver}
                  modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                  onDragEnd={handleDragEnd}
                  onDragStart={handleStart}
                >
                  <SortableContext
                    id={"Chart Widgets"}
                    items={widget.settings.charts.map((c) => c.id)}
                    strategy={verticalListSortingStrategy}
                  >
                    {widget.settings.charts.map((c) => (
                      <ChartThumbnail
                        chart={c}
                        removeChart={removeChart}
                        onlyOne={widget.settings.charts.length == 1}
                      />
                    ))}
                  </SortableContext>
                  <DragOverlay>
                    {activeId ? (
                      <ChartThumbnail
                        chart={widget.settings.charts.find(
                          (c) => c.id === activeId
                        )}
                      />
                    ) : null}
                  </DragOverlay>
                </DndContext>

                {fetchCustomFields.isSuccess && getCurrUser.isSuccess && (
                  <div
                    className={styles.plusChart}
                    onClick={() => setAddChart(true)}
                  >
                    + Chart
                  </div>
                )}
              </div>
            </div>
          )}
          {addChart && (
            <AddChartToDash
              onSave={handleNewChart}
              onClose={handleNevermind}
              custom_fields={fetchCustomFields.data.getCurrOrgFields}
            />
          )}
        </>
      )}
    </>
  );
}

function ChartThumbnail({ chart, removeChart, onlyOne }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: chart.id });

  const fetchChart = useGetViz(chart.id);
  const fetchCustomFields = useFetchAnOrgsFields(chart.orgId);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.9 : 1,
    zIndex: isDragging ? 10 : 0,
    justifyContent: "space-between",
  };

  return (
    <div
      className={styles.row}
      style={{ gap: "10px", ...style }}
      ref={setNodeRef}
      {...attributes}
    >
      <div
        className={styles.minus}
        onClick={() => removeChart(chart.id)}
        style={onlyOne ? { visibility: "hidden" } : undefined}
      >
        -
      </div>
      <div className={styles.thumbnail}>
        {fetchChart.isSuccess && fetchCustomFields.isSuccess && (
          <Visualization
            viz={fetchChart.data.getVizualizationByID}
            thumbnail
            idAddOn={"thumbnail"}
            custom_fields={fetchCustomFields.data.getOrgFields}
          />
        )}
      </div>
      <i
        className={`${styles.grip} bi bi-list`}
        style={onlyOne ? { visibility: "hidden" } : undefined}
        {...listeners}
      ></i>
    </div>
  );
}
