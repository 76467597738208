import React, { useState, useEffect } from "react";
//import styles from "./NumSlider.module.scss";
import { Slider } from "@mui/material";

/**
 * An input component designed for the NumberSlider question. It displays a slider that the user can interact with to change a value
 * @param {Int} value the starting value
 * @param {Int} min minimum value
 * @param {Int} max maximum value
 * @param {Int} step the number between each value in the number scale.
 * @param {Function} onChange is called when a number is clicked on and passes through the chosen value
 * @param {string} color the color of the slider
 * @returns {React.ReactElement} a number slider interactable component
 */

export const NumSlider = ({
  value,
  min,
  max,
  step,
  onChange,
  color,
  valueLabel,
}) => {
  const [val, setValue] = useState(value);

  useEffect(() => {
    if (value != val) {
      setValue(value);
    }
  }, [value]);

  const handleChange = (e, val) => {
    setValue(val);
    if (onChange) {
      onChange(val);
    }
  };

  const StyledSlider = {
    // marginTop: "50px",
    color: "#d8d9d9",
    height: 8,
    "& .MuiSlider-track": {
      border: "none",
      color: color,
    },
    "& .MuiSlider-thumb": {
      height: 24,
      width: 24,
      backgroundColor: "#fff",
      border: "3px solid",
      borderColor: color,
      "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
        boxShadow: "inherit",
      },
      "&:before": {
        display: "none",
      },
    },
    // "& .MuiSlider-valueLabel": {
    //   lineHeight: 1.2,
    //   fontSize: 12,
    //   background: "unset",
    //   padding: 0,
    //   width: 32,
    //   height: 32,
    //   borderRadius: "50% 50% 50% 0",
    //   backgroundColor: color,
    //   transformOrigin: "bottom left",
    //   transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    //   "&:before": { display: "none" },
    //   "&.MuiSlider-valueLabelOpen": {
    //     transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    //   },
    //   "& > *": {
    //     transform: "rotate(45deg)",
    //   },
    // },
  };

  return (
    <Slider
      defaultValue={val}
      // onChangeCommitted={handleChange}
      onChange={handleChange}
      sx={StyledSlider}
      // onAnimationEnd={handleChange}
      // onChangeCapture={handleChange}
      min={Number(min)}
      max={Number(max)}
      step={Number(step)}
      valueLabelDisplay={valueLabel}
      // onChange={handleChange}
    />
  );
};
