import React from "react";
import styles from "./WhereTo.module.scss";
import ReactModal from "components/ReactModal/ReactModal.jsx";
import ReportFolder from "./ReportFolder";
import { useCopyReport } from "api/resources/organization/reports";
import { useNavigate } from "react-router-dom";
import { useFetchReportFolders } from "api/resources/organization/reportFolders";
import Folders from "./Folders";

const ReportFolders = [
  // {
  //   id: "-0398-0498534234",
  //   name: "Custom Reports",
  //   reports: [
  //     {
  //       name: "My Report",
  //       survey: "Q2 NPS Sendout 2022",
  //       createdAt: "Jun 5, 2023",
  //       type: "Custom",
  //     },
  //     {
  //       name: "New Report",
  //       survey: "Q2 NPS Sendout 2022",
  //       createdAt: "Jun 10, 2023",
  //       type: "Custom",
  //     },
  //   ],
  //   to: `my-reports`,
  //   icon: "bi bi-folder",
  // },
  // {
  //   id: "-0398-049asdfjj",
  //   name: "Shared With Me",
  //   to: `shared-reports`,
  //   icon: <i className="bi bi-people"></i>,
  // },

  {
    id: "-0398-0498fasdpoxu",
    name: "Organization Reports",
    to: `organization-reports`,
    icon: "bi bi-building",
  },
];

function WhereTo({ show, setShow, report, isNewReport, organization, copy, message}) {
  // console.log(show);
  // const createReportQuery = useCreateReport();
  const copyReport = useCopyReport();

  let navigate = useNavigate();
  const routeChange = (path) => {
    // console.log(path);
    navigate(path);
  };

  function save(folder) {
    // console.log(whereto);

    if (isNewReport) {
      if (!report.analysis[0]?.layout) {
        report.analysis[0].layout = "[]";
      }

      // let isUser = true;
      let type = "Custom";
      // if (folder.id ==="organization-report")

      // createReportQuery.mutate(
      //   {
      //     data: {
      //       name: report.name,
      //       content: report.content,
      //       firstAnalysis: JSON.stringify(report.analysis[0]),
      //       reportFolderId: folder?.id,
      //       // pageOrder: currReport.pageOrder,
      //       // status: currReport.status,
      //       // analysisIds: analysisIds,
      //       type: type,
      //       // title: "New Analysis",
      //       // subTitle: "2022",
      //       // status: isUser === true ? null : "Unpublished",
      //       isUser: true,
      //     },
      //   },
      //   {
      //     onSuccess: (data) => {
      //       console.log(data);
      //       routeChange("/reports/" + data.createReport.id);
      //     },
      //   }
      // );
    } else if(copy) {
      copyReport.mutate(
        {
          id: report?.id,
          folderid: folder?.id
        },
        {
          onSuccess: (data) => {
            console.log(data);
            routeChange("/reports/" + data.copyReport.id);
          },
        }
      );
    }
  }

  return (
    <ReactModal
      show={show}
      onClose={() => setShow(false)}
      modalStyle={{ zIndex: "140", borderRadius: "1em", padding: "1em" }}
    >
      <div className={styles.pageContainer}>
        {!copy && <div>{message ? message : "Where would you like to save this report?"} </div>}
        {copy && <div>{message ? message : "Where would you like to save the copy?"}</div>}
        {/* <Folders onClick={save} organization={organization} showAddInit={false}></Folders> */}
        <div></div>
      </div>
    </ReactModal>
  );
}

export default WhereTo;
