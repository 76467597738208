import styles from "./EditLogo.module.scss";
import { Label } from "components/layouts/Label/Label";
import { ColorPicker } from "components/inputs/input_fields/ColorPicker/ColorPicker";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";

export function SurveyLogoSettings({ settings, setSettings }) {
  function changeSettingsField(setting, value) {
    let tempSettings = { ...settings };
    tempSettings[setting] = value;
    setSettings(tempSettings);
  }

  const options = ["left", "center", "right"];

  return (
    <div className={styles.settingContainer}>
      <div className={styles.settingsBody}>
        <div className={styles.settingHorizontal}>
          <ToggleSwitch
            startChecked={settings.showLogo}
            handleCheck={(val) => changeSettingsField("showLogo", val)}
          ></ToggleSwitch>
          <Label>Show Logo</Label>
        </div>

        <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
          <Label style={{ width: "fit-content" }}>Logo Alignment</Label>

          {options.map((opt, i) => (
            <div
              key={i}
              className={`${styles.alignmentBtn} ${
                settings.logoAlign === opt ? styles.chosenAlignment : ""
              }`}
              onClick={() => changeSettingsField("logoAlign", opt)}
            >
              <i className={`bi bi-text-${opt}`}></i>
            </div>
          ))}
        </div>

        <div className={styles.settingHorizontal}>
          <div className={styles.colorContainer}>
            <ColorPicker
              onChange={(color) =>
                changeSettingsField("logoBackgroundColor", [color])
              }
              defaultColor={
                settings.logoBackgroundColor
                  ? settings.logoBackgroundColor
                  : settings.backgroundColor
              }
            />
          </div>
          <Label style={{ paddingLeft: "2.1em" }}>Background Color</Label>
        </div>
        <div className={styles.settingHorizontal}>
          <ToggleSwitch
            startChecked={settings?.logoBoxShadow}
            handleCheck={(val) => changeSettingsField("logoBoxShadow", val)}
          ></ToggleSwitch>
          <Label>Logo Box Shadow</Label>
        </div>

        <div className={styles.settingHorizontal}>
          <ToggleSwitch
            startChecked={settings.logoOnEveryPage}
            handleCheck={(val) => changeSettingsField("logoOnEveryPage", val)}
          ></ToggleSwitch>
          <Label>Logo on every page</Label>
        </div>
      </div>
    </div>
  );
}
