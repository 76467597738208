import React, { useEffect, useState } from "react";

import styles from "./org-images.module.scss";
import {
  useDeleteImage,
  useGetOrgImagesGql,
} from "api/resources/projects/images";
import { Loading } from "components/Loading/Loading";
import { saveAs } from "file-saver";
import ImageUploadModal from "components/ImageUploadModal/imageUploadModal";
import Button from "components/Button/Button";

export const OrgImages = () => {
  const getOrgImage = useGetOrgImagesGql();
  const deleteImage = useDeleteImage();
  const [show, setShow] = useState(false);

  const handleDelete = (name, id) => {
    console.log(name);
    name = name.substring(name.lastIndexOf("/") + 1);
    console.log(name);
    deleteImage.mutate(
      {
        filename: name,
        filetype: "image/*",
        id: id,
      },
      {
        onSuccess: (data) => {
          console.log(data);
          getOrgImage.refetch();
        },
      }
    );
  };

  return (
    <div className={styles.page}>
      {getOrgImage.isLoading && <Loading></Loading>}
      {getOrgImage.isSuccess && (
        <>
          <div className={styles.headerContainer}>
            <Button shadow onClick={() => setShow(true)}>
              Upload Image
            </Button>
          </div>
          <div className={styles.imageRow}>
            {getOrgImage.data.imagesByOrgId.map((image, ind) => (
              <div className={styles.imageContainer}>
                <img
                  src={image.imageURL}
                  className={styles.avatar}
                  alt="user profile image"
                />
                <div className={styles.imageInfo}>
                  <div className={styles.info}>Name: {image.name} </div>
                  <div className={styles.info}>About: {image.about} </div>
                  <div className={styles.info}>
                    Created: {new Date(image.createdDate).toDateString()}{" "}
                  </div>
                  <div className={styles.info}>
                    Updated: {new Date(image.updatedDate).toDateString()}{" "}
                  </div>
                </div>
                <div className={styles.imageEdit}>
                  <i className="bi bi-pencil"></i>
                </div>
                <div className={styles.imageDownload}>
                  <i
                    className="bi bi-download"
                    onClick={() => saveAs(image.imageURL, image.name)}
                  ></i>
                </div>
                <div className={styles.imageDelete}>
                  <i
                    className="bi bi-trash"
                    onClick={() => handleDelete(image.imageURL, image.id)}
                  ></i>
                </div>
              </div>
            ))}
          </div>

          {show && (
            <ImageUploadModal
              show={show}
              setShow={setShow}
              refetch={getOrgImage}
            ></ImageUploadModal>
          )}
        </>
      )}
    </div>
  );
};
