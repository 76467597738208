import React, { useEffect, useRef, useState } from "react";
import {
  useCreateAnalysis,
  useFetchAnalysis,
  useUpdateAnalysis,
  useDeleteAnalysis,
  useUpdateAnalysisFilter,
  useCopyAnalysis,
} from "api/resources/organization/analysis";
import {
  useCopyReport,
  useIsReportPinned,
  useUpdateTiedSurveys,
  useUpdateReport,
} from "api/resources/organization/reports";
import Button from "components/Button/Button";
import { SelectField, TextFieldSimple } from "components/inputs";
import { Label } from "components/layouts/Label/Label";
import { Loading } from "components/Loading/Loading";
import Analysis from "pages/results/Analysis/Analysis";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./Report.module.scss";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  restrictToVerticalAxis,
  restrictToParentElement,
} from "@dnd-kit/modifiers";
import { Share } from "./Share";
import AddToWidget from "pages/dashboard/AddToDashPopouts/AddToWidget";
import DownloadView from "components/DownloadView/DownloadView";
import WhereTo from "pages/reports/WhereTo";
import {
  useFix,
  useSearchProjectsForChart,
} from "api/resources/projects/projects";
import { PageFilter } from "./PageFilter";
import IconShopper from "../Charts/Settings/AccordianItems/MetricSettings/IconShopper/IconShopper";
import { forEach } from "assets/functions/ArrayFunctions";
import { TextArea } from "components/inputs/input_fields/TextArea/TextArea";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { combinedProjIds } from "../Charts/Visualization";
import VerticalBarImage from "assets/images/VerticalBarChart.png";
import TableImage from "assets/images/Table.png";

const tagOptions = [
  {
    value: "Survey Results",
    label: "Survey Results",
  },
  {
    value: "Ongoing Results",
    label: "Ongoing Results",
  },
  {
    value: "Program Results",
    label: "Program Results",
  },
  {
    value: "Raw Survey Results",
    label: "Raw Survey Results",
  },
  {
    value: "Analytics Report",
    label: "Analytics Report",
  },
  {
    value: "Info Report",
    label: "Info Report",
  },
];

export default function Report({
  report,
  roles,
  draft,
  custom_fields,
  refetchReport,
  canEdit,
  user,
  organization,
  basic,
}) {
  const { reportid } = useParams();
  const isReportPinned = useIsReportPinned(reportid);
  const findFirstAnal = () => {
    let pageId = localStorage.getItem("ReportPage");
    if (!report.analysis.find((a) => a.id === pageId)) {
      pageId = null;
      localStorage.setItem("ReportPage", null);
    }
    // debugger;
    if (!pageId && report?.pageOrder) {
      for (let analId of JSON.parse(report?.pageOrder)) {
        let first = report?.analysis?.find(
          (analysis) => analysis.id === analId
        ); // If the first in the page order still exists, use it
        if (first) {
          return first.id;
        }
      }
    } else if (pageId) {
      return pageId;
    }

    // doc
    return report.analysis[0]?.id;
  };

  const [currAnalysisId, setCurrAnalysisId] = useState(findFirstAnal());
  const [showAddToWidget, setShowAddToWidget] = useState(false);

  const createAnalysisPage = useCreateAnalysis();
  const [show, setShow] = useState(false);
  const updateReport = useUpdateReport();
  const [saveChanges, setSaveChanges] = useState(false);
  const [saveStatus, setSaveStatus] = useState("Save");
  const [addChart, setAddChart] = useState("");
  const [showShare, setShowShare] = useState(false);
  const [showTemplate, setShowTemplate] = useState(false);
  const [publishLoading, setPublishLoading] = useState("");
  const [currReport, setCurrReport] = useState(report);
  const [tempReportName, setTempReportName] = useState(currReport?.name);
  const [reportContent, setReportContent] = useState(currReport.content);

  const [whereto, showWhereto] = useState(false);

  useEffect(() => {
    setCurrReport(report);
  }, [report]);

  // const getWidth = () =>
  //   window.innerWidth ||
  //   document.documentElement.clientWidth ||
  //   document.body.clientWidth;
  // let [width, setWidth] = useState(getWidth());

  // useEffect(() => {
  //   const resizeListener = () => {
  //     // change width from the state object
  //     let width = getWidth();
  //     setWidth(width);
  //   };
  //   // set resize listener
  //   window.addEventListener("resize", resizeListener);

  //   // clean up function
  //   return () => {
  //     // remove resize listener
  //     window.removeEventListener("resize", resizeListener);
  //   };
  // }, []);

  let navigate = useNavigate();
  const routeChange = (path) => {
    // console.log(path);
    navigate(path);
  };

  function changeName(value) {
    if (!saveChanges) {
      setSaveChanges(true);
      setSaveStatus("Save Changes");
    } else if (value != currReport?.name) {
      setSaveChanges(true);
      setSaveStatus("Save Changes");
    } else if (value === currReport?.name) {
      setSaveChanges(false);
      // setSaveStatus("Save Changes");
    }

    setTempReportName(value);
  }

  function changeContent(e) {
    let value = e.target.value;
    if (!saveChanges) {
      setSaveChanges(true);
      setSaveStatus("Save Changes");
    } else if (value != currReport.content) {
      setSaveChanges(true);
      setSaveStatus("Save Changes");
    } else if (value === currReport.content) {
      setSaveChanges(false);
      // setSaveStatus("Save Changes");
    }
    let temp = { ...currReport, ...{ content: value } };
    setCurrReport(temp);
  }

  function changeType(value) {
    if (!saveChanges) {
      setSaveChanges(true);
      setSaveStatus("Save Changes");
    } else if (value != currReport.type) {
      setSaveChanges(true);
      setSaveStatus("Save Changes");
    } else if (value === currReport.type) {
      setSaveChanges(false);
      // setSaveStatus("Save Changes");
    }
    let temp = { ...currReport, ...{ type: value } };
    setCurrReport(temp);
  }

  function changeIcon(i) {
    if (!saveChanges) {
      setSaveChanges(true);
      setSaveStatus("Save Changes");
    } else if (value != currReport.type) {
      setSaveChanges(true);
      setSaveStatus("Save Changes");
    } else if (value === currReport.type) {
      setSaveChanges(false);
      // setSaveStatus("Save Changes");
    }
    let temp = { ...currReport, ...{ icon: i } };
    setCurrReport(temp);

    setIcons(false);
  }

  function onSave() {
    setSaveStatus(
      <div style={{ display: "flex", flexDirection: "row" }}>
        Saving <Loading height={20} width={20}></Loading>
      </div>
    );

    updateReport.mutate(
      {
        data: {
          name: tempReportName,
          content: currReport.content,
          isUser: currReport.userId != null ? true : false,
          type: currReport.type,
          icon: currReport.icon,
        },
        id: report.id,
      },
      {
        onSuccess: (data) => {
          setSaveStatus("Saved");
          setTimeout(() => {
            setSaveChanges(false);
          }, 750);
          let newName = {
            name: tempReportName,
            content: currReport.content,
            userId: data.updateReport.userId,
          };
          setCurrReport({ ...currReport, ...newName });
        },
      }
    );
  }

  function changeReportStatus(status) {
    setPublishLoading(
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Loading height={20} width={20}></Loading>
      </div>
    );
    let data;
    if (status === "Published") {
      data = {
        status: status,
        organizationId: roles.organizationId,
        isUser: false,
      };
    } else {
      data = { status: status, organizationId: null, isUser: true };
    }
    updateReport.mutate(
      {
        data: data,
        id: report.id,
      },
      {
        onSuccess: (data) => {
          let newName = {
            userId: data.updateReport.userId,
            organizationId: data.updateReport.organizationId,
          };
          let newReport = { ...currReport, ...newName };
          newReport.status = status;
          setCurrReport(newReport);
          setPublishLoading("");
        },
      }
    );
  }

  function addPage(name, onComplete) {
    createAnalysisPage.mutate(
      {
        name: name,
        reportId: reportid,
      },
      {
        onSuccess: (data) => {
          onComplete(data.createAnalysis);
          localStorage.setItem("ReportPage", data.createAnalysis.id);
          setCurrAnalysisId(data.createAnalysis.id);
          if (refetchReport) {
            refetchReport();
          }
        },
      }
    );
  }

  function addReport() {
    showWhereto(true);

    // console.log(whereto);
    // if (!currReport.analysis[0]?.layout) {
    //   currReport.analysis[0].layout = "[]";
    // }
    // createReportQuery.mutate(
    //   {
    //     data: {
    //       name: currReport.name,
    //       content: currReport.content,
    //       firstAnalysis: JSON.stringify(currReport.analysis[0]),
    //       // pageOrder: currReport?.pageOrder,
    //       // status: currReport.status,
    //       // analysisIds: analysisIds,
    //       // type: type,
    //       // title: "New Analysis",
    //       // subTitle: "2022",
    //       // status: isUser === true ? null : "Draft",
    //       isUser: true,
    //     },
    //   },
    //   {
    //     onSuccess: (data) => {
    //       console.log(data);
    //       routeChange("/reports/" + data.createReport.id);
    //     },
    //   }
    // );
  }

  function fetchAnalysis(id) {
    localStorage.setItem("ReportPage", id);
    setCurrAnalysisId(id);
  }

  function onSelectPage(id) {
    if (report.analysis.some((a) => a.id === id)) {
      fetchAnalysis(id);
      setShow(false);
    }
  }

  function switchToPage(pageId) {
    fetchAnalysis(pageId);
    refetchReport();
  }

  function refetchAnalysis() {
    // fetchAnalysisReport.refetch();
  }

  const [showDownload, setShowDownload] = useState(false);
  const pageOrder =
    typeof report.pageOrder === "string"
      ? JSON.parse(report.pageOrder)
      : report?.pageOrder
      ? report?.pageOrder
      : [];

  const [showDownloads, setShowDownloads] = useState(false);

  function nextPage() {
    // let pageOrder = page;

    let index = pageOrder?.findIndex((id) => id === currAnalysisId);
    let newIndex = index + 1;
    if (newIndex > pageOrder?.length - 1) {
      newIndex = 0;
    }
    let newId = pageOrder[newIndex];
    onSelectPage(newId);
  }

  function prevPage() {
    // let pageOrder = JSON.parse(report.pageOrder);
    let index = pageOrder?.findIndex((id) => id === currAnalysisId);
    let newIndex = index - 1;
    if (newIndex < 0) {
      newIndex = pageOrder?.length - 1;
    }
    let newId = pageOrder[newIndex];
    onSelectPage(newId);
  }

  const [icons, setIcons] = useState(false);

  return (
    <>
      <div style={{ zIndex: "140", position: "absolute" }}>
        <WhereTo
          show={whereto}
          setShow={showWhereto}
          isNewReport={true}
          report={currReport}
          organization={canEdit}
        ></WhereTo>
      </div>

      <AnalysisContainer
        analysisId={currAnalysisId}
        addChart={addChart}
        setAddChart={setAddChart}
        show={show}
        setShow={setShow}
        report={currReport}
        canEdit={report.organizationId ? canEdit : true}
        roles={roles}
        onSelectPage={onSelectPage}
        setShowShare={setShowShare}
        showShare={showShare}
        custom_fields={custom_fields}
        reportName={tempReportName}
        user={user}
        showDownload={showDownload}
        setShowDownload={setShowDownload}
        pageOrder={pageOrder}
        organization={organization}
        basic={basic}
        goToPage={switchToPage}
      />
      {report.analysis.length > 1 && !showDownload && !showShare && (
        <div className={styles.footer}>
          <div className={styles.reportFooter}>
            Page {pageOrder?.findIndex((a) => a === currAnalysisId) + 1} of{" "}
            {report.analysis.length}
            <div
              className={styles.reportSettingsButton}
              onClick={() => prevPage()}
            >
              Prev
            </div>
            <div
              className={styles.reportSettingsButton}
              onClick={() => nextPage()}
            >
              Next
            </div>
          </div>
        </div>
      )}
      {show && (
        <div className={styles.pageContainer}>
          <div className={styles.filler} onClick={() => setShow(false)}></div>
          <div className={styles.configBox}>
            <div
              className={styles.closeButton}
              onClick={() => {
                setShow(false);
                setSaveChanges(null);
                setReportContent(report.content);
              }}
            >
              <i className="bi bi-x-lg"></i>
            </div>

            <div className={styles.pagesTableContainer}>
              <ReportPages
                report={currReport}
                addPage={addPage}
                onSelectPage={onSelectPage}
                switchToPage={switchToPage}
                refetchAnalysis={refetchAnalysis}
                roles={roles}
                activePage={currAnalysisId}
                refetch={refetchReport}
                canEdit={canEdit}
              ></ReportPages>
            </div>
            <div style={{ padding: "0px 20px", width: "100%" }}>
              <TextFieldSimple
                label="Report Name"
                value={tempReportName}
                onChange={changeName}
                disable={!report.userId && !canEdit}
              ></TextFieldSimple>
            </div>
            {/* <div className={styles.reportSettings}> */}
            {/* <Label style={{ fontWeight: "600", marginLeft: "30px" }}>
                Report Settings
              </Label> */}

            <TieToSurvey report={report} refetch={refetchReport} />

            <div style={{ width: "100%", padding: "0px 20px" }}>
              <Label>Report Description</Label>
              <TextArea
                className={styles.textArea}
                value={currReport.content}
                onChange={changeContent}
                disabled={!report.userId && !canEdit}
                placeholder="Description..."
              ></TextArea>
            </div>

            {/* <div className={styles.pinnedNTemplate}>
              {isReportPinned.isSuccess &&
                roles.canViewDashboardTab &&
                (roles.canEditPersonalDashboard ||
                  roles.canEditOrgDashboard ||
                  roles.canEditRoleDashboards) && (
                  <div
                    className={styles.pinned}
                    onClick={() => setShowAddToWidget(true)}
                  >
                    <div>
                      {isReportPinned.data.isReportPinned.pinned
                        ? "Pinned to Dash"
                        : "Pin to Dash"}
                    </div>
                    <i
                      className={`bi ${
                        isReportPinned.data.isReportPinned.pinned
                          ? "bi-pin-fill"
                          : "bi-pin"
                      }`}
                    ></i>
                  </div>
                )}

              {roles.canCreateReportTemplate && (
                <>
                  <div
                    className={styles.reportSettingsButton}
                    onClick={
                      () =>
                        setShowTemplate(
                          !showTemplate
                        ) 
                    }
                  >
                    Use As Template
                  </div>
                  <Template
                    roles={roles}
                    report={report}
                    show={showTemplate}
                    setShow={setShowTemplate}
                  ></Template>
                </>
              )}
            </div> */}

            <div className={styles.changeType}>
              <Label>Report Tag or Type</Label>
              <SelectField
                options={tagOptions}
                value={{ value: report.type, label: currReport.type }}
                onChange={(obj) => changeType(obj.value)}
              ></SelectField>
            </div>

            <div className={styles.reportIcon}>
              <Label style={{ width: "fit-content", padding: "0em" }}>
                Report Icon
              </Label>
              <i
                className={`bi-${
                  currReport?.icon ? currReport?.icon : "bar-chart"
                }`}
                onClick={() => setIcons(!icons)}
              ></i>
            </div>
            {icons && (
              <div className={styles.iconShopper}>
                <IconShopper
                  folderView
                  graphs
                  onPick={(val) => changeIcon(val)}
                ></IconShopper>
              </div>
            )}

            {/* {report.userId && roles.seeUnPublishedReports && <Button>Make Visible To Org</Button>} */}
            {draft && (
              <>
                {" "}
                <Button height={25} onClick={addReport}>
                  Save Report
                </Button>
                <div className={styles.notifyText}>
                  *This Report is a draft. Click{" "}
                  <a onClick={addReport}>Save Report</a> to create and save this
                  draft
                </div>
              </>
            )}
            {((!report.userId && canEdit ? canEdit : roles.canViewOrgReports) ||
              (saveChanges && !draft)) && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  height: "40px",
                  gap: "10px",
                  position: "sticky",
                  bottom: "1em",
                }}
              >
                {saveChanges && !draft && (
                  <>
                    {saveStatus === "Save Changes" && (
                      <Button
                        // height={25}
                        onClick={() => {
                          setShow(false);
                          setSaveChanges(null);
                        }}
                        shadow
                        red
                      >
                        Cancel
                      </Button>
                    )}
                    <Button
                      // height={25}
                      shadow
                      onClick={saveStatus === "Save Changes" ? onSave : null}
                    >
                      {saveStatus}
                    </Button>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      )}

      {showAddToWidget && (
        <AddToWidget
          onClose={() => setShowAddToWidget(false)}
          forReports
          report={report}
          onFinished={() => isReportPinned.refetch()}
          alreadyIn={isReportPinned.data.isReportPinned.reportWidgets}
        />
      )}
    </>
  );
}

function AnalysisContainer({
  analysisId,
  addChart,
  setAddChart,
  setShow,
  show,
  report,
  canEdit,
  roles,
  onSelectPage,
  showShare,
  setShowShare,
  custom_fields,
  user,
  showDownload,
  setShowDownload,
  pageOrder,
  organization,
  basic,
  goToPage,
}) {
  const fetchAnalysisReport = useFetchAnalysis(analysisId, onLoad);
  const useUpdateFilters = useUpdateAnalysisFilter();

  const [externalFilter, setExternalFilter] = useState();
  const [showSaveFilters, setShowSaveFilters] = useState(false);
  const [saveDisplay, setSaveDisplay] = useState(
    <>
      Save Filters <i className="bi bi-funnel"></i>
    </>
  );

  useEffect(() => {
    setExternalFilter("");
    setShowSaveFilters(false);
  }, [analysisId]);

  function onLoad(data) {
    if (!externalFilter && data?.analysisById?.filters) {
      setExternalFilter(data?.analysisById?.filters);
    }
  }

  function onFilterChange(filtersObj) {
    let filtersString = JSON.stringify(filtersObj);
    let ogFilters = fetchAnalysisReport.data.analysisById.filters;
    if (filtersString === ogFilters) {
      setShowSaveFilters(false);
    } else {
      setShowSaveFilters(true);
    }
    setExternalFilter(filtersString);
  }

  function saveFilters() {
    setSaveDisplay(
      <>
        Saving <Loading height={20} width={20}></Loading>
      </>
    );

    useUpdateFilters.mutate(
      {
        id: fetchAnalysisReport.data.analysisById.id,
        filters: externalFilter,
      },
      {
        onSuccess: (data) => {
          setSaveDisplay(
            <>
              Saved <i className="bi bi-check"></i>
            </>
          );

          setTimeout(() => {
            setSaveDisplay(
              <>
                Save Filters <i className="bi bi-funnel"></i>
              </>
            );
            setShowSaveFilters(false);
          }, 1000);
        },
      }
    );
  }

  function getPages() {
    let order = JSON.parse(report.pageOrder);
    let list = [];
    for (let id of order) {
      if (id !== analysisId) {
        let analysis = report.analysis.find((a) => a.id === id);
        if (analysis) {
          list.push(analysis);
        }
      }
    }
    return list;
  }

  return (
    <>
      {" "}
      {fetchAnalysisReport.isLoading && <Loading></Loading>}
      {fetchAnalysisReport.isSuccess && (
        <>
          <ReportHeader
            setShow={setShow}
            show={show}
            showSave={showSaveFilters}
            saveDisplay={saveDisplay}
            saveFilters={saveFilters}
            setAddChart={setAddChart}
            report={report}
            page={fetchAnalysisReport.data.analysisById}
            canEdit={canEdit}
            roles={roles}
            onSelectPage={onSelectPage}
            setShowShare={setShowShare}
            showShare={showShare}
            analysisId={analysisId}
            custom_fields={custom_fields}
            externalFilter={externalFilter}
            onFilterChange={onFilterChange}
            user={user}
            showDownload={showDownload}
            setShowDownload={setShowDownload}
            organization={organization}
            basic={basic}
          ></ReportHeader>

          <Analysis
            key={analysisId}
            analysis={fetchAnalysisReport.data.analysisById}
            refetch={() => fetchAnalysisReport.refetch()}
            addChart={addChart}
            setAddChart={setAddChart}
            canEdit={canEdit}
            externalFilter={externalFilter}
            custom_fields={custom_fields}
            page={
              pageOrder && pageOrder?.findIndex((a) => a === analysisId) + 1
            }
            pages={getPages()}
            goToPage={goToPage}
            reportId={report.id}
          />
        </>
      )}
    </>
  );
}

function ReportHeader({
  show,
  showSave,
  saveDisplay,
  saveFilters,
  setShow,
  setAddChart,
  report,
  page,
  canEdit,
  roles,
  onSelectPage,
  addReport,
  analysisId,
  showShare,
  setShowShare,
  custom_fields,
  externalFilter,
  onFilterChange,
  user,
  showDownload,
  setShowDownload,
  organization,
  basic,
}) {
  const [seePages, setSeePages] = useState(false);

  const [showSettings, setShowSettings] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [showDownloads, setShowDownloads] = useState(false);

  const copyReport = useCopyReport();

  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);

  const handleClickOutside = (event) => {
    if (ref2.current && !ref2.current.contains(event.target)) {
      setShowFilter(false);
    }
    if (ref3.current && !ref3.current.contains(event.target)) {
      setShowSettings(false);
    }
    if (ref4.current && !ref4.current.contains(event.target)) {
      setShowDownloads(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  function addAnswerFilterSubtitle(conditions) {
    let string = "";
    for (let i = 0; i < conditions.length; i++) {
      let condition = conditions[i];
      let op = condition.operator;

      string += condition.surveyName + ": ";

      if (op === "have answered") {
        string += "answered '" + condition.qName + "'";
      } else if (op === "have not answered") {
        string += "did not answer '" + condition.qName + "'";
      } else if (
        op === "passives" ||
        op === "promoters" ||
        op === "detractors"
      ) {
        string += op;
      } else if (Array.isArray(condition.answer)) {
        if (op === "answer" || op === "do not answer" || op === "falls in") {
          if (op === "do not answer") {
            string += "not ";
          }
          for (let i = 0; i < condition.answer.length; i++) {
            if (i > 0) {
              if (i < condition.answer.length - 1) {
                string += ", ";
              } else if (i == condition.answer.length - 1) {
                string += " or ";
              }
            }
            string += condition.answer[i];
          }
        } else if (op === "between") {
          let them = getSortedArray(condition.answer, (a, b) => a - b);
          string += "between " + them[0] + " and " + them[1];
        }
      } else if (op === "below" || op === "above") {
        string += op + " " + condition.answer;
      } else if (op === "contains") {
        string += "'" + condition.answer + "'";
      } else if (op === "does not contain") {
        string += op + "  '" + condition.answer + "'";
      }

      if (op === "is longer than" || op === "is shorter than") {
        if (op === "is longer than") {
          string += "more than ";
        } else {
          string += "less than ";
        }
        string += condition.answer + " characters";
      }

      if (i < conditions.length - 1) {
        string += " & ";
      }
    }

    return string;
  }

  function getFilterSubtitle() {
    let filters = JSON.parse(externalFilter);
    let string = "";
    for (let one in filters) {
      if (string) {
        string += " & ";
      }

      if (one === "surveys") {
        let all = filters.surveys;
        string += "Survey: ";
        for (let i = 0; i < all.length; i++) {
          string += all[i]?.name;
          if (i < all.length - 1) {
            string += ", ";
          } else {
            string += " ";
          }
        }
      } else if (one === "answers") {
        string += addAnswerFilterSubtitle(filters[one]);
      } else {
        let field = filters[one];
        let name =
          field?.name?.charAt(0)?.toUpperCase() + field?.name?.slice(1);
        string += name + ": ";
        for (let i = 0; i < field.properties.length; i++) {
          string += field.properties[i];
          if (i < field.properties.length - 1) {
            string += ", ";
          } else {
            string += " ";
          }
        }
      }
    }
    return string;
  }

  function showFilterSubtitle() {
    if (!externalFilter) {
      return false;
    }

    let filters = JSON.parse(externalFilter);
    let count = 0;
    for (let key in filters) {
      if (key === "surveys" || key === "answers") {
        count += filters[key].length;
      } else {
        count += filters[key]?.properties?.length;
      }
    }
    return count > 0 && count < 6;
  }

  function getPageProjects() {
    let included = {};

    let all = page.visualization.map((v) => {
      if (typeof v.projectIdsArray === "string") {
        v.projectIdsArray = JSON.parse(v.projectIdsArray);
      }
      return combinedProjIds(v);
    });
    for (let list of all) {
      for (let id of list) {
        if (!(id in included)) {
          included[id] = true;
        }
      }
    }

    return Object.keys(included);
  }

  let navigate = useNavigate();

  function runCopyReport() {
    copyReport.mutate(
      {
        id: report.id,
        name: report.name + " (Copy)",
      },
      {
        onSuccess: (data) => {
          console.log(data);
          localStorage.setItem("activepage", 2);
          localStorage.removeItem("activeprojecttab");
          localStorage.removeItem("activecontactstab");
          localStorage.removeItem("activeorgtab");
          localStorage.removeItem("activeaccounttab");
          localStorage.removeItem("activeresultstab");
          localStorage.removeItem("activeanalysistab");
          localStorage.removeItem("activesurveystab");
          localStorage.removeItem("reportspageitem");
          localStorage.removeItem("activeaudiencetab");
          window.localStorage.removeItem("page1");
          window.localStorage.removeItem("page2");
          window.localStorage.removeItem("page3");
          navigate("/report/" + data.copyReport.id);
        },
      }
    );
  }

  function pdfExport() {
    // setOpenSettings(false);
    setTimeout(() => {
      let input = window.document.getElementById(analysisId);
      html2canvas(input, { scale: 5 }).then((canvas) => {
        const img = canvas.toDataURL("image/png", 100);
        // img.save("newimgage");
        const pdf = new jsPDF("p", "pt");
        console.log(input.clientHeight);
        pdf.internal.pageSize.height = input.clientHeight;
        pdf.internal.pageSize.width = input.clientWidth;
        pdf.addImage(img, "png", 0, 0, input.clientWidth, input.clientHeight);
        //give pdf a name and download.
        let str = "chart.pdf";
        str = str.replace(/\s+/g, "");
        str = str.toLocaleLowerCase();
        pdf.save(str);
      }, 5000);
    });
  }

  function showFilterCount() {
    return externalFilter && externalFilter !== "{}";
  }

  function getFilterCount() {
    let filtersObject = JSON.parse(externalFilter);
    let count = 0;
    for (let key in filtersObject) {
      let filter = filtersObject[key];
      if (key === "surveys" || key === "answers") {
        count += filter.length;
      } else {
        if (filter?.properties?.length) {
          count += filter?.properties?.length;
        }
      }
    }
    return count;
  }

  const fix = useFix();
  function onClickFix() {
    fix.mutate(
      {},
      {
        onSuccess: (data) => {
          console.log(data);
        },
      }
    );
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "20px",
            alignItems: "center",
            position: "absolute",
            top: "1.5em",
          }}
        >
          <div className={styles.reportpagename}>
            {/* {reportName ? reportName : report.name} {`/ ${page.name}`} */}
            {page?.name}
          </div>

          {showSave && (
            <div style={{ zIndex: "130" }}>
              <Button height={35} onClick={saveFilters} shadow>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  {saveDisplay}
                </div>
              </Button>
            </div>
          )}
        </div>
      </div>

      <div className={styles.reportHeader}>
        <div className={styles.reportSettingsContainer}>
          {canEdit && <AddChartButton setAddChart={setAddChart} />}
          <HorizontalBar rotate height={20} width={2}></HorizontalBar>
          {showDownloads &&
            !showFilter &&
            !showSettings &&
            !seePages &&
            !showShare && (
              <div className={styles.projectTies} ref={ref4}>
                {console.log(report.project)}
                {report.project.map((survey, i) => (
                  <div
                    className={styles.surveyRow}
                    key={i}
                    onClick={() => setShowDownload(survey)}
                  >
                    {survey?.name}
                  </div>
                ))}
              </div>
            )}
          {(canEdit ? canEdit : JSON.parse(report.pageOrder).length > 1) && (
            <div
              className={`${styles.reportSettingsButton} ${
                seePages ? styles.reportSettingsButtonOpen : ""
              }`}
              onClick={() => setSeePages(!seePages)}
            >
              <i className="bi-journals"></i>Pages
              {seePages && (
                <PagesNav
                  report={report}
                  canEdit={canEdit}
                  addReport={addReport}
                  roles={roles}
                  onSelectPage={onSelectPage}
                  page={page}
                  show={show}
                  setShow={setShow}
                  close={() => setSeePages(false)}
                />
              )}
            </div>
          )}
          <HorizontalBar rotate height={20} width={2}></HorizontalBar>
          {showShare && !basic && (
            <Share
              roles={roles}
              report={report}
              show={showShare}
              setShow={setShowShare}
              currUser={user}
              canEdit={canEdit}
              organization={organization}
            />
          )}

          {report.project && report.project.length > 0 && !basic && (
            <>
              <div
                className={`${styles.reportSettingsButton} ${
                  showDownload ? styles.reportSettingsButtonOpen : ""
                }`}
                onClick={
                  report.project ? () => setShowDownloads(!showDownloads) : null
                }
              >
                <i
                  className="bi bi-cloud-download"
                  style={{
                    color:
                      report.project && report.project.length > 0
                        ? ""
                        : "lightgray",
                  }}
                ></i>
                <span
                  style={{
                    color:
                      report.project && report.project.length > 0
                        ? ""
                        : "lightgray",
                  }}
                >
                  Download
                </span>
              </div>
              <HorizontalBar rotate height={20} width={2}></HorizontalBar>
            </>
          )}
{/* 
          <div
            className={`${styles.reportSettingsButton}`}
            onClick={onClickFix}
          >
            Fix
          </div> */}

          <div
            className={`${styles.reportSettingsButton} ${
              showFilter ? styles.reportSettingsButtonOpen : ""
            }`}
            onClick={() => setShowFilter(true)}
          >
            <i className="bi bi-funnel"></i>
            Filters
            {showFilterCount() && (
              <div className={styles.pageFilterCount}>{getFilterCount()}</div>
            )}
            {showFilter && (
              <div
                className={styles.filter}
                style={{ right: canEdit ? "" : "0em" }}
                ref={ref2}
              >
                <div className={styles.filterHeader}>
                  <div>Filter Page By</div>
                  <div style={{ fontSize: ".6em" }}>
                    Changes override individual chart filters
                  </div>
                </div>

                <PageFilter
                  onFilterChange={onFilterChange}
                  chosenFilter={
                    externalFilter ? JSON.parse(externalFilter) : {}
                  }
                  pageProjects={getPageProjects()}
                  custom_fields={custom_fields}
                ></PageFilter>
              </div>
            )}
          </div>
          <HorizontalBar rotate height={20} width={2}></HorizontalBar>

          {!basic && canEdit && (
            <>
              <div
                className={`${styles.reportSettingsButtonShare} `}
                onClick={() => setShowShare(!showShare)}
              >
                <i className="bi-people"></i>
                <span>Share</span>
              </div>
              <HorizontalBar rotate height={20} width={2}></HorizontalBar>
            </>
          )}
          {canEdit && (
            <div
              className={`${styles.reportSettingsButton} ${
                showSettings ? styles.reportSettingsButtonOpen : ""
              }`}
              onClick={() => setShowSettings(!showSettings)}
            >
              <i className="bi bi-gear"></i>
              Settings
              {showSettings && (
                <div className={styles.showSettings} ref={ref3}>
                  {/* <i className="bi bi-x-lg" onClick={() => setShowSettings(false)}></i> */}
                  <div
                    className={styles.settingOption}
                    onClick={() => setShow(!show)}
                  >
                    Report Settings
                  </div>

                  {/* <div className={styles.settingOption}>Copy Page</div> */}
                  <div className={styles.settingOption} onClick={runCopyReport}>
                    Copy Report
                  </div>

                  <div className={styles.settingOption} onClick={pdfExport}>
                    Export Page as PDF
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        {report.project && report.project.length > 0 && basic && (
          <div
            className={`${styles.reportSettingsButtonDownload} ${
              showDownload ? styles.reportSettingsButtonOpen : ""
            }`}
            onClick={
              report.project
                ? report.project.length === 1
                  ? () => setShowDownload(report.project[0])
                  : () => setShowDownloads(!showDownloads)
                : null
            }
          >
            <i
              className="bi bi-cloud-download"
              style={{
                color:
                  report.project && report.project.length > 0
                    ? ""
                    : "lightgray",
              }}
            ></i>
            <span
              style={{
                color:
                  report.project && report.project.length > 0
                    ? ""
                    : "lightgray",
              }}
            >
              Download Results
            </span>
          </div>
        )}
      </div>

      {showDownload && (
        <DownloadView
          show={showDownload}
          setShow={setShowDownload}
          survey={showDownload}
          custom_fields={custom_fields}
          externalChosenfilter={externalFilter ? externalFilter : {}}
          roles={roles}
        ></DownloadView>
      )}
      {showFilterSubtitle() && (
        <div className={styles.filtersSubtitle}>{getFilterSubtitle()}</div>
      )}
    </>
  );
}

function AddChartButton({ setAddChart }) {
  const [show, setShow] = useState(false);

  function onClickListener() {
    setShow(false);
    document.removeEventListener("click", onClickListener, false);
  }

  function setUpShow() {
    if (!show) {
      setTimeout(
        () => document.addEventListener("click", onClickListener, false),
        300
      );
      setShow(true);
    }
  }

  const ref = useRef(null);

  const chartImageStyle = {
    height: "25px",
    width: "25px",
  };

  return (
    <div
      className={styles.addChart}
      onClick={setUpShow}
      style={show ? { backgroundColor: "#ffffff" } : undefined}
    >
      <div>+ Add</div>

      {show && (
        <div className={styles.chartTypeMenu} ref={ref}>
          <div
            className={styles.chartType}
            onClick={() => setAddChart("chart")}
          >
            Chart
            <i className={`bi bi-bar-chart-fill ${styles.chart} `}></i>
            {/* <img style={chartImageStyle} src={VerticalBarImage} alt="IMG" /> */}
          </div>
          <div
            className={styles.chartType}
            onClick={() => setAddChart("table")}
          >
            <i
              className={`bi bi-layout-text-window-reverse ${styles.table}`}
            ></i>
            Table
            {/* <img style={chartImageStyle} src={TableImage} alt="IMG" /> */}
          </div>
          <div
            className={styles.chartType}
            onClick={() => setAddChart("metric")}
          >
            Metric <i className={`bi bi-card-text ${styles.metric} `}></i>
          </div>

          {/* <div>Note</div> */}
        </div>
      )}
    </div>
  );
}

function PagesNav({
  report,
  canEdit,
  addReport,
  roles,
  onSelectPage,
  page,
  show,
  setShow,
  close,
}) {
  const ref = useRef();

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      close();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div
      className={styles.pagesPopout}
      style={{ right: canEdit ? "" : "-6em" }}
      ref={ref}
    >
      <ReportPages
        report={report}
        roles={roles}
        onSelectPage={onSelectPage}
        activePage={page.id}
        pagesTab
        noTitle
      ></ReportPages>
      {/* {canEdit && <div className={styles.notifyText}></div>} */}
    </div>
  );
}

function ReportPages({
  report,
  addPage,
  onSelectPage,
  switchToPage,
  refetchAnalysis,
  roles,
  activePage,
  pagesTab,
  noTitle,
  refetch,
  canEdit,
}) {
  const initPages = () => {
    if (report?.pageOrder) {
      let pages = [];
      forEach(JSON.parse(report?.pageOrder), (analId) => {
        let analysis = report.analysis.find((a) => a.id === analId);
        if (analysis) {
          pages.push(analysis);
        }
      });
      return pages;
    }
    return report.analysis;
  };

  const [pages, setPages] = useState(initPages());
  const [newPage, setNewPage] = useState(null);
  const [copying, setCopying] = useState("");

  const deleteAnalysis = useDeleteAnalysis();
  const copyAnalysis = useCopyAnalysis();
  const updateReport = useUpdateReport();

  function addTempPage() {
    // let temp = [...pages];
    let tempPage = { name: "", id: "new" };
    // temp.push(tempPage);
    setNewPage(tempPage);
  }

  function changePageName(id, name) {
    let newPages = [...pages];
    for (let page of pages) {
      if (page.id === id) {
        page.name = name;
      }
    }
    setPages(newPages);
    refetchAnalysis();
  }

  function deletePage(page) {
    if (window.confirm("Are you sure you want to delete " + page?.name + "?")) {
      deleteAnalysis.mutate(
        {
          deleteAnalysisId: page.id,
        },
        {
          onSuccess: (data) => {
            console.log("page deleted");
            let newPages = [...pages];
            let ind = newPages.findIndex((p) => p.id === page.id);
            newPages.splice(ind, 1);
            setPages(newPages);

            if (page.id === activePage) {
              if (ind < newPages.length) {
                switchToPage(newPages[ind].id); // Will now be the one after it
              } else {
                switchToPage(newPages[ind - 1].id); // Was last page
              }
            }
            refetch();
          },
          onError: () => {
            console.log("Error deleting the page");
          },
        }
      );
    }
  }

  function copyPage(page) {
    copyAnalysis.mutate(
      {
        copyAnalysisId: page.id,
      },
      {
        onSuccess: (data) => {
          if (data.copyAnalysis) {
            console.log("page copied!");
            let newPages = [...pages];
            let ind = newPages.findIndex((p) => p.id === page.id);
            newPages.splice(ind + 1, 0, data.copyAnalysis);
            setPages(newPages);
            refetch();
            setCopying("");
          }
        },
        onError: () => {
          console.log("Error copying the page");
        },
      }
    );
    setCopying(page.id);
  }

  function addPageToList(analysis) {
    let newPages = [...pages];
    newPages.push({
      name: analysis?.name,
      id: analysis.id,
      visualization: [],
    });
    setPages(newPages);
    // savePageOrder(newPages.map((p) => p.id));
  }

  function handleNewPage(name) {
    if (!name) {
      name = "Page " + (pages.length + 1);
    }
    addPage(name, addPageToList);
    setNewPage(false);
  }

  function savePageOrder(order) {
    updateReport.mutate(
      {
        data: {
          pageOrder: JSON.stringify(order),
        },
        id: report.id,
      },
      {
        onSuccess: (data) => {
          console.log("Order Saved");
          if (refetch) {
            refetch();
          }
        },
        onError: () => {
          console.log("Error Saving Order");
        },
      }
    );
  }

  function handleDragEnd({ active, over }) {
    if (!over?.id) {
      return;
    }
    if (over.id === active.id) {
      return;
    }

    let activeInd = active.data.current.sortable.index;
    let overInd = over.data.current.sortable.index;

    let newPages = [...pages];

    let activePage = newPages[activeInd];
    let overPage = newPages[overInd];

    newPages.splice(activeInd, 1);
    let newOverInd = newPages.findIndex((p) => p.id === overPage.id);

    if (activeInd > overInd) {
      // moving up
      newPages.splice(newOverInd, 0, activePage);
    } else {
      // moving down
      newPages.splice(newOverInd + 1, 0, activePage);
    }

    setPages(newPages);
    savePageOrder(newPages.map((p) => p.id));
  }

  return (
    <div
      className={`${styles.pagesTable} ${noTitle ? styles.pagesRadius : ""}`}
    >
      {!noTitle && (
        <Label style={{ fontWeight: "600", marginLeft: "30px" }}>Pages</Label>
      )}
      <table>
        <tbody>
          <DndContext
            // sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis, restrictToParentElement]}
          >
            <SortableContext
              id={"Sortable Pages"}
              items={pages.map((p) => p.id)}
              strategy={verticalListSortingStrategy}
            >
              {pages.map((page, index) => (
                <PageRow
                  key={index}
                  page={page}
                  report={report}
                  roles={roles}
                  isUser={report.userId}
                  active={page.id === activePage}
                  pagesTab={pagesTab}
                  index={index}
                  canEdit={canEdit}
                />
              ))}
            </SortableContext>
          </DndContext>

          {newPage && (
            <tr>
              <Input
                saveText="Add"
                onSave={handleNewPage}
                onExit={() => setNewPage(false)}
                initText=""
                index={pages.length}
              />
            </tr>
          )}

          {(report.userId || canEdit) && !pagesTab && (
            <tr onClick={addTempPage}>
              <td style={{ padding: "10px 50px", width: "100%" }}>
                <div className={styles.addpage}>+ Page</div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );

  function PageRow({
    page,
    isUser,
    roles,
    active,
    pagesTab,
    report,
    index,
    canEdit,
  }) {
    const {
      attributes,
      listeners,
      setNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({ id: page.id });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
      opacity: isDragging ? 0.9 : 1,
      zIndex: isDragging ? 10 : 0,
      width: "100%",
      backgroundColor: active ? "#E9E9E9" : "",
    };

    const [editName, setEditName] = useState(false);
    const updateAnalysis = useUpdateAnalysis();

    function editAnalysisName(name) {
      if (name !== page.name) {
        updateAnalysis.mutate(
          {
            id: page.id,
            data: {
              name: name,
            },
          },
          {
            onSuccess: (data) => {
              console.log("Updated name", data);
              changePageName(page.id, name);
            },
            onError: (data) => {
              // debugger;
            },
          }
        );
      }
      setEditName(false);
    }

    return (
      <tr
        ref={setNodeRef}
        {...attributes}
        style={{ ...style }}
        onClick={pagesTab && !active ? () => onSelectPage(page.id) : null}
      >
        {!editName && (
          <td className={styles.pageTableRow}>
            <div
              className={styles.page}
              onClick={!active ? () => onSelectPage(page.id) : null}
            >
              {page.name ? page.name : "Page " + (index + 1)}
            </div>
            {(isUser || canEdit) && !pagesTab && (
              <div className={styles.icons}>
                <i
                  className={`bi bi-pencil ${styles.editName}`}
                  onClick={() => setEditName(true)}
                ></i>
                {/* <i
                    className="bi bi-clipboard"
                    onClick={copyReport}
                  ></i> */}
                {report.analysis.length > 1 && (
                  <i
                    className={`bi bi-trash3 ${styles.trash}`}
                    onClick={() => deletePage(page)}
                  ></i>
                )}
                {copying !== page.id && (
                  <i
                    className={`bi bi-copy ${styles.copy}`}
                    style={
                      !page.visualization.length
                        ? { visibility: "hidden" }
                        : undefined
                    }
                    onClick={
                      page.visualization.length
                        ? () => copyPage(page)
                        : undefined
                    }
                  ></i>
                )}
                {copying === page.id && <Loading height={20} width={20} />}
                <div className={styles.grid} {...listeners}>
                  <i className="bi bi-grip-vertical"></i>
                </div>
              </div>
            )}
          </td>
        )}
        {editName && (
          <Input
            onSave={editAnalysisName}
            onExit={() => setEditName(false)}
            initText={page.name}
            saveText="Save"
            index={index}
          />
        )}
      </tr>
    );
  }

  function Input({ initText, saveText, onExit, onSave, index }) {
    const [value, setValue] = useState(initText);

    function listenForEnter(e) {
      if (e.key === "Enter" || e.keyCode === 13) {
        onSave(value);
      }
    }

    return (
      <>
        <td>
          <div className={styles.page}>
            <input
              value={value}
              onChange={(e) => setValue(e.target.value)}
              className={styles.inputLine}
              onKeyDown={listenForEnter}
              placeholder={"Page " + (index + 1)}
            ></input>
          </div>
        </td>
        <td>
          <div className={styles.exitOrSave}>
            <span onClick={onExit}>X</span>
            <Button height={25} onClick={() => onSave(value)}>
              {saveText}
            </Button>
          </div>
        </td>
      </>
    );
  }
}

function TieToSurvey({ report, refetch }) {
  const [edited, setEdited] = useState(false);
  const [tied, setTied] = useState([...report.project]);
  const [show, setShow] = useState(false);

  const updateTies = useUpdateTiedSurveys();

  function saveTies() {
    updateTies.mutate(
      {
        reportId: report.id,
        projectIds: {
          list: tied.map((s) => s.id),
        },
      },
      {
        onSuccess: (data) => {
          console.log("updated:", data);
          refetch();
        },
        onError: (data) => {
          setTied(report.project);
        },
      }
    );
    setEdited(false);
  }

  function untie(survey) {
    let copy = [...tied];
    let index = copy.findIndex((s) => s.id === survey.id);
    if (index >= 0) {
      copy.splice(index, 1);
    }
    setTied(copy);
    setEdited(true);
  }

  function tie(survey) {
    let copy = [...tied];
    copy.push(survey);
    setTied(copy);
    setShow(false);
    setEdited(true);
  }

  function revert() {
    setEdited(false);
    setTied([...report.project]);
  }

  return (
    <div className={styles.tiedToContainer}>
      <div className={styles.tiedToHeader}>Tied Surveys:</div>

      <div className={styles.tiedSurveys}>
        {tied.map((survey, i) => (
          <TiedSurvey key={i} survey={survey} untie={untie} />
        ))}
        <div className={styles.addSurvey} onClick={() => setShow(true)}>
          <div className={styles.plus}>+ add</div>
        </div>
        {show && (
          <AddProject
            included={tied}
            close={() => setShow(false)}
            addProject={tie}
          />
        )}

        {edited && (
          <div className={styles.saveTies}>
            <Button height={25} shadow onClick={saveTies}>
              Save
            </Button>
            <Button height={25} red onClick={revert}>
              Cancel
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

function TiedSurvey({ survey, untie }) {
  const [over, setOver] = useState(false);

  return (
    <div className={`${styles.tiedSurvey} ${over ? styles.careful : ""}`}>
      {survey.name}
      <div
        className={styles.deleteSurvey}
        onClick={() => untie(survey)}
        onMouseEnter={() => setOver(true)}
        onMouseLeave={() => setOver(false)}
      >
        <i className={`bi bi-${over ? "trash3-fill" : "trash3"}`}></i>
      </div>
    </div>
  );
}

function AddProject({ included, addProject, close }) {
  const [searchString, setSearchString] = useState("");
  const [perPage, setPerPage] = useState(20);
  const [pageSkip, setPageSkip] = useState(0);
  const [searchSort, setSearchSort] = useState({
    item: "startedAt",
    descend: true,
  });

  useEffect(() => {
    fetchProjects.refetch();
  }, [searchString]);

  const fetchProjects = useSearchProjectsForChart(
    searchString,
    pageSkip,
    perPage,
    searchSort
  );

  const ref = useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      close();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className={styles.addProjectAnchor}>
      <div className={styles.addProjects} ref={ref}>
        <div className={styles.search}>
          <TextFieldSimple
            value={searchString}
            placeholder="Search Surveys..."
            onChange={(val) => setSearchString(val)}
            customStyles={styles.searchSurveys}
          ></TextFieldSimple>
        </div>

        {fetchProjects.isSuccess && (
          <div className={styles.projectOptions}>
            {fetchProjects.data?.surveys.map((project, index) => (
              <React.Fragment key={index}>
                {!included.some((p) => p.id === project.id) && (
                  <div
                    key={index}
                    className={styles.projectOption}
                    onClick={() => {
                      addProject(project);
                    }}
                  >
                    {project.name}
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}
