import React, { useState } from "react";
import styles from "./Billing.module.scss";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";
import { useFetchOrganization } from "api/resources/organization/organization";
import { Loading } from "components/Loading/Loading";
import { useParams } from "react-router-dom";
import FlexRow from "components/layouts/FlexRow/FlexRow";
import { APISettings, OrgSignatures } from "./OrganizationDetails";

function EmailPref({ roles, user }) {
  const { id } = useParams();
  const MyOrg = useFetchOrganization(id);

  return (
    <div className={styles.page}>
      {MyOrg.isLoading && <Loading></Loading>}
      {MyOrg.isSuccess && (
        <EmailPrefContent
          organization={MyOrg.data.organization}
          role={roles}
          user={user}
        />
      )}
    </div>
  );
}

export default EmailPref;

const providers = [
  {
    value: "Sendgrid",
    label: (
      <div style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
        <img
          src={require("assets/images/sendgrid.webp")}
          style={{ width: "50px" }}
        ></img>
        Sendgrid
      </div>
    ),
  },
  // {
  //   value: "Amazons SES",
  //   label: (
  //     <div style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
  //       <img
  //         src={require("assets/images/amazonses.png")}
  //         style={{ width: "50px"}}
  //       ></img>
  //       Amazon SES
  //     </div>
  //   ),
  // },
  // {
  //   value: "MailChimp",
  //   label: (
  //     <div style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
  //       <img
  //         src={require("assets/images/mailchimp.png")}
  //         style={{ width: "50px" }}
  //       ></img>
  //       MailChimp
  //     </div>
  //   ),
  // },
];

function EmailPrefContent({ organization, role, user }) {
  const [signatures, setSignatures] = useState(false);

  return (
    <>
      <FlexRow
        style={{ flexDirection: "column", alignItems: "flex-start" }}
        start
      >
        <div className={styles.section_label5} style={{ margin: "0" }}>
          {" "}
          Email Preferences
        </div>

        <CombinedInput
          value={{
            value: "Sendgrid",
            label: (
              <div
                style={{ display: "flex", gap: ".5rem", alignItems: "center" }}
              >
                <img
                  src={require("assets/images/sendgrid.webp")}
                  style={{ height: "30px" }}
                ></img>
                Sendgrid
              </div>
            ),
          }}
          label="Email Provider"
          select
          options={providers}
          disableInputOnly
          inputClass={styles.inputStyle}
          icon={"bi-pencil-square"}
          style={{ backgroundColor: "white" }}
          shadow
        />

        <APISettings organization={organization} role={role}></APISettings>

        <div className={styles.section_label5} style={{ margin: "0" }}>
          {" "}
          Organization Signatures
        </div>
        <CombinedInput
          value={"View/Add Signatures"}
          label="Organization Signatures"
          // options={maintimezones}
          // onChange={(val) => {
          //   onChange(val, "timezone");
          //   setTimeZone(val);
          // }}
          // select
          disableInputOnly
          // inputStyle={styles.inputStyle}
          inputClass={styles.inputStyle}
          onInputClick={() => setSignatures(true)}
          icon={"bi-pencil-square"}
          onIconClick={() => setSignatures(true)}
          style={{ backgroundColor: "white" }}
          shadow
        />
        {signatures && (
          <OrgSignatures
            show={signatures}
            setShow={setSignatures}
          ></OrgSignatures>
        )}
      </FlexRow>
    </>
  );
}
