import { useFetchProjectsByIdsGql } from "api/resources/projects/projects";
import Filter from "../Charts/Settings/Filter/Filter";
import { Loading } from "components/Loading/Loading";

export function PageFilter({
  onFilterChange,
  chosenFilter,
  pageProjects,
  custom_fields,
}) {
  const getProjects = useFetchProjectsByIdsGql({
    projectIds: pageProjects,
  });

  return (
    <>
      {getProjects.isError && <div>Error</div>}
      {getProjects.isLoading && <Loading />}
      {getProjects.isSuccess && (
        <Filter
          custom_fields={custom_fields}
          updateChosenFilters={onFilterChange}
          chosenFilter={chosenFilter}
          projects={getProjects.data.survey}
        ></Filter>
      )}
    </>
  );
}
