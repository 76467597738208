import styles from "./Tips.module.scss";
import { useState } from "react";

export default function Tips({ tips, onIgnore, close }) {
  const [open, setOpen] = useState(false);

  return (
    <div
      className={styles.container}
      onClick={open ? undefined : () => setOpen(true)}
      style={
        open ? undefined : { cursor: "pointer", border: "2px solid #feccc5" }
      }
    >
      <div className={styles.exit} onClick={close}>
        <i className="bi bi-x"></i>
      </div>
      {!open && (
        <div className={styles.closed}>
          Best Practices <i className="bi bi-info-circle"></i>
        </div>
      )}
      {open && (
        <div className={styles.open}>
          {tips.map((prob, i) => (
            <>
              {!prob.ignore && (
                <div className={styles.problem}>
                  <div>{prob.title}</div>
                  <div className={styles.divider}>|</div>
                  <div className={styles.ignore} onClick={() => onIgnore(i)}>
                    ignore
                  </div>
                </div>
              )}
            </>
          ))}
        </div>
      )}
    </div>
  );
}
