import { useState } from "react";
import styles from "./SurveyDesign.module.scss";
import { ColorPicker } from "components/inputs/input_fields/ColorPicker/ColorPicker";
import { Label } from "components/layouts/Label/Label";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import { AddColorSimple } from "components/ColorPalette/EditColors/AddColorSimple";

export function FinishedSettings({
  settings,
  changeSetting,
  active,
  setActive,
}) {
  function handleSetActive() {
    active ? setActive("") : setActive("Finished");
  }

  function addColor(color) {
    let copy = settings.confettiColors ? [...settings.confettiColors] : [];
    copy.push(color);
    changeSetting("confettiColors", copy);
  }

  function removeColor(i) {
    let copy = [...settings.confettiColors];
    copy.splice(i, 1);
    changeSetting("confettiColors", copy);
  }

  function changeColor(i, val) {
    let copy = [...settings.confettiColors];
    copy[i] = val;
    changeSetting("confettiColors", copy);
  }

  return (
    <div className={styles.settingContainer}>
      <div
        className={`${
          active ? styles.headerContainerActive : styles.headerContainer
        }`}
        onClick={handleSetActive}
      >
        <div className={styles.header}>
          Finished <i className="bi-check2-circle"></i>
        </div>
        {active ? (
          <i className="bi bi-caret-down-fill"></i>
        ) : (
          <i className="bi bi-caret-left-fill"></i>
        )}
      </div>
      {active && (
        <div className={styles.body}>
          {/* <Label style={{ padding: "0em" }}>Able To</Label>  */}
          <div className={styles.settingHorizontal}>
            <ToggleSwitch
              startChecked={settings.finishWithoutFinishing}
              handleCheck={(val) =>
                changeSetting("finishWithoutFinishing", val)
              }
            ></ToggleSwitch>
            <Label style={{ fontWeight: "400" }}>
              Enable 'Finish' button before completion
            </Label>
          </div>
          <div className={styles.settingHorizontal}>
            <ToggleSwitch
              startChecked={settings.returnable}
              handleCheck={(val) => changeSetting("returnable", val)}
            ></ToggleSwitch>
            <Label style={{ fontWeight: "400" }}>
              Enable to return to survey
            </Label>
          </div>
          <div className={styles.settingHorizontal}>
            <ToggleSwitch
              startChecked={settings.confetti}
              handleCheck={(val) => changeSetting("confetti", val)}
            ></ToggleSwitch>
            <Label style={{ fontWeight: "400" }}>Confetti</Label>
          </div>
          {settings.confetti && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                paddingLeft: "10px",
              }}
            >
              <div className={styles.settingHorizontal}>
                <ToggleSwitch
                  startChecked={settings.useOwnColors}
                  handleCheck={(val) => changeSetting("useOwnColors", val)}
                ></ToggleSwitch>
                <Label style={{ fontWeight: "400" }}>
                  Custom Confetti Colors
                </Label>
              </div>

              {settings.useOwnColors && (
                <div className={styles.confettiColors}>
                  {settings.confettiColors &&
                    settings.confettiColors.map((color, i) => (
                      <ColorPicker
                        defaultColor={color}
                        remove={() => removeColor(i)}
                        onChange={(val) => changeColor(i, val)}
                      />
                    ))}
                  <AddColorSimple onDone={addColor} />
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
