import { useEffect, useState } from "react";
import Button from "components/Button/Button";
import styles from "./EditLogo.module.scss";
import { LogoUpload } from "./LogoUpload";

export default function EditableLogo({
  settings,
  setSettings,
  projectLogo,
  rightCorner,
  onSaveNewImage,
}) {
  const [height, setHeight] = useState(settings.logoSize);

  const [dragging, setDragging] = useState(false);
  const [initialY, setInitialY] = useState(0);
  const [initialX, setInitialX] = useState(0);

  const [maxWidth, setMaxWidth] = useState(636); // max width for largest container size
  const [uploadImg, setUploadImg] = useState(false);

  const handleMouseDown = (e) => {
    setDragging(true);
    setInitialY(e.clientY);
    setInitialX(e.clientX);
  };

  function finishDragging() {
    if (!dragging) return;

    setDragging(false);
    setInitialY(0);
    setInitialX(0);
    let copy = { ...settings };
    copy.logoSize = height;
    setSettings(copy);
  }

  const handleMouseMove = (e) => {
    if (!dragging) return;

    let logo = document.getElementById("logo");
    if (logo) {
      const width = logo.clientWidth;

      const deltaY = e.clientY - initialY;
      const deltaX = rightCorner ? initialX - e.clientX : e.clientX - initialX;

      let resultY = height + deltaY;
      let resultX = width + deltaX;

      if (resultX >= 50 && resultX < maxWidth && resultY >= 15) {
        setHeight(resultY);
        setInitialY(e.clientY);
        setInitialX(e.clientX);
      }
    }
  };

  function checkMaxWidth() {
    let logoContainer = document.getElementById("logoContainer");
    if (logoContainer) {
      let maxWidth = logoContainer.clientWidth - 32; // - 16 pixels of padding on each side (16 = 1em)
      setMaxWidth(maxWidth);
    }
  }

  useEffect(() => {
    checkMaxWidth();
    window.addEventListener("resize", checkMaxWidth);
    return () => window.removeEventListener("resize", checkMaxWidth);
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent:
            settings.logoAlign == "center"
              ? "center"
              : settings.logoAlign == "right"
              ? "end"
              : "start",

          backgroundColor: settings.logoBackgroundColor
            ? settings.logoBackgroundColor
            : "#efefef",
          padding: "16px",
        }}
        className={styles.logoBigContainer}
        onMouseMove={dragging ? handleMouseMove : null}
        onMouseUp={finishDragging}
        onMouseLeave={finishDragging}
        id="logoContainer"
      >
        <div className={styles.logoBox}>
          <div
            className={styles.dragBox}
            style={{
              paddingLeft: `${rightCorner ? "7" : "0"}px`,
              paddingRight: `${rightCorner ? "0" : "7"}px`,
            }}
          >
            <div className={styles.changeLogoDiv}>
              <img
                style={{
                  height: `${height}px`,
                  maxWidth: `${maxWidth}px`,
                }}
                src={
                  projectLogo != null
                    ? projectLogo.imageURL
                    : "/static/media/reactionlogodark.7d895fefebc952458ad6.png"
                }
                alt="survey logo"
                id="logo"
              />
              <div className={styles.logoCover}>
                <div className={styles.edit}>
                  <Button onClick={() => setUploadImg(true)}>Edit</Button>
                </div>
              </div>
            </div>
            <div
              className={`${styles.dragHandle}  ${
                rightCorner ? styles.downLeft : styles.downRight
              }`}
              style={
                rightCorner
                  ? { left: 0, cursor: "nesw-resize" }
                  : { right: 0, cursor: "nwse-resize" }
              }
              onMouseDown={handleMouseDown}
            >
              <i className="bi bi-caret-down-fill"></i>
            </div>
          </div>
        </div>
      </div>
      {uploadImg && (
        <LogoUpload
          onSave={onSaveNewImage}
          onCancel={() => setUploadImg(false)}
          height={height}
        />
      )}
     
    </>
  );
}
