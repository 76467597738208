import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./ReportsPage.module.scss";
import { useFetchReportByExclusiveId } from "api/resources/organization/reports";
import { Loading } from "components/Loading/Loading";
import InteractiveReportTable from "components/tables/BasicTable/InteractiveReportTable";
import CreateReport from "./CreateReport";
import Modal from "components/ReactModal/ReactModal";
import Button from "components/Button/Button";
import { useNewLoginRequest } from "api/resources/authentication/login";

function OrganizationReports({
  roles,
  organization,
  user,
  creating,
  type,
  icon,
}) {
  const [searchString, setSearchString] = useState("");
  const [confirmChange, setConfirm] = useState(false);
  const [report, setReport] = useState();
  const loginRequest = useNewLoginRequest();

  let navigate = useNavigate();
  const routeChange = (item) => {
    if (item?.organization?.id != organization?.id) {
      setReport(item);
      setConfirm(true);
    } else {
      navigate("/report/" + item.id);
    }
  };

  function signin(report) {
    loginRequest.mutate(
      {
        orgid: report?.organization?.id,
      },
      {
        onSuccess: () => {
          // setToken(undefined);
          navigate("/report/" + report.id);
          // localStorage.removeItem("reaction_token");
          window.location.reload();
        },
      }
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1em",
        width: "100%",
      }}
    >
      <Container
        routeChange={routeChange}
        searchString={searchString}
        setSearchString={setSearchString}
        roles={roles}
        organization={organization}
        user={user}
        type={type}
        icon={icon}
      />

      <Modal
        show={confirmChange}
        modalStyle={{
          borderRadius: "1em",
          padding: "1.5em 1em",
          maxWidth: "800px",
        }}
        onClose={() => setConfirm(false)}
      >
        <div className={styles.text}>
          {" "}
          <a>"{report?.name}"</a> is part of{" "}
          <span>{report?.organization?.name}</span>.<br /> You are currently
          signed into <span>{organization?.name}</span>. <br />
          Sign out of <span>{organization?.name}</span> and sign into{" "}
          <span>{report?.organization?.name}</span> to view this report?
        </div>

        <div
          style={{
            display: "flex",
            gap: "10px",
            width: "100%",
            justifyContent: "center",
            marginTop: "10;x",
          }}
        >
          <Button red shadow onClick={() => setConfirm(false)}>
            Cancel
          </Button>{" "}
          <Button shadow onClick={() => signin(report)}>
            Sign in
          </Button>
        </div>
      </Modal>
    </div>
  );
}

export default OrganizationReports;

function Container({
  routeChange,
  searchString,
  setSearchString,
  organization,
  roles,
  user,
  type,
  icon,
}) {
  // const createReport = useCreateBlankUserReport();

  const [showCreate, setShowCreate] = useState(false);

  const userheaders = [
    {
      id: 0,
      index: 0,
      name: "Type",
      accessor: "type",
      enabled: true,
      sort: false,
      canSort: false,
      cell_style: (value, id) => (
        <div
          className={styles.reportType}
          onClick={() => routeChange({ id: id })}
        >
          {value === "Basic" ? (
            <i className="bi bi-square"></i>
          ) : (
            <i className="bi bi-easel"></i>
          )}{" "}
          {value}
        </div>
      ),
    },
    {
      id: 1,
      index: 0,
      name: "Report Name",
      accessor: "name",
      enabled: true,
      sort: false,
      canSort: true,
      cell_style: null,
    },
    {
      id: 2,
      index: 0,
      name: "Created",
      accessor: "createdAt",
      enabled: true,
      sort: true,
      canSort: true,
      cell_style: null,
    },
    {
      id: 3,
      index: 0,
      name: "Survey",
      accessor: "project",
      objectAccessor: "name",
      enabled: true,
      sort: false,
      canSort: false,
      cell_style: null,
    },
  ];

  const [pageNumber, setPageNumber] = useState(0);
  const [perPage, setPerpage] = useState(0);
  const [pageSkip, setPageSkip] = useState(0);
  const [sort, setSort] = useState({
    item: "updatedAt",
    descend: true,
  });

  const reportOrg = useFetchReportByExclusiveId(
    "",
    pageSkip,
    perPage,
    sort,
    type === "Home" ? "Organization" : type
  );

  useEffect(() => {
    reportOrg.refetch();
  }, [pageNumber, searchString, perPage, sort]);

  function newReport() {
    setShowCreate(true);
  }

  return (
    <>
      {(!reportOrg?.data?.reports || reportOrg.isLoading) && (
        <Loading></Loading>
      )}
      {reportOrg?.data?.reports && reportOrg.isSuccess && (
        <InteractiveReportTable
          headers={userheaders}
          queryData={reportOrg}
          queryDataAccessor={"reports"}
          // maxDataQuery={fetchUserReportCount}
          maxDataQueryAccessor={"count"}
          currPage={pageNumber}
          setCurrPage={setPageNumber}
          maxItems={perPage}
          onRowClick={routeChange}
          sort={sort}
          setSort={setSort}
          setPageSkip={setPageSkip}
          perPage={perPage}
          setPerPage={setPerpage}
          searchString={searchString}
          setSearchString={setSearchString}
          actionButtonText={<div style={{display: "flex", gap: ".5rem"}}><i className="bi-pencil-square"></i> Create Report</div>}
          tableTitle={"Analyze Survey Results"}
          tableDescription={`Reports created by you or shared with you for analyzing your survey results even further. `}
          widgetType="report"
          searchPlaceholder={"Search Reports..."}
          onClickActionButton={newReport}
          // viewing={viewing}
          // setViewing={setViewing}
          hideActionButton={!roles.canCreateEditPersonalReports}
          roles={roles}
          user={user}
          organization={organization}
          type={type}
          icon={icon}
        ></InteractiveReportTable>
      )}{" "}
      {showCreate && <CreateReport close={() => setShowCreate(false)} />}
    </>
  );
}
