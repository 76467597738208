/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/

import { useEffect, useState } from "react";
import { BarChartVertical } from "components/Charts/BarChartVertical/BarChartVertical";
import { BarChartHorizontal } from "components/Charts/BarChartHorizontal/BarChartHorizontal";
import { DoughnutChart } from "components/Charts/DoughnutChart/DoughnutChart";
import { PieChart } from "components/Charts/PieChart/PieChart";
import { LineChart } from "components/Charts/LineChart/LineChart";
import {
  DoughnutType,
  HorizBarType,
  LineType,
  PieType,
  VertBarType,
} from "pages/results/Charts/QuestionChart";
import CustomLegend from "./Legend";
import styles from "./ChartStyles.module.scss";
import { forEach } from "assets/functions/ArrayFunctions";
import TextEditorTry from "components/inputs/input_fields/TextEditorTry/TextEditorTry";

export default function Chart({
  viz,
  data,
  onSegClick,
  thumbnail,
  idAddOn,
  reDraw,
  update,
  saveData,
  inEdit,
}) {
  useEffect(() => {
    if (update && saveData && data) {
      saveData(data);
    }
  }, []);

  function getSettings() {
    let settings = viz.designSettings;
    if (thumbnail) {
      settings = JSON.parse(JSON.stringify(settings));
      settings.hasDataLabels = false;
    }
    return settings;
  }

  const settings = getSettings();

  const initVisible = () => {
    let visible = [];
    if (data?.datasets?.length > 1) {
      forEach(data?.datasets, () => visible?.push(true));
    } else {
      if (data?.labels && Object.keys(data?.labels)?.length > 0) {
        forEach(data?.labels, () => {
          visible?.push(true);
        });
      }
    }
    return visible;
  };

  const updateData = () => {
    let newData = {};
    if (
      data?.datasets &&
      (data.datasets?.length > 1 || data.datasets[0]?.label) &&
      data.datasets[0]?.label
    ) {
      // split data
      newData.labels = data?.labels;
      newData.datasets = [];
      for (let i = 0; i < data.datasets.length; i++) {
        if (visible[i]) {
          newData.datasets.push(data.datasets[i]);
        }
      }
      newData.stacked = data.stacked;
    } else {
      newData.labels = [];
      newData.datasets = [
        {
          data: [],
          backgroundColor: [],
        },
      ];
      let c = 0;
      for (let i = 0; i < data.labels?.length; i++) {
        if (data.datasets[0]) {
          if (visible[i]) {
            newData.labels.push(data.labels[i]);
            newData.datasets[0]?.data.push(data.datasets[0]?.data[i]);
            newData.datasets[0]?.backgroundColor.push(
              data.datasets[0]?.backgroundColor[c]
            );
          } else if (viz.type === DoughnutType || viz.type == PieType) {
            newData.labels.push(data.labels[i]);
            newData.datasets[0]?.data.push(0);
            newData.datasets[0]?.backgroundColor.push(
              data.datasets[0].backgroundColor[c]
            );
          }
        }
        c < data.datasets[0]?.backgroundColor.length - 1 ? c++ : (c = 0);
      }

      if (viz.type === LineType) {
        newData.datasets[0].borderColor = data.datasets[0]?.borderColor;
        if (newData.datasets[0]?.borderColor && data.datasets[0]?.borderColor) {
          newData.datasets[0].borderColor = data.datasets[0]?.borderColor;
        }
      }
    }

    if (viz.type !== LineType) {
      forEach(newData.datasets, (set) => (set.borderWidth = 0));
    } else {
      let a = 0; // actual
      for (let i = 0; i < data.datasets.length; i++) {
        if (visible[i]) {
          newData.datasets[a].pointRadius = data.datasets[a].pointRadius;
          newData.datasets[a].borderWidth = data.datasets[a].borderWidth;
          newData.datasets[a].pointBorderWidth =
            data.datasets[a].pointBorderWidth;
          a++;
        }
      }
    }

    setFilteredData(newData);
  };

  const [filteredData, setFilteredData] = useState(
    JSON.parse(JSON.stringify(data))
  ); // Used For Data Filtering on Legends
  const [visible, setVisible] = useState(initVisible());
  const [oldData, setOldData] = useState(JSON.stringify(data));
  const [show, setShow] = useState(true);

  function reload() {
    // This function is required because since we are using our own legend here, the canvas will not redraw with changes to the legend. It has to be completely redrawn
    setShow(false);
    setTimeout(() => setShow(true), inEdit ? 350 : 1);
  }

  useEffect(() => {
    const stringified = JSON.stringify(data);
    if (stringified !== oldData) {
      setVisible(initVisible());
      setOldData(stringified);
    }
  }, [data]);

  useEffect(() => {
    updateData();
  }, [visible]);

  useEffect(() => {
    reload();
  }, [
    settings.legendPosition,
    settings.hasLegendTitle,
    settings.hasTitle,
    settings.hasLegend,
    settings.legendLabel,
    settings.legendValue,
    settings.legendPercent,
    settings.cutout,
    settings.barPercentage,
    reDraw,
    viz.type,
  ]);

  function responsiveFonts(size) {
    if (window.outerWidth > 700) {
      return size;
    } else if (window.outerWidth < 700 && window.outerWidth > 400) {
      return size - 1;
    } else if (window.outerWidth < 400) {
      return size - 2;
    }
  }

  function getPercentOfTotal(val, ctx) {
    let sum = 0;
    if (!data.datasets[ctx.datasetIndex]) {
      return "";
    }
    forEach(data.datasets[ctx.datasetIndex].data, (tally) => {
      sum += tally;
    });

    let percentage = ((val * 100) / sum).toFixed(
      viz.designSettings.dataLabelPercent.sigFigs
    );
    if (percentage == 0) {
      return "";
    }
    return percentage + "%";
  }

  const circleLR =
    (settings.legendPosition === "left" ||
      settings.legendPosition === "right") &&
    (viz.type === DoughnutType || viz.type === PieType) &&
    settings.hasLegend;

  return (
    <div
      className={styles.legendNChart}
      style={
        settings.legendPosition === "top" ||
        settings.legendPosition === "bottom"
          ? { flexDirection: "column" }
          : {
              flexDirection: "row",
            }
      }
      id={"legendNChart" + viz.id + idAddOn}
    >
      {(settings.legendPosition === "top" ||
        settings.legendPosition === "left") &&
        settings.hasLegend && (
          <CustomLegend
            data={data}
            onClick={updateData}
            visible={visible}
            settings={settings}
            type={viz.type}
            id={viz.id + idAddOn}
            thumbnail={thumbnail}
            title={viz.title}
          />
        )}

      <div
        className={`${circleLR ? styles.chart2 : styles.chart}`}
        style={
          settings.legendPosition === "bottom" ||
          settings.legendPosition === "top"
            ? { width: "100%" }
            : {
                height: "95%",
              }
        }
        id={"chart" + viz.id + idAddOn}
      >
        {show && (
          <>
            {viz.type === VertBarType && (
              <BarChartVertical
                data={filteredData}
                settings={settings}
                onSegClick={onSegClick}
                responsiveFonts={responsiveFonts}
                getPercentOfTotal={getPercentOfTotal}
                thumbnail={thumbnail}
                containerId={viz.id}
              />
            )}
            {viz.type === HorizBarType && (
              <BarChartHorizontal
                data={filteredData}
                settings={settings}
                onSegClick={onSegClick}
                responsiveFonts={responsiveFonts}
                getPercentOfTotal={getPercentOfTotal}
                thumbnail={thumbnail}
              />
            )}
            {viz.type === DoughnutType && (
              <DoughnutChart
                data={filteredData}
                settings={settings}
                onSegClick={onSegClick}
                responsiveFonts={responsiveFonts}
                getPercentOfTotal={getPercentOfTotal}
                thumbnail={thumbnail}
              />
            )}
            {viz.type === PieType && (
              <PieChart
                data={filteredData}
                settings={settings}
                onSegClick={onSegClick}
                responsiveFonts={responsiveFonts}
                getPercentOfTotal={getPercentOfTotal}
                thumbnail={thumbnail}
              />
            )}
            {viz.type === LineType && (
              <LineChart
                data={filteredData}
                settings={settings}
                onSegClick={onSegClick}
                responsiveFonts={responsiveFonts}
                getPercentOfTotal={getPercentOfTotal}
                thumbnail={thumbnail}
              />
            )}
          </>
        )}
      </div>

      {(settings.legendPosition === "bottom" ||
        settings.legendPosition === "right") &&
        settings.hasLegend && (
          <CustomLegend
            data={data}
            onClick={updateData}
            visible={visible}
            settings={settings}
            type={viz.type}
            id={viz.id + idAddOn}
            thumbnail={thumbnail}
            title={viz.title}
          />
        )}
    </div>
  );
}

export function OldTextBox({ viz, box, index, setOffsetData, value }) {
  const [update, setUpdate] = useState(false);

  function drag_start(event) {
    // debugger;
    var style = window.getComputedStyle(event.target, null);
    let offset_data =
      parseInt(style.getPropertyValue("left"), 10) -
      event.clientX +
      "," +
      (parseInt(style.getPropertyValue("top"), 10) - event.clientY);

    offset_data += "," + index;
    setOffsetData(offset_data);
    event.dataTransfer.setData("text/plain", offset_data);
  }

  useEffect(() => {
    var dm = document.getElementById("textbox:" + index + ":" + viz.id);
    dm.addEventListener("dragstart", drag_start, false);
  }, []);

  useEffect(() => {
    setUpdate(true);
    setTimeout(() => {
      setUpdate(false);
    }, 500);
  }, [box.valuetype, box.state]);

  function getState() {
    if (box.valuetype === "static") {
      return box.state;
    }
    if (box.valuetype === "npsscore") {
      return `NPS Score: ${value.score}`;
    }
    if (box.valuetype === "average") {
      return `Average = ${Math.round(value)}`;
    }
    if (box.valuetype === "responseRate") {
      return `Response Rate = ${value}`;
    }
    if (box.valuetype === "answercount") {
      return `N = ${value}`;
    }
    if (box.valuetype === "audienceSize") {
      return `Audience Size = ${value}`;
    }
  }

  return (
    <div
      style={{
        top: box.top + "%",
        right: box.stickRight ? "0px" : "",
        left: box.stickLeft ? "0px" : !box.stickRight ? box.left + "%" : "",
        color: box.color,
        backgroundColor: box.hasBackground
          ? box.backgroundColor
          : "transparent",
        boxShadow: !box.boxShadow ? "none" : "",
        width: box.width ? box.width : "",
        cursor: "pointer",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: box.padding ? box.padding : "",
        marginRight: box.stickRight
          ? box.marginRight
            ? box.marginRight
            : 45
          : "",
        marginLeft: box.stickLeft ? (box.marginLeft ? box.marginLeft : 45) : "",
      }}
      id={"textbox:" + index + ":" + viz.id}
      draggable={true}
      className={styles.textBox}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          cursor: "pointer",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {!update && (
          <TextEditorTry
            encoding={getState()}
            editable={false}
            placeholder="Enter text..."
            defaultStyle={{
              fontSize: "16px",
              color: box.color,
            }}
            circle
          ></TextEditorTry>
        )}
      </div>
    </div>
  );
}
