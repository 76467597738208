import React, { useEffect, useState } from "react";
import { ResponsiveReactGridLayout } from "./Dash";
import styles from "./Dash.module.scss";
import _ from "lodash";
import { useCreateDashboard } from "api/resources/account/dashboard";
import Widget from "./Widgets/Widget";
import { forEach } from "assets/functions/ArrayFunctions";

// const h = 2;
// const w = 2;
// const minH = 1;
// const maxH = 4;
// const maxW = 2;
// const minW = 2;
// const firstLayouts = {
//   lg: [
//     {
//       x: 0,
//       y: 2,
//       w: 4,
//       h: 2,
//       i: "0",
//       minH: 2,
//       maxH,
//       maxW,
//       minW,
//       static: false,
//     },
//     {
//       x: 5,
//       y: 2,
//       w: 2,
//       h: 4,
//       i: "1",
//       static: false,
//     },
//     {
//       x: 0,
//       y: 4,
//       w: w,
//       h: h,
//       i: "2",
//       static: false,
//     },
//     {
//       x: 2,
//       y: 4,
//       w: w,
//       h: h,
//       i: "3",
//       static: false,
//     },
//   ],
// };

function DashGrid({
  widgets,
  layouts,
  setLayouts,
  setAbove,
  itemToBeDropped,
  editing,
  canEdit,
  sendBackLayouts,
  addWidget,
  plusWidget,
  settingsClick,
}) {
  const className = "layout";
  const rowHeight = 170;
  const cols = { lg: 6, md: 4, sm: 2, xs: 1, xxs: 1 };
  const breakpoints = { lg: 1200, md: 700, sm: 380, xs: 200, xxs: 0 };
  const containerPadding = [0, 0];

  const [currentBreakpoint, setCurrentBreakpoint] = useState("lg");
  const [compactType, setCompactType] = useState("vertical");
  const [mounted, setMounted] = useState(false);
  const [toolbox, setToolbox] = useState({
    lg: [],
  });
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });
  const [showDropTarget, setShowDropTarget] = useState(false);

  function handleResize() {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  }

  useEffect(() => {
    setMounted(true);
    window.addEventListener("resize", handleResize);
  }, []);

  const onBreakpointChange = (breakpoint) => {
    // what's this?
    setCurrentBreakpoint(breakpoint);
    setToolbox({
      ...toolbox,
      [breakpoint]: toolbox[breakpoint] || toolbox[currentBreakpoint] || [],
    });
  };

  const onCompactTypeChange = () => {
    let oldCompactType = "";

    const compactType =
      oldCompactType === "horizontal"
        ? "vertical"
        : oldCompactType === "vertical"
        ? null
        : "horizontal";
    setCompactType(compactType);
  };

  const onLayoutChange = (layout, layouts) => {
    // console.log("layout change", layouts);
    setLayouts({ ...layouts });
  };

  function onDrop(layout, layoutItem, _event) {
    let temps = { ...layouts };
    forEach(layout, (l) => (l.isDraggable = undefined));

    let space = document.getElementById("gridContainer")
      ? document.getElementById("gridContainer").offsetWidth
      : undefined;

    for (let size in temps) {
      temps[size].push(layoutItem);
    }

    if (space) {
      if (space > 1200) {
        temps.lg = layout;
      } else if (space > 700) {
        temps.md = layout;
      } else if (space > 380) {
        temps.sm = layout;
      } else if (space > 200) {
        temps.xs = layout;
      } else {
        temps.xxs = layout;
      }
    } else {
      temps.lg = layout;
    }

    addWidget(temps);

    setTimeout(() => {
      // Give it a kind of drop in affect if you wait to add it
      setLayouts(temps);
    }, 500);
  }

  function checkMaxWidth() {
    if (1475 > dimensions.width && dimensions.width > 960) {
      return "100%";
    } else if (959 > dimensions.width && dimensions.width > 500) {
      return "100%";
    } else {
      return "100%";
    }
  }

  function blankSlateDrop(ev) {
    ev.preventDefault();

    let newLayout = {
      lg: [
        {
          x: 0,
          y: 0,
          w: itemToBeDropped.w,
          h: itemToBeDropped.h,
          i: itemToBeDropped.i,
          static: false,
          isDraggable: undefined,
        },
      ],
    };

    setShowDropTarget(false);
    setLayouts(newLayout);
    addWidget(newLayout);
  }

  const generateDOM = () => {
    function checkWidth(w) {
      if (w === 1) {
        return "12em";
      } else if (w === 2) {
        return "27em";
      } else {
        return "";
      }
    }

    let space = document.getElementById("gridContainer")
      ? document.getElementById("gridContainer").offsetWidth
      : undefined;

    let currLayouts = layouts.lg;
    if (layouts.md && space && 1251 > space && space > 700) {
      currLayouts = layouts.md;
    }

    return _.map(currLayouts, function (l, i) {
      let content = widgets[i];

      let width = checkWidth(l.w);

      return (
        <div key={i} style={{ maxWidth: width }}>
          <div
            className={`${editing ? styles.earthquake : styles.setInStone} ${
              styles.widgetHolder
            }`}
            style={{
              cursor: editing ? "grab" : "default",
            }}
          >
            {/* <div className={styles.delete}>
              <i
                className={`bi bi-trash3 ${styles.trash}`}
                // onClick={() => onDelete(content)}
              ></i>
              <i
                className={`bi bi-trash3-fill ${styles.trashFill}`}
                onClick={() => onDelete(content)}
              ></i>
            </div> */}

            {content && (
              <>
                {canEdit && (
                  <div className={styles.settings}>
                    <div className={styles.gearIcon}>
                      <i className="bi bi-gear"></i>
                    </div>
                    <div
                      className={styles.gearIconFill}
                      onClick={() => settingsClick(content, l)}
                    >
                      <i className="bi bi-gear-fill"></i>
                    </div>
                  </div>
                )}

                <Widget
                  widget={content}
                  layout={l}
                  setAbove={setAbove}
                  inEdit={editing}
                  canEdit={canEdit}
                />
              </>
            )}
          </div>
        </div>
      );
    });
  };

  // console.log(itemToBeDropped);

  return (
    <>
      {widgets.length > 0 && (
        <div
          className={styles.gridBox}
          id="gridContainer"
          style={{ maxWidth: checkMaxWidth() }}
        >
          {layouts && (
            <ResponsiveReactGridLayout
              breakpoints={breakpoints}
              className={className}
              rowHeight={rowHeight}
              cols={cols}
              containerPadding={containerPadding}
              layouts={layouts}
              measureBeforeMount={false}
              useCSSTransforms={mounted}
              compactType={compactType}
              // preventCollision={!compactType}
              onLayoutChange={onLayoutChange}
              onBreakpointChange={onBreakpointChange}
              onDrop={onDrop}
              isDroppable={true}
              isDraggable={editing}
              isResizable={false}
              // resizeHandle={}
              resizeHandles={["se"]}
              margin={[15, 15]}
              droppingItem={itemToBeDropped}
              // onDragStart={e => e.dataTransfer.setData('text/plain', '')} // For Firefox?
              // onResize={resize}
            >
              {generateDOM()}
            </ResponsiveReactGridLayout>
          )}
        </div>
      )}
      {widgets.length == 0 && (
        <div
          className={styles.noWidgets}
          id="dropCont"
          onDragEnter={() => setShowDropTarget(true)}
          onDragExit={() => setShowDropTarget(false)}
        >
          <div>No Widgets Currently</div>
          <div style={{ fontSize: ".9em" }}>
            click{" "}
            <span
              style={{ color: "#15bcc7", cursor: "pointer" }}
              onClick={plusWidget}
            >
              + Widget
            </span>{" "}
            to add one
          </div>
          {showDropTarget && (
            <div
              className={styles.blankDropTarget}
              onDragLeave={(e) => {
                e.preventDefault();
                setShowDropTarget(false);
              }}
              onDrop={blankSlateDrop}
              onDragOver={(e) => e.preventDefault()}
            ></div>
          )}
        </div>
      )}
    </>
  );
}

export default DashGrid;
