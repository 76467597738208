import React, { useEffect, useState } from "react";
import styles from "./NavBar.module.scss";
import { MenuItem } from "./MenuItem";

/**
 * A generic sub sidebar used throghout the website
 * @param {Array} menuItems array of objects that have the menuItems and their paths to go to
 * @param {Int} active id of the active menuItem
 * @param {Function} updateActive a function that is called when a menuItem is clicked on. Updates the active id
 * @returns {React.ReactElement} a Sidebar2 component
 */

export const NavBar = ({
  menuItems,
  active,
  updateActive,
  left,
  folder,
  beforeNavAway,
  setBeforeNavAway,
}) => {
  const [show, setShow] = useState(false);
  const [showButton, setShowButton] = useState(
    window.innerWidth > 1024 ? false : true
  );

  useEffect(() => {
    const resizeListener = () => {
      let width = window.innerWidth;
      // change width from the state object
      if (width < 1024) {
        setShowButton(true);
        setShow(false);
      } else if (width > 1024) {
        setShowButton(false);
        setShow(true);
      }
    };
    // set resize listener
    window.addEventListener("resize", resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  return (
    <>
      <div className={`${styles.menuContainer} ${show && styles.showStyle}`}>
        {showButton && (
          <div
            className={styles.icon}
            onClick={() => {
              setShow(!show);
            }}
          >
            <i className="bi bi-list"></i>
          </div>
        )}
        {(window.innerWidth > 1024 || show) && (
          <div
            className={
              window.innerWidth > 1024 ? styles.menu : styles.menuMobile
            }
            style={{ right: left ? "none" : "", left: left ? "0" : "" }}
            onMouseLeave={() => setShow(false)}
          >
            {menuItems.map((menuItem, i) => (
              <div key={i}>
                {menuItem && menuItem != "" && (
                  <MenuItem
                    key={menuItem?.id}
                    id={menuItem?.id}
                    label={menuItem?.name}
                    to={menuItem?.to}
                    iconClassName={menuItem?.iconClassName}
                    condensed
                    isActive={active == menuItem?.id}
                    type={menuItem?.type}
                    subMenus={menuItem?.subMenus}
                    makeActive={(index, type) =>
                      updateActive && !menuItem?.noChange
                        ? updateActive(index, type)
                        : ""
                    }
                    hasFolders={folder}
                    beforeNavAway={beforeNavAway}
                    setBeforeNavAway={setBeforeNavAway}
                    index={i}
                    last={i === menuItems?.length - 1}
                  />
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
