import styles from "../WidgetSettings.module.scss";
import { Label } from "components/layouts/Label/Label";
import { useEffect, useRef, useState } from "react";
import { DndContext, DragOverlay, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  restrictToVerticalAxis,
  restrictToParentElement,
} from "@dnd-kit/modifiers";
import { useGetUnIncludedReports } from "api/resources/organization/reports";
import { useGetCurrentUser } from "api/resources/organization/users";

export default function ReportPins({
  settings,
  changeSettings,
  visible,
  setVisible,
  addToDeleted,
}) {
  // const [active, setActive] = useState();
  const getUnIncluded = useGetUnIncludedReports({
    list: settings.reports
      .map((r) => r.id)
      .toSorted((a, b) => {
        // So you don't re-query unneccesarily
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      }),
  });
  const getCurrUser = useGetCurrentUser();

  const [addNewPin, setAddNewPin] = useState(false);

  function removeOne(id) {
    let reports = [...settings.reports];
    let index = reports.indexOf(id);
    reports.splice(index, 1);
    changeSettings("reports", reports);
    addToDeleted(id);
  }

  function handleDragOver({ active, over }) {
    if (active.id !== over?.id) {
      let oldList = [...settings.reports];
      let indexOfActive = oldList.findIndex((r) => r.id === active.id);
      let indexOfOver = oldList.findIndex((r) => r.id === over.id);

      let newList = [...oldList];
      newList.splice(indexOfActive, 1, oldList[indexOfOver]);
      newList.splice(indexOfOver, 1, oldList[indexOfActive]);
      changeSettings("reports", newList);
    }
  }

  function addReport(report) {
    let newList = [...settings.reports];
    newList.push({
      id: report.id,
      name: report.name,
      content: report.content,
    });
    changeSettings("reports", newList);
    setAddNewPin(false);
  }

  function handleRemove(report) {
    let newList = [...settings.reports];
    let index = newList.findIndex((r) => r.id === report.id);
    newList.splice(index, 1);
    changeSettings("reports", newList);
  }

  return (
    <>
      <div
        className={`${styles.header} ${visible ? styles.headervisible : ""}`}
        onClick={setVisible}
      >
        {"Report Pins"}
        <span className={styles.accordionicon}>
          <i className="bi bi-caret-left-fill"></i>
        </span>
      </div>
      {visible && (
        <>
          <div className={styles.body}>
            <Label style={{ fontWeight: "600", color: "#8dabb2" }}>
              Pinned Reports
            </Label>
            <div
              className={styles.col}
              style={{ gap: "15px", alignItems: "center" }}
            >
              <DndContext
                // sensors={sensors}
                collisionDetection={closestCenter}
                // onDragOver={handleDragOver}
                modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                onDragOver={handleDragOver}
                // onDragStart={({active}) => setActive(active.id)}
                // onDragEnd={() => setActive(null)}
              >
                <SortableContext
                  id={"Chart Widgets"}
                  items={settings?.reports.map((r) => r.id)}
                  strategy={verticalListSortingStrategy}
                >
                  {settings.reports.map((r) => (
                    <ReportPin report={r} remove={() => handleRemove(r)} />
                  ))}
                </SortableContext>
                {/* <DragOverlay>
                  {active ? (
                    <ReportPin
                      report={settings.reports.find((r) => r.id === active)}
                    />
                  ) : null}
                </DragOverlay> */}
              </DndContext>

              {!addNewPin &&
                getUnIncluded.isSuccess &&
                getCurrUser.isSuccess && (
                  <div
                    className={styles.plusChart}
                    onClick={() => setAddNewPin(true)}
                  >
                    + Report Pin
                  </div>
                )}
              {addNewPin && (
                <AddPin
                  options={getUnIncluded.data.allReportsNotInList}
                  onClickOut={() => setAddNewPin(false)}
                  addPin={addReport}
                  userId={getCurrUser.data.me.id}
                />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}

function ReportPin({ report, remove }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: report.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.9 : 1,
    zIndex: isDragging ? 10 : 0,
    justifyContent: "space-between",
    gap: "10px",
    width: "95%",
  };

  return (
    <div className={styles.row} style={style} ref={setNodeRef} {...attributes}>
      <div className={styles.minus} onClick={remove}>
        -
      </div>
      <div className={styles.pinnedReport}>
        {report?.symbol && <div className={styles.symbol}>{report.symbol}</div>}
        <div className={styles.text}>
          <div className={styles.reportTitle}>{report.name}</div>
          <div className={styles.subtitle}>{report.content}</div>
        </div>
      </div>
      <i
        className={`${styles.grip} bi bi-list`}
        style={isDragging ? { cursor: "grabbing" } : undefined}
        {...listeners}
      ></i>
    </div>
  );
}

function AddPin({ onClickOut, options, addPin, userId }) {
  const sortOptions = () => {
    let mine = [];
    let orgs = [];
    let shared = [];

    for (let r of options) {
      if (r.user) {
        shared.push(r);
      } else if (r.organizationId) {
        orgs.push(r);
      } else {
        mine.push(r);
      }
    }

    return { mine: mine, orgs: orgs, shared: shared };
  };

  const [reports, setReports] = useState(sortOptions());

  useEffect(() => setReports(sortOptions()), [options]);

  function clickOutside(e) {
    if (ref.current && !ref.current?.contains(e.target)) {
      onClickOut(e);
      document.removeEventListener("click", clickOutside, true);
    }
  }

  useEffect(() => document.addEventListener("click", clickOutside, true), []);

  const ref = useRef();

  const onClick = (p) => {
    document.removeEventListener("click", clickOutside, true);
    addPin(p);
  };

  return (
    <div ref={ref} className={styles.customDropDown} style={{ gap: "10px" }}>
      {reports.mine.length > 0 && (
        <div className={styles.reportSectionBlock}>
          <div className={styles.addPinnSectionHeader}>MyReports</div>
          {reports.mine.map((r) => (
            <div
              className={styles.addReportPin}
              key={r.id}
              onClick={() => onClick(r)}
            >
              <div className={styles.newReportPin}>
                <div>{r.name}</div>
                {r.content && <div className={styles.desc}>{r.content}</div>}
              </div>
            </div>
          ))}
        </div>
      )}

      {reports.shared.length > 0 && (
        <div className={styles.reportSectionBlock}>
          <div className={styles.addPinnSectionHeader}>Shared with me</div>
          {reports.shared.map((r) => (
            <div
              className={styles.addReportPin}
              key={r.id}
              onClick={() => onClick(r)}
            >
              <div className={styles.newReportPin}>
                <div>{r.name}</div>
                {r.content && <div className={styles.desc}>{r.content}</div>}
                <div className={styles.sharedByPerson}>{`from ${
                  r.user?.firstName ? r.user.firstName : ""
                } ${r.user?.lastName ? r.user.lastName : ""}`}</div>
              </div>
            </div>
          ))}
        </div>
      )}

      {reports.orgs.length > 0 && (
        <div className={styles.reportSectionBlock}>
          <div className={styles.addPinnSectionHeader}>
            Organization Reports
          </div>
          {reports.orgs.map((r) => (
            <div
              className={styles.addReportPin}
              key={r.id}
              onClick={() => onClick(r)}
            >
              <div className={styles.newReportPin}>
                <div>{r.name}</div>
                {r.content && <div className={styles.desc}>{r.content}</div>}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
