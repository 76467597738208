// External
import React, { useEffect, useRef, useState } from "react";

// Internal
import Button from "components/Button/Button";
import { SelectFieldCustom, TextFieldSimple } from "components/inputs";
import ReactModal from "components/ReactModal/ReactModal.jsx";
import styles from "./PersonalLink.module.scss";
import {
  useDeleteDistribution,
  useFetchAudContactsGql,
} from "api/resources/projects/distributions";
import { TabPill } from "components/layouts";
import { act } from "react-dom/test-utils";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "components/Loading/Loading";
import {
  useCreateParticipation,
  useCreateParticipationFromAud,
} from "api/resources/projects/participations";
import { useSearchContact } from "api/resources/contacts/contacts";
import { Label } from "components/layouts/Label/Label";
import { NavTextField } from "components/inputs/input_fields/NavTextField/NavTextField";
const menuItems = [
  {
    id: 0,
    name: "By Audience",
    // to: `emails`,
    icon: "envelope",
  },
  {
    id: 1,
    name: "By Contact",
    // to: `sms`,
    icon: "person",
  },
];

export const PersonalLink = ({
  show,
  setShow,
  onCreated,
}) => {
  const { id } = useParams();
  const [active, setActive] = useState(0);
  const [search, setSearch] = useState("");
  const [perPage, setPerPage] = useState(5);
  const [pageSkip, setPageSkip] = useState(0);
  const [pageSort, setPageSort] = useState({
    item: "updatedAt",
    descend: true,
  });
  const [audiences, setAudiences] = useState([]);
  const [searchString, setSearchString] = useState("");

  const [creating, setCreating] = useState();

  const createParts = useCreateParticipationFromAud();
  const createOne = useCreateParticipation();
  const getAudiences = useFetchAudContactsGql(
    pageSkip,
    perPage,
    pageSort,
    search
  );

  useEffect(() => {
    searchContacts.refetch();
  }, [searchString]);

  const searchContacts = useSearchContact(searchString, 0, 20, pageSort);

  function clickOutside(e) {
    // debugger;
    if (ref.current && !ref.current?.contains(e.target)) {
      setSearchString("");
    }
  }

  useEffect(() => {
    document.addEventListener("click", clickOutside, true);
    return () => {
      document.removeEventListener("click", clickOutside, true);
    };
  }, []);

  const ref = useRef();

  useEffect(() => {
    getAudiences.refetch();
  }, [search]);

  function createOnePart(contactId) {
    createOne.mutate(
      {
        data: {
          contactId: contactId,
          projectId: id,
        },
      },
      {
        onSuccess: () => {
          setShow(false);
          onCreated();
        },
        onError: () => {
          setShow(false);
        },
      }
    );
    setCreating(contactId);
  }

  const handleActiveUpdate = (item) => {
    setActive(item);
  };

  function handleAudienceChange(audience, remove) {
    let audiencesTemp = [...audiences];
    if (remove) {
      let index = audiencesTemp.indexOf(audience);
      audiencesTemp.splice(index, 1);
    } else {
      if (audiencesTemp) {
        audiencesTemp.push(audience);
      }
    }
    setAudiences(audiencesTemp);
  }

  let navigate = useNavigate();
  const routeChange = (path) => {
    navigate(path);
  };

  function createLinks() {
    if (audiences.length > 0) {
      let auds = audiences.map((aud) => aud.id);

      createParts.mutate(
        {
          projectId: id,
          audienceIds: { ids: auds },
        },
        {
          onSuccess: () => {
            setShow(false);
          },
        }
      );
    }
  }

  return (
    <ReactModal
      show={show}
      onClose={() => setShow(false)}
      modalStyle={{
        borderRadius: "1em",
        padding: "1em",
        width: "800px",
        overflow: "visible",
        height: "250px",
      }}
      dark
    >
      <div className={styles.heading}>Generate Personal Links</div>
      <div className={styles.linkpage}>
        <div>
          <TabPill
            tabBarItems={menuItems}
            active={active}
            updateActive={handleActiveUpdate}
            condensed
          />
        </div>

        {active === 0 && (
          <>
            {getAudiences.isLoading && <Loading height={20} width={20} />}
            {getAudiences.isError && <div>...error</div>}
            {getAudiences.isSuccess && (
              <SelectFieldCustom
                options={getAudiences.data.items}
                values={audiences}
                onChange={(aud, rem) => handleAudienceChange(aud, rem)}
                selectMultiple
                searchable
                placeholder="Choose or Search Audience(s)..."
                newOptionText="Create New Audience"
                onNewOptionClick={() => routeChange("/audiences")}
                onItemClick={(item) => setShow(item)}
                containerStyle={{ fontSize: ".8em", paddingLeft: ".2em" }}
                searchString={search}
                setSearchString={setSearch}
              ></SelectFieldCustom>
              // <SelectTo
              //   audiences={email.audience}
              //   options={getAudiences.data.audienceByOrganization}
              //   onChange={(aud, rem) => handleAudienceChange(aud, rem)}
              // ></SelectTo>
            )}
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={createLinks}
                shadow
                disable={audiences.length <= 0}
              >
                Generate
              </Button>
            </div>
          </>
        )}
        {active === 1 && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className={styles.search}>
              <Label>Search Contact To Add</Label>
              <NavTextField
                placeholder="Search..."
                value={searchString}
                setValue={setSearchString}
              ></NavTextField>
              <div className={styles.contacts} ref={ref}>
                {searchContacts.isSuccess &&
                  searchString != "" &&
                  searchContacts?.data?.contacts?.map((contact) => (
                    <div
                      className={styles.contact}
                      onClick={() => createOnePart(contact?.id)}
                    >
                      {`${contact?.firstName} ${contact?.lastName}`}{" "}
                      {contact?.email}{" "}
                      {creating && creating === contact.id ? (
                        <span className={styles.type}>
                          Creating... <Loading height={17} width={17} />
                        </span>
                      ) : (
                        <span className={styles.type}>Create Link</span>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </ReactModal>
  );
};
