import { NumberInput } from "components/inputs/input_fields/NumberInput/NumberInput";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import { SelectField } from "components/inputs";
import { useEffect, useState } from "react";
import styles from "../SettingsAccordion.module.scss";
import {
  AnswerCount,
  AtoZ,
  AvgScore,
  DoughnutType,
  Flywheel,
  HorizBarType,
  LineType,
  NpsScore,
  ParticipationCount,
  PieType,
  VertBarType,
  ZtoA,
} from "../../QuestionChart";
import { Label } from "components/layouts/Label/Label";
import { ColorPicker } from "components/inputs/input_fields/ColorPicker/ColorPicker";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";

//custom accordian
export const DataLabels = ({
  viz,
  title,
  changeSettingsField,
  changeVizField,
  visible,
  setVisible,
  labels,
}) => {
  const defaultLabel = {
    show: true,
    color: "#616565",
    position: "end",
    alignment: "end",
    offset: 0,
    sigFigs: 0,
    fontSize: 14,
  };

  function onLabelOrPercentClick(setting, val) {
    if (val) {
      if (!viz.designSettings[setting].color) {
        viz.designSettings[setting] = { ...defaultLabel }; // This creates the label. I thought that most chart may not use more than one label, so might as well save the extra data (strings) from the database until you use it
      }
    }
    onChange(setting, "show", val);
  }

  function onChange(setting, field, val) {
    // console.log(setting, field, val);
    let newSetting = viz.designSettings[setting];
    newSetting[field] = val;
    changeSettingsField(setting, newSetting);
  }

  useEffect(() => {
    // Just here to catch old charts. As of 5/4/23
    if (viz.designSettings.dataLabelValue === undefined) {
      let settings = { ...viz.designSettings };
      settings.dataLabelValue = {
        show: false,
        color: "#fff",
        position: "center",
        alignment: "center",
        offset: 0,
        sigFigs: 0,
        fontSize: 14,
      };
      settings.dataLabelLabel = {
        show: false,
      };
      settings.dataLabelPercent = {
        show: false,
      };

      delete settings.dataLabelAlignment;
      delete settings.dataLabelColor;
      delete settings.dataLabelFontSize;
      delete settings.dataLabelSigFig;
      delete settings.dataLabelPosition;
      delete settings.dataLabelStack;

      changeVizField("designSettings", settings);
    }
  }, []);

  function toggleLabels(val) {
    // if (!val) {
    //   viz.designSettings.zoomOut = 0;
    // }
    changeSettingsField("hasDataLabels", val);
  }

  // const byPercent =
  //   !viz.designSettings.byPercent &&
  //   (viz.designSettings.answerType === ParticipationCount ||
  //     viz.designSettings.answerType === AnswerCount ||
  //     viz.pivotString === "nps");

  const nps =
    viz.designSettings.answerType === NpsScore &&
    viz.designSettings.split !== "nps";

  const byPercent =
    viz.designSettings.byPercent &&
    viz.designSettings.answerType !== AvgScore &&
    !nps &&
    viz.designSettings.answerType !== Flywheel;


  return (
    <>
      <div
        key={"datalabels"}
        className={`${styles.header} ${visible ? styles.headervisible : ""}`}
        onClick={setVisible}
      >
        {/* {title} */}

        <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
          {title}{" "}
          <i style={{ fontSize: "1.2em" }} className="bi bi-superscript"></i>
        </div>

        <span className={styles.accordionicon}>
          <i className="bi bi-caret-left-fill"></i>
        </span>
      </div>
      {visible && (
        <div className={styles.body}>
          <div className={styles.setting2} style={{ paddingLeft: "5px" }}>
            <ToggleSwitch
              startChecked={viz.designSettings.hasDataLabels}
              handleCheck={toggleLabels}
            ></ToggleSwitch>
            <Label
              style={{
                width: "fit-content",
                fontSize: ".9em",
              }}
            >
              Data Labels
            </Label>
          </div>

          {viz.designSettings.hasDataLabels && (
            <>
              <div
                className={styles.setting2}
                style={{ marginTop: "10px", paddingLeft: "5px" }}
              >
                <Checkbox
                  checked={viz.designSettings.dataLabelValue.show}
                  onChange={(e) =>
                    onChange("dataLabelValue", "show", e.target.checked)
                  }
                />
                <Label
                  style={{
                    width: "fit-content",
                    fontSize: ".9em",
                  }}
                >
                  {byPercent ? "Value (%)" : "Value"}
                </Label>
              </div>

              {viz.designSettings.dataLabelValue.show && (
                <SingleDataLabelSetting
                  viz={viz}
                  setting={viz.designSettings.dataLabelValue}
                  onChange={(field, val) =>
                    onChange("dataLabelValue", field, val)
                  }
                  sigFigs={viz.designSettings.byPercent}
                  npsValueLabel={
                    viz.designSettings.answerType === NpsScore &&
                    viz.type === VertBarType
                  }
                />
              )}

              <div
                className={styles.setting2}
                style={{ marginTop: "3px", paddingLeft: "5px" }}
              >
                <Checkbox
                  checked={viz.designSettings.dataLabelLabel.show}
                  onChange={(e) =>
                    onLabelOrPercentClick("dataLabelLabel", e.target.checked)
                  }
                />
                <Label
                  style={{
                    width: "fit-content",
                    fontSize: ".9em",
                  }}
                >
                  Legend Label
                </Label>
              </div>
              {viz.designSettings.dataLabelLabel.show && (
                <SingleDataLabelSetting
                  viz={viz}
                  setting={viz.designSettings.dataLabelLabel}
                  onChange={(field, val) =>
                    onChange("dataLabelLabel", field, val)
                  }
                />
              )}

              {!viz.designSettings.byPercent &&
                (viz.designSettings.answerType === ParticipationCount ||
                  viz.designSettings.answerType === AnswerCount ||
                  viz.pivotString === "nps") &&
                viz.designSettings.answerType !== Flywheel && (
                  <>
                    <div
                      className={styles.setting2}
                      style={{ marginTop: "3px", paddingLeft: "5px" }}
                    >
                      <Checkbox
                        checked={viz.designSettings.dataLabelPercent.show}
                        onChange={(e) =>
                          onLabelOrPercentClick(
                            "dataLabelPercent",
                            e.target.checked
                          )
                        }
                      />
                      <Label
                        style={{
                          width: "fit-content",
                          fontSize: ".9em",
                        }}
                      >
                        {viz.designSettings.byPercent
                          ? "Actual Value"
                          : "% of Total"}
                      </Label>
                    </div>
                    {viz.designSettings.dataLabelPercent.show && (
                      <SingleDataLabelSetting
                        viz={viz}
                        setting={viz.designSettings.dataLabelPercent}
                        onChange={(field, val) =>
                          onChange("dataLabelPercent", field, val)
                        }
                        sigFigs
                      />
                    )}
                  </>
                )}
            </>
          )}
        </div>
      )}
    </>
  );
};

function SingleDataLabelSetting({
  viz,
  setting,
  sigFigs,
  onChange,
  npsValueLabel,
}) {
  const positionOptions =
    viz.type === DoughnutType || viz.type === PieType
      ? [
          { value: "start", label: "Inner Edge" },
          { value: "center", label: "Middle" },
          { value: "end", label: "Outer Edge" },
        ]
      : [
          { value: "start", label: "Start" },
          { value: "center", label: "Center" },
          { value: "end", label: "End" },
        ];

  const alignmentOptions =
    viz.type === DoughnutType || viz.type === PieType
      ? [
          { value: "end", label: "Out" },
          { value: "start", label: "In" },
          { value: "center", label: "None" },
        ]
      : viz.type === HorizBarType
      ? [
          { value: "left", label: "Left" },
          { value: "right", label: "Right" },
          { value: "center", label: "None" },
        ]
      : [
          { value: "end", label: "Up" },
          { value: "start", label: "Down" },
          { value: "left", label: "Left" },
          { value: "right", label: "Right" },
          { value: "center", label: "None" },
        ];

  function changePosition(val) {
    setting.alignment = "center";
    onChange("position", val);
  }

  useEffect(() => {
    if (viz.type === HorizBarType) {
      if (setting.alignment === "end") {
        onChange("alignment", "right");
      }
      if (setting.alignment === "start") {
        onChange("alignment", "left");
      }
    }
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "30px",
          justifyContent: "center",
          padding: "0px 15px",
        }}
      >
        <div style={{ display: "flex", gap: "15px", alignItems: "center" }}>
          <Label
            italics
            style={{
              width: "fit-content",
              fontSize: ".8em",
              paddingLeft: "0px",
            }}
          >
            Color
          </Label>
          <div
            style={{
              border: "2px solid gray",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "fit-content",
              height: "fit-content",
              borderRadius: "7.5px",
            }}
          >
            <ColorPicker
              defaultColor={setting.color}
              onChange={(val) => onChange("color", val)}
              textColors
            />
          </div>
        </div>

        <div className={styles.setting}>
          <Label
            italics
            style={{
              width: "fit-content",
              fontSize: ".8em",
              paddingLeft: "0px",
            }}
          >
            Size{" "}
          </Label>
          <NumberInput
            startNumber={setting.fontSize}
            handleNumberChange={(val) => onChange("fontSize", val)}
          ></NumberInput>
        </div>
        {sigFigs && (
          <div className={styles.setting}>
            <Label
              italics
              style={{
                width: "fit-content",
                fontSize: ".8em",
                paddingLeft: "0px",
              }}
            >
              Decimal Digits
            </Label>
            <NumberInput
              startNumber={setting.sigFigs}
              handleNumberChange={(val) => onChange("sigFigs", val)}
            ></NumberInput>
          </div>
        )}

        {(setting.alignment !== "center" || setting.position === "outside") && (
          <div className={styles.setting}>
            <Label
              italics
              style={{
                width: "fit-content",
                fontSize: ".8em",
                paddingLeft: "0px",
              }}
            >
              Push
            </Label>
            <NumberInput
              startNumber={setting.offset}
              handleNumberChange={(val) => onChange("offset", val)}
              min={-50}
            ></NumberInput>
          </div>
        )}
      </div>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "15px",
          justifyContent: "space-evenly",
          paddingBottom: "15px",
        }}
      >
        {viz.type !== LineType && (
          <div
            style={{
              display: "flex",
              gap: "15px",
              alignItems: "center",
            }}
          >
            <Label
              italics
              style={{
                fontSize: ".8em",
                width: "fit-content",
                paddingLeft: "0px",
              }}
            >
              Place
            </Label>
            <div style={{ width: "fit-content" }}>
              <SelectField
                onChange={(option) => changePosition(option.value)}
                value={positionOptions.find(
                  (opt) => opt.value === setting.position
                )}
                options={positionOptions}
                disable={npsValueLabel}
              ></SelectField>
            </div>
          </div>
        )}
        <div
          style={{
            display: "flex",
            gap: "20px",
            alignItems: "center",
          }}
        >
          <Label
            italics
            style={{
              fontSize: ".8em",
              width: "fit-content",
              paddingLeft: "0px",
            }}
          >
            Move
          </Label>
          <div style={{ width: "fit-content" }}>
            <SelectField
              onChange={(option) => onChange("alignment", option.value)}
              value={alignmentOptions.find(
                (opt) => opt.value === setting.alignment
              )}
              options={alignmentOptions}
              disable={npsValueLabel}
            ></SelectField>
          </div>
        </div>
      </div>
    </>
  );
}
