import React, { useState, useCallback } from "react";
import styles from "./SurveyDesign.module.scss";
import { PageTable } from "./PageTable/PageTable";
import { Label } from "components/layouts/Label/Label";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import Button from "components/Button/Button";
import ReactModal from "./../../ReactModal/ReactModal.jsx";
// import ReactFlow, {
//   addEdge,
//   MiniMap,
//   Controls,
//   Background,
//   useNodesState,
//   useEdgesState,
// } from "reactflow";
// import "reactflow/dist/style.css";

// import {
//   nodes as initialNodes,
//   edges as initialEdges,
// } from "./initial-elements";

// const onInit = (reactFlowInstance) =>
//   console.log("flow loaded:", reactFlowInstance);

export function PageNavigation({
  settings,
  setSettings,
  changeSetting,
  active,
  setActive,
  numPages,
  setGetAll,
  currPage,
  setCurrPage,
  allQuestions,
  onDeletePage,
  addPage,
  switchPages,
  status,
}) {
  function handleSetActive() {
    active ? setActive("") : setActive("PageNavigation");
  }

  const [showLogic, setShowLogic] = useState(false);
  return (
    <div className={styles.settingContainer}>
      <div
        className={`${
          active ? styles.headerContainerActive : styles.headerContainer
        }`}
        onClick={handleSetActive}
        // style={{
        //   borderBottomLeftRadius: active ? "" : "1em",
        //   borderBottomRightRadius: active ? "" : "1em",
        // }}
      >
        <div className={styles.header}>Pages <i className="bi-journals"></i></div>
        {active ? (
          <i className="bi bi-caret-down-fill"></i>
        ) : (
          <i className="bi bi-caret-left-fill"></i>
        )}
      </div>
      {active && (
        <div
          className={styles.body}
          style={{padding: "1em"}}
        >
          {/* <div
            className={styles.settingHorizontal}
            style={{ paddingLeft: "1em", paddingBottom: ".5em" }}
          >
            <ToggleSwitch
              startChecked={settings.pagesTogether}
              handleCheck={(val) => {
                changeSetting("pagesTogether", val);
                setGetAll(val);
              }}
            ></ToggleSwitch>
            <Label>View Pages Together</Label>
          </div> */}
          <PageTable
            numPages={numPages}
            currPage={currPage}
            setCurrPage={setCurrPage}
            settings={settings}
            setSettings={setSettings}
            allQuestions={allQuestions}
            onDeletePage={onDeletePage}
            addPage={addPage}
            switchPages={switchPages}
            status={status}
          ></PageTable>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: "1em",
            }}
          >
            <Button gray onClick={() => setShowLogic(true)}>
              Edit Logic
            </Button>
          </div> */}
        </div>
      )}
      <EditLogic show={showLogic} setShow={setShowLogic}></EditLogic>
    </div>
  );
}

// const initialNodes = [
//   { id: "1", position: { x: 0, y: 0 }, data: { label: "1" } },
//   { id: "2", position: { x: 0, y: 100 }, data: { label: "2" } },
// ];
// const initialEdges = [{ id: "e1-2", source: "1", target: "2" }];

function EditLogic({ show, setShow }) {
  // const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  // const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  // const onConnect = useCallback(
  //   (params) => setEdges((eds) => addEdge(params, eds)),
  //   [setEdges]
  // );

  return (
    <ReactModal
      show={show}
      onClose={() => setShow(false)}
      modalStyle={{ borderRadius: "1em", height: "100%", width: "100%" }}
    >
      <div style={{ padding: "1em", height: "100%" }}>
        {/* <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onInit={onInit}
          fitView
          attributionPosition="top-right"
        >
          <MiniMap
            nodeStrokeColor={(n) => {
              if (n.style?.background) return n.style.background;
              if (n.type === "input") return "#0041d0";
              if (n.type === "output") return "#ff0072";
              if (n.type === "default") return "#1a192b";

              return "#eee";
            }}
            nodeColor={(n) => {
              if (n.style?.background) return n.style.background;

              return "#fff";
            }}
            nodeBorderRadius={2}
          />
          <Controls />
          <Background color="#aaa" gap={16} />
        </ReactFlow> */}
      </div>
    </ReactModal>
  );
}
