// External
import React, { useEffect, useRef, useState } from "react";
import parse from "html-react-parser";
// Internal
import styles from "./SelectSignature.module.scss";
// import { ToggleSwitch } from "../ToggleSwitch/ToggleSwitch";
import Button from "components/Button/Button";
// import { useNavigate } from "react-router-dom";
import { useFetchMyOrg } from "api/resources/organization/organization";

/**
 * A custom select input component with no external libraries
 * @class
 * @property {object} options: The options that will be in the select menu. Should be a list of objects with 'label' and 'value' attributes
 * @property {object} defaultValue: One of the options in the options list (object with 'label' and 'value' attributes)
 * @property {boolean} selectMultiple: Whether users should be able to select multiple items
 * @property {function} onChange: A function that will be called when the user changes the selection. The value of the option will be returned.
 * @returns {React.ReactElement} Select Field component
 *
 * @example
 * <SelectField options={options} selectMultiple={false} />
 */

export const SelectSignature = ({
  icon,
  signatureList,
  onChange,
  startSig,
  startCondition,
  placeholder,
  isDynamic,
  onTypeChange,
  onDynamicChange,
}) => {

  const [show, setShow] = useState(false);
  const [activeSig, setActive] = useState(startSig);
  const [dynamic, setDynamic] = useState(isDynamic);
  const [activeCondition, setActiveCondition] = useState(
    startCondition
      ? JSON.parse(startCondition)
      : {
          fieldName: "firstName",
          fieldId: "firstName",
          delimiter: "",
          condition: "equals",
          aliasField: "firstName",
          elseSigId: "None",
          elseSigEmail: "None",
          elseSigName: "None",
        }
  );

  function handleUpdateCondition(item, value) {
    let temp = { ...activeCondition };
    temp[item] = value;
    setActiveCondition(temp);
  }

  function handleUpdateMultiple(item) {
    let temp = { ...activeCondition, ...item };
    setActiveCondition(temp);
  }

  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  function handleOnChange(sig) {
    setShow(false);
    setActive(sig);
    onChange(sig);
  }

  function checkCondition(cond) {
    if (cond === "doesnotequal") {
      return "does not equal";
    } else if (cond === "doesnotcontain") {
      return "does not contain";
    } else {
      return cond;
    }
  }

  function handleTypeChange(value) {

    setDynamic(value);
    onTypeChange(value);
  }

  function onSave() {
    onTypeChange(dynamic)
    onDynamicChange(activeCondition);
    setShow(false);
  }

  function onCancel() {
    setActiveCondition(
      startCondition
        ? JSON.parse(startCondition)
        : {
            fieldName: "firstName",
            fieldId: "firstName",
            condition: "doesnotequal",
            aliasField: "firstName",
            elseSigId: "None",
            elseSigEmail: "None",
            elseSigName: "None",
          }
    );
    setDynamic(isDynamic);
    setShow(false);
  }

  return (
    <div className={styles.selectContainer} ref={ref}>

      <div className={styles.select} onClick={() => setShow(!show)}>
        {!dynamic && (
          <div className={styles.activeOption}>
            {icon}
            {activeSig?.aliasName != undefined
              ? <>{`<${activeSig.aliasName}>`}<div className={styles.address}>{activeSig.fromAddress}</div></>
              : <div className={styles.placeholder}>{placeholder}</div>}
          </div>
        )}
        {dynamic && (
          <div className={styles.activeOptionDynamic}>
            {activeCondition
              ? <span>If contacts <a>{activeCondition.fieldName} {checkCondition(
                  activeCondition.condition
                )}</a> user alias' <a>{activeCondition.aliasField}</a></span>
              : <div className={styles.placeholder}>{placeholder}</div>}
          </div>
        )}
        <i className="bi bi-chevron-down"></i>
      </div>
      {show && !dynamic && (
        <div className={styles.dropdown} id="signatureDropdown">
          <div className={styles.signatureList}>
            {signatureList &&
              signatureList.map((signature) => (
                <div
                  className={styles.sigContainer}
                  onClick={() => handleOnChange(signature)}
                  key={signature.id}
                >
                  {signature?.aliasName != undefined && <>
                  {`<${signature.aliasName}>`} <div className={styles.address}>{signature.fromAddress}</div>
                  </>}
                  {activeSig === signature && <i className={`bi-check ${styles.icon}`} ></i>}
                </div>
              ))}
          </div>
        </div>
      )}
            {!dynamic && (
        <div className={styles.label}>
          <span onClick={() => handleTypeChange(true)}>Switch To Dynamic From Address</span>
        </div>
      )}
      {dynamic && (
        <div className={styles.label} >
          <span onClick={() => handleTypeChange(false)}>Switch To Basic From Address</span>
        </div>
      )}
      {show && dynamic && (
        <DynamicSelect
          signatureList={signatureList}
          active={activeCondition}
          setActive={handleUpdateCondition}
          setActiveMult={handleUpdateMultiple}
          onSave={onSave}
          onCancel={onCancel}
        ></DynamicSelect>
      )}
    </div>
  );
};

function DynamicSelect({
  signatureList,
  active,
  setActive,
  setActiveMult,
  onSave,
  onCancel,
}) {
  const organizationQuery = useFetchMyOrg();

  return (
    <div className={styles.dropdownDynamic} id="signatureDropdown">
      <div className={styles.conditionContainer}>
        If contacts{" "}
        {organizationQuery.isSuccess && (
          <select
            className={styles.field}
            value={active?.fieldId}
            onChange={(e) => {
              const selectedIndex = e.target.options.selectedIndex;
              setActiveMult({
                fieldId: e.target.value,
               fieldName:
                  e.target.options[selectedIndex].getAttribute("fieldName"),
                  delimiter:
                  e.target.options[selectedIndex].getAttribute("delimiter"),

              });
            }}
          >
            {organizationQuery?.data?.organization?.custom_field?.map(
              (field, i) => (
                <option key={i} value={field?.id} fieldName={field?.name} delimiter={field?.delimiter}>{field?.name}</option>
              )
            )}
            <option value="firstName" fieldName="firstName">First Name</option>
            <option value="lastName" fieldName="lastName">Last Name</option>
            <option value="email" fieldName="email">Email</option>
          </select>
        )}
        <select
          className={styles.condition}
          value={active?.condition}
          onChange={(e) => setActive("condition", e.target.value)}
        >
          <option value="equals">Equals</option>
          {/* <option value="doesnotequal">Does Not Equal</option> */}
          <option value="contains">contains</option>
          {/* <option value="doesnotcontain">Does Not Contain</option> */}
        </select>{" "}
        User Alias'{" "}
        <select
          className={styles.userFields}
          value={active?.aliasField}
          onChange={(e) => setActive("aliasField", e.target.value)}
        >
          {/* `<option value="firstNamelastName">First and Last Name</option> */}
          <option value="firstName">First Name</option>
          <option value="lastName">Last Name</option>
          <option value="email">Email</option>
        </select>
      </div>
      <div className={styles.blueText}>
        Send from User Alias that fulfills the above condition
      </div>
      <div style={{ margin: ".5em 0em" }}>
        If contact fails condition. Who should they get the email from?
      </div>
      <select
        className={styles.elsealias}
        value={active?.elseSigId}
        onChange={(e) => {
          const selectedIndex = e.target.options.selectedIndex;
          setActiveMult({
            elseSigId: e.target.value,
            elseSigEmail:
              e.target.options[selectedIndex].getAttribute("fromAddress"),
            elseSigName:
              e.target.options[selectedIndex].getAttribute("aliasName"),
          });
        }}
      >
        {signatureList &&
          signatureList.map((signature) => (
            <option
              className={styles.sigContainer}
              key={signature.id}
              value={signature.id}
              aliasName={signature?.aliasName}
              fromAddress={signature?.fromAddress}
            >
              {`<${signature?.aliasName}>`} {signature?.fromAddress}
            </option>
          ))}
        <option
          className={styles.sigContainer}
          key={"None"}
          value={"None"}
          aliasName={"None"}
          fromAddress={"None"}
        >
          None
        </option>
      </select>
      <div
        style={{
          display: "flex",
          padding: ".5em 0em",
          gap: "1em",
          justifyContent: "flex-end",
        }}
      >
        <Button red shadow onClick={onCancel}>
          Cancel
        </Button>
        <Button shadow onClick={onSave}>
          Finish
        </Button>
      </div>
    </div>
  );
}
