import React from "react";
import styles from "./FieldAdjuster.module.scss";
import Modal from "components/ReactModal/ReactModal";
import { EditCustomFields } from "pages/organization/organization_details/OrganizationDetails";
import { useFetchCurrOrgsFields } from "api/resources/organization/organization";

function FieldAdjuster({ show, setShow, orgId, user }) {
  const fetchCustomFields = useFetchCurrOrgsFields();
  return (
    <Modal
      show={show}
      onClose={() => setShow(false)}
      modalStyle={{
        height: "100%",
        paddingBottom: "0em"
      }}
      dark
      rightStyle
    >
      <div className={styles.adjustBox}>
        {fetchCustomFields.isSuccess && (
          <EditCustomFields
            customfields={fetchCustomFields.data.getCurrOrgFields}
            orgId={orgId}
            noAddFields
            noModal
            user={user}
          ></EditCustomFields>
        )}
      </div>
    </Modal>
  );
}

export default FieldAdjuster;
