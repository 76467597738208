import React from "react";
import { NavLink, Link } from "react-router-dom";
import styles from "./MenuItem.module.scss";
import FlexRow from "components/layouts/FlexRow/FlexRow";

/**
 * @author Nate Hanson
 * @function MenuItem
 **/

const MenuItem = ({
  onClick,
  name,
  subMenus,
  iconClassName,
  to,
  active,
  menuItem,
  style,
  collapsed,
}) => {
  function handleClick() {
    onClick(menuItem.id, to);
  }

  return (
    <div style={{ width: !collapsed ? "100%" : "" }} onClick={handleClick}>
      {to && (
        <FlexRow
          start
          gap=".2em"
          className={`${styles.menu_item} ${active ? styles.menu_item_active : ""}`}
        >
          <div className={active ? styles.menuIconActive : styles.menuIcon} >
            <i className={iconClassName}></i>
          </div>

          {!collapsed && (
            <div
              className={
                active ? styles.menuItemNameActive : styles.menuItemName
              }
            >
              {name}
            </div>
          )}
        </FlexRow>
      )}
      {!to && (
        <FlexRow
          start
          gap=".2em"
          className={`${styles.menu_item} ${active ? styles.menu_item_active : ""}`}
        >
          <div className={active ? styles.menuIconActive : styles.menuIcon}>
            <i className={iconClassName}></i>
          </div>

          {!collapsed && (
            <div
              className={
                active ? styles.menuItemNameActive : styles.menuItemName
              }
            >
              {name}
            </div>
          )}
        </FlexRow>
      )}
      {subMenus && subMenus.length > 0 ? (
        <ul className={styles.subMenu}>
          {subMenus.map((menu, index) => (
            <li key={index}>
              <NavLink to={menu.to}>{menu.name}</NavLink>
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  );
};

export default MenuItem;
