import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import styles from "../WidgetSettings.module.scss";
import { Label } from "components/layouts/Label/Label";

export function NotifDisplay({
  visible,
  setVisible,
  settings,
  changeSettings,
}) {
  const timeFrames = [
    {
      title: "Today",
      code: "today",
    },
    {
      title: "Within a Week",
      code: "withinWeek",
    },
    {
      title: "Within a Month",
      code: "withinMonth",
    },
    {
      title: "Month +",
      code: "monthPlus",
    },
  ];

  function changeTimeFrame(option, val) {
    let current = { ...settings?.timeFrames };
    current[option.code] = val;
    changeSettings("timeFrames", current);
  }

  return (
    <>
      <div
        className={`${styles.header} ${visible ? styles.headervisible : ""}`}
        onClick={setVisible}
      >
        {"Display"}
        <span className={styles.accordionicon}>
          <i className="bi bi-caret-left-fill"></i>
        </span>
      </div>
      {visible && (
        <div className={styles.body}>
          <div className={styles.col} style={{ gap: "1.5em" }}>
            <div className={styles.col} style={{ gap: "1em" }}>
              <div
                className={styles.row}
                style={{ gap: "1em", paddingLeft: "15px" }}
              >
                <Checkbox
                  checked={settings?.unreadOnly}
                  onChange={(e) =>
                    changeSettings("unreadOnly", e.target.checked)
                  }
                />
                <Label
                  style={{
                    width: "fit-content",
                    color: "#8dabb2",
                    paddingLeft: "0",
                    fontWeight: "600",
                  }}
                >
                  Unread Only
                </Label>
              </div>

              <div
                className={styles.row}
                style={{ gap: "1em", paddingLeft: "15px" }}
              >
                <Checkbox
                  checked={settings?.snooze}
                  onChange={(e) =>
                    changeSettings("snooze", e.target.checked)
                  }
                />
                <Label
                  style={{
                    width: "fit-content",
                    color: "#8dabb2",
                    paddingLeft: "0",
                    fontWeight: "600",
                  }}
                >
                  Snooze
                  <i
                    className="bi bi-moon-stars-fill"
                    style={{ paddingLeft: "10px" }}
                  ></i>
                </Label>
              </div>
            </div>

            <div className={styles.col} style={{ gap: "1em" }}>
              <Label style={{ color: "#8dabb2", fontWeight: "600" }}>
                Display as
              </Label>
              <div
                className={styles.row}
                style={{ gap: "1em", paddingLeft: "30px" }}
              >
                <Checkbox
                  checked={settings?.oneBigList}
                  onChange={() => changeSettings("oneBigList", true)}
                />
                <Label
                  style={{
                    width: "fit-content",
                    color: "#8dabb2",
                    paddingLeft: "0",
                    fontWeight: "500",
                    fontSize: ".8em",
                  }}
                >
                  <i
                    className="bi bi-list-nested"
                    style={{ paddingRight: "5px" }}
                  ></i>
                  One Big List
                </Label>
              </div>
              <div
                className={styles.row}
                style={{ gap: "1em", paddingLeft: "30px" }}
              >
                <Checkbox
                  checked={!settings?.oneBigList}
                  onChange={() => changeSettings("oneBigList", false)}
                />
                <Label
                  style={{
                    width: "fit-content",
                    color: "#8dabb2",
                    paddingLeft: "0",
                    fontWeight: "500",
                    fontSize: ".8em",
                  }}
                >
                  Seperated By Date{" "}
                  <i
                    className="bi bi-calendar-week"
                    style={{ paddingLeft: "3px" }}
                  ></i>
                </Label>
              </div>
              {!settings.oneBigList && (
                <div
                  className={styles.col}
                  style={{ gap: "10px", paddingLeft: "45px" }}
                >
                  {timeFrames.map((option) => (
                    <div className={styles.row} style={{ gap: "1em" }}>
                      <Checkbox
                        checked={settings?.timeFrames[option.code]}
                        onChange={(e) =>
                          changeTimeFrame(option, e.target.checked)
                        }
                      />
                      <Label
                        style={{
                          width: "fit-content",
                          color: "#8dabb2",
                          paddingLeft: "0",
                          fontWeight: "500",
                          fontSize: ".8em",
                        }}
                      >
                        {option.title}
                      </Label>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className={styles.col} style={{ gap: "12px" }}>
              <Label style={{ color: "#8dabb2", fontWeight: "600" }}>
                Links
              </Label>
              <div
                className={styles.row}
                style={{ gap: "1em", paddingLeft: "30px" }}
              >
                <Checkbox
                  checked={settings?.markAll}
                  onChange={(e) => changeSettings("markAll", e.target.checked)}
                />
                <Label
                  style={{
                    width: "fit-content",
                    color: "#8dabb2",
                    paddingLeft: "0",
                    fontWeight: "500",
                    fontSize: ".8em",
                  }}
                >
                  Mark All As Read
                </Label>
              </div>
              <div
                className={styles.row}
                style={{ gap: "1em", paddingLeft: "30px" }}
              >
                <Checkbox
                  checked={settings?.viewAll}
                  onChange={(e) => changeSettings("viewAll", e.target.checked)}
                />
                <Label
                  style={{
                    width: "fit-content",
                    color: "#8dabb2",
                    paddingLeft: "0",
                    fontWeight: "500",
                    fontSize: ".8em",
                  }}
                >
                  Go to Notifications
                </Label>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
