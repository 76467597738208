import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import styles from "../WidgetSettings.module.scss";
import { Label } from "components/layouts/Label/Label";
import { useSearchProjectsForChart } from "api/resources/projects/projects";
import { useEffect, useRef, useState } from "react";

export default function PickSurvey({
  widget,
  setWidget,
  visible,
  setVisible,
}) {

  function addProject(proj) {
    let newWidget = { ...widget };
    newWidget.settings.survey = { id: proj.id, name: proj.name };
    if (newWidget.settings.autoTitle) {
      newWidget.name = proj.name;
    }
    setWidget(newWidget);
  }

  return (
    <>
      <div
        className={`${styles.header} ${visible ? styles.headervisible : ""}`}
        onClick={setVisible}
      >
        {"Survey"}
        <span className={styles.accordionicon}>
          <i className="bi bi-caret-left-fill"></i>
        </span>
      </div>
      {visible && (
        <div className={styles.body}>
          <div
            className={styles.col}
            style={{ gap: ".5em", alignItems: "center" }}
          >
            <Label style={{ color: "#8dabb2" }}>Choose Survey</Label>
            <SearchSurvey
              addProject={addProject}
              current={widget.settings?.survey}
            />
          </div>
        </div>
      )}
    </>
  );
}

function SearchSurvey({ addProject, current }) {
  const [searchString, setSearchString] = useState("");
  const [searchVisible, setSearchVisible] = useState(false);
  const [value, setValue] = useState(current?.name ? current.name : undefined);
  function changeSearch(e) {
    setValue(e.target.value);
    setSearchString(e.target.value);
  }
  const [perPage, setPerPage] = useState(10);
  const [pageSkip, setPageSkip] = useState(0);
  const [searchSort, setSearchSort] = useState({
    item: "startedAt",
    descend: true,
  });

  const searchOptions = useSearchProjectsForChart(
    searchString,
    pageSkip,
    perPage,
    searchSort,
  );
  
  useEffect(() => {
    searchOptions.refetch();
  }, [searchString]);

  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setSearchVisible(false);
      setSearchString("");
      if (current) {
        setValue(current.name);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div style={{ width: "100%" }} ref={ref}>
      <input
        className={styles.searchSurveys}
        type="text"
        placeholder="search"
        onChange={changeSearch}
        onFocus={() => {
          setSearchVisible(true);
        }}
        value={value}
        // onBlur={() => {
        //   setSearchVisible(false);
        // }}
      ></input>
      {searchVisible &&
        searchOptions.isSuccess &&
        !searchOptions.isRefetching && (
          <div className={styles.optionsBox}>
            <div className={styles.options}>
              {searchOptions.data?.surveys.map((project, index) => (
                <>
                  {current?.id !== project.id && (
                    <div
                      key={index}
                      className={styles.option}
                      onClick={() => {
                        addProject(project);
                        setSearchVisible(false);
                        setValue(project.name);
                        setSearchString("");
                      }}
                    >
                      {project.name}
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>
        )}
      {/* <Button onClick={() => {searchOptions.refetch()}}>refetch</Button> */}
    </div>
  );
}
