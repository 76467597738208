import { Label } from "components/layouts/Label/Label";
import styles from "./SectionSettings.module.scss";
import Button from "components/Button/Button";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import { ColorPicker } from "components/inputs/input_fields/ColorPicker/ColorPicker";

export default function SectionSettings({ section, saveSection, onCancel }) {
  // const sectionTypes = {
  //   FormRegistration: {
  //     label: "Form Registration",
  //     value: "Form Registration",
  //     name: "Form Registration",
  //     icon: <i className="bi bi-list-check"></i>,
  //   },
  //   Description: {
  //     label: "Description",
  //     value: "Description",
  //     name: "Description",
  //     icon: <i className="bi bi-chat-left-text"></i>,
  //   },
  // };

  function setSetting(field, val) {
    section.sectionSettings[field] = val;
    saveSection(section);
  }
  return (
    <div className={styles.settingsContainer}>
      <div className={`${styles.dialog} ${section ? styles.borderBlue : ""}`}>
        {/* <div>
            <Label
              style={{
                fontWeight: "700",
                fontSize: ".9em",
                paddingLeft: "1em",
                textTransform: "none",
              }}
            >
              CHANGE SECTION TYPE
            </Label>
            <SelectFieldCustom
              options={Object.values(sectionTypes)}
              value={question.type}
              // icon={questionTypes[question.type].icon}
              // placeholder='yeet'
              onChange={handleChangeType}
            />
          </div> */}

        <div className={styles.settingHorizontal}>
          <div className={styles.toggleLabel}>
            <ToggleSwitch
              startChecked={section.sectionSettings?.hasBackgroundColor}
              handleCheck={(val) => setSetting("hasBackgroundColor", val)}
            ></ToggleSwitch>
          </div>
          <Label
            style={{ width: "fit-content", padding: "0em", margin: "0em" }}
          >
            Background Color
          </Label>

          {section.sectionSettings?.hasBackgroundColor && (
            <div className={styles.colorContainer}>
              <ColorPicker
                onChange={(color) => setSetting("backgroundColor", color)}
                defaultColor={section.sectionSettings?.backgroundColor}
                //   colors={settings.colorBank}
              />
            </div>
          )}
        </div>

        <div className={styles.settingHorizontal}>
          <div className={styles.toggleLabel}>
            <ToggleSwitch
              startChecked={section.sectionSettings?.sectionShadow}
              handleCheck={(val) => setSetting("sectionShadow", val)}
            ></ToggleSwitch>
          </div>
          <Label
            style={{ width: "fit-content", padding: "0em", margin: "0em" }}
          >
            Border Shadow
          </Label>
        </div>

        {section?.new && (
          <div
            style={{
              bottom: "0",
              right: "0",
              display: "flex",
              padding: ".5em 1em",
              justifyContent: "flex-end",
              gap: "1em",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "1em",
              }}
            >
              <Button
                red
                height="35px"
                shadow
                onClick={() => onCancel(section)}
              >
                Cancel
              </Button>
              {/* <Button blue height="35px" shadow>
                  Save
                </Button> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
