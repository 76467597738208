import React, { useState, useRef } from "react";
import { SketchPicker } from "react-color";
import { Popover } from "@mui/material";
import styles from "./PaletteColorPicker.module.scss";
import { ChartColors } from "assets/functions/ColorFunctions";

/**
 * A color picker component
 * @param {Array} colors something
 * @param {String} defaultColor something
 * @param {Function} onChange a function that is called when a color is chosen
 * @returns {React.ReactElement} a simple color picker component
 */

export const AddColorSimple = ({
  onDone,
  wording,
  onlyOnConfirm,
  onCancel,
  onChange,
}) => {
  const [color, setColor] = useState();
  const [show, setShow] = useState(false);

  const pickerRef = useRef();

  const handleClose = () => {
    setShow(false);
    setColor(undefined);

    if (color)
      if (!onlyOnConfirm) {
        onDone(color);
      } else if (onCancel) {
        onCancel();
      }
  };

  const handleColorChange = (colr) => {
    setColor(colr.hex);
    if (onChange) {
      onChange(colr.hex);
    }
  };

  function setUpColorPicker() {
    setShow(true);
  }

  function confirm() {
    onDone(color);
    setShow(false);
    setColor(undefined);
  }

  function revert() {
    onCancel();
    setColor(undefined);
  }

  return (
    <div style={{ cursor: "pointer" }}>
      <div ref={pickerRef} onClick={setUpColorPicker}>
        {!color && <div className={styles.addColor}>+</div>}

        {color && (
          <div
            style={{
              height: "1.25em",
              width: "1.25em",
              borderRadius: "5px",
              backgroundColor: color,
              position: "relative",
            }}
          ></div>
        )}
      </div>

      <Popover
        open={show}
        anchorEl={pickerRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          sx: { backgroundColor: "transparent", boxShadow: "none" },
        }}
      >
        <div className={styles.sketchPicker}>
          <SketchPicker
            color={color ? color : "#15BCC7"}
            presetColors={ChartColors}
            onChangeComplete={handleColorChange}
          />
          {color && (
            <div className={styles.options}>
              <div className={styles.blueButton} onClick={confirm}>
                {wording ? wording : "Add"}{" "}
                <i className="bi bi-paint-bucket"></i>
              </div>

              {onCancel && (
                <div className={styles.button} onClick={revert}>
                  Revert <i className="bi bi-x"></i>
                </div>
              )}
            </div>
          )}
        </div>
      </Popover>
    </div>
  );
};
