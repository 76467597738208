// External
import React, { useEffect, useState } from "react";

// Internal
import styles from "./CustomReport.module.scss";
import { useParams } from "react-router-dom";
// import { useFetchAnalysis } from "api/resources/organization/analysis";
import { Loading } from "components/Loading/Loading";
import {
  useFetchReport,
  useFetchUserReportRole,
} from "api/resources/organization/reports";
import Report from "./Report";
import { useFetchCurrOrgsFields } from "api/resources/organization/organization";

export const CustomReport = ({ roles, independent, organization, user }) => {
  const { reportid } = useParams();
  const fetchCustomFields = useFetchCurrOrgsFields();
  return (
    <div className={styles.pageContainer}>
      <div className={styles.subContainer}>
      {reportid && fetchCustomFields.isSuccess && (
        <ReportContainer
          reportid={reportid}
          roles={roles}
          custom_fields={fetchCustomFields.data.getCurrOrgFields}
          org={organization}
          user={user}
        ></ReportContainer>
      )}
      </div>
    </div>
  );
};

const ReportContainer = ({ reportid, roles, custom_fields, org, user }) => {
  const fetchReport = useFetchReport(reportid);
  function checkRole(canEdit) {
    if (canEdit === null) {
      return roles.canCreateEditOrgReports;
    }
    if (canEdit === false) {
      return false;
    }
    if (canEdit === true) {
      return true;
    }
  }

  const role = useFetchUserReportRole(reportid);
  return (
    <>
      {fetchReport.isLoading && <Loading />}
      {fetchReport.isError && <div>error...</div>}
      {fetchReport.isSuccess && role.isSuccess && (
        <Report
          report={fetchReport.data.report}
          roles={roles}
          org={org}
          custom_fields={custom_fields}
          refetchReport={() => fetchReport.refetch()}
          canEdit={checkRole(role?.data?.canEdit)}
          user={user}
          organization={org}
        ></Report>
      )}
    </>
  );
};
