import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import styles from "../WidgetSettings.module.scss";
import { Label } from "components/layouts/Label/Label";
import { TextFieldSimple } from "components/inputs";

export default function TitleSettings({
  widget,
  changeSettings,
  visible,
  setVisible,
  setWidget,
}) {

  function onNameChange(val) {
    let newWidget = {...widget};
    newWidget.name = val;
    setWidget(newWidget);
  }

  return (
    <>
      <div
        className={`${styles.header} ${visible ? styles.headervisible : ""}`}
        onClick={setVisible}
      >
        {"Title"}
        <span className={styles.accordionicon}>
          <i className="bi bi-caret-left-fill"></i>
        </span>
      </div>
      {visible && (
        <div className={styles.body}>
          <div className={styles.row} style={{ gap: "1em" }}>
            <Label style={{ width: "fit-content", color: '#8dabb2' }}>Show Title</Label>{" "}
            <Checkbox
              checked={widget.settings.showName}
              onChange={(e) => changeSettings("showName", e.target.checked)}
            />
          </div>

          {widget.settings?.showName && (
            <div className={styles.col}>
              <Label style={{color: '#8dabb2'}}>Title</Label>
              <TextFieldSimple value={widget.name} onChange={onNameChange} />
            </div>
          )}
        </div>
      )}
    </>
  );
}
