// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
import styles from "./Checkbox.module.scss";

/**
 * A checkbox component that is animated
 * @param {boolean} checked a boolean value that says whether the checkbox is checked or not
 * @param {function} onChange a function that will be called when the checkbox is checked or unchecked.
 * @returns {React.ReactElement} a personalized checkbox component
 */
function Checkbox({ checked, onChange, disabled, color, className }) {
  return (
    <label
      className={`${styles.container} ${disabled ? styles.disabled : ""} `}
    >
      <input
        type="checkbox"
        onChange={onChange}
        className={styles.new_checkbox}
        checked={checked}
        disabled={disabled}
      />
      <span
        className={`${styles.checkmark} ${className}`}
        style={
          checked
            ? {
                backgroundColor: color ? color : "#15bcc7",
                opacity: 1,
                border: `2px solid ${color ? color : "#15bcc7"}`,
              }
            : undefined
        }
      ></span>
    </label>
  );
}

export default Checkbox;
