import { useFetchQuestionsCountGql } from "api/resources/projects/questions";
import styles from "./Tiles.module.scss";
import { useFetchProject } from "api/resources/projects/projects";
import { useNavigate } from "react-router-dom";
import { Loading } from "components/Loading/Loading";
import Stat from "components/tables/BasicTable/SurveyWidgets/Stat";
import { trimDate } from "assets/functions/DateFunctions";

export default function SurveyTile({ widget, inSettings }) {
  const fetchSurvey = useFetchProject(widget.settings.survey.id);
  const getQuestionCount = useFetchQuestionsCountGql(widget.settings.survey.id);

  return (
    <>
      {fetchSurvey.isLoading && <Loading />}
      {fetchSurvey.isSuccess && (
        <Tile survey={fetchSurvey.data.project} inSettings={inSettings} />
      )}
    </>
  );

  function Tile({ survey }) {
    let navigate = useNavigate();
    function goTo() {
      window.localStorage.removeItem("page1");
      window.localStorage.removeItem("page2");
      window.localStorage.removeItem("page3");
      window.localStorage.removeItem("page");
      window.localStorage.setItem("activepage", 1);
      let path = survey.id;

      if (survey.status !== "Draft" && survey.rawReportId) {
        localStorage.setItem("activeprojecttab", 3);
        navigate("/project/" + path + "/surveyresults/" + survey.rawReportId);
      } else {
        localStorage.setItem("activeprojecttab", 1);
        navigate("/project/" + path + "/survey-details");
      }
    }

    return (
      <>
        <div
          className={styles.tile}
          onClick={inSettings ? undefined : goTo}
          style={inSettings ? { cursor: "default" } : undefined}
        >
          <div className={styles.surveyHeader}>
            <div className={styles.startedText}>
              <span>Started: {trimDate(survey?.startedAt)}</span>
              <span>
                {" "}
                Status:{" "}
                {survey?.status === "Open" && (
                  <span className={styles.isopen}>{survey?.status}</span>
                )}
                {survey?.status === "Closed" && (
                  <span className={styles.isclosed}>{survey?.status}</span>
                )}
                {survey?.status === "Draft" && (
                  <span className={styles.isdraft}>{survey?.status}</span>
                )}
              </span>
            </div>{" "}
            {survey.userId && !survey.organizationId && (
              <span
                className={`${styles.owner}  
        ${styles.isuser} 
     `}
              >
                <i className={`${"bi bi-person-circle"}`}></i> Survey{" "}
                {survey?.type.charAt(0).toUpperCase() + survey?.type.slice(1)}{" "}
              </span>
            )}
            {!survey.userId && survey.organizationId && (
              <span
                className={`${styles.owner} 
        ${styles.isorg}
     `}
              >
                <i className={`${"bi bi-buildings"}`}></i> Survey{" "}
                {survey?.type &&
                  survey?.type.charAt(0).toUpperCase() +
                    survey?.type.slice(1)}{" "}
              </span>
            )}
          </div>
          <div className={styles.titleContainer}>
            <div className={styles.title}>{survey?.name}</div>
            <div className={styles.description}>
              {survey?.description}
            </div>
          </div>
          <div className={styles.statsContainer}>
            {getQuestionCount.isSuccess && (
              <Stat title={"Questions"} value={getQuestionCount?.data?.count} />
            )}
            {survey.status != "Draft" && (
              <Stat title={"Responses"} value={survey?.responseCount} />
            )}
          </div>
        </div>
      </>
    );
  }
}
