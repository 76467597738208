// External
import React, { useState } from "react";

//Internal
import styles from "./ConditionBuilder.module.scss";
import { Label } from "components/layouts/Label/Label";
import { TextFieldSimple } from "components/inputs";
import Button from "components/Button/Button";

const conditionOptions = [
  {
    name: "Contains",
    value: "contains",
  },
  {
    name: "Does Not Contain",
    value: "does not contain",
  },
  {
    name: "Equals",
    value: "equals",
  },
  {
    name: "Does Not Equal",
    value: "does not equal",
  },
];

//example condition
// [
//   {
//     id: 0,
//     field: "prefix",
//     condition: "equals",
//     value: "Dr.",
//     show: ["prefix", "lastName"],
//     else: "firstName",
//     name: "First Name Condition",
//   },
// ]
const firstCondition = {
  id: 0,
  field: "prefix",
  condition: "contains",
  value: "Dr",
  show: ["prefix", "lastName"],
  else: ["firstName"],
  name: "prefix",
  tag: "prefix",
  new: true,
};

export const ConditionBuilder = ({
  handleOnDrag,
  fields,
  conditions,
  setConditions,
}) => {

  //eslint-disable-next-line no-unused-vars
  const [showFields, setShowFields] = useState(false);

  // const [conditions, setConditions] = useState(
  //   initConditions ? initConditions : []
  // );
  const [activeCondition, setActiveCondition] = useState(firstCondition);

  function changeActiveConditionField(field, value, i) {
    let temp = { ...activeCondition };

    if (field === "show" || field === "else") {
      if (i === temp[field].length) {
        temp[field].push("firstName");
      }
      temp[field][i] = value;
      setActiveCondition(temp);
      return true;
    }

    if (field === "name") {
      temp[field] = value;
      temp["tag"] = value;
    }
    temp[field] = value;
    setActiveCondition(temp);
  }

  function addCondition() {
    let temp = [...conditions];
    if (!activeCondition.new) {
      let tempcond = { ...activeCondition };
      tempcond.new = false;
      temp[tempcond.index] = tempcond;
      delete tempcond.index;
      setConditions(temp);
      setShowFields(false);
    } else {
      temp.push(activeCondition);
      setConditions(temp);
      setShowFields(false);
    }
    setActiveCondition("");
  }

  function deleteCondition() {
    let temp = [...conditions];
    temp.splice(activeCondition.index, 1);
    setConditions(temp);
    setShowFields(false);
  }

  // console.log(activeCondition);
  return (
    <div className={styles.fieldsContainer}>
      <Label
        style={{ padding: "0em", marginBottom: "5px" }}
        tooltipText={
          "Drag and drop the created Conditions below into editor or just type it in the email."
        }
        labelIcon={<i className="bi bi-question-circle"></i>}
      >
        Add Conditions
      </Label>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div className={styles.conditions}>
          {" "}
          {conditions.map((condition, i) => (
            <Condition
              key={i}
              condition={condition}
              handleOnDrag={handleOnDrag}
              setActiveCondition={setActiveCondition}
              setShow={setShowFields}
              index={i}
              activeIndex={activeCondition.index}
            ></Condition>
          ))}
        </div>
        <div
          className={styles.editPencil}
          onClick={() => {
            setActiveCondition(firstCondition);
            setShowFields(true);
          }}
        >
          +
        </div>
      </div>
      {showFields && (
        <div
          className={styles.edit}
          style={{
            height: activeCondition ? "fit-content" : "",
            width: activeCondition ? "100%" : "",
            display: activeCondition ? "flex" : "",
          }}
        >
          <Label>Create Condition</Label>{" "}
          <div className={styles.conditionHolder}>
            If{" "}
            <select
              value={activeCondition.field}
              onChange={(e) =>
                changeActiveConditionField("field", e.target.value)
              }
            >
              {fields.map((field) => (
                <option key={field.name} value={field.name}>
                  {field.displayName}
                </option>
              ))}
            </select>
            <select
              value={activeCondition.condition}
              onChange={(e) =>
                changeActiveConditionField("condition", e.target.value)
              }
            >
              {conditionOptions.map((field) => (
                <option key={field.name} value={field.value}>
                  {field.name}
                </option>
              ))}
            </select>{" "}
            <input
              placeholder="type option..."
              value={activeCondition.value}
              onChange={(e) =>
                changeActiveConditionField("value", e.target.value)
              }
            ></input>
            <br />
            <div style={{ display: "flex", flexDirection: "row" }}>
              show{" "}
              {activeCondition.show.map((show, i) => (
                <select
                  key={i}
                  value={show}
                  onChange={(e) =>
                    changeActiveConditionField("show", e.target.value, i)
                  }
                >
                  {fields.map((field) => (
                    <option key={field.name} value={field.name}>
                      {field.displayName}
                    </option>
                  ))}
                </select>
              ))}{" "}
              <div
                className={styles.editPencil}
                onClick={(e) =>
                  changeActiveConditionField(
                    "show",
                    e.target.value,
                    activeCondition.show.length
                  )
                }
              >
                +
              </div>
            </div>
            <br />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div>If above is not true, then show </div>
              <div style={{ display: "flex", flexDirection: "row" }}>
                {activeCondition.else.map((show, i) => (
                  <select
                    key={i}
                    value={show}
                    onChange={(e) =>
                      changeActiveConditionField("else", e.target.value, i)
                    }
                  >
                    {fields.map((field) => (
                      <option key={field.name} value={field.name}>
                        {field.displayName}
                      </option>
                    ))}
                  </select>
                ))}
                <div
                  className={styles.editPencil}
                  onClick={(e) =>
                    changeActiveConditionField(
                      "else",
                      e.target.value,
                      activeCondition.else.length
                    )
                  }
                >
                  +
                </div>
              </div>
            </div>
          </div>
          <TextFieldSimple
            placeholder="Type Condition Name..."
            label="Condition Name"
            value={activeCondition.name}
            onChange={(e) => changeActiveConditionField("name", e)}
          ></TextFieldSimple>
          <Condition
            condition={activeCondition}
            handleOnDrag={handleOnDrag}
            setShow={setShowFields}
            display
          ></Condition>
          <Label
            tooltipText={`If {{${activeCondition.field}}} ${
              activeCondition.condition
            } ${activeCondition.value} then show ${activeCondition.show.map(
              (item) => {
                return `{{${item}}}`;
              }
            )}, If this is not true then show ${activeCondition.else.map(
              (item) => `{{${item}}}`
            )}`}
            labelIcon={<i className="bi bi-question-circle"></i>}
          >
            Text Description
          </Label>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              gap: "1em",
            }}
          >
            {!activeCondition.new && (
              <Button height={35} onClick={deleteCondition} red shadow>
                Delete
              </Button>
            )}
            {activeCondition.new && (
              <Button
                height={35}
                shadow
                onClick={() => {
                  setShowFields(false);
                  setActiveCondition("");
                }}
                red
              >
                {" "}
                Cancel{" "}
              </Button>
            )}
            <Button height={35} onClick={addCondition} shadow>
              {activeCondition.new ? "Add" : "Done"}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

function Condition({
  handleOnDrag,
  condition,
  setActiveCondition,
  setShow,
  index,
  activeIndex,
  display,
}) {
  function activate() {
    if (setActiveCondition) {
      condition.new = false;
      condition.index = index;
      setActiveCondition(condition);
    }
    if (setShow) {
      setShow(true);
    }
  }
  return (
    <span
      draggable={true}
      onDragStart={(e) => handleOnDrag(e, condition)}
      onClick={activate}
      style={{
        backgroundColor: activeIndex === index && !display ? "lightgray" : "",
        boxShadow:
          activeIndex === index && !display
            ? "rgba(0, 0, 0, 0.24) 0px 3px 8px"
            : "",
        width: "fit-content",
        whiteSpace: "nowrap",
      }}
      className={styles.condition}
    >
      {`{condition ${condition.name}}`}
    </span>
  );
}
