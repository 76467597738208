import React, { useState } from "react";
import styles from "./EditableNavigation.module.scss";
import { Label } from "components/layouts/Label/Label";
import { SelectField } from "components/inputs";
import { ColorPicker } from "components/inputs/input_fields/ColorPicker/ColorPicker";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import Button from "components/Button/Button";
import { SurveyButton } from "components/Survey/SurveyButton/SurveyButton";

function EditableNavigation({
  settings,
  setSettings,
  previousPage,
  nextPage,
  onFinish,
  page,
  maxPage,
  active,
  setActive,
  close,
}) {
  function changeSetting(field, val) {
    let copy = { ...settings };
    copy[field] = val;
    setSettings(copy);
  }

  const buttonTypes = {
    Fill: {
      label: "Fill",
      value: "fill",
      name: "fill",
      icon: <i className="bi bi-list-check"></i>,
    },
    Link: {
      label: "Link",
      value: "link",
      name: "link",
      icon: <i className="bi bi-chat-left-text"></i>,
    },
    Outline: {
      label: "Outline",
      value: "outline",
      name: "outline",
      icon: <i className="bi bi-chat-left-text"></i>,
    },
  };

  const buttonExample = page != maxPage ? "Next" : "Finish";

  const buttonStyles = ["outline", "fill", "link"];

  const color = settings.buttonColor
    ? settings.buttonColor
    : settings.baseColor;

  function onBackgroundClick(e) {
    if (!e.target.className.includes("customButton")) {
      setActive("nav");
      document.addEventListener("click", checkForClickOutside, true);
    }
  }

  function checkForClickOutside(e) {
    let container = document.getElementById("nav");
    if (container && !container.contains(e.target)) {
      
      let root = document.getElementById("root");
      if (root && !root.contains(e.target)) {
        // It's a color picker - or some modal like it
        return;
      }

      document.removeEventListener("click", checkForClickOutside, true);
      close();
    }
  }

  return (
    <div
      className={`${styles.navContainer} ${
        active ? styles.activeNavContainer : styles.inactiveNavContainer
      } `}
      id="nav"
    >
      <div
        className={`${styles.pageButtonsContainer} ${
          active ? "" : styles.pageButtonsInactive
        }`}
        onClick={active ? null : onBackgroundClick}
      >
        {!active && <div className={styles.editSettings}>Click to edit</div>}
        {page != 1 && (
          <SurveyButton
            styleType={settings.buttonStyle}
            color={color}
            onClick={previousPage}
            shadow={settings?.buttonShadow}
          >
            BACK
          </SurveyButton>
        )}
        {page != maxPage && (
          <SurveyButton
            styleType={settings.buttonStyle}
            color={color}
            onClick={nextPage}
            shadow={settings?.buttonShadow}
          >
            NEXT
          </SurveyButton>
        )}
        {page === maxPage && (
          <SurveyButton
            styleType={settings.buttonStyle}
            color={color}
            onClick={onFinish}
            shadow={settings?.buttonShadow}
          >
            FINISH
          </SurveyButton>
        )}

        {settings.showPageProg && (
          <span style={{ zIndex: "0" }}>
            {" "}
            PAGE {page} OF {maxPage}
          </span>
        )}
      </div>
      {active && (
        <div className={styles.editNavContainer}>
          <div className={styles.settingsBody}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                paddingBottom: "10px",
                height: "fit-content",
              }}
            >
              <Label style={{ width: "fit-content" }}>Button Style</Label>
              {/* <SelectField
                options={Object.values(buttonTypes)}
                value={settings.buttonStyle}
                // icon={questionTypes[question.type].icon}
                onChange={(val) => changeSetting("buttonStyle", val.value)}
              /> */}

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "15px",
                  paddingLeft: "10px",
                }}
              >
                {buttonStyles.map((style) => (
                  <div
                    className={`${styles.buttonOption} ${
                      settings.buttonStyle == style
                        ? styles.chosenBtnOption
                        : ""
                    }`}
                    onClick={() => changeSetting("buttonStyle", style)}
                  >
                    <SurveyButton styleType={style} color={color}>
                      {buttonExample}
                    </SurveyButton>
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.settingHorizontal}>
              <div className={styles.colorContainer}>
                <ColorPicker
                  onChange={(color) => changeSetting("buttonColor", color)}
                  defaultColor={
                    settings.buttonColor
                      ? settings.buttonColor
                      : settings.baseColor
                  }
                  //   colors={settings.colorBank}
                />
              </div>
              <Label style={{ paddingLeft: "2.1em" }}>Button Color</Label>
            </div>
            <div className={styles.settingHorizontal}>
              <ToggleSwitch
                startChecked={settings?.buttonShadow}
                handleCheck={(val) => changeSetting("buttonShadow", val)}
              ></ToggleSwitch>
              <Label>Button Shadow</Label>
            </div>
            <div className={styles.settingHorizontal}>
              <ToggleSwitch
                startChecked={settings.showPageProg}
                handleCheck={(val) => changeSetting("showPageProg", val)}
              ></ToggleSwitch>
              <Label>Show Page Progress</Label>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default EditableNavigation;
