export function trimDate(date, giveTime) {
  if (date) {
    let d = new Date(date);
    let niceString = d.toDateString();
    let month = niceString.substring(4, 7);
    let day = niceString.substring(8, 10);
    day.charAt(0) === "0" ? (day = day.charAt(1)) : null;
    let year = niceString.substring(11);

    let string = month + " " + day + ", " + year;
    if (!giveTime) {
      return string;
    } else {
      let hours = d.getHours();
      let dayTime = "pm";
      if (hours > 0 && hours < 12) {
        hours = hours;
        dayTime = "am";
      } else if (hours > 12) {
        hours = hours - 12;
      } else if (hours === 0) {
        hours = 12;
        dayTime = "am";
      }

      return (
        string +
        " at " +
        hours +
        ":" +
        `${d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes()} ` +
        dayTime
      );
    }
  }
  return undefined;
}

export function trimTime(date) {
  if (date) {
    let d = new Date(date);
    let niceString = d.toDateString();
    let day = niceString.substring(8, 10);
    day.charAt(0) === "0" ? (day = day.charAt(1)) : null;

    let hours = d.getHours();
    let dayTime = "pm";
    if (hours > 0 && hours < 12) {
      hours = hours;
      dayTime = "am";
    } else if (hours > 12) {
      hours = hours - 12;
    } else if (hours === 0) {
      hours = 12;
      dayTime = "am";
    }

    return (
      hours +
      ":" +
      `${d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes()} ` +
      dayTime
    );
  }
}

const days = {
  0: "Sun",
  1: "Mon",
  2: "Tue",
  3: "Wed",
  4: "Thu",
  5: "Fri",
  6: "Sat",
};

export function trimTimeDay(date) {
  if (date) {
    let d = new Date(date);
    let niceString = d.toDateString();
    let month = niceString.substring(4, 7);
    let day = niceString.substring(8, 10);
    day.charAt(0) === "0" ? (day = day.charAt(1)) : null;

    let hours = d.getHours();
    let dayTime = "pm";
    if (hours > 0 && hours < 12) {
      hours = hours;
      dayTime = "am";
    } else if (hours > 12) {
      hours = hours - 12;
    } else if (hours === 0) {
      hours = 12;
      dayTime = "am";
    }

    let dayvar = days[date.getDay()];
    return (
      dayvar +
      " " +
      month +
      " " +
      day +
      ", " +
      hours +
      ":" +
      `${d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes()} ` +
      dayTime
    );
  }
}
