/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/

// External
import { useQueryClient } from "react-query";
import { gql } from "graphql-request";

// Internal
import { useGqlQuery, useGqlMutation } from "api/Api";

export const useFetchAnswersByAContact = (contactId) => {
  const query = gql`
    query {
      getAllAnswersByAContact(contactId: "${contactId}") {
        participations {
          answers {
            id
            scaleAnswer
            textAnswer
            choiceAnswer
            matrixAnswer
            createdAt
            updatedAt
            hide
            question {
              pageOrderIndex
              pageNumber
              buckets
              hasBuckets
              questionText
              project {
                name
                startedAt
              }
              scaleQuestion {
                id
                max
                min
              }
              textQuestion {
                id
              }
              choiceQuestion {
                id
                choices
                isRanking
              }
              matrixQuestion {
                id
                options
              }
            }
          }
        }
      }
    }
  `;

  return useGqlQuery(["answersByAContact", contactId], query, {});
};

export const useGetFilterableAnswers = (
  qIDs,
  filters,
  constraints,
  setData,
  search
) => {
  let data = {
    questionIds: qIDs,
  };

  let fieldIds = {
    constraints: constraints,
  };

  const query = gql`
    query Query(
      $data: questionIDArray!
      $filters: String!
      $userConstraints: UserConstraints!
      $search: String!
    ) {
      answers: getFilterableAnswers(
        data: $data
        filters: $filters
        userConstraints: $userConstraints
        search: $search
      ) {
        choiceAnswer
        choiceAnswerIndex
        scaleAnswer
        textAnswer
        matrixAnswer
        id
        questionId
        buckets
        updatedAt
        createdAt
        participation {
          id
          projectId
          startedAt
          contact {
            firstName
            email
            id
            lastName
            customField
          }
        }
      }
    }
  `;
  // const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      // queryClient.invalidateQueries("answers");
      if (setData) {
        setData(null); // Causes it to recalculate the data
      }
    },
  };

  return useGqlQuery(
    [
      "answers: " +
        qIDs.toString() +
        filters +
        JSON.stringify(constraints) +
        search,
    ],
    query,
    { data: data, filters: filters, userConstraints: fieldIds, search: search },
    options
  );
};

export const useGetQMetric = (data, filters, constraints, whatFor) => {
  let userConstraints = {
    constraints: constraints,
  };

  const query = gql`
    query Query(
      $whatFor: String!
      $userConstraints: UserConstraints!
      $filters: String!
      $data: QMetricData!
    ) {
      value: getQMetric(
        whatFor: $whatFor
        userConstraints: $userConstraints
        filters: $filters
        data: $data
      )
    }
  `;

  return useGqlQuery(
    [
      "QMetric: " +
        JSON.stringify(data) +
        filters +
        whatFor +
        JSON.stringify(constraints),
    ],
    query,
    {
      data: data,
      filters: filters,
      whatFor: whatFor,
      userConstraints: userConstraints,
    }
  );
};

export const useGetCorrespondingComments = (projIds, filters, constraints) => {
  let data = {
    projectIDs: projIds,
  };

  let fieldIds = {
    constraints: constraints,
  };

  const query = gql`
    query CorrespondingCommentQsAndAnswers(
      $userConstraints: UserConstraints!
      $filters: String!
      $data: projectIDArray!
    ) {
      QsAndAnswers: correspondingCommentQsAndAnswers(
        userConstraints: $userConstraints
        filters: $filters
        data: $data
      ) {
        answers {
          choiceAnswer
          choiceAnswerIndex
          scaleAnswer
          textAnswer
          matrixAnswer
          id
          questionId
          buckets
          updatedAt
          createdAt
          participation {
            id
            projectId
            startedAt
            contact {
              firstName
              email
              id
              lastName
              customField
            }
          }
        }
        questions {
          id
          index
          type
          questionText
          projectId
          hasBuckets
          buckets
          isScore
          project {
            id
            name
            startedAt
          }
          scaleQuestion {
            id
            min
            max
            step
          }
          textQuestion {
            id
            bucket {
              id
              name
              properties
            }
          }
          choiceQuestion {
            id
            choices
            hasOtherOption
            otherOptionLabel
            isRanking
            isMultiSelect
            limit
          }
          matrixQuestion {
            id
            options
            type
          }
        }
      }
    }
  `;

  return useGqlQuery(
    [
      "correspondingComments: " +
        projIds.toString() +
        filters +
        JSON.stringify(constraints),
    ],
    query,
    { data: data, filters: filters, userConstraints: fieldIds }
  );
};

export const useGetProjectMetric = (data, filters, constraints, whatFor) => {
  let userConstraints = {
    constraints: constraints,
  };

  const query = gql`
    query Query(
      $whatFor: String!
      $userConstraints: UserConstraints!
      $filters: String!
      $data: SurveyMetricData!
    ) {
      value: getProjectMetric(
        whatFor: $whatFor
        userConstraints: $userConstraints
        filters: $filters
        data: $data
      )
    }
  `;

  return useGqlQuery(
    [
      "SurveyMetric: " +
        JSON.stringify(data) +
        filters +
        whatFor +
        JSON.stringify(constraints),
    ],
    query,
    {
      data: data,
      filters: filters,
      whatFor: whatFor,
      userConstraints: userConstraints,
    }
  );
};

export const useGetToLastAnswers = (
  qIDs,
  filters,
  constraints,
  setData,
  search
) => {
  let data = {
    questionIds: qIDs,
  };

  let fieldIds = {
    constraints: constraints,
  };

  const query = gql`
    query Query(
      $data: questionIDArray!
      $filters: String!
      $userConstraints: UserConstraints!
      $search: String!
    ) {
      toLast: getToLastAnswers(
        data: $data
        filters: $filters
        userConstraints: $userConstraints
        search: $search
      ) {
        answers {
          choiceAnswer
          choiceAnswerIndex
          scaleAnswer
          textAnswer
          matrixAnswer
          id
          questionId
          buckets
          updatedAt
          createdAt
          participation {
            id
            projectId
            startedAt
            contact {
              firstName
              email
              id
              lastName
              customField
            }
          }
        }
        correlatedSurveys {
          correlated {
            from
            to
          }
          projectId
          surveyName
          questions {
            buckets
            questionText
            id
            isComment
            isScore
            type
            choiceQuestion {
              choices
              hasOtherOption
              id
              isMultiSelect
              isRanking
              limit
              otherOptionLabel
            }
            matrixQuestion {
              id
              options
              type
            }
            scaleQuestion {
              id
            }
            textQuestion {
              id
              bucket {
                id
                name
                properties
              }
            }
          }
        }
      }
    }
  `;
  // const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      // queryClient.invalidateQueries("answers");
      if (setData) {
        setData(null); // Causes it to recalculate the data
      }
    },
  };

  return useGqlQuery(
    [
      "toLast: " +
        qIDs.toString() +
        filters +
        JSON.stringify(constraints) +
        search,
    ],
    query,
    { data: data, filters: filters, userConstraints: fieldIds, search: search },
    options
  );
};

export const useUpdateAnswer = () => {
  const mutation = gql`
    mutation Mutation($data: AnswerInput!, $id: String!) {
      updateAnswer(data: $data, id: $id)
    }
  `;
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
  };

  return useGqlMutation(mutation, options);
};

export const useSaveAnswer = () => {
  const mutation = gql`
    mutation SaveAnswer($data: AnswerInput!, $increment: Boolean!) {
      saveAnswer(data: $data, increment: $increment) {
        id
        choiceAnswer
        choiceAnswerIndex
        participationId
        questionId
        scaleAnswer
        textAnswer
      }
    }
  `;
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
  };

  return useGqlMutation(mutation, options);
};

export const useRetractAnswer = () => {
  const mutation = gql`
    mutation RetractAnswer(
      $participationId: String!
      $questionId: String!
      $decrement: Boolean!
    ) {
      retractAnswer(
        participationId: $participationId
        questionId: $questionId
        decrement: $decrement
      ) {
        id
        choiceAnswer
        scaleAnswer
        textAnswer
        participationId
        questionId
      }
    }
  `;
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
  };

  return useGqlMutation(mutation, options);
};

export const useFinishSurvey = () => {
  const mutation = gql`
    mutation Mutation($participationId: String!) {
      finishSurvey(participationId: $participationId) {
        id
        participationId
        choiceAnswer
        scaleAnswer
        textAnswer
      }
    }
  `;
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
  };

  return useGqlMutation(mutation, options);
};

export const useRetractAnswers = () => {
  const mutation = gql`
    mutation Mutation(
      $questionIds: questionIDArray!
      $participationId: String!
      $decrement: Boolean!
    ) {
      retractAnswers(
        questionIds: $questionIds
        participationId: $participationId
        decrement: $decrement
      ) {
        id
        choiceAnswer
        scaleAnswer
        textAnswer
        participationId
        questionId
      }
    }
  `;
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
  };

  return useGqlMutation(mutation, options);
};

export const useImportBucketAnswers = () => {
  const mutation = gql`
    mutation Answers(
      $data: String!
      $questionId: String!
      $delimiter: String!
    ) {
      importBucketResults(
        data: $data
        questionId: $questionId
        delimiter: $delimiter
      )
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("answers");
    },
  };

  return useGqlMutation(mutation, options);
};
