import { useState } from "react";
import styles from "./LogoUpload.module.scss";
import Button from "components/Button/Button";

export function LogoUpload({ onSave, onCancel, height }) {
  const [image, setImage] = useState("");
  const [file, setFile] = useState("");

  const handleChange = (event) => {
    let selectedFile = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImage(reader.result);
    };

    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
      setFile(selectedFile);
    }
  };

  function switchOut() {
    let input = document.getElementById("imageInput");
    if (input) {
      input.click();
    }
  }

  function onUse() {
    onSave(file);
    onCancel();
  }

  return (
    <div
      className={styles.logoUpload}
      style={{ minHeight: height + 35 + "px" }}
    >
      <div className={styles.imageBay}>
        <input
          type="file"
          accept="image/*"
          onChange={handleChange}
          style={{ display: "none" }}
          id="imageInput"
        />
        <label htmlFor="imageInput">
          {image ? (
            <img
              src={image}
              alt="Selected Image"
              style={{ height: "50px", cursor: "pointer" }}
            />
          ) : (
            <div className={styles.empty}>
              <div>Select Image</div>
            </div>
          )}
        </label>
      </div>

      <div className={styles.buttons}>
        {image && file && (
          <>
            <Button seafoam height={25} onClick={switchOut}>
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                Switch <i className="bi bi-arrow-down-up"></i>
              </div>
            </Button>
            <Button shadow height={25} onClick={onUse}>
              <div
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                Use <i className="bi bi-floppy"></i>
              </div>
            </Button>
          </>
        )}
        <Button red shadow height={25} onClick={onCancel}>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            Cancel <i className="bi bi-x"></i>
          </div>
        </Button>
      </div>
    </div>
  );
}
