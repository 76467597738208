import React, { useState, useEffect, useRef } from "react";
import {
  useAudienceQuery,
  useUpdateAudienceGql,
} from "api/resources/contacts/audiences";
import styles from "./Audience.module.scss";
import AddToAudience from "./AddToAudience";
import AudienceOverview from "./AudienceOverview";
import { CSVLink } from "react-csv";
import { Loading } from "components/Loading/Loading";
import AudienceSettings from "./AudienceSettings";
import { useParams } from "react-router-dom";
import { useFetchCurrOrgsFields } from "api/resources/organization/organization";
import {
  useFetchUploadByAudience,
  useSearchContactsFiltersCount,
} from "api/resources/contacts/contacts";
import Button from "components/Button/Button";
import Modal from "components/ReactModal/ReactModal";
import ContactProfile from "components/Popout/ContactProfile";
import UnifinishedUpload from "../all_contacts/NewContacts/upload/UnifinishedUpload";
import { trimDate } from "assets/functions/DateFunctions";
import ContactTable from "../all_contacts/ContactTable";
import { randomColor } from "components/tables/EditableTable/utils";

function Audience({ user, organization, audienceId, preview }) {
  const { id } = useParams();
  const audienceQuery = useAudienceQuery(audienceId ? audienceId : id);
  const orgFields = useFetchCurrOrgsFields();

  return (
    <>
      {(audienceQuery.isLoading ||
        orgFields.isLoading ||
        audienceQuery.isRefetching) && <Loading></Loading>}
      {audienceQuery.isSuccess &&
        orgFields.isSuccess &&
        !audienceQuery.isRefetching && (
          <AudienceContainer
            audience={audienceQuery.data?.audience}
            user={user}
            customFields={orgFields?.data?.getCurrOrgFields}
            organization={organization}
            preview={preview}
          ></AudienceContainer>
        )}
    </>
  );
}

export default Audience;

function AudienceContainer({
  audience,
  user,
  customFields,
  organization,
  preview,
}) {
  // console.log(audience)
  const [showGrow, setShowGrow] = useState(false);
  const [settings, setSettings] = useState(false);
  const [upload, setUpload] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const [pageSkip, setPageSkip] = useState(0);
  const [sort, setSort] = useState({
    firstName: false,
    lastName: false,
    email: true,
    descend: false,
    item: "email",
  });
  const [searchString, setSearchString] = useState("");
  const [showContactDetails, setShowContactDetails] = useState(false);
  const [currentContact, setCurrentContact] = useState(null);
  const [active, setActive] = useState("1");
  const [data, setData] = useState([]);
  const [download, setDownload] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [headersExport, setHeadersExport] = useState(false);
  const csvref = useRef(null);
  const [viewPrev, setViewPrev] = useState(false);
  const [chosenFilters, setChosenFilters] = useState([]);
  const [audienceState, setAudienceState] = useState({
    ...audience,
    filters: audience?.filters ? JSON.parse(audience?.filters) : [],
  });

  const getContactCount = useSearchContactsFiltersCount(
    searchString,
    JSON.stringify(chosenFilters),
    audience?.id,
    JSON.stringify(audienceState.filters)
  );

  useEffect(() => {
    getContactCount.refetch();
  }, [
    searchString,
    pageSkip,
    sort,
    perPage,
    showGrow,
    chosenFilters,
    audienceState,
  ]);

  function setAudience(newAudience) {
    audience.contacts = newAudience;
  }

  const seeContact = (contact) => {
    console.log(contact);
    setShowContactDetails(true);
    setCurrentContact(contact);
  };

  function initHeaders() {
    let heads = [
      {
        id: "contactName",
        value: "contactName",
        name: "Contact Name",
        accessor: "firstName",
        label: "Contact Name",
        // width: 100,
        dataType: "text",
        enabled: true,
        sort: false,
        canSort: true,
        cell_style: (name, id, i, contact) =>
          contact && (
            <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
              {" "}
              <div
                className={styles.profileImage}
                style={{
                  backgroundColor: contact?.color
                    ? contact?.color
                    : randomColor(),
                }}
              >
                {" "}
                <span>
                  {contact && contact?.firstName[0] + contact?.lastName[0]}
                </span>
              </div>
              {contact && `${contact?.firstName} ${contact?.lastName}`}
            </div>
          ),
      },
      {
        id: "email",
        value: "email",
        name: "Email",
        accessor: "email",
        label: "Email",
        // width: 250,
        dataType: "text",
        enabled: true,
        sort: true,
        canSort: true,
        cell_style: null,
        editable: true,
      },
      {
        id: "updatedAt",
        value: "updatedAt",
        name: "Last Modified",
        accessor: "updatedAt",
        label: "Last Modified",
        // width: 100,
        dataType: "text",
        enabled: true,
        sort: false,
        canSort: true,
        cell_style: null,
      },
      // {
      //   id: 4,
      //   name: "When Added",
      //   accessor: "createdAt",
      //   enabled: true,
      //   sort: false,
      //   canSort: true,
      //   cell_style: null,
      // },
      {
        id: "unsubscribed",
        value: "unsubscribed",
        name: "Unsubscribed",
        accessor: "unsubscribed",
        label: "Unsubscribed",
        // width: 80,
        dataType: "text",
        enabled: true,
        sort: true,
        canSort: true,
        properties: ["True", "False"],
        cell_style: (item) => <div>{item ? "True" : "False"}</div>,
      },
      {
        id: "participationCount",
        value: "participationCount",
        name: "Survey Invites",
        label: "Survey Invites",
        // width: 80,
        dataType: "text",
        accessor: "participationCount",
        enabled: true,
        sort: true,
        canSort: true,
        cell_style: null,
      },
    ];

    for (let field of customFields) {
      heads.push({
        id: field.id,
        value: field.id,
        name: field?.displayName,
        accessor: field?.name,
        label: field?.displayName,
        // width: 100,
        dataType: "text",
        enabled: true,
        editable: true,
        properties:
          field?.properties && typeof field?.properties === "string"
            ? JSON.parse(field?.properties)
            : field?.properties,
        // cell_style: (val) => <div>{checkFields(val, field)}</div>,
      });
    }
    return heads;
  }

  const [headers, setHeaders] = useState(initHeaders());

  function getTitle() {
    return `Audiences → ${audience?.name}`;
  }

  useEffect(() => {
    if (download) {
      csvref.current.click();
      setDownload(false);
      setDownloading(false);
    }
  }, [data]);

  const updateAudience = useUpdateAudienceGql();

  function saveDynamicChanges() {
    updateAudience.mutate(
      {
        data: {
          dynamic:
            audienceState?.filters.length === 0
              ? false
              : audienceState?.dynamic,
          filters: audienceState?.filters
            ? JSON.stringify(audienceState?.filters)
            : undefined,
        },
        id: audience?.id,
      },
      {
        onSuccess: () => {},
      }
    );
  }

  return (
    <>
      {showContactDetails && (
        <>
          <div
            className={styles.backArrow}
            onClick={() => setShowContactDetails(false)}
          >
            <i className="bi bi-arrow-left"></i>
          </div>
          <ContactProfile
            onClose={() => setShowContactDetails(false)}
            contact={currentContact}
          ></ContactProfile>
        </>
      )}
      {!upload && (
        <>
          {settings && (
            <Modal
              show={settings}
              onClose={() => setSettings(false)}
              modalStyle={{
                height: "100%",
                width: "fit-content"
              }}
              dark
              rightStyle
            >
              <AudienceSettings
                audience={audience}
                setSettings={setSettings}
              ></AudienceSettings>
            </Modal>
          )}
          {audience && !showGrow && (
            <div className={styles.modalContainer}>
              <div className={styles.nav}>
                <div style={{ display: "flex", gap: "1em", flexWrap: "wrap" }}>
                  {!preview && (
                    <Button
                      shadow
                      seafoam
                      style={{ padding: "10px" }}
                      onClick={() => setSettings(true)}
                    >
                      <i className="bi-gear-fill"></i>
                    </Button>
                  )}

                  <div className={styles.name}> {audience?.name}</div>
                </div>

                <div style={{ display: "flex", gap: "1em", flexWrap: "wrap" }}>
                  <CSVLink
                    data={data ? data : []}
                    headers={headersExport ? headersExport : []}
                    //   headers={[]}
                    filename={audience.name + "-members.csv"}
                    style={{
                      display: "none",
                    }}
                    target="_blank"
                  >
                    <button
                      ref={csvref}
                      style={{ border: "none", backgroundColor: "transparent" }}
                    ></button>
                  </CSVLink>

                  {!preview && (
                    <div className={styles.viewPrev}>
                      <Button onClick={() => setViewPrev(true)} shadow yellow>
                        Uploads
                      </Button>
                      <Uploads
                        audience={audience}
                        upload={upload}
                        setUpload={setUpload}
                        viewPrev={viewPrev}
                        setViewPrev={setViewPrev}
                      ></Uploads>
                    </div>
                  )}
                </div>
              </div>
              {active === "0" && (
                <AudienceOverview
                  audience={audience}
                  settings={settings}
                  setSettings={setSettings}
                />
              )}

              {/*Members Page */}
              {active == "1" && !upload && (
                <div className={styles.table}>
                  <ContactTable
                    headers={headers}
                    setHeaders={setHeaders}
                    // data={searchProjects.}
                    // queryData={searchContacts}
                    queryDataAccessor={"contacts"}
                    maxDataQuery={getContactCount}
                    maxDataQueryAccessor={"count"}
                    currPage={pageNumber}
                    setCurrPage={setPageNumber}
                    maxItems={perPage}
                    // maxData={getProjectCount}
                    sort={sort}
                    setSort={setSort}
                    pageSkip={pageSkip}
                    setPageSkip={setPageSkip}
                    perPage={perPage}
                    setPerPage={setPerPage}
                    searchString={searchString}
                    setSearchString={setSearchString}
                    // actionButtonText={"Create/Update Contacts"}
                    tableTitle={getTitle()}
                    searchPlaceholder={"search contacts in audience..."}
                    onClickActionButton={() => setShow(true)}
                    hideActionButton={true}
                    chosenFilters={chosenFilters}
                    setChosenFilters={setChosenFilters}
                    orgId={organization?.id}
                    audienceId={audience?.id}
                    audience={audienceState}
                    updateAudience={setAudienceState}
                    saveAudience={saveDynamicChanges}
                    user={user}
                    hidebuttons={preview}
                  />{" "}
                </div>
              )}
            </div>
          )}

          {showGrow && !settings && (
            <AddToAudience
              audience={audience}
              setAudience={setAudience}
              user={user}
              setShowGrow={setShowGrow}
              showGrow={showGrow}
              // initContacts={searchContacts.data.contacts}
              onRowClick={seeContact}
              headers={headers}
              setUpload={setUpload}
            ></AddToAudience>
          )}
        </>
      )}
      {upload && (
        <UnifinishedUpload
          upload={upload}
          setUpload={setUpload}
          audience={audience}
        />
      )}
    </>
  );
}

function Uploads({ audience, upload, setUpload, viewPrev, setViewPrev }) {
  const getUploads = useFetchUploadByAudience(audience?.id);

  return (
    <>
      {viewPrev && (
        <div className={styles.recentUploadsBox}>
          {getUploads.isSuccess &&
            getUploads?.data?.upload?.length > 0 &&
            !upload && (
              <div className={styles.recentUploads}>
                <span>
                  Previous uploads{" "}
                  <i className="bi-x-lg" onClick={() => setViewPrev(false)}></i>
                </span>
                {getUploads?.data?.upload?.map((upload, i) => (
                  <div
                    key={i}
                    className={styles.upload}
                    onClick={() => {
                      setUpload(upload);
                      setViewPrev(false);
                    }}
                  >
                    {trimDate(upload?.uploadDate, true)}
                    <div className={styles.view}>View</div>
                  </div>
                ))}
              </div>
            )}
          {getUploads.isSuccess && getUploads?.data?.upload?.length === 0 && (
            <div
              className={styles.recentUploads}
              style={{ paddingBottom: "1em" }}
            >
              {" "}
              <span>
                No Upload Attempts
                <i className="bi-x-lg" onClick={() => setViewPrev(false)}></i>
              </span>
            </div>
          )}
        </div>
      )}
    </>
  );
}
