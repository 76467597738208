// External
import React, { useEffect, useState } from "react";
import styles from "./UserSignatures.module.scss";
import ReactModal from "components/ReactModal/ReactModal.jsx";
import { TextFieldSimple } from "components/inputs";
import { Label } from "components/layouts/Label/Label";
import { TextArea } from "components/inputs/input_fields/TextArea/TextArea";
import Button from "components/Button/Button";
import parse from "html-react-parser";
import {
  useCreateDistConfig,
  useUpdateDistConfig,
} from "api/resources/projects/distributions";
import { Loading } from "components/Loading/Loading";

// Internal

export const OldUserSig = ({ active, setActive, refetch, isActive, orgLevel }) => {
  const [loading, setLoading] = useState(false);
  const [change, setChange] = useState(false);
  const updateDistConfig = useUpdateDistConfig();
  const createDistConfig = useCreateDistConfig();

  function changeValue(field, value) {
    let sig = { ...active };
    sig[field] = value;
    setActive(sig);
    setChange(true);
  }

  function handleUpdateSignature(sig) {
    setLoading(true);

    if (sig.id) {
      let data = {
        signature: sig?.signature,
        aliasName: sig?.aliasName,
        fromAddress: sig?.fromAddress,
      };
      updateDistConfig.mutate(
        {
          data: data,
          id: active.id,
        },
        {
          onSuccess: () => {
            if (refetch) {
              refetch();
            }
            setLoading(false);
          },
        }
      );
    } else {
      let data = {
        signature: sig?.signature ? sig?.signature : "",
        aliasName: sig?.aliasName ? sig?.aliasName : "",
        fromAddress: sig?.fromAddress ? sig?.fromAddress : "",
        type: "signature",
        userId: sig?.userId ? sig?.userId : "",
        encoding: sig?.encoding ? sig?.encoding : "",
      };
      createDistConfig.mutate(
        {
          data: data,
        },
        {
          onSuccess: (data) => {
            if (refetch) {
              refetch();
            }
            setLoading(false);
          },
        }
      );
    }
  }

  return (
    <>
      <ReactModal
        show={active}
        onClose={() => setActive(null)}
        modalStyle={{
          borderRadius: "1em",
          padding: "1em",
          gap: "1em",
          height: "100%",
          width: "100%",
          maxWidth: "1200px",
        }}
        dark
      >
        <TextFieldSimple
          value={active?.aliasName}
          onChange={(val) => changeValue("aliasName", val)}
          label="Alias Name"
          placeholder="Type Alias..."
        />
        <TextFieldSimple
          value={active?.fromAddress}
          onChange={(val) => changeValue("fromAddress", val)}
          label="From Address (Email)"
          placeholder="Type Email..."
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "100%",
          }}
        >
          <Label>Signature (html)</Label>
          <TextArea
            value={active?.signature}
            onSave={(val) => changeValue("signature", val)}
            autosave
            placeholder="Paste Signature..."
            className={styles.thearea}
          />
          <div style={{ padding: "1em" }}>
            {parse(`<div>${active?.signature}</div>`)}
          </div>
        </div>
        {change && (
          <div
            style={{ display: "flex", justifyContent: "flex-end", gap: "1em" }}
          >
            <Button
              height={35}
              shadow
              seafoam
              onClick={() => {
                setActive(null);
              }}
            >
              Cancel
            </Button>
            {!loading && (
              <Button
                height={35}
                shadow
                onClick={() => {
                  handleUpdateSignature(active);
                }}
              >
                Save Changes
              </Button>
            )}

            {loading && (
              <Button height={35} shadow onClick={() => ""}>
                Saving <Loading height={20} width={20}></Loading>
              </Button>
            )}
          </div>
        )}
        {/* {!isActive && !orgLevel && (
          <div>
            <Button
              height={35}
              shadow
              onClick={() => {
                handleUpdateActiveSig(active);
              }}
            >
              Set as main signature
            </Button>
          </div>
        )} */}
        {isActive && !orgLevel && "This is your main signature"}
      </ReactModal>
    </>
  );
};
