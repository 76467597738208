import { NumberInput } from "components/inputs/input_fields/NumberInput/NumberInput";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import {
  SelectField,
  TextFieldSimple as TextField,
  TextFieldSimple,
} from "components/inputs";
import React, { useEffect, useState } from "react";
import styles from "../SettingsAccordion.module.scss";
import { Label } from "components/layouts/Label/Label";
import Button from "components/Button/Button";
import { HorizBarType, ValueType } from "../../QuestionChart";
import TextEditorTry from "components/inputs/input_fields/TextEditorTry/TextEditorTry";
import { NumSlider } from "components/inputs/input_fields/NumSlider/NumSlider";
import NumberInputWithSlider from "components/NumberInputWithSlider/NumberInputWithSlider";
import { combinedQs } from "../../Visualization";
import { ColorPicker } from "components/inputs/input_fields/ColorPicker/ColorPicker";

//custom accordian
export const TitleSettings = ({
  viz,
  title,
  updateViz,
  changeVizField,
  changeSettingsField,
  visible,
  setVisible,
}) => {
  // const [visibility, setVisibility] = useState(false);

  // const toggleVisibility = () => {
  //   setVisibility((v) => !v);
  // };

  const [showHint, setShowHint] = useState(false);
  const [oldTitle, setOldTitle] = useState();
  const [hasCustomTitle, setHasCustomTitle] = useState(
    !viz.designSettings.artificialTitle
  );

  function genTitle() {
    let titles = [];
    let title = "";
    for (let q of combinedQs(viz)) {
      if (!titles.includes(q.questionText)) {
        if (title.length > 0) {
          title += " | ";
        }
        title += q.questionText;
        titles.push(q.questionText);
      }
    }
    return title;
  }

  function onTitleEntry(val, encoding) {
    // debugger;
    if (viz.designSettings.artificialTitle) {
      changeSettingsField("artificialTitle", false);
    }

    let newViz = { ...viz };
    newViz["titleValue"] = val;
    updateViz(newViz);
  }

  const revertBtn = oldTitle
    ? () => {
        setOldTitle(null);
        changeSettingsField("artificialTitle", false);
        changeVizField("title", oldTitle);
      }
    : () => {
        setOldTitle(viz.title);
        changeSettingsField("artificialTitle", true);
        changeVizField("title", genTitle());
      };

  function changeTitleSize(val) {
    // Make it be .2, .3 .5
    changeSettingsField("titleFontSize", val);
  }

  function titleChange(val) {
    let newViz = { ...viz };
    newViz.title = val;
    newViz.titleValue = val;
    newViz.designSettings.artificialTitle = false;
    updateViz(newViz);
  }

  function showFilterSubtitle() {
    if (viz.filters) {
      let filters = JSON.parse(viz.filters);
      return Object.keys(filters).length > 0;
    }
    return false;
  }

  return (
    <>
      <div
        key={"titles"}
        className={`${styles.header} ${visible ? styles.headervisible : ""}`}
        onClick={setVisible}
      >
        {/* {title} */}

        <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
          {title} <i style={{ fontSize: "1.2em" }} className="bi bi-fonts"></i>
        </div>

        <span className={styles.accordionicon}>
          <i className="bi bi-caret-left-fill"></i>
        </span>
      </div>
      {visible && (
        <div className={styles.body}>
          <div className={styles.setting2} style={{paddingLeft: '5px'}}>
            <ToggleSwitch
              startChecked={viz.designSettings.hasTitle}
              handleCheck={(val) => changeSettingsField("hasTitle", val)}
            ></ToggleSwitch>
            <Label
              style={{
                width: "fit-content",
                fontSize: ".9em",
              }}
            >
              Chart Title
            </Label>
          </div>
          {viz.designSettings.hasTitle && (
            <>
              {/* {hasCustomTitle && (
                <div className={styles.setting}>
                  <Label
                    style={{
                      fontWeight: "700",
                      width: "fit-content",
                      paddingLeft: "20px",
                      fontSize: ".9em",
                    }}
                    labelIcon={
                      viz.designSettings.artificialTitle ? null : (
                        <i className="bi bi-info-square"></i>
                      )
                    }
                    tooltipText={genTitle()}
                  >
                    <div className={styles.linkButton} onClick={revertBtn}>
                      {oldTitle ? (
                        <>
                          <i className="bi bi-arrow-counterclockwise"></i>
                          {` Undo`}
                        </>
                      ) : (
                        `Use Question as Title`
                      )}
                    </div>
                  </Label>
                </div>
              )} */}
              <div
                className={styles.settingwithlabel}
                style={{ paddingBottom: "10px" }}
              >
                <Label
                  style={{
                    paddingLeft: "20px",
                    fontSize: ".8em",
                  }}
                >
                  Title Text
                </Label>
                <TextFieldSimple
                  value={viz.title}
                  onChange={titleChange}
                  style={{ height: "30px", fontSize: "1em" }}
                ></TextFieldSimple>
              </div>
              <div
                className={styles.setting}
                style={{
                  alignItems: "start",
                  paddingLeft: "10px",
                  paddingRight: "0px",
                }}
              >
                <Label
                  // italics
                  style={{
                    fontWeight: "400",
                    width: "fit-content",
                    fontSize: ".8em",
                    whiteSpace: "nowrap",
                    padding: "0em",
                    margin: "0em",
                  }}
                >
                  Title Font Size
                </Label>
                <div className={styles.setting2} style={{ gap: "20px" }}>
                  <div style={{ width: "100%" }}>
                    <NumSlider
                      value={
                        viz.designSettings.valueTitleSize
                          ? viz.designSettings.valueTitleSize
                          : 17
                      }
                      min={5}
                      max={60}
                      step={1}
                      onChange={(val) =>
                        changeSettingsField("valueTitleSize", val)
                      }
                      color={"#2A627C"}
                    ></NumSlider>
                  </div>
                  <NumberInput
                    startNumber={
                      viz.designSettings.valueTitleSize
                        ? viz.designSettings.valueTitleSize
                        : 17
                    }
                    min={5}
                    max={60}
                    step={1}
                    handleNumberChange={(val) =>
                      changeSettingsField("valueTitleSize", val)
                    }
                    color={"#2A627C"}
                  ></NumberInput>
                </div>
              </div>

              <div
                className={styles.setting2}
                style={{ gap: "10px", paddingLeft: "10px" }}
              >
                <ColorPicker
                  defaultColor={
                    viz.designSettings.titleColor
                      ? viz.designSettings.titleColor
                      : "#ffffff"
                  }
                  onChange={(val) => changeSettingsField("titleColor", val)}
                  textColors
                />
                <Label
                  style={{
                    fontWeight: "400",
                    width: "fit-content",
                    fontSize: ".8em",
                    whiteSpace: "nowrap",
                  }}
                >
                  Title Color
                </Label>
              </div>

              <div
                className={styles.setting2}
                style={{ gap: "10px", paddingLeft: "10px" }}
              >
                {" "}
                <ColorPicker
                  defaultColor={
                    viz.designSettings.titleBackgroundColor
                      ? viz.designSettings.titleBackgroundColor
                      : "#ffffff"
                  }
                  onChange={(val) =>
                    changeSettingsField("titleBackgroundColor", val)
                  }
                  textColors
                />
                <Label
                  style={{
                    fontWeight: "400",
                    width: "fit-content",
                    fontSize: ".8em",
                    whiteSpace: "nowrap",
                  }}
                >
                  Title Background Color
                </Label>
              </div>

              <div
                className={styles.setting}
                style={{
                  alignItems: "start",
                  paddingLeft: "10px",
                  paddingRight: "0px",
                }}
              >
                <Label
                  // italics
                  style={{
                    fontWeight: "400",
                    width: "fit-content",
                    fontSize: ".8em",
                    whiteSpace: "nowrap",
                    padding: "0em",
                    margin: "0em",
                  }}
                >
                  Title Border Radius
                </Label>
                <div className={styles.setting2} style={{ gap: "20px" }}>
                  <div style={{ width: "100%" }}>
                    <NumSlider
                      value={
                        viz.designSettings.titleBorderRadius
                          ? viz.designSettings.titleBorderRadius
                          : 0
                      }
                      min={0}
                      max={60}
                      step={1}
                      onChange={(val) =>
                        changeSettingsField("titleBorderRadius", val)
                      }
                      color={"#2A627C"}
                    ></NumSlider>
                  </div>
                  <NumberInput
                    startNumber={
                      viz.designSettings.titleBorderRadius
                        ? viz.designSettings.titleBorderRadius
                        : 0
                    }
                    min={0}
                    max={60}
                    step={1}
                    handleNumberChange={(val) =>
                      changeSettingsField("titleBorderRadius", val)
                    }
                    color={"#2A627C"}
                  ></NumberInput>
                </div>
              </div>

              {viz.type != ValueType && (
                <div
                  className={styles.setting}
                  style={{
                    marginTop: "5px",
                    gap: "0em",
                    justifyContent: "center",
                  }}
                >
                  <Label
                    italics
                    style={{
                      fontWeight: "400",
                      width: "fit-content",
                      fontSize: ".8em",
                      padding: "0em",
                      margin: "0em",
                    }}
                  >
                    Title Alignment
                  </Label>
                  <div
                    className={styles.setting2}
                    style={{
                      paddingLeft: "1.5em",
                      marginTop: "5px",
                      gap: ".5em",
                      width: "fit-content",
                    }}
                  >
                    <div
                      className={`${styles.itemA} ${
                        viz.designSettings.titleAlignment === "start" &&
                        styles.activeItem
                      } `}
                      onClick={() =>
                        changeSettingsField("titleAlignment", "start")
                      }
                    >
                      <i className="bi-text-left"></i>
                    </div>
                    <div
                      className={`${styles.itemA} ${
                        viz.designSettings.titleAlignment === "center" &&
                        styles.activeItem
                      } `}
                      onClick={() =>
                        changeSettingsField("titleAlignment", "center")
                      }
                    >
                      <i className="bi-text-center"></i>
                    </div>
                    <div
                      className={`${styles.itemA} ${
                        viz.designSettings.titleAlignment === "end" &&
                        styles.activeItem
                      } `}
                      onClick={() =>
                        changeSettingsField("titleAlignment", "end")
                      }
                    >
                      <i className="bi-text-right"></i>
                    </div>
                  </div>
                </div>
              )}
              {viz.type != ValueType && (
                <div
                  className={styles.setting}
                  style={{
                    marginTop: "5px",
                    gap: "0em",
                    justifyContent: "center",
                  }}
                >
                  <Label
                    italics
                    style={{
                      fontWeight: "400",
                      width: "fit-content",
                      fontSize: ".8em",
                      padding: "0em",
                      margin: "0em",
                    }}
                  >
                    Title Padding
                  </Label>
                  <div
                    className={styles.setting}
                    style={{
                      paddingLeft: "1.5em",
                      marginTop: "5px",
                      gap: ".5em",
                    }}
                  >
                    <div
                      className={`${styles.itemB} `}
                      style={{ flexDirection: "column" }}
                    >
                      <i className="bi-arrow-bar-up"></i>
                      <NumberInput
                        startNumber={
                          viz.designSettings?.paddingTopTitle
                            ? viz.designSettings.paddingTopTitle
                            : 0
                        }
                        handleNumberChange={(val) =>
                          changeSettingsField("paddingTopTitle", val)
                        }
                        min={0}
                        max={500}
                      ></NumberInput>
                    </div>
                    <div className={styles.setting2} style={{ gap: ".5em" }}>
                      {" "}
                      <div className={`${styles.itemB} `}>
                        <i className="bi-arrow-bar-left"></i>
                        <NumberInput
                          startNumber={
                            viz.designSettings?.paddingLeftTitle
                              ? viz.designSettings.paddingLeftTitle
                              : 0
                          }
                          handleNumberChange={(val) =>
                            changeSettingsField("paddingLeftTitle", val)
                          }
                          min={0}
                          max={500}
                        ></NumberInput>
                      </div>
                      <div className={`${styles.itemB} `}>
                        <NumberInput
                          startNumber={
                            viz.designSettings?.paddingRightTitle
                              ? viz.designSettings.paddingRightTitle
                              : 0
                          }
                          handleNumberChange={(val) =>
                            changeSettingsField("paddingRightTitle", val)
                          }
                          min={0}
                          max={500}
                        ></NumberInput>
                        <i className="bi-arrow-bar-right"></i>
                      </div>
                    </div>
                    <div
                      className={`${styles.itemB} `}
                      style={{ flexDirection: "column" }}
                    >
                      <NumberInput
                        startNumber={
                          viz.designSettings?.paddingBottomTitle
                            ? viz.designSettings.paddingBottomTitle
                            : 0
                        }
                        handleNumberChange={(val) =>
                          changeSettingsField("paddingBottomTitle", val)
                        }
                        min={0}
                        max={500}
                      ></NumberInput>
                      <i className="bi-arrow-bar-down"></i>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}

          {/* <div className={styles.setting2}>
            <Label
              style={{
                width: "fit-content",
                paddingLeft: "20px",
                fontSize: ".9em",
              }}
              labelIcon={<i className="bi bi-info-square"></i>}
            >
              Show Filter Subtitle
            </Label>
            <ToggleSwitch
              startChecked={viz.designSettings.hasSubtitle}
              handleCheck={(val) => changeSettingsField("hasSubtitle", val)}
            ></ToggleSwitch>
          </div> */}
          {showFilterSubtitle() && (
            <div className={styles.setting2}>
              <ToggleSwitch
                startChecked={viz.designSettings.showFilterSubtitle}
                handleCheck={(val) =>
                  changeSettingsField("showFilterSubtitle", val)
                }
              ></ToggleSwitch>
              <Label
                style={{
                  width: "fit-content",
                  fontSize: ".9em",
                }}
              >
                Show Filter Subtitle
              </Label>
            </div>
          )}
        </div>
      )}
    </>
  );
};
