import { useEffect, useState } from "react";
// import AccordionItem from "./Settings/AccordionItem";
import { DataLabels } from "./AccordianItems/DataLabels";
import { LegendSettings } from "./AccordianItems/LegendSettings";
import { TitleSettings } from "./AccordianItems/TitleSettings";
import { Axes } from "./AccordianItems/Axes";
import styles from "./SettingsAccordion.module.scss";

import {
  ValueType,
  TableType,
  MetricType,
  PieType,
  DoughnutType,
  defaultChartSettings,
  VertBarType,
  splitSomehow,
  LineType,
  HorizBarType,
} from "../QuestionChart";
import { SplitsNPivotsModal } from "./AccordianItems/SplitsNPivots";
import { FilterAccordian, FilterModal } from "./AccordianItems/FilterSettings";
import { SortingSettings } from "./AccordianItems/SortingSettings";
import { TableColumns } from "./AccordianItems/TableColumns";
import { QData } from "./AccordianItems/SurveyData/QData";
import { TextBoxSettings } from "./AccordianItems/TextBoxSettings";
import { forEach } from "assets/functions/ArrayFunctions";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import { ChartType } from "./AccordianItems/ChartType";
import { ColorSettings } from "./AccordianItems/ColorSettings";
import { SelectField } from "components/inputs";
import { AdvancedSettings } from "./AccordianItems/AdvancedSettings";
import Table from "../Tables/Table";
import { useGetFilterableAnswers } from "api/resources/projects/answers";
import { useFetchProjectsByIdsGql } from "api/resources/projects/projects";
import TableChart from "components/Charts/Table/TableChart";
import { Loading } from "components/Loading Rectangle/Loading";
import { LayoutSettings } from "./AccordianItems/LayoutSettings";
import { shortId } from "components/tables/EditableTable/utils";
import { defaultMetricSettings, singleMetricDefault } from "../ScoreChart";
import { PickMetric } from "./AccordianItems/MetricSettings/AddMetric/AddMetric";
import MetricSettings from "./AccordianItems/MetricSettings/MetricSettings";
import { combineFilters, combinedProjIds, combinedQs } from "../Visualization";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";
import { defaultTableSettings } from "../TableViz";
import { blankViz } from "../AddChart";
import { TablePivotsModal } from "./AccordianItems/TablePivots";
import { SurveyData } from "./AccordianItems/SurveyData/SurveyData";
import { StatSplitsNPivotsModal } from "./AccordianItems/StatSplitsNPivots";
import { defaultStatChartSettings } from "../StatChart";
import { AdvancedStatSettings } from "./AccordianItems/AdvancedStatSettings";
import { useFetchQChartRoles } from "api/resources/organization/roles";
const dataMethods = [
  { value: "question", label: "Get data from survey questions" },
  { value: "survey", label: "Get data from survey stats" },
  // { value: "message", label: "Get data from survey messages" },
  // { value: "manual", label: "Manually add data" },
];

export function splitting(viz) {
  if (viz.dataMethod === "survey") {
    return (
      viz.pivotString ||
      viz.designSettings.split ||
      viz.designSettings.useComparison
    );
  } else {
    return splitSomehow(viz) || viz.designSettings.useComparison;
  }
}

/**
 *
 * @param {object} viz the visualization object
 * @param {function} updateSettings function to change the design settings
 * @param {function} updateType function to change the type of the chart
 * @param {function} updateTitle function to change the visualization title
 * @param {function} changeData fiunction to change the
 * @returns
 */

export const SettingsAccordion = ({
  viz,
  updateViz,
  labels,
  lockData,
  projects,
  custom_fields,
  onFilterChange,
  newChart,
  dataPage,
  setDataPage,
  chartData,
  setChartData,
  externalFilter,
  hasData,
}) => {
  // const [dataPage, setDataPage] = useState(true);

  const getRole = useFetchQChartRoles();

  const scoreType = viz.type === MetricType || viz.type === ValueType;

  const [visibleItems, setVisibleItems] = useState({
    SurveyData: true,
    ChartType: false,
    Axes: false,
    ChartSegments: false,
    Title: false,
    Legend: false,
    Labels: false,
    DataSettings: false,
    Filters: false,
    SplitsNPivots: false,
    MetricSurvey: false,
    MetricSettings: scoreType && !viz.designSettings.metrics?.length,
    ScoreData: false,
    AdvancedData: false,
    ChartDetails: false,
    LayoutSettings: false,
  });

  const [dataMethod, setDataMethod] = useState(
    viz.dataMethod
      ? dataMethods.find((m) => m.value === viz.dataMethod)
      : { value: "question", label: "Get data from survey questions" }
  );
  const [addMetric, setAddMetric] = useState(false);
  const changeSettingsField = (field, val) => {
    let newViz = { ...viz };
    newViz.designSettings[field] = val;
    updateViz(newViz);
  };

  const changeVizField = (field, val) => {
    let newViz = { ...viz };
    newViz[field] = val;
    updateViz(newViz);
  };
  const [filterCount, setFilterCount] = useState(getFilterCount());

  useEffect(() => {
    setFilterCount(getFilterCount());
  }, [viz.filters]);

  // used to save colors set for a specific dataset, so you don't have to recreate it
  const [originalColors, setOriginalColors] = useState(
    viz.designSettings.colors
      ? {
          colors: viz.designSettings.colors,
          split: viz.designSettings.split,
          pivot: viz.pivotString,
          filters: viz.filters,
        }
      : undefined
  );
  // Could potentially do something in changeSettingsField to update the originalColors...

  function setVisible(item) {
    let items = { ...visibleItems };
    let visible = items[item];
    forEach(Object.keys(items), (key) => {
      items[key] = false;
    });
    items[item] = !visible;
    setVisibleItems(items);
  }

  function changePage(dPage) {
    setDataPage(dPage);
  }

  function changeMethod(method) {
    let newViz = {};
    if (viz.type === TableType) {
      newViz = {
        type: TableType,
        designSettings: JSON.parse(JSON.stringify(defaultTableSettings)),
        ...JSON.parse(JSON.stringify(blankViz)),
      };
    } else {
      newViz = {
        type: VertBarType,
        designSettings: JSON.parse(JSON.stringify(defaultChartSettings)),
        ...JSON.parse(JSON.stringify(blankViz)),
      };
    }

    if (method.value === "survey") {
      newViz.designSettings = {
        ...newViz.designSettings,
        ...JSON.parse(JSON.stringify(defaultStatChartSettings)),
      };
      newViz.programIds = [];
    }
    newViz.dataMethod = method.value;

    updateViz(newViz);
    setDataMethod(method);
  }

  function getFilterCount() {
    let filtersObject = JSON.parse(combineFilters(viz, externalFilter));
    let count = 0;
    for (let key in filtersObject) {
      let filter = filtersObject[key];
      if (key === "surveys") {
        let allProjIds = combinedProjIds(viz);
        if (allProjIds.length > 1) {
          for (let survey of filter) {
            if (allProjIds.includes(survey.id)) {
              count++;
            }
          }
        }
      } else if (key === "answers") {
        count += filter.length;
      } else {
        if (filter?.properties?.length) {
          count += filter?.properties?.length;
        }
      }
    }
    return count;
  }

  function getPivotCount() {
    let count = 0;
    if (viz.pivotString) {
      count += 1;
    }
    if (viz.designSettings.split) {
      count += 1;
    }
    return count;
  }

  const typeVals = ["Chart", "Table", "Metric"];

  function getCurrType() {
    if (scoreType) return "Metric";
    if (viz.type === TableType) return "Table";
    return "Chart";
  }

  function noEmptyQTagSelections() {
    if (viz.questionTags) {
      for (let tag of viz.questionTags) {
        if (tag.selection && !tag.selection.length) {
          return false;
        }
      }
    }
    return true;
  }

  function onChangeType(type) {
    if (type !== getCurrType()) {
      let newViz = {};
      if (type === "Chart") {
        newViz = {
          type: VertBarType,
          designSettings: JSON.parse(JSON.stringify(defaultChartSettings)),
          ...JSON.parse(JSON.stringify(blankViz)),
        };
      } else if (type === "Table") {
        newViz = {
          type: TableType,
          designSettings: JSON.parse(JSON.stringify(defaultTableSettings)),
          ...JSON.parse(JSON.stringify(blankViz)),
        };
      } else {
        newViz = {
          type: MetricType,
          designSettings: JSON.parse(JSON.stringify(defaultMetricSettings)),
          ...JSON.parse(JSON.stringify(blankViz)),
        };
        setDataPage(false);
        setVisible("MetricSettings");
      }
      updateViz(newViz);
    }
  }

  return (
    <>
      {!scoreType && (
        <div className={styles.navContainer}>
          {dataPage && (
            <div className={styles.dataHeader}>
              <div style={{ fontSize: ".8em" }}>
                <SelectField
                  // selectMultiple
                  onChange={(data) => changeMethod(data)}
                  options={dataMethods}
                  value={dataMethod}
                  placeholder="Choose how to get your data"
                  height={28}
                  disable={!newChart || viz.type === TableType}
                ></SelectField>
              </div>
              {hasData && (
                <div className={styles.settings}>
                  <div
                    className={styles.reportSettingsButton}
                    onClick={() => setVisible("Filters")}
                  >
                    {" "}
                    <i className="bi-funnel"></i>Filters
                    {filterCount > 0 && (
                      <div className={styles.number}>{getFilterCount()}</div>
                    )}
                  </div>{" "}
                  <HorizontalBar rotate height={15} width={2}></HorizontalBar>
                  <div
                    className={styles.reportSettingsButton}
                    onClick={() => setVisible("SplitsNPivots")}
                  >
                    <i className="bi-person-lines-fill"></i>Splits & Pivots{" "}
                    {getPivotCount() > 0 && (
                      <div className={styles.number}>{getPivotCount()}</div>
                    )}
                  </div>
                  {visibleItems["SplitsNPivots"] && (
                    <div className={styles.settingsPopout}>
                      {viz.type !== TableType && (
                        <>
                          {viz.dataMethod === "survey" && (
                            <StatSplitsNPivotsModal // list-check    eyeglasses    graph-up     list-columns   list-nested   list-ul    person-check-fill   person-gear  person-lines-fill    person-plus-fill   sliders-2
                              viz={viz}
                              updateViz={updateViz}
                              projects={projects}
                              close={() => setVisible("SplitsNPivots")}
                              changeSettingsField={changeSettingsField}
                              custom_fields={custom_fields}
                              originalColors={originalColors}
                            ></StatSplitsNPivotsModal>
                          )}

                          {viz.dataMethod !== "survey" && (
                            <SplitsNPivotsModal // list-check    eyeglasses    graph-up     list-columns   list-nested   list-ul    person-check-fill   person-gear  person-lines-fill    person-plus-fill   sliders-2
                              viz={viz}
                              updateViz={updateViz}
                              close={() => setVisible("SplitsNPivots")}
                              changeSettingsField={changeSettingsField}
                              custom_fields={custom_fields}
                              originalColors={originalColors}
                            ></SplitsNPivotsModal>
                          )}
                        </>
                      )}
                      {viz.type === TableType && (
                        <TablePivotsModal
                          viz={viz}
                          updateViz={updateViz}
                          close={() => setVisible("SplitsNPivots")}
                          changeSettingsField={changeSettingsField}
                          custom_fields={custom_fields}
                          projects={projects}
                        ></TablePivotsModal>
                      )}
                    </div>
                  )}
                  {visibleItems["Filters"] && (
                    <div className={styles.settingsPopout}>
                      <FilterModal // funnel-fill      person-lines-fill
                        viz={viz}
                        close={() => setVisible("Filters")}
                        projects={projects}
                        custom_fields={custom_fields}
                        onFilterChange={onFilterChange}
                        changeSettingsField={changeSettingsField}
                        externalFilter={externalFilter}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          <div className={styles.nav} style={{ flexDirection: "column" }}>
            <div className={styles.nav} style={{ justifyContent: "end" }}>
              <div className={styles.nav2}>
                <div
                  className={`${styles.navItem} ${
                    dataPage ? styles.active : ""
                  }`}
                  onClick={() => changePage(true)}
                >
                  <i className="bi-receipt-cutoff"></i>Data
                </div>
                <HorizontalBar width={5} height={"20px"} rotate />
                <div
                  className={`${styles.navItem} ${
                    !dataPage ? styles.active : ""
                  }`}
                  onClick={hasData ? () => changePage(false) : undefined}
                >
                  <i className="bi-brush"></i>Style
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                width: "100%",
                maxWidth: "315px",
                justifyContent: "space-around",
              }}
            >
              {dataPage && (
                <>
                  <HorizontalBar
                    height={3}
                    width={"27%"}
                    backgroundColor={"#15bcc7"}
                    rotate
                  />
                  <HorizontalBar
                    height={3}
                    width={"32%"}
                    backgroundColor={"transparent"}
                    rotate
                  />
                </>
              )}{" "}
              {!dataPage && (
                <>
                  <HorizontalBar
                    height={3}
                    width={"32%"}
                    backgroundColor={"transparent"}
                    rotate
                  />
                  <HorizontalBar
                    height={3}
                    width={"27%"}
                    backgroundColor={"#15bcc7"}
                    rotate
                  />
                </>
              )}
            </div>
          </div>
        </div>
      )}
      <div className={dataPage ? styles.accordionData : styles.accordion}>
        {dataPage && (
          <>
            {dataMethod.value === "question" && (
              <>
                {newChart &&
                  !combinedQs(viz).length &&
                  noEmptyQTagSelections() && (
                    <div className={styles.switchType}>
                      <div className={styles.selectContainer}>
                        <CombinedInput
                          value={getCurrType()}
                          options={typeVals}
                          style={{ backgroundColor: "#ffffff" }}
                          hideChosen
                          select
                          label={"Type"}
                          icon={"bi bi-chevron-compact-down"}
                          onChange={onChangeType}
                        />
                      </div>
                    </div>
                  )}

                <div className={styles.dataSettings}>
                  {!lockData && (
                    <QData // clipboard-data      journal-text
                      viz={viz}
                      updateViz={updateViz}
                      newChart={newChart}
                    ></QData>
                  )}

                  <HorizontalBar width={3} height={"100%"} rotate />

                  {hasData && (
                    <AdvancedSettings
                      viz={viz}
                      updateViz={updateViz}
                      filters={combineFilters(viz, externalFilter)}
                      changeSettingsField={changeSettingsField}
                    ></AdvancedSettings>
                  )}
                </div>
                <HorizontalBar
                  width={"96%"}
                  height={2}
                  style={{ margin: "0em" }}
                />
                {viz.type !== TableType &&
                  getRole.isSuccess &&
                  getRole.data.role.canSeeContactInfo && (
                    <div className={styles.tableContainer}>
                      <SurveyDataTables
                        filters={combineFilters(viz, externalFilter)}
                        viz={viz}
                        custom_fields={custom_fields}
                        chartData={chartData}
                        setChartData={setChartData}
                      />
                    </div>
                  )}
              </>
            )}

            {dataMethod.value === "manual" && (
              <div className={styles.tableContainer}>
                <ManualDataTable viz={viz} />
              </div>
            )}

            {dataMethod.value === "survey" && (
              <>
                <div className={styles.dataSettings}>
                  {!lockData && (
                    <SurveyData // clipboard-data      journal-text
                      viz={viz}
                      updateViz={updateViz}
                      method={dataMethod.value}
                      newChart={newChart}
                    ></SurveyData>
                  )}

                  <HorizontalBar width={3} height={"100%"} rotate />
                  {hasData && (
                    <AdvancedStatSettings
                      viz={viz}
                      updateViz={updateViz}
                      changeSettingsField={changeSettingsField}
                      projects={projects}
                    ></AdvancedStatSettings>
                  )}
                </div>
              </>
            )}
          </>
        )}

        {!dataPage && (
          <>
            {viz.type === TableType && hasData && (
              <TableColumns // layout-text-window-reverse
                viz={viz}
                title="Table Columns"
                changeSettingsField={changeSettingsField}
                updateViz={updateViz}
                visible={visibleItems["Table"]}
                setVisible={() => setVisible("Table")}
                projects={projects}
                custom_fields={custom_fields}
              ></TableColumns>
            )}
            {!scoreType && viz.type !== TableType && (
              <ChartType // pie-chart
                viz={viz}
                title="Chart Type"
                updateViz={updateViz}
                visible={visibleItems["ChartType"]}
                setVisible={() => setVisible("ChartType")}
              />
            )}

            {scoreType && (
              <>
                <MetricSettings
                  title="Metrics"
                  changeSettings={changeSettingsField}
                  updateViz={updateViz}
                  settings={viz.designSettings}
                  viz={viz}
                  visible={visibleItems["MetricSettings"]}
                  setVisible={() => setVisible("MetricSettings")}
                />
                <FilterAccordian // funnel-fill      person-lines-fill
                  viz={viz}
                  visible={visibleItems["Filters"]}
                  setVisible={() => setVisible("Filters")}
                  projects={projects}
                  custom_fields={custom_fields}
                  onFilterChange={onFilterChange}
                  changeSettingsField={changeSettingsField}
                  externalFilter={externalFilter}
                />
              </>
            )}

            <LayoutSettings // columns
              viz={viz}
              title="Layout"
              visible={visibleItems["LayoutSettings"]}
              setVisible={() => setVisible("LayoutSettings")}
              changeSettingsField={changeSettingsField}
              projects={projects}
              custom_fields={custom_fields}
            />

            {!scoreType && (
              <ColorSettings // bar-chart-fill
                viz={viz}
                title="Colors"
                changeSettingsField={changeSettingsField}
                labels={labels}
                updateViz={updateViz}
                visible={visibleItems["ChartSegments"]}
                setVisible={() => setVisible("ChartSegments")}
              />
            )}
            {!scoreType && (
              <>
                {(viz.type === VertBarType ||
                  viz.type === HorizBarType ||
                  viz.type === LineType) && (
                  <Axes // graph-up
                    viz={viz}
                    title="Axes"
                    changeSettingsField={changeSettingsField}
                    updateViz={updateViz}
                    visible={visibleItems["Axes"]}
                    setVisible={() => setVisible("Axes")}
                    labels={labels}
                  />
                )}
              </>
            )}

            {/* {scoreType && hasData && (
              <ScoreSettings
                viz={viz}
                title="Value Settings"
                updateViz={updateViz}
                changeSettingsField={changeSettingsField}
                visible={visibleItems["ScoreData"]}
                setVisible={() => setVisible("ScoreData")}
              />
            )} */}

            {hasData && (
              <>
                {!scoreType && (
                  <TitleSettings //input-cursor-text    input cursor
                    viz={viz}
                    title="Titles" //"Chart / Axis Titles"
                    changeVizField={changeVizField}
                    changeSettingsField={changeSettingsField}
                    visible={visibleItems["Title"]}
                    setVisible={() => setVisible("Title")}
                    updateViz={updateViz}
                  ></TitleSettings>
                )}

                {!scoreType && viz.type !== TableType && (
                  <>
                    <LegendSettings // list-ul    map     menu-app-fill
                      viz={viz}
                      title="Legend"
                      changeSettingsField={changeSettingsField}
                      visible={visibleItems["Legend"]}
                      setVisible={() => setVisible("Legend")}
                      labels={labels}
                    ></LegendSettings>
                    <DataLabels // 2-circle-fill    superscript
                      viz={viz}
                      title="Data Labels"
                      labels={labels}
                      changeSettingsField={changeSettingsField}
                      changeVizField={changeVizField}
                      visible={visibleItems["Labels"]}
                      setVisible={() => setVisible("Labels")}
                    ></DataLabels>

                    <SortingSettings // sort-alpha-down    or     sort-down
                      viz={viz}
                      title="Sorting"
                      changeSettingsField={changeSettingsField}
                      labels={labels}
                      visible={visibleItems["DataSettings"]}
                      setVisible={() => setVisible("DataSettings")}
                    ></SortingSettings>
                  </>
                )}
              </>
            )}
            <TextBoxSettings // sort-alpha-down    or     sort-down
              viz={viz}
              title="Text Boxes"
              changeSettingsField={changeSettingsField}
              labels={labels}
              visible={visibleItems["TextBoxSettings"]}
              setVisible={() => setVisible("TextBoxSettings")}
            ></TextBoxSettings>
          </>
        )}
      </div>
    </>
  );
};

function ManualDataTable({ viz }) {
  const [data, setData] = useState(getData(viz.data));

  function getData(data) {
    let tempData = data;
    while (typeof tempData === "string") {
      tempData = JSON.parse(tempData);
    }

    return tempData;
  }

  return (
    <>
      <TableChart manual chartData={data} spreadsheetmode />
    </>
  );
}

function SurveyDataTables({
  viz,
  custom_fields,
  chartData,
  setChartData,
  filters,
}) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  function getUserConstraints() {
    return viz.designSettings?.dynamic
      ? viz.designSettings.userConstraints
      : undefined;
  }

  const fetchAnswers = useGetFilterableAnswers(
    combinedQs(viz).map((q) => q.id),
    filters,
    getUserConstraints(),
    setData,
    ""
  );

  const vizProjIds = combinedProjIds(viz);

  const getProjects = useFetchProjectsByIdsGql(
    {
      projectIds: vizProjIds,
    },
    viz.id
  );

  // useEffect(() => {
  //   fetchAnswers.refetch();
  //   getProjects.refetch();

  // }, [qIds, filters]);

  // useEffect(() => {
  //   if (loading) {
  //     setLoading(false);
  //   }
  // }, [loading]);

  return (
    <>
      {(fetchAnswers.isLoading ||
        getProjects.isLoading ||
        fetchAnswers.isRefetching ||
        getProjects.isRefetching) && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Loading></Loading>
        </div>
      )}
      {fetchAnswers.isSuccess &&
        getProjects.isSuccess &&
        !fetchAnswers.isRefetching &&
        !getProjects.isRefetching &&
        fetchAnswers.data?.answers?.length > 0 && (
          <Table
            answers={fetchAnswers.data?.answers}
            filters={filters}
            // onClose={closeTablePopup}
            viz={viz}
            projects={getProjects.data?.survey}
            // surveyTags={getSurveyTags.data.tags}
            canSeeContactInfo
            //canSeeContactInfo={currRole.data.role.canSeeContactInfo}
            // drill={drill}
            // titleStyle={getStyle()}
            custom_fields={custom_fields}
            // spreadsheetmode
            togglespreadsheet
            chartData={chartData}
            setChartData={setChartData}
            editMode
          />
        )}
    </>
  );
}
