// External
import React, { useEffect, useState } from "react";

// Internal
import { OrganizationRoutes } from "routes";
import styles from "./Organization.module.scss";
import { useParams } from "react-router-dom";
import { useGetOrgLogo } from "api/resources/organization/organization";
import { TabBar } from "components/layouts";

export const Organization = ({
  userRoles,
  organization,
  currentUser,
}) => {
  const { id } = useParams();

  return (
    <>
      <OrgPageContainer
        userRoles={userRoles}
        currentUser={currentUser}
        id={id}
        organization={organization}
      ></OrgPageContainer>
    </>
  );
};

function OrgPageContainer({ userRoles, id, currentUser}) {
  const menuItems = [
    {
      id: 0,
      name: "Organization Details",
      to: `/organization/${id}/org-details`,
      icon: "bi bi-card-text",
    },
    {
      id: 1,
      name: "Product",
      to: `/organization/${id}/billing-product`,
      icon: "bi bi-cash",
    },
    {
      id: 2,
      name: "Email Preferences",
      to: `/organization/${id}/email-preferences`,
      icon: "bi bi-envelope",
    },
    {
      id: 3,
      name: "Dashboards",
      to: `/organization/${id}/dashboards`,
      icon: "bi bi-speedometer2",
    },
    {
      id: 4,
      name: "AI & NLP",
      to: `/organization/${id}/nlp`,
      icon: "bi bi-lightbulb",
    },
    {
      id: 5,
      name: "Images",
      to: `/organization/${id}/org-images`,
      icon: "bi bi-images",
    },
  ];

  const [shownItems, setShownItems] = useState(menuItems);
  const getOrgLogo = useGetOrgLogo();

  useEffect(() => {
    let newItems = [];
    for (let item of menuItems) {
      if (item.id === 0) {
        newItems.push(item);
      } else if (item.id === 1 && (userRoles?.canViewOrgDetails || currentUser.isSuperAdmin)) {
        newItems.push(item);
      } else if (item.id === 2 && (userRoles?.canViewOrgDetails || currentUser.isSuperAdmin)) {
        newItems.push(item);
      } else if (item.id === 3 && (userRoles?.canViewOrgDetails || currentUser.isSuperAdmin)) {
        newItems.push(item);
      } else if (item.id === 4 && (userRoles?.canViewOrgDetails || currentUser.isSuperAdmin)) {
        newItems.push(item);
      }else if (item.id === 5 && (userRoles?.canViewOrgDetails || currentUser.isSuperAdmin)) {
        newItems.push(item);
      }
    }
    setShownItems(newItems);
  }, []);

  const [active, setActive] = useState(
    localStorage.getItem("activeorgtab")
      ? localStorage.getItem("activeorgtab")
      : 0
  );

  const handleActiveUpdate = (item) => {
    localStorage.setItem("activeorgtab", item);
    setActive(item);
  };

 
  return (
    <div
      className={styles.page}
    >
      {getOrgLogo.isSuccess && (
        <div className={styles.top}>
          <TabBar
            tabBarItems={shownItems}
            active={active}
            updateActive={handleActiveUpdate}
          />
        </div>
      )}

      <OrganizationRoutes/>
    </div>
  );
}
