import { combinedQs } from "pages/results/Charts/Visualization";
import styles from "./ComparisonFilter.module.scss";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";
import { useState } from "react";
import { SmallCheckbox } from "components/inputs/input_fields/SmallCheckbox/SmallCheckbox";

export default function ComparisonFitler({ viz, updateViz }) {
  const vizQs = combinedQs(viz);

  function hasMatrix() {
    for (let q of vizQs) {
      if (q.matrixQuestion) {
        return true;
      }
    }
    return false;
  }

  function hasScale() {
    for (let q of vizQs) {
      if (q.scaleQuestion) {
        return true;
      }
    }
    return false;
  }

  function hasChoice() {
    for (let q of vizQs) {
      if (q.choiceQuestion) {
        return true;
      }
    }
    return false;
  }

  function hasBuckets() {
    for (let q of vizQs) {
      if (q.textQuestion && q.hasBuckets && q.buckets) {
        return true;
      }
    }
    return false;
  }

  function getChoiceOptions() {
    let options = [];
    for (let q of vizQs) {
      if (q.choiceQuestion) {
        for (let choice of q.choiceQuestion.choices) {
          if (!options.includes(choice)) {
            options.push(choice);
          }
        }
      }
    }
    return options;
  }

  function getBuckets() {
    let buckets = [];
    for (let q of vizQs) {
      if (q.textQuestion && q.textQuestion.bucket) {
        for (let bucket of q.textQuestion.bucket) {
          if (!buckets.some((b) => b.id === bucket.id)) {
            buckets.push(bucket);
          }
        }
      }
    }

    return buckets;
  }

  function saveScaleFilter(filter) {
    let copy = { ...viz };

    if (!Object.keys(filter).length) {
      delete copy.designSettings.toLastFilter.scale;
    } else {
      copy.designSettings.toLastFilter.scale = filter;
    }

    updateViz(copy);
  }

  return (
    <div className={styles.container}>
      {!hasMatrix() && (
        <>
          {hasScale() && (
            <ScaleStuff
              viz={viz}
              filter={
                viz.designSettings.toLastFilter.scale
                  ? viz.designSettings.toLastFilter.scale
                  : {}
              }
              updateFilter={saveScaleFilter}
            />
          )}
          {hasChoice() && (
            <div className={styles.block}>
              <div className={styles.label}>Choice</div>
              <ToFromSelections
                viz={viz}
                updateViz={updateViz}
                filterName={"choiceFilters"}
                options={getChoiceOptions()}
                title={"Show"}
              />
            </div>
          )}

          {hasBuckets() && (
            <div className={styles.block}>
              <div className={styles.label}>Buckets</div>

              {getBuckets().map((b) => (
                <>
                  <ToFromSelections
                    viz={viz}
                    updateViz={updateViz}
                    filterName={b.name + "bucketFilters"}
                    options={b.properties}
                    title={b.name}
                  />
                </>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
}

function ScaleStuff({ viz, updateFilter, filter }) {
  const vizQs = combinedQs(viz);

  function edit(field, val) {
    if (val) {
      filter[field] = val;
    } else {
      delete filter[field];
    }

    updateFilter(filter);
  }

  return (
    <>
      {vizQs.some((q) => q.isScore) && (
        <>
          {/* <div className={styles.block}>
            <div className={styles.label}>NPS</div>

            <div className={styles.list}>
              <div className={styles.option}>
                <Checkbox />

                <StackedLabel
                  left="Promoter"
                  to={
                    <i className={`bi bi-arrow-down-right ${styles.red}`}></i>
                  }
                  right="Detractor"
                  down
                />
              </div>
              <div className={styles.option}>
                <Checkbox />

                <StackedLabel
                  left="Detractor"
                  to={
                    <i className={`bi bi-arrow-up-right ${styles.green}`}></i>
                  }
                  right="Promoter"
                  up
                />
              </div>
            </div>
          </div> */}
          <NPSStuff edit={edit} filter={filter} updateFilter={updateFilter} />
        </>
      )}
      <div className={styles.block}>
        <div className={styles.label}>Scale</div>

        <div className={styles.list}>
          <div className={styles.option}>
            <Checkbox
              checked={filter.higher}
              onChange={(e) => edit("higher", e.target.checked)}
            />
            <div className={styles.optionLabel}>
              Higher
              <i className={`bi bi-arrow-up-right ${styles.green}`}></i>
            </div>
          </div>
          {/* <div className={styles.tippingPoint}></div> */}
          <div className={styles.option}>
            <Checkbox
              checked={filter.lower}
              onChange={(e) => edit("lower", e.target.checked)}
            />
            <div className={styles.optionLabel}>
              Lower
              <i className={`bi bi-arrow-down-right ${styles.red}`}></i>
            </div>
          </div>

          <div className={styles.option}>
            <Checkbox
              checked={filter.same}
              onChange={(e) => edit("same", e.target.checked)}
            />
            <div className={styles.optionLabel}>
              Same
              <i className={`bi bi-arrows ${styles.yellow}`}></i>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function StackedLabel({ left, to, right, down, up }) {
  return (
    <div className={styles.stackedOptionLabel}>
      <div
        className={`${styles.optionLabel} ${down ? styles.top : styles.bottom}`}
      >
        {left}
      </div>
      <div className={`${styles.optionLabel} ${styles.middle}`}>{to}</div>
      <div
        className={`${styles.optionLabel} ${up ? styles.top : styles.bottom}`}
      >
        {right}
      </div>
    </div>
  );
}

function NPSStuff({ edit, filter, updateFilter }) {
  function onAnyChanges(val) {
    let all = [
      "promToPass",
      "promToDet",
      "passToProm",
      "passToDet",
      "detToPass",
      "detToProm",
    ];
    for (let one of all) {
      if (val) {
        filter[one] = true;
      } else {
        delete filter[one];
      }
    }
    if (val) {
      filter.npsChange = true;
    } else {
      delete filter.npsChange;
    }

    updateFilter(filter);
  }

  return (
    <div className={styles.block}>
      <div className={styles.label}>NPS</div>

      <div className={styles.list} style={{ gap: "10px" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className={styles.npsHelp}>
            <div>Last</div> <div>Current</div>
          </div>
          <div className={styles.anyChanges}>
            Any Change
            <SmallCheckbox checked={filter.npsChange} onChange={onAnyChanges} />
          </div>
          <div className={styles.option}>
            <div className={styles.stackedOptionLabel}>
              <div>Promoter</div>
              <div style={{ paddingTop: ".5em" }} className={styles.arrows}>
                <i className={`bi bi-arrow-down-right ${styles.orange}`}></i>
                <i className={`bi bi-arrow-down-right ${styles.red}`}></i>
              </div>
              <div style={{ paddingTop: "1.7em" }}>
                <div className={styles.npsOptionStack}>
                  <div className={styles.npsOption}>
                    Passive
                    <SmallCheckbox
                      checked={filter.promToPass}
                      onChange={(val) => edit("promToPass", val)}
                    />
                  </div>
                  <div className={styles.npsOption}>
                    Detractor
                    <SmallCheckbox
                      checked={filter.promToDet}
                      onChange={(val) => edit("promToDet", val)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.option}>
          <div className={styles.stackedOptionLabel}>
            <div style={{ display: "flex", alignItems: "center" }}>Passive</div>
            <div className={`${styles.arrows} ${styles.passiveArrows}`}>
              <i className={`bi bi-arrow-up-right ${styles.green}`}></i>
              <i className={`bi bi-arrow-down-right ${styles.red}`}></i>
            </div>

            <div className={styles.passiveOptions}>
              <div className={styles.npsOption}>
                Promoter
                <SmallCheckbox
                  checked={filter.passToProm}
                  onChange={(val) => edit("passToProm", val)}
                />
              </div>
              <div className={styles.npsOption}>
                Detractor
                <SmallCheckbox
                  checked={filter.passToDet}
                  onChange={(val) => edit("passToDet", val)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.option}>
          <div
            className={styles.stackedOptionLabel}
            style={{ alignItems: "flex-end" }}
          >
            <div>Detractor</div>
            <div
              style={{ paddingBottom: ".5em", gap: ".5em" }}
              className={styles.arrows}
            >
              <i className={`bi bi-arrow-up-right ${styles.green}`}></i>
              <i className={`bi bi-arrow-up-right ${styles.orange}`}></i>
            </div>
            <div style={{ paddingBottom: "1.7em" }}>
              <div className={styles.npsOptionStack}>
                <div className={styles.npsOption}>
                  Promoter
                  <SmallCheckbox
                    checked={filter.detToProm}
                    onChange={(val) => edit("detToProm", val)}
                  />
                </div>
                <div className={styles.npsOption}>
                  Passive
                  <SmallCheckbox
                    checked={filter.detToPass}
                    onChange={(val) => edit("detToPass", val)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ToFromSelections({ viz, updateViz, filterName, options, title }) {
  const [showNew, setShowNew] = useState(false);

  function create(toFrom, chosen) {
    if (chosen.length) {
      let copy = { ...viz };
      let first = { to: [], from: [] };
      first[toFrom] = chosen;
      if (showNew && copy.designSettings.toLastFilter[filterName]) {
        copy.designSettings.toLastFilter[filterName].push(first);
        setShowNew(false);
      } else {
        copy.designSettings.toLastFilter[filterName] = [first];
      }
      updateViz(copy);
    }
  }

  function edit(i, toFrom, chosen) {
    let copy = { ...viz };
    let filter = copy.designSettings.toLastFilter[filterName][i];
    filter[toFrom] = chosen;
    if (!filter.to.length && !filter.from.length) {
      copy.designSettings.toLastFilter[filterName].splice(i, 1);
      if (!copy.designSettings.toLastFilter[filterName].length) {
        delete copy.designSettings.toLastFilter[filterName];
      }
    }
    updateViz(copy);
  }

  return (
    <>
      <div className={styles.list} style={{ paddingLeft: "0px" }}>
        <div className={styles.optionLabel} style={{ paddingLeft: "10px" }}>
          {title}
        </div>

        <div className={styles.choiceFilters}>
          {viz.designSettings.toLastFilter[filterName] && (
            <>
              {viz.designSettings.toLastFilter[filterName].map((toFrom, i) => (
                <>
                  <div className={styles.option}>
                    <CombinedInput
                      label="Last"
                      value={toFrom.from}
                      select
                      noSearch
                      options={options}
                      selectMultiple
                      icon={"bi bi-chevron-compact-down"}
                      style={{ backgroundColor: "#efefef" }}
                      onChange={(chosen) => edit(i, "from", chosen)}
                    />{" "}
                    to{" "}
                    <CombinedInput
                      label="Current"
                      value={toFrom.to}
                      select
                      noSearch
                      options={options}
                      selectMultiple
                      icon={"bi bi-chevron-compact-down"}
                      style={{ backgroundColor: "#efefef" }}
                      onChange={(chosen) => edit(i, "to", chosen)}
                    />
                  </div>
                  {i > 0 &&
                    i <
                      viz.designSettings.toLastFilter[filterName].length -
                        1 && <div className={styles.or}>Or</div>}
                </>
              ))}

              {!showNew && (
                <div className={styles.addOr}>
                  <div
                    className={styles.addOrButton}
                    onClick={() => setShowNew(true)}
                  >
                    + Or
                  </div>
                </div>
              )}
              {showNew && <div className={styles.or}>Or</div>}
            </>
          )}

          {(!viz.designSettings.toLastFilter[filterName] || showNew) && (
            <div className={styles.option}>
              <CombinedInput
                label="Last"
                select
                noSearch
                options={options}
                selectMultiple
                icon={"bi bi-chevron-compact-down"}
                style={{ backgroundColor: "#efefef" }}
                onChange={(chosen) => create("from", chosen)} // Fix Blur
              />{" "}
              to{" "}
              <CombinedInput
                label="Current"
                select
                noSearch
                options={options}
                selectMultiple
                icon={"bi bi-chevron-compact-down"}
                style={{ backgroundColor: "#efefef" }}
                onChange={(chosen) => create("to", chosen)} // Fix Blur
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
