/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/

import { useFetchMetrics, useGetOldQMetric } from "api/resources/account/widget";
import styles from "./MetricChart.module.scss";
import { useEffect, useState } from "react";

export default function MetricChart({ viz, idAddOn, thumbnail, filters, update, saveData }) {

  useEffect(() => {
    if (update && saveData) {
      saveData("metric");
    }
  }, []);

  function getQueryObj() {
    let query = {
      projectIds: viz.projectIdsArray,
      filters: filters ? filters : "{}",
    };
    for (let m of viz.designSettings.metrics) {
      if (!m.question) {
        query[m.code] = true;
      }
    }
    if (viz.designSettings?.dynamic) {
      query.userConstraints = {
        constraints: viz.designSettings?.userConstraints,
      };
    }
    return query;
  }

  const [query, setQuery] = useState(getQueryObj());

  const getMetrics = useFetchMetrics(query, viz.id);

  useEffect(() => {
    setQuery(getQueryObj());
  }, [
    viz.projectIdsArray,
    viz.designSettings.metrics,
    filters,
    viz.designSettings?.userConstraints,
  ]);

  useEffect(() => {
    getMetrics.refetch();
  }, [query]);

  function getHeight() {
    if (thumbnail) {
      return "100%";
    }
    let title = document.getElementById("title for " + viz.id + idAddOn);
    let metricOuterBox = document.getElementById(
      viz.id + "metricOuterBox" + idAddOn
    );
    let metricBox = document.getElementById(viz.id + "metricBox" + idAddOn);
    if (metricOuterBox && title && metricBox) {
      let gap = 5;
      let r = metricOuterBox.clientHeight - (title.clientHeight + gap);
      return r + "px";
    } else {
      return "100%";
    }
  }

  return (
    <div id={viz.id + "metricOuterBox" + idAddOn} className={styles.outerBox}>
      <div
        className={styles.smallMetricsContainer}
        style={{
          justifyContent: viz.designSettings.metricAlignment,
          // ...getStyle(),
          height: getHeight(),
        }}
      >
        <div
          className={styles.metricsBox}
          id={viz.id + "metricBox" + idAddOn}
          style={{ gap: thumbnail ? "2px" : "" }}
        >
          {viz.designSettings.metrics.map((m, i) => (
            <div
              className={styles.smallMetric}
              key={i}
              style={{
                flexDirection: viz.designSettings.metricOrder,
                gap: thumbnail ? "2px" : "",
              }}
            >
              <div
                className={styles.icon}
                style={{
                  visibility: m.showIcon ? "visible" : "hidden",
                  fontSize: thumbnail
                    ? Math.floor(viz.designSettings.mTitleSize / 4)
                    : "",
                }}
              >
                <i className={`bi bi-${m.icon}`}></i>
              </div>
              <div
                className={styles.text}
                style={{
                  textAlign:
                    viz.designSettings.metricOrder === "row-reverse"
                      ? "right"
                      : "undefined",
                  gap: thumbnail ? "0px" : "",
                }}
              >
                <div
                  className={styles.mTitle}
                  style={
                    thumbnail
                      ? {
                          fontSize:
                            Math.floor(viz.designSettings.mTitleSize / 5) +
                            "pt",
                          lineHeight:
                            Math.floor(viz.designSettings.mTitleSize / 5) +
                            "pt",
                        }
                      : {
                          fontSize: viz.designSettings.mTitleSize + "pt",
                          lineHeight: viz.designSettings.mTitleSize + "pt",
                        }
                  }
                >
                  {m.title}
                </div>
                <div
                  className={styles.mmetric}
                  style={
                    thumbnail
                      ? {
                          fontSize:
                            Math.floor(viz.designSettings.mValueSize / 5) +
                            "pt",
                          lineHeight:
                            Math.floor(viz.designSettings.mValueSize / 5) +
                            "pt",
                        }
                      : {
                          fontSize: viz.designSettings.mValueSize + "pt",
                          lineHeight: viz.designSettings.mValueSize + "pt",
                        }
                  }
                >
                  {!m.question && getMetrics.isSuccess && (
                    <>
                      {getMetrics.data.getMetrics[m.code] !== undefined
                        ? getMetrics.data.getMetrics[m.code]
                        : m.metric}
                    </>
                  )}
                  {m?.question && (
                    <QuestionMetric metric={m} filters={filters} viz={viz} />
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export function QuestionMetric({ metric, filters, viz }) {
  function init() {
    let it = {
      questionIds: metric.question,
      filters: filters ? filters : "{}",
    };
    if (viz?.designSettings?.dynamic) {
      it.userConstraints = {
        constraints: viz.designSettings?.userConstraints,
      };
    }

    it[metric.code] = true;
    return it;
  }

  const getQMetric = useGetOldQMetric(init());

  return (
    <>
      {getQMetric.isSuccess && getQMetric.data.getQMetric !== null
        ? getQMetric.data.getQMetric
        : ""}
    </>
  );
}
