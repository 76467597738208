import { NumberInput } from "components/inputs/input_fields/NumberInput/NumberInput";
import styles from "./ScaleSettings.module.scss";
import { TextFieldSimple } from "components/inputs";

export function ScaleSettings({
  question,
  saveQuestion,
  dependents,
  setUpLogicResolver,
}) {
  function clearsDependentsMin(min) {
    if (dependents) {
      for (let dependent of dependents.dependencies) {
        if (dependent?.keep) {
          for (let digit of dependent.keep) {
            if (min > digit) {
              setUpLogicResolver(
                "raise the min value on ",
                "edit",
                question,
                () => {
                  question.scaleQuestion.min = min;
                  saveQuestion(question);
                }
              );
              return false;
            }
          }
        }
      }
    }

    return true;
  }

  function clearsDependentsMax(max) {
    if (dependents) {
      for (let dependent of dependents.dependencies) {
        if (dependent?.keep) {
          for (let digit of dependent.keep) {
            if (max < digit) {
              setUpLogicResolver(
                "lower the max value on ",
                "edit",
                question,
                () => {
                  question.scaleQuestion.max = max;
                  saveQuestion(question);
                }
              );
              return false;
            }
          }
        }
      }
    }

    return true;
  }

  const changeMax = (max) => {
    if (isNaN(max)) {
      return;
    }
    if (clearsDependentsMax(max)) {
      question.scaleQuestion.max = max;
      saveQuestion(question);
    } else {
      let curr = question.scaleQuestion.max;
      question.scaleQuestion.max = max;
      saveQuestion(question);
      setTimeout(() => {
        // To get it to go back in NumberInput
        question.scaleQuestion.max = curr;
        saveQuestion(question);
      }, 500);
    }
  };

  const changeMin = (min) => {
    if (isNaN(min)) {
      return;
    }
    if (clearsDependentsMin(min)) {
      question.scaleQuestion.min = min;
      saveQuestion(question);
    } else {
      let curr = question.scaleQuestion.min;
      question.scaleQuestion.min = min;
      saveQuestion(question);
      setTimeout(() => {
        // To get it to go back in NumberInput
        question.scaleQuestion.min = curr;
        saveQuestion(question);
      }, 500);
    }
  };

  const changeStep = (step) => {
    if (isNaN(step)) {
      return;
    }
    question.scaleQuestion.step = step;
    saveQuestion(question);
  };

  function changeMinDesc(val) {
    question.scaleQuestion.minDescription = val;
    saveQuestion(question);
  }

  function changeMaxDesc(val) {
    question.scaleQuestion.maxDescription = val;
    saveQuestion(question);
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: ".5em",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className={styles.label}>Min</div>
          <NumberInput
            startNumber={question.scaleQuestion?.min}
            handleNumberChange={changeMin}
            min={0}
            max={question.scaleQuestion?.max - 1}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className={styles.label}>Max</div>
          <NumberInput
            startNumber={question.scaleQuestion?.max}
            handleNumberChange={changeMax}
            min={question.scaleQuestion?.min + 1}
            max={100}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div className={styles.label}>Step</div>
          <NumberInput
            startNumber={question.scaleQuestion?.step}
            handleNumberChange={changeStep}
            min={1}
            max={50}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: ".5em",
        }}
      >
        <div className={styles.text}>
          <TextFieldSimple
            label="Min Description"
            value={question.scaleQuestion.minDescription}
            onChange={changeMinDesc}
          />
        </div>
        <div className={styles.text}>
          <TextFieldSimple
            label="Max Description"
            value={question.scaleQuestion.maxDescription}
            onChange={changeMaxDesc}
          />
        </div>
      </div>
    </>
  );
}
