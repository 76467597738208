import { Label } from "components/layouts/Label/Label";
import { ProgressBar } from "../ProgressBar/ProgressBar";
import styles from "./EditableProgressBar.module.scss";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import { ColorPicker } from "components/inputs/input_fields/ColorPicker/ColorPicker";
import { useRef } from "react";

export default function EditableProgressBar({
  settings,
  setSettings,
  numQs,
  numAnswered,
  active,
  setActive,
  close,
}) {
  function changeSetting(setting, value) {
    let tempSettings = { ...settings };
    tempSettings[setting] = value;
    setSettings(tempSettings);
  }

  function checkForClickOutside(e) {
    let container = document.getElementById("ProgBar");
    if (container && !container.contains(e.target)) {
      let root = document.getElementById("root");
      if (root && !root.contains(e.target)) {
        // It's a color picker - or some modal like it
        return;
      }

      document.removeEventListener("click", checkForClickOutside, true);
      close();
    }
  }

  function onClick(e) {
    document.addEventListener("click", checkForClickOutside, true);
    setActive("ProgBar");
  }

  return (
    <div
      className={`${styles.progBar} ${
        active ? styles.progBarActive : styles.progBarInactive
      }`}
      style={{
        backgroundColor: settings.backgroundColor,
      }}
      onClick={active ? undefined : onClick}
      id="ProgBar"
    >
      {!active && (
        <ProgressBar
          settings={settings}
          numQuestions={numQs}
          numAnswered={numAnswered}
        ></ProgressBar>
      )}

      {active && (
        <div className={styles.activeBarHolder}>
          <ProgressBar
            settings={settings}
            numQuestions={numQs}
            numAnswered={numAnswered}
          ></ProgressBar>
        </div>
      )}

      {active && (
        <div className={styles.progBarSettings}>
          <div className={styles.progSetting}>
            <ToggleSwitch
              startChecked={settings.showProgBar}
              handleCheck={(val) => changeSetting("showProgBar", val)}
            ></ToggleSwitch>
            <Label>Show Progress Bar</Label>
          </div>
          <div className={styles.progSetting} style={{ paddingLeft: "5px" }}>
            <div className={styles.colorContainer}>
              <ColorPicker
                onChange={(color) => changeSetting("progBarColor", color)}
                defaultColor={
                  settings.progBarColor
                    ? settings.progBarColor
                    : settings.baseColor
                }
              />
            </div>
            <Label style={{ paddingLeft: "2.1em" }}>Bar Fill Color</Label>
          </div>
          <div className={styles.progSetting} style={{ paddingLeft: "5px" }}>
            <div className={styles.colorContainer}>
              <ColorPicker
                onChange={(color) => changeSetting("progBarEmptyColor", color)}
                defaultColor={
                  settings.progBarEmptyColor
                    ? settings.progBarEmptyColor
                    : "#d8d9d9" // gray60
                }
              />
            </div>
            <Label style={{ paddingLeft: "2.1em" }}>Bar Empty Color</Label>
          </div>
          <div className={styles.progSetting}>
            <ToggleSwitch
              startChecked={settings.showProgQs}
              handleCheck={(val) => changeSetting("showProgQs", val)}
            ></ToggleSwitch>
            <Label>Show Question Progress</Label>
          </div>

          {settings.showProgQs && (
            <div className={styles.progSetting} style={{ paddingLeft: "5px" }}>
              <div className={styles.colorContainer}>
                <ColorPicker
                  onChange={(color) => changeSetting("progQsColor", color)}
                  defaultColor={
                    settings.progQsColor ? settings.progQsColor : "#c0c0c0"
                  }
                  colors={["#c0c0c0"]}
                />
              </div>
              <Label style={{ paddingLeft: "2.1em" }}>Font Color</Label>
            </div>
          )}
          <div className={styles.progSetting}>
            <ToggleSwitch
              startChecked={settings?.progBarSticky}
              handleCheck={(val) => changeSetting("progBarSticky", val)}
            ></ToggleSwitch>
            <Label>Sticky Progress Bar</Label>
          </div>
        </div>
      )}
    </div>
  );
}
