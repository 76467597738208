import styles from "./Tiles.module.scss";
import Stat from "components/tables/BasicTable/SurveyWidgets/Stat";

export default function FakeTile({ settings }) {
  return (
    <>
      {settings?.survey && <FakeSurveyTile settings={settings} />}
      {settings?.report && <FakeReportTile settings={settings} />}
    </>
  );
}

function FakeSurveyTile({ settings }) {
  const survey = settings.survey;

  return (
    <div className={styles.tile} style={{ cursor: "grab" }}>
      <div className={styles.surveyHeader}>
        <div className={styles.startedText}>
          <span>Started: Sep 15, 2023</span>
          <span>
            {" "}
            Status:{" "}
            {survey?.status === "Open" && (
              <span className={styles.isopen}>{survey?.status}</span>
            )}
            {survey?.status === "Closed" && (
              <span className={styles.isclosed}>{survey?.status}</span>
            )}
            {survey?.status === "Draft" && (
              <span className={styles.isdraft}>{survey?.status}</span>
            )}
          </span>
        </div>{" "}
        <span
          className={`${styles.owner} 
             ${styles.isorg}
          `}
        >
          <i className={`${"bi bi-buildings"}`}></i> Survey{" "}
          {survey?.type &&
            survey?.type.charAt(0).toUpperCase() + survey?.type.slice(1)}{" "}
        </span>
      </div>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{survey?.name}</div>
        <div className={styles.description}>{survey?.description}</div>
      </div>
      <div className={styles.statsContainer}>
        {survey?.status != "Draft" && (
          <Stat title={"Responses"} value={survey?.responses} />
        )}
        {survey?.numQs && <Stat title={"Questions"} value={survey?.numQs} />}
      </div>
    </div>
  );
}

function FakeReportTile({ settings }) {
  const report = settings.report;

  return (
    <div className={styles.tile} style={{ cursor: "grab" }}>
      <div className={styles.reportIcon}>
        <span className={`bi bi-clipboard-data`}></span>
      </div>
      <div className={styles.reportHeader}>
        <div className={styles.startedText}>Created: Sep 8, 2023</div>
        <div className={styles.icons}>
          <span className={`${styles.status} ${styles.isResults}`}>
            {report.type}
          </span>
        </div>
      </div>
      <div className={styles.titleContainer}>
        <div className={styles.title}>{report?.name}</div>
        <div className={styles.description}>{report?.content}</div>
      </div>
      <div className={styles.bottomContainer}>
        <div>
          <span>Associated Survey</span>
          <div>{report?.project ? report?.project?.name : "None"}</div>
        </div>
        <div>
          <Stat
            title={report?.numPages > 1 ? "Pages" : "Page"}
            value={report?.numPages > 1 ? report?.numPages : 1}
            small
          />
          <Stat
            title={
              report?.numVizualizations > 1 ? "Visualizations" : "Visualization"
            }
            value={
              report?.numVizualizations > 1 ? report?.numVizualizations : 1
            }
            small
          />
        </div>
      </div>
    </div>
  );
}
