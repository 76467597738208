import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styles from "./Usubscribe.module.scss";
import reactionLogo from "assets/images/reactionlogolight.png";
import {
  useFetchContact,
  useUpdateContactGql,
} from "api/resources/contacts/contacts";
import { PageContent } from "components/layouts/Page/PageContent";
import { Page } from "components/layouts/Page/Page";
import { HeaderContainer } from "components/layouts/Header/HeaderContainer";
import Button from "components/Button/Button";
import {
  useFetchOrganizationSideMenu,
  useGetOrgLogoUnique,
} from "api/resources/organization/organization";
import { useUnsubFromDelivery } from "api/resources/projects/deliveries";

function Unsubscribe() {
  const { id } = useParams();

  const getContact = useFetchContact(id);

  return (
    <div className={styles.page}>
      {getContact.isSuccess && (
        <OrgUnsubPage contact={getContact.data.contact} id={id} />
      )}
    </div>
  );
}

export default Unsubscribe;

function OrgUnsubPage({ contact, id }) {
  const [check, setCheck] = useState(contact.unsubscribed);

  const { deliveryId } = useParams();
  
  useEffect(() => {
    if (!check) {
      unsubme(true);
    }
  }, []);

  const getOrg = useFetchOrganizationSideMenu(contact.organizationId);
  const updateContact = useUpdateContactGql();
  const unsubDelivery = useUnsubFromDelivery();

  function unsubme(value) {
    updateContact.mutate(
      {
        data: {
          unsubscribed: value,
          unsubscribedAt: value ? new Date() : null,
        },
        id: id,
      },
      {
        onSuccess: () => {
          value ? setCheck(true) : setCheck(false);
        },
      }
    );

    if (deliveryId) {
      unsubDelivery.mutate(
        {
          id: deliveryId,
          val: value,
        },
        {
          onSuccess: (data) => {
            console.log('unsubbed', data);
          },
        }
      );
    }
  }

  return (
    <Page style={{ height: "100%", padding: ".5em" }}>
      <HeaderContainer>
        {" "}
        <div className={styles.imageContainer}>
          <GetLogo orgId={contact.organizationId}></GetLogo>
        </div>
      </HeaderContainer>
      <PageContent style={{ padding: "2em" }}>
        <div className={styles.pageContent}>
          {!check && (
            <>
              <>
                Do you wan't to unsubscribe <a>{contact.email}</a> from
                receiving customer feedback and research requests from{" "}
                {getOrg.isSuccess && getOrg.data.organization.name}.
              </>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "1em",
                }}
              >
                <Button onClick={() => unsubme(true)} height={40} shadow>
                  Yes
                </Button>
              </div>
            </>
          )}
          {check && (
            <>
              You have unsubscribed <a>{contact.email}</a> from receiving
              customer feedback and research requests from{" "}
              {getOrg.isSuccess && getOrg.data.organization.name}.
            </>
          )}
          {check && (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "1em",
                }}
              >
                If this was unintentional click below to resubscribe&nbsp;
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "1em",
                }}
              >
                <Button shadow onClick={() => unsubme(false)} height={40}>
                  Resubscribe
                </Button>
              </div>
            </>
          )}
        </div>
      </PageContent>
    </Page>
  );
}

function GetLogo({ orgId }) {
  const getLogo = useGetOrgLogoUnique(orgId);

  return (
    <>
      {" "}
      {getLogo.isSuccess && (
        <img maxHeight={60} src={getLogo.data.getLogoLinkForOrg} />
      )}
    </>
  );
}
