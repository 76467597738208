import AddChart from "pages/results/Charts/AddChart";
import styles from "./AddChartToDash.module.scss";
import { useState } from "react";
import Modal from "components/ReactModal/ReactModal";

export default function AddChartToDash({ onSave, onClose, custom_fields }) {
  const [type, setType] = useState("");

  return (
    <>
      {!type && (
        <Modal
          show={true}
          modalStyle={{ borderRadius: "1em" }}
          onClose={onClose}
        >
          <div className={styles.chartTypes}>
            <div className={styles.chartType} onClick={() => setType("chart")}>
              Chart <i className="bi bi-bar-chart-fill"></i>
            </div>
            <div className={styles.chartType} onClick={() => setType("table")}>
              Table <i className="bi bi-layout-text-window-reverse"></i>
            </div>
            <div className={styles.chartType} onClick={() => setType("metric")}>
              Metric <i className="bi bi-card-text"></i>
            </div>
          </div>
        </Modal>
      )}

      {type && (
        <AddChart
          onSave={onSave}
          onClose={onClose}
          custom_fields={custom_fields}
          type={type}
        />
      )}
    </>
  );
}
