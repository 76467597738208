import { forEach } from "./ArrayFunctions";

/**
 *
 * @param {*} obj the object to traverse
 * @param {*} whatToDo a callback function which accepts two parameters, key and value.
 */
export function searchObject(obj, whatToDo) {
  for (let key in obj) {
    whatToDo(key, obj[key]);
  }
}

/**
 *
 * @param {*} contact the contact that needs its fields parsed out
 */
export function parseCustomField(contact) {
  if (contact.customField) {
    let fields = contact.customField;
    while (typeof fields === "string") {
      fields = JSON.parse(fields);
    }
    return { ...contact, ...fields };
  }
}

/**
 *
 * @param {*} contact the contact that needs its fields parsed out
 */
export function parseCustomFields(contactArray) {
  if (contactArray && contactArray.length > 0) {
    let temp = [...contactArray];
    for (let i = 0; i < temp.length; i++) {
      temp[i] = parseCustomField(temp[i]);
    }
    return temp;
  } else {
    return contactArray;
  }
}

export function lowerize(obj) {
  if (obj) {
    let newobj = {};
    Object.keys(obj).map((key) => {
      if (
        key.toLowerCase().includes("firstname") ||
        key.toLowerCase().includes("lastname")
      ) {
        let item = key[0].toLowerCase() + key.slice(1);
        newobj[item] = obj[key];
      } else {
        let item = key.toLowerCase();
        newobj[item] = obj[key];
      }
    });
    return newobj;
  } else {
    return obj;
  }
}
