import styles from "./NotifWidget.module.scss";
import user from "assets/images/blank-profile-picture.png";
import uparrow from "assets/images/UpArrow.png";
import reactionlogo from "assets/images/ReactionCircleLogo.png";

/**
 * A component that renders a Pop-out box to display recent notifications
 * @param {function} setShowingNotif used to handle clicking outside of the popout to close it
 * @returns {React.ReactElement} a personalized Notification Popout
 */
export default function FakeNotifWidget({ settings }) {
  // const fetchUnreadNotifications = useFetchUnreadNotifications();
  const notifications = [
    {
      body: "Your Data import has been finished. Go check it out!",
      header: "Data Import",
      important: true,
      link: null,
      read: false,
      sender: "Data Uploader",
      senderTitle: "Internal",
      sent: "10:05 am",
      title: "Your Data import has been finished. Go check it out!",
      type: "Upload",
    },
    {
      body: "Your Survey has been successfully created!",
      header: "Survey Created",
      important: true,
      link: null,
      read: false,
      sender: "Survey Creation",
      senderTitle: "Internal",
      sent: "May 9",
      title: "Your Survey has been successfully created!",
      type: "Survey Created",
    },
    {
      body: "Your Survey has been successfully sent out!",
      header: "Survey Sent",
      important: true,
      link: null,
      read: false,
      sender: "Survey Sender",
      senderTitle: "Internal",
      sent: "May 10",
      title: "Your Survey has been successfully sent out!",
      type: "Survey Sending",
    },
    {
      body: "Someone shared a report with you! Check it out!",
      header: "Report Shared",
      important: false,
      link: null,
      read: false,
      sender: "John Doe",
      senderTitle: "Internal",
      sent: "May 1",
      title: "Your Data import has been finished. Go check it out!",
      type: "Report Shared",
    },

    {
      body: "Your Survey has been successfully sent out!",
      header: "Survey Sent",
      important: true,
      link: null,
      read: true,
      sender: "Survey Sender",
      senderTitle: "Internal",
      sent: "9:22 am",
      title: "Your Survey has been successfully sent out!",
      type: "Survey Sending",
    },

    {
      body: "Your Survey has been successfully created!",
      header: "Survey Created",
      important: true,
      link: null,
      read: true,
      sender: "Survey Creation",
      senderTitle: "Internal",
      sent: "3:18 pm",
      title: "Your Survey has been successfully created!",
      type: "Survey Created",
    },

    {
      body: "Someone shared a report with you! Check it out!",
      header: "Report Shared",
      important: false,
      link: null,
      read: true,
      sender: "Jane Doe",
      senderTitle: "Internal",
      sent: "April 27",
      title: "Your Data import has been finished. Go check it out!",
      type: "Report Shared",
    },
  ];

  function getTodays() {
    return settings.unreadOnly
      ? [notifications[0]]
      : [notifications[0], notifications[4]];
  }

  function getYesterdays() {
    return settings.unreadOnly ? [] : [notifications[5]];
  }

  function getInTheWeek() {
    return notifications.slice(1, 3);
  }

  function getOlder() {
    return settings.unreadOnly
      ? [notifications[3]]
      : [notifications[3], notifications[6]];
  }

  return (
    <div className={styles.container}>
      <div className={styles.notificationsHeader}>
        {settings?.unreadOnly ? "Unread Notifications" : "Notifications"}
      </div>

      <div
        className={`${styles.links} ${styles.markAll}`}
        style={{ cursor: "grab" }}
      >
        Mark all as read
      </div>

      <div className={styles.wordDivider} style={{ marginTop: "0" }}>
        Today
      </div>
      <div className={styles.notifBlock}>
        {getTodays().map((notif) => (
          <Notif notif={notif} />
        ))}
        {getTodays().length === 0 && <div className={styles.none}>None</div>}
      </div>

      <div className={styles.wordDivider}>Yesterday</div>
      <div className={styles.notifBlock}>
        {getYesterdays().map((notif) => (
          <Notif notif={notif} />
        ))}
        {getYesterdays().length === 0 && (
          <div className={styles.none}>None</div>
        )}
      </div>

      <div className={styles.wordDivider}>Last 7 Days</div>
      <div className={styles.notifBlock}>
        {getInTheWeek().map((notif) => (
          <Notif notif={notif} />
        ))}
        {getInTheWeek().length === 0 && <div className={styles.none}>None</div>}
      </div>

      <div className={styles.wordDivider}>Older</div>
      <div className={styles.notifBlock}>
        {getOlder().map((notif) => (
          <Notif notif={notif} />
        ))}
        {getOlder().length === 0 && <div className={styles.none}>None</div>}
      </div>

      <div className={`${styles.viewAll} ${styles.links}`}>
        View all notifications
      </div>
    </div>
  );
}

function Notif({ notif }) {
  function getType(notif) {
    if (notif.type === "Upload") {
      return uparrow;
    } else if (notif.type === "Survey Created") {
      return reactionlogo;
    } else {
      return user;
    }
  }

  return (
    <div className={styles.notificationContainer} style={{ cursor: "grab" }}>
      {!notif.read && <div className={styles.notread}></div>}
      <img
        src={getType(notif)}
        className={styles.avatar}
        alt="user profile image"
        style={{ pointerEvents: "none" }}
      />
      <div className={styles.notification}>
        <div className={styles.notifTitle}>
          <div className={styles.header}>{notif.senderTitle}</div>
          <div className={styles.time}>{notif.sent}</div>
        </div>
        <div className={styles.notifHeader}>{notif.header}</div>
        <div className={styles.notifBody}>{notif.body}</div>
      </div>
    </div>
  );
}
