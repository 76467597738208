import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ApolloClient, ApolloProvider, InMemoryCache, useSubscription } from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
const wsLink = new GraphQLWsLink(createClient({
  url: process.env.REACT_APP_WS_KEY,
}));
const apolloClient = new ApolloClient({
  link: wsLink ,
  cache: new InMemoryCache(), 
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(

  <React.StrictMode>
      <ApolloProvider client={apolloClient}>
    <App />
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
