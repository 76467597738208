import {
  useCreateBucket,
  useDeleteBucket,
  useFetchBuckets,
  useUpdateBucket,
} from "api/resources/organization/buckets";
import styles from "./OrgBuckets.module.scss";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";
import { useState } from "react";
import Modal from "components/ReactModal/ReactModal";
import Button from "components/Button/Button";
import TextEditorPlain from "components/inputs/input_fields/TextEditorPlain/TextEditorPlain";
import { Loading } from "components/Loading/Loading";

export default function OrgBuckets({}) {
  const fetchBuckets = useFetchBuckets();
  const updateBucket = useUpdateBucket();
  const createBucket = useCreateBucket();
  const deleteBucket = useDeleteBucket();

  const [editBucket, setEditBucket] = useState();
  const [createNew, setCreateNew] = useState();

  function shortList(bucket) {
    let string = "";
    for (let prop of bucket.properties) {
      if (string) {
        string += ", ";
      }
      string += prop;
    }
    return string;
  }

  function saveBucket(bucket) {
    updateBucket.mutate(
      {
        data: {
          name: bucket.name,
          properties: bucket.properties,
        },
        id: editBucket.id,
      },
      {
        onSuccess: (data) => {
          console.log("updated bucket!");
          fetchBuckets.refetch();
        },
      }
    );
    setEditBucket(null);
  }

  function buildBucket(bucket) {
    createBucket.mutate(
      {
        data: {
          name: bucket.name,
          properties: bucket.properties,
        },
      },
      {
        onSuccess: (data) => {
          console.log("created bucket!");
          fetchBuckets.refetch();
        },
      }
    );
    setCreateNew(false);
  }

  function setUpNew() {
    setCreateNew(true);
    setTimeout(() => {
      let input = document.getElementById("name input");
      if (input) {
        input.focus();
      }
    }, 400);
  }

  function onDelete() {
    if (
      window.confirm(
        `Are you sure you want to delete "${editBucket.name}"? This will affect future bucketing`
      )
    ) {
      deleteBucket.mutate(
        {
          id: editBucket.id,
        },
        {
          onSuccess: (data) => {
            console.log("bucket deleted!");
            fetchBuckets.refetch();
          },
        }
      );
      setEditBucket(null);
    }
  }

  return (
    <div className={styles.container}>
      {fetchBuckets.isLoading && <Loading></Loading>}
      {fetchBuckets.isSuccess &&
        fetchBuckets.data.orgBuckets.map((b) => (
          <CombinedInput
            key={b.id}
            value={<div className={styles.props}>{shortList(b)}</div>}
            disableInputOnly
            label={b.name}
            icon={"bi-pencil"}
            onIconClick={() => setEditBucket(b)}
            style={{ backgroundColor: "white" }}
            shadow
          />
        ))}

      <div className={styles.addBucket} onClick={setUpNew}>
        + <span className={styles.link}>Bucket</span>
      </div>

      {editBucket && (
        <EditBucket
          initBucket={editBucket}
          onClose={() => setEditBucket(null)}
          onDone={saveBucket}
          words={"Save"}
          onDelete={onDelete}
        />
      )}

      {createNew && (
        <EditBucket
          initBucket={{ name: "", properties: [] }}
          onClose={() => setCreateNew(false)}
          onDone={buildBucket}
          words={"Create"}
        />
      )}
    </div>
  );
}

export function EditBucket({ initBucket, onClose, onDone, words, onDelete }) {
  const [bucket, setBucket] = useState({
    name: initBucket.name,
    properties: [...initBucket.properties],
  });

  const [highlight, setHighlight] = useState("");

  function editProp(i, val) {
    let props = [...bucket.properties];
    if (val) {
      props[i] = val;
    } else {
      props.splice(i, 1);
    }

    setBucket((old) => {
      return { ...old, properties: props };
    });
  }

  function removeProp(i) {
    let props = [...bucket.properties];
    props.splice(i, 1);
    setBucket((old) => {
      return { ...old, properties: props };
    });
  }

  function addProp(i) {
    let props = [...bucket.properties];
    props.splice(i, 0, "");
    setBucket((old) => {
      return { ...old, properties: props };
    });

    setTimeout(() => {
      let input = document.getElementById("prop num " + i);
      if (input) {
        input.focus();
      }
    }, 100);
  }

  function saveNew(val) {
    if (val) {
      editProp(0, val);
    }
  }

  function onEnter(i, val) {
    if (val) {
      addProp(i + 1);
    }
  }

  function onBrandNewEnter(e, val) {
    if (val) {
      setBucket((old) => {
        return { ...old, properties: [val, ""] };
      });

      setTimeout(() => {
        let input = document.getElementById("prop num 1");
        if (input) {
          input.focus();
        }
      }, 100);
    } else {
      e.target.blur();
    }
  }

  function onDeleteEmpty(e, i) {
    e.target.blur();
    setTimeout(() => {
      removeProp(i);
    }, 300);
  }

  return (
    <Modal
      show={true}
      onClose={onClose}
      modalStyle={{
        borderRadius: ".5rem",
        padding: "2rem",
      }}
      dark
    >
      <div className={styles.bucketEditor}>
        <div className={styles.editName}>
          <div className={styles.entry} style={{ width: "90%" }}>
            Name:
            <input
              type={"text"}
              onChange={(e) =>
                setBucket((old) => {
                  return { ...old, name: e.target.value };
                })
              }
              // onBlur={handleFocusOut}
              className={styles.nameInput}
              value={bucket.name}
              id="name input"
            ></input>
          </div>
          <div className={styles.description}>
            Make it accurately descriptive*
          </div>
        </div>

        <div className={styles.editProperties}>
          <div className={styles.propsHeader}>
            Buckets:{" "}
            <div className={styles.bucketIcons}>
              {/* <div className={styles.tiltedBucket}>
                <i className="bi bi-bucket-fill"></i>
              </div> */}
              <i className="bi bi-bucket-fill" style={{ zIndex: "10" }}></i>
            </div>
          </div>
          {bucket.properties.length > 0 && (
            <>
              {bucket.properties.map((p, i) => (
                <div className={styles.prop}>
                  <TextEditorPlain
                    text={p}
                    onSave={(val) => editProp(i, val)}
                    onEnter={(e, val) => onEnter(i, val)}
                    editable
                    extraClass={styles.propEntry}
                    style={
                      highlight === p
                        ? { backgroundColor: "#F9F9F9" }
                        : undefined
                    }
                    onDeleteEmpty={(e) => onDeleteEmpty(e, i)}
                    id={"prop num " + i}
                  />
                  <div
                    className={styles.removeProp}
                    onMouseOver={() => setHighlight(p)}
                    onMouseLeave={() => setHighlight("")}
                    onClick={() => removeProp(i)}
                  >
                    <i className="bi bi-dash-circle"></i>
                  </div>
                </div>
              ))}
            </>
          )}

          {!bucket.properties.length && (
            <TextEditorPlain
              text={""}
              onSave={saveNew}
              // onChange={(val) => (first.current = val)}
              editable
              extraClass={styles.propEntry}
              // placeholder={"Enter Bucket"}
              style={{ backgroundColor: "#F9F9F9" }}
              onEnter={onBrandNewEnter}
              id={"new prop"}
            />
          )}

          <div
            className={styles.addSubBucket}
            onClick={
              bucket.properties.length
                ? () => addProp(bucket.properties.length)
                : undefined
            }
          >
            + Bucket
          </div>
        </div>

        <div
          style={{
            visibility: bucket.name || onDelete ? "visible" : "hidden",
          }}
          className={styles.buttons}
        >
          {onDelete && (
            <Button shadow height={35} width={80} red onClick={onDelete}>
              Delete
            </Button>
          )}
          {bucket.name && (
            <Button
              shadow
              blue
              height={35}
              width={80}
              onClick={() => onDone(bucket)}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                }}
              >
                {words}
              </div>
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}
