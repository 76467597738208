/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson

*/
import styles from "./SurveyComponent.module.scss";
import { QuestionSettings } from "components/QuestionConstruction/QuestionSettings/QuestionSettings";
import { QuestionLogic } from "components/QuestionConstruction/QuestionLogic/QuestionLogic";
import Section from "components/QuestionConstruction/Section";
import Triggers from "components/QuestionConstruction/Triggers/Triggers";
import Question from "components/QuestionConstruction/Question";
import React, { useState, useEffect, useRef } from "react";
import SectionSettings from "components/QuestionConstruction/SectionSettings/SectionSettings";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useHasQuestionBeenAnswered } from "api/resources/projects/questions";
import { Pie } from "components/inputs/input_fields/CircleProgress/Circle";
import { J } from "components/inputs/input_fields/reactgrid/core/handleStateUpdate-7fc017d9";
import EditTags from "../EditTags/EditTags";
import { Label } from "components/layouts/Label/Label";
import Buckets from "../Buckets/Buckets";

export default function SurveyComponent({
  component,
  active,
  setActive,
  onClose,
  pageInd,
  moveTo,
  status,
  canEdit,
  onEditQ,
  deleteQ,
  allQuestions,
  designSettings,
  roles,
  onCopy,
  onAnswer,
  onRetract,
  answers,
  onSaveSomething,
  dependents,
  setUpLogicResolver,
  survey,
  calculateQuestionHealth,
  questionNumber,
}) {
  // TODO - set up a ref for whatever segment is active ?? Then do the event listener on that ref
  // Might be tricky with the overInd stuff

  const [showLogic, setShowLogic] = useState(false);
  const [showTriggers, setShowTriggers] = useState(false);
  const [makeACopy, setMakeACopy] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [showBuckets, setShowBuckets] = useState(false);
  const [health, setHealth] = useState(calculateQuestionHealth(component));

  const conditional =
    component?.conditionGroups && component?.conditionGroups.length > 0
      ? true
      : false;

  const triggered =
    component?.triggers && component?.triggers.length > 0 ? true : false;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: component.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition,
    opacity: isDragging ? 0.4 : undefined,
  };

  function clickOutside(e) {
    if (ref.current && !ref.current?.contains(e.target)) {
      setShowItems(false);
      setMakeACopy(false);
    }
  }

  useEffect(() => {
    document.addEventListener("click", clickOutside, true);
    return () => {
      document.removeEventListener("click", clickOutside, true);
    };
  }, []);

  const ref = useRef();

  function checkForClickOutside(e) {
    let q = document.getElementById(component.id);
    if (q && !q.contains(e.target)) {
      let resolver = document.getElementById("dependencyResolver");
      if (resolver && resolver.contains(e.target)) {
        return;
      }

      let root = document.getElementById("root");
      if (root && !root.contains(e.target)) {
        // It's a color picker - or some modal like it
        return;
      }

      document.removeEventListener("click", checkForClickOutside, true);
      setShowLogic(false);
      setShowTriggers(false);

      onClose();
    }
  }

  function onClick(e) {
    if (!active) {
      document.addEventListener("click", checkForClickOutside, true);
      setActive();
    }
  }

  // useEffect(() => {
  //   document.addEventListener("click", checkForClickOutside, true);
  // }, [])

  function onSaveQuestion(copy) {
    onEditQ(copy);
    setTimeout(() => onSaveSomething(), 100);
  }

  function onEditQuestion(copy) {
    onEditQ(copy);
    setHealth({ ...calculateQuestionHealth(copy) });
  }

  const [showItems, setShowItems] = useState(false);
  // console.log(health);

  const isASection = component.type === "Description";

  return (
    <div
      className={`${styles.questionContainer} ${
        status === "Open" ? styles.openQuestion : ""
      } ${active ? styles.activeQuestionContainer : ""} ${
        isDragging ? styles.dragging : ""
      }`}
      ref={setNodeRef}
      style={{
        ...style,
        backgroundColor:
          "sectionSettings" in component &&
          component.sectionSettings?.hasBackgroundColor
            ? component.sectionSettings?.backgroundColor
            : "questionText" in component
            ? ""
            : "transparent",
        // boxShadow: section.sectionSettings.boxShadow ? "" : "none",
        cursor: status === "Open" || active ? "default" : "pointer",

        boxShadow:
          "sectionSettings" in component
            ? component.sectionSettings?.sectionShadow
              ? "rgba(0, 0, 0, 0.24) 0px 3px 8px"
              : ""
            : "boxShadow" in designSettings
            ? designSettings.boxShadow
              ? "rgba(0, 0, 0, 0.24) 0px 3px 8px"
              : ""
            : "rgba(0, 0, 0, 0.24) 0px 3px 8px",
      }}
      {...attributes}
      onClick={status === "Open" ? undefined : onClick}
      // onMouseEnter={status === "Open" ? undefined : () => moveTo(pageInd)}
      id={component.id}
    >
      <div className={styles.tags}>
        {!survey.isProgram &&
          status !== "Draft" &&
          component?.answer &&
          active && (
            <div className={`${styles.tag} ${styles.seafoam}`}>
              Responses: {component?.answer?.length}
            </div>
          )}
        {component.isComment && active && (
          <div className={styles.nps}>
            {" "}
            NPS Comment <i className="bi bi-tag-fill"></i>
          </div>
        )}
        {component.isScore && active && (
          <div className={styles.nps}>
            {" "}
            NPS <i className="bi bi-tag-fill"></i>
          </div>
        )}
      </div>
      {status !== "Open" && (
        <>
          {" "}
          <div
            className={styles.rightTopBtns}
            style={{
              opacity: "1",
              // top: "questionText" in component ? "15px" : "",
            }}
          >
            <div
              className={`${styles.grip} ${styles.item}`}
              {...listeners}
              style={{
                opacity: "1",
                cursor: "grab",
                paddingTop: "10px",
                borderTopRightRadius: "1em",
                borderTopLeftRadius: "1em",
              }}
            >
              {" "}
              <i className={`bi-grip-horizontal`}></i>
            </div>
            <i
              className={`bi-gear ${styles.item} ${
                showItems && styles.activeItem
              }`}
              style={{
                // fontSize: "1.1em",
                paddingBottom:
                  "sectionSettings" in component || !survey?.useGPS
                    ? "10px"
                    : "",
                borderBottomRightRadius:
                  "sectionSettings" in component || !survey?.useGPS
                    ? "1em"
                    : "",
                borderBottomLeftRadius:
                  "sectionSettings" in component || !survey?.useGPS
                    ? "1em"
                    : "",
              }}
              onClick={(e) => {
                e.stopPropagation();
                // setActive();
                if (showItems) {
                  setShowItems(false);
                } else {
                  setShowItems(true);
                }
              }}
            ></i>

            {"questionText" in component && (
              <>
                {survey?.useGPS && health?.health <= 60 && (
                  <Label
                    labelIcon={<i className={`bi-exclamation-triangle `}></i>}
                    tooltipText={
                      health?.tips?.length > 0 ? (
                        <div>
                          {health?.tips.map((item, ind) => {
                            return (
                              <React.Fragment key={ind}>
                                {ind + 1}. {item} <br />
                                <br />
                              </React.Fragment>
                            );
                          })}
                        </div>
                      ) : null
                    }
                    className={`${styles.red} ${styles.item}`}
                    style={{
                      paddingBottom: "10px",
                      borderBottomRightRadius: "1em",
                      borderBottomLeftRadius: "1em",
                    }}
                    tooltipStyle={{
                      color: "gray",
                      right: "-10px",
                      top: "30px",
                    }}
                  ></Label>
                )}
                {survey?.useGPS && health?.health > 75 && (
                  <Label
                    labelIcon={<i className={`bi-check-circle`}></i>}
                    tooltipText={
                      health?.tips?.length > 0 ? (
                        <div>
                          {health?.tips.map((item, ind) => {
                            return (
                              <React.Fragment key={ind}>
                                {ind + 1}. {item} <br />
                                <br />
                              </React.Fragment>
                            );
                          })}
                        </div>
                      ) : null
                    }
                    // style={{fontSize: ""}}
                    className={`${styles.green} ${styles.item}`}
                    style={{
                      paddingBottom: "10px",
                      borderBottomRightRadius: "1em",
                      borderBottomLeftRadius: "1em",
                    }}
                    tooltipStyle={{
                      color: "gray",
                      right: "-10px",
                      top: "30px",
                    }}
                  ></Label>
                )}
                {survey?.useGPS &&
                  health?.health <= 75 &&
                  health?.health > 60 && (
                    <Label
                      labelIcon={<i className={`bi-exclamation-triangle `}></i>}
                      tooltipText={
                        health?.tips?.length > 0 ? (
                          <div>
                            {health?.tips.map((item, ind) => {
                              return (
                                <React.Fragment key={ind}>
                                  {ind + 1}. {item} <br />
                                  <br />
                                </React.Fragment>
                              );
                            })}
                          </div>
                        ) : null
                      }
                      className={`${styles.yellow} ${styles.item}`}
                      style={{
                        paddingBottom: "10px",
                        borderBottomRightRadius: "1em",
                        borderBottomLeftRadius: "1em",
                      }}
                      tooltipStyle={{
                        color: "gray",
                        right: "-10px",
                        top: "30px",
                      }}
                    ></Label>
                  )}
              </>
            )}

            {showItems && (
              <div
                className={styles.settingsBox}
                // onMouseLeave={() => setShowItems(false)}
                ref={ref}
                // onMouseEnter={(e) => e.stopPropagation()}
              >
                {!isASection && (
                  <div
                    className={`${styles.menuItem} ${
                      triggered ? styles.triggered : ""
                    } ${makeACopy && styles.disabled}`}
                    onClick={(e) => {
                      setShowTriggers(true);
                      setShowItems(false);
                    }}
                    style={{ opacity: active ? 1 : "" }}
                  >
                    {!triggered && (
                      <i
                        className="bi bi-bell"
                        style={{ color: !makeACopy ? "#15BCC7" : "" }}
                      ></i>
                    )}
                    {triggered && (
                      <i
                        className="bi bi-bell-fill"
                        style={{ color: !makeACopy ? "#15BCC7" : "" }}
                      ></i>
                    )}
                    Alerts
                  </div>
                )}
                <div
                  className={`${styles.menuItem} ${
                    conditional ? styles.conditional : ""
                  } ${makeACopy && styles.disabled}`}
                  onClick={() => {
                    setShowLogic(true);
                    setShowItems(false);
                  }}
                  style={{
                    opacity: active ? 1 : "",
                    visibility:
                      status === "Open" && !conditional ? "hidden" : "visible",
                  }}
                >
                  {!conditional && (
                    <i
                      className="bi bi-signpost-2"
                      style={{ color: !makeACopy ? "#A7D5C5" : "" }}
                    ></i>
                  )}
                  {conditional && (
                    <i
                      className="bi bi-signpost-2-fill"
                      style={{ color: !makeACopy ? "#A7D5C5" : "" }}
                    ></i>
                  )}
                  Edit Logic
                </div>

                {!component.new && !isASection && (
                  <>
                    <div
                      className={`${styles.menuItem} ${
                        makeACopy && styles.disabled
                      }`}
                      onClick={() => {
                        setShowTags(true);
                        setShowItems(false);
                      }}
                    >
                      <i
                        className="bi bi-tags"
                        style={{ color: !makeACopy ? "#edb57e" : "" }}
                      ></i>
                      Edit Tags
                    </div>

                    {component.type === "Text" &&
                      component?.textQuestion?.id && (
                        <div
                          className={`${styles.menuItem} ${
                            makeACopy && styles.disabled
                          }`}
                          onClick={() => {
                            setShowBuckets(true);
                            setShowItems(false);
                          }}
                        >
                          <i
                            className="bi bi-bucket"
                            style={{ color: !makeACopy ? "#d6c1e3" : "" }} // OR 9ED2C0
                          ></i>
                          Buckets
                        </div>
                      )}
                  </>
                )}

                {!component.new && (
                  <div
                    className={`${styles.menuItem} ${
                      makeACopy && styles.copyView
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      setMakeACopy(!makeACopy);
                    }}
                  >
                    {" "}
                    <i className="bi-layers" style={{ color: "#F2C85C" }}></i>
                    Make a Copy
                  </div>
                )}

                {makeACopy && (
                  <>
                    {" "}
                    <div
                      className={styles.menuItem}
                      onClick={(e) => {
                        e.stopPropagation();
                        onCopy(component, e, "abovepage");
                      }}
                    >
                      {" "}
                      <i
                        className="bi-align-top"
                        style={{ color: "#2A627C" }}
                      ></i>
                      Above Page
                    </div>
                    <div
                      className={styles.menuItem}
                      onClick={(e) => {
                        e.stopPropagation();
                        onCopy(component, e, "above");
                      }}
                    >
                      {" "}
                      <i
                        className="bi-caret-up"
                        style={{ color: "#2A627C" }}
                      ></i>
                      Above
                    </div>
                    <div
                      className={styles.menuItem}
                      onClick={(e) => {
                        e.stopPropagation();
                        onCopy(component, e, "below");
                      }}
                    >
                      {" "}
                      <i
                        className="bi-caret-down"
                        style={{ color: "#2A627C" }}
                      ></i>
                      Below
                    </div>
                    <div
                      className={styles.menuItem}
                      onClick={(e) => {
                        e.stopPropagation();
                        onCopy(component, e, "belowpage");
                      }}
                    >
                      {" "}
                      <i
                        className="bi-align-bottom"
                        style={{ color: "#2A627C" }}
                      ></i>
                      Below Page
                    </div>
                  </>
                )}

                {status !== "Closed" && (
                  <div
                    className={`${styles.menuItem}  ${
                      makeACopy && styles.disabled
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      deleteQ(component, e);
                    }}
                  >
                    <i
                      className="bi bi-trash3"
                      style={{ color: !makeACopy ? "#FF8878" : "" }}
                    ></i>
                    Delete
                  </div>
                )}
                {status === "Closed" && (
                  <DeleteClosed
                    onDelete={(e) => deleteQ(component, e)}
                    question={component}
                    makeACopy={makeACopy}
                  />
                )}
              </div>
            )}
          </div>
        </>
      )}

      {/* {!active && (
        <div className={styles.logicAndEdit}>
          <div
            className={styles.clickToEdit}
            style={status === "Open" ? { visibility: "hidden" } : {}}
          >
            Click to edit
          </div>
        </div>
      )} */}

      {(component?.questionText || component?.questionText === "") && (
        <div
          onMouseEnter={status === "Open" ? undefined : () => moveTo(pageInd)}
        >
          <Question
            key={component.id}
            question={component}
            active={active}
            saveQuestion={onEditQuestion}
            designSettings={designSettings}
            roles={roles}
            onAnswer={onAnswer}
            onRetract={onRetract}
            answerMap={answers}
            status={status}
            dependents={dependents}
            setUpLogicResolver={setUpLogicResolver}
            questionNumber={questionNumber}
            audienceType={survey?.audienceType}
            checkHealth={survey?.useGPS}
          ></Question>

          {canEdit && active && (
            <QuestionSettings
              question={component}
              saveQuestion={onEditQuestion}
              onCancel={deleteQ}
              dependents={dependents}
              setUpLogicResolver={setUpLogicResolver}
              onRetract={onRetract}
            ></QuestionSettings>
          )}
        </div>
      )}
      {!component.questionText && component?.questionText != "" && (
        <div
          onMouseEnter={status === "Open" ? undefined : () => moveTo(pageInd)}
        >
          <Section
            key={component.id}
            section={component}
            active={active}
            saveSection={onEditQ}
            designSettings={designSettings}
            roles={roles}
            status={status}
          ></Section>

          {canEdit && active && (
            <SectionSettings
              section={component}
              saveSection={onEditQ}
              onCancel={deleteQ}
            ></SectionSettings>
          )}
        </div>
      )}
      {canEdit && (
        <>
          {showLogic && (
            <QuestionLogic
              question={component}
              saveQuestion={onSaveQuestion}
              close={() => setShowLogic(false)}
              allQuestions={allQuestions}
              open={status === "Open"}
            ></QuestionLogic>
          )}
          {showTriggers && (
            <Triggers
              question={component}
              saveQuestion={onSaveQuestion}
              close={() => setShowTriggers(false)}
            />
          )}
          {showTags && (
            <EditTags
              question={component}
              saveQuestion={onSaveQuestion}
              close={() => setShowTags(false)}
              allQuestions={allQuestions}
            />
          )}
          {showBuckets && (
            <Buckets
              question={component}
              close={() => setShowBuckets(false)}
              status={status}
            />
          )}
        </>
      )}
    </div>
  );
}

function DeleteClosed({ question, onDelete, makeACopy }) {
  // const [show, setShow] = useState(false);

  const hasAnswers = useHasQuestionBeenAnswered(question.id);

  // fetch to see if any answer. If yes make them do the modal. If not delete.

  function onWantToDelete(e) {
    if (hasAnswers.data.doesQuestionHaveAnswers) {
      window.alert("People have answered this question. It cannot be deleted");
      // setShow(true);
    } else {
      onDelete(e);
    }
  }

  return (
    <>
      <div
        className={`${styles.menuItem} ${
          !hasAnswers.isSuccess && styles.disabled
        } ${makeACopy && styles.disabled}`}
        onClick={hasAnswers.isSuccess ? onWantToDelete : null}
      >
        {" "}
        <i
          className="bi bi-trash3"
          style={{ color: !makeACopy ? "#FF8878" : "" }}
        ></i>
        Delete
      </div>

      {/* <Modal
            show={show}
            onClose={() => setShow(false)}
            modalStyle={{
              padding: "1em",
              borderRadius: "1em",
              overflow: "visible",
            }}
          >
            <div className={styles.deleteModalPrompt}>
              People have answered this question. Deleting this question will
              delete all answers to this question.
            </div>
            <div className={styles.confirmDelete}>
              Would you still like to delete this question?
            </div>
          </Modal> */}
    </>
  );
}
