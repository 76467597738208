import { useEffect, useRef, useState } from "react";
import { SelectField, TextFieldSimple } from "components/inputs";
import styles from "./QuestionLogic.module.scss";
import { shortId } from "components/tables/EditableTable/utils";
import Button from "components/Button/Button";
import { NumberInput } from "components/inputs/input_fields/NumberInput/NumberInput";

export const QuestionLogic = ({
  question,
  saveQuestion,
  close,
  allQuestions,
  open,
}) => {
  const onlyQs = [];
  for (let q of allQuestions) {
    if (q.id !== question.id && "questionText" in q) {
      onlyQs.push(q);
    }
  }

  const allPrevious = [];
  for (let q of onlyQs) {
    if (q.pageNumber < question.pageNumber) {
      allPrevious.push(q);
    } else if (
      q.pageNumber === question.pageNumber &&
      q.pageOrderIndex < question.pageOrderIndex
    ) {
      allPrevious.push(q);
    }
  }

  const genOperators = [
    { value: "answer", label: "answer" },
    { value: "do not answer", label: "do not answer" },
    { value: "have answered", label: "have answered at all" },
    { value: "have not answered", label: "have not answered at all" },
  ];

  const scaleOperators = [
    { value: "between", label: "answer between" },
    { value: "below", label: "answer below" },
    { value: "above", label: "answer above" },
  ];

  const choiceSingleOperators = [];

  const choiceManyOperators = [];

  const freeResponseOperators = [
    { value: "contains", label: "contains" },
    { value: "does not contain", label: "does not contain" },
    { value: "have answered", label: "is not blank (they've answered) " },
    { value: "have not answered", label: "is blank (no answer)" },
    { value: "is longer than", label: "is longer than" },
    { value: "is shorter than", label: "is shorter than" },
  ];

  const groupOperators = [
    {
      value: "When any condition matches",
      label: "When any condition matches",
    },
    {
      value: "When any condition does not match",
      label: "When any condition does not match",
    },
    { value: "When all conditions match", label: "When all conditions match" },
    { value: "When no condition matches", label: "When no condition matches" },
  ];

  const [groups, setGroups] = useState(
    question?.conditionGroups
      ? JSON.parse(JSON.stringify(question.conditionGroups))
      : []
  );

  const [edited, setEdited] = useState(false);

  const ref = useRef();

  function clickOut(event) {
    if (ref.current && !ref.current?.contains(event.target)) {
      close();
    }
  }

  useEffect(() => {
    document.addEventListener("click", clickOut, true);
    return () => {
      document.removeEventListener("click", clickOut, true);
    };
  }, []);

  function updateGroups(newGroups) {
    setGroups(newGroups);
    setEdited(true);
  }

  function changeGroupOperator(groupIndex, value) {
    let temp = [...groups];
    temp[groupIndex].operator = value;
    updateGroups(temp);
  }

  function deleteCondition(groupIndex, index) {
    let condition = groups[groupIndex].conditions[index];
    if (
      question?.conditionGroups &&
      groupIndex < question.conditionGroups.length &&
      index < question.conditionGroups[groupIndex].conditions.length &&
      question.conditionGroups[groupIndex].conditions[index].id === condition.id
    ) {
      // deleting one that is saved, delete it from the question.
      let copy = { ...question };
      let qgroups = [...copy.conditionGroups];
      qgroups[groupIndex].conditions.splice(index, 1);
      if (qgroups[groupIndex].conditions.length === 0) {
        qgroups.splice(groupIndex, 1);
      }
      copy.conditionGroups = qgroups;
      saveQuestion(copy);
    }

    let temp = [...groups];
    temp[groupIndex].conditions.splice(index, 1);
    if (temp[groupIndex].conditions.length === 0) {
      temp.splice(groupIndex, 1);
    }
    updateGroups(temp);
  }

  function addCondition() {
    if (groups.length > 0) {
      let temp = [...groups];
      temp[groups.length - 1].conditions.push({
        id: shortId(),
        questionId: null,
        operator: null,
        answer: null,
      });
      updateGroups(temp);
    } else {
      addGroup();
    }
  }

  function addGroup() {
    let temp = [...groups];
    temp.push({
      id: shortId(),
      operator: "When all conditions match",
      conditions: [
        {
          id: shortId(),
          questionId: null,
          operator: null,
          answer: null,
        },
      ],
    });
    updateGroups(temp);
  }

  function getAnswerOptions(condition) {
    let qInQuestion = allQuestions.find((q) => q.id === condition.questionId);
    if (qInQuestion.choiceQuestion) {
      let choiceQ = qInQuestion.choiceQuestion;
      let choices = choiceQ.choices.map((choice) => {
        return { value: choice, label: choice };
      });
      if (choiceQ.hasOtherOption) {
        choices.push({
          value: choiceQ.otherOptionLabel,
          label: choiceQ.otherOptionLabel,
        });
      }
      return choices;
    }

    if (qInQuestion.scaleQuestion) {
      let scale = qInQuestion.scaleQuestion;
      let options = [];
      for (let i = scale.min; i < scale.max + 1; i += scale.step) {
        options.push({ value: i, label: i });
      }
      return options;
    }
  }

  function getChosenAnswerOptions(condition) {
    if (condition?.answer) {
      if (typeof condition.answer === "object") {
        return condition.answer.map((val) => {
          return { value: val, label: val };
        });
      } else {
        return { value: condition.answer, label: condition.answer };
      }
    }
    return null;
  }

  function canSelectMultiple(condition) {
    if (condition.operator) {
      let op = condition.operator;
      return op === "answer" || op === "do not answer" || op === "between";
    }
    return false;
  }

  function showAnswerOptions(condition) {
    if (condition?.questionId) {
      let q = allQuestions.find((one) => one.id === condition.questionId);
      if (q.textQuestion) {
        return false;
      }
    }
    if (condition?.operator) {
      let op = condition.operator;
      if (op === "have answered" || op === "have not answered") {
        return false;
      }
    }
    return true;
  }

  function showTextEntry(condition) {
    if (condition?.questionId) {
      if (condition?.operator) {
        let op = condition.operator;
        if (op === "contains" || op === "does not contain") {
          let q = allQuestions.find((one) => one.id === condition.questionId);
          if (q.textQuestion) {
            return true;
          }
        }
      }
    }
    return false;
  }

  function showNumberInput(condition) {
    if (condition?.questionId) {
      if (condition?.operator) {
        let op = condition.operator;
        if (op === "is longer than" || op === "is shorter than") {
          let q = allQuestions.find((one) => one.id === condition.questionId);
          if (q.textQuestion) {
            return true;
          }
        }
      }
    }
    return false;
  }

  function isConditionComplete(condition) {
    if (condition?.operator) {
      if ("option" in condition) {
        if (!condition.option) {
          return false;
        }
      }

      if (
        condition?.operator === "between" &&
        condition?.answer &&
        condition.answer.length !== 2
      ) {
        return false;
      }
      if (condition.answer) {
        return true;
      }
      let op = condition.operator;
      if (op === "have answered" || op === "have not answered") {
        return true;
      }
    }
    return false;
  }

  function showSave() {
    for (let group of groups) {
      for (let condition of group.conditions) {
        if (!isConditionComplete(condition)) {
          return false;
        }
      }
    }

    return edited;
  }

  function save() {
    let tempq = { ...question };
    tempq.conditionGroups = JSON.parse(JSON.stringify(groups));
    saveQuestion(tempq);
    setEdited(false);
  }

  function changeAnswer(groupIndex, index, chosen) {
    let temp = [...groups];
    let condition = temp[groupIndex].conditions[index];

    if (canSelectMultiple(condition)) {
      if (!chosen.length) {
        condition.answer = null;
      } else {
        if (condition.operator === "between" && chosen.length == 3) {
          let last2 = chosen.slice(1);
          condition.answer = last2.map((c) => c.value);
        } else {
          condition.answer = chosen.map((c) => c.value);
        }
      }
    } else {
      condition.answer = chosen.value;
    }

    updateGroups(temp);
  }

  function changeTextAnswer(groupIndex, index, value) {
    let temp = [...groups];
    groups[groupIndex].conditions[index].answer = value;
    updateGroups(temp);
  }

  function changeQuestion(groupIndex, index, value) {
    let temp = [...groups];
    temp[groupIndex].conditions[index].questionId = value;
    temp[groupIndex].conditions[index].answer = null;
    temp[groupIndex].conditions[index].operator = "";
    delete temp[groupIndex].conditions[index].option;

    let dependentQ = allQuestions.find((q) => q.id === value);
    if (dependentQ.type === "Matrix") {
      temp[groupIndex].conditions[index].option = "";
    }

    updateGroups(temp);
  }

  function changeOption(groupIndex, index, value) {
    let temp = [...groups];
    temp[groupIndex].conditions[index].option = value;
    updateGroups(temp);
  }

  function changeOperator(groupIndex, index, value) {
    let temp = [...groups];
    temp[groupIndex].conditions[index].operator = value;
    temp[groupIndex].conditions[index].answer = null;
    updateGroups(temp);
  }

  function getRelavantOperators(condition) {
    if (condition.questionId) {
      let qInQuestion = allQuestions.find((q) => q.id === condition.questionId);
      if (qInQuestion.scaleQuestion) {
        return [...genOperators, ...scaleOperators];
      } else if (qInQuestion.textQuestion) {
        return freeResponseOperators;
      } else if (qInQuestion.choiceQuestion) {
        if (qInQuestion.choiceQuestion.isMultiSelect) {
          return [...genOperators, ...choiceManyOperators];
        } else {
          return [...genOperators, ...choiceSingleOperators];
        }
      }
    }
    return [];
  }

  const isASection = question.type === "Description";

  function trimSection() {
    if (question.bodyText.length > 20) {
      let part = question.bodyText.substring(0, 20);
      return part + "...";
    } else {
      return question.bodyText;
    }
  }

  return (
    <div className={styles.settingsContainer} ref={ref}>
      <div className={styles.close} onClick={close}>
        <i className="bi bi-x-lg"></i>
      </div>
      <div className={styles.questionName}>
        Condition logic for "
        {isASection ? trimSection() : question?.questionText}"
      </div>

      {groups.map((group, ind) => (
        <Group group={group} groupIndex={ind} open={open} />
      ))}
      {!open && (
        <div className={styles.addBox}>
          <Button height={30} white onClick={() => addCondition()}>
            <div style={{ display: "flex", gap: "5px" }}>
              <i className="bi bi-plus-lg"></i> Condition
            </div>
          </Button>

          {groups.length > 0 && (
            <Button height={30} white onClick={() => addGroup()}>
              <div style={{ display: "flex", gap: "5px" }}>
                <i className="bi bi-plus-lg"></i> Group
              </div>
            </Button>
          )}
          {groups.length > 0 && showSave() && (
            <Button height={30} onClick={() => save()}>
              Save
            </Button>
          )}
        </div>
      )}
    </div>
  );

  function Group({ group, groupIndex, open }) {
    return (
      <div className={styles.group}>
        {(groups.length > 1 || groups[0].conditions.length > 1) && (
          <div style={{ padding: "1.5em 0em .5em 0em" }}>
            <SelectField
              options={groupOperators}
              value={groupOperators.find(
                (type) => group.operator === type.value
              )}
              onChange={(typeOption) =>
                changeGroupOperator(groupIndex, typeOption.value)
              }
              label="Condition Group"
              disable={open}
            ></SelectField>
          </div>
        )}
        <div className={styles.conditions}>
          {group.conditions.map((condition, ind) => (
            <Condition
              condition={condition}
              cIndex={ind}
              group={group}
              groupIndex={groupIndex}
              open={open}
            />
          ))}
        </div>
      </div>
    );
  }

  function Condition({ condition, cIndex, group, groupIndex, open }) {
    const dependentQ = condition.questionId
      ? allQuestions.find((q) => q.id === condition.questionId)
      : undefined;

    return (
      <div className={styles.condition}>
        {!open && (
          <i
            className="bi bi-trash"
            onClick={() => deleteCondition(groupIndex, cIndex)}
          ></i>
        )}
        <div className={styles.toppart}>
          <span>{`Show ${isASection ? "section" : "question"} if: on `}</span>

          <SelectField
            options={allPrevious.map((q) => {
              return { value: q.id, label: q.questionText };
            })}
            value={allPrevious
              .map((q) => {
                return { value: q.id, label: q.questionText };
              })
              .find((type) => condition.questionId === type.value)}
            onChange={(typeOption) =>
              changeQuestion(groupIndex, cIndex, typeOption.value)
            }
            label="Previous Question"
            disable={open}
          ></SelectField>
        </div>
        {dependentQ && dependentQ.type === "Matrix" && (
          <div className={styles.toppart}>
            <span>for</span>
            <SelectField
              options={dependentQ.matrixQuestion.options.map((opt) => {
                return { value: opt, label: opt };
              })}
              value={dependentQ.matrixQuestion.options
                .map((opt) => {
                  return { value: opt, label: opt };
                })
                .find((opt) => condition.option === opt.value)}
              onChange={(opt) => changeOption(groupIndex, cIndex, opt.value)}
              label="Option"
              disable={open}
            ></SelectField>
          </div>
        )}
        <div className={styles.bottompart}>
          <span>
            {condition.questionId && dependentQ.textQuestion
              ? "their answer"
              : "they"}
          </span>
          <SelectField
            options={getRelavantOperators(condition)}
            value={getRelavantOperators(condition).find(
              (type) => condition.operator === type.value
            )}
            onChange={(typeOption) =>
              changeOperator(groupIndex, cIndex, typeOption.value)
            }
            label="Operator"
            disable={open}
          ></SelectField>

          {showAnswerOptions(condition) && (
            <SelectField
              options={condition.questionId ? getAnswerOptions(condition) : []}
              value={getChosenAnswerOptions(condition)}
              onChange={(chosen) => changeAnswer(groupIndex, cIndex, chosen)}
              label="Choices"
              selectMultiple={canSelectMultiple(condition)}
              disable={open}
            ></SelectField>
          )}

          {showTextEntry(condition) && (
            <TextFieldSimple
              style={{ height: "40px" }}
              label={"What to look for"}
              placeholder="Enter text"
              onSave={(val) => changeTextAnswer(groupIndex, cIndex, val)}
              onEnter={(val) => changeTextAnswer(groupIndex, cIndex, val)}
              value={condition?.answer}
              disable={open}
            />
          )}

          {showNumberInput(condition) && (
            <div
              style={{
                height: "40px",
                display: "flex",
                alignItems: "center",
                gap: ".5em",
              }}
            >
              <NumberInput
                startNumber={
                  condition.answer
                    ? condition.answer
                    : 100 > dependentQ?.textQuestion?.maxLength
                    ? dependentQ?.textQuestion?.maxLength
                    : 100
                }
                min={0}
                max={dependentQ?.textQuestion?.maxLength}
                onSave={(val) => changeTextAnswer(groupIndex, cIndex, val)}
                disable={open}
              />
              <div className={styles.label}>Characters </div>
            </div>
          )}
        </div>
      </div>
    );
  }
};
