import React from "react";
import styles from "../SettingsAccordion.module.scss";
import {
  TableType,
  MetricType,
  ValueType,
  defaultColors,
  LineType,
  NoSort,
} from "../../QuestionChart";

import { SegmentSettings } from "./Colors/SegmentSettings";
import { Label } from "components/layouts/Label/Label";
import { ColorPicker } from "components/inputs/input_fields/ColorPicker/ColorPicker";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import { combinedQs } from "../../Visualization";
import { splitting } from "../SettingsAccordion";

export const ColorSettings = ({
  viz,
  title,
  changeSettingsField,
  labels,
  updateViz,
  visible,
  setVisible,
}) => {
  function updateSettings(newSettings) {
    let newViz = { ...viz };
    newViz.designSettings = newSettings;
    updateViz(newViz);
  }

  const QChart = !viz.dataMethod || viz.dataMethod === "question";

  return (
    <>
      <div
        key={viz.id}
        className={`${styles.header} ${visible ? styles.headervisible : ""}`}
        onClick={setVisible}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
          {title}{" "}
          <i style={{ fontSize: "1.2em" }} className="bi-paint-bucket"></i>
        </div>
        <span className={styles.accordionicon}>
          <i className="bi bi-caret-left-fill"></i>
        </span>
      </div>
      {visible && (
        <div className={styles.body}>
          {viz.type === LineType && !splitting(viz) && (
            <>
              <div
                className={styles.setting2}
                style={{ gap: "10px", paddingLeft: "10px" }}
              >
                <ColorPicker
                  defaultColor={viz.designSettings.lineGraphColor}
                  onChange={(val) => changeSettingsField("lineGraphColor", val)}
                  textColors
                />
                <Label
                  style={{
                    width: "fit-content",
                  }}
                >
                  Line Color
                </Label>
              </div>

              <div
                className={styles.setting2}
                style={{
                  gap: "10px",
                  paddingLeft: "10px",
                }}
              >
                <ToggleSwitch
                  startChecked={viz.designSettings.hasUniformPointColor}
                  handleCheck={(val) =>
                    changeSettingsField("hasUniformPointColor", val)
                  }
                ></ToggleSwitch>
                <Label
                  style={{
                    width: "fit-content",
                  }}
                >
                  Points All Same Color
                </Label>
              </div>

              {viz.designSettings.hasUniformPointColor && (
                <div
                  className={styles.setting2}
                  style={{ gap: "10px", paddingLeft: "15px" }}
                >
                  <ColorPicker
                    defaultColor={viz.designSettings.uniformPointColor}
                    onChange={(val) =>
                      changeSettingsField("uniformPointColor", val)
                    }
                  />
                  <Label
                    style={{
                      width: "fit-content",
                    }}
                  >
                    Point Color
                  </Label>
                </div>
              )}
            </>
          )}

          {((QChart && combinedQs(viz).length > 0) || !QChart) &&
            !(
              viz.type === LineType &&
              viz.designSettings.hasUniformPointColor &&
              !splitting(viz)
            ) && (
              <>
                {viz.type != TableType && (
                  <SegmentSettings
                    viz={viz}
                    changeSettingsField={changeSettingsField}
                    updateSettings={updateSettings}
                    labels={labels}
                  />
                )}
              </>
            )}
          {viz.designSettings.colors &&
            viz.designSettings.sortData !== NoSort &&
            !splitting(viz) &&
            !(
              viz.type === LineType && viz.designSettings.hasUniformPointColor
            ) && (
              <div
                className={styles.row}
                style={{
                  paddingLeft: "10px",
                  paddingTop: "10px",
                }}
              >
                <ToggleSwitch
                  startChecked={viz.designSettings.afterSort}
                  handleCheck={(val) => changeSettingsField("afterSort", val)}
                ></ToggleSwitch>
                <Label
                  style={{
                    width: "fit-content",
                    fontSize: ".85em",
                  }}
                >
                  Apply colors after sorting
                </Label>
              </div>
            )}
          {viz.type === TableType && (
            <div
              className={styles.setting2}
              style={{ gap: "10px", paddingLeft: "10px" }}
            >
              <ColorPicker
                defaultColor={viz.designSettings.tableColor}
                onChange={(val) => changeSettingsField("tableColor", val)}
              />
              <Label
                style={{
                  width: "fit-content",
                }}
              >
                Table Color
              </Label>
            </div>
          )}

          <div
            className={styles.setting2}
            style={{ gap: "10px", padding: "10px 0px 0px 10px" }}
          >
            <ColorPicker
              defaultColor={
                viz.designSettings.titleColor
                  ? viz.designSettings.titleColor
                  : "#ffffff"
              }
              onChange={(val) => changeSettingsField("titleColor", val)}
              textColors
            />
            <Label
              style={{
                fontWeight: "400",
                width: "fit-content",
                fontSize: ".8em",
                whiteSpace: "nowrap",
              }}
            >
              Title Color
            </Label>
          </div>

          <div
            className={styles.setting2}
            style={{ gap: "10px", paddingLeft: "10px" }}
          >
            {" "}
            <ColorPicker
              defaultColor={
                viz.designSettings.titleBackgroundColor
                  ? viz.designSettings.titleBackgroundColor
                  : "#ffffff"
              }
              onChange={(val) =>
                changeSettingsField("titleBackgroundColor", val)
              }
              textColors
            />
            <Label
              style={{
                fontWeight: "400",
                width: "fit-content",
                fontSize: ".8em",
                whiteSpace: "nowrap",
              }}
            >
              Title Background Color
            </Label>
          </div>
        </div>
      )}
    </>
  );
};
