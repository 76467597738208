/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/

import Button from "components/Button/Button";
import React, { useState } from "react";
import styles from "./EditColors.module.scss";
import { PaletteColorPicker } from "./PaletteColorPicker";
import { AddColorSimple } from "./AddColorSimple";

/**
 * An input component for choosing a number
 * @param {Int} startNumber startingNumber for the input
 * @param {type} handleNumberChange is called when Number is changed
 * @returns {React.ReactElement} a number input component
 */

export const EditColors = ({ changeColors, colors }) => {
  const [beforeChange, setBeforeChange] = useState();

  function onRemove(index) {
    let copy = [...colors];
    copy.splice(index, 1);
    changeColors(copy);
  }

  function getRowsOfTen() {
    let rows = [];
    let i = 0;
    let trueLength = colors.length; // addingColor? palette.colors.length - 1 : palette.colors.length
    while (i < trueLength) {
      let row = [];
      for (let c = 0; c < 10; c++) {
        if (i < trueLength) {
          row.push(colors[i]);
          i++;
        }
      }
      rows.push(row);
    }
    if (!rows.length) {
      rows.push([]);
    }
    if (rows[rows.length - 1].length == 10) {
      rows.push([]);
    }
    // console.log(rows);
    return rows;
  }

  function onApply() {
    setBeforeChange(null);
  }

  function onAdd(color) {
    if (color) {
      let copy = [...colors];
      copy.push(color);
      changeColors(copy);
    }
  }

  function onCancel() {
    if (beforeChange) {
      let copy = [...beforeChange];
      changeColors(copy);
      setBeforeChange(null);
    }
  }

  function onChange(color, ind) {
    let copy = [...colors];
    if (!beforeChange) {
      setBeforeChange([...copy]);
    }
    colors[ind] = color;
    changeColors(colors);
  }

  return (
    <div className={styles.container}>
      <div className={styles.pallet}>
        {getRowsOfTen().map((row, rowInd) => {
          if (row.length) {
            return (
              <div className={styles.row}>
                {row.map((color, ind) => {
                  let colorInd = ind + rowInd * 10;
                  return (
                    <PaletteColorPicker
                      color={color}
                      orig={beforeChange ? beforeChange[colorInd] : color}
                      onChange={(clr) => onChange(clr, colorInd)}
                      onCancel={onCancel}
                      onApply={onApply}
                      onRemove={() => onRemove(colorInd)}
                    />
                  );
                })}
                {row.length < 10 && (
                  <>
                    <AddColorSimple onDone={onAdd} />
                  </>
                )}
              </div>
            );
          } else {
            return (
              <div className={styles.row}>
                <AddColorSimple onDone={onAdd} />
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};
