import { NavLink, useNavigate } from "react-router-dom";
import styles from "./ReportWidget.module.scss";
import { useFetchMyLastSeenReports } from "api/resources/organization/users";

export default function ReportWidget({ widget, inSettings }) {
  const headToReports = () => {
    localStorage.removeItem("activeprojecttab");
    localStorage.removeItem("activecontactstab");
    localStorage.removeItem("activeorgtab");
    localStorage.removeItem("activeaccounttab");
    localStorage.setItem("reportspageitem", 1);
    localStorage.setItem("activepage", 2);
  };

  return (
    <div className={styles.container}>
      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        <div className={styles.title}>{widget.name}</div>

        <div className={styles.reportBlock}>
          {widget.settings?.reports.map((report) => (
            <Report report={report} inSettings={inSettings} />
          ))}
          {widget.settings?.reports.length === 0 && (
            <div className={styles.none}>None</div>
          )}
        </div>

        {widget.settings.showRecent && (
          <RecentReports inSettings={inSettings} settings={widget.settings} />
        )}
      </div>
      {widget.settings?.showGoToReports && (
        <div
          className={`${styles.viewAll} ${styles.links}`}
          style={inSettings ? { pointerEvents: "none" } : undefined}
        >
          <NavLink
            to={`reports/my-reports`}
            className={styles.links}
            id="goToReports"
            onClick={headToReports()}
          >
            Go to Reports
          </NavLink>
        </div>
      )}
    </div>
  );
}

function Report({ report, inSettings }) {
  let navigate = useNavigate();

  function onClick() {
    localStorage.setItem("activepage", 2);

    navigate("/reports/" + report.id);
  }

  return (
    <div
      className={styles.report}
      style={inSettings ? { pointerEvents: "none" } : undefined}
      onClick={onClick}
    >
      {report?.symbol && <div className={styles.symbol}>{report.symbol}</div>}

      <div className={styles.text}>
        <div className={styles.reportTitle}>{report.name}</div>
        <div className={styles.subtitle}>{report.content}</div>
      </div>
    </div>
  );
}

function RecentReports({ inSettings, settings }) {
  const recentReports = useFetchMyLastSeenReports(settings.recentLimit);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <div className={styles.title} style={{ fontSize: ".85em" }}>
        Recently Viewed Reports
      </div>

      {recentReports.isSuccess && (
        <div className={styles.reportBlock}>
          {recentReports?.data?.getLastSeenReports.map((report) => (
            <Report report={report} inSettings={inSettings} />
          ))}
          {!recentReports?.data?.getLastSeenReports && (
            <div className={styles.none}>None</div>
          )}
        </div>
      )}
    </div>
  );
}
