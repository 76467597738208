import { useState, useEffect, useRef } from "react";
import accordianStyles from "../../SettingsAccordion.module.scss";
import styles from "./Data.module.scss";
import { TableType } from "../../../QuestionChart";
import { useFetchQuestionsGql } from "api/resources/projects/questions";
import { Label } from "components/layouts/Label/Label";
import { Loading } from "components/Loading/Loading";
import {
  useFetchSurveyTags,
  useFetchSurveyTagsGql,
} from "api/resources/organization/surveytags";
import {
  useFetchAllPrograms,
  useFetchPrograms,
  useFetchProjectsByIdsChart,
  useSearchProjectsForChart,
} from "api/resources/projects/projects";
import { forEach } from "assets/functions/ArrayFunctions";
import { useFetchReport } from "api/resources/organization/reports";
import { useParams } from "react-router-dom";
import { QuestionTag } from "./QuestionTag/QuestionTag";
import { useFetchQuestionAndNpsTags } from "api/resources/organization/questiontag";
import { getQuestionType } from "./QData";
import { combinedQs } from "pages/results/Charts/Visualization";
import { DynamicOption } from "./DynamicOption/DynamicOption";

export const StatComparison = ({ viz, updateViz }) => {
  const { reportid } = useParams();

  return (
    <>
      <div
        className={styles.body}
        style={{
          padding: "0px",
          marginTop: "0px",
          width: "100%",
          backgroundColor: "transparent",
          maxWidth: "500px",
        }}
      >
        {/* {reportid && (
          <MiddleMan viz={viz} updateViz={updateViz} reportId={reportid} />
        )}
        {!reportid && <PickData viz={viz} updateViz={updateViz} />} */}

        <PickData viz={viz} updateViz={updateViz} />
      </div>
    </>
  );
};

function MiddleMan({ viz, updateViz, reportId }) {
  const getReport = useFetchReport(reportId);

  return (
    <>
      {getReport.isLoading && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Loading height={120} width={120} />
        </div>
      )}
      {getReport.isSuccess && (
        <PickData
          viz={viz}
          updateViz={updateViz}
          tiedIds={getReport.data.report.project.map((p) => p.id)}
        />
      )}
    </>
  );
}

function PickData({ viz, tiedIds, updateViz }) {
  const comparison = {
    projectIds: [],
    dynamics: [],
  };

  const getProjects = useFetchProjectsByIdsChart({
    projectIds: viz.comparison.projectIds,
  });

  const [addSurvey, setAddSurvey] = useState(false);

  function addProject(project) {
    let copy = { ...viz };
    copy.comparison.projectIds.push(project.id);
    updateViz(copy);
    setAddSurvey(false);
  }

  function removeProject(project) {
    let copy = { ...viz };
    let ind = copy.comparison.projectIds.indexOf(project.id);
    copy.comparison.projectIds.splice(ind, 1);
    updateViz(copy);
  }

  return (
    <>
      <div
        style={{
          position: "relative",
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TagsAndPrograms viz={viz} updateViz={updateViz} />

        {viz.comparison.projectIds.length > 0 && getProjects.isSuccess && (
          <ChosenSurveys
            removeProject={removeProject}
            chosenProjects={getProjects.data.survey}
          />
        )}

        <div className={styles.add}>
          <span onClick={() => setAddSurvey(true)}>+ Add Survey</span>
        </div>

        {addSurvey && (
          <AddSurvey
            addProject={addProject}
            projectIdsArray={viz.comparison.projectIds}
            close={() => setAddSurvey(false)}
          />
        )}
      </div>
    </>
  );
}

function TagsAndPrograms({ viz, updateViz }) {
  const [searchString, setSearchString] = useState("");
  const [active, setActive] = useState(false);

  function addSurveyTag(tag) {
    let copy = { ...viz };
    copy.comparison.dynamics.push({
      name: tag.label,
      timeFrame: "All",
      type: "surveyTag",
      id: tag.id,
    });
    updateViz(copy);
  }

  function removeDynamic(ind) {
    let copy = { ...viz };
    copy.comparison.dynamics.splice(ind, 1);
    updateViz(copy);
  }

  function addProgram(program) {
    let copy = { ...viz };
    copy.comparison.dynamics.push({
      name: program.name,
      timeFrame: "All",
      type: "program",
      id: program.id,
    });

    updateViz(copy);
  }

  function changeTimeFrame(i, tf) {
    let copy = { ...viz };
    let dynamic = copy.comparison.dynamics[i];
    if (tf === "selection") {
      dynamic.selection = [];
    } else if (dynamic.timeFrame === "selection") {
      delete dynamic.selection;
    }
    dynamic.timeFrame = tf;

    updateViz(copy);
  }

  function changeSelection(i, projId) {
    let copy = { ...viz };

    let dynamic = copy.comparison.dynamics[i];

    let ind = dynamic.selection.indexOf(projId);
    if (ind > -1) {
      dynamic.selection.splice(ind, 1);
    } else {
      dynamic.selection.push(projId);
    }

    updateViz(copy);
  }

  return (
    <>
      <div className={accordianStyles.headerStatic}>
        Program <i className="bi bi-calendar-check"></i> | Survey Tags{" "}
        <i className="bi bi-tags"></i>
      </div>
      <div className={styles.searchFor}>
        <input
          type="text"
          onChange={(e) => setSearchString(e.target.value)}
          className={styles.search}
          value={searchString}
          onFocus={() => setActive(true)}
          onBlur={() => setTimeout(() => setActive(false), 250)}
        ></input>
        <div className={styles.tagsAnchor}>
          {active && (
            <SearchTagsAndPrograms
              searchString={searchString}
              addProgram={addProgram}
              addSurveyTag={addSurveyTag}
              viz={viz}
            />
          )}
        </div>
      </div>

      {viz.comparison.dynamics.map((d, i) => (
        <DynamicOption
          dynamic={d}
          onRemove={() => removeDynamic(i)}
          changeTimeFrame={(tf) => changeTimeFrame(i, tf)}
          changeSelection={(proj) => changeSelection(i, proj.id)}
        />
      ))}
    </>
  );
}

function SearchTagsAndPrograms({
  searchString,
  addProgram,
  addSurveyTag,
  viz,
}) {
  const getSurveyTags = useFetchSurveyTagsGql();
  const getPrograms = useFetchAllPrograms();

  function programs() {
    let programs = getPrograms.data.programs.filter(
      (p) =>
        !viz.comparison.dynamics.find(
          (d) => d.id === p.id && d.type === "program"
        )
    );

    if (searchString) {
      programs = programs.filter((p) =>
        p.mainProject.name.toLowerCase().includes(searchString.toLowerCase())
      );
    }
    return programs;
  }

  function tags() {
    let tags = getSurveyTags.data.surveyTagByOrgId.filter(
      (p) =>
        !viz.comparison.dynamics.find(
          (d) => d.id === p.id && d.type === "surveyTag"
        )
    );

    if (searchString) {
      tags = tags.filter((t) =>
        t.label.toLowerCase().includes(searchString.toLowerCase())
      );
    }
    return tags;
  }

  return (
    <div className={styles.tagOptions}>
      {getSurveyTags.isSuccess && getPrograms.isSuccess && (
        <>
          {programs().map((p) => (
            <div className={styles.tagOption} onClick={() => addProgram(p)}>
              {p?.mainProject?.name}
              <div
                className={`${styles.qTagName}${styles.indicator} ${styles.programIndicator}`}
              >
                Program
              </div>
            </div>
          ))}
          {tags().map((t) => (
            <div className={styles.tagOption} onClick={() => addSurveyTag(t)}>
              {t?.label}
              <div
                className={`${styles.qTagName} ${styles.indicator} ${styles.surveyTagIndicator}`}
              >
                Tag
              </div>
            </div>
          ))}
        </>
      )}
    </div>
  );
}

function AddSurvey({ addProject, projectIdsArray, close }) {
  function clickOutListener(e) {
    if (ref.current && !ref.current.contains(e.target)) {
      close();
      document.removeEventListener("click", clickOutListener, true);
    }
  }

  const ref = useRef();

  useEffect(() => {
    document.addEventListener("click", clickOutListener, true);
    return () => {
      document.removeEventListener("click", clickOutListener, true);
    };
  }, []);

  return (
    <>
      <div className={styles.addSettings} ref={ref}>
        <i className="bi-x-lg" onClick={close}></i>
        <div className={styles.settingwithlabel}>
          <Label
            style={{
              width: "fit-content",
              fontSize: ".9em",
              paddingLeft: "20px",
            }}
          >
            Add Survey
          </Label>
          <SearchSurvey
            addProject={addProject}
            exceptForIds={projectIdsArray}
          ></SearchSurvey>
        </div>
      </div>
    </>
  );
}

export function SearchSurvey({ addProject, exceptForIds }) {
  const [searchString, setSearchString] = useState("");
  const [searchVisible, setSearchVisible] = useState(false);
  function changeSearch(e) {
    setSearchString(e.target.value);
  }
  const [perPage, setPerPage] = useState(0);
  const [pageSkip, setPageSkip] = useState(0);
  const [searchSort, setSearchSort] = useState({
    item: "startedAt",
    descend: true,
  });

  useEffect(() => {
    searchOptions.refetch();
  }, [searchString]);

  const searchOptions = useSearchProjectsForChart(
    searchString,
    pageSkip,
    perPage,
    searchSort
  );

  const ref = useRef(null);
  //handling the clicking outside of elements
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setSearchVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div className={styles.searchQuestionBox} ref={ref}>
      <input
        className={styles.searchQuestionInput}
        type="text"
        placeholder="search"
        onChange={changeSearch}
        onFocus={() => {
          setSearchVisible(true);
        }}
        // onBlur={() => {
        //   setSearchVisible(false);
        // }}
      ></input>
      {searchVisible &&
        searchOptions.isSuccess &&
        !searchOptions.isRefetching && (
          <div className={styles.optionsBox}>
            <div className={styles.options}>
              {searchOptions.data?.surveys.map((project, index) => (
                <>
                  {!exceptForIds.includes(project.id) && (
                    <div
                      key={index}
                      className={styles.option}
                      onClick={() => {
                        addProject(project);
                        setSearchVisible(false);
                      }}
                    >
                      {project.name}
                    </div>
                  )}
                </>
              ))}
            </div>
          </div>
        )}
    </div>
  );
}

function ChosenSurveys({ removeProject, chosenProjects }) {
  function getProjectsInOrder() {
    let surveys = [...chosenProjects];

    surveys.sort((a, b) => {
      //Sort the projects by survey date
      if (a.startedAt === null || b.startedAt === null) {
        return 0;
      }
      let aDate = new Date(a.startedAt);
      let bDate = new Date(b.startedAt);
      return bDate - aDate;
    });
    return surveys;
  }

  return (
    <div
      style={{
        // marginTop: "15px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        paddingTop: "10px",
      }}
    >
      <div
        className={`${accordianStyles.headerStatic}`}
        style={{ paddingBottom: "5px" }}
      >
        Surveys
        <i style={{ fontSize: "1.2em" }} className="bi bi-clipboard"></i>
      </div>
      <div className={styles.currentData}>
        {getProjectsInOrder().map((project, index) => (
          <div key={index} className={styles.projRow}>
            <div className={styles.projectName}>
              {project.name}
              <i
                className="bi bi-x-lg"
                onClick={() => removeProject(project)}
              ></i>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
