import { useState, useEffect } from "react";

//Internal

import styles from "./QuestionChart.module.scss";
import { useUpdateVisualization } from "api/resources/projects/visualizations";
import { Loading } from "components/Loading/Loading";
import {
  useFetchProjectsByIdsGql,
  useFetchProjectParticipationInfo,
  useGetStatChartProjects,
  useGetComparableStatChartProjects,
} from "api/resources/projects/projects";
import { useFetchSurveyTags } from "api/resources/organization/surveytags";
import Chart from "components/Charts/Chart";
import { forEach } from "assets/functions/ArrayFunctions";
import { useFetchColorPaletteById } from "api/resources/organization/colorpalettes";
import {
  getColorsFromGradient,
  getColorsFromPalette,
  getComparisonColors,
} from "assets/functions/ColorFunctions";
import {
  AtoZ,
  High2Low,
  LineType,
  Low2High,
  NoSort,
  ParticipationCount,
  ReverseSort,
  ZtoA,
  defaultColors,
} from "./QuestionChart";
import StatDataTable from "./Tables/StatDataTable";
import StatDrillTable from "./Tables/StatDrillTable";
import { splitting } from "./Settings/SettingsAccordion";
import { trimTimeDay } from "assets/functions/DateFunctions";

export const statBars = [
  // {
  //   label: "Response Rate",
  //   value: "responseRate",
  // },
  {
    label: "Audience Size",
    value: "participations",
  },
  {
    label: "Received Email",
    value: "received",
  },
  {
    label: "Bounced",
    value: "bounced",
  },
  {
    label: "Blocked",
    value: "blocked",
  },
  {
    label: "Received Invite, Never Took",
    value: "coulda",
  },
  {
    label: "Opened Email",
    value: "openedEmail",
  },
  {
    label: "Unsubscribers",
    value: "unsubbed",
  },
  {
    label: "Opened Email, Didn't Click",
    value: "didntClick",
  },
  {
    label: "Clicked Link",
    value: "openedSurvey",
  },
  {
    label: "Saw Survey, Didn't Participate",
    value: "nevermind",
  },
  {
    label: "Participated",
    value: "participated",
  },
  // {
  //   label: "Didn't Participate",
  //   value: "didNot",
  // },
  {
    label: "Hit Finish",
    value: "finished",
  },
];

export const emailBars = [
  "participations",
  "received",
  "blocked",
  "bounced",
  "openedEmail",
  "unsubbed",
  "openedSurvey",
  "participated",
  "didntClick",
];

export const defaultStatChartSettings = {
  answerType: "",
  hasAxisTitles: true,
  YAxisTitle: "Count",
  XAxisTitle: "",
  dynamics: {},
  onlyLinks: false,
  showBars: [
    {
      label: "Received invite, never took",
      value: "coulda",
    },
    {
      label: "Participated",
      value: "participated",
    },
  ],
};

export function getStatCodes(partStat) {
  let labels = ["participations"];
  if (partStat.stats.participated) {
    labels.push("participated");
  } else {
    labels.push("didNot");
    if (partStat.stats.receivedInvite) {
      labels.push("coulda");
    }
  }

  if (partStat.stats.received) {
    labels.push("received");

    if (partStat.stats.openedEmail) {
      labels.push("openedEmail");

      if (partStat.stats.unsubbed) {
        labels.push("unsubbed");
      }

      if (partStat.stats.receivedInvite) {
        if (partStat.participation.distIdUsed) {
          labels.push("openedSurvey");

          if (!partStat.stats.participated) {
            labels.push("nevermind");
          }
        } else {
          labels.push("didntClick");
        }
      }
    }
  } else {
    if (partStat.stats.blocked) {
      labels.push("blocked");
    }
    if (partStat.stats.bounced) {
      labels.push("bounced");
    }
  }

  if (partStat.participation.completed) {
    labels.push("finished");
  }
  return labels;
}

export function getEmailCodes(partStat, email, distributions) {
  let labels = ["participations"];
  if (email.status === "delivered") {
    labels.push("received");

    if (email.opens > 0) {
      labels.push("openedEmail");

      if (email.unsubbed) {
        labels.push("unsubbed");
      }

      if (partStat.participation.distIdUsed === email.distributionId) {
        labels.push("openedSurvey");

        if (partStat.stats.participated) {
          labels.push("participated");
        }
      } else if (
        distributions.some((d) => d.id === email.distributionId && d.hasLink)
      ) {
        labels.push("didntClick");
      }
    }
  } else if (email.status === "blocked") {
    labels.push("blocked");
  } else if (email.status === "bounce") {
    labels.push("bounced");
  }

  return labels;
}

export function passesOnlyLinks(partStat, distributions) {
  return (
    partStat.stats.receivedInvite ||
    partStat.participation.delivery.some(
      (e) => distributions.some((d) => d.id === e.distributionId && d.hasLink) // includes blocked or bounced survey invites
    )
  );
}

/**
 *
 * @param {*} noExpand For context of: viz belongs to a dash widget, they hit expand, can't expand normal way, don't want to expand again.
 * @returns
 */

export default function SurveyStatChart({
  viz,
  setOutsideData,
  setUpOutsideDataCounter,
  setLabelInfo,
  newChart,
  setAbove,
  thumbnail,
  custom_fields,
  update,
  height,
  reDraw,
  role,
  seeData,
  closeSeeData,
  filters,
  filterSubtitle,
  idAddOn,
  inEdit,
}) {
  const getProjects = useGetStatChartProjects(viz);

  const getComparableProjects = useGetComparableStatChartProjects(viz);

  return (
    <>
      {" "}
      {getProjects.isSuccess && getComparableProjects.isSuccess && (
        <StatChart
          viz={viz}
          projects={getProjects.data.survey}
          comparisonProjects={getComparableProjects.data.survey}
          setOutsideData={setOutsideData}
          setUpOutsideDataCounter={setUpOutsideDataCounter}
          setLabelInfo={setLabelInfo}
          newChart={newChart}
          setAbove={setAbove}
          custom_fields={custom_fields}
          thumbnail={thumbnail}
          update={update}
          height={height}
          reDraw={reDraw}
          role={role}
          seeData={seeData}
          closeSeeData={closeSeeData}
          idAddOn={idAddOn}
          filters={filters}
          filterSubtitle={filterSubtitle}
          inEdit={inEdit}
        />
      )}
    </>
  );
}

function StatChart({
  viz,
  projects,
  comparisonProjects,
  setOutsideData,
  setUpOutsideDataCounter,
  setLabelInfo,
  newChart,
  setAbove,
  thumbnail,
  custom_fields,
  update,
  height,
  reDraw,
  role,
  seeData,
  closeSeeData,
  filters,
  filterSubtitle,
  idAddOn,
  inEdit,
}) {
  const [data, setData] = useState(null);

  const fetchInfo = useFetchProjectParticipationInfo(
    projects.map((p) => p.id),
    filters,
    viz.designSettings?.dynamic
      ? viz.designSettings.userConstraints
      : undefined,
    setData
  );

  const fetchCompInfo = useFetchProjectParticipationInfo(
    comparisonProjects.map((p) => p.id),
    filters,
    viz.designSettings?.dynamic
      ? viz.designSettings.userConstraints
      : undefined,
    setData
  );

  const getSurveyTags = useFetchSurveyTags({
    tagIds: viz.tagIdsArray,
  });

  const colorPalette = useFetchColorPaletteById(
    viz.designSettings.paletteId ? viz.designSettings.paletteId : ""
  );

  const updateViz = useUpdateVisualization();

  const [drill, setDrill] = useState();
  const [showDrill, setShowDrill] = useState(false);
  const [N, setN] = useState(0);
  const [chartData, setChartData] = useState(false);

  useEffect(() => {
    setData(null);
  }, [viz]);

  useEffect(() => {
    if (setUpOutsideDataCounter) {
      if (setOutsideData && data) {
        setOutsideData({ ...data });
      }
    }
  }, [setUpOutsideDataCounter]);

  function getBlankBarMap() {
    let map = {};
    for (let bar of viz.designSettings.showBars) {
      map[bar.value] = 0;
    }
    return map;
  }

  const getFilteredContactFields = (contactField) => {
    let active = [];

    if (filters) {
      let chosenFilter = JSON.parse(filters);
      for (let key in chosenFilter) {
        if (chosenFilter[key].name === contactField) {
          for (let value of chosenFilter[key].properties) {
            active.push(value);
          }
        }
      }

      if (active.length > 0) {
        return active;
      }
    }
    let field = custom_fields.find((f) => f.name === contactField);
    if (!field || !field?.properties) {
      return [];
    }
    return [...field.properties];
  };

  function comparing() {
    return viz.designSettings.useComparison && comparisonProjects.length;
  }

  function getUndefinedLabel() {
    return viz.designSettings.undefinedLabel
      ? viz.designSettings.undefinedLabel
      : "Undefined";
  }

  function sortData(labels, answerTallies, colors) {
    let labelTallyMap = [];
    forEach(labels, (label, ind) =>
      labelTallyMap.push({
        label: label,
        tally: answerTallies[ind],
        color: colors[ind],
        orig: ind,
        sorted: ind,
      })
    );

    if (
      viz.designSettings?.segLabelReplacements &&
      Object.keys(viz.designSettings.segLabelReplacements).length > 0
    ) {
      forEach(labelTallyMap, (l) => {
        if (l.orig in viz.designSettings?.segLabelReplacements) {
          l.label = viz.designSettings.segLabelReplacements[l.orig];
          l.origLabel = labels[l.orig]; // So you can know what it was originally
          labels[l.orig] = l.label;
        }
      });
    }

    if (viz.designSettings.sortData !== NoSort) {
      if (viz.designSettings.sortData === ReverseSort) {
        labelTallyMap.reverse();
      } else {
        labelTallyMap.sort((a, b) => {
          if (viz.designSettings.sortData === High2Low) {
            return b.tally - a.tally;
          }
          if (viz.designSettings.sortData === Low2High) {
            return a.tally - b.tally;
          }

          if (viz.designSettings.sortData === AtoZ) {
            if (typeof a.label === "number" && typeof b.label === "number") {
              return a.label - b.label;
            }
            if (typeof a.label === "string" && typeof b.label === "string") {
              if (a.label.toLowerCase() < b.label.toLowerCase()) {
                return -1;
              }
              if (a.label.toLowerCase() > b.label.toLowerCase()) {
                return 1;
              }
              return 0;
            }
            if (typeof a.label === "number") {
              // numbers before letters
              return -1;
            }
            return 1;
          }
          if (viz.designSettings.sortData === ZtoA) {
            if (typeof a.label === "number" && typeof b.label === "number") {
              return b.label - a.label;
            }
            if (typeof a.label === "string" && typeof b.label === "string") {
              if (a.label.toLowerCase() < b.label.toLowerCase()) {
                return 1;
              }
              if (a.label.toLowerCase() > b.label.toLowerCase()) {
                return -1;
              }
              return 0;
            }
            if (typeof a.label === "number") {
              // numbers before letters
              return -1;
            }
            return 1;
          }
        });
      }

      forEach(labelTallyMap, (choice, ind) => {
        labels[ind] = choice.label;
        answerTallies[ind] = choice.tally;

        // if (viz.designSettings?.afterSort) {
        //   choice.color = colors[ind];
        // } else {
        //   colors[ind] = choice.color;
        // }
        if (viz.designSettings.colors && !viz.designSettings?.afterSort) {
          colors[ind] = choice.color;
        } else {
          choice.color = colors[ind];
        }

        choice.sorted = ind; // For Chart Settings
      });
    }

    if (setLabelInfo) {
      let labelInfo = {
        segLabels: labelTallyMap,
        axisLabels: labelTallyMap,
      };
      setLabelInfo(labelInfo);
    }
  }

  function changeToPercentData(answerTallies) {
    if (viz.designSettings.byPercentOfReceived) {
      let count = 0;
      for (let partStat of getPartStats()) {
        if (partStat.stats.receivedInvite) {
          count++;
        }
      }

      if (count) {
        // Don't apply to the blocked and bounced ones
        let bars = viz.designSettings.showBars;
        let audIndex = bars.findIndex((b) => b.value === "participations");
        let blockedIndex = bars.findIndex((b) => b.value === "blocked");
        let bouncedIndex = bars.findIndex((b) => b.value === "bounced");
        let partCount = getPartStats().length;

        for (let i = 0; i < answerTallies.length; i++) {
          if (i == audIndex) {
            answerTallies[i] = 100;
          } else if (i == blockedIndex || i == bouncedIndex) {
            answerTallies[i] = ((answerTallies[i] * 100) / partCount).toFixed(
              viz.designSettings.dataLabelValue.sigFigs
            );
          } else {
            answerTallies[i] = ((answerTallies[i] * 100) / count).toFixed(
              viz.designSettings.dataLabelValue.sigFigs
            );
          }
        }
      }
    } else {
      let partCount = getPartStats().length;
      if (partCount) {
        for (let i = 0; i < answerTallies.length; i++) {
          let portion = (answerTallies[i] / partCount) * 100;
          answerTallies[i] = portion.toFixed(
            viz.designSettings.dataLabelValue.sigFigs
          );
        }
      }
    }
  }

  function getDefaultColors(length) {
    let colors = [];
    let c = 0;
    for (let i = 0; i < length; i++) {
      colors[i] = defaultColors[c];
      c < defaultColors.length - 1 ? c++ : (c = 0);
    }
    return colors;
  }

  function getColors(num, data) {
    let compSimple = false;
    const palette = colorPalette.data.palette;
    let colors = [];
    if (viz.designSettings.colors) {
      let c = 0;
      let copy = viz.designSettings.colors;
      for (let i = 0; i < num; i++) {
        colors[i] = copy[c];
        c < copy.length - 1 ? c++ : (c = 0);
      }
    } else if (viz.designSettings.gradient) {
      let gradient = viz.designSettings.gradient;
      colors = getColorsFromGradient(gradient.anchors, gradient.blended, num);
    } else if (palette) {
      if (comparing() && !(num % 2)) {
        if (viz.pivotString || viz.designSettings.split) {
          let half = num / 2;
          let firstSet = getColorsFromPalette(palette, half);
          colors = getComparisonColors(firstSet);
        } else {
          compSimple = true;
          let actual = data.datasets[0].data.length;
          let origSet = getColorsFromPalette(palette, actual);
          colors = getComparisonColors(origSet);
        }
      } else {
        colors = getColorsFromPalette(palette, num);
      }
    } else {
      colors = getDefaultColors(num);
    }
    return [colors, compSimple];
  }

  function compileData(labels, answerTallies) {
    let data = JSON.parse(JSON.stringify(fakeData));

    let [colors] = getColors(labels.length);

    //Survey Tag Colors Display
    if (viz.pivotString === "survey tag" && viz.designSettings?.useTagColor) {
      for (let i = 0; i < labels.length; i++) {
        let tag = getSurveyTags.data.tags.find((t) => t.label === labels[i]);
        if (tag?.displayColor) {
          colors[i] = tag.displayColor;
        }
      }
    }

    sortData(labels, answerTallies, colors);

    if (viz.designSettings.byPercent) {
      changeToPercentData(answerTallies);
    }

    forEach(labels, (label, i) => {
      if (label === "Undefined") {
        labels[i] = getUndefinedLabel();
      }
    });

    data.labels = labels;
    data.datasets[0].backgroundColor = colors;
    data.datasets[0].data = answerTallies;
    data.datasets[0].borderRadius = viz.designSettings.borderRadius
      ? viz.designSettings.borderRadius
      : 0;
    data.datasets[0].borderSkipped = viz.designSettings.borderSkipped;

    data.labels = labels;

    return data;
  }

  function sortSplitData(data) {
    let segLabels = [];
    forEach(data.datasets, (dataset, i) =>
      segLabels.push({
        label: dataset.label,
        orig: i,
        sorted: i,
        color: dataset.backgroundColor[0],
      })
    );

    // add origLabel
    for (let l of segLabels) {
      if (
        viz.designSettings?.segLabelReplacements &&
        l.orig in viz.designSettings?.segLabelReplacements
      ) {
        l.label = viz.designSettings.segLabelReplacements[l.orig];
        l.origLabel = data.datasets[l.orig].label; // So you can know what it was originally
        data.datasets[l.orig].label = l.label; // Actually change it
      }
    }

    let axisLabels = [];
    if (data.labels) {
      forEach(data.labels, (label, i) =>
        axisLabels.push({
          label: label,
          orig: i,
          sorted: i,
        })
      );

      for (let l of axisLabels) {
        if (
          viz.designSettings?.axisLabelReplacements &&
          l.orig in viz.designSettings?.axisLabelReplacements
        ) {
          l.label = viz.designSettings.axisLabelReplacements[l.orig];
          l.origLabel = data.labels[l.orig]; // So you can know what it was originally
          data.labels[l.orig] = l.label; // Actually change it
        }
      }
    }
    if (setLabelInfo) {
      let labelsInfo = {
        segLabels: segLabels,
        axisLabels: axisLabels,
      };
      setLabelInfo(labelsInfo);
    }

    let labelMap = {};
    for (let i = 0; i < data.labels.length; i++) {
      labelMap[data.labels[i]] = i;
    }

    if (viz.designSettings.sortData !== NoSort) {
      if (viz.designSettings.sortData === ReverseSort) {
        data.labels.reverse();
      } else {
        data.labels.sort((a, b) => {
          if (viz.designSettings.sortData === AtoZ) {
            if (typeof a === "number" && typeof b === "number") {
              return a - b;
            }
            if (typeof a === "string" && typeof b === "string") {
              if (a.toLowerCase() < b.toLowerCase()) {
                return -1;
              }
              if (a.toLowerCase() > b.toLowerCase()) {
                return 1;
              }
              return 0;
            }
            if (typeof a === "number") {
              // numbers before letters
              return -1;
            }
            return 1;
          }
          if (viz.designSettings.sortData === ZtoA) {
            if (typeof a === "number" && typeof b === "number") {
              return b - a;
            }
            if (typeof a === "string" && typeof b === "string") {
              if (a.toLowerCase() < b.toLowerCase()) {
                return 1;
              }
              if (a.toLowerCase() > b.toLowerCase()) {
                return -1;
              }
              return 0;
            }
            if (typeof a === "number") {
              // numbers before letters
              return -1;
            }
            return 1;
          }
          return 0;
        });
      }

      // axisLabels.forEach((one, i) => (one.sorted = i));

      let order = data.labels.map((label) => labelMap[label]);

      for (let set of data.datasets) {
        let copy = [];
        forEach(order, (num, ind) => {
          copy[ind] = set.data[num];
        });
        set.data = copy;
      }
      // data.labels = axisLabels.map(l => l.label);
    }
  }

  function changeToPercentOnSplitData(data, axisLabels) {
    // if (viz.designSettings.byPercentOfTotal) {
    //   for (let dataset of data.datasets) {
    //     let sum = 0;
    //     let tallyArray = dataset.data;
    //     forEach(tallyArray, (tally) => (sum += tally));
    //     for (let i = 0; i < tallyArray.length; i++) {
    //       tallyArray[i] = ((tallyArray[i] * 100) / sum).toFixed(
    //         viz.designSettings.dataLabelValue.sigFigs
    //       );
    //     }
    //   }
    // }

    for (let i = 0; i < axisLabels.length; i++) {
      let pivotCount = 0;
      for (let dataset of data.datasets) {
        pivotCount += dataset.data[i];
      }

      for (let dataset of data.datasets) {
        let portion = (dataset.data[i] / pivotCount) * 100;
        dataset.data[i] = portion.toFixed(
          viz.designSettings.dataLabelValue.sigFigs
        );
      }
    }
  }

  function bindColorsOnSplitData(data) {
    let [colors, compMany] = getColors(data.datasets.length, data);

    //Survey Tag Colors Display
    if (
      viz.designSettings.split === "survey tag" &&
      viz.designSettings?.useTagColor
    ) {
      for (let i = 0; i < data.datasets.length; i++) {
        let tag = getSurveyTags.data.tags.find(
          (t) => t.label === data.datasets[i].label
        );
        if (tag?.displayColor) {
          colors[i] = tag.displayColor;
        }
      }
    }

    if (compMany) {
      let normal = [];
      let comp = [];
      for (let i = 0; i < colors.length; i += 2) {
        normal.push(colors[i]);
        comp.push(colors[i + 1]);
      }
      data.datasets[0].backgroundColor = normal;
      data.datasets[1].backgroundColor = comp;

      // for (let i = 0; i < data.datasets.length; i++) {
      //   data.datasets[i].backgroundColor = [colors[i]];
      //   data.datasets[i].borderWidth = 0;
      // }
    } else {
      for (let i = 0; i < data.datasets.length; i++) {
        data.datasets[i].backgroundColor = [colors[i]];
        data.datasets[i].borderWidth = 0;
      }
    }
  }

  function bindColorsAndSortData(labels, datasets) {
    let data = {
      labels: labels,
      datasets: datasets,
      stacked: viz.designSettings.stacked,
    };

    bindColorsOnSplitData(data);
    sortSplitData(data);
    if (viz.designSettings.byPercent) {
      changeToPercentOnSplitData(data, labels);
    }

    return data;
  }

  function compileSplitData(legendLabels, answerMap, axisLabels) {
    let datasets = [];

    // let compLabel = comparisonLabel();

    // if (viz.designSettings.includeOverall) {
    //   let spot = legendLabels.indexOf("Overall");
    //   legendLabels.splice(spot, 1);
    //   legendLabels.push("Overall");

    //   if (comparing()) {
    //     spot = legendLabels.indexOf(compLabel + " | Overall");
    //     legendLabels.splice(spot, 1);
    //     legendLabels.push(compLabel + " | Overall");
    //   }
    // }

    for (let i = 0; i < legendLabels.length; i++) {
      let seg = legendLabels[i];
      let data = [];

      for (let label of axisLabels) {
        if (seg in answerMap && label in answerMap[seg]) {
          data.push(answerMap[seg][label]);
        } else {
          data.push(null);
        }
      }

      if (seg === "Undefined") {
        seg = getUndefinedLabel();
      }

      // if (
      //   viz.designSettings.includeOverall &&
      //   viz.designSettings.overallLabel
      // ) {
      //   if (seg === "Overall") {
      //     seg = viz.designSettings.overallLabel;
      //   } else if (seg === compLabel + " | Overall") {
      //     seg = compLabel + " | " + viz.designSettings.overallLabel;
      //   }
      // }

      datasets.push({
        label: seg,
        data: data,
      });
    }

    if (viz.designSettings.showUndefined) {
      let ind = axisLabels.indexOf("Undefined");
      if (ind > -1) {
        axisLabels[ind] = getUndefinedLabel();
      }
    }

    let data = bindColorsAndSortData(axisLabels, datasets);
    return data;
  }

  function comparisonLabel() {
    return viz.designSettings.comparisonLabel
      ? viz.designSettings.comparisonLabel
      : "Comparison";
  }

  function compileComparedData(statMap, compMap, axisLabels) {
    let chosen = viz.designSettings.chosenLabel
      ? viz.designSettings.chosenLabel
      : "Chosen";
    let comp = comparisonLabel();
    let combinedMap = {};
    combinedMap[chosen] = statMap;
    combinedMap[comp] = compMap;
    return compileSplitData([chosen, comp], combinedMap, axisLabels);
  }

  function compileSplitComparedData(
    legendLabels,
    statMap,
    axisLabels,
    compMap
  ) {
    let comparedLabel = comparisonLabel();
    let combinedMap = { ...statMap };
    let combinedLabels = [];
    for (let label of legendLabels) {
      combinedLabels.push(label);
      let newLabel = comparedLabel + " | " + label;
      combinedLabels.push(newLabel);
      combinedMap[newLabel] = compMap[label];
    }

    return compileSplitData(combinedLabels, combinedMap, axisLabels);
  }

  function findAssociatedTag(part) {
    for (let tag of getSurveyTags.data.tags) {
      let found = tag.project.some((p) => p.id === part.projectId);
      if (found) {
        return tag.label;
      }
    }
    return "Undefined";
  }

  function settleCustomFields(contact) {
    if (contact.customField) {
      let customFields = JSON.parse(contact.customField);
      while (typeof customFields === "string") {
        customFields = JSON.parse(customFields);
      }
      for (let cField in customFields) {
        contact[cField] = customFields[cField];
      }
      delete contact.customFields;
    }
  }

  function getProjectsInOrder() {
    let surveys = [...projects];
    if (comparing()) {
      surveys = [...surveys, ...comparisonProjects];
    }

    surveys.sort((a, b) => {
      //Sort the projects by survey date
      if (a.startedAt === null || b.startedAt === null) {
        return 0;
      }
      let aDate = new Date(a.startedAt);
      let bDate = new Date(b.startedAt);
      let results = aDate - bDate;
      return results;
    });

    return surveys;
  }

  function getSurveyLabelFromPart(val, part) {
    if (val === "survey tag") {
      return findAssociatedTag(part);
    }
    let projID = part?.projectId;
    let proj = projects.find((s) => s.id === projID);
    if (!proj && comparing()) {
      proj = comparisonProjects.find((s) => s.id === projID);
    }
    return getSurveyLabel(val, proj);
  }

  // passes a pivotBySurvey for speed, to avoid that calculation every time
  function getPivotLabels(part) {
    let label = "";
    let isField = false;
    if (viz.pivotString.includes("survey")) {
      label = getSurveyLabelFromPart(viz.pivotString, part);
    } else if (viz.pivotString === "month taken") {
      label = getMonthTaken(part);
    } else {
      let contact = part.contact ? { ...part.contact } : null;
      if (contact) {
        settleCustomFields(contact);
        label = contact[viz.pivotString];
        isField = true;
      }
    }

    if (!label) {
      label = "Undefined";
    }

    if (isField) {
      let delimiter = custom_fields.find(
        (field) => field.name === viz.pivotString
      )?.delimiter;
      if (delimiter) {
        label = label.split(delimiter);
      } else {
        label = [label];
      }
    } else {
      label = [label];
    }

    return label;
  }

  // passes a splitBySurvey for speed, to avoid that calculation every time
  function getSplitLabels(part) {
    let label = "";
    let isField = false;
    if (viz.designSettings.split.includes("survey")) {
      label = getSurveyLabelFromPart(viz.designSettings.split, part);
    } else if (viz.designSettings.split === "month taken") {
      label = getMonthTaken(part);
    } else {
      let contact = part.contact ? { ...part.contact } : null;
      if (contact) {
        settleCustomFields(contact);
        label = contact[viz.designSettings.split];
        isField = true;
      }
    }

    if (!label) {
      label = "Undefined";
    }

    if (isField) {
      let delimiter = custom_fields.find(
        (field) => field.name === viz.designSettings.split
      )?.delimiter;
      if (delimiter) {
        label = label.split(delimiter);
      } else {
        label = [label];
      }
    } else {
      label = [label];
    }

    if (viz.designSettings.includeOverall) {
      label.push("Overall");
    }

    return label;
  }

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "December",
  ];

  function getMonthTaken(answer) {
    if (!answer.updatedAt) {
      return "Undefined";
    }
    let date = new Date(answer.updatedAt);

    let month = months[date.getMonth()];
    let year = date.getFullYear();
    return month + " " + year;
  }

  function getHourTaken(answer) {
    if (!answer.createdAt) {
      return "Undefined";
    }
    let date = new Date(answer.createdAt);
    date.setMinutes(0, 0, 0);
    return trimTimeDay(date, true);
  }

  function getMonths() {
    let map = {};
    for (let answer of fetchAnswers.data.answers) {
      let label = getMonthTaken(answer);
      if (!map[label]) {
        map[label] = true;
      }
    }
    return Object.keys(map);
  }

  function getHours() {
    let map = {};
    for (let answer of fetchAnswers.data.answers) {
      let label = getHourTaken(answer);
      if (!map[label]) {
        map[label] = true;
      }
    }
    return Object.keys(map);
  }

  function findTagFromSurvey(proj) {
    for (let tag of getSurveyTags.data.tags) {
      let found = tag.project.some((p) => p.id === proj.id);
      if (found) {
        return tag.label;
      }
    }
    return "Undefined";
  }

  function getSurveyLabel(val, survey) {
    if (!survey) {
      return "Undefined";
    }
    if (val === "survey") {
      return survey.name;
    }
    if (val === "survey tag") {
      return findTagFromSurvey(survey);
    }
    if (val === "survey date" || val === "survey quarter") {
      let label = survey.name;
      if (survey.startedAt) {
        let date = new Date(survey.startedAt);
        let month = date.toDateString().substring(4, 7);
        let year = date.toDateString().substring(11, 15);
        label = month + " " + year;
        if (val === "survey quarter") {
          if (month === "Jan" || month === "Feb" || month === "Mar") {
            label = "Q1 " + year;
          } else if (month === "Apr" || month === "May" || month === "Jun") {
            label = "Q2 " + year;
          } else if (month === "Jul" || month === "Aug" || month === "Sep") {
            label = "Q3 " + year;
          } else if (month === "Oct" || month === "Nov" || month === "Dec") {
            label = "Q4 " + year;
          }
        }
      }
      return label;
    }
  }

  function getLabelsFor(val) {
    let labels = [];

    if (val.includes("survey")) {
      let onlyTheseSurveyIds = [];
      if (filters) {
        let chosenFilter = JSON.parse(filters);
        if (chosenFilter.surveys) {
          onlyTheseSurveyIds = chosenFilter.surveys.map((s) => s.id);
        }
      }

      let projects = getProjectsInOrder();
      for (let survey of projects) {
        if (
          onlyTheseSurveyIds.length &&
          !onlyTheseSurveyIds.includes(survey.id)
        ) {
          continue;
        }

        let label = getSurveyLabel(val, survey);
        if (!labels.includes(label)) {
          labels.push(label);
        }
      }
    } else if (val === "month taken") {
      labels = getMonths();
    }else if (val === "hour taken") {
      labels = getHours();
    } else {
      labels = getFilteredContactFields(val);
    }

    if (val === viz.designSettings.split && viz.designSettings.includeOverall) {
      labels.push("Overall");
    }

    return labels;
  }

  function sortDistributions(distributions) {
    let all = [...distributions];
    all.sort((a, b) => {
      let aSent = new Date(a.sentDate);
      let bSent = new Date(b.sentDate);

      return aSent - bSent;
    });

    if (viz.designSettings.onlyLinks) {
      let keeping = [];
      for (let dist of all) {
        if (dist.hasLink) {
          keeping.push(dist);
        }
      }
      all = keeping;
    }

    return all;
  }
  function getDistributionsInOrder() {
    return sortDistributions(fetchInfo.data?.info?.distributions);
  }

  function getCompDistributionsInOrder() {
    return sortDistributions(fetchCompInfo.data?.info?.distributions);
  }

  function getStatLabels(partStat, barMap) {
    let keeping = [];

    if (viz.designSettings.onlyLinks) {
      if (!passesOnlyLinks(partStat, fetchInfo.data?.info?.distributions)) {
        return [];
      }
    }

    for (let bar of getStatCodes(partStat)) {
      if (bar in barMap) {
        keeping.push(bar);
      }
    }
    return keeping;
  }

  function getEmailLabels(partStat, email, barMap) {
    let keeping = [];

    let dists = [
      ...fetchInfo.data?.info?.distributions,
      ...fetchCompInfo.data?.info?.distributions,
    ];

    for (let bar of getEmailCodes(partStat, email, dists)) {
      if (bar in barMap) {
        keeping.push(bar);
      }
    }

    return keeping;
  }

  function getPartStats() {
    return fetchInfo.data?.info?.partStats;
  }

  function getComparablePartStats() {
    return fetchCompInfo.data?.info?.partStats;
  }

  function getComparedDistLabels() {
    let normal = getDistributionsInOrder();
    let compDists = getCompDistributionsInOrder();

    let list = [];
    let labelMap = {};
    let listNames = [];
    let hold = {};

    let allProjects = [...projects, ...comparisonProjects];
    for (let project of allProjects) {
      hold[project.id] = [];
    }

    for (let d of [...normal, ...compDists]) {
      hold[d.projectId].push(d);
    }

    let sends = [];

    while (Object.keys(hold).length) {
      sends.push([]);
      for (let project of allProjects) {
        if (hold[project.id]) {
          let d = hold[project.id].shift();
          sends[sends.length - 1].push(d);
          if (!hold[project.id].length) {
            delete hold[project.id];
          }
        }
      }
    }

    for (let i = 0; i < sends.length; i++) {
      let label = `Send ${i + 1}`;
      list.push(label);
      let names = [];
      for (let d of sends[i]) {
        labelMap[d.id] = label;
        if (
          normal.some((dist) => dist.id === d.id) &&
          !names.includes(d.name)
        ) {
          names.push(d.name);
        }
      }

      let title = "";
      for (let name of names) {
        if (title) {
          title += " / ";
        }
        title += name;
      }

      let fullLabel = title ? label + " (" + title + ")" : label;
      listNames.push(fullLabel);
    }

    return [list, labelMap, listNames];
  }

  function getDistLabels() {
    let distributions = getDistributionsInOrder();

    let list = [];
    let labelMap = {};
    let listNames = [];
    if (projects.length > 1) {
      let hold = {};
      for (let project of projects) {
        hold[project.id] = [];
      }

      for (let d of distributions) {
        hold[d.projectId].push(d);
      }

      let sends = [];

      while (Object.keys(hold).length) {
        sends.push([]);
        for (let project of projects) {
          if (hold[project.id]) {
            let d = hold[project.id].shift();
            sends[sends.length - 1].push(d);
            if (!hold[project.id].length) {
              delete hold[project.id];
            }
          }
        }
      }

      for (let i = 0; i < sends.length; i++) {
        let label = `Send ${i + 1}`;
        list.push(label);

        let names = [];
        for (let d of sends[i]) {
          labelMap[d.id] = label;
          if (!names.includes(d.name)) {
            names.push(d.name);
          }
        }

        let title = "";
        for (let name of names) {
          if (title) {
            title += " / ";
          }
          title += name;
        }
        listNames.push(title);
      }
    } else {
      for (let i = 0; i < distributions.length; i++) {
        let label = `Send ${i + 1}`;
        list.push(label);
        labelMap[distributions[i].id] = label;
        listNames.push(distributions[i].name);
      }
    }
    return [list, labelMap, listNames];
  }

  function pivotAndSplitAllBars() {
    let pivots = getLabelsFor(viz.pivotString);
    let splits = getLabelsFor(viz.designSettings.split);
    let bars = viz.designSettings.showBars.map((b) => b.value);

    let blankData = {};
    for (let p of pivots) {
      blankData[p] = 0;
    }

    let map = {};
    let labelMap = {};
    for (let split of splits) {
      for (let b of bars) {
        map[b + split] = { ...blankData };
        labelMap[b + split] = split;
        labelMap[split] = b + split;
      }
    }

    const barMap = getBlankBarMap();
    for (let partStat of fetchInfo.data.participations) {
      let barFields = getStatLabels(partStat, barMap);
      let splitFields = getSplitLabels(partStat.participation);
      let pivotFields = getPivotLabels(partStat.participation);
      for (let bar of barFields) {
        for (let split of splitFields) {
          let dataset = bar + split;
          if (!(dataset in map)) {
            if (viz.designSettings.showUndefined && split === "Undefined") {
              for (let b of bars) {
                map[b + split] = { ...blankData };
              }
              splits.push(split);
            } else {
              continue;
            }
          }

          for (let pivot of pivotFields) {
            if (!(pivot in map[dataset])) {
              if (viz.designSettings.showUndefined && pivot === "Undefined") {
                pivots.push(pivot);
                for (let l in map) {
                  map[l][pivot] = 0;
                }
              } else {
                continue;
              }
            }
            map[dataset][pivot]++;
          }
        }
      }
    }

    if (!viz.designSettings?.showNonParticipating) {
      let keeping = [];
      for (let pivot of pivots) {
        for (let b of bars) {
          let there = false;
          for (let s of splits) {
            if (map[b + s][pivot]) {
              keeping.push(pivot);
              there = true;
              break;
            }
          }
          if (there) break;
        }
      }
      pivots = keeping;

      let keepingSplits = [];
      for (let split of splits) {
        for (let b of bars) {
          let there = false;
          for (let p of pivots) {
            if (map[b + split][p]) {
              keepingSplits.push(split);
              there = true;
              break;
            }
          }
          if (there) break;
        }
      }
      splits = keepingSplits;
    }

    let legendLabels = [];
    let barLabels = viz.designSettings.showBars.map((b) => b.label);
    for (let split of splits) {
      for (let i = 0; i < bars.length; i++) {
        let newLabel = split + " | " + barLabels[i];
        map[newLabel] = map[bars[i] + split];
        delete map[bars[i] + split];
        legendLabels.push(newLabel);
      }
    }

    return compileSplitData(legendLabels, map, pivots);
  }

  function pivotEmailAndSplitCompared() {
    let [emails, emailMap, emailNames] = getComparedDistLabels();

    let splits = getLabelsFor(viz.designSettings.split);

    let blankData = {};
    for (let e of emails) {
      blankData[e] = 0;
    }

    let statMap = {};
    for (let p of splits) {
      statMap[p] = { ...blankData };
    }
    let compMap = JSON.parse(JSON.stringify(statMap));

    let bar = viz.designSettings.answerType;
    let barMap = {};
    barMap[bar] = 1;

    function putInStats(partStat, map) {
      let splitFields = getSplitLabels(partStat.participation);
      for (let email of partStat.participation?.delivery) {
        let emailFields = getEmailLabels(partStat, email, barMap);
        if (emailFields.includes(bar)) {
          for (let split of splitFields) {
            if (!(split in map)) {
              if (viz.designSettings.showUndefined && split === "Undefined") {
                splits.push(split);
                statMap[split] = { ...blankData };
                compMap[split] = { ...blankData };
              } else {
                continue;
              }
            }
            map[split][emailMap[email.distributionId]]++;
          }
        }
      }
    }

    for (let partStat of getPartStats()) {
      putInStats(partStat, statMap);
    }
    for (let partStat of getComparablePartStats()) {
      putInStats(partStat, compMap);
    }

    for (let split of splits) {
      for (let e of emails) {
        compMap[split][e] = Math.round(
          compMap[split][e] / comparisonProjects.length
        );
      }
    }

    if (!viz.designSettings?.showNonParticipating) {
      let keeping = [];
      for (let split of splits) {
        for (let e of emails) {
          if (statMap[split][e] || compMap[split][e]) {
            keeping.push(split);
            break;
          }
        }
      }
      splits = keeping;
    }

    let data = compileSplitComparedData(splits, statMap, emails, compMap);

    for (let i = 0; i < data.labels.length; i++) {
      data.labels[i] = emailNames[i];
    }

    return data;
  }

  function pivotEmailAndSplit() {
    if (comparing()) {
      return pivotEmailAndSplitCompared();
    }
    let [emails, emailMap, emailNames] = getDistLabels();

    let splits = getLabelsFor(viz.designSettings.split);

    let blankData = {};
    for (let e of emails) {
      blankData[e] = 0;
    }

    let map = {};
    for (let p of splits) {
      map[p] = { ...blankData };
    }

    let bar = viz.designSettings.answerType;
    let barMap = {};
    barMap[bar] = 1;

    for (let partStat of getPartStats()) {
      let splitFields = getSplitLabels(partStat.participation);
      for (let email of partStat.participation?.delivery) {
        let emailFields = getEmailLabels(partStat, email, barMap);
        if (emailFields.includes(bar)) {
          for (let split of splitFields) {
            if (!(split in map)) {
              if (viz.designSettings.showUndefined && split === "Undefined") {
                splits.push(split);
                map[split] = { ...blankData };
              } else {
                continue;
              }
            }
            map[split][emailMap[email.distributionId]]++;
          }
        }
      }
    }

    if (!viz.designSettings?.showNonParticipating) {
      let keeping = [];
      for (let split of splits) {
        for (let e of emails) {
          if (map[split][e]) {
            keeping.push(split);
            break;
          }
        }
      }
      splits = keeping;
    }

    let data = compileSplitData(splits, map, emails);

    for (let i = 0; i < data.labels.length; i++) {
      data.labels[i] = emailNames[i];
    }

    return data;
  }

  function pivotAndSplitEmailCompared() {
    let [emails, emailMap, emailNames] = getComparedDistLabels();

    let pivots = getLabelsFor(viz.pivotString);

    let blankData = {};
    for (let p of pivots) {
      blankData[p] = 0;
    }

    let statMap = {};
    for (let e of emails) {
      statMap[e] = { ...blankData };
    }
    let compMap = JSON.parse(JSON.stringify(statMap));

    let bar = viz.designSettings.answerType;
    let barMap = {};
    barMap[bar] = 1;

    function putInStats(partStat, map) {
      let pivotFields = getPivotLabels(partStat.participation);
      for (let email of partStat.participation?.delivery) {
        let emailFields = getEmailLabels(partStat, email, barMap);
        if (emailFields.includes(bar)) {
          for (let pivot of pivotFields) {
            if (!(pivot in map[emailMap[email.distributionId]])) {
              if (viz.designSettings.showUndefined && pivot === "Undefined") {
                pivots.push(pivot);
                for (let e of emails) {
                  statMap[e][pivot] = 0;
                  compMap[e][pivot] = 0;
                }
              } else {
                continue;
              }
            }
            map[emailMap[email.distributionId]][pivot]++;
          }
        }
      }
    }

    for (let partStat of getPartStats()) {
      putInStats(partStat, statMap);
    }
    for (let partStat of getComparablePartStats()) {
      putInStats(partStat, compMap);
    }

    for (let e of emails) {
      for (let p of pivots) {
        compMap[e][p] = Math.round(compMap[e][p] / comparisonProjects.length);
      }
    }

    if (!viz.designSettings?.showNonParticipating) {
      let keeping = [];
      for (let pivot of pivots) {
        for (let e of emails) {
          if (statMap[e][pivot] || compMap[e][pivot]) {
            keeping.push(pivot);
            break;
          }
        }
      }
      pivots = keeping;
    }

    let data = compileSplitComparedData(emails, statMap, pivots, compMap);

    for (let i = 0; i < data.datasets.length; i++) {
      data.datasets[i].label = data.datasets[i].label.replace(
        emails[i],
        emailNames[i]
      );
    }

    return data;
  }

  function pivotAndSplitEmail() {
    if (comparing()) {
      return pivotAndSplitEmailCompared();
    }
    let [emails, emailMap, emailNames] = getDistLabels();

    let pivots = getLabelsFor(viz.pivotString);

    let blankData = {};
    for (let p of pivots) {
      blankData[p] = 0;
    }

    let map = {};
    for (let e of emails) {
      map[e] = { ...blankData };
    }

    let bar = viz.designSettings.answerType;
    let barMap = {};
    barMap[bar] = 1;

    for (let partStat of getPartStats()) {
      let pivotFields = getPivotLabels(partStat.participation);
      for (let email of partStat.participation?.delivery) {
        let emailFields = getEmailLabels(partStat, email, barMap);
        if (emailFields.includes(bar)) {
          for (let pivot of pivotFields) {
            if (!(pivot in map[emailMap[email.distributionId]])) {
              if (viz.designSettings.showUndefined && pivot === "Undefined") {
                pivots.push(pivot);
                for (let e of emails) {
                  map[e][pivot] = 0;
                }
              } else {
                continue;
              }
            }
            map[emailMap[email.distributionId]][pivot]++;
          }
        }
      }
    }

    if (!viz.designSettings?.showNonParticipating) {
      let keeping = [];
      for (let pivot of pivots) {
        for (let e of emails) {
          if (map[e][pivot]) {
            keeping.push(pivot);
            break;
          }
        }
      }
      pivots = keeping;
    }

    let data = compileSplitData(emails, map, pivots);

    for (let i = 0; i < data.datasets.length; i++) {
      data.datasets[i].label = emailNames[i];
    }

    return data;
  }

  function pivotAndSplitCompared() {
    let pivots = getLabelsFor(viz.pivotString);
    let splits = getLabelsFor(viz.designSettings.split);

    let blankData = {};
    for (let p of pivots) {
      blankData[p] = 0;
    }

    let statMap = {};
    let compMap = {};
    for (let s of splits) {
      statMap[s] = { ...blankData };
      compMap[s] = { ...blankData };
    }

    let bar = viz.designSettings.answerType;
    let barMap = {};
    barMap[bar] = 1;

    function putInStats(partStat, map) {
      let barFields = getStatLabels(partStat, barMap);
      if (barFields.includes(bar)) {
        let splitFields = getSplitLabels(partStat.participation);
        let pivotFields = getPivotLabels(partStat.participation);
        for (let split of splitFields) {
          if (!(split in map)) {
            if (viz.designSettings.showUndefined && split === "Undefined") {
              statMap[split] = {};
              compMap[split] = {};
              for (let p of pivots) {
                statMap[split][p] = 0;
                compMap[split][p] = 0;
              }
              splits.push(split);
            } else {
              continue;
            }
          }

          for (let pivot of pivotFields) {
            if (!(pivot in map[split])) {
              if (viz.designSettings.showUndefined && pivot === "Undefined") {
                pivots.push(pivot);
                for (let s of splits) {
                  statMap[s][pivot] = 0;
                  compMap[s][pivot] = 0;
                }
              } else {
                continue;
              }
            }
            map[split][pivot]++;
          }
        }
      }
    }

    for (let partStat of getPartStats()) {
      putInStats(partStat, statMap);
    }
    for (let partStat of getComparablePartStats()) {
      putInStats(partStat, compMap);
    }

    for (let s of splits) {
      for (let p of pivots) {
        compMap[s][p] = Math.round(compMap[s][p] / comparisonProjects.length);
      }
    }

    if (!viz.designSettings?.showNonParticipating) {
      let keeping = [];
      for (let pivot of pivots) {
        for (let s of splits) {
          if (statMap[s][pivot] || compMap[s][pivot]) {
            keeping.push(pivot);
            break;
          }
        }
      }
      pivots = keeping;

      let keepingSplits = [];
      for (let split of splits) {
        for (let p of pivots) {
          if (statMap[split][p] || compMap[split][p]) {
            keepingSplits.push(split);
            break;
          }
        }
      }
      splits = keepingSplits;
    }

    return compileSplitComparedData(splits, statMap, pivots, compMap);
  }

  function pivotAndSplit() {
    if (comparing()) {
      return pivotAndSplitCompared();
    }
    // return pivotAndSplitAllBars();
    let pivots = getLabelsFor(viz.pivotString);
    let splits = getLabelsFor(viz.designSettings.split);

    let blankData = {};
    for (let p of pivots) {
      blankData[p] = 0;
    }

    let map = {};
    for (let s of splits) {
      map[s] = { ...blankData };
    }

    let bar = viz.designSettings.answerType;
    let barMap = {};
    barMap[bar] = 1;

    for (let partStat of getPartStats()) {
      let barFields = getStatLabels(partStat, barMap);
      if (barFields.includes(bar)) {
        let splitFields = getSplitLabels(partStat.participation);
        let pivotFields = getPivotLabels(partStat.participation);
        for (let split of splitFields) {
          if (!(split in map)) {
            if (viz.designSettings.showUndefined && split === "Undefined") {
              map[split] = {};
              for (let p of pivots) {
                map[split][p] = 0;
              }
              splits.push(split);
            } else {
              continue;
            }
          }

          for (let pivot of pivotFields) {
            if (!(pivot in map[split])) {
              if (viz.designSettings.showUndefined && pivot === "Undefined") {
                pivots.push(pivot);
                for (let s of splits) {
                  map[s][pivot] = 0;
                }
              } else {
                continue;
              }
            }
            map[split][pivot]++;
          }
        }
      }
    }

    if (!viz.designSettings?.showNonParticipating) {
      let keeping = [];
      for (let pivot of pivots) {
        for (let s of splits) {
          if (map[s][pivot]) {
            keeping.push(pivot);
            break;
          }
        }
      }
      pivots = keeping;

      let keepingSplits = [];
      for (let split of splits) {
        for (let p of pivots) {
          if (map[split][p]) {
            keepingSplits.push(split);
            break;
          }
        }
      }
      splits = keepingSplits;
    }

    return compileSplitData(splits, map, pivots);
  }

  function splitEmailCompared() {
    let [emails, emailMap, emailNames] = getComparedDistLabels();

    let bars = viz.designSettings.showBars
      .map((b) => b.value)
      .filter((b) => emailBars.includes(b));

    let barMap = {};
    for (let b of bars) {
      barMap[b] = 0;
    }
    let statMap = {};
    for (let e of emails) {
      statMap[e] = { ...barMap };
    }
    let compMap = JSON.parse(JSON.stringify(statMap));

    function putInStats(partStat, map) {
      for (let email of partStat.participation.delivery) {
        let barFields = getEmailLabels(partStat, email, barMap);
        for (let bar of barFields) {
          map[emailMap[email.distributionId]][bar]++;
        }
      }
    }

    for (let partStat of getPartStats()) {
      putInStats(partStat, statMap);
    }
    for (let partStat of getComparablePartStats()) {
      putInStats(partStat, compMap);
    }

    for (let e of emails) {
      for (let b of bars) {
        compMap[e][b] = Math.round(compMap[e][b] / comparisonProjects.length);
      }
    }

    for (let i = 0; i < bars.length; i++) {
      let label = viz.designSettings.showBars.find(
        (b) => b.value === bars[i]
      ).label;

      for (let e of emails) {
        statMap[e][label] = statMap[e][bars[i]];
        delete statMap[e][bars[i]];

        compMap[e][label] = compMap[e][bars[i]];
        delete compMap[e][bars[i]];
      }
      bars[i] = label;
    }

    let data = compileSplitComparedData(emails, statMap, bars, compMap);

    for (let i = 0; i < data.datasets.length; i++) {
      data.datasets[i].label = data.datasets[i].label.replace(
        emails[i],
        emailNames[i]
      );
    }

    return data;
  }

  function pivotEmailCompared() {
    let [emails, emailMap, emailNames] = getComparedDistLabels();

    let bars = viz.designSettings.showBars
      .map((b) => b.value)
      .filter((b) => emailBars.includes(b));

    let statMap = {};
    for (let b of bars) {
      statMap[b] = {};
      for (let e of emails) {
        statMap[b][e] = 0;
      }
    }
    let compMap = JSON.parse(JSON.stringify(statMap));

    function putInStats(partStat, map) {
      for (let email of partStat.participation.delivery) {
        let barFields = getEmailLabels(partStat, email, map);
        for (let bar of barFields) {
          map[bar][emailMap[email.distributionId]]++;
        }
      }
    }

    for (let partStat of getPartStats()) {
      putInStats(partStat, statMap);
    }
    for (let partStat of getComparablePartStats()) {
      putInStats(partStat, compMap);
    }

    for (let b of bars) {
      for (let e of emails) {
        compMap[b][e] = Math.round(compMap[b][e] / comparisonProjects.length);
      }
    }

    for (let i = 0; i < bars.length; i++) {
      let label = viz.designSettings.showBars.find(
        (b) => b.value === bars[i]
      ).label;
      for (let map of [statMap, compMap]) {
        map[label] = map[bars[i]];
        delete Map[bars[i]];
      }

      bars[i] = label;
    }

    let data = compileSplitComparedData(bars, statMap, emails, compMap);

    for (let i = 0; i < data.labels.length; i++) {
      data.labels[i] = emailNames[i];
    }

    return data;
  }

  function splitEmail() {
    if (comparing()) {
      return splitEmailCompared();
    }
    let [emails, emailMap, emailNames] = getDistLabels();

    let bars = viz.designSettings.showBars
      .map((b) => b.value)
      .filter((b) => emailBars.includes(b));

    let barMap = {};
    for (let b of bars) {
      barMap[b] = 0;
    }
    let map = {};
    for (let e of emails) {
      map[e] = { ...barMap };
    }

    for (let partStat of getPartStats()) {
      for (let email of partStat.participation.delivery) {
        let barFields = getEmailLabels(partStat, email, barMap);
        for (let bar of barFields) {
          map[emailMap[email.distributionId]][bar]++;
        }
      }
    }

    for (let i = 0; i < bars.length; i++) {
      let label = viz.designSettings.showBars.find(
        (b) => b.value === bars[i]
      ).label;

      for (let e of emails) {
        map[e][label] = map[e][bars[i]];
        delete map[e][bars[i]];
      }
      bars[i] = label;
    }

    let data = compileSplitData(emails, map, bars);

    for (let i = 0; i < data.datasets.length; i++) {
      data.datasets[i].label = emailNames[i];
    }

    return data;
  }

  function pivotEmail() {
    if (viz.designSettings.split) {
      return pivotEmailAndSplit();
    }
    if (comparing()) {
      return pivotEmailCompared();
    }

    let [emails, emailMap, emailNames] = getDistLabels();

    let bars = viz.designSettings.showBars
      .map((b) => b.value)
      .filter((b) => emailBars.includes(b));

    let map = {};
    for (let b of bars) {
      map[b] = {};
      for (let e of emails) {
        map[b][e] = 0;
      }
    }

    for (let partStat of getPartStats()) {
      for (let email of partStat.participation.delivery) {
        let barFields = getEmailLabels(partStat, email, map);
        for (let bar of barFields) {
          map[bar][emailMap[email.distributionId]]++;
        }
      }
    }

    for (let i = 0; i < bars.length; i++) {
      let label = viz.designSettings.showBars.find(
        (b) => b.value === bars[i]
      ).label;
      map[label] = map[bars[i]];
      delete map[bars[i]];
      bars[i] = label;
    }

    let data = compileSplitData(bars, map, emails);

    for (let i = 0; i < emailNames.length; i++) {
      data.labels[i] = emailNames[i];
    }

    return data;
  }

  function pivotStatsCompared() {
    let pivots = getLabelsFor(viz.pivotString);
    let bars = viz.designSettings.showBars.map((b) => b.value);

    let statMap = {};
    for (let b of bars) {
      statMap[b] = {};
      for (let p of pivots) {
        statMap[b][p] = 0;
      }
    }
    let compMap = JSON.parse(JSON.stringify(statMap));

    function putInStats(partStat, map) {
      let barFields = getStatLabels(partStat, map);
      let pivotFields = getPivotLabels(partStat.participation);
      for (let bar of barFields) {
        for (let pivot of pivotFields) {
          if (!(pivot in map[bar])) {
            if (viz.designSettings.showUndefined && pivot === "Undefined") {
              pivots.push(pivot);
              for (let b of bars) {
                statMap[b][pivot] = 0;
                compMap[b][pivot] = 0;
              }
            } else {
              continue;
            }
          }
          map[bar][pivot]++;
        }
      }
    }

    for (let partStat of getPartStats()) {
      putInStats(partStat, statMap);
    }
    for (let partStat of getComparablePartStats()) {
      putInStats(partStat, compMap);
    }

    for (let b of bars) {
      for (let p of pivots) {
        compMap[b][p] = Math.round(compMap[b][p] / comparisonProjects.length);
      }
    }

    if (!viz.designSettings?.showNonParticipating) {
      let keeping = [];
      for (let pivot of pivots) {
        for (let b of bars) {
          if (statMap[b][pivot] || compMap[b][pivot]) {
            keeping.push(pivot);
            break;
          }
        }
      }
      pivots = keeping;
    }

    for (let i = 0; i < bars.length; i++) {
      let label = viz.designSettings.showBars[i].label;
      statMap[label] = statMap[bars[i]];
      delete statMap[bars[i]];
      compMap[label] = compMap[bars[i]];
      delete compMap[bars[i]];
      bars[i] = label;
    }

    return compileSplitComparedData(bars, statMap, pivots, compMap);
  }

  function pivotStats() {
    if (viz.pivotString === "email") {
      return pivotEmail();
    }
    if (viz.designSettings.split) {
      if (viz.designSettings.split === "email") {
        return pivotAndSplitEmail();
      }
      return pivotAndSplit();
    }
    if (comparing()) {
      return pivotStatsCompared();
    }

    let pivots = getLabelsFor(viz.pivotString);
    let bars = viz.designSettings.showBars.map((b) => b.value);

    let map = {};
    for (let b of bars) {
      map[b] = {};
      for (let p of pivots) {
        map[b][p] = 0;
      }
    }

    for (let partStat of getPartStats()) {
      let barFields = getStatLabels(partStat, map);
      let pivotFields = getPivotLabels(partStat.participation);
      for (let bar of barFields) {
        for (let pivot of pivotFields) {
          if (!(pivot in map[bar])) {
            if (viz.designSettings.showUndefined && pivot === "Undefined") {
              pivots.push(pivot);
              for (let b of bars) {
                map[b][pivot] = 0;
              }
            } else {
              continue;
            }
          }
          map[bar][pivot]++;
        }
      }
    }

    if (!viz.designSettings?.showNonParticipating) {
      let keeping = [];
      for (let pivot of pivots) {
        for (let b of bars) {
          if (map[b][pivot]) {
            keeping.push(pivot);
            break;
          }
        }
      }
      pivots = keeping;
    }

    for (let i = 0; i < bars.length; i++) {
      let label = viz.designSettings.showBars[i].label;
      map[label] = map[bars[i]];
      delete map[bars[i]];
      bars[i] = label;
    }

    return compileSplitData(bars, map, pivots);
  }

  function splitStatsCompared() {
    const blankData = getBlankBarMap();
    let splits = getLabelsFor(viz.designSettings.split);

    let statMap = {};
    for (let s of splits) {
      statMap[s] = { ...blankData };
    }
    let compMap = JSON.parse(JSON.stringify(statMap));

    function putInStats(partStat, map) {
      let barFields = getStatLabels(partStat, blankData);
      let splitFields = getSplitLabels(partStat.participation);
      for (let split of splitFields) {
        if (!(split in map)) {
          if (viz.designSettings.showUndefined && split === "Undefined") {
            statMap[split] = { ...blankData };
            compMap[split] = { ...blankData };
            splits.push(split);
          } else {
            continue;
          }
        }
        for (let bar of barFields) {
          map[split][bar]++;
        }
      }
    }

    for (let partStat of getPartStats()) {
      putInStats(partStat, statMap);
    }

    for (let partStat of getComparablePartStats()) {
      putInStats(partStat, compMap);
    }

    let bars = viz.designSettings.showBars.map((b) => b.value);
    if (!viz.designSettings?.showNonParticipating) {
      let keeping = [];
      for (let split of splits) {
        for (let b of bars) {
          if (statMap[split][b] || compMap[split][b]) {
            keeping.push(split);
            break;
          }
        }
      }
      splits = keeping;
    }

    for (let split of splits) {
      for (let b of bars) {
        compMap[split][b] = Math.round(
          compMap[split][b] / comparisonProjects.length
        );
      }
    }

    for (let i = 0; i < bars.length; i++) {
      let label = viz.designSettings.showBars[i].label;
      for (let s of splits) {
        for (let map of [statMap, compMap]) {
          map[s][label] = map[s][bars[i]];
          delete map[s][bars[i]];
        }
      }
      bars[i] = label;
    }

    return compileSplitComparedData(splits, statMap, bars, compMap);
  }

  function splitStats() {
    if (viz.designSettings.split === "email") {
      return splitEmail();
    }
    if (comparing()) {
      return splitStatsCompared();
    }

    const blankData = getBlankBarMap();
    let splits = getLabelsFor(viz.designSettings.split);

    let map = {};
    for (let s of splits) {
      map[s] = { ...blankData };
    }

    for (let partStat of getPartStats()) {
      let barFields = getStatLabels(partStat, blankData);
      let splitFields = getSplitLabels(partStat.participation);
      for (let split of splitFields) {
        if (!(split in map)) {
          if (viz.designSettings.showUndefined && split === "Undefined") {
            map[split] = { ...blankData };
            splits.push(split);
          } else {
            continue;
          }
        }
        for (let bar of barFields) {
          map[split][bar]++;
        }
      }
    }

    let bars = viz.designSettings.showBars.map((b) => b.value);
    if (!viz.designSettings?.showNonParticipating) {
      let keeping = [];
      for (let split of splits) {
        for (let b of bars) {
          if (map[split][b]) {
            keeping.push(split);
            break;
          }
        }
      }
      splits = keeping;
    }

    for (let i = 0; i < bars.length; i++) {
      let label = viz.designSettings.showBars[i].label;
      for (let s of splits) {
        map[s][label] = map[s][bars[i]];
        delete map[s][bars[i]];
      }
      bars[i] = label;
    }

    return compileSplitData(splits, map, bars);
  }

  function statDataCompared() {
    let statMap = getBlankBarMap();
    let compMap = { ...statMap };

    function putInStats(partStat, map) {
      let barFields = getStatLabels(partStat, map);
      for (let field of barFields) {
        map[field]++;
      }
    }

    for (let p of getPartStats()) {
      putInStats(p, statMap);
    }
    for (let p of getComparablePartStats()) {
      putInStats(p, compMap);
    }

    for (let bar of viz.designSettings.showBars) {
      compMap[bar.value] = Math.round(
        compMap[bar.value] / comparisonProjects.length
      );
    }

    let labels = [];
    for (let bar of viz.designSettings.showBars) {
      let label = bar.label;
      for (let map of [statMap, compMap]) {
        map[label] = map[bar.value];
        delete map[bar.value];
      }

      labels.push(label);
    }

    return compileComparedData(statMap, compMap, labels);
  }

  function getStatData() {
    if (viz.pivotString) {
      return pivotStats();
    }
    if (viz.designSettings.split) {
      return splitStats();
    }

    if (comparing()) {
      return statDataCompared();
    }

    let map = getBlankBarMap();

    for (let partStat of getPartStats()) {
      let barFields = getStatLabels(partStat, map);
      for (let field of barFields) {
        map[field]++;
      }
    }

    let tallies = [];
    let labels = [];
    for (let b of viz.designSettings.showBars) {
      tallies.push(map[b.value]);
      labels.push(b.label);
    }

    return compileData(labels, tallies);
  }

  const getData = () => {
    if (data) {
      return data;
    }

    if (newChart && !projects.length) {
      setData(fakeData);
      return fakeData;
    }

    let chartData = getStatData();

    if (viz.type === LineType) {
      return getLineData(chartData);
    }

    setData(chartData);
    return chartData;
  };

  function getLineData(data) {
    let settings = viz.designSettings;
    //so updates to the settings gets recognized by the useEffect in Chart.jsx
    for (let i = 0; i < data.datasets.length; i++) {
      data.datasets[i].pointRadius = settings.pointRadius;
      data.datasets[i].borderWidth = settings.lineGraphWidth;
      data.datasets[i].pointBorderWidth = 0;
      data.datasets[i].borderRadius = settings.borderRadius
        ? settings.borderRadius
        : 0;
      data.datasets[i].borderSkipped = settings.borderSkipped;
    }

    if (data.datasets.length > 1 || data.datasets[0]?.label) {
      // split
      for (let i = 0; i < data.datasets.length; i++) {
        data.datasets[i].borderColor = data.datasets[i].backgroundColor[0];
      }
    } else {
      data.datasets[0].borderColor = settings.lineGraphColor;
      if (settings.hasUniformPointColor) {
        for (let i = 0; i < data.datasets[0].data.length; i++) {
          data.datasets[0].backgroundColor[i] = settings.uniformPointColor;
        }
      }
    }

    return data;
  }

  function saveData(data) {
    updateViz.mutate({
      id: viz.id,
      data: { data: typeof data != "string" ? JSON.stringify(data) : data },
    });
  }

  function closeDrill() {
    setDrill(null);
    setShowDrill(false);
    setChartData(null);
    if (setAbove) {
      setAbove(null);
    }
  }

  function onSegClick(segment, dataset, dataIndex, datasetIndex) {
    if (!projects.length || !role.canSeeContactInfo) {
      // is a new chart with no data
      return;
    }

    if (
      viz.designSettings?.segLabelReplacements &&
      Object.keys(viz.designSettings.segLabelReplacements).length > 0
    ) {
      let replacement = -1;
      let lookFor = splitting(viz) ? dataset : segment;
      for (let one in viz.designSettings.segLabelReplacements) {
        if (viz.designSettings.segLabelReplacements[one] === lookFor) {
          replacement = parseInt(one);
        }
      }
      if (replacement > -1) {
        // set it up to get the data normally, without sorting or replacements
        let copy = viz.designSettings.segLabelReplacements;
        viz.designSettings.segLabelReplacements = {};
        let sort = viz.designSettings.sortData;
        viz.designSettings.sortData = NoSort;

        // Get the data normally (inside getData() but past the initial 'return data')
        let chartData = getStatData();
        // Replace sort and replacements
        viz.designSettings.segLabelReplacements = copy;
        viz.designSettings.sortData = sort;

        if (splitting(viz)) {
          // Splitting: dataset is the replacement - the split
          dataset = chartData.datasets[replacement].label;
        } else {
          // Pivoting: segment is the replacement - the pivot
          segment = chartData.labels[replacement];
        }
      }
    }

    if (
      viz.designSettings?.axisLabelReplacements &&
      Object.keys(viz.designSettings.axisLabelReplacements).length > 0
    ) {
      let replace = false;
      for (let one in viz.designSettings.axisLabelReplacements) {
        if (dataIndex === parseInt(one)) {
          replace = true;
        }
      }
      if (replace) {
        // set it up to get the data normally, without sorting or replacements
        let copy = viz.designSettings.axisLabelReplacements;
        viz.designSettings.axisLabelReplacements = {};
        let sort = viz.designSettings.sortData;
        viz.designSettings.sortData = NoSort;

        // Get the data normally (inside getData() but past the initial 'return data')
        let chartData = getStatData();
        // Replace sort and replacements
        viz.designSettings.axisLabelReplacements = copy;
        viz.designSettings.sortData = sort;

        segment = chartData.labels[dataIndex];
      }
    }

    let setup = {
      segment: segment,
      dataset: dataset,
      split: viz.designSettings.split,
      pivot: viz.pivotString,
    };

    if (setup.pivot === "email" || setup.split === "email") {
      let [emails, emailMap, emailNames] = comparing()
        ? getComparedDistLabels()
        : getDistLabels();

      let index = setup.pivot === "email" ? dataIndex : datasetIndex;
      if (setup.split === "email" && comparing()) {
        if (index % 2) {
          index--;
        }
        index = index / 2;
      }

      let email = emails[index];
      setup.distIds = [];
      for (let id in emailMap) {
        if (emailMap[id] === email) {
          setup.distIds.push(id);
        }
      }
    }

    if (comparing()) {
      let comparedLabel = comparisonLabel();

      if (viz.designSettings.split || viz.pivotString) {
        if (
          typeof dataset === "string" &&
          dataset.includes(comparedLabel + " | ")
        ) {
          let ind = dataset.indexOf("|");
          setup.dataset = dataset.slice(ind + 2);
          setup.useCompared = true;
        }
      } else if (dataset === comparedLabel) {
        setup.split = "";
        setup.useCompared = true;
      }
    }

    setDrill(setup);

    if (setAbove) {
      setAbove(
        <StatDrillTable
          partStats={
            drill && drill.useCompared
              ? getComparablePartStats()
              : getPartStats()
          }
          distributions={
            drill && drill.useCompared
              ? getCompDistributionsInOrder()
              : getDistributionsInOrder()
          }
          getDistLabels={
            drill && drill.useCompared
              ? getComparedDistLabels()
              : getDistLabels()
          }
          projects={drill.useCompared ? comparisonProjects : projects}
          viz={viz}
          custom_fields={custom_fields}
          inEdit={inEdit}
          surveyTags={getSurveyTags.data.tags}
          drill={drill}
          onClose={closeDrill}
          toggleSpreadsheet
        />
      );
    } else {
      setShowDrill(true);
    }
  }

  function getTitle() {
    if (!viz.designSettings.hasTitle) {
      return "";
    }
    if (newChart && !projects.length) {
      return "Sample Data";
    }

    return viz.title;
  }

  const fakeData = {
    labels: ["", "", ""],
    // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
    datasets: [
      {
        // label: "Amount",
        data: [125, 55, 82],
        // you can set indiviual colors for each bar
        // backgroundColor: ["#edb57e", "#FF8878", "#C1E08D"],
        backgroundColor: ["#15bcc7", "#dbdbdb", "#ed9146"],
        borderWidth: 0,
      },
    ],
  };

  return (
    <>
      {(fetchInfo.isLoading ||
        fetchCompInfo.isLoading ||
        getSurveyTags.isLoading ||
        colorPalette.isLoading) &&
        !thumbnail && <Loading height={height}></Loading>}
      {fetchInfo.isSuccess &&
        fetchCompInfo.isSuccess &&
        getSurveyTags.isSuccess &&
        colorPalette.isSuccess && (
          <>
            {!showDrill && !seeData && (
              <>
                <div
                  className={styles.chartWithTitle}
                  id={viz.id + idAddOn}
                  style={thumbnail ? { gap: "0" } : undefined}
                >
                  {viz.designSettings.hasTitle && (
                    <div
                      className={styles.titleContainer}
                      id={"title for " + viz.id + idAddOn}
                      style={{
                        minHeight: thumbnail ? "25px" : "",
                        alignItems: viz.designSettings.titleAlignment,
                        backgroundColor:
                          viz.designSettings.titleBackgroundColor,
                        borderRadius: viz.designSettings.titleBorderRadius,
                        paddingTop: viz.designSettings.paddingTopTitle,
                        paddingBottom: viz.designSettings.paddingBottomTitle,
                        paddingLeft: viz.designSettings.paddingLeftTitle,
                        paddingRight: viz.designSettings.paddingRightTitle,
                      }}
                    >
                      <div
                        className={styles.title}
                        style={{
                          color: viz.designSettings.titleColor,
                          fontSize: viz.designSettings.valueTitleSize,
                        }}
                      >
                        {getTitle()}
                      </div>
                      {filterSubtitle &&
                        viz.designSettings.hasSubtitle &&
                        projects.length > 0 && (
                          <div className={styles.subtitle}>
                            {filterSubtitle}
                          </div>
                        )}
                    </div>
                  )}

                  {(!fetchInfo?.data?.info?.partStats ||
                    !fetchInfo?.data?.info?.partStats?.length) && (
                    <div className={styles.noData}>No Data</div>
                  )}

                  {((fetchInfo?.data?.info?.partStats &&
                    fetchInfo?.data?.info?.partStats?.length > 0) ||
                    newChart) && (
                    <>
                      <Chart
                        data={getData()}
                        onSegClick={onSegClick}
                        viz={viz}
                        thumbnail={thumbnail}
                        idAddOn={idAddOn}
                        reDraw={reDraw}
                        update={update}
                        saveData={saveData}
                        inEdit={inEdit}
                      />
                      {viz.designSettings?.showN && vizQs.length > 0 && (
                        <div
                          className={styles.answerCount}
                          style={thumbnail ? { fontSize: ".5em" } : undefined}
                        >
                          {viz.designSettings?.NLabel
                            ? viz.designSettings?.NLabel
                            : "N"}{" "}
                          = {N}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </>
            )}

            {showDrill && drill && (
              <StatDrillTable
                partStats={
                  drill.useCompared ? getComparablePartStats() : getPartStats()
                }
                distributions={
                  drill.useCompared
                    ? getCompDistributionsInOrder()
                    : getDistributionsInOrder()
                }
                getDistLabels={
                  drill.useCompared ? getComparedDistLabels() : getDistLabels()
                }
                projects={drill.useCompared ? comparisonProjects : projects}
                viz={viz}
                custom_fields={custom_fields}
                inEdit={inEdit}
                surveyTags={getSurveyTags.data.tags}
                drill={drill}
                onClose={closeDrill}
                toggleSpreadsheet
              />
            )}
            {seeData && (
              <StatDataTable
                partStats={getPartStats()}
                distributions={getDistributionsInOrder()}
                viz={viz}
                custom_fields={custom_fields}
                inEdit={inEdit}
                projects={projects}
                onClose={closeSeeData}
                chartData={chartData ? getData() : ""}
                setChartData={setChartData}
                toggleSpreadsheet
              />
            )}
          </>
        )}
    </>
  );
}
