import Button from "components/Button/Button";
import styles from "./DoYouWantToSave.module.scss";
import { useState } from "react";
import { Loading } from "components/Loading/Loading";

export default function DoYouWantToSave({
  navAway,
  onSave,
  prompt,
  cancelText,
  confirmText,
}) {
  const [saving, setSaving] = useState();

  function yes() {
    onSave();
    setSaving(true);
    setTimeout(() => {
      navAway();
    }, 1000);
  }

  return (
    <div className={styles.popoutBackground}>
      <div className={styles.modal}>
        <div className={styles.prompt}>
          {prompt ? (
            prompt
          ) : (
            <>
              <div>
                You have <strong>unsaved changes</strong> in this survey.
              </div>
              <div>Would you like to save before leaving?</div>
            </>
          )}
        </div>
        <div className={styles.buttons}>
          <Button link onClick={navAway} shadow>
            {cancelText ? cancelText : "No, don't save"}
          </Button>
          <Button onClick={yes} shadow blue>
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              {confirmText ? confirmText : "Save"}
              {saving && <Loading height="20" width="20"></Loading>}
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
}
