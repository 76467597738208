import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import styles from "../WidgetSettings.module.scss";
import { Label } from "components/layouts/Label/Label";
import { useEffect, useRef, useState } from "react";
import { useFetchReportByExclusiveId } from "api/resources/organization/reports";

export default function PickReport({ widget, setWidget, visible, setVisible }) {
  function addReport(report) {
    let newWidget = { ...widget };
    newWidget.settings.report = { id: report.id, name: report.name };
    setWidget(newWidget);
  }

  return (
    <>
      <div
        className={`${styles.header} ${visible ? styles.headervisible : ""}`}
        onClick={setVisible}
      >
        {"Report"}
        <span className={styles.accordionicon}>
          <i className="bi bi-caret-left-fill"></i>
        </span>
      </div>
      {visible && (
        <div className={styles.body}>
          <div
            className={styles.col}
            style={{ gap: ".5em", alignItems: "center" }}
          >
            <Label style={{ color: "#8dabb2" }}>Choose Report</Label>
            <SearchSurvey
              addReport={addReport}
              current={widget.settings?.report}
            />
          </div>
        </div>
      )}
    </>
  );
}

function SearchSurvey({ addReport, current }) {
  const [searchString, setSearchString] = useState("");
  const [searchVisible, setSearchVisible] = useState(false);
  const [value, setValue] = useState(current?.name ? current.name : undefined);
  function changeSearch(e) {
    setValue(e.target.value);
    setSearchString(e.target.value);
  }
  const [perPage, setPerPage] = useState(10);
  const [pageSkip, setPageSkip] = useState(0);
  const [pageSort, setPageSort] = useState({
    item: "updatedAt",
    descend: true,
  });

  const searchReportQuery = useFetchReportByExclusiveId(
    searchString,
    pageSkip,
    perPage,
    pageSort,
    "Search"
  );

  const reportOrg = useFetchReportByExclusiveId(
    "",
    pageSkip,
    perPage,
    pageSort,
    "Organization"
  );

  const reportsMe = useFetchReportByExclusiveId(
    "",
    pageSkip,
    perPage,
    pageSort,
    "Me"
  );

  useEffect(() => {
    searchReportQuery.refetch();
  }, [searchString]);

  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setSearchVisible(false);
      setSearchString("");
      if (current) {
        setValue(current.name);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <div style={{ width: "100%" }} ref={ref}>
      <input
        className={styles.searchSurveys}
        type="text"
        placeholder="search"
        onChange={changeSearch}
        onFocus={() => {
          setSearchVisible(true);
        }}
        value={value}
        // onBlur={() => {
        //   setSearchVisible(false);
        // }}
      ></input>
      {searchVisible && (
        <div className={styles.optionsBox}>
          <div className={styles.options}>
            {searchReportQuery.isSuccess &&
              searchReportQuery.data.reports.map((option) => (
                <div
                  key={option.id}
                  className={styles.option}
                  onClick={() => {
                    addReport(option);
                    setSearchVisible(false);
                    setValue(option.name);
                    setSearchString("");
                  }}
                >
                  {option.name}
                </div>
              ))}

            {reportsMe.isSuccess &&
              reportsMe.data.reports.map((option) => (
                <div
                  key={option.id}
                  className={styles.option}
                  onClick={() => {
                    addReport(option);
                    setSearchVisible(false);
                    setValue(option.name);
                    setSearchString("");
                  }}
                >
                  {option.name}
                </div>
              ))}

            {reportOrg.isSuccess &&
              reportOrg.data.reports.map((option) => (
                <div
                  key={option.id}
                  className={styles.option}
                  onClick={() => {
                    addReport(option);
                    setSearchVisible(false);
                    setValue(option.name);
                    setSearchString("");
                  }}
                >
                  {option.name}
                </div>
              ))}
          </div>
        </div>
      )}

      {/* <Button onClick={() => {searchOptions.refetch()}}>refetch</Button> */}
    </div>
  );
}
