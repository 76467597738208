import React, { useState } from "react";
import styles from "./ShareSelect.module.scss";
import { SelectField } from "../SelectField/SelectField";
import Button from "components/Button/Button";
import { Label } from "components/layouts/Label/Label";
import avatar from "assets/images/blank-profile-picture.png";

function ShareSelect({
  options,
  data,
  placeholder,
  selectMultiple,
  onChange,
  onButtonClick,
  buttonText,
  onSetPermission,
  label,
  labelbottom,
  owner,
  onDelete,
  type
}) {
  const [chosenOptions, setChosenOptions] = useState(
    initializeData(data ? data : [])
  );
  const [seenOptions, setSeenOptions] = useState(options ? options : []);
  const [middlePeople, setMiddlePeople] = useState([]);
  const [empty, setEmpty] = useState(false);
  const [currPermission, setCurrPermission] = useState("viewer");

  function handleChangePermission(newPermission) {
    if (onSetPermission) {
      onSetPermission(newPermission);
    }
    setCurrPermission(newPermission);
  }

  function handleUpdateChosen() {
    if (middlePeople?.length > 0) {
      let tempArray = [...middlePeople];
      let tempOptions = [...seenOptions];

      for (let i = 0; i < tempArray?.length; i++) {
        tempArray[i].name = tempArray[i].label;
        tempArray[i].status = currPermission;
        tempArray[i].id = "new";

        let index = tempOptions.findIndex(
          (c) => c.value === tempArray[i].value
        );
        tempOptions.splice(index, 1);
      }
      setSeenOptions(tempOptions);
      let newChosen = [...chosenOptions, ...middlePeople];
      if (onChange) {
        onChange(newChosen);
      }
      setChosenOptions(newChosen);
      setMiddlePeople([]);
    }
  }

  function handleUpdateMiddle(chosen) {
    // debugger;
    setMiddlePeople(chosen);
    // setEmpty(true);
    // setTimeout(() => {
    //   setEmpty(false);
    // }, 2000);
  }

  function remove(id) {
    let tempChosen = [...chosenOptions];
    let i = tempChosen.findIndex((c) => c.value === id);
    let itemToDelete = {...tempChosen[i]};
    
    
    tempChosen.splice(i, 1);
    setChosenOptions(tempChosen);
    onDelete(itemToDelete);
    
    
    // let tempOptions = [...seenOptions];
    // tempOptions.push(tempChosen[i]);
    // setSeenOptions(tempOptions);
  }

  function initializeData(initData) {
    if (owner) {
      let finalData = [owner, ...initData];
      return finalData;
    }
    return initData;
  }
  return (
    <div>
      {label && !labelbottom && <Label>{label}</Label>}
      <div className={styles.shareSelect}>
        <SelectField
          options={seenOptions}
          placeholder={placeholder ? placeholder : "Choose to share.."}
          selectMultiple={selectMultiple ? selectMultiple : true}
          onChange={handleUpdateMiddle}
          empty={empty}
          value={middlePeople}
        ></SelectField>{" "}
        <Button height={40} onClick={handleUpdateChosen} shadow>
          {buttonText ? buttonText : "Share"}
        </Button>
        <div className={styles.selectContainer}>
          <select
            onChange={(e) => handleChangePermission(e.target.value)}
            defaultValue={"viewer"}
          >
            <option value="editor">Editor</option>{" "}
            <option value="viewer">Viewer</option>
           { type === "organization" && <option value="roles">Roles</option>}
          </select>
        </div>
      </div>
      {label && labelbottom && <Label>{label}</Label>}
      <div className={styles.peopleWithAccess}>
        <table>
          {type != "organization" && <tbody>
            {chosenOptions?.map((user) => (
              <tr>
                <td>
                  <div>
                    <img src={avatar} className={styles.avatar} alt="user" />
                    <div className={styles.div2}>
                      {user.name}
                      <div className={styles.email}>{user?.email}</div>
                    </div>
                  </div>
                </td>

                <td className={styles.access}>
                  {user.status.charAt(0).toUpperCase() + user.status.slice(1)}{" "}
                  {user.status != "owner" && (
                    <i
                      className="bi bi-trash"
                      onClick={() => remove(user.value)}
                      style={{ cursor: "pointer" }}
                    ></i>
                  )}
                </td>
              </tr>
            ))}
          </tbody>}
          {type === "organization" && <tbody>
            {chosenOptions?.map((user) => (
              <tr>
                <td>
                  <div>
                    <img src={avatar} className={styles.avatar} alt="user" />
                    <div className={styles.div2}>
                      {console.log(user)}
                      {user.name}
                      <div className={styles.email}>{user?.type}</div>
                    </div>
                  </div>
                </td>

                <td className={styles.access}>
                  {user.status.charAt(0).toUpperCase() + user.status.slice(1)}{" "}
                  {user.status != "owner" && (
                    <i
                      className="bi bi-trash"
                      onClick={() => remove(user.value)}
                      style={{ cursor: "pointer" }}
                    ></i>
                  )}
                </td>
              </tr>
            ))}
          </tbody>}
        </table>
      </div>
    </div>
  );
}

export default ShareSelect;
