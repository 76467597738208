import NotifWidget from "../Widgets/Notifications/NotifWidget";
import SurveyWidget from "./Survey/SurveyWidget";
import styles from "./Widget.module.scss";
import ReportWidget from "./Reports/ReportWidget";
import TileWidget from "./Tile/TileWidget";

export default function Widget({ widget, layout, setAbove, inSettings, inEdit, canEdit }) {

  return (
    <div className={styles.widget} style={inEdit ? {pointerEvents: "none"} : undefined}>
      {widget?.type === "survey" && (
        <SurveyWidget
          widget={widget}
          setAbove={setAbove}
          inSettings={inSettings}
          canEdit={canEdit}
        />
      )}
      {widget?.type === "notifications" && (
        <NotifWidget widget={widget} inSettings={inSettings} />
      )}
      {widget?.type === "reports" && (
        <ReportWidget widget={widget} inSettings={inSettings} />
      )}
      {widget?.type === "tile" && (
        <TileWidget widget={widget} inSettings={inSettings} />
      )}
    </div>
  );
}
