import styles from "./SurveyWidget.module.scss";
import { useEffect, useState, useRef } from "react";
import { useGetViz } from "api/resources/projects/visualizations";
import { useFetchAnOrgsFields } from "api/resources/organization/organization";
import Visualization from "pages/results/Charts/Visualization";

export function ChartWidget({
  charts,
  setAbove,
  onDelete,
  inSettings,
  reDraw,
  reload,
  canEdit,
}) {
  const [num, setNum] = useState(0);

  // getChartId and getOrgId are both for if you are in settings and you delete the last one in the list and you are looking at that one
  function getChartId() {
    if (num < charts.length) {
      return charts[num].id;
    }
    setNum(inSettings ? num - 1 : 0);
    return charts[0].id;
  }

  function getOrgId() {
    if (num < charts.length) {
      return charts[num].orgId;
    }
    setNum(inSettings ? num - 1 : 0);
    return charts[0].orgId;
  }

  const fetchChart = useGetViz(getChartId());
  const fetchCustomFields = useFetchAnOrgsFields(getOrgId());
  const [hasMultiple, setHasMultiple] = useState(charts.length > 1);

  useEffect(() => {
    if (!hasMultiple && charts.length > 1) {
      reload();
      setHasMultiple(true);
    } else if (hasMultiple && charts.length < 2) {
      reload();
      setHasMultiple(false);
    }
  }, [charts.length]);

  // useEffect(() => {
  //   // let it load the height and width
  //   setTimeout(() => reload(), 1000);
  // }, []);

  function increment() {
    if (num < charts.length - 1) {
      setNum(num + 1);
    } else {
      setNum(0);
    }
  }

  function decrement() {
    if (num == 0) {
      setNum(charts.length - 1);
    } else {
      setNum(num - 1);
    }
  }

  const container = document.getElementById("outside " + getChartId());

  const ref = useRef();

  // console.log(ref.current.offsetHeight);

  return (
    <div className={styles.chartWidgContainer}>
      {fetchChart.isSuccess && fetchCustomFields.isSuccess && (
        <>
          {hasMultiple && (
            <div className={styles.arrow} onClick={decrement}>
              <i className="bi bi-arrow-left"></i>
            </div>
          )}
          <div className={`${styles.chart} `} ref={ref}>
            <Visualization
              viz={fetchChart.data.getVizualizationByID}
              setAbove={setAbove}
              inDash
              canSave={canEdit}
              canEdit={canEdit}
              // canDelete  Can delete in the widget settings?
              refetch={() => fetchChart.refetch()}
              deleteFunction={onDelete}
              idAddOn={inSettings ? "inSettings" : undefined}
              redDrawCounter={reDraw}
              custom_fields={fetchCustomFields.data.getOrgFields}
              height={ref.current ? ref.current.offsetHeight : undefined}
              width={ref.current ? ref.current.offsetWidth : undefined}
            />
          </div>
          {hasMultiple && (
            <div
              className={styles.arrow}
              style={{ marginRight: "3px" }}
              onClick={increment}
            >
              <i className="bi bi-arrow-right"></i>
            </div>
          )}
        </>
      )}
    </div>
  );
}
