import ConfigureBox from "./Settings/ConfigureBox";
import {
  defaultChartSettings,
  DoughnutType,
  HorizBarType,
  LineType,
  MetricType,
  PieType,
  TableType,
  ValueType,
  VertBarType,
} from "../Charts/QuestionChart";
import styles from "./AddChart.module.scss";
import { shortId } from "components/tables/EditableTable/utils";
import { defaultMetricSettings } from "./ScoreChart";
import { defaultTableSettings } from "./TableViz";

export const blankViz = {
  title: `Sample Data`,
  pivotString: "",
  projectIdsArray: [],
  tagIdsArray: [],
  filters: "{}",
  question: [],
  dynamicQs: [],
  id: shortId(),
};

export default function AddChart({
  onSave,
  onClose,
  custom_fields,
  type,
  externalFilter,
}) {

  const chart = {
    type: VertBarType,
    designSettings: JSON.parse(JSON.stringify(defaultChartSettings)),
    ...JSON.parse(JSON.stringify(blankViz)),
  };

  const metric = {
    type: MetricType,
    designSettings: JSON.parse(JSON.stringify(defaultMetricSettings)),
    ...JSON.parse(JSON.stringify(blankViz)),
  };

  const table = {
    type: TableType,
    designSettings: JSON.parse(JSON.stringify(defaultTableSettings)),
    ...JSON.parse(JSON.stringify(blankViz)),
  };

  return (
    <div className={styles.popoutBackground} id="outside">
      <div className={styles.popout}>
        <ConfigureBox
          visualization={
            type === "chart" ? chart : type === "table" ? table : metric
          }
          onSave={onSave}
          onClose={onClose}
          canSave
          newChart
          custom_fields={custom_fields}
          externalFilter={externalFilter}
          height={300}
          width={700}
        />
      </div>
    </div>
  );
}
