import React, { useState, useRef, useEffect } from "react";
import { SketchPicker } from "react-color";
import { Popover } from "@mui/material";
import styles from "./TextEditorTry.module.scss";
import Button from "components/Button/Button";
import { SurveyColors, TextColors } from "assets/functions/ColorFunctions";

/**
 * A color picker component
 * @param {Array} colors something
 * @param {String} defaultColor something
 * @param {Function} onChange a function that is called when a color is chosen
 * @returns {React.ReactElement} a simple color picker component
 */

export const TextColorPicker = ({ color, onChange, colorRef }) => {
  const [show, setShow] = useState(false);
  const pickerRef = useRef();

  const colors = [...SurveyColors, ...TextColors];

  const handleColorChange = (color) => {
    onChange(color.hex);
  };

  let paper = {
    paper: {
      sx: { backgroundColor: "transparent", boxShadow: "none" },
    },
  };

  return (
    <>
      <div
        ref={pickerRef}
        style={{
          backgroundColor: color,
        }}
        className={styles.color}
        onClick={() => setShow(true)}
      ></div>
      <Popover
        ref={colorRef}
        open={show}
        anchorEl={pickerRef.current}
        onClose={() => setShow(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        slotProps={paper}
      >
        <div style={{ padding: "10px" }}>
          <SketchPicker
            color={color}
            presetColors={colors}
            onChangeComplete={handleColorChange}
          />
        </div>
      </Popover>
    </>
  );
};
