/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/

import React, { useState, useEffect } from "react";

import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch.jsx";
import styles from "./ContactCleaner.module.scss";
import { submitFile, toCSV } from "./CleanerFunctionality";
import { useCreateUpload } from "api/resources/contacts/contacts";
import axios from "axios";
import Button from "components/Button/Button";
import { BasicTable } from "components/tables/BasicTable/BasicTable";
import { Loading } from "components/LoadingUpload/Loading";
import { Loading as Loading2 } from "components/Loading/Loading";
import { csv2json } from "csv42";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";
import { useFetchAudiencesSimple } from "api/resources/contacts/audiences";
import ColumnMapping from "./ColumnMapping";
import salesforceImage from "assets/images/Salesforce.png";
import { Label } from "components/layouts/Label/Label";
import { useNavigate } from "react-router-dom";
import { Manual } from "pages";

window.Buffer = window.Buffer || require("buffer").Buffer;
const firstname = /first[\s_.]?name/;
const lastname = /last[\s_.]?name/;
export const ContactCleaner = ({
  user,
  audienceId,
  audience,
  salesforce,
  numImport,
  toAudience,
  selected,
  doSelected,
  organizationId,
  setImporting,
  initHeaders,
  initData,
  doFilter,
  customFields,
  manual,
  refetchTasks,
}) => {
  //States
  const [file, setFile] = useState();
  const [data, setData] = useState(initData ? initData : []);
  const [headers, setHeaders] = useState();
  const [waitingForFileUpload, setWaitingForFileUpload] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFaliure] = useState(false);
  const [verifyDomain, setVerifyDomain] = useState(false);
  const [removeDups] = useState(true);
  const [checkPrefix, setCheckPrefix] = useState(true);
  const [checkSuffix, setCheckSuffix] = useState(true);
  const [checkSingle, setCheckSingle] = useState(true);
  const [emailme, setEmailme] = useState(false);
  const [notifyme, setnotifyme] = useState(true);
  const [uploadId, setUploadId] = useState();
  const [creatingUpload, setCreatingUpload] = useState(false);
  const [headerMap, setHeaderMap] = useState({});
  const [enableErrors] = useState(true);
  const [checked, setChecked] = useState("add-update");
  const [importType, setImportType] = useState(
    audienceId || toAudience ? "audience" : "contacts"
  );

  const [sendAudienceId, setSendAudienceId] = useState(
    audienceId ? audienceId : ""
  );
  const [useCleaner, setUseCleaner] = useState(true);
  const [checkWhiteSpace, setCheckWhiteSpace] = useState(true);
  const [verifyEmailSyntax, setVerifyEmailSyntax] = useState(true);
  const uploadContactsMute = useCreateUpload();

  useEffect(() => {
    if (initHeaders && salesforce) {
      let headMap = {};
      let final = [];
      for (let field of initHeaders) {
        if (field.enabled) {
          final.push(field);
          checkMap(field, headMap);
        }
      }
      setHeaders(final);

      setHeaderMap(headMap);
    }
  }, []);

  //handle onChange when you upload file
  const handleOnChange = (e) => {
    setFile(e.target.files[0]);

    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = function (e) {
        const text = e.target.result;
        const data = csv2json(text);

        setData(data);
        let heads = Object.keys(data[0]);
        let headMap = {};
        let final = [];
        let i = 0;
        for (let field of heads) {
          headMap[field] = [];
          final.push({
            id: i,
            name: field,
            accessor: field,
            enabled: true,
            cell_style: null,
            canSort: false,
            // cell_style: (val) => <div>{checkFields(val, field)}</div>,
          });
          checkMapFile(field, headMap);
          i++;
        }
        setHeaders(final);

        setHeaderMap(headMap);
      };
      reader.readAsText(e.target.files[0]);
    }
  };

  function checkMap(field, headerMap) {
    let fields = [];
    if (firstname.test(field?.accessor?.toLowerCase())) {
      fields.push({
        id: "firstName",
        name: "firstName",
        displayName: "First Name",
        label: "First Name",
        value: "firstName",
      });
    }
    if (lastname.test(field?.accessor?.toLowerCase())) {
      fields.push({
        id: "lastName",
        name: "lastName",
        displayName: "Last Name",
        label: "Last Name",
        value: "lastName",
      });
    }
    if (field?.accessor?.toLowerCase() === "email") {
      fields.push({
        id: "email",
        name: "email",
        displayName: "Email",
        label: "Email",
        value: "email",
      });
    }
    if (field?.accessor?.toLowerCase() === "unsubscribed") {
      fields.push({
        id: "unsubscribed",
        name: "unsubscribed",
        displayName: "unsubscribed",
        label: "unsubscribed",
        value: "unsubscribed",
      });
    }

    if (customFields && customFields?.length > 0) {
      for (let f of customFields) {
        if (
          f?.name === field?.accessor?.toLowerCase() ||
          f?.name?.toLowerCase() === field?.accessor?.toLowerCase() ||
          f?.name?.toLowerCase() === field?.label?.toLowerCase() ||
          f?.displayName?.toLowerCase() === field?.accessor?.toLowerCase() ||
          f?.name?.toLowerCase() ===
            field?.accessor?.toLowerCase() +
              " " +
              field?.objectAccessor?.toLowerCase() ||
          f?.displayName?.toLowerCase() ===
            field?.accessor?.toLowerCase() +
              " " +
              field?.objectAccessor?.toLowerCase()
        ) {
          fields.push({ ...f, label: f.displayName, value: f.name });
        }
      }
    }
    if (field?.objectAccessor) {
      headerMap[field?.name] = {
        columns: fields,
        sfName: field.sfName,
        new: false,
      };
    } else {
      headerMap[field?.accessor] = {
        columns: fields,
        sfName: field.sfName,
        new: false,
      };
    }
  }

  function checkMapFile(field, headerMap) {
    let fields = [];
    if (firstname.test(field?.toLowerCase())) {
      fields.push({
        id: "firstName",
        name: "firstName",
        displayName: "First Name",
        label: "First Name",
        value: "firstName",
      });
    }
    if (lastname.test(field?.toLowerCase())) {
      fields.push({
        id: "lastName",
        name: "lastName",
        displayName: "Last Name",
        label: "Last Name",
        value: "lastName",
      });
    }
    if (field?.toLowerCase() === "email") {
      fields.push({
        id: "email",
        name: "email",
        displayName: "Email",
        label: "Email",
        value: "email",
      });
    }
    if (field?.toLowerCase() === "unsubscribed") {
      fields.push({
        id: "unsubscribed",
        name: "unsubscribed",
        displayName: "unsubscribed",
        label: "unsubscribed",
        value: "unsubscribed",
      });
    }

    if (customFields && customFields?.length > 0) {
      for (let f of customFields) {
        if (
          f?.name?.toLowerCase() === field?.toLowerCase() ||
          f?.displayName?.toLowerCase() === field?.toLowerCase()
        ) {
          fields.push({ ...f, label: f.displayName, value: f.name });
        }
      }
    }

    headerMap[field] = {
      columns: fields,
      new: false,
    };
  }

  //function is called when you click submit to manipulate the data
  async function handleOnSubmit(e, file) {
    e.preventDefault();
    e.persist();

    if (!file && !salesforce && !manual) {
      return;
    }
    setCreatingUpload(true);
    let type = salesforce ? "Salesforce" : "file";
    let uploadInput = {
      status: "Uploading",
      type: type,
      totalWarnings: 0,
      appUserId: user?.id,
      organizationId: user?.organizationId,
      badContacts: JSON.stringify([]),
      duplicateContacts: JSON.stringify([]),
      audienceId: audienceId ? audienceId : undefined,
      contact: [],
    };

    uploadContactsMute.mutate(
      {
        data: uploadInput,
      },
      {
        onSuccess: async (data2) => {
          try {
            let upload_id = data2?.createUpload?.id;
            setUploadId(upload_id);
            setCreatingUpload(false);
            setWaitingForFileUpload(true);
            if (salesforce && !doSelected && upload_id) {
              const url2 = process.env.REACT_APP_SALESFORCE_UPLOAD_QUERY;
              let finalMap = {};
              let keys = Object.keys(headerMap);
              for (let k of keys) {
                if (headerMap[k]?.columns?.length > 0 || headerMap[k]?.new) {
                  finalMap[k] = headerMap[k];
                }
              }

              const config = {
                withCredentials: true,
                headers: {
                  Accept: "application/json",
                  // "Content-Type": "multipart/form-data",
                },
                params: {
                  audienceId: sendAudienceId,
                  organizationId: user.organizationId,
                  appUserId: user.id,
                  singleCharacterOrLetter: checkSingle,
                  verifyDomain: verifyDomain,
                  removeDuplicatesByEmail: removeDups,
                  checkForPrefixes: checkPrefix,
                  checkForSuffixes: checkSuffix,
                  verifyEmailSyntax: verifyEmailSyntax,
                  removeWhitespace: checkWhiteSpace,
                  importType: checked,
                  columnMap: JSON.stringify(finalMap),
                  sendEmail: emailme,
                  sendNotification: notifyme,
                  type: type,
                  upload_id: upload_id,
                },
              };

              axios
                .get(url2, config)
                .then((response) => {
                  setWaitingForFileUpload(false);
                  setSuccess(response.data);
                })
                .catch((error) => {
                  console.error(error);
                  setWaitingForFileUpload(false);
                  setFaliure(error);
                });
            } else if (useCleaner && upload_id) {
              const url = process.env.REACT_APP_UPLOADER_API_KEY;
              const formData = new FormData();
              if (!doSelected && !manual) {
                formData.append("file", file);
              } else if (doSelected) {
                let csvContent = toCSV(selected, "salesforceselected");
                const theFile = new File([csvContent], "foo.csv", {
                  type: "text/csv",
                });
                formData.append("file", theFile);
              } else if (manual) {
                let csvContent = toCSV(data, "manualupload");
                const theFile = new File([csvContent], "foo.csv", {
                  type: "text/csv",
                });
                formData.append("file", theFile);
              }

              let finalMap = {};
              let keys = Object.keys(headerMap);
              for (let k of keys) {
                if (headerMap[k]?.columns?.length > 0 || headerMap[k]?.new) {
                  if (firstname.test(k.toLowerCase())) {
                    finalMap["firstName"] = headerMap[k];
                  } else if (lastname.test(k.toLowerCase())) {
                    finalMap["lastName"] = headerMap[k];
                  } else if (k.toLowerCase() === "email") {
                    finalMap["email"] = headerMap[k];
                  } else {
                    finalMap[k] = headerMap[k];
                  }
                }
              }

              const config = {
                withCredentials: true,
                headers: {
                  Accept: "application/json",
                  "Content-Type": "multipart/form-data",
                },
                params: {
                  audienceId: sendAudienceId,
                  organizationId: user.organizationId,
                  appUserId: user.id,
                  singleCharacterOrLetter: checkSingle,
                  verifyDomain: verifyDomain,
                  removeDuplicatesByEmail: removeDups,
                  checkForPrefixes: checkPrefix,
                  checkForSuffixes: checkSuffix,
                  verifyEmailSyntax: verifyEmailSyntax,
                  removeWhitespace: checkWhiteSpace,
                  importType: checked,
                  columnMap: JSON.stringify(finalMap),
                  sendEmail: emailme,
                  sendNotification: notifyme,
                  type: type,
                  upload_id: upload_id,
                },
              };

              axios
                .post(url, formData, config)
                .then((response) => {
                  // setWaitingForFileUpload(false);
                  setUploadId(response.data?.upload_id);
                })
                .catch((error) => {
                  console.error(error);
                  // setWaitingForFileUpload(false);
                  // setFaliure(error);
                });
            } else if (upload_id) {
              let fileContents = await submitFile(file, checkedList);
              let uploadableContacts = fileContents.finalArray.map((c) => {
                let newC = {
                  firstName: c.firstName,
                  lastName: c.lastName,
                  email: c.email,
                };
                delete c.firstName;
                delete c.lastName;
                delete c.email;
                newC = { ...newC, customField: JSON.stringify({ ...c }) };
                return newC;
              });
              let uploadInput = {
                status: "Uploading",
                type: "Audience",
                totalWarnings: fileContents.warnCount,
                // appUserId: user.id,
                // organizationId: user.organizationId,
                badContacts: JSON.stringify(fileContents.badContacts),
                duplicateContacts: JSON.stringify(fileContents.duplicates),
                audienceId: audienceId,
                contact: uploadableContacts,
              };
              uploadContactsMute.mutate(
                {
                  data: uploadInput,
                },
                {
                  onSuccess: () => {
                    setWaitingForFileUpload(false);
                  },
                }
              );
            }
          } catch (e) {
            console.error(e);
            setWaitingForFileUpload(false);
            setFaliure(error);
          }
          setTimeout(() => {
            refetchTasks();
          }, 3000);
        },
      }
    );

    // setWaitingForFileUpload(true);

    // let domain_checked = document.getElementById("domainCheck");
    // let remove_duplicates_checked = document.getElementById("removeDupes");
    // let prefix_checked = document.getElementById("prefixCheck");
    // let suffix_checked = document.getElementById("suffixCheck");
    // let find_replace_checked = document.getElementById("findandreplacecheck");
    // let at_checked = document.getElementById("atCheck");
    // let error_header = document.getElementById("errorHeader");
    // // let download = document.getElementById("download");
    // let char_length_checked = document.getElementById("charlengthCheck");
    // let char_length_num = document.getElementById("charlengthnum");
    // let single_char_checked = document.getElementById("singlechar");
    // let verify_domain = document.getElementById("atCheck")?.checked;
    // let check_whitespace = document.getElementById("whitespace");
    // // let parOutput = document.getElementById("errorOutput");
    // let checkedList = {
    //   domain_checked,
    //   remove_duplicates_checked,
    //   prefix_checked,
    //   suffix_checked,
    //   find_replace_checked,
    //   at_checked,
    //   error_header,
    //   char_length_checked,
    //   char_length_num,
    //   single_char_checked,
    //   verify_domain,
    //   check_whitespace,
    // };

    // try {
    //   if (salesforce && !doSelected) {
    //     const url2 = process.env.REACT_APP_SALESFORCE_UPLOAD_QUERY;
    //     let finalMap = {};
    //     let keys = Object.keys(headerMap);
    //     for (let k of keys) {
    //       if (headerMap[k]?.columns?.length > 0 || headerMap[k]?.new) {
    //         finalMap[k] = headerMap[k];
    //       }
    //     }
    //     let type = "Salesforce";

    //     const config = {
    //       withCredentials: true,
    //       headers: {
    //         Accept: "application/json",
    //         // "Content-Type": "multipart/form-data",
    //       },
    //       params: {
    //         audienceId: sendAudienceId,
    //         organizationId: user.organizationId,
    //         appUserId: user.id,
    //         singleCharacterOrLetter: checkSingle,
    //         verifyDomain: verifyDomain,
    //         removeDuplicatesByEmail: removeDups,
    //         checkForPrefixes: checkPrefix,
    //         checkForSuffixes: checkSuffix,
    //         verifyEmailSyntax: verifyEmailSyntax,
    //         removeWhitespace: checkWhiteSpace,
    //         importType: checked,
    //         columnMap: JSON.stringify(finalMap),
    //         sendEmail: emailme,
    //         sendNotification: notifyme,
    //         type: type,
    //       },
    //     };

    //     axios
    //       .get(url2, config)
    //       .then((response) => {
    //         setWaitingForFileUpload(false);
    //         setSuccess(response.data);
    //       })
    //       .catch((error) => {
    //         console.error(error);
    //         setWaitingForFileUpload(false);
    //         setFaliure(error);
    //       });
    //   } else {
    //     if (useCleaner) {
    //       const url = process.env.REACT_APP_UPLOADER_API_KEY;
    //       let type = "";
    //       const formData = new FormData();
    //       if (!doSelected) {
    //         formData.append("file", file);
    //         type = "csv";
    //       } else {
    //         let csvContent = toCSV(selected, "salesforceselected");
    //         const theFile = new File([csvContent], "foo.csv", {
    //           type: "text/csv",
    //         });
    //         formData.append("file", theFile);
    //         type = "Salesforce";
    //       }

    //       let finalMap = {};
    //       let keys = Object.keys(headerMap);
    //       for (let k of keys) {
    //         if (headerMap[k]?.columns?.length > 0 || headerMap[k]?.new) {
    //           if (firstname.test(k.toLowerCase())) {
    //             finalMap["firstName"] = headerMap[k];
    //           } else if (lastname.test(k.toLowerCase())) {
    //             finalMap["lastName"] = headerMap[k];
    //           } else if (k.toLowerCase() === "email") {
    //             finalMap["email"] = headerMap[k];
    //           } else {
    //             finalMap[k] = headerMap[k];
    //           }
    //         }
    //       }

    //       const config = {
    //         withCredentials: true,
    //         headers: {
    //           Accept: "application/json",
    //           "Content-Type": "multipart/form-data",
    //         },
    //         params: {
    //           audienceId: sendAudienceId,
    //           organizationId: user.organizationId,
    //           appUserId: user.id,
    //           singleCharacterOrLetter: checkSingle,
    //           verifyDomain: verifyDomain,
    //           removeDuplicatesByEmail: removeDups,
    //           checkForPrefixes: checkPrefix,
    //           checkForSuffixes: checkSuffix,
    //           verifyEmailSyntax: verifyEmailSyntax,
    //           removeWhitespace: checkWhiteSpace,
    //           importType: checked,
    //           columnMap: JSON.stringify(finalMap),
    //           sendEmail: emailme,
    //           sendNotification: notifyme,
    //           type: type,
    //         },
    //       };

    //       axios
    //         .post(url, formData, config)
    //         .then((response) => {
    //           // setWaitingForFileUpload(false);
    //           setUploadId(response.data?.upload_id);
    //           console.log(response.data);
    //         })
    //         .catch((error) => {
    //           console.error(error);
    //           // setWaitingForFileUpload(false);
    //           // setFaliure(error);
    //         });
    //     } else {
    //       let fileContents = await submitFile(file, checkedList);
    //       let uploadableContacts = fileContents.finalArray.map((c) => {
    //         let newC = {
    //           firstName: c.firstName,
    //           lastName: c.lastName,
    //           email: c.email,
    //         };
    //         delete c.firstName;
    //         delete c.lastName;
    //         delete c.email;
    //         newC = { ...newC, customField: JSON.stringify({ ...c }) };
    //         return newC;
    //       });
    //       let uploadInput = {
    //         status: "Uploading",
    //         type: "Audience",
    //         totalWarnings: fileContents.warnCount,
    //         // appUserId: user.id,
    //         // organizationId: user.organizationId,
    //         badContacts: JSON.stringify(fileContents.badContacts),
    //         duplicateContacts: JSON.stringify(fileContents.duplicates),
    //         audienceId: audienceId,
    //         contact: uploadableContacts,
    //       };
    //       uploadContactsMute.mutate(
    //         {
    //           data: uploadInput,
    //         },
    //         {
    //           onSuccess: () => {
    //             setWaitingForFileUpload(false);
    //           },
    //         }
    //       );
    //     }
    //   }
    // } catch (e) {
    //   console.error(e);
    //   setWaitingForFileUpload(false);
    //   setFaliure(error);
    // }
  }

  const [page, setPage] = useState(0);

  const paginate = (newDirection) => {
    if (manual && newDirection === 1) {
      setFile();
    }
    setPage(newDirection);
  };

  const navigate = useNavigate();

  return (
    <div className={styles.page}>
      {" "}
      <div className={`${styles.body2}`}>
        {!salesforce && !manual && (
          <div className={styles.top}>
            <div className={styles.mainheader}>
              Import contacts into{" "}
              {audience ? <>"{audience?.name}"</> : "Reaction"} from a file{" "}
              <span>(.csv or .xlsx)</span>
            </div>
            {!success && !waitingForFileUpload && !failure && (
              <div
                className={styles.mainheader}
                style={{ paddingTop: "0em", height: "30px" }}
              >
                <span className={`${page === 0 && styles.active}`}>
                  Step 1: Choose file and settings
                </span>

                <span className={`${page === 1 && styles.active}`}>
                  Step 2: Provide your column mapping
                </span>
              </div>
            )}
          </div>
        )}
        {salesforce && (
          <div className={styles.top}>
            <div className={styles.mainheader}>
              {" "}
              <img src={salesforceImage} height={40}></img>
              Import contacts from Salesforce
              {audience && <> to "{audience?.name}"</>}
            </div>
            {!success && !waitingForFileUpload && !failure && (
              <div
                className={styles.mainheader}
                style={{ paddingTop: "0em", height: "30px" }}
              >
                <span>Step 1: Choose how to import</span>
                <span>Step 2: Choose who to import</span>
                <span className={`${page === 0 && styles.active}`}>
                  Step 3: Choose where to import with settings
                </span>
                <span className={`${page === 1 && styles.active}`}>
                  Step 4: Provide your column mapping
                </span>
              </div>
            )}
          </div>
        )}

        {manual && (
          <div className={styles.top}>
            <div className={styles.mainheader}>
              Import contacts into{" "}
              {audience ? <>"{audience?.name}"</> : "Reaction"} manually{" "}
            </div>
            {!success && !waitingForFileUpload && !failure && (
              <div
                className={styles.mainheader}
                style={{ paddingTop: "0em", height: "30px" }}
              >
                <span className={`${page === 0 && styles.active}`}>
                  Step 1: Add contacts and choose settings
                </span>

                <span className={`${page === 1 && styles.active}`}>
                  Step 2: Provide your column mapping
                </span>
              </div>
            )}
          </div>
        )}

        {page === 1 && !success && !waitingForFileUpload && !failure && (
          <ColumnMapping
            data={data}
            headers={headers}
            headerMap={headerMap}
            setHeaderMap={setHeaderMap}
            orgId={organizationId}
          ></ColumnMapping>
        )}
        {page === 0 && (
          <div
            className={`${manual ? styles.bodymanual : styles.body}`}
            style={{ flexWrap: window.innerWidth < 700 ? "wrap" : "" }}
          >
            {!headers && !salesforce && !manual && (
              <div className={styles.nodata}>
                <form id="myForm" className={styles.myForm}>
                  <input
                    type="file"
                    id="csvFile"
                    accept=".csv, .xlsx"
                    className={styles.csvFile}
                    onChange={handleOnChange}
                  />
                </form>
              </div>
            )}

            {manual && (
              <Manual
                isAudience={audienceId ? true : false}
                audienceId={audienceId}
                customFields={customFields}
                setData={setData}
                data={data}
                setHeaderMap={setHeaderMap}
                setHeaders={setHeaders}
                checkMap={checkMap}
              ></Manual>
            )}

            {headers &&
              !success &&
              !waitingForFileUpload &&
              !failure &&
              !manual && (
                <div className={styles.data}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      paddingLeft: "2em",
                    }}
                  >
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        gap: "2em",
                        alignItems: "flex-end",
                      }}
                    >
                      {!salesforce && <span> File Name: "{file?.name}" </span>}
                      <span>
                        {" "}
                        Rows:{" "}
                        {!doFilter
                          ? data?.length
                          : `Previewing ${data?.length} of ${numImport}`}
                      </span>
                      <span>Columns: {headers?.length}</span>
                    </div>
                    {file && (
                      <div>
                        <Button
                          onClick={(e) => {
                            setFile(null);
                            setHeaders(null);
                          }}
                          shadow
                          yellow
                          style={{ marginBottom: "5px" }}
                          // disable={!file}
                          // className={styles.submitButton}
                        >
                          Remove File
                        </Button>
                      </div>
                    )}
                  </div>

                  <BasicTable
                    data={data}
                    initheaders={headers}
                    id="ajsdfpoijfsopei"
                    emptyMessage={
                      "Choose a csv file and its contents will show up here"
                    }
                    rowNumbers
                    paginateScroll
                    style={{
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderRadius: ".5rem",
                    }}
                  ></BasicTable>
                </div>
              )}
            {!success && !failure && (
              <div className={styles.aboutbox}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1em",
                  }}
                >
                  {/* {!waitingForFileUpload && (
              <div
                className={styles.settingsbox}
                id="settingsboxid"
                style={{ padding: "1em" }}
              >
                <div className={styles.uploadbox} id="uploadBoxid">
                  <div className={styles.setting}>
                    <ToggleSwitch
                      startChecked={useCleaner}
                      handleCheck={() => setUseCleaner(!useCleaner)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexSwitchCheckDefault"
                    >
                      Clean List On Upload
                    </label>
                  </div>
                  <form id="myForm" className={styles.myForm}>
                    <input
                      type="file"
                      id="csvFile"
                      accept=".csv, .xlsx"
                      className={styles.csvFile}
                      onChange={handleOnChange}
                      data-buttonText="hi"
                    />
                    <Button
                      onClick={(e) => {
                        handleOnSubmit(e, file);
                        // setFinalList(submitted(e, file, setShow));
                        // console.log(finalList);
                      }}
                      id="submitButton"
                      shadow
                      disable={!file}
                      // className={styles.submitButton}
                    >
                      {buttonName ? buttonName : "Submit"}
                    </Button>
                  </form>
                </div>
              </div>
            )} */}
                  {useCleaner && !waitingForFileUpload && enableErrors && (
                    <div className={styles.settingsbox} id="settingsboxid">
                      <div className={styles.header}>
                        <span>Where to import</span>{" "}
                        {/* <i className="bi bi-question-circle"> </i> */}
                      </div>
                      <div
                        className={styles.nodata}
                        style={{
                          gap: ".5em",
                          height: "40px",
                          border: "none",
                          width: "300px",
                          fontSize: "1em",
                          boxShadow: "none",
                          backgroundColor: "transparent",
                        }}
                      >
                        Import {numImport} contacts to{" "}
                        <select
                          value={importType}
                          onChange={(e) => setImportType(e.target.value)}
                        >
                          <option value={"contacts"}>all contacts</option>
                          <option value={"audience"}>an audience</option>
                        </select>
                      </div>
                      {importType === "audience" && (
                        <AudienceCheck
                          audienceId={sendAudienceId}
                          audience={audience}
                          setAudienceId={setSendAudienceId}
                        ></AudienceCheck>
                      )}
                      <div id="switchbox" className={styles.switchbox}>
                        <div className={styles.header}>
                          <span>Flag contact if...</span>{" "}
                          {/* <i className="bi bi-question-circle"> </i> */}
                        </div>
                        {/* <div className={styles.charlengthbox}>
                    <div className={styles.setting} id="charlength">
                      <ToggleSwitch id="charlengthCheck" startChecked />
                      <label
                        // className={styles.charlengthtext}
                        htmlFor="flexSwitchCheckDefault"
                        id="charlengthtext"
                      >
                        Character length over:
                      </label>
                      <input
                        type="number"
                        min="0"
                        className={styles.charlengthnum}
                        aria-label="Small"
                        aria-describedby="inputGroup-sizing-sm"
                        placeholder="#"
                        id="charlengthnum"
                        defaultValue={20}
                      />
                    </div>
                  </div> */}
                        <div className={styles.setting}>
                          <ToggleSwitch
                            startChecked={checkSingle}
                            id="singlechar"
                            handleCheck={(val) => setCheckSingle(val)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexSwitchCheckDefault"
                          >
                            Single character or letter
                          </label>
                        </div>
                        <div className={styles.setting}>
                          <ToggleSwitch
                            startChecked={verifyEmailSyntax}
                            id="atCheck"
                            handleCheck={(val) => setVerifyEmailSyntax(val)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexSwitchCheckDefault"
                          >
                            Wrong Email Syntax
                          </label>
                        </div>
                        <div className={styles.setting}>
                          <ToggleSwitch
                            startChecked={checkPrefix}
                            id="prefixCheck"
                            handleCheck={(val) => setCheckPrefix(val)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexSwitchCheckDefault"
                          >
                            Check for prefixes
                          </label>
                        </div>
                        <div className={styles.setting}>
                          <ToggleSwitch
                            startChecked={checkSuffix}
                            id="suffixCheck"
                            handleCheck={(val) => setCheckSuffix(val)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexSwitchCheckDefault"
                          >
                            Check for suffixes
                          </label>
                        </div>
                        {/* <div className={styles.setting}>
                    <ToggleSwitch
                      startChecked={verifyDomain}
                      id="domainCheck"
                      handleCheck={(val) => setVerifyDomain(val)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexSwitchCheckDefault"
                    >
                      Unrecognized Email Domain
                    </label>
                  </div> */}

                        <div
                          className={styles.header}
                          style={{ marginTop: "1em" }}
                        >
                          <span>Advanced Settings</span>{" "}
                          {/* <i className="bi bi-question-circle"> </i> */}
                        </div>
                        <div className={styles.setting}>
                          <ToggleSwitch
                            startChecked={checkWhiteSpace}
                            id="whitespace"
                            handleCheck={(val) => setCheckWhiteSpace(val)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexSwitchCheckDefault"
                          >
                            Remove Whitespace
                          </label>
                        </div>

                        <div className={styles.radioLabel}>
                          <div
                            style={{
                              width: "41px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {" "}
                            <input
                              id="sortByOg"
                              type="radio"
                              name="sortByOg"
                              value="none"
                              onChange={(e) => setChecked("add-update")}
                              checked={checked === "add-update"}
                              style={{ borderRadius: "50%" }}
                            />
                          </div>
                          <div
                            className={styles.setting}
                            style={{ gap: "5px" }}
                          >
                            Add new and Update existing{" "}
                            <Label
                              labelIcon={<i className="bi-question-circle"></i>}
                              tooltipText={
                                "On upload, contacts that do not exist in the database will be added and those that will already exist will be updated with the new columns and properties. If an existing contact has columns that are not including in this upload, those columns will not be changed."
                              }
                              style={{
                                width: "fit-content",
                                padding: "0em",
                                margin: "0em",
                              }}
                              tooltipStyle={{
                                padding: "2em",
                                width: "300px",
                                right: "0px",
                              }}
                            ></Label>
                          </div>
                        </div>
                        <div className={styles.radioLabel}>
                          <div
                            style={{
                              width: "42px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {" "}
                            <input
                              id="sortByOg"
                              type="radio"
                              name="sortByOg"
                              value="none"
                              // onChange={(e) => setChecked("add")}
                              checked={checked === "add"}
                              style={{ borderRadius: "50%" }}
                            />
                          </div>
                          <div
                            className={styles.setting}
                            style={{ gap: "5px" }}
                          >
                            Add new contacts <span>(coming soon...)</span>
                          </div>
                        </div>
                        <div className={styles.radioLabel}>
                          <div
                            style={{
                              width: "41px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {" "}
                            <input
                              id="sortByOg"
                              type="radio"
                              name="sortByOg"
                              value="none"
                              // onChange={(e) => setChecked("update")}
                              checked={checked === "update"}
                              style={{ borderRadius: "50%" }}
                            />
                          </div>
                          <div
                            className={styles.setting}
                            style={{ gap: "5px" }}
                          >
                            Update existing contacts
                            <span>(coming soon...)</span>
                          </div>
                        </div>
                        <div
                          className={styles.header}
                          style={{ marginTop: "1em" }}
                        >
                          <span>Notifications</span>{" "}
                          {/* <i className="bi bi-question-circle"> </i> */}
                        </div>
                        <div className={styles.setting}>
                          <ToggleSwitch
                            startChecked={notifyme}
                            id="whitespace"
                            handleCheck={(val) => setnotifyme(val)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexSwitchCheckDefault"
                          >
                            Send notification on import
                          </label>
                        </div>
                        <div className={styles.setting}>
                          <ToggleSwitch
                            startChecked={emailme}
                            id="whitespace"
                            handleCheck={(val) => setEmailme(false)}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexSwitchCheckDefault"
                          >
                            Email me import details{" "}
                            <span>(coming soon...)</span>
                            {/* <Label
                              labelIcon={<i className="bi-question-circle"></i>}
                              tooltipText={
                                "If toggled, you will recieve an email with all of the details of this upload, including a downloadable .csv file of those who were not uploaded due to errors."
                              }
                              style={{
                                width: "fit-content",
                                padding: "0em",
                                margin: "0em",
                              }}
                              tooltipStyle={{
                                padding: "2em",
                                width: "300px",
                                right: "0px",
                              }}
                              
                            ></Label> */}
                          </label>
                        </div>
                      </div>

                      {/* <div style={{ display: "flex", justifyContent: "center" }}>

                    <Button
                      onClick={(e) => {
                        handleOnSubmit(e, file);
                      }}
                      id="submitButton"
                      shadow
                      disable={!file && !salesforce}
                    >
                      {buttonName ? buttonName : "Submit"}
                    </Button>
                  </div> */}
                    </div>
                  )}
                  {/* {useCleaner && !waitingForFileUpload && (
              <div className={styles.settingsbox} id="settingsboxid">
                <div id="switchbox" className={styles.switchbox}>
                  <div className={styles.header}>
                    <span>Advanced Settings</span>{" "}
                    <i className="bi bi-question-circle"> </i>
                  </div>

                  <div className={styles.setting}>
                    <ToggleSwitch
                      startChecked={update}
                      id="singlechar"
                      handleCheck={(val) => setUpdate(val)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexSwitchCheckDefault"
                    >
                      Update existing contact on upload
                    </label>
                  </div>
                  <div
                    className={styles.setting}
                    style={{ paddingLeft: "3em" }}
                  >
                    <ToggleSwitch
                      startChecked={autoUpdate}
                      id="singlechar"
                      handleCheck={(val) => setAutoUpdate(val)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexSwitchCheckDefault"
                    >
                      Auto update contact
                    </label>
                  </div>
                  <div className={styles.setting}>
                    <ToggleSwitch
                      startChecked={autoUpload}
                      id="domainCheck"
                      handleCheck={(val) => setAutoUpload(val)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexSwitchCheckDefault"
                    >
                      Auto upload cleaned contacts
                    </label>
                  </div>
                  <div className={styles.setting}>
                    <ToggleSwitch
                      startChecked={whitespace}
                      id="domainCheck"
                      handleCheck={(val) => setwhitespace(val)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexSwitchCheckDefault"
                    >
                      Remove whitespace
                    </label>
                  </div>
                  <div className={styles.setting}>
                    <ToggleSwitch
                      startChecked={removeDups}
                      id="removeDupes"
                      handleCheck={(val) => setRemoveDups(val)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexSwitchCheckDefault"
                    >
                      Check for duplicate emails
                    </label>
                  </div>
                  <div className={styles.setting}>
                    <ToggleSwitch
                      startChecked={enableErrors}
                      id="domainCheck"
                      handleCheck={(val) => setEnableErrors(val)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexSwitchCheckDefault"
                    >
                      Enable flag contacts
                    </label>
                  </div>
                </div>
              </div>
            )} */}
                </div>
              </div>
            )}
          </div>
        )}
        <div className={styles.loading}>
          {waitingForFileUpload && !success && !failure && (
            <div className={styles.successContainer}>
              <div className={styles.success}>
                <div className={styles.uploading}>
                  {" "}
                  Import process initiated. Feel free to leave this page. <br />{" "}
                  You will be notified when the process is complete
                  <Loading height={300} width={300}></Loading>
                  <Button
                    link
                    onClick={() => navigate("/previous-imports/" + uploadId)}
                  >
                    Click here to view upload progress
                  </Button>
                </div>
                {/* 
            <div
              style={{ display: "flex", justifyContent: "end", width: "100%" }}
            >
              <Button shadow>
                Go Back To Audience
              </Button>
            </div> */}
              </div>
            </div>
          )}
          {success && !failure && (
            <div className={styles.successContainer}>
              <div className={styles.success}>
                Your Upload was successful!{" "}
                {audienceId && (
                  <>
                    <br /> Contacts Added: {success?.num_added_to_audience}
                  </>
                )}
                <br /> Contacts Created: {success?.num_of_uploads} <br />{" "}
                Contacts Updated: {success?.num_of_updates} <br /> Contacts
                Failed: {success?.num_of_bad_contacts}
                <br /> Duplicates Removed: {success?.num_of_duplicates}
                <div
                  onClick={() => {
                    navigate(`/previous-imports/${success?.upload_id}`);
                  }}
                  className={styles.link}
                >
                  View Full Details
                </div>
              </div>
            </div>
          )}
          {failure && (
            <div className={styles.successContainer}>
              <div className={styles.success}>
                Your Upload has failed! <br />
                {`${failure}`}
              </div>
            </div>
          )}
        </div>
      </div>
      {page === 0 && !waitingForFileUpload && !success && !failure && (
        <div className={styles.footer}>
          {!salesforce && (
            <Button onClick={(e) => history.back()} shadow link>
              Cancel
            </Button>
          )}
          {salesforce && (
            <Button
              onClick={(e) => setImporting(false)}
              shadow
              disable={!file && !salesforce}
              link
            >
              Previous
            </Button>
          )}
          <Button
            onClick={(e) => {
              // setImportPage(true);
              paginate(1);
            }}
            blue
            shadow
            disable={!file && !salesforce && data.length < 1}
            // className={styles.submitButton}
          >
            Next
          </Button>
        </div>
      )}
      {page === 1 && !waitingForFileUpload && !success && !failure && (
        <div className={styles.footer}>
          <Button
            onClick={(e) => {
              // setImportPage(false);
              paginate(0);
            }}
            id="submitButton"
            shadow
            disable={!file && !salesforce && data.length < 1}
            link
          >
            Previous
          </Button>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={(e) => {
                if (!creatingUpload) {
                  handleOnSubmit(e, file);
                }
              }}
              id="submitButton"
              shadow
              blue
              disable={!file && !salesforce && data.length < 1}
            >
              {!creatingUpload && (
                <>{salesforce || manual ? "Start Import" : "Import File"}</>
              )}
              {creatingUpload && (
                <>
                  Prepping import <Loading2 height={20} width={20}></Loading2>
                </>
              )}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

function AudienceCheck({ audienceId, audience, setAudienceId }) {
  const [perPage, setPerpage] = useState(20);
  const [pageSkip, setPageSkip] = useState(0);

  const [searchSort, setSearchSort] = useState({
    item: "updatedAt",
    descend: true,
  });

  const getAudiences = useFetchAudiencesSimple(pageSkip, perPage, searchSort);

  return (
    <>
      {getAudiences.isLoading && <Loading2 height={70} width={70}></Loading2>}
      {getAudiences.isSuccess && (
        <CombinedInput
          value={audienceId ? { value: audienceId, label: audience?.name } : ""}
          options={getAudiences?.data?.audiences?.map((a) => {
            return { value: a?.id, label: a?.name };
          })}
          onChange={(val) => setAudienceId(val.value)}
          style={{ width: "300px", backgroundColor: "white" }}
          label="Select Audience"
          select
          search
          placeholder={"Choose audience..."}
          icon={"bi-chevron-down"}
        ></CombinedInput>
      )}
    </>
  );
}
