import { TextFieldSimple } from "components/inputs";
import styles from "./EditTags.module.scss";
import { useEffect, useRef, useState } from "react";
import { ColorPicker } from "components/inputs/input_fields/ColorPicker/ColorPicker";
import Button from "components/Button/Button";
import {
  useAddQToQTag,
  useCreateQuestionTag,
  useRemoveTagFromQ,
  useSearchQTags,
} from "api/resources/organization/questiontag";
import { Loading } from "components/Loading/Loading";
import { Label } from "components/layouts/Label/Label";
import {
  MultChoice,
  Scale,
  Text,
  getQuestionType,
} from "pages/results/Charts/Settings/AccordianItems/SurveyData/QData";

export default function EditTags({
  question,
  saveQuestion,
  close,
  allQuestions,
}) {
  const createTag = useCreateQuestionTag();
  const removeTagFromQ = useRemoveTagFromQ();
  const addTagToQ = useAddQToQTag();

  const ref = useRef();
  const colorRef = useRef();

  const [newTag, setNewTag] = useState({
    name: "",
    color: "",
    description: "",
    type: getQuestionType(question),
  });
  const [addNew, setAddNew] = useState(false);

  const [tags, setTags] = useState([...question.questionTag]);

  const [create, setCreate] = useState("Create");

  function updateTags(newTags) {
    question.questionTag = newTags;
    setTags(newTags);
  }

  function clickOut(event) {
    if (
      !colorRef.current &&
      ref.current &&
      !ref.current?.contains(event.target)
    ) {
      close();
    }
  }

  useEffect(() => {
    document.addEventListener("click", clickOut, true);
    return () => {
      document.removeEventListener("click", clickOut, true);
    };
  }, []);

  function handleCreateTag() {
    setCreate(
      <>
        Creating <Loading height={30} width={30}></Loading>
      </>
    );
    createTag.mutate(
      {
        data: newTag,
        questionId: question.id,
      },
      {
        onSuccess: (data) => {
          // console.log("tag created", data);
          setCreate(
            <>
              Created <i className="bi bi-check"></i>
            </>
          );
          setTimeout(() => {
            setCreate("Create");
            setAddNew(false);
            updateTags([...tags, data.tag]);
            setNewTag({
              name: "",
              color: "",
              description: "",
            });
          }, 750);
        },
      }
    );
  }

  function removeTag(tag, i) {
    let copy = [...tags];
    copy.splice(i, 1);
    updateTags(copy);

    removeTagFromQ.mutate(
      {
        tagId: tag.id,
        questionId: question.id,
      },
      {
        onSuccess: (data) => {
          console.log("tag removed", data);
        },
      }
    );
  }

  function addTag(tag) {
    addTagToQ.mutate(
      { tagId: tag.id, questionId: question.id },
      {
        onSuccess: (data) => {
          // console.log("tag added", data);
          updateTags([...tags, tag]);
        },
      }
    );
    setAddNew(false);
  }

  function toggleIsScore() {
    let copy = { ...question };
    copy.isScore = !copy.isScore;
    saveQuestion(copy);
  }

  function toggleIsComment() {
    let copy = { ...question };
    copy.isComment = !copy.isComment;
    saveQuestion(copy);
  }

  return (
    <div className={styles.settingsContainer} ref={ref}>
      <div className={styles.close} onClick={close}>
        <i className="bi bi-x-lg"></i>
      </div>

      <div className={styles.questionName}>
        Tags for "{question?.questionText}"
      </div>

      <div className={styles.currentTagsContainer}>
        <Label
          style={{ color: "#A4C6D0" }}
          labelIcon={<i className="bi bi-info-circle"></i>}
          tooltipText={"Question Tags help us know what to look for in reports"}
          tooltipStyle={{ color: "#2A627C" }}
        >
          Tags <i className="bi bi-tags" style={{ paddingRight: "5px" }}></i>
        </Label>

        <div className={styles.tags}>
          {question?.isScore && (
            <div className={styles.tag}>
              <span className={styles.tagName}>NPS</span>
              <i
                className={`bi bi-tag-fill ${styles.tagIcon}`}
                style={{ color: "#15bcc7" }}
              ></i>
              <div className={styles.deleteTag}>
                <i className="bi bi-trash-fill" onClick={toggleIsScore}></i>
              </div>
            </div>
          )}
          {question?.isComment && (
            <div className={styles.tag}>
              <span className={styles.tagName}>NPS Comment</span>
              <i
                className={`bi bi-tag-fill ${styles.tagIcon}`}
                style={{ color: "#15bcc7" }}
              ></i>
              <div className={styles.deleteTag}>
                <i className="bi bi-trash-fill" onClick={toggleIsComment}></i>
              </div>
            </div>
          )}
          {tags.map((tag, i) => (
            <div className={styles.tag}>
              <span className={styles.tagName}>{tag.name}</span>
              {tag.color && (
                // <div
                //   className={styles.tagColor}
                //   style={{ backgroundColor: tag.color }}
                // ></div>
                <i
                  className={`bi bi-tag-fill ${styles.tagIcon}`}
                  style={{ color: tag.color }}
                ></i>
              )}
              <div className={styles.deleteTag}>
                <i
                  className="bi bi-trash-fill"
                  onClick={() => removeTag(tag, i)}
                ></i>
              </div>
            </div>
          ))}
        </div>
      </div>

      {!addNew && (
        <div className={styles.plusTag} onClick={() => setAddNew(true)}>
          + Tag
        </div>
      )}

      {addNew && (
        <div className={styles.addNewContainer}>
          <div className={styles.closeAddNew} onClick={() => setAddNew(false)}>
            <i className="bi bi-x"></i>
          </div>
          <div className={styles.addNew}>Add New</div>
          <div className={styles.twoCreateMethods}>
            <div className={styles.searchContainer}>
              <SearchTags
                question={question}
                addTag={addTag}
                addNPS={() => {
                  toggleIsScore();
                  setAddNew(false);
                }}
                addNPSComment={() => {
                  toggleIsComment();
                  setAddNew(false);
                }}
                includedTags={tags}
                allQuestions={allQuestions}
              />
            </div>

            <div className={styles.divider}>
              <div className={styles.bar}></div>
            </div>
            <div className={styles.createNewContainer}>
              <div className={styles.createNew}>Create New</div>
              <div className={styles.entry}>
                Name:
                <input
                  type={"text"}
                  onChange={(e) =>
                    setNewTag((old) => {
                      return { ...old, name: e.target.value };
                    })
                  }
                  // onBlur={handleFocusOut}
                  className={styles.nameInput}
                  value={newTag.name}
                  // placeholder={"Name"}
                ></input>
              </div>
              <div className={styles.entry} style={{ fontSize: ".9em" }}>
                Description:
                <input
                  type={"text"}
                  onChange={(e) =>
                    setNewTag((old) => {
                      return { ...old, description: e.target.value };
                    })
                  }
                  // onBlur={handleFocusOut}
                  className={styles.nameInput}
                  value={newTag.description}
                  style={{ fontSize: ".9em" }}
                  // placeholder={"Description"}
                ></input>
              </div>

              <div className={styles.entry} style={{ fontSize: ".9em" }}>
                Type:
                <div className={styles.tagType}>{newTag.type}</div>
              </div>
              <div className={styles.entry} style={{ gap: "12px" }}>
                Color:{" "}
                <ColorPicker
                  colorRef={colorRef}
                  onChange={(color) =>
                    setNewTag((old) => {
                      return { ...old, color: color };
                    })
                  }
                />
              </div>
              <div
                style={{
                  visibility: newTag.name ? "visible" : "hidden",
                  alignSelf: "center",
                  paddingTop: "5px",
                }}
              >
                <Button shadow height={25} onClick={handleCreateTag}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "6px",
                    }}
                  >
                    {create}
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function SearchTags({
  question,
  addTag,
  addNPS,
  addNPSComment,
  includedTags,
  allQuestions,
}) {
  const [searchString, setSearchString] = useState("");

  const searchTags = useSearchQTags(searchString, getQuestionType(question));

  const [active, setActive] = useState(false);

  function existsOtherNPSInSurvey() {
    for (let q of allQuestions) {
      if (q.id !== question.id) {
        if (q.isScore) return true;
      }
    }
    return false;
  }

  function existsOtherNPSCommentInSurvey() {
    for (let q of allQuestions) {
      if (q.id !== question.id) {
        if (q.isComment) return true;
      }
    }
    return false;
  }

  function relevantScoreSearch() {
    if (!searchString) {
      return true;
    }
    return "nps".includes(searchString.toLowerCase());
  }

  function relevantCommentSearch() {
    if (!searchString) {
      return true;
    }
    return "nps comment".includes(searchString.toLowerCase());
  }

  function getAllPossible() {
    let all = [...searchTags.data.tags].filter(
      (tag) => !includedTags.some((t) => t.id === tag.id)
    );
    return all;
  }

  const showNPS =
    question.scaleQuestion &&
    !question.isScore &&
    !question.scaleQuestion.min &&
    question.scaleQuestion.max == 10 &&
    !question.matrixQuestion &&
    !existsOtherNPSInSurvey() &&
    relevantScoreSearch();

  const showComment =
    question.textQuestion &&
    !question.isComment &&
    !question.matrixQuestion &&
    !existsOtherNPSCommentInSurvey() &&
    relevantCommentSearch();

  return (
    <div className={styles.searchDiv}>
      <div className={styles.searchLabel}>Search Question Tag</div>
      <input
        type="text"
        onChange={(e) => setSearchString(e.target.value)}
        className={styles.search}
        value={searchString}
        // placeholder=""
        onFocus={() => setActive(true)}
        onBlur={() => setTimeout(() => setActive(false), 200)}
        id="searchTag"
      ></input>
      <div className={styles.resultsAnchor}>
        {active && searchTags.isSuccess && (
          <div className={styles.results} id="searchTagResults">
            {getAllPossible().map((tag) => (
              <div className={styles.tagOption} onClick={() => addTag(tag)}>
                {tag.name}
                {tag.color && (
                  <i
                    className={`bi bi-tag-fill`}
                    style={{ color: tag.color, paddingTop: "3px" }}
                  ></i>
                )}
              </div>
            ))}
            {showNPS && (
              <div className={styles.tagOption} onClick={addNPS}>
                NPS
                <i
                  className={`bi bi-tag-fill`}
                  style={{ color: "#15bcc7", paddingTop: "3px" }}
                ></i>
              </div>
            )}
            {showComment && (
              <div className={styles.tagOption} onClick={addNPSComment}>
                NPS Comment{" "}
                <i
                  className={`bi bi-tag-fill`}
                  style={{ color: "#15bcc7", paddingTop: "3px" }}
                ></i>
              </div>
            )}

            {!getAllPossible().length && !showNPS && !showComment && (
              <div className={styles.noneLeft}>No Data</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
