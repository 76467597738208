// External
import React, { useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import "./Calendar.css";
import TimePicker from "react-time-picker/dist/entry.nostyle";
import "./TimePicker.css";
// Internal
import styles from "./SelectFieldCalendar.module.scss";
import { TextFieldSimple } from "../TextFieldSimple/TextFieldSimple";
import { Label } from "components/layouts/Label/Label";
import { maintimezones } from "assets/functions/Variables";
import { SelectField } from "../SelectField/SelectField";

/**
 * A custom select input component with no external libraries
 * @class
 * @property {object} options: The options that will be in the select menu. Should be a list of objects with 'label' and 'value' attributes
 * @property {object} defaultValue: One of the options in the options list (object with 'label' and 'value' attributes)
 * @property {boolean} selectMultiple: Whether users should be able to select multiple items
 * @property {function} onChange: A function that will be called when the user changes the selection. The value of the option will be returned.
 * @returns {React.ReactElement} Select Field component
 *
 * @example
 * <SelectField options={options} selectMultiple={false} />
 */
class SelectedDate {
  constructor(year, month, day, time, fullText, timestamp) {
    this.year = year;
    this.month = month;
    this.day = day;
    this.time = time;
    this.fullText = fullText;
    this.timestamp = timestamp;
  }
}

export const SelectFieldCalendar = ({
  // defaultValue,
  value,
  icon,
  values,
  selectMultiple,
  // searchFunction
  searchable,
  onChange,
  startDate,
  // newOptionText,
  // onRemoveOption
  placeholder,
  label,
  disabled,
  blockView,
  tooltipText,
  timeZone,
  onChangeTimeZone,
  finalDate,
}) => {
  const [date, changeDate] = useState(new Date(startDate));
  const [show, setShow] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [chosenValues, setChosenValues] = useState(values);
  const [time, setTime] = useState("10:00");
  const [dateObject, setDateObject] = useState(new SelectedDate());
  const [timezone, setTimezone] = useState(initZone());
  const [search, setSearch] = useState("");

  function initZone() {
    let zone = maintimezones.find((t) => t.value === timeZone);
    if (zone) {
      return zone;
    } else {
      return {
        timezone: "America/Denver",
        value: "America/Denver",
        offset: 25200,
        display: "GMT-07:00",
        label: "Mountain Time, US & Canada",
      };
    }
  }

  useEffect(() => {
    setTime(`${date.getHours()}:${date.getMinutes()}`);
  }, [date]);

  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  function handleRemoveOption(item) {
    //remove chosen option
    // onRemoveOption() a function passed into component

    let newValues = [...chosenValues];
    let index = newValues.indexOf(item);
    newValues.splice(index, 1);
    setChosenValues(newValues);
  }

  function commaList(array) {
    return array.map((item) => (
      <div key={item.id} className={styles.chosenOption}>
        {item} <button onClick={() => handleRemoveOption(item)}>x</button>
      </div>
    ));
  }

  function handleTimeZone(val) {
    if (onChangeTimeZone) {
      onChangeTimeZone(val);
    }
    setTimezone(val);
  }

  function handleChangeTime(time) {
    setTime(time);
    dateObject.timestamp = Date.parse(date) / 1000;
    dateObject.day = date.getDate();
    dateObject.month = date.getMonth() + 1;
    dateObject.year = date.getFullYear();

    const newDateTime = new Date(
      `${findDay(date.getDay())}, ${date.getDate()} ${findFullMonth(
        date.getMonth()
      )} ${date.getFullYear()} ${time}:00`
    );
    changeDate(newDateTime);
    dateObject.fullText = newDateTime;
    dateObject.time = time;
    onChange(dateObject);
  }
  function handleChangeDate(newDate) {
    dateObject.timestamp = Date.parse(newDate) / 1000;
    dateObject.day = newDate.getDate();
    dateObject.month = newDate.getMonth() + 1;
    dateObject.year = newDate.getFullYear();
    dateObject.time = time + ":00";
    changeDate(newDate);
    const newDateTime = new Date(
      `${findDay(newDate.getDay())}, ${newDate.getDate()} ${findFullMonth(
        newDate.getMonth()
      )} ${newDate.getFullYear()} ${time}`
    );
    dateObject.fullText = newDateTime;
    onChange(dateObject);
  }

  let nullDate = new Date(null);

  const isDateGiven = () => {
    return date.toDateString() !== nullDate.toDateString();
  };
  const calenderDate = () => {
    if (date.toDateString() === nullDate.toDateString()) {
      return new Date();
    }
    return date;
  };

  const handleClear = () => {
    setShow(!show);
    changeDate(nullDate);
    setShow(!show);
    let x = new SelectedDate();
    x.timestamp = Date.parse(nullDate);
    onChange(nullDate);
  };
  return (
    <>
      {!blockView && (
        <div className={styles.selectContainer} ref={ref}>
          <Label style={{ paddingLeft: ".75em" }}>{label}</Label>
          {!searchable && (
            <div
              className={styles.select}
              onClick={() => {
                if (!disabled) {
                  setShow(!show);
                }
              }}
            >
              {!selectMultiple && (
                <div className={styles.activeOption}>
                  {icon}
                  {isDateGiven()
                    ? `${findDay(date.getDay())}, ${findMonth(
                        date.getMonth()
                      )} ${date.getDate()} ${date.getFullYear()} at ${militaryTimeConverter(
                        `${time}`
                      )}`
                    : placeholder}
                </div>
              )}
              {selectMultiple && (
                <div className={styles.activeOption}>
                  {icon}
                  {chosenValues ? commaList(chosenValues) : value}
                </div>
              )}

              <i className="bi bi-chevron-down"></i>
            </div>
          )}

          {show && (
            <div className={styles.dropdown}>
              <Calendar
                onChange={disabled ? "" : handleChangeDate}
                value={calenderDate()}
                // tileDisabled={disabled}
              />
              <div>
                at:{" "}
                <TimePicker
                  onChange={disabled ? "" : handleChangeTime}
                  value={time}
                />
              </div>
            </div>
          )}
          {!disabled && (
            <div className={styles.clear} onClick={handleClear}>
              <i className="bi bi-x"></i>
            </div>
          )}
        </div>
      )}
      {blockView && (
        <div className={styles.calendarContainer} style={{ fontSize: ".8em" }}>
          <Calendar
            onChange={disabled ? "" : handleChangeDate}
            value={calenderDate()}
            // tileDisabled={disabled}
            className={styles.calendar}
          />

          <div className={styles.dateandtime}>
            <div className={styles.timePickerContainer}>
              <Label style={{ paddingLeft: ".75em" }}>Time</Label>
              <TimePicker
                onChange={disabled ? "" : handleChangeTime}
                value={time}
                className={styles.timePicker}
              />
            </div>

            <SelectField
              value={timezone}
              label="Time Zone"
              options={maintimezones}
              onChange={(val) => handleTimeZone(val)}
              noCreate
            ></SelectField>
            {finalDate && (
              <TextFieldSimple
                label="Final Date"
                value={`${findDay(date.getDay())}, ${findMonth(
                  date.getMonth()
                )} ${date.getDate()} ${date.getFullYear()} at ${militaryTimeConverter(
                  `${time}`
                )}`}
                disable
                style={{ border: "none", fontWeight: "600", fontSize: "1.2em" }}
              ></TextFieldSimple>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export function findDay(dayNumber) {
  if (dayNumber == 0) {
    return "Sun";
  } else if (dayNumber == 1) {
    return "Mon";
  } else if (dayNumber == 2) {
    return "Tue";
  } else if (dayNumber == 3) {
    return "Wed";
  } else if (dayNumber == 4) {
    return "Thur";
  } else if (dayNumber == 5) {
    return "Fri";
  } else {
    return "Sat";
  }
}

export function findMonth(dayNumber) {
  if (dayNumber == 0) {
    return "Jan";
  } else if (dayNumber == 1) {
    return "Feb";
  } else if (dayNumber == 2) {
    return "Mar";
  } else if (dayNumber == 3) {
    return "Apr";
  } else if (dayNumber == 4) {
    return "May";
  } else if (dayNumber == 5) {
    return "Jun";
  } else if (dayNumber == 6) {
    return "Jul";
  } else if (dayNumber == 7) {
    return "Aug";
  } else if (dayNumber == 8) {
    return "Sep";
  } else if (dayNumber == 9) {
    return "Oct";
  } else if (dayNumber == 10) {
    return "Nov";
  } else {
    return "Dec";
  }
}

export function findFullMonth(dayNumber) {
  if (dayNumber == 0) {
    return "January";
  } else if (dayNumber == 1) {
    return "February";
  } else if (dayNumber == 2) {
    return "March";
  } else if (dayNumber == 3) {
    return "April";
  } else if (dayNumber == 4) {
    return "May";
  } else if (dayNumber == 5) {
    return "June";
  } else if (dayNumber == 6) {
    return "July";
  } else if (dayNumber == 7) {
    return "August";
  } else if (dayNumber == 8) {
    return "September";
  } else if (dayNumber == 9) {
    return "October";
  } else if (dayNumber == 10) {
    return "November";
  } else {
    return "December";
  }
}

export function militaryTimeConverter(time) {
  // var time = "16:30:00"; // your input

  time = time.split(":"); // convert to array

  // fetch
  var hours = Number(time[0]);
  var minutes = Number(time[1]);
  // var seconds = Number(time[2]);

  // calculate
  var timeValue;
  if (hours > 0 && hours <= 12) {
    timeValue = "" + hours;
  } else if (hours > 12) {
    timeValue = "" + (hours - 12);
  } else if (hours == 0) {
    timeValue = "12";
  }

  timeValue += minutes < 10 ? ":0" + minutes : ":" + minutes; // get minutes
  // timeValue += (seconds < 10) ? ":0" + seconds : ":" + seconds;  // get seconds
  timeValue += hours >= 12 ? " PM" : " AM"; // get AM/PM
  return timeValue;
}
