import styles from "./PickDashTable.module.scss";
import InteractiveTable from "components/tables/BasicTable/InteractiveTable";
import {
  useFetchUsersByOrgId,
  useFetchUsersByOrgIdCount,
} from "api/resources/organization/users";
import { useEffect, useState } from "react";
import { useFetchMyOrg } from "api/resources/organization/organization";
import { Loading } from "components/Loading/Loading";
import { useFetchOrgRolesList } from "api/resources/organization/roles";
import { BasicTable } from "components/tables/BasicTable/BasicTable";

export function PickUser({ onSelectUser, toggle, role, user }) {
  const myOrg = useFetchMyOrg();

  const [pageNumber, setPageNumber] = useState(1);
  const [searchString, setSearchString] = useState("");
  const [perPage, setPerpage] = useState(10);
  const [pageSkip, setPageSkip] = useState(0);

  const [sort, setSort] = useState({
    descend: false,
    item: "firstName",
  });

  const getUsers = useFetchUsersByOrgId(
    searchString,
    pageSkip,
    perPage,
    sort,
    user.organizationId
  );

  const getUsersCount = useFetchUsersByOrgIdCount(
    searchString,
    pageSkip,
    perPage,
    sort,
    user.organizationId
  );

  const headers = [
    {
      id: 0,
      name: "First Name",
      accessor: "firstName",
      enabled: true,
      sort: false,
      canSort: true,
      cell_style: null,
    },
    {
      id: 1,
      name: "Last Name",
      accessor: "lastName",
      enabled: true,
      sort: true,
      canSort: true,
      cell_style: null,
    },
    {
      id: 2,
      name: "Role",
      // accessor: "role",
      // objectAccessor: "name",
      enabled: true,
      sort: false,
      canSort: false,
      cell_style: (u) => <div>{u && getRoles(u)}</div>,
    },
  ];

  function getRoles(u) {
    let role = u?.roles.find(
      (role) => role?.organizationId === user.organizationId
    );
    return role?.name;
  }

  useEffect(() => {
    getUsers.refetch();
    getUsersCount.refetch();
  }, [pageNumber, sort, perPage]);

  return (
    <>
      {toggle && <div className={styles.toggleHolder}>{toggle}</div>}
      <div className={styles.container}>
        {myOrg.isLoading && <Loading />}
        {myOrg.isSuccess && (
          <div className={styles.holder}>
            <InteractiveTable
              headers={headers}
              queryData={getUsers}
              queryDataAccessor={"users"}
              maxDataQuery={getUsersCount}
              maxDataQueryAccessor={"count"}
              currPage={pageNumber}
              setCurrPage={setPageNumber}
              onRowClick={onSelectUser}
              sort={sort}
              setSort={setSort}
              pageSkip={pageSkip}
              setPageSkip={setPageSkip}
              perPage={perPage}
              setPerPage={setPerpage}
              searchString={searchString}
              setSearchString={setSearchString}
              tableTitle={`${myOrg.data.organization.name}'s Users`}
              searchPlaceholder={"Search Users..."}
              hideActionButton={true}
            ></InteractiveTable>
          </div>
        )}
      </div>
    </>
  );
}

export function BackToTable({ onClick }) {
  return (
    <>
      <div className={styles.backToTable} onClick={onClick}>
        <div className={styles.tableIcon}>
          <i className="bi bi-list"></i>{" "}
        </div>
        <div className={styles.backArrow}>
          <i className="bi bi-arrow-return-left"></i>
        </div>
        <div className={styles.descriptionAnchor}>
          <div className={styles.description}>Back to Table</div>
        </div>
      </div>
    </>
  );
}

export function PickRole({ onSelectRole, toggle, role, user }) {
  const myOrg = useFetchMyOrg();
  const orgRoles = useFetchOrgRolesList(user.organizationId);

  const headers = [
    {
      id: 0,
      name: "Role",
      accessor: "name",
      enabled: true,
      sort: false,
      canSort: false,
      cell_style: null,
    },
    {
      id: 1,
      name: "Description",
      accessor: "description",
      enabled: true,
      sort: false,
      canSort: false,
      cell_style: null,
    },
  ];

  function scanRoles() {
    let keeping = [];
    for (let role of orgRoles.data.roles) {
      if (role?.dashboardId) {
        // Show even if not showing, so that someone can make edits without having it available until their ready
        keeping.push(role);
      }
    }
    return keeping;
  }

  // Make table filterable to ones that have a dashboardId
  // Make table just be plain ? Or use different table ?

  return (
    <>
      {toggle && <div className={styles.toggleHolder}>{toggle}</div>}
      <div className={styles.container}>
        {(myOrg.isLoading || orgRoles.isLoading) && <Loading />}
        {myOrg.isSuccess && orgRoles.isSuccess && (
          <div className={styles.holder}>
            <div className={styles.roleTableTitle}>
              {`${myOrg.data.organization.name}'s Roles`}
            </div>
            <BasicTable
              initheaders={headers}
              data={scanRoles()}
              onRowClick={onSelectRole}
              id="pickdash"
            ></BasicTable>
          </div>
        )}
      </div>
    </>
  );
}
