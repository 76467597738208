import { useEffect, useState } from "react";
import styles from "./DashShadow.module.scss";
import Widget from "../Widgets/Widget";
import {
  useFetchDashById,
  useFetchMyDashboard,
  useFetchOrgDashboard,
} from "api/resources/account/dashboard";
import _ from "lodash";
import { ResponsiveReactGridLayout } from "../Dash";
import { useCreateWidget } from "api/resources/account/widget";
import { useCreateVisualization } from "api/resources/projects/visualizations";
import { forEach } from "assets/functions/ArrayFunctions";

export default function DashShadow({
  onCompleted,
  viz,
  report,
  tile,
  dashboardId,
}) {
  const getDashboard = useFetchDashById(dashboardId);

  return (
    <Shadow
      onCompleted={onCompleted}
      viz={viz}
      report={report}
      tile={tile}
      getDashboard={getDashboard}
    />
  );
}

function Shadow({ onCompleted, viz, report, tile, getDashboard }) {
  const createWidget = useCreateWidget();
  const createViz = useCreateVisualization();

  const [showPanel, setShowPanel] = useState(true);
  const [itemToDrop, setToDrop] = useState();

  const reportLayouts = [
    {
      w: 2,
      h: 2,
    },
    {
      w: 2,
      h: 4,
    },
  ];

  const chartLayouts = [
    {
      w: 2,
      h: 2,
    },
    {
      w: 4,
      h: 2,
    },
    {
      w: 6,
      h: 2,
    },
  ];

  const tileLayouts = [
    {
      w: 2,
      h: 1,
    },
  ];

  const options = report
    ? {
        layouts: reportLayouts,
        widget: {
          name: "Pinned Reports",
          type: "reports",
          settings: {
            reports: [
              {
                id: report.id,
                name: report.name,
                content: report.content,
              },
            ],
            showGoToReports: true,
            showRecent: false,
            recentLimit: 3,
          },
        },
      }
    : viz
    ? {
        layouts: chartLayouts,
        widget: {
          name: "Chart Widget",
          type: "survey",
          settings: {
            showName: false,
            charts: [{ id: viz.id, orgId: viz.orgId }],
          },
        },
      }
    : tile
    ? {
        layouts: tileLayouts,
        widget: {
          type: "tile",
          settings: tile,
        },
      }
    : {};

  function onDragStart(widget, size) {
    let widg = { ...widget };
    widg.x = 0;
    widg.y = 0;
    widg.w = size.w;
    widg.h = size.h;
    widg.i = getDashboard.data.dashboard?.widget.length.toString();
    widg.static = false;
    setToDrop(widg);
  }

  function createVizCopy(newLayout) {
    let data = {
      title: viz.title,
      type: viz.type,
      questionIds: viz.question ? viz.question.map((q) => q.id) : null,
      designSettings: JSON.stringify(viz.designSettings),
      pivotString: viz.pivotString,
      filters: viz.filters ? viz.filters : "{}",
      analysisId: null,
      projectIdsArray: JSON.stringify(viz.projectIdsArray),
      tagIdsArray: JSON.stringify(viz.tagIdsArray),
      programIds: viz.programIds ? JSON.stringify(viz.programIds) : null,
      questionTags: viz.questionTags ? JSON.stringify(viz.questionTags) : null,
      comparison: viz.comparison ? JSON.stringify(viz.comparison) : null,
      dataMethod: viz.dataMethod,
    };

    createViz.mutate(
      {
        data: data,
      },
      {
        onSuccess: (data) => {
          console.log("Added Chart");
          itemToDrop.settings.charts = [
            {
              id: data.createVisualization.id,
              orgId: viz.orgId,
            },
          ];
          addWidget(newLayout);
        },
        onError: (data) => {},
      }
    );
  }

  function addWidget(newLayout) {
    for (let l of newLayout.lg) {
      l.isDraggable = undefined;
    }

    createWidget.mutate(
      {
        data: {
          dashboardId: getDashboard.data.dashboard.id,
          index: parseInt(itemToDrop.i),
          name: itemToDrop.name,
          type: itemToDrop.type,
          settings: JSON.stringify(itemToDrop.settings),
        },
        layouts: JSON.stringify(newLayout),
      },
      {
        onSuccess: (data) => {
          console.log("new widget saved");
          setTimeout(() => {
            onCompleted();
          }, 800);
          // getDashboard.refetch();
        },
        onError: (data) => {},
      }
    );
  }

  return (
    <div className={styles.shadowContainer}>
      {showPanel && (
        <div className={styles.panel}>
          <div className={styles.menu}>
            <div className={styles.catalog}>
              <div className={styles.sectionHeader}>
                {report && "Report Widgets"}
                {viz && "Chart Widgets"}
                {/* {tile && "Survey Tile"} */}
              </div>
              {getDashboard.isSuccess && (
                <div className={styles.row}>
                  {options.layouts.map((l) => (
                    <div
                      draggable="true"
                      unselectable="on"
                      className={styles.widget}
                      style={{
                        height: l.h + "0em",
                        minWidth: l.w + "0em",
                      }}
                      onDrag={() => setShowPanel(false)}
                      onDragStart={() => onDragStart(options.widget, l)}
                    >
                      <div style={{ pointerEvents: "none", height: "100%" }}>
                        <Widget widget={options.widget} />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {getDashboard.isSuccess && (
        <div className={styles.dash}>
          <SmallDashGrid
            itemToDrop={itemToDrop}
            initLayouts={
              getDashboard.data.dashboard?.layouts
                ? JSON.parse(getDashboard.data.dashboard?.layouts)
                : null
            }
            initWidgets={getDashboard.data.dashboard?.widget}
            addWidget={viz ? createVizCopy : addWidget}
          />
        </div>
      )}
    </div>
  );
}

function SmallDashGrid({ itemToDrop, initLayouts, initWidgets, addWidget }) {
  const className = "layout";
  const rowHeight = 170;
  const cols = { lg: 6, md: 4, sm: 2, xs: 1, xxs: 1 };
  const breakpoints = { lg: 1200, md: 700, sm: 380, xs: 200, xxs: 0 };
  const containerPadding = [0, 0];

  const combWidgets = () => {
    if (initWidgets) {
      forEach(initWidgets, (w) => {
        if (typeof w.settings === "string") {
          w.settings = JSON.parse(w.settings);
        }
      });
      return initWidgets.sort((a, b) => a.index - b.index);
    }
    return [];
  };

  const [widgets, setWidgets] = useState(combWidgets());

  useEffect(() => {
    setWidgets(combWidgets());
  }, [initWidgets]);

  const [layouts, setLayouts] = useState(initLayouts);

  const [currentBreakpoint, setCurrentBreakpoint] = useState("lg");
  const [compactType, setCompactType] = useState("vertical");
  const [mounted, setMounted] = useState(false);
  const [toolbox, setToolbox] = useState({
    lg: [],
  });
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight * 0.85,
    width: window.innerWidth * 0.9,
  });
  const [showDropTarget, setShowDropTarget] = useState(false);

  function handleResize() {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  }

  useEffect(() => {
    setMounted(true);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onBreakpointChange = (breakpoint) => {
    // what's this?
    setCurrentBreakpoint(breakpoint);
    setToolbox({
      ...toolbox,
      [breakpoint]: toolbox[breakpoint] || toolbox[currentBreakpoint] || [],
    });
  };

  const onLayoutChange = (layout, layouts) => {
    console.log('onLayoutchange');

    setLayouts({ ...layouts });
  };

  function onDrop(layout, layoutItem, _event) {
    let temps = { ...layouts };
    forEach(layout, (l) => (l.isDraggable = undefined));

    let space = document.getElementById("gridContainer")
      ? document.getElementById("gridContainer").offsetWidth
      : undefined;

    for (let size in temps) {
      temps[size].push(layoutItem);
    }

    if (space) {
      if (space > 1200) {
        temps.lg = layout;
      } else if (space > 700) {
        temps.md = layout;
      } else if (space > 380) {
        temps.sm = layout;
      } else if (space > 200) {
        temps.xs = layout;
      } else {
        temps.xxs = layout;
      }
    } else {
      temps.lg = layout;
    }

    addWidget(temps);

    setTimeout(() => {
      setLayouts(temps);
    }, 500);
  }

  function checkMaxWidth() {
    if (1475 > dimensions.width && dimensions.width > 960) {
      return "55em";
    } else if (959 > dimensions.width && dimensions.width > 500) {
      return "26em";
    } else {
      return "80em";
    }
  }

  function blankSlateDrop(ev) {
    ev.preventDefault();

    let widges = [...widgets];
    widges.push(itemToDrop);

    let newLayout = {
      lg: [
        {
          x: 0,
          y: 0,
          w: itemToDrop.w,
          h: itemToDrop.h,
          i: itemToDrop.i,
          static: false,
          isDraggable: undefined,
        },
      ],
    };

    setShowDropTarget(false);
    setWidgets(widges);
    setLayouts(newLayout);
    addWidget(newLayout);
  }

  const generateDOM = () => {
    function checkWidth(w) {
      if (w === 1) {
        return "12em";
      } else if (w === 2) {
        return "27em";
      } else {
        return "";
      }
    }

    let space = document.getElementById("gridContainer")
      ? document.getElementById("gridContainer").offsetWidth
      : undefined;

    let currLayouts = layouts.lg;
    if (layouts.md && space && 1251 > space && space > 700) {
      currLayouts = layouts.md;
    }

    return _.map(currLayouts, function (l, i) {
      let content = widgets[i];

      return (
        <div key={i} style={{ maxWidth: checkWidth(l.w) }}>
          <div
            className={styles.setInStone}
            style={{
              height: "100%",
              pointerEvents: "none",
            }}
          >
            {content && <Widget widget={content} layout={l} />}
          </div>
        </div>
      );
    });
  };

  return (
    <>
      {widgets.length > 0 && (
        <div
          className={styles.gridBox}
          id="gridContainer"
          style={{ maxWidth: checkMaxWidth() }}
        >
          {layouts && (
            <ResponsiveReactGridLayout
              breakpoints={breakpoints}
              className={className}
              rowHeight={rowHeight}
              cols={cols}
              containerPadding={containerPadding}
              layouts={layouts}
              measureBeforeMount={false}
              useCSSTransforms={mounted}
              compactType={compactType}
              // preventCollision={!compactType}
              onLayoutChange={onLayoutChange}
              onBreakpointChange={onBreakpointChange}
              onDrop={onDrop}
              isDroppable={true}
              isDraggable={false}
              isResizable={false}
              // resizeHandle={}
              resizeHandles={["se"]}
              margin={[20, 20]}
              droppingItem={itemToDrop}
              // onResize={resize}
            >
              {generateDOM()}
            </ResponsiveReactGridLayout>
          )}
        </div>
      )}
      {widgets.length == 0 && (
        <div
          className={styles.noWidgets}
          id="dropCont"
          onDragEnter={() => setShowDropTarget(true)}
          onDragExit={() => setShowDropTarget(false)}
        >
          <div>No Widgets Currently</div>

          {showDropTarget && (
            <div
              className={styles.blankDropTarget}
              onDragLeave={(e) => {
                e.preventDefault();
                setShowDropTarget(false);
              }}
              onDrop={blankSlateDrop}
              onDragOver={(e) => e.preventDefault()}
            ></div>
          )}
        </div>
      )}
    </>
  );
}
