import { useFetchCurrOrgsFieldsByMutation } from "api/resources/organization/organization";
import styles from "./Menu.module.scss";
import { Loading } from "components/Loading/Loading";
import { useState, useRef, useEffect } from "react";
import { defaultLink, Link } from "./Link/Link";
import { useFetchDistConfigs } from "api/resources/projects/distributions";
import parse, { domToReact } from "html-react-parser";
import { Image } from "./Image/Image";

export const Menu = ({
  onClose,
  menu,
  onFieldPrep,
  onPickField,
  onLinkEdit,
  onSigPick,
  onImagePick,
}) => {
  const [hover, setHover] = useState(0);
  const menuRef = useRef();
  const hoverRef = useRef(0);
  const [show, setShow] = useState();
  const linkBuilding = useRef(false);
  const colorRef = useRef();

  useEffect(() => {
    document.addEventListener("click", clickListener, true);
    document.addEventListener("keydown", keyListener, true);

    return () => {
      document.removeEventListener("click", clickListener, true);
      document.removeEventListener("keydown", keyListener, true);
    };
  }, []);

  function clickListener(e) {
    if (!menuRef.current || !menuRef.current.contains(e.target)) {
      if (!colorRef.current || !colorRef.current.contains(e.target)) {
        onClose();
        document.removeEventListener("click", clickListener, true);
      }
    }
  }

  function keyListener(e) {
    if (linkBuilding.current) {
      // e.preventDefault();
      return;
    }

    if (e.key === "ArrowDown") {
      e.preventDefault();
      incMenuHover(1);
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      incMenuHover(-1);
    } else if (e.key === "Escape") {
      onClose();
    } else if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();

      let item = items.current[hoverRef.current];
      if (item.onClick) {
        item.onClick();
      }
    } else {
      onClose();
    }
  }

  function incMenuHover(val) {
    if (items.current && items.current.length) {
      let newInd = hoverRef.current + val;
      if (newInd < 0) {
        newInd = items.current.length - 1;
      } else if (newInd > items.current.length - 1) {
        newInd = 0;
      }

      setHover(newInd);
      hoverRef.current = newInd;
    }
  }

  function goTo(name) {
    setHover(0);
    setShow(name);
  }

  function setUpLink() {
    goTo("Link");
    linkBuilding.current = true;
    onLinkEdit(defaultLink);
  }

  const menuItems = [
    {
      title: "Fields",
      description:
        "Add a dynamic contact field that changes for according to each recipient",
      icon: (
        <i
          className={`bi bi-person-bounding-box ${styles.menuIcon} ${styles.fieldIcon}`}
        ></i>
      ),
      onClick: () => {
        goTo("Fields");
        onFieldPrep();
      },
    },
    {
      title: "Button",
      description: "Add a survey link or custom button",
      icon: (
        <div className={styles.buttonIcon}>
          <div className={styles.mockButton}></div>
          <i className={`bi bi-hand-index-fill ${styles.cursorIcon} `}></i>{" "}
        </div>
      ),
      onClick: setUpLink,
    },
    {
      title: "Signature",
      description: "Add a specific signature to be tied to the email",
      icon: (
        <i className={`bi bi-pen ${styles.menuIcon} ${styles.sigIcon}`}></i>
      ),
      onClick: () => goTo("Signature"),
    },
    {
      title: "Image",
      description: "Add an image to the body",
      icon: (
        <i
          className={`bi bi-image-alt ${styles.menuIcon} ${styles.imageIcon}`}
        ></i>
      ),
      onClick: () => goTo("Image"),
    },
  ];

  const items = useRef(menuItems);

  function onHover(i) {
    setHover(i);
    hoverRef.current = i;
  }

  return (
    <div className={styles.menu} style={menu.style} ref={menuRef}>
      {!show &&
        menuItems.map((m, i) => (
          <div
            className={`${styles.menuItem} ${
              hover == i ? styles.menuHover : ""
            }`}
            onMouseEnter={() => onHover(i)}
            onClick={m.onClick ? m.onClick : undefined}
          >
            <div className={styles.menuIconHolder}>{m.icon}</div>
            <div className={styles.menuInfo}>
              <div className={styles.menuTitle}>{m.title}</div>
              <div className={styles.menuDescription}>{m.description}</div>
            </div>
          </div>
        ))}

      {show === "Fields" && (
        <Fields
          hover={hover}
          onHover={onHover}
          itemsRef={items}
          onPick={onPickField}
        />
      )}
      {show === "Link" && <Link colorRef={colorRef} onLinkEdit={onLinkEdit} />}
      {show === "Signature" && <Signature onPick={onSigPick} />}
      {show === "Image" && <Image onImagePick={onImagePick} />}
    </div>
  );
};

function Fields({ hover, onHover, itemsRef, onPick }) {
  const fetchCustomFields = useFetchCurrOrgsFieldsByMutation();

  const nameItems = [
    {
      displayName: "Full name",
      name: "full name",
      desc: "(prefix) [first] [last]",
    },
    {
      displayName: "First name",
      name: "first name",
    },
    {
      displayName: "Last name",
      name: "last name",
    },
  ];

  useEffect(() => {
    fetchCustomFields.mutate(
      {},
      {
        onSuccess: (data) => {
          // console.log(data);
          itemsRef.current = [...nameItems, ...data.fields].map((f) => {
            return { ...f, onClick: () => onPick(f) };
          });
        },
      }
    );
  }, []);

  return (
    <>
      {(fetchCustomFields.isLoading || fetchCustomFields.isError) && (
        <Loading />
      )}

      {fetchCustomFields.isSuccess &&
        [...nameItems, ...fetchCustomFields.data.fields].map((field, i) => (
          <div
            className={`${styles.customField} ${
              hover == i ? styles.hoverField : ""
            } `}
            onMouseEnter={() => onHover(i)}
            onClick={() => onPick(field)}
          >
            {field.displayName}
            {field.desc && <div className={styles.fieldDesc}>{field.desc}</div>}
          </div>
        ))}
    </>
  );
}

function Signature({ onPick }) {
  const [preview, setPreview] = useState("");

  const fetchSignatures = useFetchDistConfigs();

  useEffect(() => {
    if (preview) {
      let sig = fetchSignatures.data.signatures.find((s) => s.id === preview);

      let div = document.getElementById(preview);
      if (div) {
        div.innerHTML = sig.signature;
      }
    }
  }, [preview]);

  return (
    <div className={styles.sigMenu}>
      {fetchSignatures.isSuccess &&
        fetchSignatures.data.signatures.map((s) => (
          <div
            className={`${styles.sigOption} ${
              preview === s.id ? styles.selected : styles.selectable
            }`}
            onClick={() => setPreview((old) => (old === s.id ? "" : s.id))}
          >
            <div className={styles.sigName}>{s.aliasName}</div>
            {preview === s.id && (
              <div
                className={styles.sigPreview}
                id={s.id}
                onClick={() => onPick(s)}
              ></div>
            )}
          </div>
        ))}
    </div>
  );
}
