/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/

// External
import { gql } from "graphql-request";

// Internal
import { useGqlMutation, useGqlQuery } from "api/Api";

// GRAPHQL API

export const useFetchNotifications = (sort, id, unread) => {
  class notifSortInput {
    descending;
    all;
    unread;
    read;
    important;
  }

  const sort2 = new notifSortInput();
  sort2.descending = sort.sort.descending;
  sort2.all = sort.sort.all;
  sort2.unread = sort.sort.unread;
  sort2.read = sort.sort.read;
  sort2.important = sort.sort.important;

  const query = gql`
    query Query($sort: notifSortInput!) {
      notifications(sort: $sort) {
        body
        createdAt
        header
        id
        link
        read
        type
        important
        title
        sender
        senderTitle
      }
    }
  `;

  return useGqlQuery(
    ["notification" + (id ? id + (unread ? true : false) : "")],
    query,
    {
      sort: sort2,
    }
  );
};

export const useFetchRecentNotifications = () => {
  const query = gql`
    query {
      recentNotifs {
        id
        title
        sender
        senderTitle
        type
        link
        body
        header
      }
    }
  `;

  return useGqlQuery(["recentNotifs"], query, {});
};

export const useFetchUnreadNotificationCount = () => {
  const mutation = gql`
    mutation Mutation {
      getUnreadNotificationCount
    }
  `;
  return useGqlMutation(mutation);
};

export const useMarkAllRead = () => {
  const mutation = gql`
    mutation Mutation {
      markAllRead
    }
  `;
  return useGqlMutation(mutation);
};

export const useUpdateNotification = () => {
  const mutation = gql`
    mutation Mutation(
      $data: notifUpdateInput!
      $updateNotificationId: String!
    ) {
      updateNotification(data: $data, id: $updateNotificationId)
    }
  `;

  return useGqlMutation(mutation);
};

export const useDeleteNotification = () => {
  const mutation = gql`
    mutation Mutation($deleteNotificationId: String!) {
      deleteNotification(id: $deleteNotificationId)
    }
  `;

  return useGqlMutation(mutation);
};

export const useSearchNotifications = (
  sort,
  pageSkip,
  perPage,
  searchString,
  unique
) => {
  const query = gql`
    query Query(
      $sort: sortSelectionDelivery!
      $page: Float!
      $perPage: Float!
      $search: String!
    ) {
      notifications: searchNotifications(
        search: $search
        sort: $sort
        page: $page
        perPage: $perPage
      ) {
        body
        createdAt
        header
        id
        link
        read
        type
        important
        title
        sender
        senderTitle
        itemId
        itemId2
      }
    }
  `;

  return useGqlQuery(
    ["searchNotif:" + searchString + pageSkip + perPage + unique],
    query,
    {
      search: searchString,
      sort: sort,
      page: pageSkip,
      perPage: perPage,
    }
  );
};

export const useSearchNotificationsCount = (read, searchString, unique) => {
  const query = gql`
    query Query($read: Boolean!, $search: String!) {
      count: searchNotificationCount(search: $search, read: $read)
    }
  `;

  return useGqlQuery(
    ["searchNotifCount:" + read + searchString + unique],
    query,
    {
      search: searchString,
      read: read,
    }
  );
};

export const useSearchTasks = (
  sort,
  pageSkip,
  perPage,
  searchString,
  finished,
  showHide
) => {
  let unique = finished ? "getfinished" : "getrunning";
  unique += showHide ? "showhidden" : "donotshowhidden";
  const query = gql`
    query Query(
      $sort: sortSelectionDelivery!
      $page: Float!
      $perPage: Float!
      $search: String!
      $finished: Boolean!
      $showHide: Boolean!
    ) {
      tasks: searchTasks(
        search: $search
        sort: $sort
        page: $page
        perPage: $perPage
        finished: $finished
        showHide: $showHide
      ) {
        body
        createdAt
        header
        id
        link
        type
        important
        title
        sender
        senderTitle
        progress
        hide
      }
    }
  `;

  return useGqlQuery(unique, query, {
    search: searchString,
    sort: sort,
    page: pageSkip,
    perPage: perPage,
    finished: finished,
    showHide: showHide,
  });
};

export const useCancelTask = () => {
  const mutation = gql`
    mutation Mutation($id: String!) {
      success: cancelTask(id: $id)
    }
  `;

  return useGqlMutation(mutation, {});
};

export const useUpdateTask = () => {
  const mutation = gql`
    mutation Mutation($id: String!, $data: TaskInput!) {
      success: updateTask(id: $id, data: $data)
    }
  `;

  return useGqlMutation(mutation, {});
};
