/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson, brodyspencer

*/
//External
import React, { useEffect, useState } from "react";

//Internal
import styles from "./QuestionConstruction.module.scss";
import Button from "components/Button/Button";
import {
  useCreateQuestionGql,
  useDeletePageGql,
  useDeleteQuestion,
  useMovePages,
  useUpdateQuestionGql,
} from "api/resources/projects/questions";
import { shortId } from "components/tables/EditableTable/utils";
import { useNavigate, useParams } from "react-router-dom";
import {
  useUpdateNumPages,
  useUpdateProjectDesignSettingsGql,
  useWriteInFinished,
} from "api/resources/projects/projects";
import { SurveyDesign } from "./SurveyDesign/SurveyDesign";
import Survey from "components/Survey/Survey";
import {
  useCreateSectionGql,
  useDeleteSectionGql,
  useUpdateSectionGql,
} from "api/resources/projects/sections";
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  closestCorners,
  DragOverlay,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Loading } from "components/Loading/Loading";
import EditableNavigation from "./EditableNavigation/EditableNavigation";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import EditLogo from "./EditableLogo/EditLogo";
import EditableProgressBar from "./EditableProgressBar/EditableProgressBar";
import SettleLogic from "./QuestionLogic/SettleLogic";
import SurveyComponent from "./SurveyComponent/SurveyComponent";
import { forEach, getSortedArray } from "assets/functions/ArrayFunctions";
import { createEncodingCopy } from "components/inputs/input_fields/TextEditorTry/TextEditorTry";
import Stat from "components/tables/BasicTable/SurveyWidgets/Stat";
import { Pie } from "components/inputs/input_fields/CircleProgress/Circle";
import {
  calculateQuestionHealth,
  calculateSurveyHealth,
} from "assets/functions/SurveyHealth";
import DoYouWantToSave from "components/Popout/DoYouWantToSave";
import SurveyCompletedInConstruction from "./Finished/SurveyCompleted";

const sectionSettings = {
  bodySize: 14,
  bodyColor: "#616565",
  showBody: true,
  bodyItalics: true,
  headerSize: 22,
  headerColor: "#616565",
  showHeader: true,
  headerItalics: false,
  sectionShadow: true,
};

/**
 * A component that when passed a list of questions will display them and let you edit them
 * @param {Array} initQuestions used to handle clicking outside of the popout to close it
 * @returns {React.ReactElement} a QuestionConstruction component
 */
export function QuestionConstruction({
  initFinishedMessage,
  maxPage,
  setMaxPage,
  status,
  roles,
  show,
  setShow,
  designSettings,
  setDesignSettings,
  currPage,
  setCurrPage,
  questions,
  setQuestions,
  allQuestions,
  setAllQuestions,
  refetchQs,
  refetchDSettings,
  showLoading,
  setShowLoading,
  projectId,
  projectOrgId,
  orgId,
  survey,
  setBeforeNavAway,
}) {
  const [seeAll, setSeeAll] = useState(false);

  const [activeId, setActiveId] = useState("");
  const [overInd, setOverInd] = useState(-1);
  const [close, setClose] = useState(-2);

  const { id } = useParams();
  const [activeTab, setActiveTab] = useState();
  const [savingStatus, setSavingStatus] = useState(null);
  const [showFinshedPage, setShowFinishedPage] = useState(false);
  const [finishedMsg, setFinishedMsg] = useState(initFinishedMessage);
  const [seeDesignSettings, setSeeDesignSettings] = useState(false);
  // const [copy, setCopy] = useState();
  const [answers, setAnswers] = useState({});
  const [snapShot, setSnapShot] = useState();
  const [dependencies, setDependencies] = useState([]);
  const [dependencyResolver, setDependencyResolver] = useState();
  const [editedDesign, setEditedDesign] = useState(false);
  const [editedQs, setEditedQs] = useState(false);

  const [runSave, setRunSave] = useState(false);
  const [fixNewQ, setFixNewQ] = useState();

  // const [queue, setQueue] = useState();
  // const [counter, setCounter] = useState();

  const saveQuestion = useUpdateQuestionGql();
  const createQuestion = useCreateQuestionGql();
  const deleteQuestion = useDeleteQuestion();
  const deleteSection = useDeleteSectionGql();
  const updateNumPages = useUpdateNumPages();
  const deletePage = useDeletePageGql();
  const updateSettings = useUpdateProjectDesignSettingsGql(id);
  const createSection = useCreateSectionGql();
  const updateSection = useUpdateSectionGql();
  const movePage = useMovePages();

  function checkRole() {
    if (orgId === projectOrgId) {
      return roles.canCreateEditOrgSurveys;
    } else {
      return true;
    }
  }

  useEffect(() => {
    setUpDependencies();
  }, [allQuestions]);

  function setUpDependencies() {
    let dependentRecord = [];

    for (let q of allQuestions) {
      if (
        q.type !== "Description" &&
        q?.conditionGroups &&
        q.conditionGroups.length
      ) {
        for (let group of q.conditionGroups) {
          if (group?.operator && group?.conditions) {
            for (let condition of group.conditions) {
              let record = dependentRecord.find(
                (d) => d.questionId === condition.questionId
              );
              if (!record) {
                // Create a dependency record on this question
                record = {
                  questionId: condition.questionId,
                  dependencies: [],
                };
                dependentRecord.push(record);
              }

              // Keep the record of each dependent question contained in one, in other words every dependency is a seperate question
              let subRecord = record.dependencies.find(
                (d) => d.dependentId === q.id
              );
              if (!subRecord) {
                subRecord = {
                  dependentId: q.id,
                  dependentName: q.questionText,
                };
                record.dependencies.push(subRecord);
              }

              const dependsOn = allQuestions.find(
                (q) => q.id === condition.questionId
              );

              if (!dependsOn) {
                continue;
              }

              if (
                condition.operator === "answer" ||
                condition.operator === "do not answer"
              ) {
                if (subRecord.keep) {
                  subRecord.keep = [...subRecord.keep, ...condition.answer];
                } else {
                  subRecord.keep = condition.answer;
                }
              } else if (
                dependsOn.type === "NumberScale" ||
                dependsOn.type === "NumberSlider"
              ) {
                let keep = [];

                if (condition.operator === "between") {
                  keep = getSortedArray(condition.answer, (a, b) => a - b);
                } else if (
                  condition.operator === "above" ||
                  condition.operator === "below"
                ) {
                  keep.push(condition.answer);
                }

                if (subRecord.keep) {
                  subRecord.keep = [...subRecord.keep, ...keep];
                } else {
                  subRecord.keep = keep;
                }
              } else if (
                dependsOn.type === "Text" &&
                condition.operator === "is longer than"
              ) {
                if (
                  !("keepLimitAbove" in subRecord) ||
                  subRecord.keepLimitAbove < condition.answer
                ) {
                  subRecord.keepLimitAbove = condition.answer;
                }
              }

              if (dependsOn.type === "Matrix") {
                if (subRecord.keepOptions) {
                  subRecord.keepOptions = [
                    ...subRecord.keepOptions,
                    condition.option,
                  ];
                } else {
                  subRecord.keepOptions = [condition.option];
                }
              }
            }
          }
        }
      }
    }
    setDependencies(dependentRecord);
  }

  function clearsBaselineLogic(question) {
    // Checks to see if there are any dependencies. Returns false if so. For operations like delete, moving below, and changing the quesion type
    // Not to check specific changes in the question
    let dependents = dependencies.find((d) => d.questionId === question.id);
    if (dependents) {
      return false;
    }
    return true;
  }

  function editFinishMessage(msg) {
    // let settings = { ...designSettings };
    // settings.finishedMessage = msg;
    // setDesignSettings(settings);
    setFinishedMsg(msg);
    setEditedDesign(true);
    setBeforeNavAway({
      getDisplay: checkToSave,
    });
  }

  function setUpLogicResolver(areYouSure, action, question, onContinue) {
    setDependencyResolver(
      <SettleLogic
        areYouSure={areYouSure}
        action={action}
        question={question}
        onCarryThrough={() => {
          onContinue();
          setDependencyResolver(null);
          setRunSave(true);
        }}
        onNevermind={() => {
          setDependencyResolver(null);
        }}
        dependencies={dependencies}
        allQuestions={allQuestions}
        saveQuestions={onEditAllQs}
      />
    );
  }

  function onEditQ(question) {
    let index = questions.findIndex((item) => item.id === question.id);
    let allIndex = allQuestions.findIndex((q) => q.id === question.id);

    let tempPage = [...questions];
    let tempAll = [...allQuestions];

    tempPage[index] = { ...question };
    tempAll[allIndex] = { ...question };

    tempAll[allIndex].changed = true;

    setQuestions(tempPage);
    setAllQuestions(tempAll);

    setEditedQs(true);
  }

  function onEditAllQs(copyOfAll) {
    let page = [];
    for (let one of copyOfAll) {
      if (one.pageNumber == currPage) {
        page.push(one);
      }
    }
    setQuestions(page);
    setAllQuestions(copyOfAll);

    setEditedQs(true);
  }

  function deleteQ(question, e) {
    if (!("questionText" in question) || clearsBaselineLogic(question)) {
      finishDeleteQ(question, e);
    } else {
      setDependencyResolver(
        <SettleLogic
          areYouSure={"delete"}
          action={"delete"}
          question={question}
          onCarryThrough={() => {
            finishDeleteQ(question, e);
            setDependencyResolver(null);
            setRunSave(true);
            setEditedQs(true); // in finishDeleteQ it sets editedQs to false
            setOverInd(-1);
            setActiveId("");
          }}
          onNevermind={() => {
            setDependencyResolver(null);
            if (!activeId) {
              // just on hover
              setClose(overInd - 1);
              setTimeout(() => {
                setClose(overInd);
                setOverInd(-1);
              }, 50);
            }
          }}
          dependencies={dependencies}
          allQuestions={allQuestions}
          saveQuestions={onEditAllQs}
        />
      );

      if (!activeId || activeId !== question.id) {
        e.stopPropagation(); // don't run the onQuestionClick
      }
    }
  }

  function finishDeleteQ(question, e, update) {
    let index = questions.findIndex((q) => q.id === question.id);
    let tempAll = [...allQuestions];
    let allInd = 0;
    for (let i = 0; i < allQuestions.length; i++) {
      if (allQuestions[i].pageNumber === currPage) {
        allInd = i + index;
        break;
      }
    }
    tempAll.splice(allInd, 1);

    let questionNumber = 1;
    for (let i = 0; i < tempAll.length; i++) {
      if ("questionText" in tempAll[i] || tempAll[i]?.questionText === "") {
        tempAll[i].index = questionNumber;
        tempAll[i].changed = true;
        questionNumber += 1;
      }
    }

    let thisPage = [];
    for (let q of tempAll) {
      if (q.pageNumber === question.pageNumber) {
        if (q.pageOrderIndex > question.pageOrderIndex) {
          q.pageOrderIndex--;
        }
        thisPage.push(q);
      }
    }

    setQuestions(thisPage);
    setAllQuestions(tempAll);

    if (activeId === question.id) {
      setActiveId("");
      setOverInd(-1);
    } else if (!activeId) {
      // just hovering
      setOverInd(-1);
    } else {
      // while active on a different Q
      e.stopPropagation(); // Don't let the onQuestionClick function run
      if (overInd > index) {
        setOverInd(overInd - 1);
      }
    }

    if (!question?.new) {
      // It decrements the others' pageOrderIndex if needed in the backend as well.

      if (question.type === "Description") {
        deleteSection.mutate(
          {
            id: question.id,
          },
          {
            onSuccess: () => {},
          }
        );
      } else {
        deleteQuestion.mutate(
          {
            id: question.id,
          },
          {
            onSuccess: () => {},
          }
        );
      }
    }
    setEditedQs(false);
  }

  function handleCreateQuestion(index) {
    let tempId = "temporaryid:" + shortId();
    const question = {
      id: tempId,
      type: "MultipleChoice",
      projectId: id,
      pageOrderIndex: index,
      pageNumber: currPage,
      isHidden: false,
      description: "",
      buckets: [],
      questionTag: [],
      hasBuckets: false,
      new: true,
      choiceQuestion: {
        isMultiSelect: false,
        isRandomized: false,
        hasOtherOption: false,
        otherOptionLabel: "Other",
        choices: [],
        isRanking: false,
      },
      questionText: "",
      encoding: "",
      descriptionEncoding: "",
    };
    addQToState(question);
  }

  function handleCreateSection(index) {
    let tempId = "temporarySectionId:" + shortId();
    const section = {
      id: tempId,
      type: "Description",
      projectId: id,
      pageOrderIndex: index,
      new: true,
      pageNumber: currPage,
      sectionSettings: sectionSettings,
      bodyText: null,
      encoding: null,
    };
    addQToState(section);
  }

  function addQToState(question) {
    let tempAll = [...allQuestions];
    let ind = 0;

    for (let i = 0; i < allQuestions.length; i++) {
      if (allQuestions[i].pageNumber === currPage) {
        ind += question.pageOrderIndex;
        break;
      }
      ind++;
    }
    tempAll.splice(ind, 0, question);

    let questionNumber = 1;
    for (let i = 0; i < tempAll.length; i++) {
      if ("questionText" in tempAll[i] || tempAll[i]?.questionText === "") {
        tempAll[i].index = questionNumber;
        tempAll[i].changed = true;
        questionNumber += 1;
      }
    }

    let thisPage = [];
    for (let q of tempAll) {
      if (q.pageNumber === currPage) {
        if (
          q.pageOrderIndex >= question.pageOrderIndex &&
          q.id !== question.id
        ) {
          q.pageOrderIndex++;
          q.changed = true;
        }
        thisPage.push(q);
      }
    }

    setAllQuestions(tempAll);
    setQuestions(thisPage);

    setActiveId(question.id);
    setOverInd(question.pageOrderIndex);

    setEditedQs(true);
  }

  //functionality for pages
  function nextPage() {
    if (currPage + 1 <= maxPage) {
      setCurrPage(currPage + 1);
      setActiveId("");
      scrollToTop();
      refetchQs(currPage + 1);
    }
  }

  function previousPage() {
    if (currPage - 1 > 0) {
      setCurrPage(currPage - 1);
      setActiveId("");
      scrollToTop();
      refetchQs(currPage - 1);
    }
  }

  function scrollToTop() {
    let container = document.getElementById("constructionZone");
    if (container) {
      container.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }

  function addPage() {
    if (status != "Open") {
      updateNumPages.mutate(
        {
          id: id,
          data: { numPages: maxPage + 1 },
        },
        {
          onSuccess: () => {
            setMaxPage((page) => page + 1);
            setCurrPage(maxPage + 1);
            setActiveId("");
          },
        }
      );
    } else {
      alert("Cannot create a page when project status is Open");
    }
  }

  function saveToDB() {
    if (editedDesign) {
      setSavingStatus(<Loading height="20" width="20"></Loading>);
      let jsonStr = JSON.stringify(designSettings);
      saveDesign(jsonStr);
    }
    if (editedQs) {
      setSavingStatus(<Loading height="20" width="20"></Loading>);
      saveQuestions();
    }
  }

  function saveQuestions() {
    let quesitonNum = 1;
    for (let item of allQuestions) {
      if (item?.changed || item?.new) {
        delete item.changed;
        if ("questionText" in item) {
          let copy = { ...item, index: quesitonNum };
          copy.conditionGroups = item?.conditionGroups
            ? JSON.stringify(item.conditionGroups)
            : null;
          copy.triggers = item?.triggers
            ? JSON.stringify(item.triggers)
            : undefined;

          copy.buckets =
            item?.buckets && typeof item.buckets !== "string"
              ? JSON.stringify(item.buckets)
              : item.buckets;

          if (copy.choiceQuestion) {
            delete copy.choiceQuestion.id;
          }
          if (copy.textQuestion) {
            copy.textQuestion = { ...copy.textQuestion };
            delete copy.textQuestion.id;
            delete copy.textQuestion.bucket; // Taken care of in Buckets.jsx
          }
          if (copy.scaleQuestion) {
            delete copy.scaleQuestion.id;
          }
          if (copy.matrixQuestion) {
            copy.matrixQuestion = JSON.parse(
              JSON.stringify(copy.matrixQuestion)
            ); // don't alter the current state
            copy.matrixQuestion.settings = JSON.stringify(
              copy.matrixQuestion.settings
            );
            delete copy.matrixQuestion.id;
          }

          delete copy.id;
          if (copy?.answer) {
            delete copy.answer;
          }
          delete copy.questionTag; // Taken care of in EditTags.jsx

          if (copy?.new) {
            delete copy.new;
            createQuestion.mutate(
              {
                data: copy,
              },
              {
                onSuccess: (data) => {
                  // setFixNewQ({
                  //   old: item.id,
                  //   new: data.createQuestion.id,
                  // });

                  fixSaveStatus();
                  refetchQs();
                },
              }
            );
          } else {
            saveQuestion.mutate(
              {
                id: item.id,
                data: copy,
              },
              {
                onSuccess: (data) => {
                  fixSaveStatus();
                },
              }
            );
          }
        } else {
          // It's a section
          let copy = { ...item };

          copy.sectionSettings = JSON.stringify(item.sectionSettings);
          copy.conditionGroups = item?.conditionGroups
            ? JSON.stringify(item.conditionGroups)
            : undefined;
          delete copy.id;

          if (copy?.new) {
            delete copy.new;

            createSection.mutate(
              {
                data: copy,
              },
              {
                onSuccess: (data) => {
                  setFixNewQ({
                    new: data.createSection.id,
                    old: item.id,
                  });
                  fixSaveStatus();
                },
              }
            );
          } else {
            updateSection.mutate(
              {
                id: item.id,
                data: copy,
              },
              {
                onSuccess: (data) => {
                  fixSaveStatus();
                },
              }
            );
          }
        }
      }
      if ("questionText" in item) {
        quesitonNum += 1;
      }
    }

    setEditedQs(false);
  }

  function saveDesign(settings) {
    let json =
      typeof settings === "string" ? settings : JSON.stringify(settings);

    updateSettings.mutate(
      {
        updateProjectId: id,
        data: {
          designSettings: json,
          surveyInstruction: "",
          finishedMessage: finishedMsg,
        },
      },
      {
        onSuccess: (data) => {
          let newSettings = JSON.parse(data.updateProject.designSettings);
          setDesignSettings(newSettings);
          fixSaveStatus();
        },
      }
    );
    setEditedDesign(false);
    setBeforeNavAway(null);
  }

  function fixSaveStatus() {
    setSavingStatus(<i className="bi bi-check"></i>);
    setTimeout(() => {
      setSavingStatus(null);
    }, 1000);
  }

  function onSaveClick() {
    if (editedQs || editedDesign) {
      setRunSave(true);
    } else {
      setSavingStatus(<Loading height="20" width="20"></Loading>);
      setTimeout(() => fixSaveStatus(), 1000); // Purely for user's peace of mind.
      refetchQs();
    }
  }

  function switchPages(activeInd, overInd) {
    if (dependencies.length) {
      let activeQs = [];
      forEach(allQuestions, (q) => {
        if (q.pageNumber == activeInd) {
          activeQs.push(q);
        }
      });

      if (activeInd < overInd) {
        // moving down
        // Can break other's
        // focus on active page's
        let dependedOn_WillBreak = [];
        for (let q of activeQs) {
          let dependents = dependencies.find((d) => d.questionId === q.id);
          if (dependents) {
            for (let dependent of dependents.dependencies) {
              let depQ = allQuestions.find(
                (que) => que.id === dependent.dependentId
              );
              if (depQ.pageNumber > activeInd && depQ.pageNumber <= overInd) {
                // they will now be behind it.
                dependedOn_WillBreak.push(q);
                break;
              }
            }
          }
        }

        if (dependedOn_WillBreak.length) {
          setDependencyResolver(
            <SettleLogic
              areYouSure={"move"}
              action={"move"}
              questions={dependedOn_WillBreak}
              onCarryThrough={() => {
                setDependencyResolver(null);
                setRunSave(true);
                setShowLoading(true);
                setTimeout(() => {
                  finishSwitchingPages(activeInd, overInd);
                }, 1000);
              }}
              onNevermind={() => {
                setDependencyResolver(null);
              }}
              dependencies={dependencies}
              allQuestions={allQuestions}
              saveQuestions={onEditAllQs}
            />
          );
          return;
        }
      } else {
        // moving up
        // Can break its own
        let shouldBeRemoved = [];
        for (let q of activeQs) {
          if (q.conditionGroups) {
            for (let g of q.conditionGroups) {
              for (let c of g.conditions) {
                let dependency = allQuestions.find(
                  (qst) => qst.id === c.questionId
                );
                if (
                  dependency.pageNumber < activeInd &&
                  dependency.pageNumber >= overInd
                ) {
                  shouldBeRemoved.push(q);
                  break;
                }
              }
            }
          }
        }

        if (shouldBeRemoved.length) {
          setDependencyResolver(
            <SettleLogic
              removeItsOwn
              areYouSure={"move"}
              action={"move"}
              questions={shouldBeRemoved}
              onCarryThrough={() => {
                setDependencyResolver(null);
                setRunSave(true);
                setShowLoading(true);
                setTimeout(() => {
                  finishSwitchingPages(activeInd, overInd);
                }, 1000);
              }}
              onNevermind={() => {
                setDependencyResolver(null);
              }}
              dependencies={dependencies}
              allQuestions={allQuestions}
              saveQuestions={onEditAllQs}
            />
          );
          return;
        }
      }
    }

    finishSwitchingPages(activeInd, overInd);
  }

  function finishSwitchingPages(activeInd, overInd) {
    setShowLoading(true);

    if (designSettings?.pageSettings) {
      let newSettings = JSON.parse(JSON.stringify(designSettings));
      let changed = false;
      let oldActive = newSettings.pageSettings[activeInd];

      if (activeInd > overInd) {
        // moving up
        for (let i = activeInd - 1; i >= overInd; i--) {
          if (newSettings.pageSettings[i]) {
            newSettings.pageSettings[i + 1] = newSettings.pageSettings[i];
            delete newSettings.pageSettings[i];
            changed = true;
          }
        }
      } else {
        // moving down
        for (let i = activeInd + 1; i <= overInd; i++) {
          if (newSettings.pageSettings[i]) {
            newSettings.pageSettings[i - 1] = newSettings.pageSettings[i];
            delete newSettings.pageSettings[i];
            changed = true;
          }
        }
      }
      if (oldActive) {
        newSettings.pageSettings[overInd] = oldActive;
        changed = true;
      }

      if (changed) {
        saveDesign(JSON.stringify(newSettings));
      }
    }

    movePage.mutate(
      {
        projectId: id,
        activePageNum: activeInd,
        overPageNum: overInd,
      },
      {
        onSuccess: (data) => {
          refetchQs();
        },
      }
    );
  }

  function newComponentListener(e) {
    let container = document.getElementById(activeId);
    if (container && !container.contains(e.target)) {
      document.removeEventListener("click", newComponentListener, false);
      setRunSave(true);
      setActiveId("");

      let Qs = document.getElementById("questionsContainer");
      if (Qs && !Qs.contains(e.target)) {
        setOverInd(-1);
      }
    }
  }

  function checkForClickOutsideQs(e) {
    let Qs = document.getElementById("questionsContainer");
    if (Qs && !Qs.contains(e.target)) {
      // If in resolver, don't do anything
      let resolver = document.getElementById("dependencyResolver");
      if (resolver && resolver.contains(e.target)) {
        return;
      }

      if (root && !root.contains(e.target)) {
        // It's a color picker - or some modal like it
        return;
      }

      setActiveId("");
      document.removeEventListener("click", checkForClickOutsideQs, false);
      setOverInd(-1);
    }
  }

  useEffect(() => {
    if (
      activeId &&
      activeId !== "ProgBar" &&
      activeId !== "editLogo" &&
      activeId !== "nav"
    ) {
      let listener = activeId.includes("temporary")
        ? newComponentListener
        : checkForClickOutsideQs;

      document.addEventListener("click", listener, false);
      return () => {
        document.removeEventListener("click", listener, false);
      };
    }
  }, [activeId]);

  useEffect(() => {
    if (runSave) {
      saveToDB();
      setRunSave(false);
    }
  }, [runSave]); // This is here so that on those document event listeners, they can trigger the saving, but by the time it comes here the state values will be updated. The manual event listeners stay in the same scope they were called in, so they don't have the data to update

  useEffect(() => {
    if (fixNewQ) {
      let copyOfAll = [...allQuestions];
      let copyOfPage = [...questions];

      let allInd = copyOfAll.findIndex((q) => q.id === fixNewQ.old);
      let pageInd = copyOfPage.findIndex((q) => q.id === fixNewQ.old); // Is not the pageOrderIndex because a new quesion may have been added or a question been deleted.

      if (allInd >= 0 && pageInd >= 0) {
        // In case it's been deleted
        copyOfAll[allInd].id = fixNewQ.new;
        delete copyOfAll[allInd].new;

        copyOfPage[pageInd].id = fixNewQ.new;
        delete copyOfPage[pageInd].new;

        setAllQuestions(copyOfAll);
        setQuestions(copyOfPage);
        console.log(copyOfPage);

        if (activeId === fixNewQ.old) {
          setActiveId(fixNewQ.new);
        }
      }

      setFixNewQ(undefined);
    }
  }, [fixNewQ]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragStart(event) {
    const { active } = event;
    setMovingId(active.id);
    setClose(overInd - 1);
    setTimeout(() => {
      setClose(overInd);
      setOverInd(-1);
    }, 50);
    setActiveId("");

    let copy = questions.map((q) => q.id);
    setSnapShot(copy);
  }

  function handleDragOver(event) {
    const { active, over } = event;
    if (seeAll) {
      if (active.id !== over.id) {
        //update allQuestions
        let oldIndex = allQuestions.findIndex((q) => q.id === active.id);
        let newIndex = allQuestions.findIndex((q) => q.id === over.id);
        let overQ = allQuestions.find((q) => q.id === over.id);
        let temparray = arrayMove(allQuestions, oldIndex, newIndex);
        temparray[newIndex].pageNumber = overQ.pageNumber;

        let currpage = 1;
        let index = 0;
        let questionNumber = 1;
        for (let i = 0; i < temparray.length; i++) {
          if ("questionText" in temparray[i]) {
            temparray[i].index = questionNumber;
            questionNumber += 1;
          }
          if (temparray[i].pageNumber === currpage) {
            temparray[i].pageOrderIndex = index;
            index += 1;
          } else {
            currpage += 1;
            index = 0;
            temparray[i].pageOrderIndex = index;
          }
        }
        // debugger;
        setAllQuestions(temparray);
        let tempall = [...allQuestions];

        let tempQArrays = [];
        currpage = 1;
        let tempQs = [];
        for (let q of tempall) {
          if (q.pageNumber === currpage) {
            tempQs.push(q);
          } else {
            currpage += 1;
            tempQArrays.push(tempQs);
            tempQs = [];
            tempQs.push(q);
          }
        }
        tempQArrays.push(tempQs);
        if (currpage < maxPage) {
          while (currpage < maxPage) {
            tempQArrays.push([]);
            currpage += 1;
          }
        }

        setQuestions(tempQArrays);
        setEditedQs(true);
      }
    } else {
      if (active.id !== over.id) {
        const oldAllInd = allQuestions.findIndex((q) => q.id === active.id);
        const newAllInd = allQuestions.findIndex((q) => q.id === over.id);

        let all = arrayMove(allQuestions, oldAllInd, newAllInd);
        all[oldAllInd].changed = true;
        all[newAllInd].changed = true;

        let thisPage = [];
        let questionNumber = 1;
        for (let q of all) {
          if ("questionText" in q) {
            q.index = questionNumber;
            questionNumber += 1;
          }
          if (q.pageNumber === currPage) {
            q.pageOrderIndex = thisPage.length;
            thisPage.push(q);
          }
        }

        setQuestions(thisPage);
        setAllQuestions(all);
        setEditedQs(true);
      }
    }
  }

  function handleDragEnd(event) {
    // checking for dependency issues.
    // moving down
    // by moving down it will only break other's logic
    let resolve = false;
    let dependents = dependencies.find((d) => d.questionId === movingId);
    if (dependents) {
      let movingInd = questions.findIndex((q) => q.id === movingId);
      for (let dependent of dependents.dependencies) {
        let dependentIndex = allQuestions.findIndex(
          (q) => q.id === dependent.dependentId
        );
        if (movingInd > dependentIndex) {
          setDependencyResolver(
            <SettleLogic
              areYouSure={"move"}
              action={"move"}
              question={questions.find((q) => q.id === movingId)}
              onCarryThrough={() => {
                setDependencyResolver(null);
                setRunSave(true);
              }}
              onNevermind={() => {
                setDependencyResolver(null);
                setBackToSnapShot();
              }}
              dependencies={dependencies}
              allQuestions={allQuestions}
              saveQuestions={onEditAllQs}
            />
          );
          resolve = true;
          break;
        }
      }
    }

    if (!resolve) {
      // moving Up
      // by moving up it will only break it's own
      let movingQ = questions.find((q) => q.id === movingId);
      if (movingQ.conditionGroups && movingQ.conditionGroups.length) {
        let dependsOn = [];
        forEach(movingQ.conditionGroups, (g) => {
          forEach(g.conditions, (c) => {
            if (!dependsOn.includes(c.questionId)) {
              dependsOn.push(c.questionId);
            }
          });
        });

        let movingInd = questions.findIndex((q) => q.id === movingId);
        for (let id of dependsOn) {
          let index = allQuestions.findIndex((q) => q.id === id);
          if (movingInd < index) {
            setDependencyResolver(
              <SettleLogic
                removeItsOwn
                areYouSure={"move"}
                action={"move"}
                question={questions.find((q) => q.id === movingId)}
                onCarryThrough={() => {
                  setDependencyResolver(null);
                  setRunSave(true);
                }}
                onNevermind={() => {
                  setDependencyResolver(null);
                  setBackToSnapShot();
                }}
                dependencies={dependencies}
                allQuestions={allQuestions}
                saveQuestions={onEditAllQs}
              />
            );
            resolve = true;
            break;
          }
        }
      }
    }

    setMovingId("");
    if (!resolve) {
      setRunSave(true);
    }
  }

  function setBackToSnapShot() {
    let order = [];

    for (let i = 0; i < snapShot.length; i++) {
      let q = questions.find((q) => q.id === snapShot[i]);
      q.pageOrderIndex = i;
      order.push(q);
    }

    setSnapShot(undefined);
    setQuestions(order);
    // no need for all, since nothing should've updated
  }

  const [movingId, setMovingId] = useState("");

  function getActive() {
    let question = allQuestions.find((q) => q.id === movingId);

    return (
      <div>
        <SurveyComponent
          component={question}
          active={activeId === question.id}
          status={status}
          moveTo={moveTo}
          canEdit={checkRole()}
          designSettings={designSettings}
          roles={roles}
          answers={answers}
          survey={survey}
          calculateQuestionHealth={calculateQuestionHealth}
          questionNumber={
            question?.index ? question?.index : getNumber(question)
          }
        ></SurveyComponent>
      </div>
    );
  }

  function onDeletePage(number) {
    let hadPageSettings =
      designSettings?.pageSettings && number in designSettings.pageSettings;
    let name = hadPageSettings ? designSettings.pageSettings[number] : number;
    if (
      window.confirm(
        "Are you sure you want to delete page " +
          name +
          "? All questions on it will also be deleted"
      )
    ) {
      let newSettings = JSON.parse(JSON.stringify(designSettings));
      if (hadPageSettings) {
        delete newSettings.pageSettings[number];
      }
      if (newSettings?.pageSettings) {
        for (let i = number + 1; i < maxPage + 1; i++) {
          if (i in newSettings.pageSettings) {
            let copy = newSettings.pageSettings[i];
            newSettings.pageSettings[i - 1] = copy;
            delete newSettings.pageSettings[i];
          }
        }
      }

      deletePage.mutate(
        {
          projectId: id,
          pageNumber: number,
          designSettings: JSON.stringify(newSettings),
        },
        {
          onSuccess: () => {
            let newPage = currPage;
            if (currPage >= number && currPage > 1) {
              newPage = currPage - 1;
            }
            refetchQs(newPage);
            refetchDSettings();
            setMaxPage((max) => max - 1);
            console.log("page deleted");
          },
        }
      );
    }
  }

  function onCopy(question, e, placement) {
    let copy = {};
    if ("questionText" in question) {
      copy = {
        id: "temporaryid:" + shortId(),
        type: question.type,
        projectId: id,
        pageOrderIndex: questions.length,
        pageNumber: currPage,
        isHidden: false,
        description: "",
        // buckets: [],
        // hasBuckets: false,
        new: true,
        questionText: question.questionText,
        description: question.description,
        encoding: createEncodingCopy(question.encoding),
        descriptionEncoding: createEncodingCopy(question.descriptionEncoding),
      };
      if (question.choiceQuestion) {
        copy.choiceQuestion = JSON.parse(
          JSON.stringify(question.choiceQuestion)
        ); // has second level of items
      }
      if (question.scaleQuestion) {
        copy.scaleQuestion = { ...question.scaleQuestion };
      }
      if (question.textQuestion) {
        copy.textQuestion = { ...question.textQuestion };
      }
      if (question.matrixQuestion) {
        copy.matrixQuestion = JSON.parse(
          JSON.stringify(question.matrixQuestion)
        ); // has second level of items
      }
    } else {
      copy = {
        id: "temporarySectionId:" + shortId(),
        type: "Description",
        projectId: id,
        pageOrderIndex: questions.length,
        new: true,
        pageNumber: currPage,
        sectionSettings: JSON.parse(JSON.stringify(question.sectionSettings)),
        bodyText: question.bodyText,
        encoding: createEncodingCopy(question.encoding),
      };
    }

    //update questions based on placement of question
    let newIndex = questions.length;
    if (placement === "abovepage") {
      newIndex = 0;
    } else if (placement === "above") {
      newIndex = allQuestions.findIndex((q) => q.id === question.id);
    } else if (placement === "below") {
      newIndex = allQuestions.findIndex((q) => q.id === question.id);
      if (newIndex != allQuestions.length) {
        newIndex = newIndex + 1;
      }
    }

    let temparray = [...allQuestions];
    if (newIndex === allQuestions.length) {
      temparray.push(copy);
    } else {
      temparray.splice(newIndex, 0, copy);
    }

    // let oldIndex = allQuestions.findIndex((q) => q.id === active.id);
    // // let newIndex = allQuestions.findIndex((q) => q.id === over.id);
    // let overQ = allQuestions.find((q) => q.id === over.id);
    // let temparray = arrayMove(allQuestions, oldIndex, newIndex);

    let currpage = 1;
    let index = 0;
    let questionNum = 1;
    for (let i = 0; i < temparray.length; i++) {
      if (temparray[i].pageNumber === currpage) {
        temparray[i].pageOrderIndex = index;
        index += 1;
      } else {
        currpage += 1;
        index = 0;
        temparray[i].pageOrderIndex = index;
      }

      if ("questionText" in temparray[i]) {
        temparray[i].index = questionNum;
        questionNum += 1;
        temparray[i].changed = true;
      }
    }
    setAllQuestions(temparray);
    let tempall = [...allQuestions];

    let tempQArrays = [];
    currpage = 1;
    let tempQs = [];
    for (let q of tempall) {
      if (q.pageNumber === currpage) {
        tempQs.push(q);
      } else {
        currpage += 1;
        tempQArrays.push(tempQs);
        tempQs = [];
        tempQs.push(q);
      }
    }
    tempQArrays.push(tempQs);
    if (currpage < maxPage) {
      while (currpage < maxPage) {
        tempQArrays.push([]);
        currpage += 1;
      }
    }

    setQuestions(tempQArrays);

    addQToState(copy);

    e.stopPropagation();
  }

  function onPaste(index) {
    if (copy) {
      let it = { ...copy };
      it.pageNumber = currPage;
      it.pageOrderIndex = index;
      addQToState(it);
      setCopy(null);
    }
  }

  function onAnswer(qId, answer) {
    let copy = { ...answers };
    copy[qId] = answer;
    setAnswers(copy);
  }

  function onRetract(qId) {
    let copy = { ...answers };
    delete copy[qId];
    setAnswers(copy);
  }

  const getNumQs = () => {
    let count = 0;
    forEach(allQuestions, (q) => {
      if (q.type !== "Description") {
        count++;
      }
    });
    return count;
  };
  const numQs = getNumQs();

  function checkToSave(navAway) {
    return (
      <DoYouWantToSave navAway={navAway} onSave={() => setRunSave(true)} />
    );
  }

  function changeSettings(newSettings) {
    setEditedDesign(true);
    setDesignSettings(newSettings);
    setBeforeNavAway({
      getDisplay: checkToSave,
    });
  }

  function setUpActiveQ(question) {
    if (status !== "Open" && activeId !== question.id) {
      setActiveTab("");
      setActiveId(question.id);

      let newInd = questions.findIndex((q) => q.id === question.id);
      if (newInd !== overInd) {
        setOverInd(newInd);
      }
    }
  }

  function moveTo(ind) {
    if (activeId || movingId || ind == overInd) {
      return;
    }

    // check to see if it is already closing, if so, leave it there?

    //decide which direction to go
    if (overInd >= 0) {
      // we were hovering over one
      if (ind > overInd) {
        // moving down
        setClose(overInd - 1);
      } else if (ind < overInd) {
        // moving up
        setClose(overInd);
      }
    }
    setOverInd(ind);
  }

  function closeAll() {
    if (activeId || movingId || dependencyResolver) {
      return;
    }

    setClose(overInd - 1);
    setTimeout(() => {
      setClose(overInd);
      setOverInd(-1);
    }, 50);
  }

  function setUpDesignEditing(string) {
    setActiveId(string);
    if (activeId) {
      setOverInd(-1);
    }
  }

  function exitDesignEditing() {
    setActiveId("");
    setRunSave(true);
  }

  const navigate = useNavigate();
  function goToDistribute() {
    localStorage.setItem("activeprojecttab", 2);
    localStorage.setItem("activedelivertab", 1);
    if (survey.isProgram) {
      navigate(`/program/${survey.id}/delivery/messages`, { replace: true });
    } else {
      navigate(`/project/${survey.id}/delivery/messages`, {
        replace: true,
      });
    }
  }

  const writeIn = useWriteInFinished();

  function runSwitch() {
    // writeIn.mutate(
    //   {},
    //   {
    //     onSuccess: (data) => {
    //       console.log("switch successful!");
    //       console.log("num: ", data.writeInFinished);
    //     },
    //     onError: (data) => {
    //       console.log("switch error");
    //     },
    //   }
    // );
  }

  function surveyHealth() {
    let health = calculateSurveyHealth(survey, questions);

    return (
      <>
        {" "}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "1em",
          }}
        >
          <Stat
            title="Survey Health"
            value={
              <div>
                <Pie percentage={health?.health}></Pie>
              </div>
            }
            header
            titleStyle={{ fontSize: ".9em", fontWeight: "300" }}
            valueStyle={{
              fontSize: "1em",
            }}
            style={{ alignItems: "center", paddingTop: "1em" }}
          />

          {activeId && (
            <Stat
              title="Question Health"
              value={
                <div>
                  <Pie
                    percentage={
                      calculateQuestionHealth(
                        questions.find((q) => q.id === activeId)
                      )?.health
                    }
                  ></Pie>
                </div>
              }
              header
              titleStyle={{ fontSize: ".9em", fontWeight: "300" }}
              valueStyle={{
                fontSize: "1em",
              }}
              style={{ alignItems: "center", paddingTop: "1em" }}
            />
          )}
        </div>
        {health?.tips.length != 0 && (
          <div className={styles.tip}>
            <span>
              <a>Tip:</a>
              {health?.tips[0]}
            </span>
          </div>
        )}
      </>
    );
  }

  function getNumber(q) {
    let num = 1;
    for (let question of allQuestions) {
      if ("questionText" in question) {
        if (question?.id === q?.id) {
          return num;
        }
        num += 1;
      }
    }
  }

  function showPreview() {
    setRunSave(true);
    setTimeout(() => setShow(!show), 250);
  }

  // console.log(questions);

  return (
    <div
      className={styles.pageContainer}
      style={
        designSettings.backgroundColor
          ? { backgroundColor: designSettings.backgroundColor }
          : { backgroundColor: "transparent" }
      }
    >
      {checkRole() && (
        <div className={styles.constructionZone} id="constructionZone">
          {showLoading && <Loading />}

          {!showLoading && (
            <div
              className={styles.survey}
              onClick={(e) => e.stopPropagation()}
              id="survey"
            >
              {!showFinshedPage && (
                <>
                  {designSettings.showLogo &&
                    !seeAll &&
                    (designSettings.logoOnEveryPage || currPage == 1) && (
                      <EditLogo
                        settings={designSettings}
                        setSettings={changeSettings}
                        projId={id}
                        active={activeId === "editLogo"}
                        setActive={setUpDesignEditing}
                        close={exitDesignEditing}
                      />
                    )}

                  {!show && !seeAll && designSettings.showProgBar && (
                    <div
                      // className={styles.stick}
                      className={`${styles.progBar} ${
                        designSettings.progBarSticky ? styles.stick : ""
                      } `}
                      // style={{
                      //   backgroundColor: settings.backgroundColor,
                      // }}
                      // ref={progressbar}
                    >
                      <div
                        className={`${
                          designSettings.progBarSticky ? styles.shadow : ""
                        }`}
                      >
                        <EditableProgressBar
                          settings={designSettings}
                          setSettings={changeSettings}
                          active={activeId === "ProgBar"}
                          setActive={setUpDesignEditing}
                          close={exitDesignEditing}
                          numQs={numQs}
                          numAnswered={Object.keys(answers).length}
                        />
                      </div>
                    </div>
                  )}

                  <div
                    className={styles.questionsContainer}
                    id="questionsContainer"
                    onMouseLeave={
                      status !== "Open" ? () => closeAll() : undefined
                    }
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: designSettings?.questionGap
                        ? `${designSettings?.questionGap}px`
                        : "10px",
                    }}
                  >
                    <DndContext
                      sensors={sensors}
                      collisionDetection={closestCorners}
                      onDragEnd={handleDragEnd}
                      onDragOver={handleDragOver}
                      onDragStart={handleDragStart}
                      modifiers={[
                        restrictToVerticalAxis,
                        // restrictToParentElement,
                      ]}
                    >
                      {!seeAll && questions.length > 0 && (
                        <SortableContext
                          id={"list"}
                          items={questions}
                          strategy={verticalListSortingStrategy}
                        >
                          <div style={{ position: "relative" }}>
                            {status !== "Open" && (
                              <Add
                                show={!overInd}
                                close={close == -1}
                                onAddQ={() => handleCreateQuestion(0)}
                                onAddSection={() => handleCreateSection(0)}
                                onPaste={() => onPaste(0)}
                                index={0}
                                beginningOrEnd
                              />
                            )}
                          </div>
                          {questions.map((question, ind) => (
                            <React.Fragment key={ind}>
                              <SurveyComponent
                                key={question.id}
                                component={question}
                                active={activeId === question.id}
                                setActive={() => setUpActiveQ(question)}
                                onClose={() => setRunSave(true)}
                                status={status}
                                moveTo={moveTo}
                                pageInd={ind}
                                canEdit={checkRole()}
                                allQuestions={allQuestions}
                                onEditQ={onEditQ}
                                deleteQ={deleteQ}
                                designSettings={designSettings}
                                roles={roles}
                                onCopy={onCopy}
                                onAnswer={onAnswer}
                                onRetract={onRetract}
                                answers={answers}
                                onSaveSomething={() => setRunSave(true)}
                                dependents={dependencies.find(
                                  (d) => d.questionId === question.id
                                )}
                                setUpLogicResolver={setUpLogicResolver}
                                survey={survey}
                                calculateQuestionHealth={
                                  calculateQuestionHealth
                                }
                                questionNumber={
                                  question?.index
                                    ? question?.index
                                    : getNumber(question)
                                }
                              ></SurveyComponent>
                              <div style={{ position: "relative" }}>
                                <Add
                                  show={
                                    status !== "Open" &&
                                    (overInd - 1 == ind || overInd == ind)
                                  }
                                  close={close == ind}
                                  onAddQ={() => handleCreateQuestion(ind + 1)}
                                  onAddSection={() =>
                                    handleCreateSection(ind + 1)
                                  }
                                  onPaste={() => onPaste(ind + 1)}
                                  beginningOrEnd
                                />
                              </div>
                            </React.Fragment>
                          ))}
                        </SortableContext>
                      )}

                      {/* {seeAll &&
                    questions.map((pageQuestions, pageIndex) => (
                      <SortableContext
                        key={pageIndex}
                        items={
                          !(
                            "questionText" in pageQuestions ||
                            "bodyText" in pageQuestions
                          )
                            ? pageQuestions
                            : []
                        }
                        strategy={verticalListSortingStrategy}
                      >
                        <div className={styles.pageSection} id={"Page" + 1}>
                          <div className={styles.stickyHeader}>
                            <Header
                              title={"Page " + (pageIndex + 1)}
                              style={{ fontSize: ".7em" }}
                            ></Header>
                          </div>
                          {designSettings.showLogo &&
                            (!designSettings.logoOnEveryPage
                              ? pageIndex > 0
                                ? false
                                : true
                              : true) && (
                              <EditLogo
                                designSettings={designSettings}
                                image={image}
                                setImage={setImage}
                                projId={id}
                              />
                            )}
                          <SurveyComponent
                            component={question}
                            active={activeSettingsId === question.id}
                            onClick={() => onQuestionClick(question)}
                            status={status}
                            moveTo={moveTo}
                            pageInd={ind}
                            canEdit={checkRole()}
                            allQuestions={allQuestions}
                            saveSingleQ={saveSingleQ}
                            deleteQ={deleteQ}
                            designSettings={designSettings}
                            roles={roles}
                            onCopy-={onCopy}
                            onAnswer={onAnswer}
                            onRetract={onRetract}
                            answers={answers}
                          >
                          </SurveyComponent>
                          <EditableProgressBar
                            settings={designSettings}
                            setSettings={changeSettings}
                            active={activeSettingsId === "ProgBar"}
                            setActive={setUpEditing}
                            numQs={numQs}
                            numAnswered={Object.keys(answers).length}
                          />
                          {!(
                            "questionText" in pageQuestions ||
                            "bodyText" in pageQuestions
                          ) &&
                            pageQuestions.length > 0 &&
                            pageQuestions.map((question, i) => (
                              
                            ))}
                          <EditableNavigation
                            designSettings={designSettings}
                            // previousPage={previousPage}
                            // nextPage={nextPage}
                            // setShowFinishedPage={setShowFinishedPage}
                            page={pageIndex + 1}
                            maxPage={maxPage}
                          ></EditableNavigation>
                        </div>
                      </SortableContext>
                    ))} */}

                      <DragOverlay
                        dropAnimation={{
                          duration: 500,
                          easing: "cubic-bezier(0.18, 0.67, 0.6, 1.22)",
                        }}
                      >
                        {movingId && <>{getActive()}</>}
                      </DragOverlay>
                    </DndContext>
                  </div>
                  {questions.length === 0 && status !== "Open" && (
                    <Add
                      show={true}
                      onAddQ={() => handleCreateQuestion(0)}
                      onAddSection={() => handleCreateSection(0)}
                      blankPage
                      pageInd={0}
                      onPaste={onPaste}
                      emptyPage
                    />
                  )}
                  {!seeAll && (
                    <EditableNavigation
                      settings={designSettings}
                      setSettings={changeSettings}
                      previousPage={previousPage}
                      nextPage={nextPage}
                      onFinish={() => {
                        setShowFinishedPage(true);
                        setActiveId("");
                      }}
                      page={currPage}
                      maxPage={maxPage}
                      active={activeId === "nav"}
                      setActive={setUpDesignEditing}
                      close={exitDesignEditing}
                    ></EditableNavigation>
                  )}
                  {!show && !seeDesignSettings && window.innerWidth < 830 && (
                    <div className={styles.quickButtonContainer}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "1em",
                          justifyContent: "center",
                          flexWrap: "wrap",
                        }}
                      >
                        <Button
                          blue
                          height="35px"
                          onClick={showLoading ? undefined : onSaveClick}
                          shadow
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "5px",
                              alignItems: "center",
                            }}
                          >
                            Save {savingStatus}
                          </div>
                        </Button>
                        <Button blue height="35px" onClick={showPreview} shadow>
                          Preview
                        </Button>
                      </div>
                      <div
                        className={styles.editSurveyPencil}
                        onClick={() => setSeeDesignSettings(true)}
                      >
                        <i className="bi bi-pencil"></i>
                      </div>
                    </div>
                  )}
                </>
              )}
              {showFinshedPage && (
                <SurveyCompletedInConstruction
                  message={finishedMsg}
                  returnToSurvey={() => setShowFinishedPage(false)}
                  settings={designSettings}
                  onEdit={editFinishMessage}
                ></SurveyCompletedInConstruction>
              )}
            </div>
          )}

          {dependencyResolver && (
            <div id="dependencyResolver">{dependencyResolver}</div>
          )}
        </div>
      )}
      {checkRole() && (window.innerWidth < 830 ? seeDesignSettings : true) && (
        <div
          className={styles.settingsContainer}
          style={{
            position: window.innerWidth < 830 ? "absolute" : "",
            right: "0",
            boxShadow:
              window.innerWidth < 830 ? "rgba(0, 0, 0, 0.15) 0px 5px 15px" : "",
            zIndex: "95",
          }}
        >
          <div className={styles.settingsContent}>
            {/* <div>
                <Label>Survey Settings</Label>
            
              </div> */}
            {window.innerWidth < 830 && (
              <i
                className="bi bi-x-lg"
                style={{
                  position: "absolute",
                  top: "1em",
                  right: "1em",
                  cursor: "pointer",
                }}
                onClick={() => setSeeDesignSettings(false)}
              ></i>
            )}
            {checkRole() && (
              <div
                onClick={() => {
                  setActiveId("");
                }}
              >
                <SurveyDesign
                  settings={designSettings}
                  setSettings={changeSettings}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                  numPages={maxPage}
                  setGetAll={setSeeAll}
                  currPage={currPage}
                  setCurrPage={setCurrPage}
                  allQuestions={allQuestions}
                  onDeletePage={onDeletePage}
                  addPage={addPage}
                  switchPages={switchPages}
                  status={status}
                ></SurveyDesign>
              </div>
            )}

            {checkRole() && (
              <div
                style={{
                  paddingTop: "1em",
                  display: "flex",
                  gap: "1em",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  flexDirection: "column",
                  padding: "0em 1em",
                }}
              >
                {status !== "Open" && (
                  <>
                    <div className={styles.addBox}>
                      <span
                        onClick={() => handleCreateQuestion(questions.length)}
                      >
                        {" "}
                        <i className="bi bi-plus-lg"></i> Question
                      </span>
                      <span
                        onClick={() => handleCreateSection(questions.length)}
                      >
                        {" "}
                        <i className="bi bi-plus-lg"></i> Section
                      </span>
                    </div>
                    <div className={styles.addBox2}>
                      {maxPage > 1 && !seeAll && (
                        <span
                          className={styles.deletePage}
                          onClick={() => onDeletePage(currPage)}
                        >
                          {" "}
                          <i
                            className="bi bi-trash"
                            style={{ marginRight: ".3em" }}
                          ></i>{" "}
                          Page
                        </span>
                      )}
                      <span onClick={addPage}>
                        {" "}
                        <i
                          className="bi bi-plus-lg"
                          style={{ marginRight: ".3em" }}
                        ></i>{" "}
                        Page
                      </span>
                    </div>
                  </>
                )}
                <div
                  style={{
                    display: "flex",
                    gap: "1em",
                    justifyContent: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Button
                    blue
                    height="35px"
                    onClick={showLoading ? undefined : onSaveClick}
                    shadow
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      Save {savingStatus}
                    </div>
                  </Button>
                  <Button blue height="35px" onClick={showPreview} shadow>
                    Preview
                  </Button>

                  {/* <Button height="35px" onClick={runSwitch}>
                    Run Switch
                  </Button> */}
                </div>
                {/* Go to messages */}
                {survey.status === "Open" && (
                  <div className={styles.sendText}>
                    Survey is <span style={{ color: "#15bcc7" }}>Open</span>.
                    You cannot edit the survey questions when the survey is{" "}
                    <span>Open</span>. You can close and reopen the survey in
                    the settings <i className="bi bi-gear"></i>
                  </div>
                )}
                {survey.useGPS && surveyHealth()}

                {!survey.isProgram &&
                  survey.status === "Draft" &&
                  roles.canSendOrgSurveyEmails && (
                    <div className={styles.sendText}>
                      When you feel your survey is ready, go to the{" "}
                      <span className={styles.link} onClick={goToDistribute}>
                        Distribute
                      </span>{" "}
                      tab to view options on how to deliver your survey
                    </div>
                  )}
              </div>
            )}
          </div>
        </div>
      )}
      {show && (
        <div
          className={styles.expandedPreview}
          style={{
            backgroundColor: designSettings.backgroundColor,
            // position: !roles.editSurveyDesign && !roles.editSurveyQuestions ? "absolute" : ""
          }}
        >
          <div
            className={styles.close}
            onClick={() => {
              // window.location.reload(false);
              setShow(false);
            }}
          >
            <div style={{ transform: "rotateX(180deg)" }}>
              <i className="bi bi-arrow-return-left"></i>
            </div>
            Back to Build
          </div>
          <Survey projectId={projectId} preview></Survey>
        </div>
      )}
      {!checkRole() && (
        <div
          className={styles.expandedPreview}
          style={{
            backgroundColor: designSettings.backgroundColor,
            // position: "absolute",
          }}
        >
          <Survey projectId={projectId} preview></Survey>
        </div>
      )}
    </div>
  );
}

function Add({
  show,
  close,
  onAddQ,
  onAddSection,
  copy,
  onPaste,
  beginningOrEnd,
  emptyPage,
}) {
  const [closing, setClosing] = useState(false);

  return (
    <>
      {show && (
        <div
          className={`${show ? styles.showAdd : ""} ${styles.addContainer} ${
            closing
              ? beginningOrEnd
                ? styles.endPieceClosing
                : styles.closing
              : ""
          }

        `}
          style={emptyPage ? { position: "static", gap: "2em" } : undefined}
        >
          <div
            className={`${emptyPage ? styles.addBtnBig : styles.addBtn} ${
              !emptyPage && styles.addQ
            }`}
            onClick={onAddSection}
          >
            + Section
            {emptyPage && (
              <span>
                Can only add text into a section. Good for cover pages or
                separating sections of your survey
              </span>
            )}
          </div>
          <div
            className={`${emptyPage ? styles.addBtnBig : styles.addBtn} ${
              !emptyPage && styles.addQ
            }`}
            onClick={onAddQ}
          >
            + Question
            {emptyPage && (
              <span>
                Your survey question. Can be multiple choice, scale, free
                response, or ranking type questions.
              </span>
            )}
          </div>
          {copy && (
            <div
              className={`${styles.addBtn} ${styles.paste}`}
              onClick={onPaste}
            >
              Paste
            </div>
          )}
        </div>
      )}
      {/* {!show && !closing && !beginningOrEnd && (
        <div style={{ height: "10px" }}></div>
      )} */}
    </>
  );
}

// function Add({ onAddQ, onAddSection, blankPage, pageInd, copy, onPaste }) {
//   const [show, setShow] = useState(blankPage ? true : false);
//   const [closing, setClosing] = useState();

//   const [timer, setTimer] = useState();

//   function startTimer() {
//     let temp = setTimeout(() => {
//       setShow(true);
//       setTimer(null);
//     }, 100);
//     setTimer(temp);
//   }

//   function exit() {
//     if (timer) {
//       clearTimeout(timer);
//       setTimer(null);
//     }
//     if (show) {
//       setClosing(true);
//       setTimeout(() => setClosing(false), 1100);
//     }
//     setShow(false);
//   }

//   function addSection() {
//     setShow(false);
//     onAddSection(pageInd);
//   }

//   function addQuestion() {
//     setShow(false);
//     onAddQ(pageInd);
//   }

//   function paste() {
//     setShow(false);
//     onPaste(pageInd);
//   }

//   return (
//     <div
//       className={`${show ? styles.showAdd : ""} ${styles.addContainer} ${
//         closing ? styles.closing : ""
//       }`}
//       onMouseEnter={blankPage ? null : startTimer}
//       onMouseLeave={blankPage ? null : exit}
//     >
//       <div
//         className={`${styles.addBtn} ${styles.addSection}`}
//         onClick={show ? addSection : undefined}
//       >
//         + Section
//       </div>
//       <div
//         className={`${styles.addBtn} ${styles.addQ}`}
//         onClick={show ? addQuestion : undefined}
//       >
//         + Question
//       </div>
//       {copy && (
//         <div
//           className={`${styles.addBtn} ${styles.paste}`}
//           onClick={show ? paste : undefined}
//         >
//           Paste
//         </div>
//       )}
//     </div>
//   );
// }
