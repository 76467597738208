/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction Data. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: brodyspencer

*/
// eslint-disable-next-line no-unused-vars
import React from "react";
import MultipleChoice from "./MultipleChoice/MultipleChoice";
import NumberScale from "./NumberScale/NumberScale";
import NumberSlider from "./NumberSlider/NumberSlider";
// import Ranking from "./Ranking";
import TextQ from "./TextQuestion/TextQ";
import styles from "./Question.module.scss";
import TextEditorTry from "components/inputs/input_fields/TextEditorTry/TextEditorTry";
import MatrixQ from "./Matrix/MatrixQ";

// const initgroups = [
//   {
//     id: "p0cv89zhdsf",
//     operator: "When all conditions match",
//     conditions: [
//       {
//         id: "pfsdifjod",
//         groupNumber: 0,
//         questionId: "f648eb8a-2437-42da-94f3-11ded444d204",
//         question: {
//           id: "f648eb8a-2437-42da-94f3-11ded444d204",
//           questionText: "Is this the provider you usually see if you need a check-up, want advice about a health problem, or get sick or hurt?",
//         },
//         operator: "equals",
//         answer: "2",
//       },
//     ],
//   },
// ];

/**
 *
 * @param {Object} question the question object
 * @param {Function} onAnswered what to do when answered
 * @param {Function} onRetract what to do when the answer is taken back
 * @param {Map} answerMap to know and hold the current answers
 * @param {string} color the base color for the buttons and such
 * @returns
 */
export const Question = ({
  question,
  onAnswered,
  onRetract,
  answerMap,
  color,
  multipleChoiceBars,
  settings,
  otherMap,
  questionNumber,
}) => {
  return (
    <div
      className={styles.questionContainer}
      style={{
        boxShadow:
          "boxShadow" in settings
            ? settings.boxShadow
              ? "rgba(0, 0, 0, 0.24) 0px 3px 8px"
              : ""
            : "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        // backgroundColor:
        //   "sectionSettings" in component &&
        //   component.sectionSettings?.hasBackgroundColor
        //     ? component.sectionSettings?.backgroundColor
        //     : "questionText" in component
        //     ?
        //     : "transparent",
      }}
    >
      <div className={styles.descriptionContainer}>
        <div style={{ display: "flex", width: "100%" }}>
          {settings?.numbers && (
            <div className={styles.number}>{questionNumber}.</div>
          )}
          <TextEditorTry encoding={question.encoding} />
        </div>

        {question.description &&
          question.description != "" &&
          question.description != "\n" && (
            <TextEditorTry encoding={question.descriptionEncoding} />
          )}
      </div>
      {(question.type === "MultipleChoice" || question.type === "Ranking") &&
        question.choiceQuestion && (
          <MultipleChoice
            question={question}
            onAnswered={onAnswered}
            answerMap={answerMap}
            onRetract={onRetract}
            color={color}
            isMultiSelect={question?.choiceQuestion?.isMultiSelect}
            isRanking={question?.choiceQuestion?.isRanking}
            barDesign={multipleChoiceBars}
            savedOther={otherMap[question.id]}
            settings={settings}
          />
        )}
      {question.type === "NumberScale" && question.scaleQuestion && (
        <NumberScale
          question={question}
          onAnswered={onAnswered}
          onRetract={onRetract}
          color={color}
          startVal={answerMap[question.id]}
        />
      )}
      {question.type === "NumberSlider" && question.scaleQuestion && (
        <NumberSlider
          question={question}
          onAnswered={onAnswered}
          color={color}
          value={answerMap[question.id]}
        />
      )}
      {/* {question.type === "Ranking" && <Ranking question={question} />} */}
      {question.type === "Text" && (
        <TextQ
          question={question}
          onAnswered={onAnswered}
          onRetract={onRetract}
          color={color}
          value={answerMap[question.id] ? answerMap[question.id] : ""}
          answered={question.id in answerMap}
        />
      )}
      {question.type === "Matrix" && (
        <MatrixQ
          question={question}
          designSettings={settings}
          onAnswer={onAnswered}
          onRetract={onRetract}
          answerMap={answerMap}
        />
      )}
    </div>
  );
};

export default Question;
