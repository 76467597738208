/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/

// External
import { useQueryClient } from "react-query";
import { gql } from "graphql-request";

// Internal
import { useGqlQuery, useGqlMutation } from "api/Api";

// GRAPHQL API

export const useFetchEmailsGql = () => {
  const query = gql`
    query {
      allEmails {
        id
        project_id
        subject
        audience_id
        status
        date
        strength
      }
    }
  `;

  return useGqlQuery(["emails"], query, {});
};


export const useSendDistributionGql = () => {
  const mutation = gql`
    mutation Mutation($distribution: String!, $projectId: String!, $audienceSchedule: String!, $timeZone: String!) {
      sendDistribution2(distribution: $distribution, projectId: $projectId, audienceSchedule: $audienceSchedule, timeZone: $timeZone)
    }
  `;
  return useGqlMutation(mutation);
};

export const useSendDistributionPreview = () => {
  const mutation = gql`
    mutation Mutation($distribution: String!, $projectId: String!, $preview: PreviewParticipant!) {
      sendDistributionPreview(distribution: $distribution, projectId: $projectId, preview: $preview)
    }
  `;
  return useGqlMutation(mutation);
};

export const useCancelSend = () => {
  const mutation = gql`
    mutation Mutation($id: String!) {
      cancelDistributionSend(id: $id)
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onSuccess: () => {
      queryClient.invalidateQueries(["emails"]);
    },
  };

  return useGqlMutation(mutation, options);
};

