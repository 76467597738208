import { useEffect, useState } from "react";
import styles from "./SurveyWidget.module.scss";
import { useFetchMetrics } from "api/resources/account/widget";

export default function BigMetric({ settings, id, titleId }) {
  function getQueryObj() {
    let query = {
      projectIds: [settings?.survey?.id]
    };
    if (settings.metrics.big?.code) {
      query[settings.metrics.big.code] = true;
    }
    return query;
  }

  const [query, setQuery] = useState(getQueryObj());

  const getMetrics = useFetchMetrics(query, id);

  useEffect(() => {
    setQuery(getQueryObj());
  }, [settings?.survey?.id, settings.metrics]);

  useEffect(() => {
    getMetrics.refetch();
  }, [query]);

  function getBottomPadding() {
    let title = document.getElementById(titleId);
    if (title && title.clientHeight < 35) {
      return title.clientHeight + "px";
    }
    return "5px";
  }

  return (
    <div
      className={styles.bigMetricContainer}
      style={
        settings.showName ? { paddingBottom: getBottomPadding() } : undefined
      }
    >
      <div className={styles.metricBox}>
        <div className={styles.MTitle}>{settings.metrics.big.title}</div>
        {getMetrics.isSuccess && (
          <div className={styles.Mmetric}>
            {getMetrics.data.getMetrics[settings.metrics.big?.code] !==
            undefined
              ? getMetrics.data.getMetrics[settings.metrics.big.code]
              : settings.metrics.big.metric}
          </div>
        )}
      </div>
    </div>
  );
}
