import React, { useState } from "react";
import styles from "./ProgressBar.module.scss";

export function ProgressBar({ settings, numAnswered, numQuestions, percentage }) {
  function findCurrentProgress() {
    if (numQuestions) {
      return (numAnswered / numQuestions) * 100 + "%";
    }
    return 0;
  }
  const currentProgress = percentage ? percentage + "%" : findCurrentProgress();

  return (
    <>
      {(settings.showProgBar || settings.showProgQs) && (
        <div className={styles.progressContainer}>
          <div
            className={styles.fillProgress}
            style={{
              visibility: `${settings.showProgBar ? "visible" : "hidden"}`,
              backgroundColor: settings?.progBarEmptyColor
                ? settings.progBarEmptyColor
                : null,
            }}
          >
            <div
              className={styles.progressBar}
              style={{
                width: `${currentProgress}`,
                backgroundColor: settings.progBarColor
                  ? settings.progBarColor
                  : settings.baseColor
                  ? settings.baseColor
                  : "#15bcc7",
              }}
            ></div>
          </div>

          {settings.showProgQs && (
            <div
              className={styles.progressReport}
              style={
                settings?.progQsColor ? { color: settings.progQsColor } : null
              }
            >
              {" "}
              {numAnswered < numQuestions
                ? `Question ${numAnswered} of ${numQuestions}`
                : "Completed"}
            </div>
          )}
        </div>
      )}
    </>
  );
}
