import { useState, useEffect } from "react";
import styles from "./AnswerTable.module.scss";
import ContactDetails from "components/Popout/ContactProfile";
import TableChart from "components/Charts/Table/TableChart";
import { combinedProjIds, combinedQs } from "../Visualization";
import {
  getTitleContainerStyle,
  getTitleStyle,
  listCustomFields,
} from "./Table";
import { useGetCorrespondingComments } from "api/resources/projects/answers";
import { Loading } from "components/Loading/Loading";

export default function DataTable({
  answers,
  filters,
  onClose,
  viz,
  inEdit,
  projects,
  custom_fields,
  togglespreadsheet,
  chartData,
  setChartData,
  editMode,
  spreadsheetmode,
}) {
  const vizQs = combinedQs(viz);

  function allNPS() {
    for (let q of vizQs) {
      if (!q.isScore) {
        return false;
      }
    }
    return true;
  }

  const addComment = !editMode && viz.designSettings.includeComment && allNPS();

  return (
    <>
      {!addComment && (
        <Data
          answers={answers}
          Qs={vizQs}
          onClose={onClose}
          viz={viz}
          inEdit={inEdit}
          projects={projects}
          custom_fields={custom_fields}
          togglespreadsheet={togglespreadsheet}
          chartData={chartData}
          setChartData={setChartData}
          editMode={editMode}
          spreadsheetmode={spreadsheetmode}
        />
      )}
      {addComment && (
        <TackOnComment
          answers={answers}
          Qs={vizQs}
          filters={filters}
          onClose={onClose}
          viz={viz}
          inEdit={inEdit}
          projects={projects}
          custom_fields={custom_fields}
          togglespreadsheet={togglespreadsheet}
          chartData={chartData}
          setChartData={setChartData}
          spreadsheetmode={spreadsheetmode}
        />
      )}
    </>
  );
}

function TackOnComment({
  answers,
  Qs,
  filters,
  onClose,
  viz,
  inEdit,
  projects,
  custom_fields,
  togglespreadsheet,
  chartData,
  setChartData,
  spreadsheetmode,
}) {
  const getCorresponding = useGetCorrespondingComments(
    Qs.map((q) => q.projectId),
    filters,
    viz.designSettings?.dynamic ? viz.designSettings.userConstraints : undefined
  );

  return (
    <>
      {getCorresponding.isLoading && <Loading />}
      {getCorresponding.isSuccess && (
        <Data
          answers={answers}
          Qs={[...Qs, ...getCorresponding.data.QsAndAnswers.questions]}
          comments={getCorresponding.data.QsAndAnswers.answers}
          onClose={onClose}
          viz={viz}
          inEdit={inEdit}
          projects={projects}
          custom_fields={custom_fields}
          togglespreadsheet={togglespreadsheet}
          chartData={chartData}
          setChartData={setChartData}
          spreadsheetmode={spreadsheetmode}
        />
      )}
    </>
  );
}

function Data({
  answers,
  Qs,
  comments,
  onClose,
  viz,
  inEdit,
  projects,
  custom_fields,
  togglespreadsheet,
  chartData,
  setChartData,
  editMode,
  spreadsheetmode,
}) {
  const [dataArray, setDataArray] = useState([]);
  const [headers, setHeaders] = useState();
  const [contact, setContact] = useState(null);

  const projIds = combinedProjIds(viz);

  function isRanking(qId) {
    let q = Qs.find((q) => q.id === qId);
    return q.choiceQuestion?.isRanking;
  }

  function getMatrixAnswer(answer) {
    let matrixAnswer = JSON.parse(answer.matrixAnswer);
    let q = Qs.find((q) => q.id === answer.questionId);
    let string = "";
    for (let option of q.matrixQuestion.options) {
      if (option in matrixAnswer) {
        if (string) {
          string += "\n";
        }
        string += option + ": ";
        if (q.choiceQuestion) {
          let choiceAnswer = matrixAnswer[option];
          const ranking = isRanking(answer.questionId);
          for (let i = 0; i < choiceAnswer.length; i++) {
            if (ranking) {
              string += i + 1 + ": ";
            }
            string += choiceAnswer[i];
            if (i < choiceAnswer.length - 1) {
              if (ranking) {
                string += "  ";
              } else {
                string += ", ";
              }
            }
          }
        } else {
          string += matrixAnswer[option];
        }
      }
    }
    return string;
  }

  function getRankingAnswer(answer) {
    let string = "";
    for (let i = 0; i < answer.choiceAnswer.length; i++) {
      if (string) string += " ";
      string += i + 1 + ": ";
      string += answer.choiceAnswer[i];
    }
    return string;
  }

  function getAnswer(answer) {
    if (answer.choiceAnswer) {
      if (isRanking(answer.questionId)) {
        return getRankingAnswer(answer);
      }
      let string = "";
      for (let i = 0; i < answer.choiceAnswer.length; i++) {
        if (string) string += ", ";
        string += answer.choiceAnswer[i];
      }
      return string;
    }
    if (answer.scaleAnswer || answer.scaleAnswer === 0) {
      return answer.scaleAnswer;
    }
    if (answer.textAnswer) {
      return answer.textAnswer;
    }
    if (answer.matrixAnswer) {
      return getMatrixAnswer(answer);
    }

    return "Did not answer";
  }

  // const style = (value) => <span className={styles.text}>{value}</span>;
  // const style = (value) => {
  //   return value;
  // };

  const initHeaders = [
    {
      name: "First Name",
      accessor: "firstName",
      // cell_style: style,
    },
    {
      name: "Last Name",
      accessor: "lastName",
      // cell_style: style,
    },
    {
      name: "Email",
      accessor: "email",
      // cell_style: style,
    },
  ];

  function getColumns() {
    // const chosenFilters = JSON.parse(filters);
    let columns = [...initHeaders];

    if (projIds.length > 1) {
      columns.push({
        name: "Survey",
        accessor: "survey",
        // cell_style: style,
      });
    }

    if (Qs.length > 1) {
      for (let q of Qs) {
        if (!columns.some((c) => c.accessor === q.questionText)) {
          columns.push({
            name: q.questionText,
            accessor: q.questionText,
            cell_style: (val) => <div style={{ whiteSpace: "pre" }}>{val}</div>,
          });
        }
      }
    } else {
      columns.push({
        name: "Answer",
        accessor: "answer",
        cell_style: (val) => <div style={{ whiteSpace: "pre" }}>{val}</div>,
      });
    }

    for (let q of Qs) {
      if (q.textQuestion && q.textQuestion.bucket) {
        for (let b of q.textQuestion.bucket) {
          if (!columns.some((c) => c.accessor === b.name)) {
            columns.push({
              name: b.name,
              accessor: b.name,
              // cell_style: style,
            }); 
          }
        }
      }
    }
    for (let field of custom_fields.filter((f) => f.filterable !== false)) {
      columns.push({
        text: field.displayName ? field.displayName : field.name,
        name: field.displayName ? field.displayName : field.name,
        accessor: field.name,
        // cell_style: style,
      });
    }

    return columns;
  }

  function getAnswerBucketLabels(result) {
    if (Array.isArray(result)) {
      return result;
    } else {
      return [result.overall];
    }
  }

  function putInBuckets(dataField, answer) {
    let buckets = JSON.parse(answer.buckets);
    for (let b in buckets) {
      let props = getAnswerBucketLabels(buckets[b]);
      let string = "";
      for (let prop of props) {
        if (string) {
          string += ", ";
        }
        string += prop;
      }

      if (dataField[b]) {
        string = dataField[b] + ", " + string;
      }
      dataField[b] = string;
    }
  }

  function getDataField(answer, contact) {
    let dataField = {};

    if (contact) {
      dataField = { ...contact };
      dataField.anon = false;
    } else {
      dataField = {
        firstName: "Anonymous",
        anon: true,
      };
    }

    if (Qs.length > 1) {
      let q = Qs.find((q) => q.id === answer.questionId);
      dataField[q.questionText] = getAnswer(answer);
    } else {
      dataField.answer = getAnswer(answer);
    }

    if (projIds.length > 1) {
      let proj = projects.find((p) => p.id === answer.participation?.projectId);
      dataField.survey = proj?.name;
    }

    if (answer.buckets) {
      putInBuckets(dataField, answer);
    }

    return dataField;
  }

  function getData(columns) {
    let anonymous = [];
    let dataMap = {};

    let commentMap = {};
    if (comments) {
      for (let c of comments) {
        commentMap[c.participation.id] = c;
      }
    }

    for (let answer of answers) {
      let contact = answer.participation?.contact
        ? { ...answer.participation?.contact }
        : null;
      if (contact && contact.customField) {
        let customFields =
          typeof contact.customField === "string"
            ? JSON.parse(contact.customField)
            : contact.customField;
        while (typeof customFields === "string") {
          customFields = JSON.parse(customFields);
        }
        for (let cField in customFields) {
          contact[cField] = customFields[cField];
        }
        delete contact.customFields;
      }

      let dataField = getDataField(answer, contact);

      if (comments) {
        let comment = commentMap[answer.participation.id];
        if (comment) {
          let q = Qs.find((q) => q.id === comment.questionId);
          dataField[q.questionText] = getAnswer(comment);
          if (comment.buckets) {
            putInBuckets(dataField, comment);
          }
        }
      }

      if (dataField.anon) {
        anonymous.push(dataField);
      } else {
        let key = answer.participation.id;
        if (!dataMap[key]) {
          dataMap[key] = dataField;
        } else {
          let orig = dataMap[key];
          dataMap[key] = { ...orig, ...dataField };
        }
      }
    }

    let data = [];
    for (let key in dataMap) {
      data.push(dataMap[key]);
    }

    sortData(data, columns);

    data = [...data, ...anonymous];

    listCustomFields(data, custom_fields);

    return data;
  }

  useEffect(() => {
    if (answers) {
      let columns = getColumns();
      let data = getData(columns);
      setHeaders(columns);
      setDataArray(data);
    }
  }, []);

  function sortData(data, columns) {
    // sort data so contacts are next to each other by last name, then first name, then by surveys
    if (columns.some((c) => c.accessor === "survey")) {
      let copy = [...projects];
      copy.sort((p1, p2) => {
        let a = p1.startedAt ? new Date(p1.startedAt).getTime() : 0;
        let b = p2.startedAt ? new Date(p2.startedAt).getTime() : 0;
        return a - b;
      });

      let projectMap = {};
      for (let i = 0; i < copy.length; i++) {
        projectMap[copy[i].name] = i;
      }

      data.sort((row1, row2) => {
        let a = projectMap[row1.survey];
        let b = projectMap[row2.survey];

        return b - a;
      });
    }
    if (columns.some((c) => c.accessor === "firstName")) {
      sortField(data, "firstName");
    }
    if (columns.some((c) => c.accessor === "lastName")) {
      sortField(data, "lastName");
    }
  }

  function sortField(data, field) {
    data.sort((row1, row2) => {
      let a = row1[field];
      let b = row2[field];
      if (!a) {
        a = "";
      }
      if (!b) {
        b = "";
      }

      if (a.toLowerCase() < b?.toLowerCase()) {
        return -1;
      }
      if (a.toLowerCase() > b?.toLowerCase()) {
        return 1;
      }
      return 0;
    });
  }

  function handleRowClick(obj) {
    var selection = window.getSelection();
    if (!selection.toString()) {
      let person = { ...obj };
      if (!person.anon) {
        delete person.anon;
        setContact(person);
      }
    }
  }

  function handleClickOutside(event) {
    if (event.target === document.getElementById("outside-data-table")) {
      document.removeEventListener("click", handleClickOutside, true);
      if (onClose) {
        onClose();
      }
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <>
      {!editMode && (
        <div className={styles.popoutBackground} id="outside-data-table">
          <div className={styles.popout}>
            {onClose && (
              <i
                className={`${"bi bi-x-lg"} ${styles.exitBtn}`}
                onClick={onClose}
              ></i>
            )}
            {(!headers || !dataArray) && (
              <div style={{ width: "65vw", height: "80%" }}></div>
            )}
            {headers && dataArray && (
              <TableChart
                initHeaders={headers} //Answers only if not
                data={dataArray}
                threeDots
                onRowClick={inEdit ? undefined : handleRowClick}
                titleStyle={getTitleStyle(viz)}
                tableTitle={viz.designSettings.hasTitle ? viz.title : ""}
                downloadCsv
                toggleSpreadsheet={togglespreadsheet}
                chartData={chartData}
                setChartData={setChartData}
                titleContainerStyle={getTitleContainerStyle(viz)}
                color={viz.designSettings.tableColor}
              />
            )}
          </div>
          {contact && (
            <ContactDetails
              onClose={() => setContact(null)}
              contact={contact}
            ></ContactDetails>
          )}
        </div>
      )}
      {editMode && (
        <>
          <div
            className={styles.popout}
            style={{
              width: "100%",
              maxWidth: "100%",
              borderRadius: "0em",
              boxShadow: "none",
              maxHeight: "100%",
              height: "100%",
              backgroundColor: "transparent",
            }}
          >
            {onClose && (
              <i
                className={`${"bi bi-x-lg"} ${styles.exitBtn}`}
                onClick={onClose}
              ></i>
            )}
            {(!headers || !dataArray) && (
              <div style={{ width: "65vw", height: "80%" }}></div>
            )}
            {headers && dataArray && (
              <TableChart
                initHeaders={headers}
                data={dataArray}
                threeDots
                onRowClick={inEdit || chartData ? undefined : handleRowClick}
                titleStyle={getTitleStyle(viz)}
                tableTitle={
                  viz.designSettings.hasTitle
                    ? viz.titleValue
                      ? viz.titleValue
                      : viz.title
                    : undefined
                }
                downloadCsv
                toggleSpreadsheet={togglespreadsheet}
                chartData={chartData}
                setChartData={setChartData}
                spreadsheetmode={spreadsheetmode}
                titleContainerStyle={getTitleContainerStyle(viz)}
                color={viz.designSettings.tableColor}
                showScroll
              />
            )}
          </div>
          {contact && (
            <ContactDetails
              onClose={() => setContact(null)}
              contact={contact}
            ></ContactDetails>
          )}
        </>
      )}
    </>
  );
}
