import React, { useState } from "react";
import styles from "./SurveyDesign.module.scss";
import { ColorPicker } from "components/inputs/input_fields/ColorPicker/ColorPicker";
import { Label } from "components/layouts/Label/Label";
import { NumSlider } from "components/inputs/input_fields/NumSlider/NumSlider";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import { SelectFieldCustom } from "components/inputs";
import { NumberInput } from "components/inputs/input_fields/NumberInput/NumberInput";

const buttonTypes = {
  Fill: {
    label: "Fill",
    value: "fill",
    name: "fill",
    icon: <i className="bi bi-list-check"></i>,
  },
  Link: {
    label: "Link",
    value: "link",
    name: "link",
    icon: <i className="bi bi-chat-left-text"></i>,
  },
  Outline: {
    label: "Outline",
    value: "outline",
    name: "outline",
    icon: <i className="bi bi-chat-left-text"></i>,
  },
};

export function PageStyle({ settings, changeSetting, active, setActive }) {
  function handleSetActive() {
    active ? setActive("") : setActive("PageStyle");
  }

  return (
    <div className={styles.settingContainer}>
      <div
        className={`${
          active ? styles.headerContainerActive : styles.headerContainer
        }`}
        onClick={handleSetActive}
      >
        <div className={styles.header}>Survey Style <i className="bi-brush"></i></div>
        {active ? (
          <i className="bi bi-caret-down-fill"></i>
        ) : (
          <i className="bi bi-caret-left-fill"></i>
        )}
      </div>
      {active && (
        <div className={styles.body}>
        <Label style={{padding: "0em"}}>Page</Label>
          <div className={styles.settingHorizontal}>
            <div className={styles.colorContainer}>
              <ColorPicker
                onChange={(color) => changeSetting("backgroundColor", color)}
                defaultColor={settings.backgroundColor}
                //   colors={settings.colorBank}
              />
            </div>
            <Label style={{fontWeight: "400"}}>Background Color</Label>
          </div>
          <div className={styles.settingHorizontal}>
            <div className={styles.colorContainer}>
              <ColorPicker
                onChange={(color) => changeSetting("baseColor", color)}
                defaultColor={settings.baseColor}
                //   colors={settings.colorBank}
              />
            </div>
            <Label style={{fontWeight: "400"}}>Base Color</Label>
          </div>
          <div className={styles.settingHorizontal}>
            <div className={styles.colorContainer}>
              <ColorPicker
                onChange={(color) => changeSetting("answerColor", color)}
                defaultColor={settings?.answerColor}
                //   colors={settings.colorBank}
              />
            </div>
            <Label style={{fontWeight: "400"}}>Answer Text Color</Label>
          </div>
          <div className={styles.settingHorizontal}>
            <ToggleSwitch
              startChecked={settings.showLogo}
              handleCheck={(val) => changeSetting("showLogo", val)}
            ></ToggleSwitch>
            <Label style={{fontWeight: "400"}}>Show Logo</Label>
          </div>
          {settings.showLogo && (
            <div className={styles.settingHorizontal}>
              <ToggleSwitch
                startChecked={settings.logoOnEveryPage}
                handleCheck={(val) => changeSetting("logoOnEveryPage", val)}
              ></ToggleSwitch>
              <Label style={{fontWeight: "400"}}>Logo On Every Page</Label>
            </div>
          )}
          <div className={styles.settingHorizontal}>
            <ToggleSwitch
              startChecked={settings.showProgBar}
              handleCheck={(val) => changeSetting("showProgBar", val)}
            ></ToggleSwitch>
            <Label style={{fontWeight: "400"}}>Show Progress Bar</Label>
          </div>
          <div className={styles.settingHorizontal}>
            <ToggleSwitch
              startChecked={"boxShadow" in settings ? settings.boxShadow : true}
              handleCheck={(val) => changeSetting("boxShadow", val)}
            ></ToggleSwitch>
            <Label style={{fontWeight: "400"}}>Question Box Shadow</Label>
          </div>
          <div className={styles.settingHorizontal}>
            <NumberInput
              style={{ width: "45px" }}
              startNumber={settings?.questionGap}
              handleNumberChange={(val) => changeSetting("questionGap", val)}
              min={0}
              // step={.1}
            ></NumberInput>
            <Label style={{fontWeight: "400"}}>Question Gap</Label>
          </div>
          <div className={styles.settingHorizontal}>
            <ToggleSwitch
              startChecked={settings?.numbers}
              handleCheck={(val) => changeSetting("numbers", val)}
            ></ToggleSwitch>
            <Label style={{fontWeight: "400"}}>Question Numbers</Label>
          </div>
        </div>
      )}
    </div>
  );
}
