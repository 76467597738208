import styles from "./TextQ.module.scss";
import { useState } from "react";

export default function TextQ({
  question,
  onAnswered,
  answered,
  onRetract,
  color,
  value,
}) {
  function onSave(entry) {
    if (entry === "") {
      if (answered) {
        onRetract(question.id);
      }
    } else {
      onAnswered(question.id, entry);
    }
  }

  const [val, setVal] = useState(value);

  const [border, setBorder] = useState({});

  const handleChange = (event) => {
    let entry = event.target.value;

    // if (!entry && answered) {
    //   onSave(entry);
    // }

    if (!answered && entry.length) {
      onSave(entry);
    }

    setVal(entry);
  };

  const handleFocusOut = (event) => {
    onSave(event.target.value);
    setBorder({});
  };

  const handleFocusIn = () => {
    if (color) {
      setBorder({ borderColor: color });
    }
  };

  return (
    <textarea
      className={`${styles.textarea}`}
      type="text"
      value={val}
      placeholder={question.textQuestion.placeholder}
      onChange={handleChange}
      onBlur={handleFocusOut}
      onFocus={handleFocusIn}
      style={{ ...border, height: "120px" }}
    />
  );
}
