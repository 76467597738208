import { useState } from "react";

//Internal
import styles from "./ConfigureBox.module.scss";
import { SettingsAccordion } from "./SettingsAccordion";
import { MetricType, TableType, ValueType } from "../QuestionChart";
import {
  useFetchProjectsByIdsGql,
  useGetStatChartProjects,
} from "api/resources/projects/projects";
import Button from "components/Button/Button";
import { Loading } from "components/Loading/Loading";
import Visualization, { combinedProjIds, combinedQs } from "../Visualization";

export default function ConfigureBox({
  visualization,
  onSave,
  onClose,
  lockData,
  canSave,
  newChart,
  custom_fields,
  externalFilter,
  inDash,
  height,
  width,
  saving,
}) {
  function initViz() {
    let it = JSON.parse(JSON.stringify(visualization));
    return it;
  }

  const [visualizationCopy, setVisualizationCopy] = useState(initViz());

  const [hide, setHide] = useState(false);

  const getProjects =
    visualizationCopy.dataMethod === "survey"
      ? useGetStatChartProjects(visualizationCopy)
      : useFetchProjectsByIdsGql({
          projectIds: combinedProjIds(visualizationCopy),
        });

  const [labels, setLabels] = useState();

  const score =
    visualization.type === MetricType || visualization.type === ValueType;

  const [dataPage, setDataPage] = useState(
    score ? false : newChart ? true : false
  );

  const [reDraw, setReDraw] = useState(0);

  function setUpDataPage(val) {
    setDataPage(val);
    setReDraw(reDraw + 1);
  }

  function handleSave() {
    onSave(visualizationCopy);
  }

  function hasOneProject() {
    if (visualizationCopy.projectIdsArray.length) {
      return true;
    }
    for (let id in visualizationCopy.designSettings.dynamics) {
      if (
        visualizationCopy.designSettings.dynamics[id].timeFrame !== "selection"
      ) {
        return true;
      } else if (
        visualizationCopy.designSettings.dynamics[id].selection.length
      ) {
        return true;
      }
    }
    return false;
  }

  const showSave = score
    ? visualizationCopy.designSettings.metrics.length > 0
    : visualizationCopy.dataMethod === "survey"
    ? hasOneProject()
    : combinedQs(visualizationCopy).length > 0;

  function onFilterChange(filters) {
    let newViz = { ...visualizationCopy };
    newViz.filters = JSON.stringify(filters);
    let count = 0;
    for (let prop in filters) {
      if (prop === "surveys") {
        count += filters[prop].length;
      } else if (prop === "answers") {
        count += filters[prop].length;
      } else if (prop !== "orAnswers") {
        count += filters[prop].properties.length;
      }
    }
    newViz.designSettings.showFilterSubtitle = count < 4;
    setVisualizationCopy(newViz);
  }

  const [chartData, setChartData] = useState();

  const [dataCounter, setDataCounter] = useState(0);

  function setUpChartData(val) {
    if (val) {
      setDataCounter(dataCounter + 1);
    } else {
      setChartData(null);
      setDataCounter(0);
    }
  }

  return (
    <div className={styles.pageContainer}>
      <div className={styles.configPage}>
        <div className={styles.questionChartContainer}>
          <div
            className={`${styles.questionChart} ${
              dataPage ? styles.zoomOut : ""
            }`}
            style={{
              height: height ? height : "",
              maxWidth: width ? width : "",
            }}
          >
            <Visualization
              viz={visualizationCopy}
              inEdit
              setLabelInfo={setLabels}
              newChart={newChart}
              setVizCopy={setVisualizationCopy}
              custom_fields={custom_fields}
              idAddOn={"inChartSettings"}
              containerId={"EDIT:" + visualization.id}
              externalFilter={externalFilter}
              inDash={inDash}
              height={height}
              width={width}
              setData={setChartData}
              setUpDataCounter={dataCounter}
              redrawCounter={reDraw}
            />
          </div>
          <div className={styles.saveBtnDiv}>
            <div className={styles.buttonContainer}>
              <Button onClick={onClose} red height={35}>
                Cancel
              </Button>
              {canSave && showSave && (
                <Button
                  onClick={handleSave}
                  blue
                  height={35}
                  style={{ whiteSpace: "nowrap" }}
                >
                  {!saving && <>Save Changes</>}
                  {saving && (
                    <div style={{ display: "flex", gap: ".5em" }}>
                      <Loading height={"20px"} width={"20px"}></Loading>{" "}
                      Saving...
                    </div>
                  )}
                </Button>
              )}
            </div>
          </div>
        </div>
        {/* {dataPage && !hide && <div className={styles.configBox}></div>} */}
        {!hide && (
          <div
            className={`${styles.configBox} ${dataPage ? styles.dataPage : ""}`}
          >
            <div className={styles.formContainer}>
              <SettingsAccordion
                viz={visualizationCopy}
                updateViz={setVisualizationCopy}
                labels={labels}
                lockData={lockData}
                projects={getProjects.isSuccess ? getProjects.data?.survey : []}
                custom_fields={custom_fields}
                onFilterChange={onFilterChange}
                externalFilter={externalFilter}
                newChart={newChart}
                dataPage={dataPage}
                setDataPage={setUpDataPage}
                chartData={chartData}
                setChartData={setUpChartData}
                hasData={showSave}
              />
              <div className={styles.hide} onClick={() => setHide(true)}>
                Hide Settings
              </div>
            </div>
          </div>
        )}
        {hide && (
          <div onClick={() => setHide(false)} className={styles.show}>
            <i className="bi bi-pencil"></i>
          </div>
        )}
      </div>
    </div>
  );
}
