import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import styles from "../WidgetSettings.module.scss";
import { Label } from "components/layouts/Label/Label";
import { NumberInput } from "components/inputs/input_fields/NumberInput/NumberInput";
import { DndContext, DragOverlay, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  restrictToVerticalAxis,
  restrictToParentElement,
} from "@dnd-kit/modifiers";
import { TextFieldSimple } from "components/inputs";

export function ReportDisplay({
  visible,
  setVisible,
  settings,
  changeSettings,
  onNameChange,
  name,
}) {
  return (
    <>
      <div
        className={`${styles.header} ${visible ? styles.headervisible : ""}`}
        onClick={setVisible}
      >
        {"Display"}
        <span className={styles.accordionicon}>
          <i className="bi bi-caret-left-fill"></i>
        </span>
      </div>
      {visible && (
        <div className={styles.body}>
          <div className={styles.col} style={{ gap: "1.5em" }}>
            <div
              className={styles.row}
              style={{ justifyContent: "space-between", gap: "2em" }}
            >
              <div
                className={styles.row}
                style={{ gap: "1em", paddingLeft: "15px" }}
              >
                <Checkbox
                  checked={settings?.showRecent}
                  onChange={(e) =>
                    changeSettings("showRecent", e.target.checked)
                  }
                />
                <Label
                  style={{
                    width: "fit-content",
                    color: "#8dabb2",
                    paddingLeft: "0",
                    fontWeight: "600",
                  }}
                >
                  Recently Viewed
                </Label>
              </div>
              {settings?.showRecent && (
                <div className={styles.row} style={{ gap: "7px" }}>
                  <NumberInput
                    startNumber={settings?.recentLimit}
                    handleNumberChange={(val) =>
                      changeSettings("recentLimit", val)
                    }
                    min={1}
                    max={10}
                    borderColor={"#15bcc7"}
                  />
                  <Label
                    style={{
                      width: "fit-content",
                      color: "#8dabb2",
                      paddingLeft: "0",
                      fontWeight: "500",
                      fontSize: ".8em",
                    }}
                  >
                    Limit
                  </Label>
                </div>
              )}
            </div>
            {/* {settings?.showRecent && <Arrangement settings={settings} changeSettings={changeSettings} />} */}

            <div className={styles.col}>
              <Label style={{ color: "#8dabb2" }}>Title</Label>
              <TextFieldSimple
                value={name}
                onChange={onNameChange}
                // style={{ borderColor: "#15bcc7" }}
              />
            </div>

            <div className={styles.col} style={{ gap: "12px" }}>
              <Label style={{ color: "#8dabb2", fontWeight: "600" }}>
                Links
              </Label>
              <div
                className={styles.row}
                style={{ gap: "1em", paddingLeft: "30px" }}
              >
                <Checkbox
                  checked={settings?.showGoToReports}
                  onChange={(e) =>
                    changeSettings("showGoToReports", e.target.checked)
                  }
                />
                <Label
                  style={{
                    width: "fit-content",
                    color: "#8dabb2",
                    paddingLeft: "0",
                    fontWeight: "500",
                    fontSize: ".8em",
                  }}
                >
                  Go To Reports
                </Label>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function Arrangement({ settings, changeSettings }) {
  return (
    <div className={styles.col} style={{ gap: "15px", alignItems: "center" }}>
      <DndContext
        // sensors={sensors}
        collisionDetection={closestCenter}
        // onDragOver={handleDragOver}
        modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        // onDragOver={handleDragOver}
        // onDragStart={({active}) => setActive(active.id)}
        // onDragEnd={() => setActive(null)}
      >
        <SortableContext
          id={"Chart Widgets"}
          items={settings?.reports.map((r) => r.id)}
          strategy={verticalListSortingStrategy}
        >
          {/* {settings.reports.map((r) => (
            <ReportPin report={r} remove={() => handleRemove(r)} />
          ))} */}
        </SortableContext>
        {/* <DragOverlay>
                  {active ? (
                    <ReportPin
                      report={settings.reports.find((r) => r.id === active)}
                    />
                  ) : null}
                </DragOverlay> */}
      </DndContext>
    </div>
  );
}
