// External
import React, { useEffect, useState } from "react";

// Internal
import { useSearchContactsFiltersCount } from "api/resources/contacts/contacts";
import styles from "./AllContacts.module.scss";
import ReactModal from "components/ReactModal/ReactModal.jsx";
import { TabPill } from "components/layouts";
import { Manual } from "./NewContacts/manual/Manual";
import { Upload } from "./NewContacts/upload/Upload";
import ContactsSettings from "./ContactsSettings";
import ExternalImport from "../ExternalImport/ExternalImport";
import ContactTable from "./ContactTable";
import { randomColor } from "components/tables/EditableTable/utils";

export const AllContacts = ({ user, organization, roles, customFields }) => {
  const seeContact = (contact) => {
    // debugger; // eslint-disable-line no-debugger
    setShowContactDetails(true);
    setCurrContactId(contact.id);
  };
  const [headers, setHeaders] = useState();

  function initHeaders() {
    let heads = [
      {
        id: "contactName",
        value: "contactName",
        name: "Contact Name",
        accessor: "firstName",
        label: "Contact Name",
        // width: 100,
        dataType: "text",
        enabled: true,
        sort: false,
        canSort: true,
        fullName: true,
        custom: false,
        cell_style: (name, id, i, contact) =>
          contact && (
            <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
              {" "}
              <div
                className={styles.profileImage}
                style={{
                  backgroundColor: contact?.color
                    ? contact?.color
                    : randomColor(),
                }}
              >
                {" "}
                <span>
                  {contact && contact?.firstName[0] + contact?.lastName[0]}
                </span>
              </div>
              {contact && `${contact?.firstName} ${contact?.lastName}`}
            </div>
          ),
      },
      {
        id: "firstName",
        value: "firstName",
        name: "First Name",
        accessor: "firstName",
        label: "First Name",
        // width: 100,
        dataType: "text",
        enabled: false,
        sort: false,
        canSort: true,
        editable: true,
        custom: false,
      },
      {
        id: "lastName",
        value: "lastName",
        name: "Last Name",
        accessor: "lastName",
        label: "Last Name",
        // width: 100,
        dataType: "text",
        enabled: false,
        sort: false,
        canSort: true,
        editable: true,
        custom: false,
      },
      {
        id: "email",
        value: "email",
        name: "Email",
        accessor: "email",
        label: "Email",
        // width: 250,
        dataType: "text",
        enabled: true,
        sort: true,
        canSort: true,
        cell_style: null,
        editable: true,
        custom: false,
      },
      {
        id: "updatedAt",
        value: "updatedAt",
        name: "Last Modified",
        accessor: "updatedAt",
        label: "Last Modified",
        // width: 100,
        dataType: "text",
        enabled: true,
        sort: false,
        canSort: true,
        cell_style: null,
        custom: false,
      },
      {
        id: "unsubscribed",
        value: "unsubscribed",
        name: "Unsubscribed",
        accessor: "unsubscribed",
        label: "Unsubscribed",
        dataType: "text",
        enabled: true,
        sort: true,
        canSort: true,
        custom: false,
        properties: ["True", "False"],
        cell_style: (item) => <div>{item ? "True" : "False"}</div>,
      },
      {
        id: "participationCount",
        value: "participationCount",
        name: "Survey Invites",
        label: "Survey Invites",
        // width: 80,
        dataType: "text",
        accessor: "participationCount",
        enabled: true,
        sort: true,
        canSort: true,
        cell_style: null,
        custom: false,
      },
      {
        id: "createdAt",
        value: "createdAt",
        name: "When Added",
        accessor: "createdAt",
        label: "When Added",
        dataType: "text",
        enabled: false,
        sort: false,
        canSort: true,
        cell_style: null,
        custom: false,
      },
    ];

    for (let field of customFields) {
      if (!field?.generic) {
        heads.push({
          id: field.id,
          value: field.id,
          name: field?.displayName,
          accessor: field?.name,
          label: field?.displayName,
          // width: 100,
          dataType: "text",
          enabled: true,
          editable: true,
          custom: true,
          properties:
            field?.properties && typeof field?.properties === "string"
              ? JSON.parse(field?.properties)
              : field?.properties,
          // cell_style: (val) => <div>{checkFields(val, field)}</div>,
        });
      }
    }
    return heads;
  }

  function initEditHeaders() {
    let heads = [
      {
        id: "contactName",
        value: "contactName",
        name: "Contact Name",
        accessor: "firstName",
        label: "Contact Name",
        // width: 100,
        dataType: "text",
        enabled: false,
        sort: false,
        canSort: true,
        fullName: true,
        custom: false,
        cell_style: (name, id, i, contact) =>
          contact && (
            <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
              {" "}
              <div
                className={styles.profileImage}
                style={{
                  backgroundColor: contact?.color
                    ? contact?.color
                    : randomColor(),
                }}
              >
                {" "}
                <span>
                  {contact && contact?.firstName[0] + contact?.lastName[0]}
                </span>
              </div>
              {contact && `${contact?.firstName} ${contact?.lastName}`}
            </div>
          ),
      },
      {
        id: "firstName",
        value: "firstName",
        name: "First Name",
        accessor: "firstName",
        label: "First Name",
        // width: 100,
        dataType: "text",
        enabled: true,
        sort: false,
        canSort: true,
        editable: true,
        custom: false,
      },
      {
        id: "lastName",
        value: "lastName",
        name: "Last Name",
        accessor: "lastName",
        label: "Last Name",
        // width: 100,
        dataType: "text",
        enabled: true,
        sort: false,
        canSort: true,
        editable: true,
        custom: false,
      },
      {
        id: "email",
        value: "email",
        name: "Email",
        accessor: "email",
        label: "Email",
        // width: 250,
        dataType: "text",
        enabled: true,
        sort: true,
        canSort: true,
        cell_style: null,
        custom: false,
        editable: true,
      },
      {
        id: "updatedAt",
        value: "updatedAt",
        name: "Last Modified",
        accessor: "updatedAt",
        label: "Last Modified",
        // width: 100,
        dataType: "text",
        enabled: true,
        sort: false,
        canSort: true,
        custom: false,
        cell_style: null,
      },
      {
        id: "unsubscribed",
        value: "unsubscribed",
        name: "Unsubscribed",
        accessor: "unsubscribed",
        label: "Unsubscribed",
        dataType: "boolean",
        enabled: true,
        sort: true,
        canSort: true,
        editable: true,
        custom: false,
        properties: [
          { value: true, label: "True" },
          { value: false, label: "False" },
        ],
        // cell_style: (item) => <div>{item ? "True" : "False"}</div>,
      },
      {
        id: "participationCount",
        value: "participationCount",
        name: "Survey Invites",
        label: "Survey Invites",
        // width: 80,
        dataType: "text",
        accessor: "participationCount",
        enabled: true,
        sort: true,
        canSort: true,
        cell_style: null,
        custom: false,
      },
      {
        id: "createdAt",
        value: "createdAt",
        name: "When Added",
        accessor: "createdAt",
        label: "When Added",
        dataType: "text",
        enabled: false,
        sort: false,
        canSort: true,
        cell_style: null,
        custom: false,
      },
    ];

    for (let field of customFields) {
      if (field?.generic === false) {
        heads.push({
          id: field.id,
          value: field.id,
          name: field?.displayName,
          accessor: field?.name,
          label: field?.displayName,
          // width: 100,
          dataType: field?.delimiter ? "selectMultiple" : "select",
          options: field?.properties,
          enabled: true,
          editable: true,
          custom: true,
          properties:
            field?.properties && typeof field?.properties === "string"
              ? JSON.parse(field?.properties)
              : field?.properties,
          cell_style: (val) =>
            val && (field?.delimiter ? checkDelimiter(val, field) : val),
        });
      }
    }
    return heads;
  }

  function checkDelimiter(them, field) {
    let chosen = [];
    if (them) {
      if (field?.delimiter) {
        them = them.split(field?.delimiter ? field.delimiter : undefined);
        chosen = them.map((prop) => {
          return {
            value: prop,
            label: prop,
          };
        });
      }
    }
    return chosen;
  }

  // id: 999999,
  // width: 20,
  // label: "+",
  // disableResizing: true,
  // dataType: "null"

  // function checkFields(contact, field) {
  //   if (contact && "customField" in contact && contact?.customField) {
  //     let fields = JSON.parse(contact.customField);
  //     while (typeof fields === "string") {
  //       fields = JSON.parse(fields);
  //     }

  //     return fields[field.name];
  //   } else {
  //     return "";
  //   }
  // }

  const tabBarItems = [
    {
      id: 0,
      name: "Upload From File",
      // to: `questions`,
    },
    {
      id: 1,
      name: "Manual Entry",
      // to: `design`,
    },
    {
      id: 2,
      name: "External Import",
      // to: `design`,
    },
  ];
  const [sort, setSort] = useState({
    item: "email",
    descend: false,
  });

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSkip, setPageSkip] = useState(0);

  const [perPage, setPerpage] = useState(20);
  const [active, setActive] = useState(0);
  const [chosenFilters, setChosenFilters] = useState([]);

  const [show, setShow] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [editMode, setEditMode] = useState(false);

  const getContactCount = useSearchContactsFiltersCount(
    searchString,
    JSON.stringify(chosenFilters),
    "",
    "[]"
  );

  useEffect(() => {
    getContactCount.refetch();
  }, [chosenFilters]);

  useEffect(() => {
    if (editMode) {
      setHeaders(initEditHeaders());
    } else {
      setHeaders(initHeaders());
    }
  }, [editMode]);

  const handleActiveUpdate = (item) => {
    setActive(item);
  };

  return (
    <>
      <ContactTable
        headers={headers}
        setHeaders={setHeaders}
        maxDataQuery={getContactCount}
        maxDataQueryAccessor={"count"}
        currPage={pageNumber}
        setCurrPage={setPageNumber}
        maxItems={perPage}
        onRowClick={seeContact}
        sort={sort}
        setSort={setSort}
        pageSkip={pageSkip}
        setPageSkip={setPageSkip}
        perPage={perPage}
        setPerPage={setPerpage}
        searchString={searchString}
        setSearchString={setSearchString}
        searchPlaceholder={"Search Contacts..."}
        onClickActionButton={() => setShow(true)}
        hideActionButton={true}
        chosenFilters={chosenFilters}
        setChosenFilters={setChosenFilters}
        orgId={organization?.id}
        organization={organization}
        editFields={editMode}
        setEditFields={setEditMode}
        customFields={customFields}
        user={user}
        roles={roles}
      ></ContactTable>

      <ReactModal
        show={show}
        onClose={() => setShow(false)}
        modalStyle={{
          borderRadius: "1em",
          height: "100%",
          width: "100%",
          maxWidth: "1200px",
        }}
        dark
      >
        <div className={styles.createContactsContainer}>
          <div className={styles.top}>
            <div style={{ maxWidth: "390px", paddingBottom: "10px" }}>
              <div className={styles.header}>Create/Update Contacts</div>
              <div className={styles.desc}>
                Upload a .csv file of your audience. If contact email already
                exists in your organization, then only the fields for that
                contact that you uploaded will be updated.{" "}
                <a>First three headers must be: firstName, lastName, email</a>
              </div>
            </div>
            <div className={styles.pill}>
              <TabPill
                tabBarItems={tabBarItems}
                active={active}
                updateActive={handleActiveUpdate}
                wings
              ></TabPill>
            </div>
          </div>
          {active === 0 && <Upload user={user} />}
          {active === 1 && (
            <div className={styles.manualContainer}>
              <Manual></Manual>
            </div>
          )}
          {active === 2 && (
            <ExternalImport user={user} organization={organization} />
          )}
        </div>
      </ReactModal>
      <ContactsSettings
        show={showSettings}
        setShow={setShowSettings}
      ></ContactsSettings>
    </>
  );
};
