import { useState, useEffect } from "react";
import styles from "./AnswerTable.module.scss";
import { TableType } from "../QuestionChart";
import ContactDetails from "components/Popout/ContactProfile";
import TableChart from "components/Charts/Table/TableChart";
import { capitalize } from "assets/functions/StringFunctions";
import {
  getEmailCodes,
  getStatCodes,
  passesOnlyLinks,
  statBars,
} from "../StatChart";
import {
  getTitleContainerStyle,
  getTitleStyle,
  listCustomFields,
} from "./Table";
import { getReversed } from "assets/functions/ArrayFunctions";
import { trimTimeDay } from "assets/functions/DateFunctions";

export default function StatDrillTable({
  partStats,
  distributions,
  onClose,
  viz,
  inEdit,
  drill,
  projects,
  surveyTags,
  custom_fields,
  toggleSpreadsheet,
  chartData,
}) {
  const [dataArray, setDataArray] = useState([]);
  const [headers, setHeaders] = useState();
  const [contact, setContact] = useState(null);

  function undefinedLabel() {
    return viz.designSettings.undefinedLabel
      ? viz.designSettings.undefinedLabel
      : "Undefined";
  }

  const style = (value) => <span className={styles.text}>{value}</span>;

  const initHeaders = [
    {
      name: "First Name",
      accessor: "firstName",
      cell_style: style,
    },
    {
      name: "Last Name",
      accessor: "lastName",
      cell_style: style,
    },
    {
      name: "Email",
      accessor: "email",
      cell_style: style,
    },
  ];

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "December",
  ];

  function getMonthTaken(answer) {
    if (!answer.updatedAt) {
      return undefinedLabel();
    }
    let date = new Date(answer.updatedAt);

    let month = months[date.getMonth()];
    let year = date.getFullYear();
    return month + " " + year;
  }

  function getHourTaken(answer) {
    if (!answer.createdAt) {
      return "Undefined";
    }
    let date = new Date(answer.createdAt);
    date.setMinutes(0, 0, 0);
    return trimTimeDay(date, true);
  }


  function getSurveyLabel(partStat, val) {
    let proj = getProjFromParticipation(partStat.participation);
    if (val === "survey") {
      return proj.name;
    }
    if (val === "survey tag") {
      let tag = surveyTags.find((t) => t.project.find((p) => p.id === proj.id));
      if (tag) {
        return tag.label;
      }
      return undefinedLabel();
    }

    if (val === "survey date" || val === "survey quarter") {
      let segLabel = proj.name;
      if (proj.startedAt) {
        let date = new Date(proj.startedAt);
        let month = date.toDateString().substring(4, 7);
        let year = date.toDateString().substring(11, 15);
        segLabel = month + " " + year;
        if (drill.split === "survey quarter") {
          if (month === "Jan" || month === "Feb" || month === "Mar") {
            segLabel = "Q1 " + year;
          } else if (month === "Apr" || month === "May" || month === "Jun") {
            segLabel = "Q2 " + year;
          } else if (month === "Jul" || month === "Aug" || month === "Sep") {
            segLabel = "Q3 " + year;
          } else if (month === "Oct" || month === "Nov" || month === "Dec") {
            segLabel = "Q4 " + year;
          }
        }
      }
      return segLabel;
    }
  }

  function passesSplitDrill(contact, partStat) {
    if (drill.split.includes("survey")) {
      let label = getSurveyLabel(partStat, drill.split);
      return label === drill.dataset;
    }
    if (drill.split === "month taken") {
      let label = getMonthTaken(partStat);
      return label === drill.dataset;
    }
    if (drill.split === "hour taken") {
      let label = getMonthTaken(partStat);
      return label === drill.dataset;
    }
    if (drill.split === "email") {
      for (let email of partStat.participation.delivery) {
        let d = distributions.find((d) => d.id === email.distributionId);
        if (d && drill.distIds.includes(d.id)) {
          return true;
        }
      }

      return false;
    }

    // else splitting by a contact field
    if (!contact) {
      if (drill.dataset === undefinedLabel()) {
        return true;
      }
      return false;
    }

    if (!contact[drill.split] && drill.dataset === undefinedLabel()) {
      return true;
    }

    let val = contact[drill.split];
    if (val) {
      let customField = custom_fields.find((c) => c.name === drill.split);
      let list = val.split(
        customField?.delimiter ? customField.delimiter : undefined
      );
      for (let field of list) {
        if (field === drill.dataset) {
          return true;
        }
      }
    }

    return false;
  }

  function getProjFromParticipation(participation) {
    let project = projects.find((p) => p.id === participation?.projectId);
    return project;
  }

  function passesPivotDrill(contact, partStat) {
    if (drill.pivot.includes("survey")) {
      let label = getSurveyLabel(partStat, drill.pivot);
      return label === drill.segment;
    }
    if (drill.pivot === "month taken") {
      let label = getMonthTaken(partStat);
      return label === drill.segement;
    }
    if (drill.pivot === "hour taken") {
      let label = getHourTaken(partStat);
      return label === drill.segement;
    }
    if (drill.pivot === "email") {
      for (let email of partStat.participation.delivery) {
        let d = distributions.find((d) => d.id === email.distributionId);
        if (d && drill.distIds.includes(d.id)) {
          return true;
        }
      }

      return false;
    }

    // else pivoting by a contact Field
    if (!contact) {
      if (drill.segment === undefinedLabel()) {
        return true;
      }
      return false;
    }

    if (!contact[drill.pivot] && drill.segment === undefinedLabel()) {
      return true;
    }

    let val = contact[drill.pivot];
    if (val) {
      let customField = custom_fields.find((c) => c.name === drill.pivot);
      let list = val.split(
        customField?.delimiter ? customField.delimiter : undefined
      );
      for (let field of list) {
        if (field === drill.segment) {
          return true;
        }
      }
    }

    return false;
  }

  function passesStatCode(partStat) {
    let bars = viz.designSettings.showBars;
    let statCode = "";

    if (drill.pivot) {
      if (drill.split) {
        statCode = viz.designSettings.answerType;
      } else {
        statCode = bars.find((b) => b.label === drill.dataset).value;
      }
    } else {
      statCode = bars.find((b) => b.label === drill.segment).value;
    }

    if (drill.pivot === "email" || drill.split === "email") {
      for (let email of partStat.participation.delivery) {
        if (drill.distIds.includes(email.distributionId)) {
          let statCodes = getEmailCodes(partStat, email, distributions);
          if (statCodes.includes(statCode)) {
            return true;
          }
        }
      }
      return false;
    } else {
      if (viz.designSettings.onlyLinks) {
        if (!passesOnlyLinks(partStat, distributions)) {
          return false;
        }
      }

      let statCodes = getStatCodes(partStat);
      return statCodes.includes(statCode);
    }
  }

  function passesDrill(contact, partStat) {
    if (passesStatCode(partStat)) {
      let passesPivot = drill.pivot
        ? passesPivotDrill(contact, partStat)
        : true;
      let passesSplit = drill.split
        ? passesSplitDrill(contact, partStat)
        : true;
      return passesSplit && passesPivot;
    }
    return false;
  }

  function getColumns() {
    let columns = [...initHeaders];

    if (
      projects.length > 1 &&
      !(drill.pivot === "survey" || drill.split === "survey")
    ) {
      columns.push({
        name: "Survey",
        accessor: "survey",
        cell_style: style,
      });
    }

    columns.push({
      name: "Status",
      accessor: "status",
      cell_style: style,
    });

    if (drill.distIds) {
      for (let id of drill.distIds) {
        let dist = distributions.find((d) => d.id === id);
        if (dist) {
          columns.push({
            name: dist.name,
            accessor: dist.id,
            cell_style: style,
          });
        }
      }
    } else {
      for (let dist of distributions) {
        let survey = projects.find((p) => p.id === dist.projectId);
        columns.push({
          name: dist.name + " from " + survey.name,
          accessor: dist.id,
          cell_style: style,
        });
      }
    }

    for (let field of custom_fields.filter((f) => f.filterable !== false)) {
      columns.push({
        name: field.displayName ? field.displayName : field.name,
        accessor: field.name,
        cell_style: style,
      });
    }

    return columns;
  }

  const reversedBars = getReversed(statBars.slice(1));

  function getDataField(partStat, contact) {
    let dataField = {};

    if (contact) {
      dataField = { ...contact };
      dataField.anon = false;
    } else {
      dataField = {
        firstName: "Anonymous",
        anon: true,
      };
    }

    let proj = projects.find((p) => p.id === partStat.participation?.projectId);
    if (proj) {
      dataField.survey = proj?.name;
    }

    let statCodes = getStatCodes(partStat);
    for (let bar of reversedBars) {
      if (statCodes.includes(bar.value)) {
        let includedBar = viz.designSettings.showBars.find(
          (b) => b.value === bar.value
        );
        dataField.status = includedBar ? includedBar.label : bar.label;
        break;
      }
    }

    for (let email of partStat.participation.delivery) {
      let emailCodes = getEmailCodes(partStat, email, distributions);
      for (let bar of reversedBars) {
        if (emailCodes.includes(bar.value)) {
          let includedBar = viz.designSettings.showBars.find(
            (b) => b.value === bar.value
          );
          let string = includedBar ? includedBar.label : bar.label;
          if (bar.value === "blocked" || bar.value === "bounced") {
            string += ": " + email.bounce_classification;
          }
          dataField[email.distributionId] = string;
          break;
        }
      }
    }

    return dataField;
  }

  function getData() {
    let dataMap = {};
    let anonymous = [];

    for (let partStat of partStats) {
      let contact = partStat.participation?.contact
        ? { ...partStat.participation?.contact }
        : null;
      if (contact && contact.customField) {
        let customFields = JSON.parse(contact.customField);
        while (typeof customFields === "string") {
          customFields = JSON.parse(customFields);
        }
        for (let cField in customFields) {
          contact[cField] = customFields[cField];
        }
        delete contact.customFields;
      }

      if (passesDrill(contact, partStat)) {
        let dataField = getDataField(partStat, contact);
        dataMap[partStat.participation.id] = dataField;
      }
    }

    let data = [];
    for (let responseId in dataMap) {
      data.push(dataMap[responseId]);
    }
    data = [...data, ...anonymous];

    listCustomFields(data, custom_fields);

    return data;
  }

  useEffect(() => {
    if (partStats) {
      let columns = getColumns();
      let data = getData();

      let keeping = [];
      for (let col of columns) {
        if (col.accessor === drill.pivot || col.accessor === drill.split) {
          keeping.push(col);
          continue;
        }
        for (let row of data) {
          if (col.accessor in row && row[col.accessor]) {
            keeping.push(col);
            break;
          }
        }
      }
      columns = keeping;

      setHeaders(columns);
      setDataArray(data);
    }
  }, [viz]);

  function handleRowClick(obj) {
    var selection = window.getSelection();
    if (!selection.toString()) {
      let person = { ...obj };
      if (!person.anon) {
        delete person.anon;
        setContact(person);
      }
    }
  }

  function handleClickOutside(event) {
    if (event.target === document.getElementById("outsideDrill")) {
      document.removeEventListener("click", handleClickOutside, true);
      if (onClose) {
        onClose();
      }
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  function getDrillTitle() {
    let string = "";
    if (drill.pivot) {
      if (drill.split) {
        let bar = viz.designSettings.showBars.find(
          (b) => b.value === viz.designSettings.answerType
        );
        if (!bar) {
          bar = statBars.find((b) => b.value === viz.designSettings.answerType);
        }
        string = bar?.label;
      } else {
        string = drill.dataset;
      }
    } else {
      string = drill.segment;
    }

    if (drill.pivot) {
      string += " with " + drill.segment + " as " + capitalize(drill.pivot);
      if (drill.split) {
        string += " and " + capitalize(drill.split) + " as " + drill.dataset;
      }
    } else if (drill.split) {
      string += " with " + drill.dataset + " as " + capitalize(drill.split);
    }

    return string;
  }

  return (
    <div className={styles.popoutBackground} id="outsideDrill">
      <div className={styles.popout}>
        {onClose && (
          <i
            className={`${"bi bi-x-lg"} ${styles.exitBtn}`}
            onClick={onClose}
          ></i>
        )}
        {(!headers || !dataArray) && (
          <div style={{ width: "65vw", height: "80%" }}></div>
        )}
        {headers && dataArray && (
          <TableChart
            initHeaders={headers}
            data={dataArray}
            threeDots
            onRowClick={inEdit ? undefined : handleRowClick}
            titleStyle={getTitleStyle(viz)}
            tableTitle={viz.designSettings.hasTitle ? viz.title : ""}
            subtitle={getDrillTitle()}
            downloadCsv
            toggleSpreadsheet={toggleSpreadsheet}
            titleContainerStyle={getTitleContainerStyle(viz)}
            color={viz.designSettings.tableColor}
            showScroll
          />
        )}
      </div>
      {contact && (
        <ContactDetails
          onClose={() => setContact(null)}
          contact={contact}
        ></ContactDetails>
      )}
    </div>
  );
}
