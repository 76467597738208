import ReportTile from "./ReportTile";
import SurveyTile from "./SurveyTile";

export default function TileWidget({ widget, inSettings }) {
  return (
    <>
      {widget.settings?.survey && widget.settings?.survey?.id && (
        <SurveyTile widget={widget} inSettings={inSettings} />
      )}
      {widget.settings?.report && widget.settings?.report?.id && (
        <ReportTile widget={widget} inSettings={inSettings} />
      )}
    </>
  );
}
