import React, { useState } from "react";
import styles from "../SettingsAccordion.module.scss";
import {
  DoughnutType,
  PieType,
  LineType,
  VertBarType,
  HorizBarType,
  TableType,
  ParticipationCount,
  ValueType,
  MetricType,
} from "../../QuestionChart";
import VerticalBarImage from "assets/images/VerticalBarChart.png";
import HorizontalBarImage from "assets/images/HorizontalBarChart.png";
import LineChartImage from "assets/images/LineChart.png";
import PieChartImage from "assets/images/PieChart.png";
import SingleValueImage from "assets/images/SingleValue.png";
import TableImage from "assets/images/Table.png";
import HznlBarSplit from "assets/images/HorizontalBarSplit.png";
import HznlStackedbar from "assets/images/HorizontalStackedBar.png";
import vtlBarSplit from "assets/images/VerticalBarSplit.png";
import vtlStackedbar from "assets/images/VerticalStacked.png";
import DonutChartImage from "assets/images/DoughnutChart.png";
import MuliLineChart from "assets/images/MultiLineChart.png";
import { useFetchSurveyTags } from "api/resources/organization/surveytags";
import { combinedQs } from "../../Visualization";

export const ChartType = ({ viz, title, updateViz, visible, setVisible }) => {
  const [showQClarifier, setShowQClarifier] = useState(false); // Can likely get rid of the QClarifier
  const [intendedType, setIntendedType] = useState("");
  const getSurveyTags = useFetchSurveyTags({
    tagIds: viz.tagIdsArray,
  });

  const chartImageStyle = {
    height: "30px",
    width: "30px",
  };

  function changeType(val, stacked) {
    // if (viz.type === val) {
    //   return;
    // }

    // if (
    //   viz.type === TableType &&
    //   val !== TableType &&
    //   viz.question.length > 0
    // ) {
    //   let t = viz.question[0].type;
    //   for (let i = 1; i < viz.question.length; i++) {
    //     if (viz.question[i].type !== t) {
    //       setIntendedType({
    //         type: val,
    //         stacked: stacked,
    //       });
    //       setShowQClarifier(true);
    //       return;
    //     }
    //   }
    // }

    let newViz = { ...viz };
    newViz.type = val;
    newViz.designSettings.stacked = stacked ? true : false;

    // if (viz.type === MetricType && val !== MetricType) {
    //   let textBoxes = newViz.designSettings.textboxes;
    //   newViz.designSettings = JSON.parse(JSON.stringify(defaultSettings));
    //   newViz.designSettings.textboxes = textBoxes;
    //   if (val === DoughnutType || val === PieType) {
    //     newViz.designSettings.hasLegend = true;
    //     newViz.designSettings.legendPosition = "right";
    //   }
    //   updateViz(newViz);
    //   return;
    // }
    // if (val === MetricType) {
    //   let textBoxes = newViz.designSettings.textboxes;
    //   newViz.designSettings = JSON.parse(JSON.stringify(defaultMetricSettings));
    //   newViz.designSettings.textboxes = textBoxes;
    //   updateViz(newViz);
    //   return;
    // }

    // if (val === TableType) {
    //   // Switching to a Table
    //   if (newViz.pivotString) {
    //     let creator = new PivotTableFieldsCreator(
    //       newViz,
    //       projects,
    //       getSurveyTags
    //     );

    //     if (newViz.designSettings.split === "choices") {
    //       newViz.designSettings.split = "";
    //     }

    //     if (newViz.designSettings.split) {
    //       newViz = creator.splitFields(
    //         newViz.designSettings.split,
    //         custom_fields
    //       );
    //     } else {
    //       newViz = creator.combineFields();
    //     }
    //   } else {
    //     // Just get rid of the split if not pivotting as well, since that's what the table builds from
    //     newViz.designSettings.split = "";
    //   }
    //   newViz.segLabelReplacements = {};
    // }

    // if (viz.type === TableType) {
    //   // Switching from a Table
    //   if (newViz.designSettings.split === "question") {
    //     newViz.designSettings.split = "";
    //   }
    //   if (newViz.designSettings.split) {
    //     newViz.designSettings.hasLegend = true;
    //   } else {
    //     newViz.designSettings.hasLegend = false;
    //   }
    //   if (newViz.designSettings.autoGenAxisTitles) {
    //     generateAxisTitles(newViz);
    //   }
    // }

    if (val === DoughnutType || val === PieType) {
      if (!(viz.type === DoughnutType || viz.type === PieType)) {
        newViz.designSettings.hasLegend = true;
        newViz.designSettings.legendPosition =
          Math.random() < 1 / 2 ? "right" : "left";
        newViz.designSettings.dataLabelValue.position = "center";
        newViz.designSettings.dataLabelValue.alignment = "center";
        // if (QChart && vizQs[0].choiceQuestion) {
        //   // newViz.designSettings.answerType = ParticipationCount;
        // }
        // newViz.designSettings.borderRadius = 0;
      }
    } else {
      // if (val === LineType) {
      //   viz.designSettings.byPercent = false; // Not sure why huh
      //   viz.designSettings.byPercentOfTotal = false;
      // }
      if (newViz.designSettings.hasLegend) {
        if (newViz.type === VertBarType) {
          newViz.designSettings.legendPosition = "top";
        }
        if (newViz.type === HorizBarType) {
          newViz.designSettings.legendPosition = "right";
        }
      }

      newViz.designSettings.dataLabelValue.position = "end";
      newViz.designSettings.dataLabelValue.alignment = "end";
    }

    updateViz(newViz);
  }

  const vizQs = combinedQs(viz);

  const QChart = !viz.dataMethod || viz.dataMethod === "question";

  const matrix =
    QChart &&
    vizQs[0].type === "Matrix" &&
    !viz.designSettings.option &&
    !vizQs[0].textQuestion;

  const split =
    viz.designSettings.split ||
    matrix ||
    (viz.designSettings.asRanking && !viz.designSettings.scaleByRank) ||
    viz.designSettings.useComparison ||
    (!QChart && viz.pivotString);

  const stacked = viz.designSettings.stacked;

  const [dounutTip, setDonutTip] = useState(false);
  const [pieTip, setPieTip] = useState(false);

  const showDD = () => {
    if (dounutTip) {
      setDonutTip(false);
    } else {
      setDonutTip(true);
      setTimeout(() => setDonutTip(false), 5000);
    }
  };

  const showDP = () => {
    if (pieTip) {
      setPieTip(false);
    } else {
      setPieTip(true);
      setTimeout(() => setPieTip(false), 5000);
    }
  };

  return (
    <>
      <div
        key={viz.id}
        // className={`${visible ? styles.headervisible : styles.header} ${
        //   visible ? styles.accordionactive : ""
        // }`}
        className={`${styles.header} ${visible ? styles.headervisible : ""}`}
        onClick={setVisible}
      >
        {/* {title} */}
        <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
          {title}{" "}
          <i style={{ fontSize: "1.2em" }} className="bi bi-bar-chart-line"></i>
        </div>
        <span className={styles.accordionicon}>
          <i className="bi bi-caret-left-fill"></i>
        </span>
      </div>
      {visible && (
        <div className={styles.body}>
          <div className={styles.chartStyleContainer}>
            {!split && (
              <div
                className={styles.imgContainer}
                style={{
                  backgroundColor: viz.type === VertBarType ? "#a3a4a8" : "",
                }}
                onClick={() => changeType(VertBarType)}
              >
                <img style={chartImageStyle} src={VerticalBarImage} alt="IMG" />
              </div>
            )}
            {split && (
              <>
                <div
                  className={styles.imgContainer}
                  style={{
                    backgroundColor:
                      viz.type === VertBarType && !stacked ? "#a3a4a8" : "",
                  }}
                  onClick={() => changeType(VertBarType)}
                >
                  <img style={chartImageStyle} src={vtlBarSplit} alt="IMG" />
                </div>
                <div
                  className={styles.imgContainer}
                  style={{
                    backgroundColor:
                      viz.type === VertBarType && stacked ? "#a3a4a8" : "",
                  }}
                  onClick={() => changeType(VertBarType, true)}
                >
                  <img style={chartImageStyle} src={vtlStackedbar} alt="IMG" />
                </div>
              </>
            )}

            {!split && (
              <div
                className={styles.imgContainer}
                style={{
                  backgroundColor: viz.type === HorizBarType ? "#a3a4a8" : "",
                }}
                onClick={() => changeType(HorizBarType)}
              >
                <img
                  style={chartImageStyle}
                  src={HorizontalBarImage}
                  alt="IMG"
                />
              </div>
            )}
            {split && (
              <>
                <div
                  className={styles.imgContainer}
                  style={{
                    backgroundColor:
                      viz.type === HorizBarType && !stacked ? "#a3a4a8" : "",
                  }}
                  onClick={() => changeType(HorizBarType)}
                >
                  <img style={chartImageStyle} src={HznlBarSplit} alt="IMG" />
                </div>

                <div
                  className={styles.imgContainer}
                  style={{
                    backgroundColor:
                      viz.type === HorizBarType && stacked ? "#a3a4a8" : "",
                  }}
                  onClick={() => changeType(HorizBarType, true)}
                >
                  <img style={chartImageStyle} src={HznlStackedbar} alt="IMG" />
                </div>
              </>
            )}

            {!matrix && (
              <>
                <div
                  className={`${
                    split ? styles.disabledImgContainer : styles.imgContainer
                  }`}
                  style={{
                    backgroundColor: viz.type === DoughnutType ? "#a3a4a8" : "",
                  }}
                  onClick={
                    split ? () => showDD() : () => changeType(DoughnutType)
                  }
                >
                  <img
                    style={chartImageStyle}
                    src={DonutChartImage}
                    alt="IMG"
                  />
                  <div className={styles.imgBlurer}>
                    {dounutTip && (
                      <div className={styles.message}>
                        <div>Disabled when splitting data</div>
                        <div style={{ fontSize: ".8em" }}>{"(chart data)"}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div
                  className={`${
                    split ? styles.disabledImgContainer : styles.imgContainer
                  }`}
                  style={{
                    backgroundColor: viz.type === PieType ? "#a3a4a8" : "",
                  }}
                  onClick={split ? () => showDP() : () => changeType(PieType)}
                >
                  <img style={chartImageStyle} src={PieChartImage} alt="IMG" />
                  <div className={styles.imgBlurer}>
                    {pieTip && (
                      <div className={styles.message}>
                        <div>Disabled when splitting data</div>
                        <div style={{ fontSize: ".8em" }}>{"(chart data)"}</div>
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}

            {!split && (
              <div
                className={styles.imgContainer}
                style={{
                  backgroundColor: viz.type === LineType ? "#a3a4a8" : "",
                }}
                onClick={() => changeType(LineType)}
              >
                <img style={chartImageStyle} src={LineChartImage} alt="IMG" />
              </div>
            )}
            {split && (
              <div
                className={styles.imgContainer}
                style={{
                  backgroundColor: viz.type === LineType ? "#a3a4a8" : "",
                }}
                onClick={() => changeType(LineType)}
              >
                <img style={chartImageStyle} src={MuliLineChart} alt="IMG" />
              </div>
            )}
            {/* <div
              className={styles.imgContainer}
              style={{
                backgroundColor: viz.type === ValueType ? "#a3a4a8" : "",
              }}
              onClick={() => changeType(ValueType)}
            >
              <img style={chartImageStyle} src={SingleValueImage} alt="IMG" />
            </div>
            <div
              className={styles.imgContainer}
              style={{
                backgroundColor: viz.type === MetricType ? "#a3a4a8" : "",
              }}
              onClick={() => changeType(MetricType)}
            >
              <MetricChartIcon active={viz.type === MetricType} />
            </div> */}
            {/* <div
              className={styles.imgContainer}
              style={{
                backgroundColor: viz.type === TableType ? "#a3a4a8" : "",
              }}
              onClick={() => changeType(TableType)}
            >
              <img style={chartImageStyle} src={TableImage} alt="IMG" />
            </div> */}
          </div>

          {showQClarifier && <QClarifier />}
        </div>
      )}
    </>
  );

  function QClarifier({}) {
    const Scale = viz.question.filter((q) => q.scaleQuestion);
    const Choice = viz.question.filter((q) => q.choiceQuestion);
    const Text = viz.question.filter((q) => q.textQuestion);

    function addToTypes(types, val) {
      if (!types.includes(val)) {
        types.push(val);
      }
    }

    const getCurrentTypes = () => {
      let types = [];
      forEach(viz.question, (q) => {
        if (q.scaleQuestion) {
          addToTypes(types, "Scale");
        }
        if (q.choiceQuestion) {
          addToTypes(types, "Multiple Choice");
        }
        if (q.textQuestion) {
          addToTypes(types, "Free Response");
        }
      });

      let string = "";
      forEach(types, (t, ind) => {
        if (ind > 0) {
          if (ind < types.length - 1) {
            string += ", ";
          } else if (ind == types.length - 1) {
            string += " and ";
          }
        }
        string += t;
      });

      string += " questions";
      return string;
    };

    const getIntendedType = () => {
      let type = intendedType.type;
      if (type.includes("bar")) {
        return "Bar charts";
      }
      if (type.includes("line")) {
        return "Line charts";
      }
      if (type === DoughnutType) {
        return "Doughnut charts";
      }
      if (type === PieType) {
        return "Pie charts";
      }
      if (type === ValueType) {
        return "Value charts";
      }
    };

    function genTitle(Qs) {
      let titles = [];
      let title = "";
      for (let q of Qs) {
        if (!titles.includes(q.questionText)) {
          if (titles.length > 0) {
            title += " | ";
          }
          title += q.questionText;
          titles.push(q.questionText);
        }
      }
      return title;
    }

    function onClick(questions) {
      let newViz = { ...viz };
      newViz.question = questions;
      newViz.designSettings.combinedQTs = false;

      //add back nessecary projects, tags, and tableFields

      newViz.projectIdsArray = [];
      newViz.tagIdsArray = [];

      newViz.designSettings.tableFields = {
        firstName: { name: "first name", show: true },
        lastName: { name: "last name", show: true },
        email: { name: "email", show: false },
      };
      newViz.designSettings.tableOrder = ["firstName", "lastName", "email"];
      newViz.designSettings.tablePivotFields = {};
      newViz.designSettings.tablePivotOrder = [];

      for (let q of newViz.question) {
        if (!newViz.projectIdsArray.includes(q.projectId)) {
          newViz.projectIdsArray.push(q.projectId);

          // Find if there is a survey tag associated
          let tag = getSurveyTags?.data?.tags.find((t) =>
            t.project.some((p) => p.id === q.projectId)
          );
          if (tag) {
            // If so, add it back to the IdsArray
            if (!newViz.tagIdsArray.includes(tag.id)) {
              newViz.tagIdsArray.push(tag.id);
            }
          }
        }

        newViz.designSettings.tableFields[q.id] = {
          show: true,
          name: q.questionText,
          project: q.projectId,
        };
        newViz.designSettings.tableOrder.push(q.id);
      }

      if (viz.designSettings.artificialTitle) {
        newViz.title = genTitle(newViz.question);
      }

      newViz.type = intendedType.type;
      newViz.designSettings.stacked = intendedType.stacked;

      if (newViz.designSettings.split === "question") {
        newViz.designSettings.split = "";
      }
      if (newViz.designSettings.split) {
        newViz.designSettings.hasLegend = true;
      } else {
        newViz.designSettings.hasLegend = false;
      }

      if (newViz.type === DoughnutType || newViz.type === PieType) {
        newViz.designSettings.hasLegend = true;
        newViz.designSettings.legendPosition = "right";
        if (newViz.question[0].choiceQuestion) {
          newViz.designSettings.answerType = ParticipationCount;
        }
      }

      updateViz(newViz);
      setShowQClarifier(false);
    }

    return (
      <div className={styles.qClarifierBackground}>
        <div className={styles.qClarifierPopout}>
          <i
            className={`${"bi bi-x-lg"} ${styles.exitBtn}`}
            onClick={() => setShowQClarifier(false)}
            style={{ borderRadius: "1em" }}
          ></i>
          <div
            style={{ textAlign: "center" }}
          >{`You currently have ${getCurrentTypes()}. ${getIntendedType()} require each question to be the same type (ie. Multiple Choice, Number Scale).`}</div>
          {Text.length > 0 && (
            <div style={{ textAlign: "center" }}>
              {`You can't keep Free Response questions with ${getIntendedType()}, they will be dropped from this chart.`}
            </div>
          )}
          <div
            style={{ textAlign: "center", color: "#616565", marginTop: ".6em" }}
          >
            Pick which type you would like to keep
          </div>
          <div className={styles.qTypeSelectorDiv}>
            {Scale.length > 0 && (
              <div className={styles.qType}>
                <Button navyWhite onClick={() => onClick(Scale)}>
                  Keep
                </Button>
                <div className={styles.qTypeTitle}>Scale:</div>
                <div className={styles.Qs}>
                  {Scale.map((q) => (
                    <div className={styles.Q}>{q.questionText}</div>
                  ))}
                </div>
              </div>
            )}
            {Choice.length > 0 && (
              <div className={styles.qType}>
                <Button navyWhite onClick={() => onClick(Choice)}>
                  Keep
                </Button>
                <div className={styles.qTypeTitle}>Choice:</div>
                <div className={styles.Qs}>
                  {Choice.map((q) => (
                    <div className={styles.Q}>{q.questionText}</div>
                  ))}
                </div>
              </div>
            )}
            {Text.length > 0 && (
              <div className={styles.qType}>
                {/* <div className={styles.keep}>Keep</div> */}
                {/* <Button navyWhite onClick={() => onClick(Text)}>
                  Keep
                </Button> */}
                {/* <div>For your info</div> */}
                <div className={styles.qTypeTitle}>Free Resopnse:</div>
                <div className={styles.Qs}>
                  {Text.map((q) => (
                    <div className={styles.Q}>{q.questionText}</div>
                  ))}
                </div>
              </div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "50px",
            }}
          >
            <Button red onClick={() => setShowQClarifier(false)}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    );
  }
};
