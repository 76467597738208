/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/

// import { useQueryClient } from "react-query";
import { gql } from "graphql-request";

// Internal
import { useGqlMutation, useGqlQuery } from "api/Api";
import { useQueryClient } from "react-query";

export const useFetchAnalysis = (id, onSuccess) => {
  const query = gql`
    query AnalysisById($id: String!) {
      analysisById(id: $id) {
        id
        title
        subTitle
        filters
        name
        status
        layout
        widths
        heights
        visualization {
          designSettings
          id
          title
          titleValue
          type
          pivotString
          filters
          projectIdsArray
          tagIdsArray
          analysisId
          questionTags
          programIds
          comparison
          dataMethod
          question {
            id
            index
            type
            questionText
            projectId
            hasBuckets
            buckets
            isScore
            project {
              id
              name
              startedAt
            }
            scaleQuestion {
              id
              min
              max
              step
            }
            textQuestion {
              id
              bucket {
                id
                name
                properties
              }
            }
            choiceQuestion {
              id
              choices
              hasOtherOption
              otherOptionLabel
              isRanking
              isMultiSelect
              limit
            }
            matrixQuestion {
              id
              options
              type
            }
          }
          dynamicQs {
            id
            index
            type
            questionText
            projectId
            hasBuckets
            buckets
            isScore
            scaleQuestion {
              id
              min
              max
              step
            }
            textQuestion {
              id
              bucket {
                id
                name
                properties
              }
            }
            choiceQuestion {
              id
              choices
              hasOtherOption
              otherOptionLabel
              isRanking
              limit
            }
            matrixQuestion {
              id
              options
              type
            }
          }
          comparisonQs {
            id
            index
            type
            questionText
            projectId
            hasBuckets
            buckets
            isScore
            scaleQuestion {
              id
              min
              max
              step
            }
            textQuestion {
              id
              bucket {
                id
                name
                properties
              }
            }
            choiceQuestion {
              id
              choices
              hasOtherOption
              otherOptionLabel
              isRanking
              limit
            }
            matrixQuestion {
              id
              options
              type
            }
          }
        }
      }
    }
  `;

  // const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: (data) => {
      // queryClient.invalidateQueries("analysis");
      // console.log(doneLoading);
      if (onSuccess) {
        onSuccess(data);
      }
    },
  };

  return useGqlQuery(["analysis", id], query, { id: id }, options);
};

export const useCreateAnalysis = () => {
  const mutation = gql`
    mutation Mutation($reportId: String!, $name: String!) {
      createAnalysis(reportId: $reportId, name: $name) {
        id
        name
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      //here
      queryClient.invalidateQueries("analysis");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useUpdateAnalysis = () => {
  const mutation = gql`
    mutation UpdateAnalysis($id: String!, $data: UpdateAnalysisInput!) {
      updateAnalysis(id: $id, data: $data) {
        id
        type
        name
        title
        subTitle
        filters
        status
        layout
        widths
        heights
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("analysis");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useUpdateAnalysisFilter = () => {
  const mutation = gql`
    mutation Mutation($id: String!, $filters: String!) {
      updateAnalysisFilter(id: $id, filters: $filters) {
        id
        title
        subTitle
        filters
        name
        status
        layout
        visualization {
          designSettings
          id
          title
          type
          pivotString
          filters
          projectIdsArray
          tagIdsArray
          question {
            id
            index
            type
            questionText
            projectId
            hasBuckets
            buckets
            scaleQuestion {
              id
              min
              max
              step
            }
            textQuestion {
              id
            }
            choiceQuestion {
              id
              choices
            }
          }
        }
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("analysis");
      // queryClient.invalidateQueries("viz");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useDeleteAnalysis = () => {
  const mutation = gql`
    mutation Mutation($deleteAnalysisId: String!) {
      deleteAnalysis(id: $deleteAnalysisId)
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("report");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useCopyAnalysis = () => {
  const mutation = gql`
    mutation Mutation($copyAnalysisId: String!) {
      copyAnalysis(id: $copyAnalysisId) {
        id
        name
        visualization {
          id
        }
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("report");
    },
  };

  return useGqlMutation(mutation, options);
};
