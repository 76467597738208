/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/

//External
import React from "react";

//Internal
import styles from "./Button.module.scss";

/**
 * A Personalized Button Componenet
 * @param {function} onClick a function that will be called when button is clicked on
 * @param {Boolean} white if passed in as true button will be white themed
 * @param {Boolean} blue if passed in as true button will be blue themed
 * @param {Boolean} gray if passed in as true button will be gray themed
 * @param {Boolean} red if passed in as true button will be red themed
 * @param {Boolean} link if passed in as true button will be displayed as a link
 * @param {Boolean} navy if passed in as true button will be navy themed
 * @param {Boolean} navyWhite if passed in as true button will be navyWhite themed
 * @param {Boolean} orange if passed in as true button will be orange themed
 * @param {String} width specify a fixed width of your button. If not specified button will fit its container
 * @param {String} id lets you specify the id of the button dom
 * @param {String} children the children of the button dom that will be displayed.
 * @param {String} type if type is specified then button dom will be given that type, else it will be the default type.
 * @returns {React.ReactElement} a button component
 */
export function Button({
  onClick,
  gray,
  red,
  link,
  sapphire,
  orange,
  blue,
  seafoam,
  info,
  width,
  id,
  children,
  type,
  shadow,
  padding,
  height,
  green,
  yellow,
  disable,
  style,
  ref,
}) {
  return (
    <button
      className={`${!link && styles.button} ${
        shadow && !link && styles.shadow
      } ${info && !disable && !link && styles.lightblue} ${
        blue && !disable && !link && styles.blue
      } ${gray && !disable && !link && styles.gray} ${
        red && !disable && !link && styles.red
      } ${sapphire && !disable && !link && styles.sapphire} ${
        seafoam && !disable && !link && styles.seafoam
      } ${green && !disable && !link && styles.green} ${
        yellow && !link && styles.yellow
      } ${orange && !disable && !link && styles.orange} ${
        disable && !link && styles.disable
      } ${link && styles.link}`}
      id={id}
      onClick={onClick}
      style={{
        ...{
          width: width ? width : "",
          padding: padding ? padding : "",
          height: height ? height : "",
        },
        ...style,
      }}
      disabled={disable}
      type={type ? type : ""}
      ref={ref}
    >
      {children}
    </button>
  );
}

export default Button;
