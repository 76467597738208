import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  useFetchNotifications,
  useMarkAllRead,
  useUpdateNotification,
} from "api/resources/account/notification";
import styles from "./NotifWidget.module.scss";
import { useNavigate } from "react-router-dom";
import user from "assets/images/blank-profile-picture.png";
import uparrow from "assets/images/UpArrow.png";
import reactionlogo from "assets/images/ReactionCircleLogo.png";
import { Loading } from "components/Loading/Loading";

/**
 * A component that renders a Pop-out box to display recent notifications
 * @param {function} setShowingNotif used to handle clicking outside of the popout to close it
 * @returns {React.ReactElement} a personalized Notification Popout
 */
export default function NotifWidget({ widget, inSettings }) {
  // const fetchUnreadNotifications = useFetchUnreadNotifications();

  const markAllRead = useMarkAllRead();
  const fetchNotifications = useFetchNotifications(
    {
      sort: {
        descending: true,
        all: widget.settings?.unreadOnly ? false : true,
        unread: true,
        read: false,
        important: false,
      },
    },
    widget.id,
    widget.settings?.unreadOnly
  );

  useEffect(() => {
    fetchNotifications.refetch();
    setNotifs(null);
  }, [widget.settings?.unreadOnly]);

  useEffect(() => {
    setNotifs(null);
  }, [widget.settings?.timeFrames, widget.settings?.oneBigList]);

  const [notifs, setNotifs] = useState();

  // Go to notif
  // Mark unread

  const days = {
    0: "Sun",
    1: "Mon",
    2: "Tue",
    3: "Wed",
    4: "Thu",
    5: "Fri",
    6: "Sat",
  };

  // console.log(fetchNotificationsQuery);

  function markAll() {
    markAllRead.mutate(
      {},
      {
        onSuccess: (data) => {
          console.log("Return Value: ", data);
          fetchNotifications.refetch();
          // setNotifications(null);
        },
      }
    );
  }

  const headToNotifPage = () => {
    localStorage.removeItem("activeprojecttab");
    localStorage.removeItem("activecontactstab");
    localStorage.removeItem("activeorgtab");
    localStorage.removeItem("activeaccounttab");
    localStorage.removeItem("activeresultstab");
    localStorage.removeItem("activeanalysistab");
    localStorage.setItem("activeaccounttab", 1);
    localStorage.setItem("activepage", 6);
  };

  function trimDate(date) {
    let d = new Date(date);
    let niceString = d.toDateString();
    let month = niceString.substring(4, 7);
    let day = niceString.substring(8, 10);
    //eslint-disable-next-line no-unused-expressions
    day.charAt(0) === "0" ? (day = day.charAt(1)) : null;

    const today = new Date();
    let string = month + " " + day;
    if (d.getFullYear < today.getFullYear) {
      let year = niceString.substring(11);
      string += ", " + year;
    }
    return string;
  }

  function getTime(date) {
    date = new Date(date);
    let hr = date.getHours();
    let ext = "am";
    if (hr > 12) {
      hr -= 12;
      ext = "pm";
    }
    let min = date.getMinutes();
    return hr + ":" + min + " " + ext;
  }

  function getDayOfWeek(date) {
    date = new Date(date);
    let day = date.getDay();
    day = days[day];
    return day;
  }

  function sortNotifications() {
    let notifications = {
      today: [],
      week: [],
      month: [],
      older: [],
    };

    const today = new Date();
    const timezoneOffset = today.getTimezoneOffset();
    today.setMinutes(today.getMinutes() + timezoneOffset);

    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    const lastWeek = new Date(today);
    lastWeek.setDate(lastWeek.getDate() - 7);

    const lastMonth = new Date(today);
    lastMonth.setMonth(lastMonth.getMonth() - 1);

    for (let notif of fetchNotifications.data.notifications) {
      let date = new Date(notif.createdAt);
      date.setMinutes(date.getMinutes() + timezoneOffset);
      if (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
      ) {
        notif.sent = getTime(notif.createdAt);
        notifications.today.push(notif);
      } else if (
        date.getDate() === yesterday.getDate() &&
        date.getMonth() === yesterday.getMonth() &&
        date.getFullYear() === yesterday.getFullYear()
      ) {
        notif.sent = "Yesterday";
        notifications.week.push(notif);
      } else if (date > lastWeek) {
        notif.sent = getDayOfWeek(notif.createdAt);
        notifications.week.push(notif);
      } else if (date > lastMonth) {
        notif.sent = trimDate(notif.createdAt);
        notifications.month.push(notif);
      } else {
        notif.sent = trimDate(notif.createdAt);
        notifications.older.push(notif);
      }
    }

    if (widget.settings?.oneBigList) {
      return fetchNotifications.data.notifications;
    }

    return notifications;
  }

  let navigate = useNavigate();
  const onNotifClick = (id) => {
    // localStorage.setItem("activeaccounttab", 1);
    // localStorage.setItem("activepage", 6);
    // navigate('account/notifications/' + id);
  };

  function getType(notif) {
    if (notif.type === "Upload") {
      return uparrow;
    } else if (notif.type === "Survey Created") {
      return reactionlogo;
    } else {
      return user;
    }
  }

  function getNotifications() {
    if (notifs) {
      return notifs;
    }
    let frames = [];
    let frameSettings = widget.settings.timeFrames;

    let all = sortNotifications();

    if (widget.settings?.oneBigList) {
      return all;
    }

    let bucket = [];
    if (frameSettings.today) {
      let today = { title: "Today", notifications: all.today };
      frames.push(today);
    } else {
      if (!frameSettings.today) {
        bucket = [...all.today];
      }
    }

    if (frameSettings.withinWeek) {
      let week = {
        title: "Last 7 days",
        notifications: [...bucket, ...all.week],
      };
      frames.push(week);
      bucket = [];
    } else {
      bucket = [...bucket, ...all.week];
    }

    if (frameSettings.withinMonth) {
      let month = {
        title: "This month",
        notifications: [...bucket, ...all.month],
      };
      frames.push(month);
      bucket = [];
    } else {
      bucket = [...bucket, ...all.month];
    }

    if (frameSettings.monthPlus) {
      let older = {
        title: "Month +",
        notifications: [...bucket, ...all.older],
      };
      frames.push(older);
    }

    setNotifs(frames);
    return frames;
  }

  return (
    <div className={styles.container}>
      {fetchNotifications.isLoading && <Loading></Loading>}
      {fetchNotifications.isSuccess && (
        <>
          <div className={styles.notificationsHeader}>
            {widget.settings?.unreadOnly
              ? "Unread Notifications"
              : "Notifications"}
          </div>

          {!widget.settings?.snooze && (
            <>
              {fetchNotifications.data.notifications.length > 0 && (
                <>
                  {widget.settings?.markAll && (
                    <div
                      className={`${styles.links} ${styles.markAll}`}
                      onClick={markAll}
                      style={inSettings ? {pointerEvents: "none"} : undefined}
                    >
                      Mark all as read
                    </div>
                  )}

                  {widget.settings?.oneBigList && (
                    <div
                      className={styles.notifBlock}
                      style={{ marginTop: "10px" }}
                    >
                      {getNotifications().map((notif) => (
                        <Notif notif={notif} onClick={onNotifClick} />
                      ))}
                      {getNotifications().length === 0 && (
                        <div className={styles.none}>None</div>
                      )}
                    </div>
                  )}

                  {!widget.settings?.oneBigList && (
                    <>
                      {getNotifications().map((frame) => (
                        <>
                          <div className={styles.wordDivider}>
                            {frame.title}
                          </div>
                          <div className={styles.notifBlock}>
                            {frame.notifications.map((notif) => (
                              <Notif notif={notif} inSettings={inSettings} />
                            ))}
                            {frame.notifications.length === 0 && (
                              <div className={styles.none}>None</div>
                            )}
                          </div>
                        </>
                      ))}
                    </>
                  )}
                </>
              )}
              {fetchNotifications.data.notifications.length === 0 && (
                <div className={styles.noNewNotifs}>
                  No new notifications &nbsp;
                  <i className="bi bi-envelope-slash"></i>
                </div>
              )}
            </>
          )}
          {widget.settings?.snooze && (
            <div className={styles.snoozing}>
              Snoozing
              <i className="bi bi-moon-stars"></i>
            </div>
          )}
          {widget.settings?.viewAll && (
            <div
              className={styles.viewAll}
              style={
                widget.settings?.snooze
                  ? { visibility: "hidden" }
                  : inSettings
                  ? { pointerEvents: "none" }
                  : undefined
              }
            >
              <NavLink
                to={`/notifications`}
                className={styles.links}
                id="notificationLink"
                onClick={headToNotifPage()}
              >
                Go to all notifications
              </NavLink>
            </div>
          )}
        </>
      )}
    </div>
  );
}

function Notif({ notif, inSettings }) {
  const updateNotification = useUpdateNotification();

  const [read, setRead] = useState(notif.read);

  function markRead() {
    setRead(true);
    updateNotification.mutate({
      data: {
        read: !notif.read,
      },
      updateNotificationId: notif.id,
    });
  }

  function getType(notif) {
    if (notif.type === "Upload") {
      return uparrow;
    } else if (notif.type === "Survey Created") {
      return reactionlogo;
    } else {
      return user;
    }
  }

  return (
    <div
      className={`${styles.notificationContainer} ${
        read || inSettings ? "" : styles.clickableNotif
      }`}
      onClick={read || inSettings ? undefined : markRead}
      style={read || inSettings ? undefined : { cursor: "pointer" }}
    >
      {!read && <div className={styles.notread}></div>}
      <img
        src={getType(notif)}
        className={styles.avatar}
        alt="user profile image"
      />
      <div className={styles.notification}>
        <div className={styles.notifTitle}>
          <div className={styles.header}>{notif.senderTitle}</div>
          <div className={styles.time}>{notif.sent}</div>
        </div>
        <div className={styles.notifHeader}>{notif.header}</div>
        <div className={styles.notifBody}>{notif.body}</div>
      </div>
    </div>
  );
}
