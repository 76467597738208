import React, { useEffect, useState } from "react";
import styles from "./InteractiveDistributionTable.module.scss";
import { BasicTable } from "./BasicTable";
import { PaginationContainer } from "./PaginationContainer";
import { Pagination } from "./Pagination";
import { NavTextField } from "components/inputs/input_fields/NavTextField/NavTextField";
import { Loading } from "components/Loading/Loading";
import Button from "components/Button/Button";
import EmailWidgets from "./SurveyWidgets/EmailWidgets/EmailWidgets";
import { useSearchDistributions } from "api/resources/projects/distributions";
import { useFetchProjectGql } from "api/resources/projects/projects";
import { Label } from "components/layouts/Label/Label";
import { useNavigate } from "react-router-dom";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import ReactionCalendar from "components/ReactionCalendar/ReactionCalendar";

function InteractiveDistributionTable({
  headers,
  onRowClick,
  sort,
  setSort,
  currPage,
  setCurrPage,
  maxItems,
  perPage,
  setPerPage,
  queryData,
  queryDataAccessor,
  maxDataQuery,
  maxDataQueryAccessor,
  setPageSkip,
  actionButtonText,
  hideActionButton,
  disableActionButton,
  tableTitle,
  tableDescription,
  widgetType,
  searchPlaceholder,
  onClickActionButton,
  viewing,
  setViewing,
  roles,
  user,
  id,
  copyClick,
  survey,
}) {
  const [widgetView, setWidgetView] = useState(true);
  const [searchString, setSearchString] = useState("");
  const [perPageAud, setPerPagAud] = useState(10);
  const [pageSkipAud, setPageSkipAud] = useState(0);
  const [pageSortAud, setPageSortAud] = useState({
    item: "index",
    descend: false,
  });
  const [reminders, setReminders] = useState([]);

  const searchEmails = useSearchDistributions(
    id,
    pageSortAud,
    pageSkipAud,
    perPageAud,
    searchString,
    widgetType,
    "emailsearch"
  );

  useEffect(() => {
    searchEmails.refetch();
  }, [searchString]);

  function setActive(status, item) {
    let temp = [...viewing];
    temp[item.index].active = status;
    setViewing(temp);
  }

  const reminderoptions = [
    { label: "Never", value: 0 },
    { label: "One hour before", value: 60 },
    { label: "30 Minutes before", value: 30 },
    { label: "10 Minutes before", value: 10 },
    { label: "5 Minutes before", value: 5 },
    { label: "One day before", value: 1440 },
    { label: "Two days before", value: 2880 },
    { label: "Three days before", value: 4320 },
    { label: "One week before", value: 10080 },
  ];

  function scrollPage() {
    let element = document.getElementById("projectpage");
    element.scrollTop = element.scrollHeight - 1100;
  }

  const navigate = useNavigate();
  return (
    <div className={styles.tableContainer}>
      {queryData?.isSuccess &&
        maxDataQuery?.isSuccess &&
        !queryData?.isRefetching &&
        !maxDataQuery?.isRefetching &&
        queryData.data[queryDataAccessor].length === 0 && (
          <div className={styles.nomessagePage}>
            <div className={styles.nomessageContainer}>
              <Label
                className={styles.header}
                labelIcon={<i className="bi-info-circle"></i>}
                tooltipText="A message can also work in conjuction with other methods of collecting responses. For example, you can send an email out to known contacts that you want to take the survey and you can have an generic survey link to send to anyone else or share on social media"
              >
                Compose First Message
              </Label>
              <div className={styles.nomessages}>
                <div className={styles.icons}>
                  <i className="bi-envelope"></i>
                  <i className="bi-phone"></i>
                </div>
                <div className={styles.description}>
                  A "Message" allows you to send a unique survey link via email,
                  text message, or both to contacts identified by their
                  individual email address and/or phone number. This allows you
                  to track response rates, who has not responded, who opts outs,
                  etc.
                </div>
              </div>
              <div style={{ marginLeft: "50px" }}>
                <Button
                  shadow
                  disable={disableActionButton}
                  onClick={
                    onClickActionButton
                      ? onClickActionButton
                      : () => console.log("click")
                  }
                >
                  <div style={{ display: "flex", gap: ".5em" }}>
                    Compose First Message <i className="bi-pencil"></i>
                  </div>
                </Button>
              </div>
            </div>
            {!survey.isProgram && (
              <div style={{ display: "flex", gap: "7px", fontSize: ".8em" }}>
                or
                <Button
                  link
                  style={{ padding: "0em", fontSize: "1em" }}
                  onClick={() => {
                    localStorage.setItem("activedelivertab", 0);
                    navigate(
                      `/${survey.isProgram ? "program" : "project"}/${
                        survey.id
                      }/delivery/survey-link`
                    );
                  }}
                >
                  go to Survey Link
                </Button>
              </div>
            )}
          </div>
        )}

      {(maxDataQuery?.isLoading || queryData?.isLoading) && <Loading></Loading>}
      {(maxDataQuery?.isError || queryData?.isError) && <p>Error</p>}
      {queryData?.isSuccess &&
        maxDataQuery?.isSuccess &&
        queryData.data[queryDataAccessor].length > 0 && (
          <div className={styles.content}>
            <div
              className={styles.content2}
              style={{
                flexWrap: window.innerWidth < 820 ? "wrap-reverse" : "",
              }}
            >
              <div className={styles.messages}>
                <div className={styles.tableHeader}>
                  <div
                    style={{
                      display: "flex",
                      gap: "1em",
                      paddingTop: ".25em",
                      alignItems: "center",
                      flexWrap: "wrap",
                      justifyContent: 'space-between',
                      width: "100%",
                      maxWidth: "1000px",

                    }}
                  >
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        gap: "1em",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <div className={styles.searchBox}>
                        <NavTextField
                          value={searchString}
                          setValue={(val) => {
                            setSearchString(val);
                          }}
                          placeholder={
                            searchPlaceholder ? searchPlaceholder : "Search..."
                          }
                          className={styles.search}
                          shadow
                        />
                        {searchEmails.isSuccess &&
                          !searchEmails.isRefetching &&
                          searchString != "" && (
                            <div className={styles.items}>
                              {searchEmails?.data?.distributions.map(
                                (report) => (
                                  <div
                                    className={styles.item}
                                    onClick={() => onRowClick(report)}
                                  >
                                    <span className={styles.name}>
                                      {" "}
                                      {report?.name}
                                    </span>
                                    <span className={styles.type}>
                                      {" "}
                                      {report?.status}
                                    </span>
                                  </div>
                                )
                              )}
                            </div>
                          )}
                      </div>

                      {!hideActionButton && (
                        <Button
                          shadow
                          disable={disableActionButton}
                          onClick={
                            onClickActionButton
                              ? onClickActionButton
                              : () => console.log("click")
                          }
                        >
                          {actionButtonText ? actionButtonText : "Click Here "}
                        </Button>
                      )}
                    </div>
                    <PaginationContainer
                      style={{
                        width: "fit-content",
                        padding: "0",
                      }}
                    >
                      <Pagination
                        pageNum={currPage}
                        setPageNum={setCurrPage}
                        maxPage={
                          maxDataQuery.data[maxDataQueryAccessor] / perPage < 1
                            ? 1
                            : Math.ceil(
                                maxDataQuery.data[maxDataQueryAccessor] /
                                  perPage
                              )
                        }
                        length={queryData.data[queryDataAccessor].length}
                        searchString={searchString}
                        perPage={perPage}
                        setPerPage={setPerPage}
                        setPageSkip={setPageSkip}
                      ></Pagination>
                    </PaginationContainer>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    width: "100%",
                    // gap: "4em",
                  }}
                >
                  {widgetView && (
                    <EmailWidgets
                      surveys={queryData.data[queryDataAccessor]}
                      onClick={onRowClick}
                      copyClick={copyClick}
                      survey={survey}
                      user={user}
                      length={queryData.data[queryDataAccessor].length}
                      count={maxDataQuery.data[maxDataQueryAccessor]}
                      searchString={searchString}
                      perPage={perPage}
                      setPerPage={setPerPage}
                      setPageSkip={setPageSkip}
                      currPage={currPage}
                      setCurrPage={setCurrPage}
                    ></EmailWidgets>
                  )}
                </div>
              </div>
            </div>

            <ReactionCalendar
              projectId={id}
              title="Message Schedule Calendar"
              onClickEvent={onRowClick}
            />
          </div>
        )}
    </div>
  );
}

export default InteractiveDistributionTable;
