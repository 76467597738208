import React, { useEffect, useRef, useState } from "react";
import styles from "./imageUploadModal.module.scss";
import ReactModal from "components/ReactModal/ReactModal.jsx";
import { ImageUpload } from "components/inputs";
import { TextFieldSimple } from "components/inputs/input_fields/TextFieldOnChange/TextFieldSimple";
import {
  useCreateOrgLogo,
  useCreateUserLogo,
  useGetSignedURL,
  useGetSignedURLUser,
  useUpdateImage,
} from "api/resources/projects/images";
import axios from "axios";
import AvatarEditor from "react-avatar-editor";
import { NumSlider } from "components/inputs/input_fields/NumSlider/NumSlider";
import { NumberInput } from "components/inputs/input_fields/NumberInput/NumberInput";
import Button from "components/Button/Button";
import { Label } from "components/layouts/Label/Label";
import { Loading } from "components/Loading/Loading";

export const ImageUploadModal = ({
  show,
  setShow,
  setImage,
  isUser,
  refetch,
  isLogo,
  isProject,
  onSave,
  userId,
  src,
  initPosition,
  image,
  orgId
}) => {
  const [name, setName] = useState(image?.name ? image?.name : "");
  const [about, setAbout] = useState(image?.about ? image?.about : "");
  const [url, setUrl] = useState(src);
  const [position, setPosition] = useState(initPosition);
  const [scale, setScale] = useState(image?.scale ? image?.scale : 1);
  const [file, setFile] = useState("");
  const [change, setChange] = useState(false);
  const [saving, setSaving] = useState(false);

  const getSignedURL = useGetSignedURL();
  const createOrgImage = useCreateOrgLogo();
  const createUserImage = useCreateUserLogo();
  const getSignedURLUser = useGetSignedURLUser();
  const updateImage = useUpdateImage();

  function combFileName(fileName) {
    let index = fileName?.lastIndexOf("\\");
    if (index == -1) {
      index = fileName?.lastIndexOf("/");
    }
    let shortName = fileName?.substring(index + 1);
    if (shortName?.length > 15) {
      shortName = shorten(shortName);
    }
    return shortName;
  }

  function shorten(fileName) {
    let dotInd = fileName.lastIndexOf(".");
    if (dotInd == -1) {
      return fileName.substring(0, 15) + "...";
    }
    let suffix = fileName.substring(dotInd);
    fileName = fileName.substring(0, 15);
    return fileName + "..." + suffix;
  }

  const handleImageUpload = (event) => {
    if (event.target.value === "") {
      return;
    }
    let shortName = combFileName(event.target.value);
    setName(shortName);
    setFile(event.target.files[0]);
    setUrl(URL.createObjectURL(event.target.files[0])); // ? what this for
  };

  function handleUpdateImage() {
    setSaving(true);
    updateImage.mutate(
      {
        data: {
          position: JSON.stringify(position),
          scale: scale,
          name: name,
          about: about,
        },
        id: image?.id,
      },
      {
        onSuccess: async (data) => {
          if (refetch) {
            refetch();
          }
          setSaving(false);
          setShow(false);
          setChange(false);
        },
      }
    );
  }

  const handleSaveImage = () => {
    if (isUser) {
      getSignedURLUser.mutate(
        {
          filename: name,
          filetype: file.type,
        },
        {
          onSuccess: (data) => {
            uploadToS3(
              file,
              data.getSignedURLUser.signedRequest,
              data.getSignedURLUser.url
            );
          },
        }
      );
    } else if (isProject) {
      onSave(file, url);
      setShow(false);
    } else {
      getSignedURL.mutate(
        {
          filename: name,
          filetype: file.type,
        },
        {
          onSuccess: (data) => {
            uploadToS3(
              file,
              data.getSignedURL.signedRequest,
              data.getSignedURL.url
            );
          },
        }
      );
    }
  };

  async function uploadToS3(file, signedRequest, url) {
    const options = {
      headers: {
        "Content-Type": file.type,
      },
    };
    const instance = axios.create({
      baseURL: "https://reaction.health/",
    });
    instance.defaults.withCredentials = true;
    if (isUser) {
      createUserImage.mutate(
        {
          url: url,
          name: name,
          about: about ? about : "",
          userId: userId ? userId : "",
          position: position
            ? JSON.stringify(position)
            : JSON.stringify({ x: 0.5, y: 0.5 }),
          scale: parseInt(scale),
        },
        {
          onSuccess: async (data) => {
            if (refetch) {
              refetch();
            }
            await instance.put(signedRequest, file, options);
            if (setImage) {
              setImage(data.createUserImage.imageURL);
            }
            setShow(false);
          },
        }
      );
    } else {
      createOrgImage.mutate(
        {
          url: url,
          name: name,
          about: about,
          isLogo: isLogo ? isLogo : false,
          position: JSON.stringify(position),
          scale: scale,
          orgId: orgId
        },
        {
          onSuccess: async (data) => {
            await instance.put(signedRequest, file, options);
            setShow(false);
            if (refetch) {
              refetch();
            }
            location.reload();
          },
        }
      );
    }
  }

  return (
    <>
      {" "}
      {show && (
        <div
          // show={show}
          // onClose={() => setShow(false)}
          className={styles.modal}
          // modalStyle={{
          //   borderRadius: "1em",
          //   padding: "1em",
          //   paddingTop: "1.5em",
          //   position: "relative",
          //   height: "fit-content",
          //   width: "50%",
          //   display: "flex",
          //   flexDirection: "column",
          //   gap: "1em",
          // }}
        >
          <div className={styles.modalContainer}>
            <div className={styles.modalRight}>
              {url != "" && (
                <div className={styles.imageContainer} style={{borderRadius: isLogo ? ".5rem" : ""}}>
                  <AvatarEditor
                    image={url}
                    border={10}
                    borderRadius={isLogo ? 0 : 200}
                    onPositionChange={(p) => {
                      setPosition(p);
                      setChange(true);
                    }}
                    position={position}
                    height={isLogo ? 90 : 240}
                    width={240}
                    scale={scale}
                  ></AvatarEditor>
                </div>
              )}
            </div>
            <div className={styles.modalLeft}>
              <TextFieldSimple
                value={name}
                placeholder="Name"
                onChange={(val) => {
                  setName(val);
                  setChange(true);
                }}
                onSave={setName}
              ></TextFieldSimple>
              <TextFieldSimple
                value={about}
                placeholder="Description"
                onChange={(val) => {
                  setAbout(val);
                  setChange(true);
                }}
                onSave={setAbout}
              ></TextFieldSimple>
              <div style={{ width: "100%" }}>
                <Label
                  style={{ padding: "0em", margin: "0em", fontSize: ".9em" }}
                >
                  Image Scale
                </Label>
                <div className={styles.setting2}>
                  <div style={{ width: "100%" }}>
                    <NumSlider
                      value={scale}
                      min={0}
                      max={2}
                      step={0.025}
                      onChange={(val) => {
                        setScale(val);
                        setChange(true);
                      }}
                      color={"#2A627C"}
                    ></NumSlider>
                  </div>
                  <NumberInput
                    startNumber={scale}
                    min={0}
                    max={2}
                    step={0.025}
                    handleNumberChange={(val) => {
                      setScale(val);
                      setChange(true);
                    }}
                    color={"#2A627C"}
                  ></NumberInput>
                </div>
              </div>
            </div>

            <div className={styles.modalFooter}>
              {" "}
              <Button
                seafoam
                shadow
                onClick={() => {
                  setPosition(initPosition);
                  setScale(image?.scale ? image?.scale : 1);
                  setName(image?.name);
                  setAbout(image?.about);
                  setFile("");
                  setUrl(src);
                  setChange(false);
                  setShow(false);
                }}
              >
                Cancel{" "}
              </Button>
              {!change && (
                <Button shadow>
                  <label htmlFor="orgPicInput" style={{ cursor: "pointer" }}>
                    <input
                      type="file"
                      id="orgPicInput"
                      name="orgPicInput"
                      accept="image/*"
                      content="Upload Image"
                      hidden
                      onChange={(e) => handleImageUpload(e)}
                    ></input>
                    Change Image
                  </label>
                </Button>
              )}
              {file && !saving && change && (
                <Button shadow onClick={() => handleSaveImage()}>
                  Save Changes{" "}
                </Button>
              )}
              {!file && !saving && change && (
                <Button shadow onClick={() => handleUpdateImage()}>
                  Save Changes{" "}
                </Button>
              )}
              {saving && change && (
                <Button shadow onClick={() => handleUpdateImage()}>
                  Saving <Loading height={20} width={20}></Loading>
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ImageUploadModal;
