import { NumberInput } from "components/inputs/input_fields/NumberInput/NumberInput";
import { SelectField } from "components/inputs";
import React, { useState } from "react";
import styles from "../SettingsAccordion.module.scss";
import {
  TableType,
  High2Low,
  Low2High,
  NoSort,
  NumUp,
  NumDown,
  AtoZ,
  ZtoA,
  ReverseSort,
  splitSomehow,
  HorizBarType,
} from "../../QuestionChart";
import { Label } from "components/layouts/Label/Label";
import { forEach } from "assets/functions/ArrayFunctions";
import { splitting } from "../SettingsAccordion";

//custom accordian
export const SortingSettings = ({
  viz,
  title,
  changeSettingsField,
  labels,
  visible,
  setVisible,
}) => {
  return (
    <>
      <div
        key={"datalabels"}
        className={`${styles.header} ${visible ? styles.headervisible : ""}`}
        onClick={setVisible}
      >
        {/* {title} */}

        <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
          {title}{" "}
          <i
            style={{ fontSize: "1.2em" }}
            className="bi bi-sort-alpha-down"
          ></i>
        </div>

        <span className={styles.accordionicon}>
          <i className="bi bi-caret-left-fill"></i>
        </span>
      </div>
      {visible && labels && <Sorting />}
    </>
  );

  function Sorting() {
    function getShrinkScale() {
      let min = 10000;
      let max = -100;
      if (viz?.question) {
        forEach(viz?.question, (q) => {
          if (q.scaleQuestion.min < min) {
            min = q.scaleQuestion.min;
          }
          if (q.scaleQuestion.max > max) {
            max = q.scaleQuestion.max;
          }
        });
      }
      return max + " - " + min;
    }

    function getGrowScale() {
      let min = 10000;
      let max = -100;
      if (viz?.question) {
        forEach(viz?.question, (q) => {
          if (q.scaleQuestion.min < min) {
            min = q.scaleQuestion.min;
          }
          if (q.scaleQuestion.max > max) {
            max = q.scaleQuestion.max;
          }
        });
      }
      return min + " - " + max;
    }

    function getAxisSortOptions() {
      let numberLabels = false;
      let mixedLabels = false;
      let stringLabels = false;

      let type;

      for (let opt of labels.axisLabels) {
        if (!type) {
          type = typeof opt.label;
        } else if (type !== typeof opt.label) {
          mixedLabels = true;
          break;
        }
      }

      if (!mixedLabels) {
        if (type === "number") {
          numberLabels = true;
        } else {
          stringLabels = true;
        }
      }

      const sortByAxisLabel = {
        heading: "By Axis Label",
        items: [
          {
            label: numberLabels
              ? `Ascending (${getGrowScale()})`
              : mixedLabels
              ? "Ascending (1,2,3..., A - Z)"
              : "Ascending (A - Z)",
            value: AtoZ,
          },
          {
            label: numberLabels
              ? `Descending (${getShrinkScale()})`
              : mixedLabels
              ? "Descending(3,2,1..., Z - A)"
              : "Descending (Z - A)",
            value: ZtoA,
          },
        ],
      };
      return sortByAxisLabel;
    }

    const sortByAnswerCount = {
      heading: "By Value",
      items: [
        {
          label: (
            <div style={{ display: "flex", gap: "1em", alignItems: "center" }}>
              Ascending
              <i className="bi bi-bar-chart-fill"></i>
            </div>
          ),
          value: Low2High,
        },
        {
          label: (
            <div style={{ display: "flex", gap: "1em", alignItems: "center" }}>
              Descending{" "}
              <div style={{ transform: "rotateY(180deg)" }}>
                <i className="bi bi-bar-chart-fill"></i>
              </div>
            </div>
          ),
          value: High2Low,
        },
      ],
    };

    function getSortOptions() {
      let options = [getAxisSortOptions()];
      if (!splitting(viz)) {
        options.push(sortByAnswerCount);
      }
      return options;
    }

    const sortDataOptions = getSortOptions();

    function sortingByLabel() {
      return (
        viz.designSettings.sortData === AtoZ ||
        viz.designSettings.sortData === ZtoA
      );
    }

    return (
      <div className={styles.body} style={{ paddingRight: "20px" }}>
        <div className={styles.settingwithlabel}>
          <Label
            style={{
              paddingLeft: "10px",
              fontSize: ".9em",
              paddingBottom: "5px",
            }}
          >
            Sort Data
          </Label>
          <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            {sortDataOptions.map((option) => (
              <div
                style={{ display: "flex", flexDirection: "column", gap: "2px" }}
              >
                <Label
                  italics
                  style={{
                    fontWeight: "400",
                    fontSize: ".9em",
                    paddingLeft: "20px",
                  }}
                >
                  {option.heading}
                </Label>
                <div className={styles.sortOptions}>
                  {option.items.map((item) => (
                    <div className={styles.radioLabel}>
                      <Label
                        style={{
                          fontSize: ".9em",
                          paddingLeft: "20px",
                          // width: "fit-content",
                          width: "245px",

                          // flexShrink: 0,
                        }}
                      >
                        {item.label}
                      </Label>
                      <input
                        id={item.value}
                        type="radio"
                        name="sort"
                        value={item.value}
                        onChange={() =>
                          changeSettingsField("sortData", item.value)
                        }
                        checked={viz.designSettings.sortData === item.value}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>

          <div
            className={styles.radioLabel}
            style={viz.type === TableType ? {} : { marginTop: "12px" }}
          >
            <Label
              italics
              style={{
                paddingLeft: "0px",
                width: "fit-content",
                fontSize: ".9em",
                width: "245px",
              }}
            >
              {`No Sort (Original)`}
            </Label>
            <input
              id="None"
              type="radio"
              name="sort"
              value="none"
              onChange={() => changeSettingsField("sortData", NoSort)}
              checked={viz.designSettings.sortData === NoSort}
            />
          </div>
          <div
            className={styles.radioLabel}
            style={viz.type === TableType ? {} : { marginTop: "12px" }}
          >
            <Label
              italics
              style={{
                paddingLeft: "0px",
                width: "fit-content",
                fontSize: ".9em",
                width: "245px",
              }}
            >
              Reverse Original{" "}
              <i
                className="bi bi-arrow-left-right"
                style={{ marginLeft: "5px" }}
              ></i>
            </Label>
            <input
              id="None"
              type="radio"
              name="sort"
              value="none"
              onChange={() => changeSettingsField("sortData", ReverseSort)}
              checked={viz.designSettings.sortData === ReverseSort}
            />
          </div>
        </div>
      </div>
    );
  }
};
