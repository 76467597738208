import Button from "components/Button/Button";
import styles from "./WidgetSettings.module.scss";
import { useState } from "react";
import Widget from "../Widget";
import TitleSettings from "./AccordianItems/TitleSettings";
import MetricSettings from "./AccordianItems/MetricSettings";
import ChartSettings from "./AccordianItems/ChartSettings";
import { useDeleteOneVisualization } from "api/resources/projects/visualizations";
import PickSurvey from "./AccordianItems/PickSurvey";
import { NotifDisplay } from "./AccordianItems/NotifDisplay";
import { NotifyMeWhen } from "./AccordianItems/NotifyMeWhen";
import { ReportDisplay } from "./AccordianItems/ReportDisplay";
import ReportPins from "./AccordianItems/ReportPins";
import PickReport from "./AccordianItems/PickReport";

export default function WidgetSettings({
  widget,
  layout,
  onClose,
  onSave,
  onDelete,
}) {
  return (
    <>
      {widget.type === "survey" && (
        <SurveySettings
          widget={widget}
          onClose={onClose}
          onSave={onSave}
          onDelete={onDelete}
          layout={layout}
        />
      )}
      {widget.type === "notifications" && (
        <NotifWidgSettings
          widget={widget}
          onClose={onClose}
          onSave={onSave}
          onDelete={onDelete}
          layout={layout}
        />
      )}
      {widget.type === "reports" && (
        <ReportWidgetSetings
          widget={widget}
          onClose={onClose}
          onSave={onSave}
          onDelete={onDelete}
          layout={layout}
        />
      )}

      {widget.type === "tile" && (
        <TileWidgetSettings
          widget={widget}
          onClose={onClose}
          onSave={onSave}
          onDelete={onDelete}
          layout={layout}
        />
      )}
    </>
  );
}

export function NewWidgetSettings({ widget, onClose, onSave }) {
  return (
    <>
      {widget.type === "survey" && (
        <SurveySettings
          widget={widget}
          onClose={onClose}
          onSave={onSave}
          layout={widget}
          brandNew
        />
      )}
      {widget.type === "reports" && (
        <ReportWidgetSetings
          widget={widget}
          onClose={onClose}
          onSave={onSave}
          layout={widget}
          brandNew
        />
      )}
      {widget.type === "tile" && (
        <TileWidgetSettings
          widget={widget}
          onClose={onClose}
          onSave={onSave}
          layout={widget}
          brandNew
        />
      )}
    </>
  );
}

function Framework({
  children,
  onDelete,
  onCancel,
  onSave,
  widgetCopy,
  layout,
  brandNew,
}) {
  return (
    <div className={styles.pageContainer}>
      <div className={styles.widgetDisplay}>
        <div
          className={styles.widgetHolder}
          style={{
            height: layout.h + "5em",
            width: layout.w + "5em",
          }}
        >
          <Widget widget={widgetCopy} layout={layout} inSettings />
        </div>
        <div className={styles.saveBtnDiv}>
          {!brandNew && (
            <div className={styles.trashCan} onClick={onDelete}>
              <i className="bi bi-trash3"></i>
            </div>
          )}
          <Button onClick={onCancel} red height={35}>
            Cancel
          </Button>

          <Button onClick={onSave} blue height={35}>
            Save Changes
          </Button>
        </div>
      </div>
      <div className={styles.settingsContainer}>{children}</div>
    </div>
  );
}

function SurveySettings({
  widget,
  onClose,
  onSave,
  onDelete,
  brandNew,
  layout,
}) {
  const [visible, setVisible] = useState({
    survey:
      brandNew && (widget.settings?.metrics || widget.settings?.tile) // Start open if its a metric widget and doesn't have a survey (brand new)
        ? true
        : false,
    title: false,
    metrics: false,
    charts: false,
    arrangement: false,
  });

  function changeVisible(field) {
    let newVisible = { ...visible };
    newVisible[field] = !newVisible[field];
    for (let seg in newVisible) {
      if (seg !== field) {
        newVisible[seg] = false;
      }
    }
    setVisible(newVisible);
  }

  const deleteViz = useDeleteOneVisualization();

  const [widgetCopy, setWidgetCopy] = useState(
    JSON.parse(JSON.stringify(widget))
  );
  const [deleted, setDeleted] = useState([]);

  const handleSave = () => {
    for (let id of deleted) {
      deleteThisViz(id);
    }
    if (
      widget.settings?.metrics &&
      widget.settings?.autoTitle &&
      widgetCopy.settings?.title !== widgetCopy.settings?.survey?.name // What about widgets with charts? Survey's on them too?
    ) {
      widgetCopy.settings.autoTitle = false;
    }
    onSave(widgetCopy);
  };

  function deleteThisViz(id) {
    deleteViz.mutate(
      {
        id: id,
      },
      {
        onSuccess: () => {
          console.log("chart deleted");
        },
      }
    );
  }

  function onCancel() {
    if (widget.settings?.charts) {
      //Check if you added any new charts, to delete those
      let obj = {};
      for (let chart of widget.settings.charts) {
        obj[chart.id] = true;
      }
      for (let chart of widgetCopy.settings.charts) {
        if (!obj[chart.id]) {
          //You added this one
          deleteThisViz(chart.id);
        }
      }

      for (let id of deleted) {
        if (!obj[id]) {
          //You added then deleted this one
          deleteThisViz(id);
        }
      }
    }
    onClose();
  }

  function addToDeleted(id) {
    let vizs = [...deleted];
    vizs.push(id);
    setDeleted(vizs);
  }

  function changeSettings(field, val) {
    let newCopy = { ...widgetCopy };
    newCopy.settings[field] = val;
    setWidgetCopy(newCopy);
  }

  return (
    <Framework
      onSave={handleSave}
      onCancel={onCancel}
      onDelete={onDelete}
      layout={layout}
      widgetCopy={widgetCopy}
      brandNew={brandNew}
    >
      <div className={styles.settings}>
        {widget.settings.metrics && (
          <PickSurvey
            widget={widgetCopy}
            setWidget={setWidgetCopy}
            visible={visible.survey}
            setVisible={() => changeVisible("survey")}
          />
        )}

        {(!brandNew || widgetCopy.settings?.survey) && (
          <>
            <TitleSettings
              widget={widgetCopy}
              changeSettings={changeSettings}
              setWidget={setWidgetCopy}
              visible={visible.title}
              setVisible={() => changeVisible("title")}
            />
            {/* {widget.settings.metrics && (
              <MetricSettings
                settings={widgetCopy.settings}
                changeSettings={changeSettings}
                visible={visible.metrics}
                setVisible={() => changeVisible("metrics")}
              />
            )} */}
            {widget.settings?.charts && (
              <ChartSettings
                changeSettings={changeSettings}
                visible={visible.charts}
                setVisible={() => changeVisible("charts")}
                widget={widgetCopy}
                addToDeleted={brandNew ? null : addToDeleted}
              />
            )}
          </>
        )}
      </div>
    </Framework>
  );
}

function NotifWidgSettings({
  widget,
  onClose,
  onSave,
  onDelete,
  brandNew,
  layout,
}) {
  const [visible, setVisible] = useState({
    notifDisplay: true,
    notifyMeWhen: false,
  });

  function changeVisible(field) {
    let newVisible = { ...visible };
    newVisible[field] = !newVisible[field];
    for (let seg in newVisible) {
      if (seg !== field) {
        newVisible[seg] = false;
      }
    }
    setVisible(newVisible);
  }

  const [widgetCopy, setWidgetCopy] = useState(
    JSON.parse(JSON.stringify(widget))
  );

  function handleSave() {
    onSave(widgetCopy);
  }

  function onCancel() {
    onClose();
  }

  function changeSettings(field, val) {
    let newCopy = { ...widgetCopy };
    newCopy.settings[field] = val;
    setWidgetCopy(newCopy);
  }

  return (
    <Framework
      onSave={handleSave}
      onCancel={onCancel}
      onDelete={onDelete}
      layout={layout}
      widgetCopy={widgetCopy}
      brandNew={brandNew}
    >
      <div className={styles.settings}>
        <NotifDisplay
          visible={visible.notifDisplay}
          setVisible={() => changeVisible("notifDisplay")}
          settings={widgetCopy.settings}
          changeSettings={changeSettings}
        />
        {/* <NotifyMeWhen
          visible={visible.notifyMeWhen}
          setVisible={() => changeVisible("notifyMeWhen")}
          settings={widgetCopy.settings}
          changeSettings={changeSettings}
        /> */}
      </div>
    </Framework>
  );
}

function ReportWidgetSetings({
  widget,
  onClose,
  onSave,
  onDelete,
  brandNew,
  layout,
}) {
  const [widgetCopy, setWidgetCopy] = useState(
    JSON.parse(JSON.stringify(widget))
  );

  const [visible, setVisible] = useState({
    reportDisplay: false,
    reportPins: brandNew ? true : false,
  });

  function changeVisible(field) {
    let newVisible = { ...visible };
    newVisible[field] = !newVisible[field];
    for (let seg in newVisible) {
      if (seg !== field) {
        newVisible[seg] = false;
      }
    }
    setVisible(newVisible);
  }

  function handleSave() {
    onSave(widgetCopy);
  }

  function onCancel() {
    onClose();
  }

  function changeSettings(field, val) {
    let newCopy = { ...widgetCopy };
    newCopy.settings[field] = val;
    setWidgetCopy(newCopy);
  }

  function onNameChange(val) {
    let newWidget = { ...widgetCopy };
    newWidget.name = val;
    setWidgetCopy(newWidget);
  }

  return (
    <Framework
      onSave={handleSave}
      onCancel={onCancel}
      onDelete={onDelete}
      layout={layout}
      widgetCopy={widgetCopy}
      brandNew={brandNew}
    >
      <div className={styles.settings}>
        <ReportDisplay
          visible={visible.reportDisplay}
          setVisible={() => changeVisible("reportDisplay")}
          settings={widgetCopy.settings}
          changeSettings={changeSettings}
          name={widgetCopy.name}
          onNameChange={onNameChange}
        />
        <ReportPins
          visible={visible.reportPins}
          setVisible={() => changeVisible("reportPins")}
          settings={widgetCopy.settings}
          changeSettings={changeSettings}
        />
      </div>
    </Framework>
  );
}

function TileWidgetSettings({
  widget,
  onClose,
  onSave,
  onDelete,
  brandNew,
  layout,
}) {
  const [visible, setVisible] = useState({
    survey: brandNew && widget.settings?.survey ? true : false,
    report: brandNew && widget.settings?.report ? true : false,
  });

  function changeVisible(field) {
    let newVisible = { ...visible };
    newVisible[field] = !newVisible[field];
    for (let seg in newVisible) {
      if (seg !== field) {
        newVisible[seg] = false;
      }
    }
    setVisible(newVisible);
  }

  const [widgetCopy, setWidgetCopy] = useState(
    JSON.parse(JSON.stringify(widget))
  );

  function handleSave() {
    onSave(widgetCopy);
  }

  function onCancel() {
    onClose();
  }

  return (
    <Framework
      onSave={handleSave}
      onCancel={onCancel}
      onDelete={onDelete}
      layout={layout}
      widgetCopy={widgetCopy}
      brandNew={brandNew}
    >
      <div className={styles.settings}>
        {widget.settings?.survey && (
          <PickSurvey
            widget={widgetCopy}
            setWidget={setWidgetCopy}
            visible={visible.survey}
            setVisible={() => changeVisible("survey")}
          />
        )}
        {widget.settings?.report && (
          <PickReport
            widget={widgetCopy}
            setWidget={setWidgetCopy}
            visible={visible.report}
            setVisible={() => changeVisible("report")}
          />
        )}
      </div>
    </Framework>
  );
}
