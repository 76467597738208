import React from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import {
  // Project pages
  SurveyBuild,
  Delivery,
  QuestionDesign,
  Distributions,
  Deliveries,
  Email,
  SMS,
  Link,
} from "pages";

import { useGetCurrentUser } from "api/resources/organization/users";
import { useFetchRole } from "api/resources/organization/roles";
import { SurveyPreview } from "pages/projects/project/survey_build/preview/SurveyPreview";
import SurveyDetails2 from "pages/projects/project/survey_details/SurveyDetails2";
import SurveyResults from "pages/projects/project/results/SurveyResults";
import { AnonLinks } from "pages/projects/project/delivery/distributions/AnonLinks";
import { PersonalLinks } from "pages/projects/project/delivery/distributions/PersonalLinks";
import EmailBuilder from "pages/projects/project/delivery/distributions/emailBuilderTry/Builder";

export const ProjectRoutes = ({
  setHideNav,
  survey,
  direction,
  setShowSettings,
  setBeforeNavAway,
}) => {
  const getCurrUser = useGetCurrentUser();
  const getCurrRole = useFetchRole();
  const location = useLocation();
  return (
    <>
      {getCurrUser.isSuccess && getCurrRole.isSuccess && (
        <Routes location={location} key={location.key}>
          <Route path="/" element={<Navigate to={"survey-build"} />} />
          {getCurrRole.data.role.canCreateEditOrgSurveys && (
            <Route
              path="survey-build/*"
              element={
                <SurveyBuild
                  roles={getCurrRole.data.role}
                  organization={getCurrUser.data?.me?.organization?.find(
                    (org) => org.id === getCurrUser.data?.me.organizationId
                  )}
                  direction={direction}
                  setBeforeNavAway={setBeforeNavAway}
                />
              }
            />
          )}
          {getCurrRole.data.role.canViewOrgSurveys && (
            <Route
              path="preview"
              element={<SurveyPreview roles={getCurrRole.data.role} />}
            />
          )}
          {getCurrRole.data.role.canSendOrgSurveyEmails && (
            <Route
              path="delivery/*"
              element={
                <Delivery
                  roles={getCurrRole.data.role}
                  survey={survey}
                  direction={direction}
                />
              }
            />
          )}

          {getCurrRole.data.role.canViewRawResultsForOrg && (
            <>
              <Route
                path="surveyresults/:reportid"
                element={
                  <SurveyResults
                    roles={getCurrRole.data.role}
                    direction={direction}
                    org={getCurrUser.data?.me?.organization?.find(
                      (org) => org.id === getCurrUser.data?.me.organizationId
                    )}
                    user={getCurrUser.data.me}
                  />
                }
              />
            </>
          )}

          <Route
            path="survey-details"
            element={
              <SurveyDetails2
                userRoles={getCurrRole.data.role}
                currUser={getCurrUser.data.me}
                setHideNav={setHideNav}
                direction={direction}
                setShowSurveySettings={setShowSettings}
              />
            }
          />

          {/* <Route path="take-survey/:id" element={<SurveyTaker />} /> */}
        </Routes>
      )}
    </>
  );
};

export const SurveyBuildRoutes = (project) => {
  const getCurrRole = useFetchRole();

  return (
    <>
      {getCurrRole.isSuccess && (
        <Routes>
          <Route path="/" element={<Navigate to="questions" />} />
          {/* <Route path="survey-details" element={<SurveyDetails />} /> */}
          <Route
            path="questions"
            element={<SurveyBuild roles={getCurrRole.data.role} />}
          />
          <Route
            path="design"
            element={<QuestionDesign roles={getCurrRole.data.role} />}
          />
        </Routes>
      )}
    </>
  );
};

export const DeliveryRoutes = ({ survey }) => {
  const getCurrRole = useFetchRole();
  const getCurrUser = useGetCurrentUser();

  return (
    <>
      {getCurrRole.isSuccess && getCurrUser.isSuccess && (
        <Routes>
          <Route path="/" element={<Navigate to="survey-link" />} />
          <Route
            path="messages"
            element={
              <Distributions
                roles={getCurrRole.data.role}
                user={getCurrUser.data.me}
                survey={survey}
              />
            }
          />
          <Route
            path="messages/:distId"
            element={
              <Distributions
                roles={getCurrRole.data.role}
                user={getCurrUser.data.me}
                survey={survey}
              />
            }
          />
           <Route
            path="builder"
            element={
              <EmailBuilder
                roles={getCurrRole.data.role}
                user={getCurrUser.data.me}
                survey={survey}
              />
            }
          />
          <Route
            path="survey-link"
            element={
              <AnonLinks
                roles={getCurrRole.data.role}
                userId={getCurrUser.data.me.id}
                survey={survey}
              />
            }
          />
          <Route
            path="personal-links"
            element={
              <PersonalLinks
                roles={getCurrRole.data.role}
                userId={getCurrUser.data.me.id}
              />
            }
          />
          {/* <Route
            path="stats/*"
            element={<Stats roles={getCurrRole.data.role} />}
          /> */}
          {/* <Route path="distributions/email" element={<Email />} />
      <Route path="distributions/link" element={<Link />} />
      <Route path="distributions/sms" element={<SMS />} /> */}
        </Routes>
      )}
    </>
  );
};

export const DistributionRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="email" />} />
      <Route path="email" element={<Email />} />
      <Route path="link" element={<Link />} />
      <Route path="sms" element={<SMS />} />
    </Routes>
  );
};

export const SurveysRoutes = () => {
  const getCurrRole = useFetchRole();
  const getCurrUser = useGetCurrentUser();

  return (
    <>
      {getCurrUser.isSuccess && getCurrRole.isSuccess && (
        <Routes>
          <Route path="/" element={<Navigate to="OrganizationProjects" />} />
        </Routes>
      )}
    </>
  );
};
