import React, { useEffect, useRef, useState } from "react";
import ReactModal from "components/ReactModal/ReactModal.jsx";
import styles from "./ContactsSettings.module.scss";
import Button from "components/Button/Button";
import { useGetContactsByUnsubscribed } from "api/resources/contacts/contacts";
import { CSVLink } from "react-csv";
import { useFetchCurrOrgsFields } from "api/resources/organization/organization";
import { Loading } from "components/Loading/Loading";

function ContactsSettings({ show, setShow }) {
  const [data, setData] = useState([]);
  const [download, setDownload] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [headersExport, setHeadersExport] = useState([]);
  const csvref = useRef(null);
  const getContacts = useGetContactsByUnsubscribed();
  const fetchCustomFields = useFetchCurrOrgsFields();

  // function objectsToCSV(arr) {
  //   const array = [Object.keys(arr[0])].concat(arr);
  //   return array
  //     .map((row) => {
  //       return Object.values(row)
  //         .map((value) => {
  //           return typeof value === "string" ? JSON.stringify(value) : value;
  //         })
  //         .toString();
  //     })
  //     .join("\n");
  // }

  function exportContacts() {
    setDownloading(true);
    getContacts.mutate(
      {},
      {
        onSuccess: (data) => {
          let result = data?.getContactsByUnsubscribed;
          let headers = [
            {
              label: "id",
              key: "id",
            },

            {
              label: "First Name",
              key: "firstName",
            },
            {
              label: "Last Name",
              key: "lastName",
            },
            {
              label: "Email",
              key: "email",
            },
            {
              label: "unsubscribed",
              key: "unsubscribed",
            },
            {
              label: "unsubscribedAt",
              key: "unsubscribedAt",
            },
          ];


          let fields = fetchCustomFields.data.getCurrOrgFields;
          for (let field of fields) {
            headers.push({label: field?.name, key: field?.name})
          }
          setHeadersExport(headers)
          // let finalDownload = [];
          for (let i = 0; i < result.length; i++) {
            if (result[i]?.customField) {
              let custom = JSON.parse(result[i]?.customField);
              while (typeof custom === "string") {
                custom = JSON.parse(custom);
              }

              delete result[i].customField;
              result[i] = { ...result[i], ...custom };
            }
          }

          setDownload(true);
          setData(result);

          // let csvContent =
          //   "data:text/csv;charset=utf-8," + objectsToCSV(result);

          // var encodedUri = encodeURI(csvContent);
          // var link = document.createElement("a");
          // link.setAttribute("href", encodedUri);
          // link.setAttribute("download", "unsubscribed_contacts.csv");
          // document.body.appendChild(link); // Required for FF

          // link.click();
        },
      }
    );
  }



  useEffect(() => {
    if (download) {
      csvref.current.click();
      setDownload(false);
      setDownloading(false);
    }
  }, [data]);

  return (
    <ReactModal
      show={show}
      onClose={() => setShow(false)}
      modalStyle={{
        borderRadius: "1em",
        padding: "10px",
        gap: "1em",
        alignItems: "center",
        // height: "100%",
        // width: "100%",
        // maxWidth: "1200px",
      }}
    >
      <div className={styles.header}>Contacts Options</div>
      <Button
        style={{ gap: "1em" }}
        onClick={() => !downloading ? exportContacts() : console.log("Nope!")}
      >
        {!downloading && (
          <>
            <i className="bi bi-cloud-download"></i> Export Unsubscribes
          </>
        )}{" "}
        {downloading && (
          <>
            Creating Export <Loading height={20} width={20}></Loading>
          </>
        )}
      </Button>
      <CSVLink
        data={data ? data : []}
        headers={headersExport}
        //   headers={[]}
        filename={"personallinks.csv"}
        // style={{
        //   height: "40px",
        //   //border: "2px solid black",
        //   alignContent: "center",
        //   display: "flex",
        // }}
        target="_blank"
      >
        <button ref={csvref} style={{ border: "none" }}></button>
      </CSVLink>
    </ReactModal>
  );
}

export default ContactsSettings;
