// Externals
import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// Internals
import styles from "./MenuItem.module.scss";
import { TextFieldSimple } from "components/inputs";
import Button from "components/Button/Button";
import { useCreateReportFolder } from "api/resources/organization/reportFolders";
import IconShopper from "pages/results/Charts/Settings/AccordianItems/MetricSettings/IconShopper/IconShopper";

export const MenuItem = ({
  id,
  label,
  // sublabel,
  iconClassName,
  to,
  subMenus,
  isActive,
  makeActive,
  type,
  hasFolders,
  beforeNavAway,
  setBeforeNavAway,
  last,
}) => {
  const [show, setShow] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [chooseIcon, setChooseIcon] = useState(false);
  const [folder, setFolder] = useState({ name: "", icon: "folder" });
  const [display, setDisplay] = useState();

  const navigate = useNavigate();

  const createFolder = useCreateReportFolder();
  // console.log(getReportFolders.data);
  function handleAdd() {
    // if (onAddClick) {
    //   onAddClick(folder);
    // }
    createFolder.mutate(
      {
        folder: folder,
      },
      {
        onSuccess: () => {
          setShowAdd(false);
          setChooseIcon(false);
          setFolder({ name: "", icon: "folder" });
          // getReportFolders.refetch();
        },
      }
    );
  }

  const iconRef = useRef();

  function onItemClick() {
    if (!isActive) {
      if (beforeNavAway) {
        let displayItem = beforeNavAway.getDisplay(() => {
          setBeforeNavAway(undefined);
          setDisplay(undefined);
          navigate(to);
        });
        setDisplay(displayItem);
      } else {
        navigate(to, { replace: true });
      }
    }
  }

  return (
    <>
      <div
        className={`${
          window.innerWidth > 1024 ? styles.menuItem : styles.menuItemMobile
        } ${isActive && styles.activeItem} ${subMenus && styles.hover}`}
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        onClick={(e) => {
          if (makeActive) {
            makeActive(id, type);
          }
          onItemClick(e);
        }}
      >
        <div className={styles.view}>
          {iconClassName && <i className={iconClassName}></i>}
          {label}
          {!last && <i className={"bi-arrow-right"}></i>}
        </div>
        {subMenus && (
          <div className={styles.subMenus}>
            {subMenus.map((item) => (
              <div key={item.title} className={styles.subMenu}>
                <span>{item?.title}</span>
                {item?.menuItems?.map((submenuItem, i) => (
                  <Link to={submenuItem.to} key={i}>
                    <div
                      className={styles.submenuItem}
                      onClick={() => (makeActive ? makeActive(id, type) : "")}
                    >
                      {submenuItem?.title}
                    </div>
                  </Link>
                ))}

                {!showAdd && hasFolders && (
                  <div
                    className={styles.addFolder}
                    onClick={() => setShowAdd(true)}
                    style={{ whiteSpace: "nowrap" }}
                  >
                    + New Folder
                  </div>
                )}
                {showAdd && hasFolders && (
                  <div className={styles.header}>
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        gap: "1em",
                        alignItems: "center",
                      }}
                    >
                      <i
                        className={`bi bi-${
                          folder?.icon ? folder?.icon : "folder"
                        }`}
                        onClick={() => setChooseIcon(!chooseIcon)}
                        style={{ cursor: "pointer" }}
                      ></i>
                      <TextFieldSimple
                        style={{}}
                        maxLength={20}
                        value={folder.name}
                        className={styles.textinput}
                        onChange={(val) => setFolder({ ...folder, name: val })}
                        autoFocus
                        placeholder="Folder Name..."
                      ></TextFieldSimple>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "1em",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "fit-content",
                        // border: "2px solid black",
                        marginLeft: "3em",
                      }}
                    >
                      <Button height={30} onClick={handleAdd}>
                        Add Folder
                      </Button>
                      <Button
                        red
                        height={30}
                        onClick={() => {
                          setShowAdd(false);
                          setFolder({ name: "", icon: "folder" });
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            ))}{" "}
            {chooseIcon && (
              <div
                style={{
                  maxWidth: "25em",
                  background: "white",
                  borderRadius: "1em",
                  padding: ".5em",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  height: "fit-content",
                  // position: "absolute",
                  // top: "10em",
                }}
                ref={iconRef}
              >
                <IconShopper
                  onPick={(val) => {
                    setFolder({ ...folder, icon: val });
                    setChooseIcon(false);
                  }}
                  folderView
                />
              </div>
            )}
          </div>
        )}
      </div>
      {display && (
        <div style={{ position: "absolute", zIndex: "400" }}>{display}</div>
      )}
    </>
  );
};

// subMenus: [
//   {
//     title: "Survey Projects",
//     menuItems: [
//       { title: "All Survey Projects", to: "/AllSurveyProjects" },
//       { title: "My Projects", to: "/MyProjects" },
//       { title: "Shared With Me", to: "/ProjectsSharedWithMe" },
//     ],
//   },
//   {
//     title: "Survey Programs",
//     menuItems: [
//       { title: "All Survey Programs", to: "/AllSurveyPrograms" },
//       { title: "My Programs", to: "/MyPrograms" },
//       { title: "Shared With Me", to: "/ProgramsSharedWithMe" },
//     ],
//   },
// ],
