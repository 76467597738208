import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import { Label } from "components/layouts/Label/Label";
import { useEffect, useState } from "react";
import styles from "../SettingsAccordion.module.scss";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
  restrictToVerticalAxis,
  restrictToParentElement,
} from "@dnd-kit/modifiers";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import { getSortedArray } from "assets/functions/ArrayFunctions";
import { combinedProjIds, combinedQs } from "../../Visualization";
import { getQuestionType } from "./SurveyData/QData";
import { SmallCheckbox } from "components/inputs/input_fields/SmallCheckbox/SmallCheckbox";
import { useFetchBucket } from "api/resources/organization/buckets";
import { OneOrTheOther } from "components/inputs/input_fields/OneOrTheOther/OneOrTheOther";

export const TableColumns = ({
  viz,
  title,
  changeSettingsField,
  updateViz,
  visible,
  setVisible,
  projects,
  custom_fields,
}) => {
  const vizQs = combinedQs(viz);

  function couldBeRanking() {
    for (let q of vizQs) {
      if (!q.choiceQuestion || !q.choiceQuestion.isRanking) {
        return false;
      }
    }
    return true;
  }

  function hasMatrix() {
    for (let q of vizQs) {
      if (q.matrixQuestion) {
        return true;
      }
    }
    return false;
  }

  return (
    <>
      <div
        key={"titles"}
        className={`${styles.header} ${visible ? styles.headervisible : ""}`}
        onClick={setVisible}
      >
        {/* {title} */}

        <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
          {title}{" "}
          <i
            style={{ fontSize: "1.2em" }}
            className="bi bi-layout-text-window-reverse"
          ></i>
        </div>

        <span className={styles.accordionicon}>
          <i className="bi bi-caret-left-fill"></i>
        </span>
      </div>
      {visible && (
        <div className={styles.body}>
          {viz.designSettings.toLast && (
            <ToLastFields
              viz={viz}
              changeSettingsField={changeSettingsField}
              projects={projects}
              custom_fields={custom_fields}
              updateViz={updateViz}
            />
          )}

          {!viz.designSettings.toLast && (
            <>
              {!viz.pivotString && !viz.designSettings.pivotOptions && (
                <>
                  {hasMatrix() && (
                    <MatrixShowOnly viz={viz} updateViz={updateViz} />
                  )}
                  <NormalFields
                    viz={viz}
                    changeSettingsField={changeSettingsField}
                    projects={projects}
                    custom_fields={custom_fields}
                    updateViz={updateViz}
                  ></NormalFields>
                </>
              )}
              {(viz.pivotString || viz.designSettings.pivotOptions) && (
                <>
                  {couldBeRanking() && (
                    <RankingSettings
                      viz={viz}
                      changeSettingsField={changeSettingsField}
                      updateViz={updateViz}
                    />
                  )}
                  <PivotFields
                    viz={viz}
                    changeSettingsField={changeSettingsField}
                    projects={projects}
                  ></PivotFields>
                </>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

const MatrixShowOnly = ({ viz, updateViz }) => {
  const vizQs = combinedQs(viz);

  function findContainsIndex(question) {
    let fields = viz.designSettings.tableFields;
    let order = viz.designSettings.tableOrder;
    for (let i = 0; i < order.length; i++) {
      let key = order[i];
      if (fields[key].contains) {
        if (fields[key].contains.includes(question.id)) {
          return i;
        }
      }
    }
    return viz.designSettings.tableOrder.length;
  }

  function getMatrixQOptions() {
    let all = [];
    let Qs = [];
    let allQs = getSortedArray(vizQs, (a, b) => {
      if (viz.designSettings.separateBySurvey) {
        let aInd = findContainsIndex(a);
        let bInd = findContainsIndex(b);
        return aInd - bInd;
      } else {
        let aInd = viz.designSettings.tableOrder.indexOf(a.id);
        let bInd = viz.designSettings.tableOrder.indexOf(b.id);
        return aInd - bInd;
      }
    });

    for (let q of allQs) {
      if (q.matrixQuestion) {
        let theseQs = [];
        for (let option of q.matrixQuestion.options) {
          if (!all.includes(option)) {
            all.push(option);
            theseQs.push(option);
          }
        }
        Qs.push(theseQs);
      }
    }

    return Qs;
  }

  function showOnly(option) {
    let newViz = { ...viz };
    if (!newViz.designSettings.showOnly) {
      newViz.designSettings.showOnly = [option];
    } else {
      let ind = newViz.designSettings.showOnly.indexOf(option);
      if (ind > -1) {
        newViz.designSettings.showOnly.splice(ind, 1);
        if (!newViz.designSettings.showOnly.length) {
          delete newViz.designSettings.showOnly;
        }
      } else {
        newViz.designSettings.showOnly.push(option);
      }
    }

    updateViz(newViz);
  }

  return (
    <div>
      <Label
        style={{
          fontWeight: "700",
          paddingLeft: "20px",
          fontSize: ".9em",
        }}
      >
        Show only
      </Label>
      <div className={styles.showOnlyOptions}>
        {getMatrixQOptions().map((matrix) => (
          <div className={styles.pivots}>
            {matrix.map((option) => (
              <div className={`${styles.item} `}>
                <Checkbox
                  checked={
                    viz.designSettings.showOnly &&
                    viz.designSettings.showOnly.includes(option)
                  }
                  onChange={() => showOnly(option)}
                ></Checkbox>
                <span>{option}</span>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

const RankingSettings = ({ viz, changeSettingsField, updateViz }) => {
  function toggleStandardScale(val) {
    let newViz = { ...viz };

    newViz.designSettings.standardScale = val;
    if (newViz.designSettings.zeroHundredScale) {
      newViz.designSettings.zeroHundredScale = false;
    }
    updateViz(newViz);
  }

  function toggleZeroHundredScale(val) {
    let newViz = { ...viz };

    newViz.designSettings.zeroHundredScale = val;
    if (newViz.designSettings.standardScale) {
      newViz.designSettings.standardScale = false;
    }
    updateViz(newViz);
  }

  return (
    <>
      <div className={styles.setting2} style={{ paddingLeft: "15px" }}>
        <ToggleSwitch
          startChecked={viz.designSettings.scaleByRank}
          handleCheck={(val) => changeSettingsField("scaleByRank", val)}
        ></ToggleSwitch>
        <Label
          style={{
            fontWeight: "600",
            width: "fit-content",
            fontSize: ".85em",
          }}
          tooltipText="Each answer gets scaled by the inverse of its rank"
          tooltipStyle={{ color: "#738c91", left: "-100px" }}
          labelIcon={<i className="bi bi-info-circle"></i>}
        >
          Weight Counts by Rank
        </Label>
      </div>

      {viz.designSettings.scaleByRank && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            paddingLeft: "25px",
          }}
        >
          <div className={styles.setting2} style={{ gap: "0px" }}>
            <Checkbox
              checked={viz.designSettings.standardScale}
              onChange={(e) => toggleStandardScale(e.target.checked)}
            ></Checkbox>

            <Label
              style={{
                fontWeight: "600",
                width: "fit-content",
                fontSize: ".85em",
              }}
              tooltipText="Scaled from 1 to n (n being the number of choices - or the ranking limit)"
              tooltipStyle={{ color: "#738c91", left: "-100px" }}
              labelIcon={<i className="bi bi-info-circle"></i>}
            >
              Standard Scale
            </Label>
          </div>
          <div className={styles.setting2} style={{ gap: "0px" }}>
            <Checkbox
              checked={viz.designSettings.zeroHundredScale}
              onChange={(e) => toggleZeroHundredScale(e.target.checked)}
            ></Checkbox>
            <Label
              style={{
                fontWeight: "600",
                width: "fit-content",
                fontSize: ".85em",
              }}
              tooltipText="Scaled from 0 - 100. (If everyone ranked it as their first choice, the score would be 100"
              tooltipStyle={{ color: "#738c91", left: "-100px" }}
              labelIcon={<i className="bi bi-info-circle"></i>}
            >
              0-100 Scale
            </Label>
          </div>
        </div>
      )}
    </>
  );
};

function NormalFields({
  viz,
  changeSettingsField,
  projects,
  custom_fields,
  updateViz,
}) {
  function initColumns() {
    let fields = { ...viz.designSettings.tableFields };

    let changed = false;

    let allQs = combinedQs(viz);
    for (let q of allQs) {
      if (q.textQuestion && q.textQuestion.bucket) {
        for (let bucket of q.textQuestion.bucket) {
          if (viz.designSettings.separateBySurvey) {
            if (!(bucket.id in fields)) {
              fields[bucket.id] = {
                name: bucket.name,
                show: false,
                isBucket: true,
              };
              changed = true;
            }
          } else {
            if (!(bucket.id + "_" + q.id in fields)) {
              fields[bucket.id + "_" + q.id] = {
                name: bucket.name,
                show: false,
                isBucket: true,
                project: q.projectId,
              };
            }
          }
        }
      }
    }

    for (let field of custom_fields) {
      if (
        !fields[field.name] &&
        field.name !== "survey" &&
        field.filterable !== false
      ) {
        fields[field.name] = {
          name: field.displayName,
          show: false,
        };
        changed = true;
      }
    }

    let order = [...viz.designSettings.tableOrder];

    for (let field in fields) {
      if (!order.includes(field)) {
        changed = true;
        order.push(field);
      }
    }

    let sorted = getSortedArray(order, (a, b) => {
      if (fields[a].show) {
        if (!fields[b].show) {
          return -1;
        }
      } else if (fields[b].show) {
        return 1;
      }

      return 0;
    });
    if (
      changed ||
      JSON.stringify(sorted) !== JSON.stringify(viz.designSettings.tableOrder)
    ) {
      let copy = { ...viz };
      copy.designSettings.tableFields = fields;
      copy.designSettings.tableOrder = sorted;
      updateViz(copy);
    }
  }

  useEffect(() => {
    initColumns();
  }, []);

  function changeOrder(newOrder) {
    changeSettingsField("tableOrder", newOrder);
  }

  function changeFields(newFields) {
    changeSettingsField("tableFields", newFields);
  }

  return (
    <>
      <Columns
        fields={viz.designSettings.tableFields}
        order={viz.designSettings.tableOrder}
        changeOrder={changeOrder}
        changeFields={changeFields}
        projects={projects}
        viz={viz}
        updateViz={updateViz}
      />
    </>
  );
}

function PivotFields({ viz, changeSettingsField, projects }) {
  return (
    <Columns
      fields={viz.designSettings.tablePivotFields}
      order={viz.designSettings.tablePivotOrder}
      changeOrder={(newOrder) =>
        changeSettingsField("tablePivotOrder", newOrder)
      }
      changeFields={(newFields) =>
        changeSettingsField("tablePivotFields", newFields)
      }
      projects={projects}
      viz={viz}
    />
  );
}

function ToLastFields({
  viz,
  changeSettingsField,
  projects,
  custom_fields,
  updateViz,
}) {
  function initFields() {
    let newViz = { ...viz };

    let fields = viz.designSettings.toLastFields
      ? { ...viz.designSettings.toLastFields }
      : {};

    let changed = false;

    let allQs = combinedQs(viz);
    for (let q of allQs) {
      if (q.textQuestion && q.textQuestion.bucket) {
        for (let bucket of q.textQuestion.bucket) {
          if (!(bucket.id in fields)) {
            fields[bucket.id] = {
              name: bucket.name,
              show: false,
              isBucket: true,
            };
            changed = true;

            fields["last" + bucket.id] = {
              name: "Last - " + bucket.name,
              show: false,
              isBucket: true,
            };
          }
        }
      }
    }

    for (let field of custom_fields) {
      if (
        !fields[field.name] &&
        field.name !== "survey" &&
        field.filterable !== false
      ) {
        fields[field.name] = {
          name: field.displayName,
          show: false,
        };
        changed = true;
      }
    }

    let order = viz.designSettings.toLastOrder
      ? [...viz.designSettings.toLastOrder]
      : [];

    for (let field in fields) {
      if (!order.includes(field)) {
        order.push(field);
      }
    }
    let sorted = getSortedArray(order, (a, b) => {
      if (fields[a].show) {
        if (!fields[b].show) {
          return -1;
        }
      } else if (fields[b].show) {
        return 1;
      }

      return 0;
    });
    if (
      JSON.stringify(sorted) !== JSON.stringify(viz.designSettings.toLastOrder)
    ) {
      changed = true;
    }

    if (changed) {
      newViz.designSettings.toLastFields = fields;
      newViz.designSettings.toLastOrder = sorted;
      updateViz(newViz);
    }
  }

  useEffect(() => {
    initFields();
  }, []);

  function changeOrder(newOrder) {
    changeSettingsField("toLastOrder", newOrder);
  }

  function changeFields(newFields) {
    changeSettingsField("toLastFields", newFields);
  }

  function hasScale() {
    for (let q of combinedQs(viz)) {
      if (q.scaleQuestion) {
        return true;
      }
    }
    return false;
  }

  return (
    <>
      {hasScale() && (
        <div
          className={styles.setting2}
          style={{ padding: "0px 0px 5px 15px" }}
        >
          <ToggleSwitch
            startChecked={viz.designSettings?.sideBySide}
            handleCheck={(val) => changeSettingsField("sideBySide", val)}
          ></ToggleSwitch>
          <Label
            style={{
              fontWeight: "500",
              width: "fit-content",
              fontSize: ".85em",
            }}
          >
            Show Side by Side
          </Label>
          <div className={styles.sideBySideExample}>
            <div className={styles.last}>5</div>
            <i className="bi bi-arrow-up-right"></i>
            10
          </div>
        </div>
      )}
      <Columns
        fields={viz.designSettings.toLastFields}
        order={viz.designSettings.toLastOrder}
        changeOrder={changeOrder}
        changeFields={changeFields}
        projects={projects}
        viz={viz}
        updateViz={updateViz}
      />
    </>
  );
}

function Columns({
  fields,
  order,
  changeOrder,
  changeFields,
  projects,
  viz,
  updateViz,
}) {
  function handleDragOver({ active, over }) {
    if (active.id !== over?.id) {
      let indexOfActive = order.indexOf(active.id);
      let indexOfOver = order.indexOf(over.id);
      let newOrder = [...order];
      newOrder.splice(indexOfActive, 1, over.id);
      newOrder.splice(indexOfOver, 1, active.id);
      changeOrder(newOrder);
    }
  }

  function editName(field, val) {
    let newFields = { ...fields };
    newFields[field].name = val;
    changeFields(newFields);
  }

  function checkField(e, field) {
    let newFields = { ...fields };
    newFields[field].show = e.target.checked;
    changeFields(newFields);
  }

  const [highlight, setHighlight] = useState("");

  function mergeWith(field, mergeName) {
    let newViz = { ...viz };
    let newFields = { ...fields };
    let newOrder = [...order];

    newFields[mergeName].contains = [
      ...newFields[mergeName].contains,
      ...newFields[field].contains,
    ];
    delete newFields[field];

    let ind = newOrder.indexOf(field);
    newOrder.splice(ind, 1);

    newViz.designSettings.tableFields = newFields;
    newViz.designSettings.tableOrder = newOrder;
    updateViz(newViz);
  }

  return (
    <>
      <div
        style={{
          maxHeight: "550px",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          // gap: "1em",
          gap: "5px",
        }}
      >
        <Label
          style={{
            fontWeight: "700",
            width: "fit-content",
            paddingLeft: "20px",
            fontSize: ".9em",
          }}
          // labelIcon={<i className="bi bi-question-square"></i>}
          // iconPosition="right"
          // tooltipText="New fields are added when you upload contacts with fields that you don't already have."
        >
          Columns
        </Label>
        <DndContext
          // sensors={sensors}
          collisionDetection={closestCenter}
          // onDragEnd={handleDragEnd}
          onDragOver={handleDragOver}
          modifiers={[restrictToVerticalAxis, restrictToParentElement]}
        >
          <SortableContext
            id={"Table Fields"}
            items={order}
            strategy={verticalListSortingStrategy}
          >
            {order.map((field) => {
              if (fields[field]) {
                return (
                  <TableField
                    key={field}
                    field={field}
                    fields={fields}
                    editName={editName}
                    checkField={checkField}
                    projects={projects}
                    viz={viz}
                    highlight={highlight === field}
                    setHighlight={(name) => setHighlight(() => name)}
                    mergeWith={(name) => mergeWith(field, name)}
                    changeFields={changeFields}
                  />
                );
              }
            })}
          </SortableContext>
        </DndContext>
      </div>
    </>
  );
}

function TableField({
  field,
  fields,
  editName,
  checkField,
  projects,
  viz,
  highlight,
  setHighlight,
  mergeWith,
  changeFields,
}) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: field });

  const [show, setShow] = useState(false);
  const [showBucket, setShowBucket] = useState(false);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.9 : 1,
    zIndex: isDragging ? 10 : 0,
    backgroundColor: highlight ? "#efefef" : show ? "#F9F9F9" : undefined,
  };

  function getProjectName(id) {
    let survey = projects.find((s) => s.id === id);
    return survey?.name;
  }

  function couldBeMergeable() {
    if (fields[field].contains) {
      let possible = getMergeableFields();

      return possible.length ? true : false;
    }
    return false;
  }

  function getMergeableFields() {
    let possible = [];

    let qId = fields[field].contains[0];
    const vizQs = combinedQs(viz);
    let q = vizQs.find((q) => q.id === qId);

    //check to see if there is another contains column that has qs with the same type
    for (let key in fields) {
      if (key !== field && fields[key].contains) {
        let otherId = fields[key].contains[0];
        let otherQ = vizQs.find((qst) => qst.id === otherId);
        if (getQuestionType(otherQ) === getQuestionType(q)) {
          possible.push(key);
        }
      }
    }

    return possible;
  }

  function checkMergeWith(otherField) {
    if (
      confirm(
        `Confirm merge of '${fields[field].name}' with '${fields[otherField].name}'. This action cannot be undone.`
      )
    ) {
      mergeWith(otherField);
      setShow(false);
      setHighlight("");
    }
  }

  function onFieldEdit() {
    changeFields(fields);
  }

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      style={{ ...style }}
      className={styles.tableColumn}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
        <Checkbox
          checked={fields[field].show}
          onChange={(e) => checkField(e, field)}
        ></Checkbox>

        <input
          type="text"
          placeholder={fields[field].name}
          className={styles.tableColumnNameInput}
          value={fields[field].name}
          onChange={(e) => editName(field, e.target.value)}
          style={{
            backgroundColor: highlight
              ? "#efefef"
              : show
              ? "#F9F9F9"
              : undefined,
          }}
        ></input>

        {viz.designSettings.separateBySurvey &&
          !viz.designSettings.toLast &&
          couldBeMergeable() && (
            <i
              className={show ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"}
              style={{ color: "#a3a4a8", cursor: "pointer" }}
              onClick={() => setShow(!show)}
            ></i>
          )}

        {fields[field].isBucket && fields[field].show && (
          <i
            className={
              showBucket ? "bi bi-caret-up-fill" : "bi bi-caret-down-fill"
            }
            style={{ color: "#a3a4a8", cursor: "pointer" }}
            onClick={() => setShowBucket(!showBucket)}
          ></i>
        )}

        <div
          className={styles.grid}
          {...listeners}
          style={{ cursor: isDragging ? "grabbing" : "grab", fontSize: "10pt" }}
        >
          <i
            className="bi bi-list"
            style={{
              color: `${fields[field].show ? "#616565" : "#d8d9d9"}`,
            }}
          ></i>
        </div>
      </div>

      {fields[field].project && combinedProjIds(viz).length > 1 && (
        <div className={styles.surveySubtitle}>
          {getProjectName(fields[field].project)}
        </div>
      )}

      {show && fields[field].show && (
        <div className={styles.mergeContainer}>
          <div className={styles.mergeWith}>Merge with:</div>
          {getMergeableFields().map((otherField) => (
            <div
              className={styles.mergeableField}
              onMouseEnter={() => setHighlight(otherField)}
              onMouseLeave={() => setHighlight("")}
              onClick={() => checkMergeWith(otherField)}
            >
              {fields[otherField].name}
            </div>
          ))}
        </div>
      )}

      {showBucket && fields[field].show && (
        <BucketOptions
          field={field}
          fields={fields}
          changeFields={changeFields}
          viz={viz}
        />
      )}
    </div>
  );
}

function BucketOptions({ fields, field, changeFields, viz }) {
  function editBucketField(val, bucketField) {
    fields[field][bucketField] = val;
    changeFields(fields);
  }

  function getBucketId() {
    if (viz.designSettings.separateBySurvey) {
      return field;
    } else if (viz.designSettings.toLast) {
      if (field.includes("last")) {
        return field.slice(4);
      }
      return field;
    } else {
      let ind = field.indexOf("_");
      return field.slice(0, ind);
    }
  }

  const fetchBucket = useFetchBucket(getBucketId());

  function editFilter(prop, val) {
    if (!fields[field].filter) {
      fields[field].filter = [];
    }

    if (val) {
      fields[field].filter.push(prop);
    } else {
      let ind = fields[field].filter.indexOf(prop);
      if (ind > -1) fields[field].filter.splice(ind, 1);

      if (!fields[field].filter.length) {
        delete fields[field].filter;
      }
    }

    changeFields(fields);
  }

  return (
    <div className={styles.bucketSettings}>
      <div className={styles.setting2}>
        <SmallCheckbox
          checked={!fields[field].hideOverall}
          onChange={(val) => editBucketField(!val, "hideOverall")}
        ></SmallCheckbox>
        Bucket
      </div>

      {!fields[field].hideOverall && (
        <OneOrTheOther
          onCheck={(val) => editBucketField(val, "includeAll")}
          checked={fields[field]?.includeAll}
          left="Overall"
          right="Any"
          style={{paddingLeft: '10px', fontSize: ".95em", paddingBottom: '5px'}}
        />
      )}

      <div className={styles.setting2}>
        <SmallCheckbox
          checked={fields[field].acc}
          onChange={(val) => editBucketField(val, "acc")}
        ></SmallCheckbox>
        <input
          type="text"
          placeholder={"Accuracy"}
          className={styles.tableColumnNameInput}
          value={
            "accName" in fields[field] ? fields[field].accName : "Accuracy"
          }
          onChange={(e) => editBucketField(e.target.value, "accName")}
        ></input>
      </div>
      <div className={styles.setting2}>
        <SmallCheckbox
          checked={fields[field].breakdown}
          onChange={(val) => editBucketField(val, "breakdown")}
        ></SmallCheckbox>
        <input
          type="text"
          placeholder={"Breakdown"}
          className={styles.tableColumnNameInput}
          value={"bName" in fields[field] ? fields[field].bName : "Breakdown"}
          onChange={(e) => editBucketField(e.target.value, "bName")}
        ></input>
      </div>

      {fields[field].breakdown &&
        fetchBucket.isSuccess &&
        fetchBucket.data.bucket && (
          <div className={styles.col} style={{ paddingLeft: "7px" }}>
            {fetchBucket.data.bucket.properties.map((prop) => (
              <div className={styles.setting2}>
                <SmallCheckbox
                  checked={
                    fields[field].filter
                      ? fields[field].filter.includes(prop)
                      : false
                  }
                  onChange={(val) => editFilter(prop, val)}
                ></SmallCheckbox>
                {prop}
              </div>
            ))}
          </div>
        )}
    </div>
  );
}
