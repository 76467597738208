export function SurveyLogo({ settings, source }) {

  function getMaxWidth() {
    let logoContainer = document.getElementById("logoContainer");
    if (logoContainer) {
      let maxWidth = logoContainer.clientWidth - 32; // - 16 pixels of padding on each side (16 = 1em)
      return maxWidth;
    }
    return "300px"; // max width for the widest possible container
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent:
          settings.logoAlign == "center"
            ? "center"
            : settings.logoAlign == "right"
            ? "end"
            : "start",

        padding: "16px",
        borderRadius: "1em",
        backgroundColor: settings.logoBackgroundColor
          ? settings.logoBackgroundColor
          : "#efefef",
        boxShadow: settings?.logoBoxShadow
          ? "rgba(0, 0, 0, 0.15) 0px 5px 15px"
          : "",

          
      }}
      id="logoContainer"
    >
      {source && (
        <img
          style={{ height: settings.logoSize}}
          src={source}
          alt="companyLogo"
        />
      )}
    </div>
  );
}
