import styles from "./SurveyWidget.module.scss";
import BigMetric from "./BigMetric";
import SmallMetrics from "./SmallMetrics";
import { ChartWidget } from "./ChartWidget";
import { useState, useEffect } from "react";

export default function SurveyWidget({ widget, setAbove, inSettings, canEdit}) {
  const [reDraw, setReDraw] = useState(0);

  useEffect(() => {
    setReDraw(reDraw + 1);
  }, [widget.settings?.showName]);

  function getTitleId() { 
    let it = "title of " + widget.id;
    if (inSettings) it += " in settings";
    return it;
  }

  return (
    <div
      className={styles.container}
      style={
        widget.settings?.charts?.length > 1
          ? { padding: "0px 20px" }
          : { padding: "0px" }
      }
    >
      {widget.settings?.showName && (
        <div className={styles.title} id={getTitleId()}>{widget.name}</div>
      )}
      <div className={styles.widgetPart}>
        {widget.settings?.metrics?.small && (
          <SmallMetrics settings={widget.settings} id={widget.id} />
        )}
        {widget.settings?.metrics?.big && (
          <BigMetric settings={widget.settings} id={widget.id} titleId={getTitleId()}/>
        )}
        {widget.settings?.charts && (
          <ChartWidget
            charts={widget.settings.charts}
            setAbove={setAbove}
            inSettings={inSettings}
            reDraw={reDraw}
            reload={() => setReDraw(reDraw + 1)}
            canEdit={canEdit}
          />
        )}
      </div>
    </div>
  );
}
