/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/

import { useEffect, useState } from "react";
import styles from "./Legend.module.scss";
import {
  DoughnutType,
  LineType,
  PieType,
} from "pages/results/Charts/QuestionChart";
import { forEach } from "assets/functions/ArrayFunctions";

export default function CustomLegend({
  data,
  onClick,
  visible,
  settings,
  type,
  id,
  thumbnail,
  title,
}) {
  function getPercentOfTotal(value) {
    let sum = 0;
    if (data.datasets.length > 0) {
      data.datasets[0].data.map((data) => {
        sum += data;
      });

      let percentage =
        ((value * 100) / sum).toFixed(settings.dataLabelSigFig) + "%";
      if (percentage === "0%" && value > 0) {
        percentage = ((value * 100) / sum).toFixed(1) + "%";
      }
      return percentage;
    } else {
      return null;
    }
  }

  function getLabel(i) {
    let labels = [];
    if (settings.legendLabel) {
      labels.push(data.labels[i]);
    }
    if (settings.legendValue) {
      let val = data.datasets[0].data[i];
      if (settings.byPercent) {
        val += "%";
      }
      labels.push(val);
    }
    if (settings.legendPercent && !settings.byPercent) {
      let prct = getPercentOfTotal(data.datasets[0].data[i]);
      labels.push(prct);
    }

    let string = "";
    forEach(labels, (label, ind) => {
      if (ind !== 0 && ind < labels.length) {
        if (string >= "A" && string <= "z") {
          // if is Alpha Numeric
          string += " ";
        } else {
          string += " | "; // Put this in between the numbers
        }
        // string += " ";
      }
      string += label;
    });

    return string;
  }

  const initLabels = () => {
    if (data?.datasets && data.datasets.length > 0) {
      if (
        data.datasets.length > 1 ||
        (data.datasets[0] &&
          "label" in data.datasets[0] &&
          data.datasets[0].label)
      ) {
        return data.datasets.map((set, ind) => {
          return {
            label: "label" in set ? set.label : "",
            color: set.backgroundColor[0],
            hidden: false,
          };
        });
      } else {
        return data.labels.map((label, ind) => {
          return {
            label: getLabel(ind),
            color: data.datasets[0].backgroundColor[ind],
            hidden: false,
          };
        });
      }
    } else {
      return null;
    }
  };

  const [labels, setLabels] = useState(initLabels());

  useEffect(() => setLabels(initLabels), [data]);

  function handleClick(i) {
    visible[i] = !visible[i];
    onClick(i);
  }

  const pos = settings.legendPosition;

  function getColor(item) {
    if (
      type === LineType &&
      settings.hasUniformPointColor &&
      !(data.datasets.length > 1 || data.datasets[0].label)
    ) {
      return settings.uniformPointColor;
    } else {
      return item.color;
    }
  }

  function getWidth() {
    let chart = document.getElementById("chart" + id);
    let box = document.getElementById("legendNChart" + id);
    let legendContainer = document.getElementById("legend container for " + id);
    if (chart && box && legendContainer) {
      if (legendContainer.clientWidth >= 150) {
        let h = Math.floor(box.clientHeight * 0.95);
        let w = box.clientWidth - 150;

        let min = Math.min(h, w);
        let leftOver = box.clientWidth - min;
        return leftOver + "px";
      }
    }

    return "150px";
  }

  const circle = type === PieType || type === DoughnutType;

  return (
    <div
      className={styles.container}
      style={
        pos === "top"
          ? {
              paddingBottom: thumbnail
                ? "4px"
                : type === PieType || type === DoughnutType
                ? "10px"
                : "0px",
              width: "100%",
              maxHeight: thumbnail ? "17px" : "25%",
            }
          : pos === "right"
          ? {
              paddingLeft: thumbnail ? "0px" : "10px",
              height: "100%",
              maxWidth: circle ? getWidth() : "150px",
              alignItems: "start",
              paddingRight: thumbnail ? "10px" : "",
            }
          : pos === "left"
          ? {
              paddingRight: thumbnail ? "0px" : "10px",
              height: "100%",
              maxWidth: circle ? getWidth() : "150px",
            }
          : pos === "bottom"
          ? {
              paddingTop: thumbnail
                ? "4px"
                : type === PieType || type === DoughnutType
                ? "10px"
                : "0px",
              width: "100%",
              maxHeight: thumbnail ? "17px" : "25%",
            }
          : {}
      }
      id={"legend container for " + id}
    >
      {settings.hasLegendTitle && !thumbnail && (
        <div
          className={styles.title}
          style={{ fontSize: thumbnail ? ".6em" : settings.legendFontSize }}
        >
          {settings.legendTitle}
        </div>
      )}
      <div
        className={`${styles.legend} ${
          pos === "top" || pos === "bottom" ? styles.row : styles.column
        }`}
        style={{ gap: thumbnail ? "1px" : "" }}
        id={"legend for " + id}
      >
        {labels &&
          labels.map((item, i) => (
            <div key={i} className={styles.item} onClick={() => handleClick(i)}>
              <div
                className={thumbnail ? styles.littleColor : styles.color}
                style={{
                  backgroundColor: getColor(item),
                  height: thumbnail ? "5px" : "",
                  width: thumbnail ? "5px" : "",
                }}
              ></div>
              <div
                className={`${styles.text} ${
                  thumbnail ? styles.smallText : ""
                }`}
                style={
                  !visible[i]
                    ? {
                        textDecoration: "line-through",
                        textDecorationThickness: "2px",
                      }
                    : undefined
                }
              >
                <span>{item.label}</span>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
