import { useState, useRef } from "react";
import { useUpdateColorPalette } from "api/resources/organization/colorpalettes";
import { Label } from "components/layouts/Label/Label";
import styles from "../../SettingsAccordion.module.scss";
import segStyles from "./SegmentSettings.module.scss";
import { SegColors } from "./SegColors";
import PalettePicker from "./PalettePicker";
import { AddColorSimple } from "components/ColorPalette/EditColors/AddColorSimple";
import {
  getGradientColors,
  getGradientLengths,
  getTheOffWhite,
} from "assets/functions/ColorFunctions";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import { getSortedArray } from "assets/functions/ArrayFunctions";
import { NoSort } from "pages/results/Charts/QuestionChart";

export const SegmentSettings = ({
  viz,
  changeSettingsField,
  updateSettings,
  labels,
}) => {
  const [tentativeGradient, setTentativeGradient] = useState(false);

  const [wasPalette, setWasPalette] = useState(
    !viz.designSettings.colors && !viz.designSettings.gradient
  );

  const before = useRef();

  function changePalette(chosen) {
    let settings = { ...viz.designSettings };
    settings.paletteId = chosen.isDefault ? "default" : chosen.id;
    settings.colors = undefined;
    settings.gradient = undefined;
    updateSettings(settings);
    setWasPalette(true);
  }

  const usingTags =
    viz.pivotString === "survey tag" ||
    viz.designSettings.split === "survey tag";

  const exampleGradient = [
    // "#15bcc7",
    // "#3cc7d0",
    "#63d2da",
    "#8adee3",
    "#b1e9ed",
    "#d8f4f6",
  ];

  function reProportion(data, blended) {
    let anchors = [];

    if (blended) {
      // need lengths
      let extras = 0;
      let anchor = { ...data[0], length: 0 };
      for (let i = 1; i < data.length; i++) {
        if (data[i].anchor) {
          anchors.push(anchor);
          anchor = { ...data[i], length: 0 };
        } else {
          extras++;
          anchor.length++;
        }
      }

      for (let anchor of anchors) {
        anchor.portion = Math.round((anchor.length / extras) * 100);
      }

      anchors.push({ ...data[data.length - 1], portion: 0 });
    } else {
      // already has lengths
      for (let i = 0; i < data.length; i++) {
        if (data[i].anchor) {
          anchors.push({
            color: data[i].color,
            length: data[i].length,
            reversed: data[i].reversed ? true : false,
          });
        }
      }

      for (let anchor of anchors) {
        anchor.portion = Math.round((anchor.length / data.length) * 100);
      }
    }

    for (let anchor of anchors) {
      delete anchor.length;
    }

    let gradient = { ...viz.designSettings.gradient };
    gradient.anchors = anchors;
    // console.log(anchors);
    return gradient;
  }

  function getGradients() {
    let data = getGradientLengths(
      viz.designSettings.gradient.anchors,
      viz.designSettings.gradient.blended,
      labels.segLabels.length
    );

    let gradients = [];

    if (viz.designSettings.gradient.blended) {
      let pairs = [];
      for (let i = 1; i < data.length; i++) {
        let pair = {
          one: data[i - 1].color,
          two: data[i].color,
          length: data[i - 1].length,
        };
        pairs.push(pair);
      }

      for (let pair of pairs) {
        let spread = getGradientColors(pair.one, pair.two, pair.length + 2);
        gradients.push(spread);
      }
    } else {
      for (let anchor of data) {
        let offWhite = getTheOffWhite(anchor.color, anchor.length);
        let spread = getGradientColors(anchor.color, offWhite, anchor.length);
        if (anchor.reversed) {
          spread.reverse();
        }
        gradients.push(spread);
      }
    }

    return gradients;
  }

  function switchGradientOrder(ind) {
    let copy = [...viz.designSettings.gradient.anchors];

    if (viz.designSettings.gradient.blended) {
      let x = copy[ind].color;
      copy[ind].color = copy[ind + 1].color;
      copy[ind + 1].color = x;
    } else {
      copy[ind].reversed = copy[ind].reversed ? false : true;
    }

    let gradient = { ...viz.designSettings.gradient };
    gradient.anchors = copy;
    changeSettingsField("gradient", gradient);
  }

  function getCurrentColors() {
    if (viz.designSettings.gradient) {
      let data = getGradientLengths(
        viz.designSettings.gradient.anchors,
        viz.designSettings.gradient.blended,
        labels.segLabels.length
      );

      let colors = [];
      if (viz.designSettings.gradient.blended) {
        let pairs = [];
        for (let i = 1; i < data.length; i++) {
          let pair = {
            one: data[i - 1].color,
            two: data[i].color,
            length: data[i - 1].length,
          };
          pairs.push(pair);
        }

        colors.push({ color: data[0].color, anchor: true });
        for (let pair of pairs) {
          let spread = getGradientColors(pair.one, pair.two, pair.length + 2); // length refers to the gap amounts

          for (let i = 1; i < spread.length; i++) {
            colors.push({
              color: spread[i],
              anchor: i == spread.length - 1,
            });
          }
        }
      } else {
        let num = 0;
        for (let anchor of data) {
          let offWhite = getTheOffWhite(anchor.color, anchor.length);
          let spread = getGradientColors(anchor.color, offWhite, anchor.length);
          if (anchor.reversed) {
            spread.reverse();
          }
          for (let i = 0; i < spread.length; i++) {
            let it = {
              color: spread[i],
              anchor: anchor.reversed ? i == spread.length - 1 : !i,
              realInd: anchor.reversed
                ? num + (spread.length - 1 - i)
                : num + i,
            };
            if (it.anchor) {
              it.reversed = anchor.reversed;
              it.length = spread.length;
            }
            colors.push(it);
          }

          num += spread.length;
        }
      }

      // console.log(colors);
      return colors;
    }
    return undefined;
  }

  function checkSingleTones() {
    if (viz.designSettings.gradient.blended) {
      let data = getCurrentColors();
      data[data.length - 1].anchor = false;

      // got to put lengths in it.
      // since it's coming from blended, it will keep anchors in order.
      let count = 1;
      let lastAnchorInd = 0;
      for (let i = 1; i < data.length; i++) {
        if (data[i].anchor) {
          data[lastAnchorInd].length = count;
          lastAnchorInd = i;
          count = 1;
        } else {
          count++;
        }
      }
      data[lastAnchorInd].length = count;

      let gradient = reProportion(data, false);
      gradient.blended = false;
      changeSettingsField("gradient", gradient);
    }
  }

  function checkBlend() {
    if (!viz.designSettings.gradient.blended) {
      let data = getCurrentColors();
      data[data.length - 1].anchor = true;

      let gradient = reProportion(data, true);
      gradient.blended = true;
      changeSettingsField("gradient", gradient);
    }
  }

  function getTwoAnchorColors() {
    let anchors = viz.designSettings.gradient.anchors;
    let them = {
      one: anchors[0].color,
      two: anchors[1] ? anchors[1].color : undefined,
    };
    return them;
  }

  function getSingleTones() {
    let anchors = getTwoAnchorColors();
    let offOne = getTheOffWhite(anchors.one, 3);
    let gradient = getGradientColors(anchors.one, offOne, 3);
    if (anchors.two) {
      let offTwo = getTheOffWhite(anchors.two, 3);
      let gradTwo = getGradientColors(anchors.two, offTwo, 3);
      gradient = [...gradient, ...gradTwo];
    } else {
      gradient = [...gradient, ...gradient];
    }
    return gradient;
  }

  function getBlended() {
    let anchors = getTwoAnchorColors();
    if (anchors.two) {
      return getGradientColors(anchors.one, anchors.two, 6);
    }
    let offWhite = getTheOffWhite(anchors.one, 6);
    return getGradientColors(anchors.one, offWhite, 6);
  }

  function onStartGradient(color) {
    before.current = null;
    setTentativeGradient(false);
  }

  function stopGradient() {
    if (viz.designSettings.sort !== NoSort) {
      viz.designSettings.afterSort = true;
    }

    let names = getSortedArray(
      labels.segLabels,
      (a, b) => a.sorted - b.sorted
    ).map((seg) => seg.label);

    debugger;
    let newColors = getSortedArray(
      labels.segLabels,
      (a, b) => a.sorted - b.sorted
    ).map((seg) => seg.color);

    let settings = { ...viz.designSettings };
    settings.gradient = undefined;
    settings.colors = newColors;
    updateSettings(settings);
  }

  function onTentativeGradient(color) {
    let settings = { ...viz.designSettings };
    if (viz.designSettings.colors) {
      before.current = viz.designSettings.colors;
      settings.colors = undefined;
    }
    let gradient = {};
    gradient.anchors = [];
    gradient.blended = false;
    gradient.anchors.push({
      color: color,
      portion: 100,
    });

    settings.gradient = gradient;
    updateSettings(settings);
    setTentativeGradient(true);
  }

  function onTentativeChange(color) {
    if (viz.designSettings.gradient) {
      let gradient = { ...viz.designSettings.gradient };
      gradient.anchors[0].color = color;
      changeSettingsField("gradient", gradient);
    } else {
      onTentativeGradient(color);
    }
  }

  function onNevermindGradient() {
    let settings = { ...viz.designSettings };
    settings.gradient = undefined;
    if (before.current) {
      settings.colors = before.current;
      before.current = null;
    }
    updateSettings(settings);
    setTentativeGradient(false);
  }

  // TODO: Edit Palette, Line Chart Settings

  return (
    <>
      <div
        style={{
          display: "flex",
          gap: "20px",
          width: "100%",
        }}
      >
        <Label
          style={{
            textAlign: "center",
            justifyContent: "center",
            display: "flex",
            width: "fit-content",
          }}
        >
          Palette
        </Label>

        <PalettePicker viz={viz} onChange={changePalette} />
      </div>

      {viz.designSettings.gradient && !tentativeGradient && (
        <div className={segStyles.gradientSection}>
          <div className={segStyles.gradientSettings}>
            <div className={segStyles.gradientSetting}>
              <Checkbox
                checked={!viz.designSettings.gradient.blended}
                onChange={checkSingleTones}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingLeft: "10px",
                  gap: "5px",
                }}
              >
                <div className={segStyles.settingName}>Single Tones</div>
                <div className={segStyles.gradientColors}>
                  {getSingleTones().map((g) => (
                    <div
                      className={segStyles.gradientColor}
                      style={{ backgroundColor: g }}
                    ></div>
                  ))}
                </div>
              </div>
            </div>
            <div className={segStyles.gradientSetting}>
              <Checkbox
                checked={viz.designSettings.gradient.blended}
                onChange={checkBlend}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  paddingLeft: "10px",
                  gap: "5px",
                }}
              >
                <div className={segStyles.settingName}>Blended</div>
                <div className={segStyles.gradientColors}>
                  {getBlended().map((g) => (
                    <div
                      className={segStyles.gradientColor}
                      style={{ backgroundColor: g }}
                    ></div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className={segStyles.gradients}>
            <Label
              style={{
                width: "fit-content",
                padding: "0",
              }}
            >
              Gradients
            </Label>
            {getGradients().map((gradient, ind) => (
              <div className={segStyles.gradientSegment}>
                <div className={segStyles.gradientColors}>
                  {gradient.map((color) => (
                    <div
                      className={segStyles.gradientColor}
                      style={{ backgroundColor: color }}
                    ></div>
                  ))}
                </div>

                <div
                  className={segStyles.switch}
                  style={{ cursor: "pointer" }}
                  onClick={
                    gradient.length > 1
                      ? () => switchGradientOrder(ind)
                      : undefined
                  }
                >
                  <i
                    className="bi bi-arrow-repeat"
                    style={{ marginRight: "5px" }}
                  ></i>
                  switch
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <div
        className={styles.setting}
        style={{ alignItems: "flex-start", paddingLeft: "10px" }}
      >
        <Label style={{ padding: "0px 0px 5px 0px" }}>Segment Colors</Label>

        {labels && (
          <SegColors
            labels={labels}
            changeSettingsField={changeSettingsField}
            viz={viz}
            gradientColors={getCurrentColors()}
            gradientMode={viz.designSettings.gradient && !tentativeGradient}
            reProportion={reProportion}
            wasPalette={wasPalette}
            setWasPalette={setWasPalette}
            // disableColors
          ></SegColors>
        )}
      </div>

      {/* {usingTags && (
            <div
              className={styles.row}
              style={{ margin: "5px 0px 10px", gap: "15px" }}
            >
              <Label
                italics
                style={{
                  fontWeight: "500",
                  fontSize: ".8em",
                  paddingLeft: "40px",
                  width: "fit-content",
                }}
              >
                Use Tag Color
              </Label>
              <ToggleSwitch
                startChecked={viz.designSettings?.useTagColor}
                handleCheck={(val) => changeSettingsField("useTagColor", val)}
              ></ToggleSwitch>
            </div>
          )} */}

      {/* <div className={segStyles.toggle}>
              <ToggleSwitch
                startChecked={gradientMode}
                handleCheck={(val) => setGradientMode(val)}
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "0px 10px",
                  gap: "5px",
                }}
              >
                <Label
                  style={{
                    width: "fit-content",
                    fontSize: ".9em",
                    padding: "0",
                    textTransform: "none",
                  }}
                >
                  Gradient Mode
                </Label>
                <div className={segStyles.exampleGradient}>
                  {exampleGradient.map((g) => (
                    <div
                      className={segStyles.gradientColor}
                      style={{ backgroundColor: g }}
                    ></div>
                  ))}
                </div>
              </div>
            </div> */}

      {/* {gradientMode && !viz.designSettings.gradient && labels && (
              <div className={segStyles.startingColor}>
                Starting Color
                <AddColorSimple
                  wording="Start"
                  onDone={onStartGradient}
                  onlyOnConfirm
                />
              </div>
            )} */}

      {(!viz.designSettings.gradient || tentativeGradient) && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignSelf: "flex-start",
            gap: "5px",
            paddingLeft: "15px",
          }}
        >
          <div className={segStyles.startGradient}>
            <Label style={{ width: "fit-content", paddingLeft: "0px" }}>
              Build Gradient
            </Label>
            <AddColorSimple
              wording="Start"
              onDone={onStartGradient}
              onlyOnConfirm
              onChange={onTentativeChange}
              onCancel={onNevermindGradient}
            />
          </div>

          {/* <div className={segStyles.exampleGradient}>
                {exampleGradient.map((g) => (
                  <div
                    className={segStyles.gradientColor}
                    style={{ backgroundColor: g }}
                  ></div>
                ))}
              </div> */}
        </div>
      )}

      {viz.designSettings.gradient && !tentativeGradient && (
        <>
          <div className={segStyles.stopGradient} onClick={stopGradient}>
            Stop Gradient <i className="bi bi-x"></i>
          </div>
        </>
      )}
    </>
  );
};

export function ColorManipulation({
  viz,
  changePallet,
  onPalletColorChange,
  noLabel,
}) {
  const updateColorPallet = useUpdateColorPalette();
  const [save, setSave] = useState(false);
  // const [pallets, setPallets] = useState(initPallets);
  const [creatingNew, setCreatingNew] = useState(false);

  function updatePallet(newPallet, dontSave) {
    // let newPallets = [...pallets];
    // let index = newPallets.findIndex((pallet) => pallet.id === newPallet.id);
    // newPallets[index] = newPallet;
    // setPallets(newPallets);
    if (!dontSave) {
      setSave(true);
    }

    onPalletColorChange(newPallet);
  }

  function savePallet(newPallet) {
    updateColorPallet.mutate(
      {
        data: { colors: JSON.stringify(newPallet.colors) },
        id: newPallet.id,
      },
      {
        onSuccess: () => {
          setSave(false);
        },
      }
    );
  }

  function onCancel() {
    // setPallets(initPallets);
    let origPallet = initPallets.find(
      (pallet) => pallet.id === viz?.designSettings?.currPalletId
    );
    if (origPallet) {
      origPallet.colors =
        typeof origPallet?.colors === "string"
          ? JSON.parse(origPallet?.colors)
          : origPallet?.colors;
      onPalletColorChange(origPallet);
    }
    setSave(false);
    setCreatingNew(false);
  }

  function createPalletClick() {
    setCreatingNew(true);
  }

  function onPalletCreated(pallet) {
    debugger;
    // if (pallet) {
    //   pallet.colors = JSON.parse(pallet?.colors);
    //   changePallet(pallet);
    //   let newPallets = [...pallets];
    //   newPallets.push(pallet);
    //   // setPallets(newPallets);
    //   setCreatingNew(false);
    // }
  }

  function onPalletSelect(pallet) {
    // debugger;
    setSave(false);
    // setPallets(initPallets);
    changePallet(pallet);
  }

  return (
    <>
      {!creatingNew && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: ".5em",
            alignItems: "center",
            // paddingLeft: '10px'
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "20px",
              // alignItems: "center",
              // paddingRight: "0.5"
              width: "100%",
            }}
          >
            <Label
              style={{
                fontWeight: "500",
                fontSize: ".9em",
                textAlign: "center",
                justifyContent: "center",
                display: "flex",
                width: "fit-content",
                display: noLabel ? "none" : "",
                paddingLeft: "20px",
              }}
            >
              Palette
            </Label>

            {/* <SelectField
              value={pallets.find(
                (pallet) => pallet.id === viz?.designSettings?.currPalletId
              )}
              options={pallets}
              onChange={onPalletSelect}
            ></SelectField> */}

            <PalettePicker
              currPalletId={viz?.designSettings?.currPalletId}
              onChange={onPalletSelect}
            />
          </div>
          {/* <div
            className={styles.setting2}
            style={{ maxWidth: noLabel ? "20em" : "fit-content" }}
          >
            <Label //Just here for equal spacing (visibility: hidden)
              style={{
                fontWeight: "500",
                fontSize: ".9em",
                textAlign: "center",
                justifyContent: "center",
                display: "flex",
                width: noLabel ? "0px" : "fit-content",
                paddingLeft: noLabel ? "0px" : "40px",
                visibility: "hidden",
              }}
            >
              Palette
            </Label>

            <div
              style={{ display: "flex", justifyContent: "center", flexGrow: 1 }}
            >
              <div style={{ maxWidth: "100%" }}>
                <ColorPalletHolder
                  pallet={pallets.find(
                    (pallet) => pallet.id === viz?.designSettings?.currPalletId
                  )}
                  updatePallet={updatePallet}
                  save={save}
                  savePallet={savePallet}
                  onCancel={onCancel}
                ></ColorPalletHolder>
              </div>
            </div>
          </div> */}
          {/* {!save && (
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                paddingRight: "15px",
              }}
            >
              <div className={styles.addPallet} onClick={createPalletClick}>
                + Palette
              </div>
            </div>
          )} */}
        </div>
      )}
    </>
  );
}
