import React from "react";

import styles from "./NavTextField.module.scss";

/**
 * An auto saveable textField
 * @param {String} value value that is passed in as initial value and/or changeable value for the search text field
 *
 *
 *
 *
 * @returns {React.ReactElement} a numberscale clickable component
 */

export const NavTextField = ({
  value,
  setValue,
  placeholder,
  inputID,
  handlePressEnter,
  handleFocusOut,
  disabled,
  style,
  className, 
  shadow
}) => {


  // TODO: add a save timer while focusing on the input
  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div className={`${styles.searchField} ${className} ${shadow && styles.shadow}`}>
      <i className="bi bi-search"></i>
      <input
        className={styles.textField}
        style={style}
        type="text"
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleFocusOut}
        disabled={disabled}
        id={inputID}
        onKeyDown={handlePressEnter ? handlePressEnter : null}
      />

   
    </div>
  );
};
