import styles from "./Builder.module.scss";
import EmailEditor from "./EmailEditor";
import { useState } from "react";
import Tips from "./Tips/Tips";
import Subject from "./Subject/Subject";

export default function EmailBuilder({}) {
  const [tips, setTips] = useState([]);

  const [showTip, setShowTip] = useState(false);
  const [reDraw, setReDraw] = useState(0);

  function saveTips(copy) {
    setTips(copy);

    let show = false;
    for (let prob of copy) {
      if (!prob.ignore) {
        show = true;
        break;
      }
    }

    setShowTip(show);
  }

  function saveSubjectTips(subjectTips) {
    let emailTips = [...tips].filter((t) => t.code.includes("Email"));
    saveTips([...subjectTips, ...emailTips]);
  }

  function saveEmailTips(emailTips) {
    let subjectTips = [...tips].filter((t) => t.code.includes("Subject"));
    saveTips([...subjectTips, ...emailTips]);
  }

  function onIgnore(i) {
    let copy = [...tips];
    copy[i].ignore = true;
    saveTips(copy);
    setReDraw((old) => old + 1);
  }

  return (
    <div className={styles.container}>
      <div className={styles.topBar}>
        <div>1st Email</div>

        <div>View Send Preview Save</div>
      </div>

      <div className={styles.trifold}>
        <div className={styles.settingsControls}>
          <div className={styles.control}>
            <i className="bi bi-gear"></i>
          </div>

          <div className={styles.control}>
            <i className="bi bi-bounding-box-circles"></i>
          </div>
        </div>

        <div className={styles.settings}>
          Email Settings
          {/* <div className={styles.list}>List of things to Do</div> */}
        </div>

        <div className={styles.builder}>
          <Subject
            editable
            onSave={(text) => console.log(text)}
            tips={tips}
            setTips={saveSubjectTips}
            reDraw={reDraw}
          />
          <EmailEditor
            editable
            tips={tips}
            setTips={saveEmailTips}
            reDraw={reDraw}
          />
        </div>
      </div>
      {showTip && (
        <Tips tips={tips} onIgnore={onIgnore} close={() => setShowTip(false)} />
      )}
    </div>
  );
}
