import { useState, useEffect } from "react";
import styles from "./AnswerTable.module.scss";
import {
  AnswerCount,
  ParticipationCount,
  AvgScore,
  NpsScore,
} from "../QuestionChart";
import TableChart from "components/Charts/Table/TableChart";
import { forEach } from "assets/functions/ArrayFunctions";
import { combinedQs } from "../Visualization";
import { getTitleContainerStyle, getTitleStyle } from "./Table";
import { trimTimeDay } from "assets/functions/DateFunctions";

export default function PivotAnswerTable({
  answers,
  filters,
  viz,
  canSeeContactInfo,
  inEdit,
  projects,
  surveyTags,
  onSegClick,
  setCsvData,
  custom_fields,
  filterSubtitle,
  onSaveSort,
  togglespreadsheet,
  height,
  setOutsideData,
  setUpOutsideDataCounter,
}) {
  const [dataArray, setDataArray] = useState([]);
  const [headers, setHeaders] = useState();

  const vizQs = combinedQs(viz);

  const style = (value) => <span className={styles.text}>{value}</span>;

  function getUndefinedLabel() {
    return viz.designSettings.undefinedLabel
      ? viz.designSettings.undefinedLabel
      : "Undefined";
  }

  function handleUndefinedRow(data) {
    // Put's any undefined row at the end
    let index = data.findIndex((row) => row[viz.pivotString] === "Undefined");
    if (index >= 0) {
      let undefinedRow = data[index];
      undefinedRow[viz.pivotString] = getUndefinedLabel();
      data.splice(index, 1);
      data.push(undefinedRow);
    }
  }

  function addUndefined() {
    //Works because State won't recognize it because it is past the first layer;

    if (viz.designSettings?.totalRows) {
      let ind = viz.designSettings.tablePivotOrder.indexOf("totalRows");
      viz.designSettings.tablePivotOrder.splice(ind, 0, "Undefined");
    } else {
      viz.designSettings.tablePivotOrder.push("Undefined");
    }

    viz.designSettings.tablePivotFields.Undefined = {
      name: getUndefinedLabel(),
      show: true,
    };
  }

  function removeColumn(col) {
    delete viz.designSettings.tablePivotFields[col];
    let ind = viz.designSettings.tablePivotOrder.indexOf(col);
    if (ind > -1) {
      viz.designSettings.tablePivotOrder.splice(ind, 1);
    }
  }

  function getColumns() {
    let columns = [];
    let tableFields = viz.designSettings.tablePivotFields;
    // if (viz.designSettings.split) {
    //   let field = custom_fields.find(
    //     (f) => f.name === viz.designSettings.split
    //   );
    //   if (field) {
    //     let props = field?.properties;
    //     tableFields = props;
    //   }
    // }

    for (let field of viz.designSettings.tablePivotOrder) {
      if (tableFields[field].show) {
        columns.push({
          name: tableFields[field]?.name,
          accessor: field,
          cell_style: style,
        });
      }
    }

    setHeaders(columns);
  }

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "December",
  ];

  function getMonthTaken(answer) {
    if (!answer.updatedAt) {
      return "Undefined";
    }
    let date = new Date(answer.updatedAt);

    let month = months[date.getMonth()];
    let year = date.getFullYear();
    return month + " " + year;
  }


  function getMonths() {
    let map = {};
    for (let answer of answers) {
      let label = getMonthTaken(answer);
      if (!map[label]) {
        map[label] = true;
      }
    }
    return Object.keys(map);
  }

  function getHourTaken(answer) {
    if (!answer.createdAt) {
      return "Undefined";
    }
    let date = new Date(answer.createdAt);
    date.setMinutes(0, 0, 0);
    return trimTimeDay(date, true);
  }


  function getHours() {
    let map = {};
    for (let answer of fetchAnswers.data.answers) {
      let label = getHourTaken(answer);
      if (!map[label]) {
        map[label] = true;
      }
    }
    return Object.keys(map);
  }


  function getProjectsInOrder() {
    projects.sort((a, b) => {
      //Sort the projects by survey date
      if (a.startedAt === null || b.startedAt === null) {
        return 0;
      }
      let aDate = new Date(a.startedAt);
      let bDate = new Date(b.startedAt);
      let results = aDate - bDate;
      return results;
    });
    return projects;
  }

  function settleCustomFields(contact) {
    if (contact.customField) {
      let customFields = JSON.parse(contact.customField);
      while (typeof customFields === "string") {
        customFields = JSON.parse(customFields);
      }
      for (let cField in customFields) {
        contact[cField] = customFields[cField];
      }
      delete contact.customFields;
    }
  }

  function findAssociatedTag(projID) {
    for (let tag of surveyTags) {
      let found = tag.project.some((p) => p.id === projID);
      if (found) {
        return tag.label;
      }
    }
    return null;
  }

  function getSurveyDateLabel(val, survey) {
    let label = survey.name;

    if (survey.startedAt) {
      let date = new Date(survey.startedAt);
      let month = date.toDateString().substring(4, 7);
      let year = date.toDateString().substring(11, 15);
      label = month + " " + year;
      if (val === "survey quarter") {
        if (month === "Jan" || month === "Feb" || month === "Mar") {
          label = "Q1 " + year;
        } else if (month === "Apr" || month === "May" || month === "Jun") {
          label = "Q2 " + year;
        } else if (month === "Jul" || month === "Aug" || month === "Sep") {
          label = "Q3 " + year;
        } else if (month === "Oct" || month === "Nov" || month === "Dec") {
          label = "Q4 " + year;
        }
      }
    }
    return label;
  }

  function getSurveyLabel(val, id) {
    if (val === "survey tag") {
      return findAssociatedTag(id);
    }

    let proj = projects.find((p) => p.id === id);
    if (proj) {
      if (val === "survey") {
        return proj.name;
      }
      if (val === "survey date" || val === "survey quarter") {
        return getSurveyDateLabel(val, proj);
      }
    }

    return null;
  }

  function getPivotLabels(pivotBySurvey, answer) {
    let label = "";
    if (pivotBySurvey) {
      label = getSurveyLabel(viz.pivotString, answer.participation.projectId);
    } else if (viz.pivotString === "month taken") {
      label = getMonthTaken(answer);
    } else if (viz.pivotString === "hour taken") {
      label = getHourTaken(answer);
    } else {
      let contact = answer.participation?.contact
        ? { ...answer.participation?.contact }
        : null;
      if (contact) {
        settleCustomFields(contact);
        label = contact[viz.pivotString];
      }
    }
    if (!label) {
      if (viz.designSettings.showUndefined) {
        return ["Undefined"];
      } else {
        return [];
      }
    }

    let field = custom_fields.find((c) => c.name === viz.pivotString);
    if (field && field?.delimiter) {
      label = label.split(field.delimiter);
    } else {
      label = [label];
    }
    return label;
  }

  function getSplitLabels(splitBySurvey, answer) {
    let label = "";
    if (splitBySurvey) {
      label = getSurveyLabel(
        viz.designSettings.split,
        answer.participation.projectId
      );
    } else if (viz.designSettings.split === "question") {
      label = answer.questionId;
    } else {
      // Does not split by month taken yet. May need a more dynamic approach to generating the columns for that
      let contact = answer.participation?.contact
        ? { ...answer.participation?.contact }
        : null;
      if (contact) {
        settleCustomFields(contact);
        label = contact[viz.designSettings.split];
      }
    }
    if (!label) {
      if (viz.designSettings.showUndefined) {
        return ["Undefined"];
      } else {
        return [];
      }
    }

    let field = custom_fields.find((c) => c.name === viz.designSettings.split);
    if (field && field?.delimiter) {
      label = label.split(field.delimiter);
    } else {
      label = [label];
    }
    return label;
  }

  const getFilteredContactFields = (contactField) => {
    let active = [];

    if (filters) {
      let chosenFilter = JSON.parse(filters);
      for (let key in chosenFilter) {
        if (
          chosenFilter[key]?.properties &&
          chosenFilter[key].name === contactField
        ) {
          for (let value of chosenFilter[key].properties) {
            active.push(value);
          }
        }
      }

      if (active.length > 0) {
        return active;
      }
    }
    let index = custom_fields.find((f) => f.name === contactField);
    if (index?.properties) {
      return [...index?.properties];
    }
    return [];
  };

  function getLabelsFor(val) {
    let labels = [];
    if (val.includes("survey")) {
      let projects = getProjectsInOrder();

      let onlyTheseSurveyIds = [];
      if (filters) {
        let chosenFilter = JSON.parse(filters);
        if (chosenFilter.surveys) {
          onlyTheseSurveyIds = chosenFilter.surveys.map((s) => s.id);
        }
      }

      for (let survey of projects) {
        if (
          onlyTheseSurveyIds.length &&
          !onlyTheseSurveyIds.includes(survey.id)
        ) {
          continue;
        }

        let label;
        if (val === "survey") {
          label = survey.name;
        }
        if (val === "survey tag") {
          label = findAssociatedTag(survey.id);
        }
        if (val === "survey date" || val === "survey quarter") {
          label = getSurveyDateLabel(val, survey);
        }
        if (!labels.includes(label)) {
          labels.push(label);
        }
      }
    } else if (val === "month taken") {
      labels = getMonths();
    } else if (val === "hour taken") {
      labels = getHours();
    } else {
      labels = getFilteredContactFields(val);
    }
    return labels;
  }

  function getAnswersLengthForOption(option) {
    let count = 0;
    // only included those who answered the option in the count
    for (let a of answers) {
      if (a.matrixAnswer) {
        let matrixAnswer = JSON.parse(a.matrixAnswer);
        if (option in matrixAnswer) {
          count++;
        }
      }
    }
    return count;
  }

  function cameFromRanking(choice) {
    for (let q of vizQs) {
      if (
        q.choiceQuestion &&
        q.choiceQuestion.isRanking &&
        q.choiceQuestion.choices.includes(choice)
      ) {
        return true;
      }
    }
    return false;
  }

  function getMatrixPivotOptions() {
    // const pivotBySurvey = viz.pivotString.includes("survey");
    const blankData = {
      count: 0,
      participationCount: 0,
      promoters: 0,
      passives: 0,
      detractors: 0,
      total: 0,
    };

    let choices = [];
    forEach(vizQs, (q) => {
      if (q.choiceQuestion) {
        for (let choice of q.choiceQuestion.choices) {
          let lowered = choice.toLowerCase();
          if (!choices.includes(lowered)) {
            choices.push(lowered);
          }
        }
      }
    });

    forEach(choices, (c) => (blankData[c] = 0));

    const limit = viz.designSettings.scaleByRank ? getLimit() : undefined;

    let scale = false;

    let answerMap = {};
    let participationMap = {};
    let options = [];

    for (let q of vizQs) {
      for (let opt of q.matrixQuestion.options) {
        if (!options.includes(opt)) {
          options.push(opt);
        }
      }
    }

    forEach(options, (opt) => {
      answerMap[opt] = { ...blankData };
      participationMap[opt] = {};
    });

    for (let answer of answers) {
      // let labels = getPivotLabels(pivotBySurvey, answer);
      let question = vizQs.find((q) => q.id === answer.questionId);
      let matrixAnswer = JSON.parse(answer.matrixAnswer);
      for (let option in matrixAnswer) {
        if (!(answer.participation.id in participationMap[option])) {
          answerMap[option].participationCount += 1;
          participationMap[option][answer.participation.id] = true;
        }

        if (question.choiceQuestion) {
          if (
            viz.designSettings.scaleByRank &&
            question.choiceQuestion.isRanking
          ) {
            scaleByMatrixRank(limit, option, answerMap, matrixAnswer[option]);
          } else {
            for (let choice of matrixAnswer[option]) {
              answerMap[option][choice.toLowerCase()] += 1;
            }
          }
        }

        if (question.scaleQuestion) {
          scale = true;
          let num = matrixAnswer[option];
          if (num === 9 || num === 10) {
            answerMap[option].promoters += 1;
          } else if (num === 7 || num === 8) {
            answerMap[option].passives += 1;
          } else {
            answerMap[option].detractors += 1;
          }

          answerMap[option].total += num;
        }

        answerMap[option].count += 1;
      }
    }

    if (
      viz.designSettings.scaleByRank &&
      (viz.designSettings.standardScale || viz.designSettings.zeroHundredScale)
    ) {
      for (let option of options) {
        let length = getAnswersLengthForOption(option);
        for (let choice of choices) {
          if (
            cameFromRanking(choice) &&
            answerMap[option] &&
            answerMap[option][choice]
          ) {
            if (viz.designSettings.standardScale) {
              answerMap[option][choice] = parseFloat(
                (answerMap[option][choice] / length).toFixed(1)
              );
            } else {
              let val = (answerMap[option][choice] / length / limit) * 100;
              answerMap[option][choice] = Math.round(val);
            }
          }
        }
      }
    }

    let data = [];

    for (let option in answerMap) {
      let row = answerMap[option];
      row.option = option;

      if (scale) {
        if (row.count === 0) {
          row.avgScore = 0;
          row.nps = 0;
        } else {
          let avg = (row.total / row.count).toFixed(1);
          row.avgScore = parseFloat(avg);
          let nps =
            ((row.promoters - row.detractors) / row.participationCount) * 100;
          row.nps = Math.round(nps);
        }
      }

      data.push(row);
    }

    // handleUndefinedRow(data);
    return data;
  }

  function pivotScale() {
    const pivotBySurvey = viz.pivotString.includes("survey");

    let answerMap = {};
    const blankData = {
      total: 0,
      count: 0,
      promoters: 0,
      passives: 0,
      detractors: 0,
      partcount: 0,
    };

    let participationMap = {};

    let possible = getLabelsFor(viz.pivotString);
    forEach(possible, (opt) => {
      answerMap[opt] = { ...blankData };
      participationMap[opt] = {};
    });

    for (let answer of answers) {
      let labels = getPivotLabels(pivotBySurvey, answer);

      for (let label of labels) {
        if (!answerMap[label]) {
          if (viz.designSettings.showUndefined && label === "Undefined") {
            answerMap[label] = { ...blankData };
            participationMap[label] = {};
          } else {
            continue;
          }
        }
        if (!(answer.participation.id in participationMap[label])) {
          answerMap[label].partcount += 1;
          participationMap[label][answer.participation.id] = true;
        }

        answerMap[label].count += 1;
        answerMap[label].total += answer.scaleAnswer;

        if (answer.scaleAnswer === 9 || answer.scaleAnswer === 10) {
          answerMap[label].promoters += 1;
        } else if (answer.scaleAnswer === 7 || answer.scaleAnswer === 8) {
          answerMap[label].passives += 1;
        } else {
          answerMap[label].detractors += 1;
        }
      }
    }

    let data = [];

    if (!viz.designSettings.showNonParticipating) {
      for (let row of possible) {
        if (!answerMap[row].partcount) {
          delete answerMap[row];
        }
      }
    }

    for (let label in answerMap) {
      let avg = 0;
      if (answerMap[label].partcount) {
        avg = (answerMap[label].total / answerMap[label].partcount).toFixed(1);
        avg = parseFloat(avg);
      }
      let partCount = answerMap[label].partcount;

      let row = {
        participationCount: partCount,
        avgScore: avg,
      };

      if (viz.designSettings.tablePivotFields?.nps) {
        let score = 0;
        if (answerMap[label].partcount) {
          // Don't count it if the count was 0;
          score =
            ((answerMap[label].promoters - answerMap[label].detractors) /
              answerMap[label].partcount) *
            100; //calculate nps score
        }
        row.nps = Math.round(score);
      }

      row[viz.pivotString] = label;

      data.push(row);
    }

    handleUndefinedRow(data);

    return data;
  }

  function pivotNpsSplit() {
    let answerMap = {};
    const blankData = {
      Promoters: 0,
      Detractors: 0,
      Passives: 0,
    };

    // TODO, maybe re-organize how the table columns work... Let it be after the fact?
    // Filter some of the columns - they all still stay!

    const splitBySurvey = viz.designSettings.split.includes("survey");
    let splitUndefined = false;

    let columns = getLabelsFor(viz.designSettings.split);
    for (let col of columns) {
      answerMap[col] = {
        ...blankData,
      };
    }

    for (let answer of answers) {
      let fields = getSplitLabels(splitBySurvey, answer);
      for (let dataset of fields) {
        if (!answerMap[dataset]) {
          if (viz.designSettings.showUndefined && dataset === "Undefined") {
            splitUndefined = true;
            answerMap[dataset] = { ...blankData };
          } else {
            continue;
          }
        }
        if (answer.scaleAnswer === 9 || answer.scaleAnswer === 10) {
          answerMap[dataset].Promoters += 1;
        } else if (answer.scaleAnswer === 7 || answer.scaleAnswer === 8) {
          answerMap[dataset].Passives += 1;
        } else {
          answerMap[dataset].Detractors += 1;
        }
      }
    }

    let rows = ["Passives", "Promoters", "Detractors"];

    if (!viz.designSettings.showNonParticipating) {
      for (let col of columns) {
        let keep = false;
        for (let nps of rows) {
          if (answerMap[col][nps]) {
            keep = true;
            break;
          }
        }
        if (!keep) {
          delete answerMap[col];
          removeColumn(col);
        }
      }
    }

    let data = [];

    for (let seg of rows) {
      let row = { nps: seg };
      for (let dataset in answerMap) {
        row[dataset] = answerMap[dataset][seg];
      }
      data.push(row);
    }

    if (
      splitUndefined &&
      !viz.designSettings.tablePivotOrder.includes("Undefined")
    ) {
      addUndefined();
    }

    return data;
  }

  function pivotByNPS() {
    if (viz.designSettings.split) {
      return pivotNpsSplit();
    }

    let promoters = 0;
    let detractors = 0;
    let passives = 0;

    for (let answer of answers) {
      if (answer.scaleAnswer === 9 || answer.scaleAnswer === 10) {
        promoters += 1;
      } else if (answer.scaleAnswer === 7 || answer.scaleAnswer === 8) {
        passives += 1;
      } else {
        detractors += 1;
      }
    }

    let rows = [
      {
        nps: "Promoters",
        count: promoters,
      },
      {
        nps: "Passives",
        count: passives,
      },
      {
        nps: "Detractors",
        count: detractors,
      },
    ];
    return rows;
  }

  function scalePivotSplitNps() {
    const blankData = {
      promoters: 0,
      passives: 0,
      detractors: 0,
      count: 0,
    };

    let answerMap = {};
    let possible = getLabelsFor(viz.pivotString);
    forEach(possible, (opt) => {
      answerMap[opt] = { ...blankData };
    });

    const pivotBySurvey = viz.pivotString.includes("survey");

    for (let answer of answers) {
      let pivotFields = getPivotLabels(pivotBySurvey, answer);
      for (let pivot of pivotFields) {
        if (!(pivot in answerMap)) {
          answerMap[pivot] = { ...blankData };
        }
        if (answer.scaleAnswer === 9 || answer.scaleAnswer === 10) {
          answerMap[pivot].promoters += 1;
        } else if (answer.scaleAnswer === 7 || answer.scaleAnswer === 8) {
          answerMap[pivot].passives += 1;
        } else {
          answerMap[pivot].detractors += 1;
        }
        answerMap[pivot].count++;
      }
    }

    if (!viz.designSettings.showNonParticipating) {
      for (let row of possible) {
        if (!answerMap[row].count) {
          delete answerMap[row];
        }
      }
    }

    let data = [];
    for (let pivot in answerMap) {
      let row = {};
      row[viz.pivotString] = pivot;
      row = { ...row, ...answerMap[pivot] };
      data.push(row);
    }

    handleUndefinedRow(data);

    return data;
  }

  function scalePivotAndSplit() {
    const blankData = {
      count: 0,
      partcount: 0,
      promoters: 0,
      passives: 0,
      detractors: 0,
      total: 0,
    };

    let answerMap = {};
    let participationMap = {};
    let possibleRows = getLabelsFor(viz.pivotString);
    let possibleCols = getLabelsFor(viz.designSettings.split);

    forEach(possibleRows, (row) => {
      answerMap[row] = {};
      participationMap[row] = {};
      forEach(possibleCols, (col) => {
        answerMap[row][col] = { ...blankData };
        participationMap[row][col] = {};
      });
    });

    const splitBySurvey = viz.designSettings.split.includes("survey");
    const pivotBySurvey = viz.pivotString.includes("survey");
    let splitUndefined = false;
    for (let answer of answers) {
      let pivotFields = getPivotLabels(pivotBySurvey, answer);
      for (let pivot of pivotFields) {
        let splitFields = getSplitLabels(splitBySurvey, answer);
        for (let split of splitFields) {
          if (!(pivot in answerMap)) {
            answerMap[pivot] = {};
            participationMap[pivot] = {};
          }
          if (!(split in answerMap[pivot])) {
            answerMap[pivot][split] = { ...blankData };
            participationMap[pivot][split] = {};
            if (split === "Undefined") {
              splitUndefined = true;
            }
          }

          if (answer.scaleAnswer === 9 || answer.scaleAnswer === 10) {
            answerMap[pivot][split].promoters += 1;
          } else if (answer.scaleAnswer === 7 || answer.scaleAnswer === 8) {
            answerMap[pivot][split].passives += 1;
          } else if (answer.scaleAnswer < 7) {
            answerMap[pivot][split].detractors += 1;
          }

          if (!(answer?.participation?.id in participationMap[pivot][split])) {
            answerMap[pivot][split].partcount += 1;
            participationMap[pivot][split][answer?.participation?.id] = true;
          }

          answerMap[pivot][split].count += 1;
          answerMap[pivot][split].total += answer.scaleAnswer;
        }
      }
    }

    if (!viz.designSettings.showNonParticipating) {
      for (let row of possibleRows) {
        let there = false;
        for (let col in answerMap[row]) {
          if (answerMap[row][col].count) {
            there = true;
            break;
          }
        }
        if (!there) {
          delete answerMap[row];
        }
      }

      for (let col of possibleCols) {
        let there = false;
        for (let row in answerMap) {
          if (answerMap[row][col] && answerMap[row][col].count) {
            there = true;
            break;
          }
        }
        if (!there) {
          removeColumn(col);
          for (let row in answerMap) {
            delete answerMap[row][col];
          }
        }
      }
    }

    const answerType = viz.designSettings.answerType
      ? viz.designSettings.answerType
      : ParticipationCount;
    let data = [];
    for (let pivot in answerMap) {
      for (let split in answerMap[pivot]) {
        if (answerType === AvgScore) {
          if (answerMap[pivot][split].partcount === 0) {
            answerMap[pivot][split] = 0;
          } else {
            let avg =
              answerMap[pivot][split].total / answerMap[pivot][split].partcount;
            answerMap[pivot][split] = parseFloat(avg.toFixed(1));
          }
        } else if (answerType === ParticipationCount) {
          answerMap[pivot][split] = answerMap[pivot][split].partcount;
        } else if (answerType === NpsScore) {
          let score = 0;
          if (answerMap[pivot][split].partcount) {
            // Don't count it if the count was 0;
            score =
              ((answerMap[pivot][split].promoters -
                answerMap[pivot][split].detractors) /
                answerMap[pivot][split].partcount) *
              100; //calculate nps score
          }
          answerMap[pivot][split] = Math.round(score);
        }
      }
      let row = {};
      row[viz.pivotString] = pivot;
      row = { ...row, ...answerMap[pivot] };

      data.push(row);
    }

    if (
      splitUndefined &&
      !viz.designSettings.tablePivotOrder.includes("Undefined")
    ) {
      addUndefined();
    }

    handleUndefinedRow(data);

    return data;
  }

  function getScaleData() {
    if (viz.pivotString === "nps") {
      return pivotByNPS();
    }

    if (viz.designSettings.split === "nps") {
      return scalePivotSplitNps();
    }

    if (viz.designSettings.split) {
      return scalePivotAndSplit();
    }

    return pivotScale();
  }

  function getLimit() {
    let limit = 100;
    for (let q of vizQs) {
      if (q.choiceQuestion && q.choiceQuestion.isRanking) {
        if (q.choiceQuestion.limit && q.choiceQuestion.limit < limit) {
          limit = q.choiceQuestion.limit;
        } else if (q.choiceQuestion.choices.length < limit) {
          limit = q.choiceQuestion.choices.length;
        }
      }
    }

    return limit;
  }

  function scaleByRank(limit, label, answerMap, answer) {
    let weight = limit;
    for (let choice of answer.choiceAnswer) {
      let key = choice.toLowerCase();
      if (key in answerMap[label]) {
        answerMap[label][key] += weight ? weight : 1;
      } else {
        answerMap[label].otherOption += weight ? weight : 1;
      }
      if (weight) {
        weight--;
      }
    }
  }

  function scaleByMatrixRank(limit, label, answerMap, answers) {
    let weight = limit;
    for (let choice of answers) {
      let key = choice.toLowerCase();
      if (key in answerMap[label]) {
        answerMap[label][key] += weight ? weight : 1;
      } else {
        answerMap[label].otherOption += weight ? weight : 1;
      }
      if (weight) {
        weight--;
      }
    }
  }

  function getChoiceData() {
    // Must be pivoting.
    // Show choices and Part Count
    const pivotBySurvey = viz.pivotString.includes("survey");

    let choices = [];
    let hasOther = false;
    forEach(vizQs, (q) => {
      for (let choice of q.choiceQuestion.choices) {
        let lowered = choice.toLowerCase();
        if (!choices.includes(lowered)) {
          choices.push(lowered);
        }
      }
      if (q.choiceQuestion.hasOtherOption) {
        hasOther = true;
      }
    });
    if (hasOther) {
      choices.push("otherOption");
    }

    let blankData = { participationCount: 0 };
    forEach(choices, (c) => (blankData[c] = 0));

    let answerMap = {};
    let participationMap = {};
    let possible = getLabelsFor(viz.pivotString);
    forEach(possible, (opt) => {
      answerMap[opt] = { ...blankData };
      participationMap[opt] = {};
    });

    const limit = viz.designSettings.scaleByRank ? getLimit() : undefined;

    for (let answer of answers) {
      let labels = getPivotLabels(pivotBySurvey, answer);
      for (let label of labels) {
        if (!answerMap[label]) {
          if (viz.designSettings.showUndefined && label === "Undefined") {
            answerMap[label] = { ...blankData };
            participationMap[label] = {};
            possible.push(label);
          } else {
            continue;
          }
        }
        if (!(answer.participation.id in participationMap[label])) {
          answerMap[label].participationCount += 1;
          participationMap[label][answer.participation.id] = true;
        }

        if (viz.designSettings.scaleByRank) {
          scaleByRank(limit, label, answerMap, answer);
        } else {
          for (let choice of answer.choiceAnswer) {
            let key = choice.toLowerCase();
            if (key in answerMap[label]) {
              answerMap[label][key] += 1;
            } else {
              answerMap[label].otherOption += 1;
            }
          }
        }
      }
    }

    if (!viz.designSettings.showNonParticipating) {
      for (let row of possible) {
        if (!answerMap[row].participationCount) {
          delete answerMap[row];
        }
      }
    }

    if (
      viz.designSettings.scaleByRank &&
      (viz.designSettings.standardScale || viz.designSettings.zeroHundredScale)
    ) {
      let length = answers.length;
      for (let label of possible) {
        for (let choice of choices) {
          if (answerMap[label] && answerMap[label][choice]) {
            if (viz.designSettings.standardScale) {
              answerMap[label][choice] = parseFloat(
                (answerMap[label][choice] / length).toFixed(1)
              );
            } else {
              let val = (answerMap[label][choice] / length / limit) * 100;
              answerMap[label][choice] = Math.round(val);
            }
          }
        }
      }
    }

    let data = [];
    for (let field in answerMap) {
      let row = answerMap[field];
      row[viz.pivotString] = field;

      if (viz.designSettings.tablePivotFields?.winner?.show) {
        let winner = choices[0];
        forEach(choices, (c) => {
          if (answerMap[field][c] > answerMap[field][winner]) {
            winner = c;
          }
        });
        row.winner = winner;
      }
      data.push(row);
    }

    handleUndefinedRow(data);

    return data;
  }

  // function combinedTypesSplit() {
  //   let answerMap = {};
  //   const splitBySurvey = viz.designSettings.split.includes("survey");
  //   const splitByQs = viz.designSettings.split === "question";
  //   const pivotBySurvey = viz.pivotString.includes("survey");
  //   let splitUndefined = false;
  //   let participationMap = {};
  //   for (let answer of answers) {
  //       if (!(answer?.participation?.id in participationMap)) {
  //         participationMap[answer?.participation?.id] = true;
  //         let pivotFields = getPivotLabels(pivotBySurvey, answer);
  //         for (let pivot of pivotFields) {
  //           let splitFields = getSplitLabels(splitBySurvey, splitByQs, answer);
  //           for (let split of splitFields) {
  //             if (!(pivot in answerMap)) {
  //               answerMap[pivot] = {};
  //             }
  //             if (!(split in answerMap[pivot])) {
  //               answerMap[pivot][split] = 0;
  //               if (split === "Undefined") {
  //                 splitUndefined = true;
  //               }
  //             }
  //             answerMap[pivot][split] += 1;
  //           }
  //         }
  //     }
  //   }

  //   let data = [];
  //   for (let pivot in answerMap) {
  //     let row = {};
  //     row[viz.pivotString] = pivot;
  //     row = { ...row, ...answerMap[pivot] };

  //     data.push(row);
  //   }

  //   if (
  //     splitUndefined &&
  //     !viz.designSettings.tablePivotOrder.includes("Undefined")
  //   ) {
  //     addUndefined();
  //   }

  //   handleUndefinedRow(data);

  //   return data;
  // }

  function getCombinedTypesData() {
    // if (viz.designSettings.split) {  // Won't ever hit currently
    //   return combinedTypesSplit();
    // }

    const pivotBySurvey = viz.pivotString.includes("survey");
    const blankData = {
      count: 0,
      participationCount: 0,
      promoters: 0,
      passives: 0,
      detractors: 0,
      total: 0,
    };

    let choices = [];
    let hasOther = false;
    forEach(vizQs, (q) => {
      if (q.choiceQuestion) {
        for (let choice of q.choiceQuestion.choices) {
          let lowered = choice.toLowerCase();
          if (!choices.includes(lowered)) {
            choices.push(lowered);
          }
        }
        if (q.choiceQuestion.hasOtherOption) {
          hasOther = true;
        }
      }
    });
    if (hasOther) {
      choices.push("otherOption");
    }

    forEach(choices, (c) => (blankData[c] = 0));

    let scale = false;

    let answerMap = {};
    let participationMap = {};
    let possible = getLabelsFor(viz.pivotString);
    forEach(possible, (opt) => {
      answerMap[opt] = { ...blankData };
      participationMap[opt] = {};
    });

    for (let answer of answers) {
      let labels = getPivotLabels(pivotBySurvey, answer);
      for (let label of labels) {
        if (!answerMap[label]) {
          if (viz.designSettings.showUndefined && label === "Undefined") {
            answerMap[label] = { ...blankData };
            participationMap[label] = {};
          } else {
            continue;
          }
        }
        if (!(answer.participation.id in participationMap[label])) {
          answerMap[label].participationCount += 1;
          participationMap[label][answer.participation.id] = true;
        }

        if (answer.choiceAnswer) {
          for (let choice of answer.choiceAnswer) {
            let key = choice.toLowerCase();
            if (key in answerMap[label]) {
              answerMap[label][key] += 1;
            } else {
              answerMap[label].otherOption += 1;
            }
          }
        }

        if (answer.scaleAnswer) {
          scale = true;
          if (answer.scaleAnswer === 9 || answer.scaleAnswer === 10) {
            answerMap[label].promoters += 1;
          } else if (answer.scaleAnswer === 7 || answer.scaleAnswer === 8) {
            answerMap[label].passives += 1;
          } else {
            answerMap[label].detractors += 1;
          }

          answerMap[label].total += answer.scaleAnswer;
        }

        answerMap[label].count += 1;
      }
    }

    if (!viz.designSettings.showNonParticipating) {
      for (let row of possible) {
        if (!answerMap[row].participationCount) {
          delete answerMap[row];
        }
      }
    }

    let data = [];

    for (let label in answerMap) {
      let row = answerMap[label];
      row[viz.pivotString] = label;

      if (scale) {
        if (row.count === 0) {
          row.avgScore = 0;
          row.nps = 0;
        } else {
          let avg = (row.total / row.count).toFixed(1);
          row.avgScore = parseFloat(avg);
          let nps =
            ((row.promoters - row.detractors) / row.participationCount) * 100;
          row.nps = Math.round(nps);
        }
      }

      data.push(row);
    }

    handleUndefinedRow(data);
    return data;
  }

  function getTextData() {
    // Must be pivoting to be here.
    // Only option is Participation count
    const pivotBySurvey = viz.pivotString.includes("survey");
    let participationMap = {};
    let answerMap = {};
    let possible = getLabelsFor(viz.pivotString);
    forEach(possible, (option) => {
      answerMap[option] = 0;
      participationMap[option] = {};
    });

    for (let answer of answers) {
      let labels = getPivotLabels(pivotBySurvey, answer);
      for (let label of labels) {
        if (!(label in answerMap)) {
          if (viz.designSettings.showUndefined && label === "Undefined") {
            answerMap[label] = 0;
            participationMap[label] = {};
          } else {
            continue;
          }
        }
        if (!(answer.participation.id in participationMap[label])) {
          answerMap[label] += 1;
          participationMap[label][answer.participation.id] = true;
        }
      }
    }

    let data = [];
    for (let label in answerMap) {
      if (!answerMap[label] && !viz.designSettings.showNonParticipating) {
        continue;
      }
      let row = {
        participationCount: answerMap[label],
      };
      row[viz.pivotString] = label;
      data.push(row);
    }

    handleUndefinedRow(data);

    return data;
  }

  function getData() {
    let data;

    if (viz.designSettings.pivotOptions) {
      data = getMatrixPivotOptions();
    } else if (allScale()) {
      data = getScaleData();
    } else if (allChoice()) {
      data = getChoiceData();
    } else if (allFreeResponse()) {
      data = getTextData();
    } else {
      data = getCombinedTypesData();
    }

    totals(data);

    setDataArray(data);
  }

  function totals(data) {
    if (viz.designSettings?.totalRows) {
      for (let row of data) {
        let total = 0;
        for (let field in row) {
          if (
            viz.designSettings.tablePivotFields[field] &&
            viz.designSettings.tablePivotFields[field].show &&
            typeof row[field] === "number"
          ) {
            total += row[field];
          }
        }
        if (!total) {
          row.totalRows = "";
        } else {
          if (!Number.isInteger(total)) {
            row.totalRows = total.toFixed(1);
          } else {
            row.totalRows = total;
          }
        }
      }
    }

    if (viz.designSettings?.totalColumns) {
      let totals = {};
      totals[viz.pivotString] = "Total";

      for (let row of data) {
        for (let field in row) {
          if (
            viz.designSettings.tablePivotFields[field] &&
            viz.designSettings.tablePivotFields[field].show &&
            typeof row[field] === "number"
          ) {
            if (!(field in totals)) {
              totals[field] = 0;
            }
            totals[field] += row[field];
          }
        }
      }

      for (let field in totals) {
        if (typeof totals[field] === "number") {
          let total = totals[field];
          if (!Number.isInteger(total)) {
            totals[field] = total.toFixed(1);
          } else {
            totals[field] = total;
          }
        }
      }

      data.push(totals);
    }
  }

  function allScale() {
    for (let q of vizQs) {
      if (!q.scaleQuestion) {
        return false;
      }
    }
    return true;
  }

  function allChoice() {
    for (let q of vizQs) {
      if (!q.choiceQuestion) {
        return false;
      }
    }
    return true;
  }

  function allFreeResponse() {
    for (let q of vizQs) {
      if (!q.textQuestion) {
        return false;
      }
    }
    return true;
  }

  useEffect(() => {
    if (answers) {
      getData(); //Data first in case Undefined is added to the table fields
      getColumns();
    }
  }, [viz, answers]);

  // useEffect(() => {
  //   if (setUpOutsideDataCounter) {
  //     if (dataArray && headers) {
  //       let copy = dataArray;
  //       if (copy.length > 30) {
  //         copy = copy.slice(0, 30);
  //       }
  //       setOutsideData({
  //         dataArray: copy,
  //         headers: headers,
  //       });
  //     }
  //   }
  // }, [setUpOutsideDataCounter]);

  function handleRowClick(obj) {
    var selection = window.getSelection();
    if (!selection.toString()) {
      let segment = viz.designSettings.pivotOptions
        ? obj.option
        : obj[viz.pivotString];
      let dataset = "";
      onSegClick(segment, dataset);
    }
  }

  function getSubtitle() {
    let it = "";

    if (viz.designSettings.split) {
      let answerType = viz.designSettings.answerType;

      if (viz.pivotString === "nps") {
        it = "Passives, Promoters, Detractors";
      } else if (answerType === ParticipationCount) {
        it = "Participation Count";
      } else if (answerType === AnswerCount) {
        it = "Answer Count";
      } else if (answerType === ParticipationCount) {
        it = "Participation Count";
      } else if (answerType === AvgScore) {
        it = "Average Score";
      } else if (answerType === NpsScore) {
        it = "NPS Score";
      }
      if (filterSubtitle) {
        it += " | ";
      }
    }

    if (filterSubtitle) {
      it += filterSubtitle;
    }
    return it;
  }

  return (
    <>
      {headers && dataArray && (
        <TableChart
          initHeaders={headers}
          data={dataArray}
          asChart
          onRowClick={inEdit || !canSeeContactInfo ? undefined : handleRowClick}
          titleStyle={getTitleStyle(viz)}
          tableTitle={viz.designSettings.hasTitle ? viz.title : ""}
          subtitle={
            viz.designSettings.split || filterSubtitle ? getSubtitle() : null
          }
          setExternalCsvData={setCsvData}
          tableSort={viz.designSettings?.tableSort}
          onSaveSort={onSaveSort}
          inEdit={inEdit}
          toggleSpreadsheet={togglespreadsheet}
          titleContainerStyle={getTitleContainerStyle(viz)}
          color={viz.designSettings.tableColor}
          height={height}
          setOutsideData={setOutsideData}
          setUpOutsideDataCounter={setUpOutsideDataCounter}
        />
      )}
    </>
  );
}
