/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2024 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/

import React, { useState, useEffect, useRef } from "react";
import { Loading } from "components/Loading/Loading";
import styles from "./CustomFieldsEditor.module.scss";
import { useUpdateUserById } from "api/resources/organization/users";
import Button from "components/Button/Button";
import { useFetchCurrOrgsFields } from "api/resources/organization/organization";
import { ErrorPage } from "pages";
import { useUpdateContactGql } from "api/resources/contacts/contacts";
import EditSingleCustomField from "./EditSingle";
import { trimDate } from "assets/functions/DateFunctions";
import CombinedInput from "components/inputs/input_fields/CombinedInput/CombinedInput";

export default function CustomFieldsEditor({
  user,
  contact,
  disabled,
  setAdd,
  add,
  edit,
  setEdit,
}) {
  const orgFields = useFetchCurrOrgsFields();

  return (
    <>
      {orgFields.isLoading && <Loading />}
      {orgFields.isError && <ErrorPage error={orgFields.error} />}
      {orgFields.isSuccess && (
        <>
          <PickFields
            user={user}
            contact={contact}
            orgFields={orgFields.data.getCurrOrgFields}
            disabled={disabled}
            refetch={() => orgFields.refetch()}
            setAdd={setAdd}
            add={add}
            edit={edit}
            setEdit={setEdit}
          />
        </>
      )}
    </>
  );
}

function PickFields({
  user,
  orgFields,
  contact,
  refetch,
  add,
  setAdd,
  disabled
}) {
  const initFields = () => {
    if (user?.customField) {
      return JSON.parse(user.customField);
    }
    if (contact?.customField) {
      let fields = JSON.parse(contact.customField);

      while (typeof fields === "string") {
        fields = JSON.parse(fields);
      }
      if (!fields) {
        return {};
      }
      return fields;
    }
    return {};
  };

  const [userFields, setUserFields] = useState(initFields());
  const [currContact, setContact] = useState(contact);
  const [changed, setChanged] = useState(false);
  const [saveWords, setSaveWords] = useState(
    `Save ${user ? "User" : contact ? "Contact" : ""} Fields`
  );
  const [createNew, setCreateNew] = useState(false);

  function addField(field) {
    let copy = { ...userFields };
    if (user) {
      copy[field] = [];
    }
    if (contact) {
      copy[field] = "";
    }
    setUserFields(copy ? copy : {});
    setAdd(false);
    setChanged(true);
  }

  function getLeft() {
    let left = [];
    if (orgFields) {
      for (let one of orgFields) {
        if (!(one.name in userFields)) {
          let caps =
            one.displayName.charAt(0).toUpperCase() + one.displayName.slice(1);
          left.push({ name: one.name, displayName: caps });
        }
      }
    }
    return left;
  }

  const updateUser = useUpdateUserById();
  const updateContact = useUpdateContactGql();

  function saveUserFields() {
    setSaveWords(
      <>
        Saving <Loading width={20} height={20}></Loading>
      </>
    );
    if (user) {
      updateUser.mutate(
        {
          data: {
            customField: JSON.stringify(userFields),
          },
          id: user.id,
        },
        {
          onSuccess: (data) => {
            onSaved();
          },
        }
      );
    }
    if (contact) {
      let finalFields = {};
      let fields = Object?.keys(userFields ? userFields : {});
      for (let field of fields) {
        if (userFields[field] && userFields[field] != "") {
          finalFields[field] = userFields[field];
        }
      }

      updateContact.mutate(
        {
          data: {
            customField: JSON.stringify(finalFields),
            firstName: currContact.firstName,
            lastName: currContact.lastName,
            email: currContact.email,
          },
          id: contact.id,
        },
        {
          onSuccess: (data) => {
            onSaved();
            setUserFields(finalFields ? finalFields : {});
          },
        }
      );
    }
  }

  function onSaved() {
    setSaveWords(
      <>
        Saved <i className="bi bi-check"></i>
      </>
    );
    setTimeout(() => {
      setChanged(false);
      setSaveWords(`Save ${user ? "User" : contact ? "Contact" : ""} Fields`);
    }, 1000);
    if (refetch) {
      refetch();
    }
  }

  function changeField(name, options) {
    let copy = { ...userFields };
    if (user) {
      copy[name] = options.map((opt) => opt.value);
    }
    if (contact) {
      let field = orgFields.find((f) => f.name === name);
      if (field?.delimiter) {
        copy[name] = "";
        for (let option of options) {
          if (copy[name]) {
            copy[name] += field.delimiter;
          }
          copy[name] += option.value;
        }
      } else {
        copy[name] = options.value;
      }
    }
    setUserFields(copy ? copy : {});
    setChanged(true);
  }

  function cancel() {
    setUserFields(initFields());
    setChanged(false);
  }

  const hasSome = Object?.keys(userFields ? userFields : {}).length > 0;

  function handleNewField(field) {
    if (field?.name) {
      addField(field.name);
    }
    setCreateNew(false);
    refetch();
  }

  return (
    <>
      {" "}
      {add && (
        <AddField
          onClickOut={() => setAdd(false)}
          onAdd={addField}
          options={getLeft()}
          onCreateNew={() => {
            setCreateNew(true);
            setAdd(false);
          }}
        />
      )}
      <div className={styles.userFields}>
        <div className={styles.userFieldsTable}>
          <div className={styles.customField}>
            <CombinedInput
              label="First Name"
              value={contact.firstName}
              onChange={(val) => {
                setContact({ ...currContact, firstName: val });
                setChanged(true);
              }}
              disable={disabled}
              style={{backgroundColor: "white"}}
              shadow
            ></CombinedInput>
          </div>
          <div className={styles.customField}>
            <CombinedInput
              label="Last Name"
              value={contact.lastName}
              onChange={(val) => {
                setContact({ ...currContact, lastName: val });
                setChanged(true);
              }}
              disable={disabled}
              style={{backgroundColor: "white"}}
              shadow
            ></CombinedInput>
          </div>
          <div className={styles.customField}>
            <CombinedInput
              label="Email"
              value={contact.email}
              onChange={(val) => {
                setContact({ ...currContact, email: val });
                setChanged(true);
              }}
              disable={disabled}
              style={{backgroundColor: "white"}}
              shadow
            ></CombinedInput>
          </div>

          {hasSome && (
            <>
              {Object?.keys(userFields ? userFields : {}).map((key, i) => {
                let field = orgFields.find((f) => f.name === key);
                if (!field) {
                  field = { name: key, properties: [], displayName: key };
                }
                let chosen = null;

                let them = userFields[key];
                let name = key;
                let caps = key;
                let multiple = false;

                name = field?.displayName ? field.displayName : field.name;
                caps = name;

                if (them) {
                  if (contact && field?.delimiter) {
                    them = them.split(
                      field?.delimiter ? field.delimiter : undefined
                    );
                    chosen = them.map((prop) => {
                      return {
                        value: prop,
                        label: prop,
                      };
                    });
                  } else if (contact) {
                    chosen = {
                      value: them,
                      label: them,
                    };
                  } else {
                    chosen = "";
                  }
                }
                multiple = contact ? (field.delimiter ? true : false) : true;

                return (
                  <React.Fragment key={i}>
                    {(userFields[key] || userFields[key] === "") && (
                      <div className={styles.customField}>
                        <CombinedInput
                          label={caps}
                          value={chosen}
                          onChange={(val) => changeField(key, val)}
                          options={
                            field
                              ? field.properties.map((prop) => {
                                  return {
                                    value: prop,
                                    label: prop,
                                  };
                                })
                              : []
                          }
                          selectMultiple={multiple}
                          select={!multiple}
                          search
                          canCreate
                          disable={disabled}
                          style={{backgroundColor: "white"}}
                          shadow
                        ></CombinedInput>

                      </div>
                    )}
                  </React.Fragment>
                );
              })}
            </>
          )}
          <div className={styles.customField}>
            <CombinedInput
              label={"Created Date"}
              value={trimDate(contact.createdAt, true)}
              disable
              style={{backgroundColor: "white"}}
              shadow
            ></CombinedInput>
          </div>
        </div>

        {!add && changed && !disabled && (
          <div
            style={{
              display: "flex",
              gap: "15px",
              height: "30px",
              marginTop: "20px",
              position: "sticky",
              bottom: "2em",
            }}
          >
            <Button seafoam shadow onClick={cancel}>
              <div>Cancel</div>
            </Button>
            <Button shadow onClick={saveUserFields}>
              {saveWords}
            </Button>
          </div>
        )}
        {createNew && (
          <EditSingleCustomField
            brandNew
            onClose={() => setCreateNew(false)}
            onSaved={handleNewField}
          />
        )}
      </div>
    </>
  );
}

export function AddField({ onClickOut, onAdd, options, onCreateNew }) {
  function clickOutside(e) {
    if (ref.current && !ref.current?.contains(e.target)) {
      onClickOut(e);
      document.removeEventListener("click", clickOutside, true);
    }
  }

  useEffect(() => {
    document.addEventListener("click", clickOutside, true);
    return () => {
      document.removeEventListener("click", clickOutside, true);
    };
  }, []);

  const ref = useRef();

  const onClick = (name) => {
    onAdd(name);
  };

  return (
    <div ref={ref} className={styles.customDropDown}>
      {options.map((field, i) => (
        <div
          key={i}
          className={styles.choice}
          onClick={() => onClick(field.name)}
        >
          {field?.displayName ? field.displayName : field.name}
        </div>
      ))}
      <div
        className={`${styles.choice} ${styles.addNew}`}
        onClick={onCreateNew}
      >
        + New Field
      </div>
    </div>
  );
}
