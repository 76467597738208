// External
import React, { useState, useRef, useEffect } from "react";

// Internal
import styles from "./Email.module.scss";
import ReactModal from "components/ReactModal/ReactModal.jsx";
import { TextFieldSimple } from "components/inputs";
import Button from "components/Button/Button";
import {
  findDay,
  findMonth,
  militaryTimeConverter,
} from "components/inputs/input_fields/SelectFieldCalendar/SelectFieldCalendar";

import SunEditor from "components/inputs/input_fields/suneditor-react";
import { buttonList } from "./buttons/buttonList";
import "components/inputs/input_fields/suneditor-react/suneditor.min.css"; // Import Sun Editor's CSS File
import { useParams } from "react-router-dom";
import {
  useCreateDistribution,
  useDeleteDistribution,
  useGetDistributionById,
  useUpdateDistribution,
} from "api/resources/projects/distributions";

import {
  useCancelSend,
  useSendDistributionGql,
  useSendDistributionPreview,
} from "api/resources/projects/emails";

import { ToFrom } from "./Settings/tofrom";
import { AddFields } from "./AddFields/AddFields";
import { useFetchCurrOrgsFields } from "api/resources/organization/organization";
import { AddButton } from "./AddButton/AddButton";

import { ConditionBuilder } from "./ConditionBuilder/ConditionBuilder";
import { useGetCurrentUser } from "api/resources/organization/users";
import { Loading } from "components/Loading/Loading";
import { Label } from "components/layouts/Label/Label";
import { DISTRIBUTION } from "api/resources/subscription/subscribe";
import { useSubscription } from "@apollo/client";
import IconShopper from "pages/results/Charts/Settings/AccordianItems/MetricSettings/IconShopper/IconShopper";
import { Deliveries } from "../../deliveries/Deliveries";
import { SurveyURL } from "./AddButton/SurveyURL";
import parse from "html-react-parser";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import { forEach } from "assets/functions/ArrayFunctions";
import Scheduler from "./Scheduler";
import { maintimezones } from "assets/functions/Variables";
import Audience from "pages/contacts/audiences/Audience";
import { Player } from "@lottiefiles/react-lottie-player";
import { HorizontalBar } from "components/layouts/HorizontalBar/HorizontalBar";
import ContactProfile from "components/Popout/ContactProfile";
import { Pie } from "components/inputs/input_fields/CircleProgress/Circle";

const genContact = {
  firstName: "John",
  lastName: "Doe",
  email: "johndoe@example.com",
  customField: JSON.stringify({
    prefix: "Dr.",
  }),
};

export function seeDate(date) {
  // let newDate = new Date(date);
  return trimDate(date);
}

export function trimDate(date) {
  let d = new Date(date);
  let niceString = d.toDateString();

  let month = niceString.substring(4, 7);
  let day = niceString.substring(8, 10);
  //eslint-disable-next-line no-unused-expressions
  day.charAt(0) == "0" ? (day = day.charAt(1)) : null;
  let year = niceString.substring(11);
  return month + " " + day + ", " + year + " at " + d.toLocaleTimeString();
}

export const EmailContainer = ({
  show,
  setShow,
  email,
  roles,
  user,
  projectStatus,
  initschedule,
  initpreview,
  survey,
  iteration,
  iterationSchedule,
  organization,
  copy,
}) => {
  const [currEmail, setEmail] = useState(email);
  const [refetch, setRefect] = useState(false);
  const getDistribution = useGetDistributionById(
    currEmail?.id ? currEmail?.id : ""
  );

  return (
    <>
      {!copy && getDistribution.isSuccess && (
        <Email
          show={show}
          setShow={setShow}
          email={
            getDistribution.isSuccess ? getDistribution?.data?.dist : email
          }
          roles={roles}
          user={user}
          projectStatus={projectStatus}
          initschedule={initschedule}
          initpreview={initpreview}
          survey={survey}
          iteration={iteration}
          iterationSchedule={iterationSchedule}
          organization={organization}
          refetch={() => {
            getDistribution?.refetch();
            setRefect(!refetch);
          }}
          refetched={refetch}
          refetching={getDistribution.isRefetching}
        ></Email>
      )}
      {copy && (
        <Email
          show={show}
          setShow={setShow}
          email={email}
          roles={roles}
          user={user}
          projectStatus={projectStatus}
          initschedule={initschedule}
          initpreview={initpreview}
          survey={survey}
          iteration={iteration}
          iterationSchedule={iterationSchedule}
          organization={organization}
          refetch={() => {
            getDistribution?.refetch();
            setRefect(!refetch);
          }}
          refetched={refetch}
          refetching={false}
          copy={copy}
        ></Email>
      )}
    </>
  );
};

export const Email = ({
  show,
  setShow,
  email,
  roles,
  user,
  projectStatus,
  initschedule,
  initpreview,
  survey,
  iteration,
  iterationSchedule,
  organization,
  refetch,
  refetched,
  refetching,
  copy,
}) => {
  const [currEmail, setEmail] = useState({
    ...email,
    distConfig: email?.distConfig ? email?.distConfig : {},
  });

  useEffect(() => {
    if (!copy) {
      setEmail(email);
    }
  }, [refetched, email]);

  const [preview, showPreview] = useState(initpreview ? initpreview : false);
  const [surveyLinkText, setSurveyLinkText] = useState(
    currEmail.linkName ? currEmail.linkName : "Take Survey"
  );
  const [surveyLinkStyle, setSurveyLinkStyle] = useState(
    currEmail?.linkStyle
      ? JSON.parse(currEmail?.linkStyle)
      : {
          color: "#15bcc7",
          cursor: "pointer",
        }
  );
  const [linkColor, setLinkColor] = useState("rgb(21, 188, 199)");
  const [chooseIcon, setChooseIcon] = useState(false);
  const [emailContent, setEmailContent] = useState(
    email?.messageBody ? email.messageBody : ""
  );

  const [showAud, setShowAud] = useState();
  const [showSchedule, setShowSchedule] = useState(
    initschedule ? initschedule : false
  );

  const [contact, setContact] = useState(false);

  const [audienceSchedule, setAudienceSchedule] = useState(
    initAudienceSchedule(currEmail?.audience)
  );
  const [byAudience, setByAudience] = useState(false);

  const [validateSend, setValidateSend] = useState(false);
  const [viewDeliveries, setViewDeliveries] = useState(false);

  const [sent, setSent] = useState(false);

  const [previewParticipant, setPreviewParticipant] = useState({});

  const getCurrUser = useGetCurrentUser();

  const [conditions, setConditions] = useState(
    typeof email?.conditions === "string"
      ? JSON.parse(email?.conditions)
      : email?.conditions
      ? email?.conditions
      : []
  );

  const [page, setPage] = useState("edit");
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [timezone, setTimezone] = useState(initZone());
  function initZone() {
    return {
      timezone: "America/Denver",
      value: "America/Denver",
      offset: 25200,
      display: "GMT-07:00",
      label: "Mountain Time, US & Canada",
    };
    // let zone = maintimezones.find((t) => t.value === timeZone);
    // if (zone) {
    //   return zone;
    // } else {
    //   return {
    //     timezone: "America/Denver",
    //     value: "America/Denver",
    //     offset: 25200,
    //     display: "GMT-07:00",
    //     label: "Mountain Time, US & Canada",
    //   };
    // }
  }

  useEffect(() => {
    setTimeout(() => {
      setSaved(false);
    }, 3000);
  }, [saving]);

  function initAudienceSchedule(audiences) {
    if (audiences && audiences.length > 0) {
      let audSched = {};
      for (let aud of audiences) {
        audSched[aud.id] = {
          id: aud?.id,
          name: aud?.name,
          timezone: aud?.timeZone
            ? aud?.timeZone
            : "Mountain Time (US) MST UTC-7",
          date: currEmail.scheduledDate ? currEmail.scheduledDate : new Date(),
        };
      }
      return audSched;
    }
  }

  const updateDistribution = useUpdateDistribution();
  const sendDistibution = useSendDistributionGql();
  const sendDistibutionPreview = useSendDistributionPreview();
  const deleteDistribution = useDeleteDistribution();
  const orgFields = useFetchCurrOrgsFields();
  const { id } = useParams();

  function handleScheduleChange(val) {
    let newEmail = { ...currEmail };
    newEmail.scheduledDate = val.fullText;
    setEmail(newEmail);
  }

  function handleSubjectChange(value) {
    let newEmail = { ...currEmail };
    newEmail.subject = value;
    setEmail(newEmail);
  }

  function handleTimeDelayChange(e) {
    let newTime = e.target.value;
    newTime = parseInt(newTime);
    let newEmail = { ...currEmail };
    newEmail.timeDelay = newTime;
    setEmail(newEmail);
  }

  function handleTimeDelayCountChange(e) {
    let newCount = e.target.value;
    newCount = parseInt(newCount);
    let newEmail = { ...currEmail };
    newEmail.timeDelayCount = newCount;
    debugger;
    setEmail(newEmail);
  }

  const editor = useRef();

  function clickOutside(e) {
    if (iconRef?.current && !iconRef.current.contains(e.target)) {
      setChooseIcon(false);
    }
  }

  useEffect(() => {
    // setC(true);
    document.addEventListener("click", clickOutside, true);
    return () => {
      document.removeEventListener("click", clickOutside, true);
    };
  }, []);

  function sendEmail(emailId, previewContact, date) {
    if (previewContact) {
      sendDistibutionPreview.mutate(
        {
          distribution: emailId ? emailId : currEmail.id,
          projectId: id,
          preview: previewContact ? previewContact : undefined,
        },
        {
          onSuccess: (data) => {
            setShowSchedule(false);
            showPreview(false);
          },
        }
      );
    } else {
      sendDistibution.mutate(
        {
          distribution: emailId ? emailId : currEmail.id,
          projectId: id,
          audienceSchedule: byAudience ? JSON.stringify(audienceSchedule) : "",
          timeZone: timezone ? timezone.value : undefined,
        },
        {
          onSuccess: (data) => {
            setValidateSend(false);
            setShowSchedule(false);
          },
        }
      );
    }

    setSendLoading(true);
    setShowSchedule(false);
    setTimeout(() => {
      refetch();
      setValidateSend(false);
      setSendLoading(false);
    }, 1000);
  }

  function handleDeleteDistribution() {
    deleteDistribution.mutate(
      {
        id: currEmail.id,
      },
      {
        onSuccess: (data) => {
          alert("Email Deleted");
          setShow(false);
        },
      }
    );
  }

  // The sunEditor parameter will be set to the core suneditor instance when this function is called
  const getSunEditorInstance = (sunEditor) => {
    sunEditor.onDrop = handleOnDrop;
    editor.current = sunEditor;
  };

  function handleChange(content) {
    setEmailContent(content);
  }

  function handleOnDrag(e, field, elementId) {
    e.dataTransfer.setData("text/html", e.target.outerHTML);
  }

  function handleOnDrop(e, cleanData, maxCarCount, core) {
    e.dataTransfer.getData("text/html");

    e.preventDefault();
    return true;
  }
  //eslint-disable-next-line no-unused-vars
  function onSaveDraft() {
    let html = emailContent;
    if (!currEmail?.id) {
      handleCompose(html);
    } else {
      let audiences = [];
      for (let i = 0; i < currEmail?.audience?.length; i++) {
        audiences.push(currEmail?.audience[i].id);
      }
      setSaving(true);
      updateDistribution.mutate(
        {
          id: currEmail.id,
          data: {
            projectId: id,
            messageType: currEmail.messageType,
            subject: currEmail.subject,
            messageBody: html,
            sender: currEmail.sender,
            status: survey.isProgram ? "Template" : currEmail.status,
            scheduledDate: currEmail.scheduledDate,
            emailStrength: currEmail.emailStrength,
            distConfigId: currEmail?.distConfig?.id,
            conditions: JSON.stringify(conditions),
            timeDelay: currEmail.timeDelay ? currEmail.timeDelay : 1,
            timeDelayCount: currEmail.timeDelayCount
              ? currEmail.timeDelayCount
              : 1,
            name: currEmail?.name,
            icon: currEmail?.icon,
            dynamicFrom: currEmail?.dynamicFrom,
            dynamicConditions: currEmail?.dynamicConditions,
            linkName: surveyLinkText,
            showUnsub: currEmail?.showUnsub,
            replyTo: currEmail?.replyTo,
            linkStyle:
              typeof surveyLinkStyle != "string"
                ? JSON.stringify(surveyLinkStyle)
                : surveyLinkStyle,
          },
          audienceIds: { ids: audiences },
        },
        {
          onSuccess: (data) => {
            setSaving(false);
            setSaved(true);
          },
        }
      );
    }
  }

  const options = [
    {
      value: 1,
      label: "1 (second)",
      // to: `distributions`,
    },
    {
      value: 2,
      label: "2 (seconds)",
      // to: `stats`,
    },
    {
      value: 3,
      label: "3 (seconds)",
      // to: `stats`,
    },

    {
      value: 4,
      label: "4 (seconds)",
      // to: `stats`,
    },
    {
      value: 5,
      label: "5 (seconds)",
      // to: `stats`,
    },
    {
      value: 30,
      label: "30 (seconds)",
      // to: `stats`,
    },
    {
      value: 60,
      label: "60 (1 min)",
      // to: `stats`,
    },
    {
      value: 120,
      label: "120 (2 min)",
      // to: `stats`,
    },
    {
      value: 300,
      label: "300 (5 min)",
      // to: `stats`,
    },
    {
      value: 600,
      label: "600 (10 min)",
      // to: `stats`,
    },
    {
      value: 1800,
      label: "1800 (30 min)",
      // to: `stats`,
    },
    {
      value: 3600,
      label: "3600 (1 hour)",
      // to: `stats`,
    },
  ];

  const optionsNum = [
    {
      value: 1,
      label: "1 (email)",
      // to: `distributions`,
    },
    {
      value: 2,
      label: "2 (emails)",
      // to: `stats`,
    },
    {
      value: 3,
      label: "3 (emails)",
      // to: `stats`,
    },

    {
      value: 4,
      label: "4 (emails)",
      // to: `stats`,
    },
    {
      value: 5,
      label: "5 (emails)",
      // to: `stats`,
    },
    {
      value: 10,
      label: "10 (emails)",
      // to: `stats`,
    },
    {
      value: 15,
      label: "15 (emails)",
      // to: `stats`,
    },
    {
      value: 20,
      label: "20 (emails)",
      // to: `stats`,
    },
    {
      value: 25,
      label: "25 (eamils)",
      // to: `stats`,
    },
    {
      value: 30,
      label: "30 (emails)",
      // to: `stats`,
    },
    {
      value: 50,
      label: "50 (emails)",
      // to: `stats`,
    },
    {
      value: 100,
      label: "100 (emails)",
      // to: `stats`,
    },
  ];

  const createDistribution = useCreateDistribution();
  const cancelDistribution = useCancelSend();

  function cancelSend() {
    if (
      window.confirm("Are you sure you want to cancel these scheduled sends?")
    ) {
      cancelDistribution.mutate(
        {
          id: currEmail.id,
        },
        {
          onSuccess: () => {
            alert("Email Send Cancelled");
            setShow(false);
          },
        }
      );
    }
  }

  function handleCompose(html) {
    setSaving(true);
    let audiences = [];
    for (let i = 0; i < currEmail?.audience.length; i++) {
      audiences.push(currEmail?.audience[i].id);
    }
    //create link
    const data = {
      projectId: id,
      messageType: currEmail.messageType,
      emailStrength: currEmail.emailStrength,
      sender: currEmail.sender,
      status: survey.isProgram ? "Template" : currEmail.status,
      //this will have to be different in deployment
      link: "http://reaction.health/take-survey/generic/" + id + "/",
      messageBody: html,
      subject: currEmail.subject,
      conditions: JSON.stringify(conditions),
      timeDelay: currEmail.timeDelay,
      timeDelayCount: currEmail.timeDelayCount ? currEmail.timeDelayCount : 1,
      name: currEmail?.name,
      icon: currEmail?.icon,
      distConfigId: currEmail?.distConfig?.id,
      dynamicFrom: currEmail?.dynamicFrom,
      dynamicConditions: currEmail?.dynamicConditions,
      showUnsub: currEmail?.showUnsub,
      linkName: surveyLinkText,
      replyTo: currEmail?.replyTo,
      linkStyle:
        typeof surveyLinkStyle != "string"
          ? JSON.stringify(surveyLinkStyle)
          : surveyLinkStyle,
      // distConfigId: currEmail.distConfig.id,
    };
    createDistribution.mutate(
      {
        data: data,
        audienceIds: { ids: audiences },
      },
      {
        onSuccess: (data) => {
          setSaved(true);
          setSaving(false);
          let temp = { ...currEmail };
          temp.id = data.createDistribution.id;
          setEmail(temp);
          alert("Your draft has been saved.");
        },
      }
    );
  }

  function schedulesend() {
    prepareToSend(currEmail.scheduledDate, "Scheduled", false);
  }

  function sendNow(preview) {
    if (preview) {
      if (
        window.confirm(
          `Are you sure you want to send this email preview? The Email will be saved.`
        )
      ) {
        var date = new Date();
      }
      prepareToSend(date, "Preview", preview);
    } else {
      // if (projectStatus === "Open") {
      //   var date = new Date();
      //   prepareToSend(date, "Sent", false);
      // } else {
      //   alert("Survey is not open, go to survey details and open the survey to send your email.")
      // }
      var date = new Date();
      prepareToSend(date, "Sent", false);
    }
  }

  function prepareToSend(date, type, preview) {
    if (!preview && currEmail?.sendCount >= 1) {
      if (
        !window.confirm(
          "You have already sent this email out before, would you like to send it again?"
        )
      ) {
        return false;
      }
    }
    setSaving(true);
    let html = emailContent;
    if (!currEmail?.id) {
      let audiences = [];
      for (let i = 0; i < currEmail?.audience.length; i++) {
        audiences.push(currEmail?.audience[i].id);
      }
      let data = {};

      if (preview) {
        data = {
          projectId: id,
          messageType: currEmail.messageType,
          emailStrength: currEmail.emailStrength,
          sender: currEmail.sender,
          status: survey.isProgram ? "Template" : currEmail.status,
          //this will have to be different in deployment
          link: "http://reaction.health/take-survey/generic/" + id + "/",
          messageBody: html,
          subject: currEmail.subject,
          conditions: JSON.stringify(conditions),
          // scheduledDate: date,
          distConfigId: currEmail?.distConfig?.id,
          timeDelay: currEmail.timeDelay ? currEmail.timeDelay : 1,
          timeDelayCount: currEmail.timeDelayCount
            ? currEmail.timeDelayCount
            : 1,
          name: currEmail?.name,
          icon: currEmail?.icon,
          dynamicFrom: currEmail?.dynamicFrom,
          dynamicConditions: currEmail?.dynamicConditions,
          showUnsub: currEmail?.showUnsub,
          linkName: surveyLinkText,
          replyTo: currEmail?.replyTo,
          linkStyle:
            typeof surveyLinkStyle != "string"
              ? JSON.stringify(surveyLinkStyle)
              : surveyLinkStyle,
          // distConfigId: currEmail.distConfig.id,
        };
      } else {
        data = {
          projectId: id,
          messageType: currEmail.messageType,
          emailStrength: currEmail.emailStrength,
          sender: currEmail.sender,
          status: currEmail.status,
          //this will have to be different in deployment
          link: "http://reaction.health/take-survey/generic/" + id + "/",
          messageBody: html,
          subject: currEmail.subject,
          conditions: JSON.stringify(conditions),
          scheduledDate: date,
          status: survey.isProgram ? "Template" : type,
          distConfigId: currEmail?.distConfig?.id,
          timeDelay: currEmail.timeDelay ? currEmail.timeDelay : 1,
          timeDelayCount: currEmail.timeDelayCount
            ? currEmail.timeDelayCount
            : 1,
          name: currEmail?.name,
          icon: currEmail?.icon,
          dynamicFrom: currEmail?.dynamicFrom,
          dynamicConditions: currEmail?.dynamicConditions,
          showUnsub: currEmail?.showUnsub,
          linkName: surveyLinkText,
          replyTo: currEmail?.replyTo,
          linkStyle:
            typeof surveyLinkStyle != "string"
              ? JSON.stringify(surveyLinkStyle)
              : surveyLinkStyle,
          // distConfigId: currEmail.distConfig.id,
        };
      }

      createDistribution.mutate(
        {
          data: data,
          audienceIds: { ids: audiences },
        },
        {
          onSuccess: (data) => {
            setSaving(false);
            setSaved(true);
            let temp = { ...currEmail };
            temp.id = data.createDistribution.id;
            setEmail(temp);
            sendEmail(temp.id, preview, date);
          },
        }
      );
    } else {
      let audiences = [];
      for (let i = 0; i < currEmail?.audience.length; i++) {
        audiences.push(currEmail?.audience[i].id);
      }

      let update = {};
      if (preview) {
        update = {
          id: currEmail.id,
          data: {
            projectId: id,
            messageType: currEmail.messageType,
            subject: currEmail.subject,
            messageBody: html,
            sender: currEmail.sender,
            // scheduledDate: preview ? null : date,x/
            emailStrength: currEmail.emailStrength,
            distConfigId: currEmail?.distConfig?.id,
            conditions: JSON.stringify(conditions),
            timeDelay: currEmail.timeDelay ? currEmail.timeDelay : 1,
            timeDelayCount: currEmail.timeDelayCount
              ? currEmail.timeDelayCount
              : 1,
            name: currEmail?.name,
            icon: currEmail?.icon,
            dynamicFrom: currEmail?.dynamicFrom,
            dynamicConditions: currEmail?.dynamicConditions,
            showUnsub: currEmail?.showUnsub,
            linkName: surveyLinkText,
            replyTo: currEmail?.replyTo,
            linkStyle:
              typeof surveyLinkStyle != "string"
                ? JSON.stringify(surveyLinkStyle)
                : surveyLinkStyle,
          },
          audienceIds: { ids: audiences },
        };
      } else {
        update = {
          id: currEmail.id,
          data: {
            projectId: id,
            messageType: currEmail.messageType,
            subject: currEmail.subject,
            messageBody: html,
            sender: currEmail.sender,
            scheduledDate: date,
            emailStrength: currEmail.emailStrength,
            distConfigId: currEmail?.distConfig?.id,
            conditions: JSON.stringify(conditions),
            status: survey.isProgram ? "Template" : type,
            timeDelay: currEmail.timeDelay ? currEmail.timeDelay : 1,
            timeDelayCount: currEmail.timeDelayCount
              ? currEmail.timeDelayCount
              : 1,
            name: currEmail?.name,
            icon: currEmail?.icon,
            dynamicFrom: currEmail?.dynamicFrom,
            dynamicConditions: currEmail?.dynamicConditions,
            showUnsub: currEmail?.showUnsub,
            linkName: surveyLinkText,
            replyTo: currEmail?.replyTo,
            linkStyle:
              typeof surveyLinkStyle != "string"
                ? JSON.stringify(surveyLinkStyle)
                : surveyLinkStyle,
          },
          audienceIds: { ids: audiences },
        };
      }

      updateDistribution.mutate(update, {
        onSuccess: (data) => {
          setSaving(false);
          setSaved(true);
          sendEmail(currEmail.id, preview, date);
        },
      });
      //setShow(false);
      // alert("Your draft has been saved.");
      // let emailToSave = {...currEmail};
    }
  }

  const { data, loading } = useSubscription(DISTRIBUTION, {
    variables: { userId: user?.id },
  });

  const [task, setTask] = useState();

  useEffect(() => {
    if (
      !copy &&
      data &&
      data.distributionSubscription &&
      data.distributionSubscription?.itemId === currEmail?.id
    ) {
      setTask({
        ...data.distributionSubscription,
      });
      if (
        data.distributionSubscription &&
        data.distributionSubscription?.progress === 100
      ) {
        setScheduled(true);
        setTimeout(() => {
          refetch();
        }, 2000);
      }
    }
  }, [data, loading]);

  const [sendLoading, setSendLoading] = useState(
    task && task?.progress != 100 ? true : false
  );
  const [scheduled, setScheduled] = useState(
    task && task?.progress != 100 ? true : false
  );
  const iconRef = useRef();

  const [addfield, setaddfield] = useState(false);
  const [newfields, setnewfields] = useState([]);

  function getPreviewUser() {
    let user = {
      firstName: previewParticipant?.firstName
        ? previewParticipant?.firstName
        : getCurrUser.data.me.firstName,
      lastName: previewParticipant?.lastName
        ? previewParticipant?.lastName
        : getCurrUser.data.me.lastName,
      email: previewParticipant?.email
        ? previewParticipant?.email
        : getCurrUser.data.me.email,
      customField: {},
    };
    let keys = Object.keys(previewParticipant);
    if (keys) {
      for (let field of keys) {
        if (field != "firstName" && field != "lastName" && field != "email") {
          user.customField[field] = previewParticipant[field];
        }
      }
    }
    user.customField = JSON.stringify(user.customField);
    return user;
  }

  function startScroll() {
    let element = document.getElementById("leftContent");
    element.scrollTop += 1000;
  }

  function closePop(id) {
    let element = document.getElementById(id);
    element.style.display = "none";
  }

  let player1 = useRef(null);

  return (
    <>
      <ReactModal
        show={
          show && !viewDeliveries && !showSchedule && !preview && !validateSend
        }
        onClose={() => setShow(false)}
        modalStyle={{
          // borderRadius: "1em",
          height: "100%",
          maxHeight: "100%",
          maxWidth: "1000px",
          width: "98%",
          // height: "100%",
          overflow: "hidden",
          // alignItems: "center"
        }}
        dark
        rightStyle
      >
        {refetching && <Loading></Loading>}
        {!refetching && (
          <>
            <div
              className={`${styles.addFields} se-addfields`}
              id={"se-addfields"}
            >
              <div className={styles.close}>
                <i
                  className="bi-x-lg"
                  onClick={() => closePop("se-addfields")}
                ></i>
              </div>
              <AddFields
                fields={[
                  ...[
                    {
                      id: "_firstName",
                      name: "firstName",
                      displayName: "First Name",
                    },
                    {
                      id: "_lastName",
                      name: "lastName",
                      displayName: "Last Name",
                    },
                  ],
                  organization.custom_field,
                ]}
                editor={editor}
                handleOnDrag={handleOnDrag}
              ></AddFields>
            </div>
            <div
              className={`${styles.addFields} se-addbutton`}
              id={"se-addbutton"}
              style={{ overflow: "visible", maxHeight: "fit-content" }}
            >
              <div className={styles.close}>
                <i
                  className="bi-x-lg"
                  onClick={() => closePop("se-addbutton")}
                ></i>
              </div>
              <AddButton
                handleOnDrag={handleOnDrag}
                surveyLinkText={surveyLinkText}
                setSurveyLinkText={setSurveyLinkText}
                surveyLinkStyle={surveyLinkStyle}
                setSurveyLinkStyle={setSurveyLinkStyle}
                linkColor={linkColor}
                setLinkColor={setLinkColor}
              >
                {" "}
              </AddButton>
            </div>
            <div
              className={`${styles.addFields} se-addURL`}
              id={"se-addURL"}
              style={{ overflow: "visible", maxHeight: "fit-content" }}
            >
              <div className={styles.close}>
                <i
                  className="bi-x-lg"
                  onClick={() => closePop("se-addURL")}
                ></i>
              </div>
              <SurveyURL
                handleOnDrag={handleOnDrag}
                surveyLinkText={surveyLinkText}
                setSurveyLinkText={setSurveyLinkText}
                surveyLinkStyle={surveyLinkStyle}
                setSurveyLinkStyle={setSurveyLinkStyle}
                linkColor={linkColor}
                setLinkColor={setLinkColor}
              >
                {" "}
              </SurveyURL>
            </div>
            <div
              className={`${styles.addFields} se-addconditions`}
              id={"se-addconditions"}
              style={{ overflow: "visible", maxHeight: "fit-content" }}
            >
              <div className={styles.close}>
                <i
                  className="bi-x-lg"
                  onClick={() => closePop("se-addconditions")}
                ></i>
              </div>
              <ConditionBuilder
                fields={[
                  ...[
                    {
                      id: "_firstName",
                      name: "firstName",
                      displayName: "First Name",
                    },
                    {
                      id: "_lastName",
                      name: "lastName",
                      displayName: "Last Name",
                    },
                  ],
                  ...organization.custom_field,
                ]}
                handleOnDrag={handleOnDrag}
                conditions={conditions}
                setConditions={setConditions}
              ></ConditionBuilder>
            </div>

            {page === "edit" && (
              <div className={styles.page}>
                {!iteration && currEmail.status === "Scheduled" && (
                  <div className={styles.flex}>
                    <div className={styles.player}>
                      <Player
                        autoplay
                        onEvent={(e) => {
                          if (e === "play") {
                            setTimeout(() => {
                              player1?.current?.pause();
                            }, 900);
                          }
                        }}
                        ref={player1}
                        src={require("assets/animations/Calendar.json")}
                        style={{
                          height: "50px",
                          width: "50px",
                        }}
                      />
                    </div>
                    <span>
                      Email scheduled for{" "}
                      {`${seeDate(currEmail.scheduledDate)}`}
                    </span>{" "}
                    <span className={styles.cancel} onClick={cancelSend}>
                      Cancel send?
                    </span>
                    {currEmail.status != "Draft" && (
                      <span
                        onClick={() => setViewDeliveries(true)}
                        className={styles.link}
                      >
                        View Deliveries
                      </span>
                    )}
                  </div>
                )}

                {!iteration && currEmail.status === "Sent" && (
                  <div className={styles.flex}>
                    {/* <div className={styles.player}>
                  <Player
                    autoplay
                    onEvent={(e) => {
                      if (e === "play") {
                        setTimeout(() => {
                          player1?.current?.pause();
                        }, 2000);
                      }
                    }}
                    ref={player1}
                    src={require("assets/animations/checkmarkgreen.json")}
                    style={{
                      height: "40px",
                      width: "40px",
                    }}
                  />
                </div> */}
                    <div className={`${styles.method} ${styles.sent}`}>
                      Sent
                    </div>
                    <span>
                      This email has been sent{" "}
                      {currEmail.sendCount ? currEmail.sendCount : "1"}{" "}
                      {currEmail.sendCount > 1 ? "times" : "time"}
                    </span>{" "}
                    {currEmail.status != "Draft" && (
                      <span
                        onClick={() => setViewDeliveries(true)}
                        className={styles.link}
                      >
                        View Deliveries
                      </span>
                    )}
                  </div>
                )}
                {!iteration && currEmail.status === "Scheduling" && (
                  <div className={styles.flex}>
                    <div className={styles.player}>
                      {" "}
                      {/* {!scheduled && task?.progress && (
                        <Pie
                          percentage={task?.progress ? task?.progress : 0}
                          dim={40}
                          progress
                          colour={"#15bcc7"}
                          hideText
                          backColor={"#d8d9d9"}
                        />
                      )}
                      {scheduled && (
                        <Player
                          autoplay
                          onEvent={(e) => {
                            if (e === "play") {
                              setTimeout(() => {
                                player1?.current?.pause();
                              }, 2000);
                            }
                          }}
                          ref={player1}
                          src={require("assets/animations/checkmarkgreen.json")}
                          style={{
                            height: "40px",
                            width: "40px",
                          }}
                        />
                      )} */}
                    </div>
                    <span>
                      Creating and scheduling {/*currEmail.scheduleCount*/}{" "}
                      email messages...
                    </span>{" "}
                    <span className={styles.cancel} onClick={cancelSend}>
                      Cancel?
                    </span>
                    {currEmail.status != "Draft" && (
                      <span
                        onClick={() => setViewDeliveries(true)}
                        className={styles.link}
                      >
                        View Deliveries
                      </span>
                    )}
                  </div>
                )}

                {!iteration && currEmail.status === "Sending" && (
                  <div className={styles.flex}>
                    <div className={styles.player}>
                      <Player
                        autoplay
                        loop
                        src={require("assets/animations/EmailSending.json")}
                        style={{
                          height: "100px",
                          width: "100px",
                        }}
                      />
                    </div>
                    <span>Email messages are currently being sent out</span>{" "}
                    <span className={styles.cancel} onClick={cancelSend}>
                      Cancel?
                    </span>
                    {currEmail.status != "Draft" && (
                      <span
                        onClick={() => setViewDeliveries(true)}
                        className={styles.link}
                      >
                        View Deliveries
                      </span>
                    )}
                  </div>
                )}
                {!iteration && currEmail.status === "Cancelled" && (
                  <div className={styles.flex}>
                    <span>
                      Your last send was cancelled{" "}
                      {`${seeDate(currEmail.sentDate)}`}
                    </span>{" "}
                    {currEmail.status != "Draft" && (
                      <span
                        onClick={() => setViewDeliveries(true)}
                        className={styles.link}
                      >
                        View Deliveries
                      </span>
                    )}
                  </div>
                )}
                {!iteration && currEmail.status === "Cancelling" && (
                  <div className={styles.flex}>
                    <div className={styles.player}>
                      <Player
                        autoplay
                        loop
                        src={require("assets/animations/ReactionLoading.json")}
                        style={{
                          height: "60px",
                          width: "60px",
                        }}
                      />
                    </div>
                    <span>Your send is being cancelled</span>{" "}
                    {currEmail.status != "Draft" && (
                      <span
                        onClick={() => setViewDeliveries(true)}
                        className={styles.link}
                      >
                        View Deliveries
                      </span>
                    )}
                  </div>
                )}
                {currEmail.status != "Draft" && !copy && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {" "}
                    <HorizontalBar width={"98%"} height={"2px"}></HorizontalBar>
                  </div>
                )}
                <div className={styles.editorContainer}>
                  <div
                    className={styles.scrollLeft}
                    onClick={() => {
                      startScroll(true);
                    }}
                  >
                    {window.innerWidth > 1100 && <span>View Preview</span>}{" "}
                    <i className="bi-chevron-down"></i>
                  </div>

                  <div className={styles.leftContent} id="leftContent">
                    <div className={styles.header}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          paddingTop: !copy ? ".5em" : "1em",
                          gap: "1em",
                        }}
                      >
                        {copy && (
                          <Label
                            style={{
                              width: "75px",
                              justifyContent: "end",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Name:
                          </Label>
                        )}
                        <div className={styles.topheader}>
                          {/* <i
                        className={`bi bi-${
                          currEmail?.icon ? currEmail?.icon : "envelope"
                        }`}
                        style={{ cursor: "pointer" }}
                        onClick={() => setChooseIcon(!chooseIcon)}
                      ></i> */}

                          <TextFieldSimple
                            style={{}}
                            maxLength={40}
                            value={currEmail?.name}
                            className={
                              !copy ? styles.textinput : styles.textinputnew
                            }
                            onChange={(val) =>
                              setEmail({ ...currEmail, name: val })
                            }
                          ></TextFieldSimple>
                          {/* <i className={`${styles.edit} bi bi-pencil`}></i> */}
                        </div>
                      </div>

                      {chooseIcon && (
                        <div
                          style={{
                            maxWidth: "25em",
                            background: "white",
                            borderRadius: "1em",
                            padding: ".5em",
                            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                            position: "absolute",
                            top: "4em",
                            zIndex: "100",
                          }}
                          ref={iconRef}
                        >
                          <IconShopper
                            onPick={(val) =>
                              setEmail({ ...currEmail, icon: val })
                            }
                            folderView
                          />
                        </div>
                      )}
                    </div>

                    <div className={styles.topContent}>
                      <ToFrom
                        email={currEmail}
                        setEmail={setEmail}
                        show={showAud}
                        setShow={setShowAud}
                        roles={roles}
                      ></ToFrom>
                      <div className={styles.emailDelay}>
                        <div
                          style={{
                            display: "flex",
                            gap: "1em",
                          }}
                        >
                          <Label
                            style={{ width: "75px", justifyContent: "end" }}
                          >
                            Drip:
                          </Label>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: ".5em",
                            }}
                          >
                            <div className={styles.selectContainer}>
                              <select
                                onChange={handleTimeDelayCountChange}
                                value={currEmail?.timeDelayCount}
                                className={styles.timeDelay}
                              >
                                {optionsNum.map((option) => (
                                  <option
                                    value={option.value}
                                    key={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                              <i className="bi bi-chevron-down"></i>
                            </div>
                            <span>every</span>
                            <div className={styles.selectContainer}>
                              <select
                                onChange={handleTimeDelayChange}
                                value={currEmail.timeDelay}
                                className={styles.timeDelay}
                              >
                                {options.map((option) => (
                                  <option
                                    value={option.value}
                                    key={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                              <i className="bi bi-chevron-down"></i>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            height: "fit-content",
                          }}
                        >
                          <ToggleSwitch
                            startChecked={currEmail?.showUnsub}
                            handleCheck={(val) =>
                              setEmail({ ...currEmail, showUnsub: val })
                            }
                          ></ToggleSwitch>
                          <Label>Show Unsub Button</Label>
                        </div>
                      </div>
                      <div
                        style={{ display: "flex", gap: "1em", width: "100%" }}
                      >
                        <Label style={{ width: "80px", justifyContent: "end" }}>
                          Subject:
                        </Label>
                        <TextFieldSimple
                          placeholder="Type Subject..."
                          value={currEmail.subject}
                          onSave={handleSubjectChange}
                          // label="Subject"
                          style={{
                            // height: "40px",
                            width: "100%",
                            fontSize: ".8em",
                            // minWidth:" 300px"
                          }}
                        ></TextFieldSimple>
                      </div>

                      <SunEditor
                        getSunEditorInstance={getSunEditorInstance}
                        name="email-editor"
                        defaultValue={emailContent}
                        width="100%"
                        height="100%"
                        placeholder="Please type here..."
                        // autoFocus={false}
                        onChange={handleChange}
                        setOptions={{
                          buttonList: buttonList.complex,
                          // buttonList: [buttonList.basic, buttonList.formatting],
                          resizingBar: false,
                          // height: "100%",
                          // mode: "balloon",
                        }}
                      />
                    </div>

                    <div className={styles.view}>
                      <div className={styles.liveheader}>Live Preview</div>
                      <div className={styles.livepreview}>
                        {parse(
                          editMessageBody(emailContent, genContact, currEmail)
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <>
                  {
                    <div className={styles.finishButtonsContainer}>
                      <div
                        style={{
                          display: "flex",
                          gap: "1em",
                          flexWrap: "wrap",
                          // width: "500px",
                        }}
                      >
                        {currEmail.sender && (
                          <Button
                            blue
                            height={35}
                            width={150}
                            shadow
                            onClick={() => showPreview(true)}
                          >
                            Send Preview
                          </Button>
                        )}
                      </div>
                      <div className={styles.finishButtonsContainerSection}>
                        {currEmail.status === "Scheduled" && (
                          <div className={styles.reminder}>
                            {iteration && (
                              <>
                                <span>
                                  Email will be Scheduled for{" "}
                                  {`${seeDate(currEmail.scheduledDate)}`}
                                </span>{" "}
                                <span
                                  className={styles.cancel}
                                  onClick={() => iterationSchedule(true)}
                                >
                                  Change?
                                </span>
                              </>
                            )}
                          </div>
                        )}
                        {currEmail.status != "Scheduled" &&
                          currEmail.status != "Sending" &&
                          currEmail.status != "Scheduling" && (
                            <>
                              {currEmail.status != "Sent" && !copy && (
                                <>
                                  <Button
                                    red
                                    onClick={handleDeleteDistribution}
                                    height={35}
                                    shadow
                                  >
                                    Delete Email
                                  </Button>
                                </>
                              )}
                              <Button
                                blue
                                onClick={onSaveDraft}
                                height={35}
                                shadow
                              >
                                {saving && !saved && (
                                  <div style={{ display: "flex" }}>
                                    Saving{" "}
                                    <Loading height="20" width="20"></Loading>
                                  </div>
                                )}
                                {saved && !saving && (
                                  <>
                                    Saved <i className="bi-check"></i>
                                  </>
                                )}
                                {!saving && !saved && <>Save Email</>}
                              </Button>
                              {!copy &&
                                currEmail?.audience?.length > 0 &&
                                currEmail.sender &&
                                (!survey.isProgram || iteration) && (
                                  <Button
                                    blue
                                    onClick={() => {
                                      iteration
                                        ? iterationSchedule(true)
                                        : projectStatus != "Closed"
                                        ? setShowSchedule(true)
                                        : alert(
                                            "This survey is closed, if you would like to send more emails you will have to open it."
                                          );
                                    }}
                                    height={35}
                                    shadow
                                    yellow
                                  >
                                    {iteration ? "Set Send Date" : "Schedule"}
                                  </Button>
                                )}
                              {!copy &&
                                currEmail?.audience?.length > 0 &&
                                currEmail.sender &&
                                !survey.isProgram && (
                                  <Button
                                    height={35}
                                    green
                                    shadow
                                    // onClick={() => sendNow(false)}
                                    onClick={() => {
                                      projectStatus != "Closed"
                                        ? setValidateSend(true)
                                        : alert(
                                            "This survey is closed, if you would like to send more emails you will have to open it."
                                          );
                                    }}
                                  >
                                    Send Now
                                  </Button>
                                )}
                            </>
                          )}
                      </div>
                    </div>
                  }
                </>
              </div>
            )}
          </>
        )}
      </ReactModal>

      <ReactModal
        show={preview}
        onClose={() => showPreview(false)}
        modalStyle={{
          borderRadius: "1em",
          height: "fit-content",
          maxWidth: "500px",
          width: "100%",
          padding: "1em",
          overflow: "visible",
        }}
        dark
      >
        {getCurrUser.isSuccess && (
          <div className={styles.emailPreview}>
            <TextFieldSimple
              label="First Name"
              placeholder={"Type First Name..."}
              onChange={(val) =>
                setPreviewParticipant({
                  ...previewParticipant,
                  firstName: val,
                })
              }
              value={
                previewParticipant?.firstName
                  ? previewParticipant?.firstName
                  : getCurrUser.data.me.firstName
              }
            ></TextFieldSimple>
            <TextFieldSimple
              label="Last Name"
              placeholder={"Type Last Name..."}
              onChange={(val) =>
                setPreviewParticipant({
                  ...previewParticipant,
                  lastName: val,
                })
              }
              value={
                previewParticipant?.lastName
                  ? previewParticipant?.lastName
                  : getCurrUser.data.me.lastName
              }
            ></TextFieldSimple>
            <TextFieldSimple
              label="Email"
              placeholder={"Type Email..."}
              onChange={(val) =>
                setPreviewParticipant({ ...previewParticipant, email: val })
              }
              value={
                previewParticipant?.email
                  ? previewParticipant?.email
                  : getCurrUser.data.me.email
              }
            ></TextFieldSimple>
            {newfields &&
              newfields?.map((field, i) => (
                <div key={i}>
                  <TextFieldSimple
                    label={field}
                    placeholder={`Type ${field}...`}
                    onChange={(val) => {
                      let par = { ...previewParticipant };
                      par[field] = val;
                      setPreviewParticipant(par);
                    }}
                    // defaultValue={""}
                  ></TextFieldSimple>
                </div>
              ))}

            <div className={styles.addfield}>
              <span onClick={() => setaddfield(!addfield)}>+ Add Field</span>
              {addfield && (
                <div className={styles.addfields}>
                  {organization.custom_field.map((field, i) => (
                    <div
                      className={styles.userfield}
                      onClick={() => {
                        let temp = [...newfields];
                        temp.push(field.name);
                        setnewfields(temp);
                        setaddfield(false);
                      }}
                      key={i}
                    >
                      {field.displayName}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                height={35}
                shadow
                onClick={() => sendNow(getPreviewUser())}
              >
                Send
              </Button>
            </div>
          </div>
        )}
      </ReactModal>

      <ReactModal
        show={validateSend}
        onClose={() => setValidateSend(false)}
        modalStyle={{
          borderRadius: "1em",
          // height: "100%",
          maxWidth: byAudience ? "800px" : "600px",
          width: "100%",
          // padding: "1em",
          gap: "1em",
          overflow: "visible",
        }}
        dark
      >
        {sendLoading && <Loading></Loading>}
        {!sendLoading && (
          <div className={styles.validateContainer}>
            <div className={styles.valHeader}> {currEmail?.name}</div>
            <div className={styles.valDescription}>
              {!showSchedule
                ? "Review your email details before clicking send"
                : "Review your email details before scheduling your send"}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                gap: "1em",
                alignItems: "center",
              }}
            >
              <div className={styles.valSection}>
                <Label style={{ maxWidth: "180px", justifyContent: "end" }}>
                  Subject:
                </Label>{" "}
                {currEmail?.subject}
              </div>
              {!byAudience && (
                <div className={styles.valSection}>
                  <Label style={{ maxWidth: "180px", justifyContent: "end" }}>
                    Sending To:
                  </Label>{" "}
                  <div className={styles.auds}>
                    {currEmail?.audience?.map((aud, i) => (
                      <div className={styles.aud} key={i}>
                        {aud?.name}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {byAudience && (
                <div
                  className={styles.valSection}
                  style={{ alignItems: "start" }}
                >
                  <Label style={{ maxWidth: "180px", justifyContent: "end" }}>
                    Sending To:
                  </Label>{" "}
                  <div className={styles.auds2}>
                    {Object.keys(audienceSchedule)?.map((id, i) => (
                      <div key={i}>
                        <div className={styles.aud}>
                          {audienceSchedule[id]?.name}
                        </div>
                        <div className={styles.audDate}>
                          {trimDate(audienceSchedule[id]?.date)}
                          <span>
                            (
                            {
                              maintimezones.find(
                                (a) =>
                                  a.timezone === audienceSchedule[id]?.timezone
                              ).name
                            }
                            )
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div className={styles.valSection}>
                <Label style={{ maxWidth: "180px", justifyContent: "end" }}>
                  Sending From:
                </Label>{" "}
                {currEmail?.dynamicFrom ? (
                  <div className={styles.blue}>Dynamic From Address</div>
                ) : (
                  currEmail?.distConfig?.fromAddress
                )}
              </div>
              {!byAudience && (
                <div className={styles.valSection}>
                  <Label style={{ maxWidth: "180px", justifyContent: "end" }}>
                    Sending Time:
                  </Label>{" "}
                  {!showSchedule
                    ? "Immediately"
                    : getTimeString(currEmail?.scheduledDate)}
                </div>
              )}
              <div className={styles.valSection}>
                <Label style={{ maxWidth: "180px", justifyContent: "end" }}>
                  Email Drip:
                </Label>{" "}
                <div style={{ display: "flex", gap: ".5em" }}>
                  <span>
                    {currEmail?.timeDelayCount
                      ? emailString(currEmail?.timeDelayCount)
                      : "1 (email)"}
                  </span>
                  <span>every</span>
                  <span>
                    {currEmail?.timeDelay
                      ? secondString(currEmail?.timeDelay)
                      : "1 (second)"}
                  </span>
                </div>
              </div>
            </div>
            <div className={styles.valDescription2}>
              {projectStatus === "Draft"
                ? 'Sending this email will open this survey for participation and change the survey status to "Open".'
                : ""}
            </div>
            <div className={styles.valDescription}>
              {!showSchedule
                ? "Are you sure you want to send this email?"
                : "Are you sure you want to schedule this email?"}
            </div>
            <div className={styles.valButtons}>
              <Button shadow red onClick={() => setValidateSend(false)}>
                Cancel
              </Button>{" "}
              <Button
                shadow
                onClick={() => (!showSchedule ? sendNow() : schedulesend())}
              >
                {!showSchedule ? "Send Email" : "Schedule Email"}
              </Button>
            </div>
          </div>
        )}
      </ReactModal>

      <Scheduler
        showSchedule={showSchedule}
        setShowSchedule={setShowSchedule}
        currEmail={currEmail}
        setValidateSend={setValidateSend}
        handleScheduleChange={handleScheduleChange}
        validateSend={validateSend}
        audiences={currEmail?.audience}
        audienceSchedule={audienceSchedule}
        setAudienceSchedule={setAudienceSchedule}
        byAudience={byAudience}
        setByAudience={setByAudience}
        timezone={timezone}
        setTimezone={setTimezone}
        onClose={() => {
          setShowSchedule(false);
          setByAudience(false);
        }}
      ></Scheduler>

      <ReactModal
        show={viewDeliveries && !contact}
        onClose={() => setViewDeliveries(false)}
        modalStyle={{
          borderRadius: "1em",
          height: "100vh",
          width: "1400px",
          maxWidth: "100%",
          overflow: "hidden",
        }}
        dark
      >
        <Deliveries
          email={currEmail}
          roles={roles}
          organization={organization}
          user={user}
          setContact={setContact}
        ></Deliveries>
      </ReactModal>

      {contact && (
        <ContactProfile
          onClose={() => setContact(false)}
          contact={contact?.participation?.contact}
        ></ContactProfile>
      )}

      {orgFields.isSuccess && showAud && (
        <ReactModal
          show={showAud}
          onClose={() => setShowAud(null)}
          modalStyle={{
            borderRadius: "1em",
            height: "95%",
            width: "100%",
            maxWidth: "1400px",
            overflow: "hidden",
          }}
          dark
        >
          <Audience
            user={user?.id}
            audienceId={showAud?.id}
            preview
            // show={showAud}
            // onClose={() => setShowAud(null)}
            // setShow={setShowAud}
            // customFields={orgFields?.data?.getCurrOrgFields}
          ></Audience>
        </ReactModal>
      )}
    </>
  );
};

function SeeAud({ audience }) {
  return <div className={styles.selectContainer}>{audience?.name}</div>;
}

export function secondString(seconds) {
  if (seconds === 1) {
    return "1 (second)";
  }
  if (seconds <= 30) {
    return seconds + " (seconds)";
  }

  if (seconds === 3600) {
    return seconds + " (1 hour)";
  }

  const minutes = Math.floor(seconds / 60);
  return seconds + " (" + minutes + " min)";
}

export function emailString(seconds) {
  if (seconds === 1) {
    return "1 (email)";
  }
  return `${seconds} (emails)`;
}

export function getTimeString(start) {
  let date = new Date(start);
  return `${findDay(date.getDay())}, ${findMonth(
    date.getMonth()
  )} ${date.getDate()} ${date.getFullYear()} at ${militaryTimeConverter(
    `${date.getHours()}:${date.getMinutes()}`
  )}`;
}
//schedulesend

function addToString(item, val, fields, condition, i, contact, type) {
  if (i + 1 === condition[type].length) {
    if (val === "firstName") {
      item += contact.firstName;
    } else if (val === "lastName") {
      item += contact.lastName;
    } else if (val === "email") {
      item += contact.email;
    } else {
      if (fields) {
        item += fields[val];
      }
    }
  } else {
    if (val === "firstName") {
      item += contact.firstName + " ";
    } else if (val === "lastName") {
      item += contact.lastName + " ";
    } else if (val === "email") {
      item += contact.email + " ";
    } else {
      if (fields) {
        item += fields[val] + " ";
      }
    }
  }

  return item;
}

function getSpecificLink(style, dist) {
  if (process.env.PRODUCTION === "production") {
    return `<a style="color: ${style?.color}; background-color: ${style?.backgroundColor}; font-size: ${style?.fontSize}; border-radius: ${style?.borderRadius}; padding: ${style?.padding}; cursor: ${style?.cursor}" href="#">${dist?.linkName}</a>`;
  } else if (process.env.PRODUCTION === "staging") {
    return `<a style="color: ${style?.color}; background-color: ${style?.backgroundColor}; font-size: ${style?.fontSize}; border-radius: ${style?.borderRadius}; padding: ${style?.padding}; cursor: ${style?.cursor}" href="#">${dist?.linkName}</a>`;
  } else {
    return `<a style="color: ${style?.color}; background-color: ${style?.backgroundColor}; font-size: ${style?.fontSize}; border-radius: ${style?.borderRadius}; padding: ${style?.padding}; cursor: ${style?.cursor}" href="#">${dist?.linkName}</a>`;
  }
}

function editMessageBody(messageBody, contact, dist) {
  if (dist?.linkStyle) {
    let buttonStyle = JSON.parse(dist.linkStyle);
    messageBody = messageBody.replaceAll(
      "{Survey Link}",
      getSpecificLink(buttonStyle, dist)
    );
  }
  messageBody = messageBody.replaceAll(
    "{Survey URL}",
    `https://reaction.health/take-survey/specific/projectid/participationid/emailid`
  );

  messageBody = messageBody.replaceAll(
    "{participant firstName}",
    contact.firstName
  );
  messageBody = messageBody.replaceAll(
    "{participant lastName}",
    contact.lastName
  );

  //start checking conditions
  let conditions = null;

  if (dist?.conditions) {
    conditions = JSON.parse(dist.conditions);
  }

  if (conditions) {
    for (let condition of conditions) {
      if (messageBody?.includes(condition.tag)) {
        if (condition.condition === "equals") {
          let field = condition.field;
          let fields = JSON.parse(contact.customField);
          while (typeof fields === "string") {
            fields = JSON.parse(fields);
          }
          //if condition is true do show condition, else do else condition
          if (fields && field in fields && fields[field] === condition.value) {
            let show = "";
            forEach(condition.show, (val, i) => {
              show = addToString(
                show,
                val,
                fields,
                condition,
                i,
                contact,
                "show"
              );
            });

            messageBody = messageBody.replaceAll(
              `{condition ${condition.tag}}`,
              show
            );
            messageBody = messageBody.replaceAll(
              `{condition $${condition.tag}}`,
              show
            );
          } else {
            let els = "";
            forEach(condition.else, (val, i) => {
              els = addToString(
                els,
                val,
                fields,
                condition,
                i,
                contact,
                "else"
              );
            });
            messageBody = messageBody.replaceAll(
              `{condition ${condition.tag}}`,
              els
            );
            messageBody = messageBody.replaceAll(
              `{condition $${condition.tag}}`,
              els
            );
          }
        } else if (condition.condition === "does not equal") {
          let field = condition.field;
          let fields = JSON.parse(contact.customField);
          while (typeof fields === "string") {
            fields = JSON.parse(fields);
          }
          if (fields && field in fields && fields[field] != condition.value) {
            let show = "";
            forEach(condition.show, (val, i) => {
              show = addToString(
                show,
                val,
                fields,
                condition,
                i,
                contact,
                "show"
              );
            });
            messageBody = messageBody.replaceAll(
              `{condition ${condition.tag}}`,
              show
            );
            messageBody = messageBody.replaceAll(
              `{condition $${condition.tag}}`,
              show
            );
          } else {
            let els = "";
            forEach(condition.else, (val, i) => {
              els = addToString(
                els,
                val,
                fields,
                condition,
                i,
                contact,
                "else"
              );
            });
            messageBody = messageBody.replaceAll(
              `{condition ${condition.tag}}`,
              els
            );
            messageBody = messageBody.replaceAll(
              `{condition $${condition.tag}}`,
              els
            );
          }
        } else if (condition.condition === "contains") {
          let field = condition.field;
          let fields = JSON.parse(contact.customField);
          while (typeof fields === "string") {
            fields = JSON.parse(fields);
          }

          if (
            fields &&
            field in fields &&
            fields[field]?.includes(condition.value)
          ) {
            let show = "";
            forEach(condition.show, (val, i) => {
              show = addToString(
                show,
                val,
                fields,
                condition,
                i,
                contact,
                "show"
              );
            });
            messageBody = messageBody.replaceAll(
              `{condition ${condition.tag}}`,
              show
            );
            messageBody = messageBody.replaceAll(
              `{condition $${condition.tag}}`,
              show
            );
          } else {
            let els = "";
            forEach(condition.else, (val, i) => {
              els = addToString(
                els,
                val,
                fields,
                condition,
                i,
                contact,
                "else"
              );
            });
            messageBody = messageBody.replaceAll(
              `{condition ${condition.tag}}`,
              els
            );
            messageBody = messageBody.replaceAll(
              `{condition $${condition.tag}}`,
              els
            );
          }
        } else if (condition.condition === "does not contain") {
          let field = condition.field;
          let fields = JSON.parse(contact.customField);
          while (typeof fields === "string") {
            fields = JSON.parse(fields);
          }
          if (
            fields &&
            field in fields &&
            !fields[field]?.includes(condition.value)
          ) {
            let show = "";
            forEach(condition.show, (val, i) => {
              show = addToString(
                show,
                val,
                fields,
                condition,
                i,
                contact,
                "show"
              );
            });
            messageBody = messageBody.replaceAll(
              `{condition ${condition.tag}}`,
              show
            );
            messageBody = messageBody.replaceAll(
              `{condition $${condition.tag}}`,
              show
            );
          } else {
            let els = "";
            forEach(condition.show, (val, i) => {
              els = addToString(
                els,
                val,
                fields,
                condition,
                i,
                contact,
                "else"
              );
            });
            messageBody = messageBody.replaceAll(
              `{condition ${condition.tag}}`,
              els
            );
            messageBody = messageBody.replaceAll(
              `{condition $${condition.tag}}`,
              els
            );
          }
        }
      }
    }
  }
  messageBody += dist?.distConfig?.signature ? dist?.distConfig?.signature : "";

  if (dist?.showUnsub) {
    messageBody += `<div style="color: #616565; font-size: 11px; margin: 5px 0px;"><a href="#">Click here</a> to unsubscribe.</div>`;
  }

  return messageBody;
}
