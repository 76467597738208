import React, { useState } from "react";
import SurveyNumSlider from "components/inputs/input_fields/SurveyNumSlider/SurveyNumSlider";

export const NumberSlideQuestion = ({
  question,
  color,
  onAnswer,
  answerMap,
}) => {
  function onChange(val) {
    onAnswer(question.id, val);
  }

  return (
    <SurveyNumSlider
      value={answerMap[question.id]}
      scaleQ={question.scaleQuestion}
      color={color}
      selected={answerMap[question.id]}
      onChange={onChange}
    />
  );
};
