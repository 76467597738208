import { useState } from "react";
import styles from "./SurveyButton.module.scss";

export function SurveyButton({
  children,
  color,
  onClick,
  style,
  shadow,
  styleType,
  disabled,
  finish,
}) {
  const [hovering, setHovering] = useState(false);

  let colorStyle = {};

  const gray100 = "#a3a4a8";
  const gray60 = "#d8d9d9";

  if (styleType === "fill" || (finish && !disabled)) {
    colorStyle.borderColor = disabled ? gray60 : color;
    colorStyle.backgroundColor = disabled ? gray60 : color;
    colorStyle.color = "white";
    if (hovering) {
      colorStyle.backgroundColor = "white";
      colorStyle.color = disabled ? gray60 : color;
    }
  } else if (styleType === "outline") {
    colorStyle.borderColor = disabled ? gray60 : color;
    colorStyle.backgroundColor = "white";
    colorStyle.color = disabled ? gray60 : color;
    if (hovering) {
      colorStyle.backgroundColor = disabled ? gray60 : color;
      colorStyle.color = "white";
    }
  } else if (styleType === "link") {
    colorStyle.borderColor = "transparent";
    colorStyle.backgroundColor = "transparent";
    colorStyle.color = disabled ? gray60 : color;
  }

  if (shadow) {
    colorStyle.boxShadow = "rgba(0, 0, 0, 0.15) 0px 5px 15px";
  }

  if (disabled) {
    colorStyle.cursor = "not-allowed";
  }

  return (
    <button
      className={styles.customButton}
      style={colorStyle}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
