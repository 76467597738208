import React, { useState, useEffect } from "react";
import styles from "./TextField.module.scss";

/**
 * A simple and customized text field
 * @param {String} value value that is passed in as initial value and/or changeable value for the text field
 * @param {String} placeholder a placeholder for the text field
 * @param {Function} onChange called when the text field changes/is typed in and returns the event which contains the value accessed with : (e.target.value)
 * @param {string} concerning used to update an object through it's property or accessor
 * @param {Function} onSave used to reduce the amount of re-renders to update an object;
 * @returns {React.ReactElement} a simple and customized text field
 */
export const TextFieldSimple = ({
  onChange,
  value,
  placeholder,
  type,
  name,
  password,
  customStyles,
  style,
  inactive,
  copyToClipboard,
  label,
  concerning,
  onSave,
  lineTheme,
  disable,
  valid,
  validMessage,
  invalidMessage,
  characterCheck,
  onEnter,
  id,
  autoFocus,
  defaultValue,
  maxLength,
  className,
  containerStyle,
  noState,
  email,
  newPswd,
  editColor,
}) => {
  const [val, setVal] = useState(value ? value : defaultValue);
  const [copied, setCopied] = useState(false);
  const [edit, setEdit] = useState(false);
  const [initval, setinitval] = useState(value ? value : defaultValue);

  useEffect(() => {
    if (value != val) {
      setVal(value);
    }
  }, [value]);

  useEffect(() => {
    if (copied) {
      const timeId = setTimeout(() => {
        // After 3 seconds set Copied value to false
        setCopied(false);
      }, 3000);
    }
  }, [copied]);

  function handleOnChange(e) {
    setEdit(true);
    if (onChange) {
      if (concerning) {
        onChange(e.target.value, concerning);
      } else {
        onChange(e.target.value);
      }
    }
    if (!disable) {
      setVal(e.target.value);
    }
  }

  function handleFocusOut(e) {
    if (onSave && !disable) {
      if (concerning) {
        onSave(e.target.value, concerning);
      } else {
        onSave(e.target.value);
      }
    }
  }

  function handleKeyDown(e) {
    if (e.key === "Enter" || e.keyCode === 13) {
      if (concerning) {
        onEnter(e.target.value, concerning);
      }
      onEnter(e.target.value);
    }
  }

  return (
    <div
      style={{
        ...{ display: "flex", flexDirection: "column", width: "100%" },
        ...{
          backgroundColor: editColor && edit && val != initval ? "#FFF7E4" : "",
        },
        ...containerStyle,
      }}
    >
      {!inactive && (
        <div className={styles.labelContainer}>
          {label && <div className={styles.label}>{label}</div>}
          {valid === true && (
            <div className={styles.validMessage}>
              {validMessage ? validMessage : ""}
            </div>
          )}
          {valid === false && (
            <div className={styles.invalidMessage}>
              {invalidMessage ? invalidMessage : "Invalid"}
            </div>
          )}
          {copied === true && (
            <div className={styles.copied}>Copied To Clipboard!</div>
          )}
        </div>
      )}
      <div className={`${styles.inputBox}`}>
        <input
          type={type}
          name={name}
          onChange={handleOnChange}
          onBlur={handleFocusOut}
          className={`${
            customStyles
              ? customStyles
              : lineTheme
              ? styles.textFieldLine
              : styles.textField
          } ${password || newPswd ? styles.password : ""} ${
            val?.length > characterCheck ? styles.invalidBorder : ""
          } ${
            valid === false ? styles.invalidBorder : styles.validBorder
          } ${className}`}
          value={noState ? value : val ? val : ""}
          placeholder={placeholder}
          disabled={disable}
          onKeyDown={onEnter ? handleKeyDown : null}
          id={id}
          style={style}
          autoFocus={autoFocus}
          defaultValue={defaultValue}
          maxLength={maxLength}
          autoComplete={
            email
              ? "email"
              : password
              ? "current-password"
              : newPswd
              ? "new-password"
              : undefined
          }
        ></input>
        {copyToClipboard && (
          <i
            className="bi bi-clipboard"
            onClick={() => {
              navigator.clipboard.writeText(val);
              setCopied(true);
            }}
          ></i>
        )}
      </div>
    </div>
  );
};
