import styles from "./SurveyWidget.module.scss";
import { useState } from "react";
import Visualization from "pages/results/Charts/Visualization";

export default function FakeSurveyWidget({ settings }) {
  return (
    <div
      className={styles.container}
      style={
        settings?.charts?.length > 1 ? { padding: "20px" } : { padding: "0px" }
      }
    >
      {settings?.metrics?.small && ( // Made for the fake metric settings
        <div className={styles.smallMetricsContainer}>
          <div className={styles.metricsBox}>
            {settings.metrics.small.map((m, i) => (
              <div className={styles.smallMetric} key={i}>
                <div className={styles.icon}>
                  <i className={`bi bi-${m.icon}`}></i>
                </div>
                <div className={styles.text}>
                  <div className={styles.mTitle}>{m.title}</div>
                  <div className={styles.mmetric}>{m.metric}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {settings?.metrics?.big && (
        <div className={styles.bigMetricContainer}>
          <div className={styles.metricBox}>
            <div className={styles.MTitle}>{settings.metrics.big.title}</div>
            <div className={styles.Mmetric}>{settings.metrics.big.metric}</div>
          </div>
        </div>
      )}
      {settings?.charts && <FakeChartWidget settings={settings} />}
    </div>
  );
}

function FakeChartWidget({ settings }) {
  const [num, setNum] = useState(0);

  function increment() {
    if (num < settings.charts.length - 1) {
      setNum(num + 1);
    } else {
      setNum(0);
    }
  }

  function decrement() {
    if (num == 0) {
      setNum(settings.charts.length - 1);
    } else {
      setNum(num - 1);
    }
  }

  return (
    <div className={styles.chartWidgContainer}>
      <div className={styles.arrow} onClick={decrement}>
        <i className="bi bi-arrow-left"></i>
      </div>
      <div className={styles.chart} style={{ pointerEvents: "none" }}>
        <Visualization viz={settings.charts[num]} newChart />
      </div>

      <div
        className={styles.arrow}
        style={{ marginRight: "3px" }}
        onClick={increment}
      >
        <i className="bi bi-arrow-right"></i>
      </div>
    </div>
  );
}
