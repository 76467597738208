import { NumberInput } from "components/inputs/input_fields/NumberInput/NumberInput";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import {
  SelectField,
  TextFieldSimple as TextField,
  TextFieldSimple,
} from "components/inputs";
import React, { useState } from "react";
import styles from "../SettingsAccordion.module.scss";
import { Label } from "components/layouts/Label/Label";
import { DoughnutType, PieType } from "../../QuestionChart";
import Checkbox from "components/inputs/input_fields/CheckboxBlue/Checkbox";
import { splitting } from "../SettingsAccordion";
import { SegLabelEditor } from "./Axes";


//custom accordian
export const LegendSettings = ({
  viz,
  title,
  changeSettingsField,
  visible,
  setVisible,
  labels,
}) => {
  // const [visibility, setVisibility] = useState(false);

  // const toggleVisibility = () => {
  //   setVisibility((v) => !v);
  // };

  const positionOptions = [
    { label: "Top", value: "top" },
    {
      label: "Left",
      value: "left",
    },
    {
      label: "Bottom",
      value: "bottom",
    },
    {
      label: "Right",
      value: "right",
    },
  ];

  function capitalize(title) {
    return title.charAt(0).toUpperCase() + title.slice(1);
  }

  function generateLegendTitle() {
    if (viz.type === DoughnutType || viz.type === PieType) {
      if (viz.pivotString && viz.pivotString !== "nps") {
        return capitalize(viz.pivotString);
      } else {
        return "Choices";
      }
    }

    if (viz.designSettings.split) {
      return capitalize(viz.designSettings.split);
    }
    return "Choices";
  }

  function handleTitleToggle(val) {
    if (val) {
      let title = generateLegendTitle();
      changeSettingsField("legendTitle", title);
    }
    changeSettingsField("hasLegendTitle", val);
  }

  return (
    <>
      <div
        key={"legend"}
        className={`${styles.header} ${visible ? styles.headervisible : ""}`}
        onClick={setVisible}
      >
        {/* {title} */}

        <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
          {title} <i style={{ fontSize: "1.2em" }} className="bi bi-map"></i>
        </div>

        <span className={styles.accordionicon}>
          <i className="bi bi-caret-left-fill"></i>
        </span>
      </div>
      {visible && (
        <div className={styles.body}>
          <div className={styles.setting2} style={{ paddingLeft: "5px" }}>
            <ToggleSwitch
              startChecked={viz.designSettings.hasLegend}
              handleCheck={(val) => changeSettingsField("hasLegend", val)}
            ></ToggleSwitch>
            <Label
              style={{
                width: "fit-content",
                fontSize: ".9em",
              }}
            >
              Legend
            </Label>
          </div>
          {viz.designSettings.hasLegend && (
            <>
              {!splitting(viz) && (
                <div
                  style={{
                    // display: "flex",
                    // flexDirection: "row",
                    // justifyContent: "center",
                    paddingLeft: "10px",
                    margin: "5px 0px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <div className={styles.setting2}>
                      <Checkbox
                        checked={viz.designSettings.legendLabel}
                        onChange={(e) =>
                          changeSettingsField("legendLabel", e.target.checked)
                        }
                      />
                      <Label
                        style={{
                          width: "fit-content",
                          fontSize: ".9em",
                          paddingLeft: 0,
                        }}
                      >
                        Label
                      </Label>
                    </div>
                    <div
                      className={styles.setting2}
                      // style={{ width: "fit-content" }}
                    >
                      <Checkbox
                        checked={viz.designSettings.legendValue}
                        onChange={(e) =>
                          changeSettingsField("legendValue", e.target.checked)
                        }
                      />
                      <Label
                        style={{
                          width: "fit-content",
                          fontSize: ".9em",
                          paddingLeft: 0,
                        }}
                      >
                        {viz.designSettings.byPercent ? "Value (%)" : "Value"}
                      </Label>
                    </div>
                    {!viz.designSettings.byPercent && (
                      <div
                        className={styles.setting2}
                        // style={{ width: "fit-content" }}
                      >
                        <Checkbox
                          checked={viz.designSettings.legendPercent}
                          onChange={(e) =>
                            changeSettingsField(
                              "legendPercent",
                              e.target.checked
                            )
                          }
                        />
                        <Label
                          style={{
                            width: "fit-content",
                            fontSize: ".9em",
                            paddingLeft: 0,
                          }}
                        >
                          %
                        </Label>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {labels && (
                <div
                  className={styles.setting}
                  style={{ paddingLeft: "5px", alignItems: "flex-start" }}
                >
                  <Label
                    style={{
                      fontSize: ".9em",
                      paddingLeft: "0px",
                      paddingBottom: "5px",
                    }}
                  >
                    Labels
                  </Label>

                  <SegLabelEditor
                    viz={viz}
                    changeSettingsField={changeSettingsField}
                    labels={labels}
                  />
                </div>
              )}

              <div
                className={styles.setting2}
                style={{ paddingLeft: "5px", gap: "10px" }}
              >
                <div>
                  <SelectField
                    value={positionOptions.find(
                      (opt) => opt.value === viz.designSettings.legendPosition
                    )}
                    onChange={(option) =>
                      changeSettingsField("legendPosition", option.value)
                    }
                    options={positionOptions}
                  ></SelectField>
                </div>
                <Label
                  style={{
                    fontWeight: "400",
                    width: "fit-content",
                    fontSize: ".8em",
                    whiteSpace: "nowrap",
                    padding: "0em",
                    margin: "0em",
                  }}
                >
                  Position
                </Label>
              </div>

              {/* <div className={styles.setting}>
                  <Label
                    italics
                    style={{
                      fontWeight: "500",
                      width: "fit-content",
                      fontSize: ".9em",
                    }}
                  >
                    Use Points
                  </Label>
                  <ToggleSwitch
                    startChecked={viz.designSettings.legendPointStyle}
                    handleCheck={(val) =>
                      changeSettingsField("legendPointStyle", val)
                    }
                  ></ToggleSwitch>
                </div> */}

              <div className={styles.setting2} style={{ paddingLeft: "5px" }}>
                <ToggleSwitch
                  startChecked={viz.designSettings.hasLegendTitle}
                  handleCheck={handleTitleToggle}
                ></ToggleSwitch>
                <Label
                  // italics
                  style={{
                    fontSize: ".9em",
                    width: "fit-content",
                  }}
                >
                  Legend Title
                </Label>
              </div>

              {viz.designSettings.hasLegendTitle && (
                <>
                  <div
                    className={styles.settingwithlabel}
                    style={{ paddingBottom: "10px" }}
                  >
                    <Label
                      style={{
                        fontWeight: "400",
                        paddingLeft: "20px",
                        fontSize: ".8em",
                      }}
                    >
                      Title Text
                    </Label>
                    <TextFieldSimple
                      value={viz.designSettings.legendTitle}
                      onChange={(val) =>
                        changeSettingsField("legendTitle", val)
                      }
                      style={{ height: "30px", fontSize: "1em" }}
                    ></TextFieldSimple>
                  </div>

                  <div
                    className={styles.setting2}
                    style={{ paddingLeft: "5px" }}
                  >
                    <div className={styles.inputContainer}>
                      <NumberInput
                        startNumber={viz.designSettings.legendFontSize}
                        handleNumberChange={(val) =>
                          changeSettingsField("legendFontSize", val)
                        }
                      ></NumberInput>
                    </div>
                    <Label
                      style={{
                        fontWeight: "400",
                        width: "fit-content",
                        fontSize: ".8em",
                        whiteSpace: "nowrap",
                        padding: "0em",
                        margin: "0em",
                      }}
                    >
                      Font Size
                    </Label>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};
