// eslint-disable-next-line no-unused-vars
import React, { useState } from "react";
import _ from "lodash";
import styles from "./NumChoices.module.scss";

/**
 * An input component designed for the NumberScale question. It displays the numbers in a row of clickable circles that highlights the circle that has been clicked
 * @param {Int} min minimum value
 * @param {Int} max maximum value
 * @param {Int} step the number between each value in the number scale.
 * @param {Function} onChange is called when a number is clicked on and passes in the clicked value
 * @param {Int} selected the int val of the on selected
 * @param {styling} color the fill color
 * @returns {React.ReactElement} a numberscale clickable component
 */

export const NumChoices = ({
  min,
  max,
  step,
  minDescription,
  maxDescription,
  onChange,
  selected,
  color,
}) => {
  const [active, setActive] = useState(selected);
  const range = _.range(min, max + 1, step);
  const [hovering, setHovering] = useState(100);

  const handleChange = (value) => {
    if (value === active) {
      setActive(null);
      setHovering(100);
    } else {
      setActive(value);
    }

    if (onChange) {
      onChange(value);
    }
  };

  function getStyle(value) {
    let style = {};

    if (active == value || hovering == value) {
      style = {
        backgroundColor: color ? color : "#2A627C",
        color: "#ffffff",
        borderColor: color,
      };
    } else {
      style = {
        // backgroundColor: color ? color : "#2A627C",
        // color: color,
        // borderColor: color
      };
    }
    return style;
  }

  function getStyleBorder() {
    let style = {
      backgroundColor: color ? color : "#2A627C",
      color: "#ffffff",
      borderColor: color,
    };

    return style;
  }

  return (
    <div className={styles.container}>
      <div className={styles.stack}>
        {(minDescription || maxDescription) && (
          <div className={styles.minMax}>
            <div>{minDescription}</div>
            <div>{maxDescription}</div>
          </div>
        )}
        <div
          className={styles.itemContainer}
          // style={
          //   minDescription || maxDescription
          //     ? { padding: "0px 15px" }
          //     : undefined
          // }
        >
          {range.map((value, index) => {
            return (
              <div
                key={index}
                onMouseEnter={() => setHovering(value)}
                onMouseLeave={() => setHovering(100)}
                className={`${styles.item} ${active == value && styles.active}`}
                style={getStyle(value)}
                onClick={() => handleChange(value)}
              >
                {value}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
