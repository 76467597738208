import styles from "./Link.module.scss";
import { useState } from "react";
import { SmallCheckbox } from "components/inputs/input_fields/SmallCheckbox/SmallCheckbox";
import { TextColorPicker } from "../../TextColorPicker";

export const defaultLink = {
  asButton: false,
  text: "here",
  surveyLink: true,
  unsub: false,
  color: "#15bcc7",
};

export function Link({ colorRef, onLinkEdit }) {
  const [link, setLink] = useState({ ...defaultLink });

  function updateLink(copy) {
    onLinkEdit(copy);
    setLink(copy);
  }

  function changeField(field, val) {
    let copy = { ...link };
    copy[field] = val;
    updateLink(copy);
  }

  function changeToLink() {
    let copy = { ...link };
    copy.asButton = false;
    updateLink(copy);
  }

  function changeToButton() {
    let copy = { ...link };
    copy.asButton = true;
    updateLink(copy);
  }

  function makeSurveyLink(val) {
    let copy = {
      ...link,
      surveyLink: val,
      unsub: false,
      custom: false,
    };

    if ((val && copy.text === "unsubscribe") || copy.text === "custom") {
      copy.text = "here";
    }

    updateLink(copy);
  }

  function makeUnsub(val) {
    let copy = {
      ...link,
      surveyLink: false,
      unsub: val,
      custom: false,
    };

    if ((val && copy.text === "here") || copy.text === "custom") {
      copy.text = "unsubscribe";
    }

    updateLink(copy);
  }

  function makeCustom(val) {
    let copy = {
      ...link,
      surveyLink: false,
      unsub: false,
      custom: val,
    };

    if ((val && copy.text === "unsubscribe") || copy.text === "here") {
      copy.text = "custom";
    }

    if (!("url" in copy)) {
      copy.url = "http://";
    }

    updateLink(copy);
  }

  function onKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      e.target.blur();
    }
  }

  function onURLKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      e.target.blur();
    } else if (e.key === " ") {
      e.preventDefault();
    }
  }

  return (
    <div className={styles.linkBuilder}>
      {/* <div className={styles.subSection}>
        <div className={styles.linkCheck}>
          <SmallCheckbox checked={!link.asButton} onChange={changeToLink} />
          <div
            className={`${styles.checkLabel} ${
              link.asButton ? "" : styles.chosenCheckLabel
            } `}
          >
            Link
          </div>
        </div>

        <div className={styles.linkCheck}>
          <SmallCheckbox checked={link.asButton} onChange={changeToButton} />
          <div
            className={`${styles.checkLabel} ${
              link.asButton ? styles.chosenCheckLabel : ""
            } `}
          >
            Button
          </div>
        </div>
      </div> */}

      <div className={styles.textInputHolder}>
        <div className={styles.textInputLabel}>Text</div>
        <input
          type="text"
          className={styles.textInput}
          placeholder="here"
          value={link.text}
          onChange={(e) => changeField("text", e.target.value)}
          style={{
            color: link.color,
            textDecoration: link.underline ? "underline" : "",
          }}
          onKeyDown={onKeyDown}
        ></input>
      </div>

      <div className={styles.subSection}>
        <div className={styles.linkCheck}>
          <div className={styles.colorLabel}>
            Color <i className="bi bi-paint-bucket"></i>
          </div>
          <TextColorPicker
            colorRef={colorRef}
            color={link.color}
            onChange={(color) => changeField("color", color)}
          />
        </div>

        {!link.asButton && (
          <div className={styles.linkCheck}>
            <SmallCheckbox
              checked={link.underline}
              onChange={(val) => changeField("underline", val)}
            />
            <div
              className={`${styles.checkLabel} ${
                link.underline ? styles.chosenCheckLabel : ""
              } `}
              style={{ textDecoration: "underline", fontSize: ".75rem" }}
            >
              U
            </div>
          </div>
        )}
      </div>

      <div className={styles.subSection}>
        <div className={styles.linkCheck}>
          <SmallCheckbox checked={link.surveyLink} onChange={makeSurveyLink} />
          <div
            className={`${styles.checkLabel} ${
              link.surveyLink ? styles.chosenCheckLabel : ""
            } `}
          >
            Link to Survey
          </div>
        </div>

        <div className={styles.linkCheck}>
          <SmallCheckbox checked={link.unsub} onChange={makeUnsub} />
          <div
            className={`${styles.checkLabel} ${
              link.unsub ? styles.chosenCheckLabel : ""
            } `}
          >
            Link to Unsubscribe
          </div>
        </div>

        <div className={styles.linkCheck}>
          <SmallCheckbox checked={link.custom} onChange={makeCustom} />
          <div
            className={`${styles.checkLabel} ${
              link.custom ? styles.chosenCheckLabel : ""
            } `}
          >
            Custom URL
          </div>
        </div>
      </div>

      {link.custom && (
        <div className={styles.textInputHolder}>
          <div className={styles.textInputLabel}>URL</div>
          <input
            type="text"
            className={`${styles.textInput} ${styles.urlInput}`}
            placeholder="http://"
            value={link.url}
            onChange={(e) => changeField("url", e.target.value)}
            onKeyDown={onURLKeyDown}
          ></input>
        </div>
      )}
    </div>
  );
}
