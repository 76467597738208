import { useRef, useState } from "react";
import { ColorPicker } from "components/inputs/input_fields/ColorPicker/ColorPicker";
import styles from "../../SettingsAccordion.module.scss";
import segStyles from "./SegmentSettings.module.scss";
import { PaletteColorPicker } from "components/ColorPalette/EditColors/PaletteColorPicker";
import { GradientColorPicker } from "components/ColorPalette/GradientBuilder/GradientColorPicker";
import { getSortedArray } from "assets/functions/ArrayFunctions";
import TextEditorPlain from "components/inputs/input_fields/TextEditorPlain/TextEditorPlain";
import { NoSort } from "pages/results/Charts/QuestionChart";

export const SegColors = ({
  labels,
  changeSettingsField,
  viz,
  gradientColors,
  gradientMode,
  reProportion,
  wasPalette,
  setWasPalette,
  disableColors,
}) => {
  function changeSingle(seg, val) {
    let afterSort = viz.designSettings.afterSort;
    let inOrder = getSortedArray(labels.segLabels, (a, b) =>
      afterSort ? a.sorted - b.sorted : a.orig - b.orig
    );
    let newColors = inOrder.map((seg) => seg.color);
    newColors[afterSort ? seg.sorted : seg.orig] = val;
    changeSettingsField("colors", newColors);
  }

  function applySingle() {
    if (viz.designSettings.gradient) {
      changeSettingsField("gradient", undefined);
      // officially remove the gradient.
    }
    setWasPalette(false);
  }

  function cancelSingle(seg, color) {
    if (wasPalette || viz.designSettings.gradient) {
      changeSettingsField("colors", undefined);
    } else {
      changeSingle(seg, color);
    }
  }

  const [beforeChange, setBeforeChange] = useState();
  const [oldGradient, setOldGradient] = useState();

  function saveState() {
    setOldGradient(JSON.parse(JSON.stringify(viz.designSettings.gradient)));
    setBeforeChange(JSON.parse(JSON.stringify(gradientColors)));
  }

  function addAnchor(color, index) {
    if (!beforeChange) {
      saveState();
    }

    if (!gradientColors[index].anchor) {
      let copy = [...gradientColors];
      copy[index].anchor = true;
      copy[index].color = color;

      if (!viz.designSettings.gradient.blended) {
        if (index !== copy[index].realInd) {
          // in a reversed gradient
          // find num away from grad after
          let distance = 0;
          for (let i = index + 1; i < copy.length; i++) {
            distance++;
            if (copy[i].anchor) {
              copy[index].length = copy[i].length - distance;
              copy[i].length = distance;
              break;
            }
          }

          copy[index].reversed = true;
        } else {
          // find num away from anchor before
          let distance = 0;
          for (let i = index - 1; i >= 0; i--) {
            distance++;
            if (copy[i].anchor) {
              copy[index].length = copy[i].length - distance;
              copy[i].length = distance;
              break;
            }
          }
          copy[index].reversed = false;
        }
      }
      // have to find if it is in the middle of a reversed one,

      let gradient = reProportion(copy, viz.designSettings.gradient.blended);
      changeSettingsField("gradient", gradient);
      return;
    }

    let anchorInd = -1;
    for (let i = 0; i <= index; i++) {
      if (gradientColors[i].anchor) {
        anchorInd++;
      }
    }

    let copy = [...viz.designSettings.gradient.anchors];
    copy[anchorInd].color = color;

    let gradient = { ...viz.designSettings.gradient };
    gradient.anchors = copy;
    changeSettingsField("gradient", gradient);
  }

  function removeAnchor(index) {
    if (!beforeChange) {
      saveState();
    }
    let copy = [...gradientColors];
    copy[index].anchor = false;

    if (!viz.designSettings.gradient.blended) {
      // length goes to one before it...
      for (let i = index - 1; i >= 0; i--) {
        if (copy[i].anchor) {
          copy[i].length += copy[index].length;
        }
      }
    }

    let gradient = reProportion(copy, viz.designSettings.gradient.blended);
    changeSettingsField("gradient", gradient);
  }

  function onCancel() {
    if (beforeChange) {
      changeSettingsField("gradient", oldGradient);
      setOldGradient(null);
      setBeforeChange(null);
    }
  }

  function onApply() {
    setBeforeChange(null);
    setOldGradient(null);
  }

  function editText(seg, newLabel) {
    if (seg.origLabel && newLabel === seg.origLabel) {
      let copy = { ...viz.designSettings?.segLabelReplacements };
      delete copy[seg.orig];
      changeSettingsField("segLabelReplacements", copy);
    } else {
      let replacement = { ...viz.designSettings?.segLabelReplacements };
      replacement[seg.orig] = newLabel;
      changeSettingsField("segLabelReplacements", replacement);
    }
  }

  return (
    <div className={styles.segLabels}>
      {getSortedArray(labels.segLabels, (a, b) => a.sorted - b.sorted).map(
        (seg) => (
          <Seg
            seg={seg}
            changeSingle={changeSingle}
            applySingle={applySingle}
            cancelSingle={cancelSingle}
            gradientMode={gradientMode}
            viz={viz}
            addAnchor={addAnchor}
            removeAnchor={removeAnchor}
            onCancel={onCancel}
            onApply={onApply}
            now={gradientColors}
            orig={beforeChange}
            disableColors={disableColors}
            onTextChange={(val) => editText(seg, val)}
          />
        )
      )}
    </div>
  );
};

function Seg({
  seg,
  changeSingle,
  applySingle,
  cancelSingle,
  gradientMode,
  viz,
  addAnchor,
  removeAnchor,
  onCancel,
  onApply,
  now,
  orig,
  disableColors,
  onTextChange,
}) {
  const usingTags =
    (viz.pivotString === "survey tag" ||
      viz.designSettings.split === "survey tag") &&
    viz.designSettings?.useTagColor;

  function startTextChange(val) {
    if (val !== seg.label) {
      onTextChange(seg, val);
    }
    if (!val && seg.origLabel) {
      // If they delete a previously changed label, go back to the original in the placeholder
      onTextChange(seg, seg.origLabel);
    }
  }

  if (seg.label === "Undefined") {
    console.log(seg.color);
  }

  return (
    <div className={styles.segLabelContainer} key={seg.orig}>
      {usingTags && (
        <div style={{ pointerEvents: "none" }}>
          <ColorPicker
            defaultColor={seg.color}
            onChange={(val) => changeSingle(seg, val)}
            disable={disableColors}
          />
        </div>
      )}
      {!usingTags && (
        <>
          {(!gradientMode || !viz.designSettings.gradient) && (
            <Color
              color={seg.color}
              onChange={(color) => changeSingle(seg, color)}
              onApply={applySingle}
              onCancel={(color) => cancelSingle(seg, color)}
              disable={disableColors}
            />
          )}
          {gradientMode && viz.designSettings.gradient && (
            <GradientColor
              color={seg.color}
              index={seg.sorted}
              addAnchor={addAnchor}
              removeAnchor={removeAnchor}
              onCancel={onCancel}
              onApply={onApply}
              blended={viz.designSettings.gradient.blended}
              now={now}
              orig={orig}
              disable={disableColors}
            />
          )}
        </>
      )}

      {/* <div className={styles.segLabel}>{seg.label}</div> */}
      <TextEditorPlain
        text={seg.label}
        onSave={startTextChange}
        onEnter={(e) => e.target.blur()}
        editable
        extraClass={styles.segLabelEditor}
      />
    </div>
  );
}

function Color({ color, onChange, onApply, onCancel, disable }) {
  const [orig, setOrig] = useState(color);

  function handleCancel() {
    onCancel(orig);
  }

  function onConfirm() {
    setOrig(color);
    onApply();
  }

  return (
    <PaletteColorPicker
      color={color}
      orig={orig}
      onChange={onChange}
      onCancel={handleCancel}
      onApply={onConfirm}
      disable={disable}
    />
  );
}

function GradientColor({
  color,
  addAnchor,
  removeAnchor,
  index,
  onCancel,
  onApply,
  now,
  orig,
  blended,
  disable,
}) {
  return (
    <GradientColorPicker
      color={color}
      orig={orig ? orig[index].color : color}
      start={blended ? !index : !now[index].realInd}
      end={blended ? index == now.length - 1 : false}
      anchor={orig ? orig[index].anchor : now[index].anchor}
      addAnchor={(clr) => addAnchor(clr, index)}
      removeAnchor={() => removeAnchor(index)}
      onApply={onApply}
      onCancel={onCancel}
      disable={disable}
    />
  );
}
