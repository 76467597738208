import styles from "../SettingsAccordion.module.scss";
import { HorizBarType, LineType } from "../../QuestionChart";
import { Label } from "components/layouts/Label/Label";
import { ToggleSwitch } from "components/inputs/input_fields/ToggleSwitch/ToggleSwitch";
import { NumberInput } from "components/inputs/input_fields/NumberInput/NumberInput";
import { splitting } from "../SettingsAccordion";
import { getSortedArray } from "assets/functions/ArrayFunctions";
import TextEditorPlain from "components/inputs/input_fields/TextEditorPlain/TextEditorPlain";
import { ColorPicker } from "components/inputs/input_fields/ColorPicker/ColorPicker";
import { generateAxisTitles } from "./SplitsNPivots";
import { genStatAxisTitles } from "./StatSplitsNPivots";
import { useState } from "react";
import { PaletteColorPicker } from "components/ColorPalette/EditColors/PaletteColorPicker";

export const Axes = ({
  viz,
  title,
  changeSettingsField,
  visible,
  setVisible,
  labels,
  updateViz,
}) => {
  const autoStyle = {
    color: "#616565",
    display: "flex",
    gap: "5px",
    alignItems: "center",
    cursor: "pointer",
  };

  function Auto({ onClick }) {
    return (
      <div style={autoStyle} onClick={onClick}>
        <i
          className="bi bi-arrow-counterclockwise"
          style={{ color: "#15bcc7" }}
        ></i>
        auto
      </div>
    );
  }

  const toggleAutoGenAxis = (val) => {
    let newViz = { ...viz };
    newViz.designSettings.autoGenAxisTitles = val;
    if (val) {
      if (newViz.dataMethod === "survey") {
        genStatAxisTitles(newViz);
      } else {
        generateAxisTitles(newViz);
      }
    }
    updateViz(newViz);
  };

  function onAxisLabelChange(axis, newLabel) {
    if (axis.origLabel && newLabel === axis.origLabel) {
      let copy = { ...viz.designSettings?.axisLabelReplacements };
      delete copy[axis.orig];
      changeSettingsField("axisLabelReplacements", copy);
    } else {
      let replacement = { ...viz.designSettings?.axisLabelReplacements };
      replacement[axis.orig] = newLabel;
      changeSettingsField("axisLabelReplacements", replacement);
    }
  }

  const hidingLabels =
    viz.type === HorizBarType
      ? viz.designSettings.hideYticks
      : viz.designSettings.hideXticks;

  return (
    <>
      <div
        key={viz.id}
        className={`${styles.header} ${visible ? styles.headervisible : ""}`}
        onClick={setVisible}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "1em" }}>
          {title} <i style={{ fontSize: "1.2em" }} className="bi-graph-up"></i>
        </div>
        <span className={styles.accordionicon}>
          <i className="bi bi-caret-left-fill"></i>
        </span>
      </div>
      {visible && (
        <div className={styles.body} style={{ paddingLeft: "25px" }}>
          <div className={styles.setting}>
            <Label
              style={{
                paddingLeft: "0px",
                fontSize: ".9em",
                paddingBottom: "5px",
              }}
            >
              Axis Labels
            </Label>
            <div className={styles.setting2}>
              <ToggleSwitch
                startChecked={!viz.designSettings.hideXticks}
                handleCheck={(val) => changeSettingsField("hideXticks", !val)}
              ></ToggleSwitch>
              <Label
                style={{
                  width: "fit-content",
                  fontSize: ".9em",
                }}
              >
                X Axis Labels
              </Label>
            </div>
            <div className={styles.setting2}>
              <ToggleSwitch
                startChecked={!viz.designSettings.hideYticks}
                handleCheck={(val) => changeSettingsField("hideYticks", !val)}
              ></ToggleSwitch>
              <Label
                style={{
                  width: "fit-content",
                  fontSize: ".9em",
                }}
              >
                Y Axis Labels
              </Label>
            </div>
          </div>

          {labels && !hidingLabels && (
            <div
              className={styles.setting}
              style={{ alignItems: "flex-start" }}
            >
              <Label
                style={{
                  fontSize: ".9em",
                  padding: "5px 0px",
                }}
              >
                Labels
              </Label>

              {splitting(viz) && (
                <div className={styles.segLabels}>
                  {getSortedArray(
                    labels.axisLabels,
                    (a, b) => a.sorted - b.sorted
                  ).map((seg) => (
                    <SegLabel
                      seg={seg}
                      onTextChange={onAxisLabelChange}
                      viz={viz}
                    />
                  ))}
                </div>
              )}

              {!splitting(viz) && (
                <SegLabelEditor
                  viz={viz}
                  changeSettingsField={changeSettingsField}
                  labels={labels}
                />
              )}
            </div>
          )}

          <div className={styles.setting}>
            <Label
              style={{
                fontSize: ".9em",
                padding: "5px 0px",
              }}
            >
              Titles
            </Label>
            <div className={styles.setting2}>
              <ToggleSwitch
                startChecked={viz.designSettings.hasAxisTitles}
                handleCheck={(val) => changeSettingsField("hasAxisTitles", val)}
              ></ToggleSwitch>
              <Label
                style={{
                  fontSize: ".9em",
                }}
              >
                Axis Titles
              </Label>
            </div>
            {viz.designSettings.hasAxisTitles && (
              <>
                <div className={styles.setting2}>
                  <ToggleSwitch
                    startChecked={viz.designSettings.autoGenAxisTitles}
                    handleCheck={toggleAutoGenAxis}
                  ></ToggleSwitch>
                  <Label
                    style={{
                      width: "fit-content",
                      fontSize: ".9em",
                    }}
                  >
                    Auto-Generate
                  </Label>
                </div>

                <div className={styles.setting2} style={{ paddingTop: "5px" }}>
                  <Label
                    style={{
                      fontSize: ".9em",
                      paddingLeft: "5px",
                      width: "fit-content",
                    }}
                  >
                    X:
                  </Label>
                  <div className={styles.segLabelContainer}>
                    <TextEditorPlain
                      text={viz.designSettings.XAxisTitle}
                      onSave={(val) => changeSettingsField("XAxisTitle", val)}
                      onEnter={(e) => e.target.blur()}
                      editable
                      extraClass={styles.segLabelEditor}
                    />
                  </div>
                </div>
                <div className={styles.setting2}>
                  <Label
                    style={{
                      fontSize: ".9em",
                      paddingLeft: "5px",
                      width: "fit-content",
                    }}
                  >
                    Y:
                  </Label>
                  <div className={styles.segLabelContainer}>
                    <TextEditorPlain
                      text={viz.designSettings.YAxisTitle}
                      onSave={(val) => changeSettingsField("YAxisTitle", val)}
                      onEnter={(e) => e.target.blur()}
                      editable
                      extraClass={styles.segLabelEditor}
                    />
                  </div>
                </div>

                {/* <div
                    className={styles.setting}
                    style={{ paddingBottom: "20px" }}
                  >
                    <Label
                      italics
                      style={{
                        fontWeight: "500",
                        width: "fit-content",
                        fontSize: ".9em",
                      }}
                    >
                      Font Size{" "}
                    </Label>
                    <NumberInput
                      startNumber={viz.designSettings.axisTitleFontSize}
                      handleNumberChange={(val) =>
                        changeSettingsField("axisLabelTitleSize", val)
                      }
                    ></NumberInput>
                  </div> */}
              </>
            )}
          </div>

          <div className={styles.setting}>
            <Label
              style={{
                fontSize: ".9em",
                padding: "0px 0px 5px 0px",
              }}
            >
              Lines
            </Label>

            <div className={styles.setting2}>
              <ToggleSwitch
                startChecked={!viz.designSettings.hideBorder}
                handleCheck={(val) => changeSettingsField("hideBorder", !val)}
              ></ToggleSwitch>
              <Label
                style={{
                  fontWeight: "400",
                  width: "fit-content",
                  fontSize: ".8em",
                  whiteSpace: "nowrap",
                }}
              >
                Axis Border
              </Label>
            </div>
            <div className={styles.setting2}>
              <ToggleSwitch
                startChecked={viz.designSettings.hideTicks}
                handleCheck={(val) => changeSettingsField("hideTicks", val)}
              ></ToggleSwitch>
              <Label
                style={{
                  fontWeight: "400",
                  width: "fit-content",
                  fontSize: ".8em",
                  whiteSpace: "nowrap",
                }}
              >
                Hide All Lines
              </Label>
            </div>

            {!viz.designSettings.hideTicks && (
              <>
                <div
                  className={styles.setting2}
                  style={{ paddingLeft: "10px", marginTop: "5px" }}
                >
                  <ToggleSwitch
                    startChecked={viz.designSettings.drawXLines}
                    handleCheck={(val) =>
                      changeSettingsField("drawXLines", val)
                    }
                  ></ToggleSwitch>
                  <Label
                    italics
                    style={{
                      fontWeight: "400",
                      width: "fit-content",
                      fontSize: ".8em",
                      whiteSpace: "nowrap",
                    }}
                  >
                    X Axis
                  </Label>
                </div>
                <div
                  className={styles.setting2}
                  style={{ paddingLeft: "10px" }}
                >
                  <ToggleSwitch
                    startChecked={viz.designSettings.drawYLines}
                    handleCheck={(val) =>
                      changeSettingsField("drawYLines", val)
                    }
                  ></ToggleSwitch>
                  <Label
                    italics
                    style={{
                      fontWeight: "400",
                      width: "fit-content",
                      fontSize: ".8em",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Y Axis
                  </Label>
                </div>
                <div
                  className={styles.setting2}
                  style={{ marginTop: "5px", paddingLeft: "10px" }}
                >
                  <div className={styles.inputContainer}>
                    <NumberInput
                      startNumber={viz.designSettings.lineWidth}
                      handleNumberChange={(val) =>
                        changeSettingsField("lineWidth", val)
                      }
                      min={1}
                    ></NumberInput>
                  </div>
                  <Label
                    italics
                    style={{
                      fontWeight: "400",
                      width: "fit-content",
                      fontSize: ".8em",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Line Width
                  </Label>
                </div>
              </>
            )}
          </div>

          <div className={styles.settingsComponent}>
            <Label
              style={{
                width: "fit-content",
                fontSize: ".8em",
                whiteSpace: "nowrap",
                padding: "5px 0px",
              }}
            >
              {`${viz.type === HorizBarType ? "X" : "Y"} Axis Scale`}
            </Label>
            <div
              className={styles.settingContainer}
              style={{ paddingRight: "10px" }}
            >
              <div className={styles.setting}>
                <div
                  className={styles.setting2}
                  style={{ width: "fit-content" }}
                >
                  <Label
                    italics
                    style={{
                      fontWeight: "400",
                      width: "fit-content",
                      fontSize: ".8em",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Min{" "}
                  </Label>
                  <div className={styles.inputContainer}>
                    <NumberInput
                      startNumber={viz.designSettings.graphMin}
                      onSave={(val) => changeSettingsField("graphMin", val)}
                      max={
                        viz.designSettings.graphMax
                          ? viz.designSettings.graphMax - 1
                          : undefined
                      }
                      min={
                        viz.designSettings.answerType === "nps score" ? -100 : 0
                      }
                    ></NumberInput>
                  </div>
                </div>
                {viz.designSettings.graphMin !== null && (
                  <Auto onClick={() => changeSettingsField("graphMin", null)} />
                )}
              </div>

              <div className={styles.setting}>
                <div
                  className={styles.setting2}
                  style={{ width: "fit-content" }}
                >
                  <Label
                    italics
                    style={{
                      fontWeight: "400",
                      width: "fit-content",
                      fontSize: ".8em",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Max{" "}
                  </Label>
                  <div className={styles.inputContainer}>
                    <NumberInput
                      startNumber={viz.designSettings.graphMax}
                      onSave={(val) => changeSettingsField("graphMax", val)}
                      min={
                        viz.designSettings.graphMin
                          ? viz.designSettings.graphMin + 1
                          : undefined
                      }
                    ></NumberInput>
                  </div>
                </div>
                {viz.designSettings.graphMax !== null && (
                  <Auto onClick={() => changeSettingsField("graphMax", null)} />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export function SegLabelEditor({ viz, changeSettingsField, labels }) {
  const [wasPalette, setWasPalette] = useState(
    !viz.designSettings.colors && !viz.designSettings.gradient
  );

  function editText(seg, newLabel) {
    if (seg.origLabel && newLabel === seg.origLabel) {
      let copy = { ...viz.designSettings?.segLabelReplacements };
      delete copy[seg.orig];
      changeSettingsField("segLabelReplacements", copy);
    } else {
      let replacement = { ...viz.designSettings?.segLabelReplacements };
      replacement[seg.orig] = newLabel;
      changeSettingsField("segLabelReplacements", replacement);
    }
  }

  function changeSingle(seg, val) {
    let origOrder = getSortedArray(labels.segLabels, (a, b) => a.orig - b.orig);
    let newColors = origOrder.map((seg) => seg.color);
    newColors[seg.orig] = val;
    changeSettingsField("colors", newColors);
  }

  function applySingle() {
    if (viz.designSettings.gradient) {
      changeSettingsField("gradient", undefined);
      // officially remove the gradient.
    }
    setWasPalette(false);
  }

  function cancelSingle(seg, orig) {
    if (wasPalette || viz.designSettings.gradient) {
      changeSettingsField("colors", undefined);
    } else {
      changeSingle(seg, orig);
    }
  }

  return (
    <div className={styles.segLabels}>
      {getSortedArray(labels.segLabels, (a, b) => a.sorted - b.sorted).map(
        (seg) => (
          <SegLabel
            seg={seg}
            onTextChange={editText}
            viz={viz}
            onColorChange={changeSingle}
            onApply={applySingle}
            onCancel={cancelSingle}
          />
        )
      )}
    </div>
  );
}

export function SegLabel({
  onTextChange,
  seg,
  viz,
  onColorChange,
  onApply,
  onCancel,
}) {
  const [orig, setOrig] = useState(seg.color);

  function sortingConflict() {
    let sort = viz.designSettings.sortData;
    if (sort == AtoZ || sort == ZtoA) {
      return true;
    }
    return false;
  }

  function startTextChange(val) {
    if (val !== seg.label) {
      onTextChange(seg, val);
    }
    if (!val && seg.origLabel) {
      // If they delete a previously changed label, go back to the original in the placeholder
      onTextChange(seg, seg.origLabel);
    }
  }

  function handleCancel() {
    onCancel(seg, orig);
  }

  function onConfirm() {
    setOrig(seg.color);
    onApply();
  }

  return (
    <div className={styles.segLabelContainer}>
      {seg.color &&
        !(
          viz.type === LineType &&
          viz.designSettings.hasUniformPointColor &&
          !splitting(viz)
        ) && (
          <PaletteColorPicker
            color={seg.color}
            orig={orig}
            onChange={(color) => onColorChange(seg, color)}
            onCancel={handleCancel}
            onApply={onConfirm}
          />
        )}
      <TextEditorPlain
        text={seg.label}
        onSave={startTextChange}
        onEnter={(e) => e.target.blur()}
        editable
        extraClass={styles.segLabelEditor}
      />
    </div>
  );

  // {
  //   sortingConflict() && (
  //     <div className={styles.sortWarning}>May be sorted differently</div>
  //   );
  // }
}
