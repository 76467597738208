import React, { useState, useRef } from "react";
import { SketchPicker } from "react-color";
import { Popover } from "@mui/material";
import styles from "./PaletteColorPicker.module.scss";
import { ChartColors } from "assets/functions/ColorFunctions";

/**
 * A color picker component
 * @param {Array} colors something
 * @param {String} defaultColor something
 * @param {Function} onChange a function that is called when a color is chosen
 * @returns {React.ReactElement} a simple color picker component
 */

export const PaletteColorPicker = ({
  color,
  orig,
  onChange,
  onCancel,
  onApply,
  onRemove,
  disable,
}) => {
  const [show, setShow] = useState(false);

  const pickerRef = useRef();

  const handleClose = () => {
    setShow(false);
    onCancel();
  };

  const handleColorChange = (colr) => {
    onChange(colr.hex);
  };

  function setUpColorPicker() {
    if (!disable) {
      setShow(true);
    }
  }

  function handleApply() {
    setShow(false);
    onApply(color);
  }

  function handleRemove() {
    setShow(false);
    onRemove();
  }

  return (
    <div style={{ cursor: disable ? "" : "pointer" }}>
      <div ref={pickerRef} onClick={setUpColorPicker}>
        {!color && <div className={styles.addColor}>+</div>}

        {color && (
          <div
            style={{
              height: "1.25em",
              width: "1.25em",
              borderRadius: "5px",
              backgroundColor: color,
            }}
          ></div>
        )}
      </div>

      <Popover
        open={show}
        anchorEl={pickerRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          sx: { backgroundColor: "transparent", boxShadow: "none" },
        }}
      >
        <div className={styles.sketchPicker}>
          <SketchPicker
            color={color ? color : "#15BCC7"}
            presetColors={ChartColors}
            onChangeComplete={handleColorChange}
          />
          {/* {color && (
            <div className={styles.options}>
              <div className={styles.button} onClick={handleClose}>
                Apply
              </div>
            </div>
          )} */}
          <div className={styles.options}>
            {orig && orig !== color && (
              <>
                <div className={styles.blueButton} onClick={handleApply}>
                  Apply <i className="bi bi-paint-bucket"></i>
                </div>

                <div className={styles.button} onClick={onCancel}>
                  Revert <i className="bi bi-x"></i>
                </div>
              </>
            )}

            {onRemove && orig && orig === color && (
              <div className={styles.button} onClick={handleRemove}>
                Delete <i className="bi bi-trash"></i>
              </div>
            )}
          </div>
        </div>
      </Popover>
    </div>
  );
};
