import { useEffect, useState } from "react";
import styles from "./AddToWidget.module.scss";
import {
  useAddChartToWidgets,
  useFetchWidgetsOfType,
} from "api/resources/account/widget";
import Widget from "../Widgets/Widget";
import { useUpdateWidgetArray } from "api/resources/account/dashboard";
import Button from "components/Button/Button";
import DashShadow from "./DashShadow";
import { useCheckOrgDash } from "api/resources/organization/organization";
import { useFetchRole } from "api/resources/organization/roles";
import { TabPill } from "components/layouts";
import { Loading } from "components/Loading/Loading";
import { useGetCurrentUser } from "api/resources/organization/users";
import { PickUser, BackToTable, PickRole } from "../PickDashTable";

export default function AddToWidget({
  onClose,
  onFinished,
  alreadyIn,
  viz,
  report,
  tile,
}) {
  const checkOrgDash = useCheckOrgDash();
  const getUser = useGetCurrentUser();
  const role = useFetchRole();

  const [showShadow, setShowShadow] = useState(tile ? true : false);

  function onClickOutside(e) {
    if (e.target === document.getElementById("outside AddToWidget")) {
      document.removeEventListener("click", onClickOutside, true);
      if (onClose) {
        onClose();
      }
    }
  }

  useEffect(() => {
    document.addEventListener("click", onClickOutside, true);
    return () => {
      document.removeEventListener("click", onClickOutside, true);
    };
  }, []);

  return (
    <div className={styles.popoutBackground} id="outside AddToWidget">
      <div
        className={styles.popout}
        style={showShadow ? { height: "85vh", width: "90vw" } : undefined}
      >
        {onClose && (
          <div className={styles.exitBtn}>
            <i className={`${"bi bi-x-lg"}`} onClick={onClose}></i>
          </div>
        )}
        {role.isSuccess && checkOrgDash.isSuccess && getUser.isSuccess && (
          <WhosDash
            onClose={onClose}
            onFinished={onFinished}
            alreadyIn={alreadyIn}
            viz={viz}
            report={report}
            tile={tile}
            showShadow={showShadow}
            setShowShadow={setShowShadow}
            me={getUser.data.me}
            mine={
              role.data.role.canEditPersonalDashboard
                ? getUser.data.me.dashboardId
                : ""
            }
            orgs={
              checkOrgDash.data.organization.showDash &&
              checkOrgDash.data.organization.dashboard.id &&
              role.data.role.canEditOrgDashboard
                ? checkOrgDash.data.organization.dashboard.id
                : ""
            }
            canEditOthers={role.data.role.canEditOthersDashboards}
            myRoles={
              role.data.role.canEditRoleDashboards
                ? role.data.role.dashboardId
                : ""
            }
            canEditRoles={role.data.role.canEditRoleDashboards}
            role={role.data.role}
          />
        )}
      </div>
    </div>
  );
}

function WhosDash({
  onClose,
  onFinished,
  alreadyIn,
  viz,
  report,
  tile,
  showShadow,
  setShowShadow,
  me,
  mine,
  orgs,
  canEditOthers,
  myRoles,
  canEditRoles,
  role,
}) {
  const [dashId, setDashId] = useState(
    mine ? mine : orgs ? orgs : myRoles ? myRoles : ""
  );
  const [otherUser, setOtherUser] = useState(null);
  const [otherRole, setOtherRole] = useState(null);

  let menuItems = [];
  if (mine) {
    menuItems.push({
      id: 0,
      name: "My Dash",
      dashId: mine,
    });
  }
  if (orgs) {
    menuItems.push({
      id: 1,
      name: "Org Dash",
    });
  }
  if (canEditOthers) {
    menuItems.push({
      id: 2,
      name: "Other's",
    });
  }
  if (myRoles) {
    menuItems.push({
      id: 3,
      name: `${role.name}'s Dash`,
    });
  }
  if (canEditRoles) {
    menuItems.push({
      id: 4,
      name: "Other Role's",
    });
  }

  const [activeTab, setActiveTab] = useState(
    mine
      ? 0
      : orgs
      ? 1
      : myRoles
      ? 3
      : canEditOthers
      ? 2
      : canEditOthers
      ? 4
      : -1
  );

  const widgetsOfType = useFetchWidgetsOfType(
    viz ? "charts" : report ? "reports" : "",
    dashId
  );

  function onTabSelect(num) {
    if (!num) {
      setDashId(mine);
    } else if (num == 1) {
      setDashId(orgs);
    } else if (num == 2) {
      if (dashId) {
        setDashId("");
        setOtherUser(null);
      }
    } else if (num == 3) {
      setDashId(myRoles);
    } else if (num == 4) {
      if (dashId) {
        setDashId("");
        setOtherRole(null);
      }
    }
    setActiveTab(num);
  }

  return (
    <>
      {menuItems.length > 1 && (
        <TabPill
          tabBarItems={menuItems}
          active={activeTab}
          updateActive={onTabSelect}
        />
      )}
      {activeTab == 2 && (
        <>
          {!dashId && (
            <PickUser
              onSelectUser={(user) => {
                setDashId(user.dashboardId);
                setOtherUser(user);
              }}
              user={me}
            />
          )}
          {dashId && otherUser && (
            <div className={styles.afterUserChosen}>
              <div
                className={styles.viewing}
              >{`Viewing ${otherUser.firstName} ${otherUser.lastName}'s dash`}</div>
              <div className={styles.backToTable}>
                <BackToTable
                  onClick={() => {
                    setDashId("");
                    setOtherUser(null);
                  }}
                />
              </div>
              <div style={{ width: "10px", height: "10px", flex: "1" }}></div>
            </div>
          )}
        </>
      )}
      {activeTab == 4 && (
        <>
          {!dashId && (
            <PickRole
              onSelectRole={(role) => {
                setDashId(role.dashboardId);
                setOtherRole(role);
              }}
              user={me}
            />
          )}
          {dashId && otherRole && (
            <div className={styles.afterUserChosen}>
              <div
                className={styles.viewing}
              >{`Viewing ${otherRole.name}'s dash`}</div>
              <div className={styles.backToTable}>
                <BackToTable
                  onClick={() => {
                    setDashId("");
                    setOtherRole(null);
                  }}
                />
              </div>
              <div style={{ width: "10px", height: "10px", flex: "1" }}></div>
            </div>
          )}
        </>
      )}
      {widgetsOfType.isLoading && <Loading />}
      {dashId && widgetsOfType.isSuccess && (
        <Decider
          widgetsOfType={widgetsOfType?.data?.getWidgetsOfType}
          showShadow={showShadow}
          setShowShadow={setShowShadow}
          onClose={onClose}
          onFinished={onFinished}
          alreadyIn={alreadyIn}
          viz={viz}
          report={report}
          tile={tile}
          dashboardId={dashId}
        />
      )}
      {!mine && !orgs && !canEditRoles && (
        <div
          style={{
            height: "85%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Sorry, there are no dashboards for you to add this to.
        </div>
      )}
    </>
  );
}

function Decider({
  widgetsOfType,
  showShadow,
  setShowShadow,
  onClose,
  onFinished,
  alreadyIn,
  viz,
  report,
  tile,
  dashboardId,
}) {
  const [showBackArrow, setShowBackArrow] = useState(tile ? true : false);

  useEffect(() => {
    if (widgetsOfType == null) {
      setShowShadow(true);
      setShowBackArrow(false);
    } else {
      setShowShadow(false);
      setShowBackArrow(true);
    }
  }, [widgetsOfType]);

  return (
    <>
      {!showShadow && widgetsOfType && (
        <Menu
          data={widgetsOfType}
          onFinished={onFinished}
          close={onClose}
          addToNew={() => {
            setShowShadow(true);
            setShowBackArrow(true);
          }}
          alreadyIn={alreadyIn}
          viz={viz}
          report={report}
        />
      )}
      {showShadow && (
        <>
          <DashShadow
            onCompleted={() => {
              if (onFinished) {
                onFinished();
              }
              onClose();
            }}
            viz={viz}
            report={report}
            tile={tile}
            dashboardId={dashboardId}
          />
          {showBackArrow && (
            <div
              className={styles.back}
              onClick={() => {
                if (tile) {
                  onClose();
                } else {
                  setShowShadow(false);
                  setShowBackArrow(false);
                }
              }}
            >
              <i className="bi bi-arrow-left"></i>
            </div>
          )}
        </>
      )}
    </>
  );
}

function Menu({ data, onFinished, close, addToNew, alreadyIn, viz, report }) {
  if (typeof data.dashLayouts === "string") {
    data.dashLayouts = JSON.parse(data.dashLayouts);
  }
  const updateWidgets = useUpdateWidgetArray();
  const addChartToWidgets = useAddChartToWidgets();

  const multiple = data.widgets.length > 1;

  const initChosen = () => {
    if (!alreadyIn || !alreadyIn.length) {
      return data.widgets.map((w) => false);
    }
    let array = [];

    for (let w of data.widgets) {
      if (alreadyIn.includes(w.id)) {
        array.push(true);
      } else {
        array.push(false);
      }
    }

    return array;
  };

  const [chosen, setChosen] = useState(initChosen());
  const [widgets, setWidgets] = useState(data.widgets); // Made a state so that pauses in interaction don't reverse the updates to the widgets
  const [edited, setEdited] = useState(false);

  const showEdited = () => {
    if (report && edited) {
      return true;
    }
    if (viz && edited) {
      for (let c of chosen) {
        if (c) return true;
      }
    }
    return false;
  };

  function addVizToWidgets() {
    let widgetIds = [];
    for (let i = 0; i < data.widgets.length; i++) {
      if (chosen[i]) {
        widgetIds.push(data.widgets[i].id);
      }
    }

    if (widgetIds.length) {
      addChartToWidgets.mutate(
        {
          vizId: viz.id,
          widgetIdArray: {
            widgetIds: widgetIds,
          },
        },
        {
          onSuccess: (data) => {
            if (onFinished) {
              onFinished();
            }
            close();
          },
        }
      );
    }
  }

  function sendUpdate() {
    for (let widget of widgets) {
      widget.settings = JSON.stringify(widget.settings);
    }
    updateWidgets.mutate(
      {
        jsonWidgets: JSON.stringify(widgets),
      },
      {
        onSuccess: (data) => {
          if (onFinished) {
            onFinished();
          }
          close();
        },
        onError: (data) => {},
      }
    );
  }

  function pinReport(widget) {
    widget.settings.reports.push({
      id: report.id,
      name: report.name,
      content: report.content,
    });
    return widget;
  }

  function unPinReport(widget) {
    let index = widget.settings.reports.findIndex((r) => r.id === report.id);
    if (index >= 0) {
      widget.settings.reports.splice(index, 1);
    }
  }

  function onOptionClick(widget, i) {
    if (typeof widget.settings === "string") {
      widget.settings = JSON.parse(widget.settings);
    }

    if (report) {
      if (chosen[i]) {
        unPinReport(widget);
      } else {
        pinReport(widget);
      }
    }

    let newChosen = [...chosen];
    newChosen[i] = !newChosen[i];
    setChosen(newChosen);
    setEdited(true);
  }

  return (
    <div className={styles.layout}>
      {multiple && (
        <div className={styles.title}>
          {report && "Select widgets to pin the report to"}
          {viz && "Select widgets to add the visual to"}
        </div>
      )}
      {!multiple && (
        <div className={styles.link}>
          <>
            {report && (
              <div
                onClick={() => {
                  pinReport(widgets[0]);
                  sendUpdate();
                }}
              >
                Pin to this Widget
              </div>
            )}
            {viz && (
              <div
                onClick={() => {
                  chosen[0] = true;
                  addVizToWidgets();
                }}
              >
                Copy to this Widget
              </div>
            )}
          </>
        </div>
      )}
      <div className={styles.widgetsContainer}>
        {data.widgets.map((w, i) => {
          let layout = data.dashLayouts.lg[w.index];
          if (!layout) {
            return <></>;
          }

          if (typeof w.settings === "string") {
            w.settings = JSON.parse(w.settings);
          }
          return (
            <div
              className={styles.widgetHolder}
              onClick={() => onOptionClick(w, i)}
            >
              <div
                style={{
                  height: layout.h + "0em",
                  width: layout.w + "0em",
                  pointerEvents: "none",
                  position: "relative",
                }}
              >
                <Widget widget={w} layout={layout} />
                {chosen[i] && (
                  <div className={styles.chosen}>
                    <i className="bi bi-check-lg"></i>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      {showEdited() && (
        <div className={styles.save}>
          <Button
            green
            height={30}
            onClick={viz ? addVizToWidgets : sendUpdate}
          >
            Save
          </Button>
        </div>
      )}

      <div className={styles.addToNewBtn}>
        <div style={{ color: "#616565" }}>or</div>

        <div className={styles.link} onClick={addToNew}>
          Add to a new one
        </div>
      </div>
    </div>
  );
}
