import styles from "./ScaleMatrixOption.module.scss";
import { useState } from "react";

export function ScaleMatrixOption({
  num,
  selected,
  handleCheck,
  color,
  showNum,
}) {
  const [hovering, setHovering] = useState(false);

  function onCheck() {
    if (selected) {
      setHovering(false);
    }
    handleCheck(!selected);
  }

  return (
    <div className={styles.container}>
      <div
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        className={`${styles.checkbox} ${showNum && styles.number}`}
        style={
          selected || hovering
            ? {
                backgroundColor: color ? color : "#2A627C",
                color: "#ffffff",
                borderColor: color ? color : "#2A627C",
              }
            : undefined
        }
        onClick={onCheck}
      >
        {showNum ? num : ""}
      </div>
    </div>
  );
}
