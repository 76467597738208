/*
======================= START OF LICENSE NOTICE =======================
  Copyright (C) 2023 Reaction. All Rights Reserved

  NO WARRANTY. THE PRODUCT IS PROVIDED BY DEVELOPER "AS IS" AND ANY
  EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, THE
  IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
  PURPOSE ARE DISCLAIMED. IN NO EVENT SHALL DEVELOPER BE LIABLE FOR
  ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL
  DAMAGES (INCLUDING, BUT NOT LIMITED TO, PROCUREMENT OF SUBSTITUTE
  GOODS OR SERVICES; LOSS OF USE, DATA, OR PROFITS; OR BUSINESS
  INTERRUPTION) HOWEVER CAUSED AND ON ANY THEORY OF LIABILITY, WHETHER
  IN CONTRACT, STRICT LIABILITY, OR TORT (INCLUDING NEGLIGENCE OR
  OTHERWISE) ARISING IN ANY WAY OUT OF THE USE OF THE PRODUCT, EVEN
  IF ADVISED OF THE POSSIBILITY OF SUCH DAMAGE.
======================== END OF LICENSE NOTICE ========================
  Primary Author: natehanson
*/

// External
import { useQueryClient } from "react-query";
import { gql } from "graphql-request";

// Internal
import { useGqlQuery, useGqlMutation } from "api/Api";

// GRAPHQL API

export const useGetViz = (id) => {
  const query = gql`
    query GetVizualizationByID($id: String!) {
      getVizualizationByID(id: $id) {
        designSettings
        title
        filters
        id
        pivotString
        projectIdsArray
        tagIdsArray
        type
        questionTags
        programIds
        comparison
        question {
          id
          type
          questionText
          projectId
          buckets
          hasBuckets
          isScore
          scaleQuestion {
            id
            min
            max
            step
          }
          textQuestion {
            id
            bucket {
              id
              name
              properties
            }
          }
          choiceQuestion {
            id
            choices
            isMultiSelect
          }
        }
        dynamicQs {
          id
          type
          questionText
          projectId
          buckets
          hasBuckets
          isScore
          scaleQuestion {
            id
            min
            max
            step
          }
          textQuestion {
            id
            bucket {
              id
              name
              properties
            }
          }
          choiceQuestion {
            id
            choices
          }
        }
        comparisonQs {
          id
          type
          questionText
          projectId
          buckets
          hasBuckets
          isScore
          scaleQuestion {
            id
            min
            max
            step
          }
          textQuestion {
            id
            bucket {
              id
              name
              properties
            }
          }
          choiceQuestion {
            id
            choices
          }
        }
      }
    }
  `;

  return useGqlQuery(["viz", id], query, { id: id });
};

export const useCreateVisualization = () => {
  const mutation = gql`
    mutation Mutation($data: visualizationInput!) {
      createVisualization(data: $data) {
        designSettings
        id
        title
        type
        pivotString
        filters
        projectIdsArray
        tagIdsArray
        question {
          id
          index
          type
          questionText
          projectId
          buckets
          hasBuckets
          scaleQuestion {
            id
            min
            max
            step
          }
          textQuestion {
            id
          }
          choiceQuestion {
            id
            choices
          }
          matrixQuestion {
            id
            options
          }
        }
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("analysis");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useUpdateVisualization = () => {
  const mutation = gql`
    mutation Mutation($data: visualizationInput!, $id: String!) {
      updateVisualization(data: $data, id: $id) {
        designSettings
        id
        title
        projectIdsArray
        tagIdsArray
        type
        pivotString
        filters
        question {
          id
          index
          type
          pageOrderIndex
          questionText
          projectId
          buckets
          hasBuckets
          scaleQuestion {
            id
            min
            max
            step
          }
          textQuestion {
            id
          }
          choiceQuestion {
            id
            choices
          }
        }
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      // queryClient.invalidateQueries("visualizations");
      queryClient.invalidateQueries("analysis");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useDeleteOneVisualization = () => {
  const mutation = gql`
    mutation DeleteVisualization($id: String!) {
      deleteVisualization(id: $id)
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("visualizaiton");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useCopyVizToPage = () => {
  const mutation = gql`
    mutation Mutation(
      $reportId: String!
      $analysisId: String!
      $data: visualizationInput!
    ) {
      page: copyVizToPage(
        reportId: $reportId
        analysisId: $analysisId
        data: $data
      ) {
        id
        name
      }
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("visualizaiton");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useDeleteWidgetCharts = () => {
  const mutation = gql`
    mutation DeleteVisualizations($chartIds: VizIdsArray!) {
      deleteVisualizations(chartIds: $chartIds)
    }
  `;
  const queryClient = useQueryClient();
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
    onSettled: () => {
      queryClient.invalidateQueries("visualizaiton");
    },
  };

  return useGqlMutation(mutation, options);
};

export const useGetComparisonQs = () => {
  const mutation = gql`
    mutation Mutation($comparison: String!) {
      comparisonQs: getQsFromComparison(comparison: $comparison) {
        id
        index
        type
        questionText
        projectId
        hasBuckets
        buckets
        scaleQuestion {
          id
          min
          max
          step
        }
        textQuestion {
          id
        }
        choiceQuestion {
          id
          choices
          hasOtherOption
          otherOptionLabel
          isRanking
          limit
        }
        matrixQuestion {
          id
          options
          type
        }
      }
    }
  `;
  const options = {
    onError: (err, _project, rollback) => {
      if (rollback) rollback();
    },
  };

  return useGqlMutation(mutation, options);
};
