import { useFetchMetrics } from "api/resources/account/widget";
import styles from "./SurveyWidget.module.scss";
import { useEffect, useState } from "react";
import { QuestionMetric } from "components/Charts/Metrics/MetricChart";

export default function SmallMetrics({ settings, id }) {
  function getQueryObj() {
    let query = {
      projectIds: settings?.survey?.id ? [settings?.survey?.id] : [],
      filters: "{}",
    };
    for (let m of settings.metrics.small) {
      if (!m.question) {
        query[m.code] = true;
      }
    }
    return query;
  }

  const [query, setQuery] = useState(getQueryObj());

  const getMetrics = useFetchMetrics(query, id);

  useEffect(() => {
    setQuery(getQueryObj());
  }, [settings?.survey?.id, settings.metrics]);

  useEffect(() => {
    getMetrics.refetch();
  }, [query]);

  return (
    <div
      className={styles.smallMetricsContainer}
      style={{
        ...{ justifyContent: settings.metricAlignment },
        ...(settings.showName ? { paddingTop: "5px" } : {}),
      }}
    >
      <div className={styles.metricsBox}>
        {settings.metrics.small.map((m, i) => (
          <div
            className={styles.smallMetric}
            key={i}
            style={{
              flexDirection: settings.metricOrder,
            }}
          >
            <div
              className={styles.icon}
              // style={{ visibility: m.showIcon ? "visible" : "hidden" }}
            >
              <i className={`bi bi-${m.icon}`}></i>
            </div>
            <div
              className={styles.text}
              style={
                settings.metricOrder === "row-reverse"
                  ? { textAlign: "right" }
                  : undefined
              }
            >
              <div className={styles.mTitle}>{m.title}</div>
              <div className={styles.mmetric}>
                {!m.question && getMetrics.isSuccess && (
                  <>
                    {getMetrics.data.getMetrics[m.code] !== undefined
                      ? getMetrics.data.getMetrics[m.code]
                      : m.metric}
                  </>
                )}
                {m?.question && <QuestionMetric metric={m} />}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
