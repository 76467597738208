import { useEffect, useRef, useState } from "react";

import styles from "./QuestionSettings.module.scss";
import Button from "components/Button/Button";
import { Label } from "components/layouts/Label/Label";
import { ChoiceSettings } from "./Choice/ChoiceSettings";
import { TextSettings } from "./Text/TextSettings";
import { ScaleSettings } from "./Scale/ScaleSettings";
import { MatrixSettings } from "./Matrix/MatrixSettings";

export const questionTypes = [
  {
    label: "Multiple Choice",
    value: "MultipleChoice",
    icon: <i className="bi bi-list-ul"></i>,
  },

  {
    label: "Free Response",
    value: "Text",
    icon: <i className="bi bi-pencil"></i>,
  },

  {
    label: "Number Scale",
    value: "NumberScale",
    icon: <i className="bi bi-123"></i>,
  },
  {
    label: "Ranking",
    value: "Ranking",
    icon: <i className="bi bi-list-ol"></i>,
    // icon: <i className="bi bi-bar-chart-steps"></i>,
  },
  {
    label: "Number Slider",
    value: "NumberSlider",
    icon: <i className="bi bi-sliders"></i>,
  },
  {
    label: "Matrix",
    value: "Matrix",
    // icon: <i className="bi bi-sliders2"></i>,
    icon: <i className="bi bi-bar-chart-steps"></i>,
    // icon: <i className="bi bi-grid-3x3"></i>,
  },
];

export const QuestionSettings = ({
  question,
  saveQuestion,
  onCancel,
  dependents,
  setUpLogicResolver,
  onRetract,
}) => {
  const sectionTypes = {
    FormRegistration: {
      label: "Form Registration",
      value: "Form Registration",
      name: "Form Registration",
      icon: <i className="bi bi-list-check"></i>,
    },
    Description: {
      label: "Description",
      value: "Description",
      name: "Description",
      icon: <i className="bi bi-chat-left-text"></i>,
    },
  };

  function handleChangeType(type) {
    if (question.type !== type) {
      if (dependents) {
        setUpLogicResolver(
          "change the type of question on",
          "edit",
          question,
          () => finishChangingType(type)
        );
      } else {
        finishChangingType(type);
      }
    } else if (
      question.choiceQuestion &&
      type === "MultipleChoice" &&
      question.choiceQuestion.isRanking
    ) {
      // won't affect dependents
      finishChangingType(type);
    }
  }

  function finishChangingType(type) {
    let oldType = question.type;
    question.type = type;
    if (type === "MultipleChoice") {
      question.choiceQuestion = {
        isMultiSelect: false,
        isRandomized: false,
        hasOtherOption: false,
        isRanking: false,
        otherOptionLabel: "Other",
        limit: null,
        choices: question.choiceQuestion?.choices
          ? question.choiceQuestion.choices
          : [],
      };
      question.scaleQuestion = null;
      question.textQuestion = null;
      question.matrixQuestion = null;
    } else if (type === "NumberScale" || type === "NumberSlider") {
      question.scaleQuestion = {
        min: 1,
        minDescription: "",
        max: 5,
        maxDescription: "",
        step: 1,
      };
      question.choiceQuestion = null;
      question.textQuestion = null;
    } else if (type === "Text") {
      question.textQuestion = {
        placeholder: "Enter Text",
        maxLength: 1000,
        autoBucket: true,
        bucket: [],
      };
      question.choiceQuestion = null;
      question.scaleQuestion = null;
      question.matrixQuestion = null;
    } else if (type === "Ranking") {
      question.choiceQuestion = {
        isMultiSelect: false,
        isRandomized: false,
        isRanking: true,
        hasOtherOption: false,
        otherOptionLabel: "Other",
        limit: null,
        choices: question.choiceQuestion?.choices
          ? question.choiceQuestion.choices
          : [],
      };
      question.type = "MultipleChoice";
      question.scaleQuestion = null;
      question.textQuestion = null;
      question.matrixQuestion = null;
    } else if (type === "Matrix") {
      question.matrixQuestion = {
        options: [],
        settings: {},
        type: oldType,
      };
    }
    question.isScore = false;
    question.isComment = false;
    question.questionTag = [];

    saveQuestion(question);
    onRetract(question.id);
  }

  const currentType =
    question.choiceQuestion &&
    question.choiceQuestion?.isRanking &&
    !question.matrixQuestion
      ? questionTypes.find((t) => t.value === "Ranking")
      : questionTypes.find((t) => t.value === question.type);

  return (
    <div className={styles.settingsContainer}>
      <div className={`${styles.dialog} ${question ? styles.borderBlue : ""}`}>
        {question.type === "MultipleChoice" && (
          <ChoiceSettings question={question} saveQuestion={saveQuestion} />
        )}

        {question.type === "Text" && (
          <TextSettings
            question={question}
            saveQuestion={saveQuestion}
            dependents={dependents}
            setUpLogicResolver={setUpLogicResolver}
          />
        )}
        {(question.type === "NumberScale" ||
          question.type === "NumberSlider") && (
          <ScaleSettings
            question={question}
            saveQuestion={saveQuestion}
            dependents={dependents}
            setUpLogicResolver={setUpLogicResolver}
          />
        )}
        {question.type === "Matrix" && (
          <MatrixSettings
            question={question}
            saveQuestion={saveQuestion}
            dependents={dependents}
            setUpLogicResolver={setUpLogicResolver}
            onRetract={onRetract}
          />
        )}

        <ChangeQuestionType
          types={questionTypes}
          onSelect={handleChangeType}
          current={currentType}
          label="Question Type"
        />

        {question?.new && (
          <div
            style={{
              bottom: "0",
              right: "0",
              display: "flex",
              paddingTop: "1em",
              justifyContent: "space-between",
              flexDirection: "row-reverse",
              gap: "1em",
              alignItems: "end",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "1em",
              }}
            >
              <Button
                red
                height="35px"
                shadow
                onClick={() => onCancel(question)}
              >
                Cancel
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export function ChangeQuestionType({ types, current, onSelect, label }) {
  const [show, setShow] = useState(false);

  const ref = useRef(null);

  return (
    <div className={styles.questionTypesContainer}>
      <Label
        style={{
          fontWeight: "600",
          fontSize: ".85em",
          paddingLeft: "1em",
          paddingBottom: "3px",
          textTransform: "none",
        }}
      >
        {label}
      </Label>
      <div
        className={`${styles.questionType} ${show ? styles.active : ""}`}
        onClick={() => setShow(!show)}
      >
        <div className={styles.type} ref={ref}>
          {current.label}
          {current.icon}
        </div>

        <div className={styles.select}>
          <i className="bi bi-chevron-down"></i>
        </div>
      </div>

      {show && (
        <ChangeTypeMenu
          types={types}
          onSelect={(val) => {
            onSelect(val);
            setShow(false);
          }}
          current={current}
          close={() => setShow(false)}
          topRef={ref}
        />
      )}
    </div>
  );
}

function ChangeTypeMenu({ types, current, onSelect, close, topRef }) {
  function onClickOut(e) {
    if (
      ref.current &&
      !ref.current.contains(e.target) &&
      topRef.current &&
      !topRef.current.contains(e.target)
    ) {
      close();
      document.removeEventListener("click", onClickOut, true);
      e.stopPropagation();
    }
  }

  useEffect(() => {
    setTimeout(() => document.addEventListener("click", onClickOut, true), 250);
    return () => document.removeEventListener("click", onClickOut, true);
  }, []);

  const ref = useRef();

  return (
    <div className={styles.optionsAnchor}>
      <div className={styles.options} ref={ref}>
        {types.map((type) => (
          <>
            {type.value !== current.value && (
              <div
                className={styles.option}
                onClick={() => onSelect(type.value)}
              >
                {type.label}
                {type.icon}
              </div>
            )}
          </>
        ))}
      </div>
    </div>
  );
}
